import { FormatWidth } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, QueryFn, validateEventsArray } from '@angular/fire/firestore';
import { first, tap } from 'rxjs/operators';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AgencyOfficeModalComponent } from '../agency-office-modal/agency-office-modal.component';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ArtemisCustomDialogComponent } from 'src/app/artemis/artemis-custom-dialog/artemis-custom-dialog.component';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';
import { ArtemisOfficeControlComponent } from '../../artemis-office-control/artemis-office-control.component';

@Component({
  selector: 'app-agency-offices',
  templateUrl: './agency-offices.component.html',
  styleUrls: ['./agency-offices.component.scss']
})
export class AgencyOfficesComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger1: Subject<any> = new Subject<any>();

  @Input() leadEncoder :boolean =false

  offices: any;
  loading:any = false;

  officeCollection: any = []
  @Input('agency_id') agency_id:any;
  @Input('isAdmin') isAdmin:any;
  @Input('isAgencyMember') isAgencyMember:any;

  constructor(private afs: AngularFirestore,
    private snackBarService: SnackbarNotifService, public dialog: MatDialog) {


  }

  dataSource:any[] = []

  /* get lowerLevelUnits(){
    return this.dataSource.filter((f:any)=> f?.isLowerLevelUnit === true)
} */

  ngOnInit(): void {
    
   
    /* this.getAgencyOffices()
    this.dtOptions = {
      processing: true,
      lengthMenu: [20, 50, 100],
      destroy: true,
      pagingType: 'full_numbers',
      pageLength: 20,
      searching: true,
    } */

      this.loadoffices()
    
  }
  ngAfterViewInit(): void {
    //this.dtTrigger.next();
    this.dtTrigger1.next();
   
  }

  rerender(): void {
  
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear().draw();
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger1.next();
    })

  }
  

   getAgencyOffices() {

    let instatus: any = ["Active"]
    if(this.isAdmin)instatus.push("Deleted");

    this.afs.collection('Agency Offices', 
    filter => filter
    .where('Status','in',instatus)
    .where('fromAgencyId','==',this.agency_id).orderBy("name",'asc'))
    .snapshotChanges()
    .pipe(
      tap((data:any)=>{
        //clear tables before adding snapshots
        this.officeCollection = []
      data.forEach((info:any)=>{
        let item: any = info.payload.doc.data();
        item.id = info.payload.doc.id;
        this.officeCollection.push(item)
      })
      this.rerender();
    })).subscribe()
    


  }

  loadoffices(){

    this.afs
    .collection('Agency Office Hierarchy', 
    filter => filter.where('fromAgencyId','==',this.agency_id)
    .where("isLowerLevelUnit",'==',true)
    .orderBy('createdAt','asc'))
    .snapshotChanges()
    .pipe(tap((data:any)=>{
      this.dataSource = []
      data.forEach((info:any)=>{
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;
          if(item?.isLowerLevelUnit){
            this.dataSource.push(item)
          }
      })
      

    })).subscribe(()=>{
    })


  }


  addNode(islowerlevelUnit:boolean =false){
    this.dialog.open(ArtemisOfficeControlComponent, {
    }).afterClosed().subscribe((value:any)=>{
      if(value.submitFlag){
        this.afs.collection("Agency Office Hierarchy")
        .add({
          fromAgencyId : this.agency_id,
          isLowerLevelUnit: islowerlevelUnit,
          office: value.office,
          createdAt: new Date()
        }).then(()=>{
          this.snackBarService.displayNotif('success',"Success")
        })
      }
    });

  }


  addOffice() {

    const dialogRef = this.dialog.open(AgencyOfficeModalComponent,{
      data:{
        action:'Add',
        agencyID: this.agency_id
      }
    });
  }

  UpdateOffice(office:any) {
    //console.log('Update',office.id)
    const dialogRef = this.dialog.open(AgencyOfficeModalComponent,{
      data:{ 
        id: office.id,
        name: office.type,
        number: office.name,
        agencyId: this.agency_id
      }
    });


  }


  removeOffice(data: any,index:any) {
    
    const dialogRef = this.dialog.open(ArtemisCustomDialogComponent,{
      data:{
        title: "Delete office",
        message: "Would you like to delete " + data.name + "?",
        acceptText:"Yes, Proceed",
        dialogType:1
 

      }
    })

    dialogRef.afterClosed().subscribe(result =>{
      if(result.flag){
        data.Status = "Deleted"
        this.afs.collection('Agency Offices').doc(data.id).update(data)
        .then(()=>{
          this.snackBarService.openPlainNotif("Successfully Deleted","Close");
        })
        .catch(er=>{
          alert(er)
        });

      }
    })
    //console.log("Document id",data.id)
   
  }



}
