<!-- Navbar -->
<nav class="navbar navbar-expand-xl sticky-top navbar-light bg-light shadow" style="max-width: 100%;">
    <!-- Container wrapper -->
    <div class="container-fluid">
        <!-- Navbar brand -->
        <a class="navbar-brand" href="/">
            <img src="/assets/Assets/ARTA%20LOGO.png" height="85" alt="Anti-Red Tape Authority Logout" loading="lazy"></a>

        <!-- Toggle button -->
        <button class="navbar-toggler" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <i class="fas fa-bars"></i>
    </button>

        <!-- Collapsible wrapper -->
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <!-- Left links -->
            <ul class="navbar-nav d-inline-flex text-truncate ms-auto mb-2 mb-lg-0">

                <li class="nav-item">
                    <a class="nav-link" routerLink="/about-us">About Us</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Resources</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">EODB 2021</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" href="#">Media</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">File & Complaint</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">FAQs</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Contact Us</a>
                </li>
                <li class="nav-item" *ngIf="auth.user | async as user;">
                    <a class="nav-link" *ngIf="cred?.credentials.account_subtype === 'ARTA - IT/Admin' " href="#">Contact Us</a>
                </li>
                <!-- Profile -->
                <li class="nav-item" *ngIf="auth.user | async as user; else showLogin">
                    <a class="btn btn-lg btn-primary m-1" (click)="goTo(pbris)">PBRIS</a>
                    <a class="btn btn-lg btn-danger m-1" (click)="goTo(artemis)">ARTEMIS</a>
                </li>
                <!--Admin Controls-->
                <li class="nav-item" *ngIf="auth.user | async as user;" hidden>
                    <a class="nav-link" *ngIf="isAdmin" routerLink="/admin/controls/">Admin Controls</a>
                </li>


                <ng-template #showLogin>
                    <a class="btn btn-lg btn-primary m-1" routerLink="/pbris/login" [queryParams]="{initial:'true'}">PBRIS</a>
                    <a class="btn btn-lg btn-danger m-1" routerLink="/artemis/login" [queryParams]="{initial:'true'}">ARTEMIS</a>
                </ng-template>

            </ul>


        </div>
        <!-- Collapsible wrapper -->
    </div>
    <!-- Container wrapper -->
</nav>
<!-- Navbar -->