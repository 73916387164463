<h2 mat-dialog-title class="mat-typography" style="font-weight: bold;">{{action}} {{title}}</h2>
<div mat-dialog-content style="min-width: 800px;max-width: 800px;">
    <form [formGroup]="artemisRefForm" class="form-group">
        <div class="row margin-1em" *ngIf="data.currentRef == 'Classification' || data.currentRef == 'Service Category'">
            <div class="col-2"><label class="form-input-header fw-bold">Section</label></div>
            <div class="col-1 form-input-header fw-bold">:</div>
            <div class="col">
                <ng-container *ngIf="toView || toDelete">
                    <p style="text-align: justify;">{{currentRef ? (currentRef.section ? currentRef.section : "N/A") : "N/A"}}</p>
                </ng-container>
                <ng-container *ngIf="toCreate || toEdit">
                    <input type="text" class="form-control" formControlName="section">
                    <ng-container *ngFor="let item of checkRefForm.section">
                        <mat-error style="color:red" *ngIf="hasError('section',item.type)">* {{item.message}}</mat-error>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="row margin-1em" *ngIf="data.currentRef == 'Mother Agency'">
            <div class="col-2"><label class="form-input-header fw-bold">Name</label></div>
            <div class="col-1 form-input-header fw-bold">:</div>
            <div class="col">
                <ng-container *ngIf="toView || toDelete">
                    <p style="text-align: justify;">{{currentRef ? (currentRef.motheragency ? currentRef.motheragency : "N/A") : "N/A"}}</p>
                </ng-container>
                <ng-container *ngIf="toCreate || toEdit">
                    <input type="text" class="form-control" formControlName="motheragency">
                    <ng-container *ngFor="let item of checkRefForm.motheragency">
                        <mat-error style="color:red" *ngIf="hasError('motheragency',item.type)">* {{item.message}}</mat-error>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="row margin-1em" *ngIf="data.currentRef == 'Agency Category'">
            <div class="col-2"><label class="form-input-header fw-bold">Abbreviation</label></div>
            <div class="col-1 form-input-header fw-bold">:</div>
            <div class="col">
                <ng-container *ngIf="toView || toDelete">
                    <p style="text-align: justify;">{{currentRef ? (currentRef.abbreviation ? currentRef.abbreviation : "N/A") : "N/A"}}</p>
                </ng-container>
                <ng-container *ngIf="toCreate || toEdit">
                    <input type="text" class="form-control" formControlName="abbreviation">
                    <ng-container *ngFor="let item of checkRefForm.abbreviation">
                        <mat-error style="color:red" *ngIf="hasError('abbreviation',item.type)">* {{item.message}}</mat-error>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="row margin-1em" *ngIf="data.currentRef == 'Agency Category' || data.currentRef == 'Classification' || data.currentRef == 'Service Category'">
            <div class="col-2"><label class="form-input-header fw-bold">Name</label></div>
            <div class="col-1 form-input-header fw-bold">:</div>
            <div class="col">
                <ng-container *ngIf="toView || toDelete">
                    <p style="text-align: justify;">{{currentRef ? (currentRef.name ? currentRef.name : "N/A") : "N/A"}}</p>
                </ng-container>
                <ng-container *ngIf="toCreate || toEdit">
                    <input type="text" class="form-control" formControlName="name">
                    <ng-container *ngFor="let item of checkRefForm.name">
                        <mat-error style="color:red" *ngIf="hasError('name',item.type)">* {{item.message}}</mat-error>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="row margin-1em" *ngIf="data.currentRef == 'Stage of Life'">
            <div class="col-2"><label class="form-input-header fw-bold">Stage</label></div>
            <div class="col-1 form-input-header fw-bold">:</div>
            <div class="col">
                <ng-container *ngIf="toView || toDelete">
                    <p style="text-align: justify;">{{currentRef ? (currentRef.stage ? currentRef.stage : "N/A") : "N/A"}}</p>
                </ng-container>
                <ng-container *ngIf="toCreate || toEdit">
                    <input type="text" class="form-control" formControlName="stage">
                    <ng-container *ngFor="let item of checkRefForm.stage">
                        <mat-error style="color:red" *ngIf="hasError('stage',item.type)">* {{item.message}}</mat-error>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions class="d-flex justify-content-between">
    <button type="button" class="btn btn-dark" (click)="goBack()" >
        <span *ngIf="!toView">Cancel</span>
        <span *ngIf="toView">Back</span>
    </button>
    <button type="button" *ngIf="toDelete" class="btn btn-danger" (click)="deleting()">
        Delete
    </button>
    <button type="button" *ngIf="toEdit" class="btn btn-warning" (click)="updating()">
        Update
    </button>
    <button type="button" *ngIf="toCreate && data.currentRef != 'List of Service'" class="btn btn-primary" (click)="creating()" cdkFocusInitial>
        Add
    </button>
</div>