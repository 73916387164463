<!--NAVBAR-->
<app-artemis-navigation-header [currentPage]="''"></app-artemis-navigation-header>


<div class="advanced-search-card shadow">
    <div style="height: 30px"></div>
    <div class="{{style_theme}} advanced-search-title">
        <h1>Announcements</h1>&nbsp;&nbsp;
        <i class="advanced-search-title-icon fas fa-search fa-3x"></i>
    </div>
    <div class="row advanced-search-card">
        <div class="" style="width: 300px;">
            <div class="row">
                <h5 class="{{style_theme}}-text"><u>Date Filter</u></h5>
                <div class="button-group">
                    <div class="">
                        <p class="header">2020</p>
                        <div class="ms-5">
                            <p>January 2020</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <h5 class="{{style_theme}}-text"><u>Type Filter</u></h5>
                <div class="button-group">
                    <p *ngFor="let category of list_of_categories" class="w-100">+ {{category}}</p>
                </div>
            </div>
        </div>
        <div class="col">
            <ul class="list-group overflow-auto">
                <li *ngFor="let announcement of list_of_announcements" class="list-group-flush border-bottom p-3">
                    <h5 class="list-header">{{announcement.title}}</h5>
                    <small>Posted on {{announcement.date.toDate() | date:"MMMM dd, yyyy" }}</small>
                </li>
            </ul>
        </div>
    </div>
    <div class="m-3 text-center"></div>
</div>