// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyD6ldUEzy8Pvl7MSEfSNa8cLzrLG6yiGHo",
    authDomain: "arta-respond.firebaseapp.com",
    databaseURL: "https://arta-respond-default-rtdb.firebaseio.com",
    projectId: "arta-respond",
    storageBucket: "arta-respond.appspot.com",
    messagingSenderId: "536720105435",
    appId: "1:536720105435:web:dac46bd1966f5ea8acd1fe",
    measurementId: "G-8C5KXF3V8M"
  },
  recaptchaSiteKeys: {
    v2: "6LeXrfMaAAAAAG6-FVnGufJFgUq1UVfBTeLGywwN",
    v3: "6LfRLPEaAAAAAJraWyXxZYhX0Rdk9I8vc5mMiH-3"
  },
  verificationRedirectUrls: {
    pbris: "https://arta-respond.web.app/pbris/login",
    artemis: "https://arta-respond.web.app/artemis/login"
  },
  redirectUrls: {
    artemisHome: "https://arta-respond.web.app/artemis"
  },
  apiBaseURL:
    // "http://localhost:5000/arta-respond/us-central1/artaRespondApi/api/v1",
    "https://us-central1-arta-respond.cloudfunctions.net/artaRespondApi/api/v1",
  emailURL: "https://firebase-php-mailer-usxhevx4pq-uc.a.run.app",
  emailURLNotif: 'https://notification-emailer-usxhevx4pq-uc.a.run.app',
  vapidKey: "BM9YRlovQwb9nb0y0nU7w9rPvrSUestxKegTrnupX_vi25cqyrYVtAR2egtj4994ehqt9dCTkmhUl2-LGpGa7mw",
  jsReport: {
    serverUrlCloudRun: "https://artemis-service-base-usxhevx4pq-uc.a.run.app",
    publicAccess: {
      artemisServicePDFPath: "/Artemis-Service/Artemis-Service-Report"
    },
    privateAccess:{
      user: "",
      password: "",
      path: ""
    }
  },
  consultationTimegateDisabled: true
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
