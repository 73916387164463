import { Component, OnInit } from '@angular/core';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { formatDate,Location } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { first, map, take, tap } from 'rxjs/operators';
import { CitizensCharterCommentStatus } from 'src/app/entities/worklist';

@Component({
  selector: 'app-artemis-comment-create',
  templateUrl: './artemis-comment-create.component.html',
  styleUrls: ['./artemis-comment-create.component.scss']
})
export class ArtemisCommentCreateComponent implements OnInit {
  recaptchaV2SiteKey: string = environment.recaptchaSiteKeys.v2;
  // Modal
  comment: any;
  user: any;
  url: any;
  document: any;
  created_at: any;

  loading: boolean = false;

  // File
  temp_file: any = [];
  // Date
  todayDate: Date = new Date();
  // phase 1 - sample form
  phase1form = new FormGroup({
    comment: new FormControl(''),
    user: new FormControl(''),
    document: new FormControl(''),
    path: new FormControl(''),
    url: new FormControl(''),
    captcha: new FormControl('', Validators.required),
  });
  // Path storage
  path: any = '';
  
  data: any = [];

  // User id
  user_id: any;
  user_info: any = [];

  constructor(
    public auth: AngularFireAuth, 
    private afs: AngularFirestore, 
    private store: AngularFireStorage,
    private route: ActivatedRoute,
    private _location: Location
  ) {}

  ngOnInit(): void {
    this.user_info = {
      user_id: '',
      name: '',
      email: '',
      type: ''
    }

    this.loadParams();

    // Load user_info starting with this.auth.user
    this.auth.user.pipe(
      map(data => {
        this.user_info = {
          user_id: data?.uid,
          name: data?.displayName,
          email: data?.email
        }

        return data?.uid;
      }),
      map(uid => {
        this.afs.collection(`Users`).doc(uid).valueChanges().pipe(
          tap(
            (data: any) => {
              this.user_info.type = data.credentials.account_type;
            }
          ),
          take(1)
        ).subscribe();
      }),
    ).subscribe();
  }

  public resolved(captchaResponse: string): void {
    this.phase1form.value.captcha = captchaResponse;
    //console.log(`Resolved captcha with response: ${this.phase1form.value.captcha}`);
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    //console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }

  async loadParams(){
    const params = await this.route.queryParams.pipe(first()).toPromise();
    //console.log(params);
  }

  async onSubmit() {
    this.loading = true;
    Swal.fire({
      title: 'Processing',
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: async () => {
        Swal.showLoading();
        let temp_file_list = [];
        if (this.temp_file.length > 0) {
          for (let x = 0; x < this.temp_file.length; x++) {
            temp_file_list.push({
              name: this.temp_file[x].name,
              type: this.temp_file[x].type,
              size: this.temp_file[x].size,
            })
            this.path = `comment_artemis/${this.user_info.user_id}/files/`;
            let destinationPath = `comment_artemis/${this.user_info.user_id}/files/${this.temp_file[x].name}`;
            let destinationRef = this.store.ref(destinationPath);
            let task = this.store.upload(destinationPath, this.temp_file[x]);
            console.log(task.percentageChanges());
          }
        }

        const params = await this.route.queryParams.pipe(first()).toPromise();
       // console.log(params);

        let typeObj = {
          type_flag: params.type_flag || "",
          type_id: params.type_id || "",
          type_title: params.type_title || "",
          type_agencyId: params.type_agencyId || "",
        };

        this.phase1form.value.document = temp_file_list;
        this.phase1form.value.path = this.path;
        this.data.push({
          uuid: this.afs.createId(),
          status: CitizensCharterCommentStatus.UNRESOLVED,
          comments: this.phase1form.value,
          type: typeObj,
          user: this.user_info,
          reply: '',
          created_at: formatDate(this.todayDate, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0800')
        });

        this.afs.collection(`Comment Artemis`).add(this.data[0]).then((docRef:any) => {
          this.loading = false;
          Swal.close();
          Swal.fire({
            title:'Successfully processed',
            icon: 'info'
          });
          this.resetForm();
          this._location.back();
        }).catch((error:any) =>{
          this.loading = false;
          Swal.close();
          Swal.fire({
            title:'System error',
            titleText: 'Please refresh the page',
            icon: 'error'
          });
        });
      }
    })
  }

  resetForm() {
    this.phase1form.reset();
    this.data = [];
    this.temp_file = [];
  }

  documentOnChange(data: any) {
    if (data.target.files.length > 0) {
      let temp = data.target.files;
      for (let x = 0; x < temp.length; x++) {
        if (Object.keys(this.temp_file).length == 0) {
          this.temp_file.push(temp[x]);
        } else {
          let found = this.temp_file.find((elements: any) => elements.name === temp[x].name);
          if (!found) {
            this.temp_file.push(temp[x]);
          }
        }
      }
      this.phase1form.value.document = this.temp_file;
    }
  }

  deleteFile(data: any) {
    this.temp_file = this.temp_file.filter((element: any) => element.name !== data);
  }
}
