<app-artemis-navigation-header [currentPage]="''"></app-artemis-navigation-header>
<div class="container p-3">
    <div class="px-3" style="margin: auto" >
        <mat-card>
          
          <mat-card-content>
    
            <app-citizens-charter-service-view  [service]=services [service_id]=service_id  [reviewMode]="false" [hasCounter]="false"></app-citizens-charter-service-view>
    
            <div class="row p-2 mt-2 " style="border: 2px dotted black; ">
              <span class="text-center">End of Service</span>
            </div>
    
          </mat-card-content>
    
    
          </mat-card>
        </div>
</div>
