import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ArtemisCocPreviewPageArtaComponent } from 'src/app/artemis/artemis-inbox-page/artemis-charter-inbox-page-arta/artemis-coc-preview-page-arta/artemis-coc-preview-page-arta.component';

@Component({
  selector: 'app-agency-citizens-charter',
  templateUrl: './agency-citizens-charter.component.html',
  styleUrls: ['./agency-citizens-charter.component.scss']
})
export class AgencyCitizensCharterComponent implements OnInit {
  @Input('agency_id') agency_id:any;
  @Input('isAdmin') isAdmin:any;
  @Input('isAgencyMember') isAgencyMember:any;
  results:any = []
  //mat table paginator
  displayedColumns: string[] = ['dateposted', 'ccversion'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

   //number formatting
   ordinals: string[] = ['th', 'st', 'nd', 'rd'];



  constructor(private router: Router,public dialog: MatDialog,private afs: AngularFirestore,private http: HttpClient,) { }
  
  ngOnInit(): void {
    this.loadCCbyAgency();
  }


  loadCCbyAgency(){

    this.afs.collection('citizens-charter-phase-02',filter=>
    filter.where("fromAgencyId","==",this.agency_id)
    .orderBy("date_posted",'desc'))
    .snapshotChanges()
    .pipe(tap((data:any)=>{

      //reset tables berofe adding snapshots
      this.results = []
      data.forEach((info:any)=>{
        let item: any = info.payload.doc.data();
        item.id = info.payload.doc.id;
        if(item.reportURL){
          this.results.push(item)

        }
        
      })
      
      this.dataSource = new MatTableDataSource(this.results)
      this.resetSortPage()
    }))
    .subscribe()
  }

  resetSortPage() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }

  sortChange(sort: Sort) {
    const data = this.results.slice()
    // console.log('sort.active',sort.active)
    // console.log('sort.direction',sort.direction)
    if (!sort.active || sort.direction === '') {
      this.results = data
      return;
    }
    this.results = data.sort((a: any, b: any) => {
      // console.log('results',this.results)
      const isAsc = sort.direction === 'asc'
      switch (sort.active) {

        case 'dateposted':
          return this.compare(a.date_posted, b.date_posted, isAsc)
        case 'reporturl':
          return this.compare(a.reportURL.trim().toLowerCase(), b.reportURL.trim().toLowerCase(), isAsc)

        default:
          return null
      }
    })
    this.dataSource = new MatTableDataSource<any>(this.results)
    this.resetSortPage()

  }
  //sort data 
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ViewCCDocument(data:any){

    if(data.id){

     // console.log("CC Dat",data);
      this.router.navigate(["/artemis/citizen-charter/view/report/"],{
        queryParams: {
          cc_id: data.id,
          edition: data.edition
        }
      })


      //this.checkDocumentExist(data)
    }
  }

  transform(n: number, year: string, keepNumber: boolean = true) {
    let v = n % 100;
    return year  + " "+  (keepNumber ? n : '') + (this.ordinals[(v - 20) % 10] || this.ordinals[v] || this.ordinals[0]) + " Edition";
  }

  private async checkDocumentExist(data:any){
    // console.log("checkDocumentExist")
    // const apiUrl = "https://us-central1-arta-respond.cloudfunctions.net/checkDocExist"; //cloudfunc /to check document existence /no checking
    const apiUrl = "https://us-central1-arta-respond.cloudfunctions.net/generateArtemisReport/api/v1/checkDocumentExist";
    let  serviceData ={
      generationFor: "ccAgencyReports",
      id: data.id,
      agency: {agencyCode: this.agency_id}
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    this.http.post<any> (apiUrl,serviceData,httpOptions)
    .subscribe({
      next:(apiResponse:any)=>{
        // { message: string, exist: boolean  }
        //console.log('apiResponse: ',apiResponse)
        if(apiResponse){
          if(apiResponse.exist == true && data.reportURL){

            this.dialog.open(ArtemisCocPreviewPageArtaComponent, {
              maxWidth: "80vw",
              width: "80vw",
              data : {
                file : data.reportURL,
                file_type: "Citizen's Charter " + data.edition + " Edition"
              }
              
            })
            //window.open(data.reportURL)
           
          }else{
            alert("No PDF file to check, Generate a report first")
      
          }
        }else{
          alert("Checking Report failed")
       
        }


       
      },
      error:(err:any)=>{
        console.error("Error checking document: ",err)
        //this.loader = false
      }
    })
  }


}
