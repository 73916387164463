<div class="d-flex w-100 flex-layout" >
    <div class="d-flex w-100">
        <div class=" agency-details-left relative hover-parent width-layout">
            <mat-card>
                <div class="px-5">
                    <!-- sendFeedback -->
                <form class="form-group space" [formGroup]="sendFeedbackForm">
                    <div class="profileHeader d-flex justify-content-between">
                        <span>
                            <h3>How to send feedback ?</h3>
                        </span>
                        <span *ngIf="(isAdmin || isAgencyMember)" >
                            <a  *ngIf="editSendFeedback"
                                (click)="toggleEdit('sendFeedback')"
                                style=" z-index: 1000; cursor: pointer;"
                                class="report-title fw-bold"
                                title="Edit How to send feedback">
                                <i class="fas fa-edit"></i>
                            </a> 
                            <span  *ngIf="!editSendFeedback">
                                <button type="button"  (click)="update('sendFeedback')" class="btn update-btn btn-primary" [disabled]="loading">
                                    <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>Update</button>
                                <button type="button"  (click)="toggleEdit('sendFeedback')" class="btn btn-danger">Cancel</button>
                            </span>
                        </span>
                    </div>
                    <div class="p-2">
                        <p *ngIf="editSendFeedback" [innerHTML]="sendFeedbackForm.value.sendFeedback | linebreaks"></p>
                    </div>
                    <div class="space" *ngIf="!editSendFeedback">
                        <quill-editor id="sendFeedback" name="quillEditor" formControlName="sendFeedback" ></quill-editor>
                        <!-- <textarea type="text" class="form-control" formControlName="sendFeedback" id="sendFeedback" placeholder="Input how to send feedback here" appTextareaAutoresize></textarea> -->
                        <!-- <ng-container *ngFor="let item of checkFeedbackMeasuresForm.mission"> 
                            <mat-error style="color:red" *ngIf="hasErrors('mission','mission',item.type)">* {{item.message}}</mat-error>
                        </ng-container> -->
                    </div>
                </form>
                <hr >
                <!-- howFeedback -->
                <form class="form-group space" [formGroup]="howFeedbackProcessedForm">
                    <div class="profileHeader d-flex justify-content-between">
                        <span>
                            <h3>How feedback is processed ?</h3>
                        </span>
                        <span *ngIf="(isAdmin || isAgencyMember)" >
                            <a  *ngIf="editHowFeedback"
                                (click)="toggleEdit('howFeedback')"
                                style=" z-index: 1000; cursor: pointer;"
                                class="report-title fw-bold"
                                title="Edit How feedback is processed">
                                <i class="fas fa-edit"></i>
                            </a> 
                            <span  *ngIf="!editHowFeedback">
                                <button type="button"  (click)="update('howFeedback')" class="btn update-btn btn-primary" [disabled]="loading">
                                    <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>Update</button>
                                <button type="button"  (click)="toggleEdit('howFeedback')" class="btn btn-danger">Cancel</button>
                            </span>
                        </span>
                    </div>
                    <div class="p-2">
                        <p *ngIf="editHowFeedback" [innerHTML]="howFeedbackProcessedForm.value.howFeedbackProcessed | linebreaks"></p>
                    </div>
                    <div class="space" *ngIf="!editHowFeedback">
                        <quill-editor id="howFeedbackProcessed" name="quillEditor" formControlName="howFeedbackProcessed" ></quill-editor>
                        <!-- <textarea type="text" class="form-control" formControlName="howFeedbackProcessed" id="howFeedbackProcessed" placeholder="Input how feedback is processed here" appTextareaAutoresize></textarea>
                        --> <!-- <ng-container *ngFor="let item of checkFeedbackMeasuresForm.howFeedback"> 
                            <mat-error style="color:red" *ngIf="hasErrors('howFeedback','howFeedback',item.type)">* {{item.message}}</mat-error>
                        </ng-container> -->
                    </div>
                </form>
                <hr >
                 <!-- fileComplaint -->
                <form class="form-group space" [formGroup]="fileHowComplaintForm">
                    <div class="profileHeader d-flex justify-content-between">
                        <span>
                            <h3>How to file complaint ?</h3>
                        </span>
                        <span *ngIf="(isAdmin || isAgencyMember)" >
                            <a  *ngIf="editFileComplaint"
                                (click)="toggleEdit('fileComplaint')"
                                style=" z-index: 1000; cursor: pointer;"
                                class="report-title fw-bold"
                                title="Edit Mandate">
                                <i class="fas fa-edit"></i>
                            </a> 
                            <span  *ngIf="!editFileComplaint">
                                <button type="button"  (click)="update('fileComplaint')" class="btn update-btn btn-primary" [disabled]="loading">
                                    <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>Update</button>
                                <button type="button"  (click)="toggleEdit('fileComplaint')" class="btn btn-danger">Cancel</button>
                            </span>
                        </span>
                    </div>
                    <div class="p-2">
                        <p *ngIf="editFileComplaint" [innerHTML]="fileHowComplaintForm.value.fileHowComplaint | linebreaks"></p>
                    </div>
                    <div class="space" *ngIf="!editFileComplaint">
                        <quill-editor id="fileHowComplaint" name="quillEditor" formControlName="fileHowComplaint" ></quill-editor>
                        <!-- <textarea type="text" class="form-control" formControlName="fileHowComplaint" id="fileHowComplaint" placeholder="Input Mandate here" appTextareaAutoresize></textarea> -->
                        <!-- <ng-container *ngFor="let item of checkFeedbackMeasuresForm.mandate"> 
                            <mat-error style="color:red" *ngIf="hasErrors('mandate','mandate',item.type)">* {{item.message}}</mat-error>
                        </ng-container> -->
                    </div>
                </form>
                <hr>
                <!-- service pledges -->
                <form class="form-group space" [formGroup]="howComplaintProcessedForm">
                    <div class="profileHeader d-flex justify-content-between">
                        <span>
                            <h3>How complaints are processed ?</h3>
                        </span>
                        <span *ngIf="(isAdmin || isAgencyMember)" >
                            <a  *ngIf="editHowComplaint"
                                (click)="toggleEdit('howComplaint')"
                                style=" z-index: 1000; cursor: pointer;"
                                class="report-title fw-bold"
                                title="Edit How complaints are processed">
                                <i class="fas fa-edit"></i>
                            </a> 
                            <span  *ngIf="!editHowComplaint">
                                <button type="button"  (click)="update('howComplaint')" class="btn update-btn btn-primary" [disabled]="loading">
                                    <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>Update</button>
                                <button type="button"  (click)="toggleEdit('howComplaint')" class="btn btn-danger">Cancel</button>
                            </span>
                        </span>
                    </div>
                    <div class="p-2">
                        <p *ngIf="editHowComplaint" [innerHTML]="howComplaintProcessedForm.value.howComplaintProcessed | linebreaks"></p>
                    </div>
                    <div class="space" *ngIf="!editHowComplaint">
                        <quill-editor id="howComplaintProcessed" name="quillEditor" formControlName="howComplaintProcessed" ></quill-editor>
                        <!-- <textarea type="text" class="form-control" formControlName="howComplaintProcessed" id="howComplaintProcessed" placeholder="Input how complaints are processed here" appTextareaAutoresize></textarea>
                        --> <!-- <ng-container *ngFor="let item of checkFeedbackMeasuresForm.pledges"> 
                            <mat-error style="color:red" *ngIf="hasErrors('pledges','pledges',item.type)">* {{item.message}}</mat-error>
                        </ng-container> -->
                    </div>
                </form>
                </div>
            </mat-card>
        </div>
    </div>
</div>
