<div class="contianer my-3">
  <form [formGroup]="pbrisCommentForm" autocomplete="off">
    <div class="row justify-content-center">
      <div class="col-12 info-header">
        <h6 class="fw-bold mb-0">SUBMIT A COMMENT</h6>
      </div>
      <div class="col-12 border-bottom">
        <p class="p-4" style="text-align:justify;">Please note that your comment will only be visible to the concerned agency. If you submit your comment as an individual/organization, your identity or details of your account will be visible to them. 
          If submitted anonymously, your identity will not be revealed. The agency retains discretion on whether or not to respond to your inquiry. We kindly request that your comments remain relevant to the service. 
          <br><br>The system administrators reserve the right to disable or ban your account for any inappropriate, malicious, and abusive use of comment functionalities.
          </p>
      </div>
    </div>

    <div class="row justify-content-center" style="background-color:#FAFAFA;border-bottom:1px solid rgba(0, 0, 0, 0.1);">
      <div class="form-group">
        <div class="px-5 pt-3">
          <h6 class="fw-bold">WRITE YOUR COMMENT HERE <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></h6>
          <textarea class="form-control col-12" formControlName="comment" id="comment" rows="3" placeholder=""></textarea>
          <label for="comment" class="form-label font-weight-bold">
            <div *ngFor="let formValid of checkPbrisCommentForm.comment">
              <div class="error" *ngIf="hasError('comment', formValid.type)">
                <p class="mb-0">* {{formValid.message}}</p>
              </div>
            </div>
          </label>
        </div>

      </div>

      <div class="form-group px-5">
        <div class="row d-flex justify-content-center">
          <div class="col-12 mb-3">
            <h6 class="fw-bold">ADD URL</h6>
            <input type="text" class="form-control" formControlName="url" placeholder="Or add a URL">
          </div>
        
          <div class="col-md-6 col-sm-12 file-uploader-wrapper border-0 my-3">
            <h6 class="fw-bold" style="text-align:center;">UPLOAD RELEVANT AND/OR SUPPORTING DOCUMENTS</h6>
            <div appFileUploader class="col-12 dropzone" (filesChangeEmiter)="onDropzoneMultipleChange($event)">
              <!-- FILE UPLOAD -->
              <button type="button" class="btn btn-link dropzone-btn file-uploader-wrapper" (click)="fileDialogFiles.click()">
                <!-- <mat-icon class="upload-icon" svgIcon="upload"></mat-icon> -->
                <!-- <i class="fa-solid fa-arrow-down-to-square"></i> -->
                <!-- <i class="fal fa-arrow-alt-circle-down"></i> -->
                <span>
                  <p class="fw-bold mb-0">Drop files here to upload or click to choose file</p>
                  <p class="mb-0" style="font-size: 12px;">
                    Valid types include bmp. docx, gif, jpg, jpeg, pdf, png, pptx <br>
                    You can attach up to 5MB, but total of size should not exceed 20MB.
                  </p>
                </span>
              </button>
              <input #fileDialogFiles id="hidden-file-btn"  type="file" multiple (change)="onMultipleChange($event)"/>
            </div>
          </div>

          <div class="col-md-7 col-sm-12 file-uploader-list">
            <ol class="dropzone-list" *ngIf="uploadedFiles.list.length > 0">
              <li class="dropzone-list-item position-relative my-2" *ngFor="let file of uploadedFiles.list; let i = index">
                <div class="dropzone-list-item-info ">
                    <span class="file-name"><strong>{{ file.name }}</strong>
                      <span class="position-absolute ab-btn" (click)="removeFile(i)" style="font-size:12px;color:#0038A8;" matTooltipPosition="above" matTooltip="Remove">
                        <a><i class="fas fa-times-circle fa-lg mx-3"></i></a>
                      </span>
                    </span>
                    <p class="progress-bar-wrapper mb-1">
                      <mat-progress-bar mode="determinate" [value]="file.progress"></mat-progress-bar>
                    </p>
                    <span class="file-upload-status">
                        {{ file.loaded }} KB of {{ file.total }} KB ({{ file.progress }}% done)
                    </span>
                </div>
              </li>
            </ol>
          </div>
        </div>

        <div class="form-group p-5">
          <label>
            <h6 class="fw-bold">SUBMITTING AS: <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></h6>
            <div *ngFor="let formValid of checkPbrisCommentForm.user">
              <div class="error" *ngIf="hasError('user', formValid.type)">
                <p class="mb-0">* {{formValid.message}}</p>
              </div>
            </div>
          </label>

          <div class="row justify-content-center mt-3" style="text-align:center;">
            <div class="col-md-4 col-sm-12 my-1">
              <input type="radio" class="btn-check" formControlName="user" id="individual" name="user" [value]="submitAs.INIVIDUAL" />
              <label for="individual" title="Individual" class="col-lg-12 btn btn-outline-dark btn-rounded py-2">
                <div class="row align-items-center">
                  <p class="mb-0" style="text-align:center;">
                    <span>
                      <i class="fa fa-user mx-3" aria-hidden="true" style="font-size:x-large;"></i>
                      <strong class="fs-5 my-auto text-wrap">Individual</strong>
                    </span>
                  </p>
                  <!-- <div class="col-lg-7">
                    <h6 class="my-auto" style="text-transform: none;">
                      Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                    </h6>
                  </div> -->
                </div>
              </label>
              <p class="p-3">submitting a comment as an individual, without any affiliation to any organization or business</p>
            </div>

            <div class="col-md-4 col-sm-12 my-1">
              <input type="radio" class="btn-check" formControlName="user" id="organization" name="user" [value]="submitAs.ORGANIZATION" />
              <label for="organization" title="Organization" class="col-lg-12 btn btn-outline-dark btn-rounded py-2">
                <div class="row align-items-center">
                  <p class="mb-0" style="text-align:center;">
                    <span>
                      <i class="fa fa-users mx-3" aria-hidden="true" style="font-size:x-large;"></i>
                      <strong class="fs-5 my-auto text-wrap">Organization</strong>
                    </span>
                  </p>
                  <!-- <div class="col-lg-7">
                  <h6 class="my-auto" style="text-transform: none;">
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                  </h6> -->
                </div>
              </label>
              <p class="p-3 border-start border-end">submitting a comment as a representative of an academic institution, government body, or business entity</p>
            </div>

            <div class="col-md-4 col-sm-12 my-1">
              <input type="radio" class="btn-check" formControlName="user" id="anonymity" name="user" [value]="submitAs.ANONYMOUS" />
              <label for="anonymity" title="Anonymity" class="col-lg-12 btn btn-outline-dark btn-rounded py-2">
                <div class="row align-items-center">
                  <p class="mb-0" style="text-align:center;">
                    <span>
                      <i class="fa fa-user-secret mx-3" aria-hidden="true" style="font-size:x-large;"></i>
                      <strong class="fs-5 my-auto text-wrap">Anonymity</strong>
                    </span>
                  </p>
                  <!-- <div class="col-lg-7">
                    <h6 class="my-auto" style="text-transform: none;">
                      Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                    </h6>
                  </div> -->
                </div>
              </label>
              <p class="p-3">submitting a comment with the protection of hidden identity</p>
            </div>

          </div>
        </div>

      </div>

      <!-- PROCEED -->
      <div class="form-group text-center mt-5">
        <button class="btn btn-primary btn-rounded col-md-4 col-sm-12 p-2 my-3" (click)="openCommentModal();" [disabled]="!this.pbrisCommentForm.valid">
          <!-- sampleModal.show(); openCommentModal();-->
          <strong class="fs-6 text-wrap">Proceed</strong>
          <i class="fas fa-arrow-circle-right mx-2" style="font-size:large"></i>
        </button>
      </div>
      
    </div>
  </form>

  <!-- Modal -->
  <!-- <div style="overflow-y: auto" class="modal fade" mdbModal #sampleModal="mdbModal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content bg-dark opacity-25 text-white">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">This is how your comment will appear</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="sampleModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group my-2">
            <label for="comment2" class=""><strong>Your comment :</strong></label>
            <p id="comment2" class="m-2 fs-6">{{pbrisCommentForm.value.comment ? pbrisCommentForm.value.comment : "N/A"}}</p>
          </div>
          <div class="form-group my-2">
            <ul class="list-unstyled">
              <li><label class=""><strong>Attachments</strong></label>
                <ul class="list-unstyled m-2">
                  <li>
                    <label for="url" class=""><strong>Url :</strong></label> {{pbrisCommentForm.value.url ? pbrisCommentForm.value.url : "N/A"}}
                  </li>
                  <li>
                    <label for="attachment" class=""><strong>File :</strong></label>
                    <div *ngIf="temp_file" >
                      <ul class="list-unstyled" *ngFor="let list of temp_file; let i = index">
                        <li>
                          <div id="attachment" class="row m-2">
                            <div class="col text-break">{{list.name}}</div>
                            <div class="col">{{list.type}}</div>
                            <div class="col">{{list.size}} kb/s</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="form-group mt-3 d-flex">
            <div class="d-flex ml-5">
              <label for="submit_as" class=""><strong>Submitting as :</strong></label>
              <p id="submit_as" class="mx-2">{{pbrisCommentForm.value.user ? pbrisCommentForm.value.user : "N/A"}}</p>
            </div>
          </div>
          <hr />
          <div class="form-group">
            <div class="row">
              <div class="col-3 text-center">
                <i class="fa fa-exclamation-triangle fa-5x text-warning"></i>
              </div>
              <div class="col-9">
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
              </div>
            </div>
          </div>
        </div> 
        <div class="modal-footer">
          <button type="button" class="btn btn-light" style="letter-spacing: 2px;" data-dismiss="modal" (click)="sampleModal.hide()"><strong>Cancel</strong></button>
          <button type="button" class="btn btn-primary" style="letter-spacing: 2px;" (click)="onSubmit(); sampleModal.hide()"><strong>Submit</strong></button>
        </div>
      </div>
    </div>
  </div> -->
</div>