import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, take } from 'rxjs/operators';
import { GovernmentAccountSubtype, WorklistStatusPBRISRegulation, WorklistActionFlagPBRISRegulation } from 'src/app/entities/worklist';
import { EmailAppCode, EmailTemplateCode } from 'src/app/entities/emailTemplates';

@Injectable({
  providedIn: 'root',
})
export class NotificationsEmailer {

  private emailCollection = 'Email Configuration'
  public footerPBRIS: string = "<p>Best,</p><p>Philippine Business Regulations Information System</p><p>For inquiries and other concern, you may reach us through our <a href='https://regulations.gov.ph/pbris/contact-us'>PBRIS Website</a> or <a href='https://arta.gov.ph/contact-us/'>ARTA Website</a></p><p>This is an automated message please <strong>DO NOT REPLY</strong>.</p>"

  constructor(
    private http: HttpClient,
    private afs: AngularFirestore,
  ) { }
  //added a footer parameter. By default its set as blank. For PBRIS we use footerPBRIS which is included in this service.ts
  async emailServiceHandler(emails: any, appCode: any, templateCode: any, variables: any, footer: string = "") {
    // console.log('emailServiceHandler',{emails},{appCode},{templateCode},{variables})
    if (appCode && templateCode >= 0 && emails && emails.length > 0) {
      return new Promise((res, rej) => {
        this.afs.collection(this.emailCollection, filter => filter.where("configCode", "==", templateCode).where("enabled", "==", true)).snapshotChanges()
          .pipe(take(1))
          .subscribe({
            next: (responses) => {
              let template: any = false;
              responses.map((response) => {
                template = response.payload.doc.data();
                template.id = response.payload.doc.id;
              })

              if (template) {
                if (template.enabled == true) {
                  let body = {
                    recipients: emails, //emails
                    app_code: appCode,
                    mail_code: templateCode,
                    data: {
                      variables: variables,
                      content: template.content + "<br><br>" + footer,
                      subject: template.subject
                    },
                  }
                  //console.log('body', body)
                  this.sendEmailNotiftoUser(body)
                    .subscribe({
                      next: (response) => {
                        //console.log('response: ', response)
                        if (response && response.description == "Approved") {
                          //console.log('Email Successfully sent: ', templateCode)
                          return res(true)
                        }
                        else {
                          //console.log('Failed to send an email: ', templateCode)
                          return res(false)
                        }
                      },
                      error: (err) => {
                        //console.error('Error: ', err)
                        return res(false)
                      }
                    })
                } else {
                  console.warn('Email notification for template is disabled!')
                  return res(false)
                }
              } else {
                console.warn('No template: ', templateCode)
                return res(false)
              }
            },
            error: (err) => {
              console.error('Error: ', err)
              return res(false)
            }
          })
      })
    } else {
      console.warn('Error: emailServiceHandler')
      return false
    }
  }

  sendEmailNotiftoUser(body: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8"
      })
    };
    return this.http.post<any>(environment.emailURL, body, httpOptions)
  }

  async getEmails(userAgency: string, govAccSubType: GovernmentAccountSubtype, access_pbris: boolean = true) {

    return new Promise((res, rej) => {
      this.afs.collection('Users', filter => filter.where('credentials.account_subtype', '==', govAccSubType).where('credentials.access_pbris', '==', access_pbris).where('government.agency', '==', userAgency)).snapshotChanges()
        .pipe(first())
        .subscribe({
          next: (result) => {
            let tempArr: any = [];
            //console.log('Number of emails: ', result.length)
            result.map(data => {
              let user: any = data.payload.doc.data()
              let email: string = user.credentials.email // Extract email from user object
              if (!tempArr.includes(email)) tempArr.push(email)
            })
            return res(tempArr)
          },
          error: (err) => {
            console.error('getEmails', err)
            return res(false)
          }
        })
    })
  }

  async getAdminEmails() {

    return new Promise((res, rej) => {
      this.afs.collection('Users', filter => filter.where('credentials.account_subtype', '==', GovernmentAccountSubtype.ARTA_ADMIN)).snapshotChanges()
        .pipe(first())
        .subscribe({
          next: (result) => {
            let tempArr: any = [];
            //console.log('Number of emails: ', result.length)
            result.map(data => {
              let user: any = data.payload.doc.data()
              let email: string = user.credentials.email // Extract email from user object
              if (!tempArr.includes(email)) tempArr.push(email)
            })
            return res(tempArr)
          },
          error: (err) => {
            console.error('getEmails', err)
            return res(false)
          }
        })
    })
  }

  async emailNotificationbyAction(userAgency: string, userEmail: any[], data: any, actionFlag: WorklistActionFlagPBRISRegulation, reg_status: any = '') {
    var agencyHead: any = await this.getEmails(userAgency, GovernmentAccountSubtype.AGENCY_DIR);
    var agencyEncoders: any = await this.getEmails(userAgency, GovernmentAccountSubtype.AGENCY_ENC);
    var agencyVerifiers: any = await this.getEmails(userAgency, GovernmentAccountSubtype.AGENCY_VER);

    var footer: string = this.footerPBRIS;
    //for testing
    console.log(actionFlag);
    // agencyVerifiers = ["miguelsantos@arta.gov.ph"];
    // agencyEncoders = ["miguelsantos@arta.gov.ph"];
    // agencyHead = ["miguelsantos@arta.gov.ph"];
    // userEmail = ["miguelsantos@arta.gov.ph"];
   /*  console.log(agencyVerifiers);
    console.log(agencyEncoders);
    console.log(agencyHead);
    console.log(userEmail); */ 
    //test
    switch (actionFlag) {
      case WorklistActionFlagPBRISRegulation.SUBMIT_DRAFT: //for revision na isusubmit
        await this.emailServiceHandler(userEmail, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_ENCODER_FORWARD_REGULATION_TO_VERIFIER, data, footer);
        await this.emailServiceHandler(agencyVerifiers, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_VERIFIER_RECIEVES_REGULATION_FOR_VERIFICATION, data, footer);
        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_AGENCY_APPR: //submit to agency head for approval
        if (reg_status == "FOR REVISION") {

        }
        await this.emailServiceHandler(userEmail, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_VERIFIER_SUBMITS_REGULATION_FOR_APPROVAL, data, footer);
        await this.emailServiceHandler(agencyHead, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_HEAD_RECEIVES_REGULATION_FOR_APPROVAL, data, footer);

        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA:

        break;
      case WorklistActionFlagPBRISRegulation.RE_SUBMIT_TO_AGENCY_APPR:

        break;
      case WorklistActionFlagPBRISRegulation.RE_SUBMIT_TO_ARTA:
        //self
        await this.emailServiceHandler(userEmail, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_HEAD_SUBMITS_TO_ARTA, data, footer);
        break;
      case WorklistActionFlagPBRISRegulation.RETURN_FROM_AGENCY_APPR: //for revision
      case WorklistActionFlagPBRISRegulation.RETURN_FROM_AGENCY_QA:
        //self
        await this.emailServiceHandler(userEmail, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_VALIDATOR_INDICATED_REGULATION_REVISION, data, footer);
        //encoders
        await this.emailServiceHandler(agencyEncoders, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_ENCODER_RECEIVES_REGULATION_FOR_REVISION, data, footer);
        break;
      case WorklistActionFlagPBRISRegulation.RETURN_FROM_ARTA:

        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_BRO_DC:

        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_BRO_DIR:

        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_DDG:

        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_DG:

        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_FOR_POSTING:

        break;
      case WorklistActionFlagPBRISRegulation.POST_REGULATION:
        // TODO: Update Service Status to POSTED for List of Service retrieval
        //self
        await this.emailServiceHandler(userEmail, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_HEAD_POSTS_REG, data, footer);
        // encoder
        await this.emailServiceHandler(agencyEncoders, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_HEAD_POSTS_REG, data, footer);
        break;
    }
  }
}

