<div class="content col-md-12">
    <div class="container-fluid">
        <div class="row">
            <div class="card padded">

                <!-- Regulatory notification Form -->
                <div class="card-header info-header">
                    <div class="row">
                        <div class="ms-3">
                            <h6 class="fw-bold">{{baseCode}} Regulation Details</h6>
                        </div>
                    </div>
                </div>

                <div class="card-body form-container">
                    <div class="row">
                        <form class="col">

                            <!-- Regulation Details-->
                            <div class="row table-responsive">
                                <table class="table table-striped table-bordered">
                                    <thead class="info-subheader">
                                        <tr>
                                        </tr>
                                    </thead>
    
                                    <tbody class="info-subheader">
                                        <tr>
                                            <th colspan="2">Regulation Title</th>
                                        </tr>
                                        <tr>
                                            <td colspan="2"><textarea class="form-control" rows="2" [value]="regulationsData.title ? regulationsData.title : '--'" disabled readonly></textarea></td>
                                        </tr>

                                        <tr>
                                            <th colspan="2">Regulation Short Title</th>
                                        </tr>
                                        <tr>
                                            <td colspan="2"><textarea class="form-control" [value]="regulationsData.subject ? regulationsData.subject : '--'" disabled readonly></textarea></td>
                                        </tr>

                                        <tr>
                                            <th class="col-md-3">Regulation Number:</th>
                                            <td><input type="text" class="form-control" [value]="regulationsData.reg_number ? regulationsData.reg_number : '--'" disabled readonly/></td>
                                        </tr>
                                        <tr>
                                            <th class="col-md-3">Regulation Instrument:</th>
                                            <td><input type="text" class="form-control" [value]="regulationsData.reg_instrument_name ? regulationsData.reg_instrument_name : '--'"  disabled readonly/></td>
                                        </tr>
                                        <tr>
                                            <th class="col-md-3">Issuing Agency:</th>
                                            <td>
                                                <ul *ngIf="regulationsData.agency_item">
                                                    <!-- <ng-container *ngIf="agencyOption == 'single'">
                                                        <li>
                                                            {{regulationsData.agency_item}}
                                                        </li>
                                                    </ng-container>
                                                    <ng-container *ngIf="agencyOption == 'multi'">
                                                        <li *ngFor="let row of regulationsData.agency_item">
                                                            {{row}}
                                                        </li>
                                                    </ng-container> -->
                                                    <li *ngFor="let row of regulationsData.agency_item">
                                                        {{row}}
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                    
                                </table>
                            </div>

                        </form>
                    </div>
                </div>

                <!-- Review of Tags -->
                <div class="card-header info-header">
                    <div class="row">
                        <div class="ms-3">
                            <h6 class="fw-bold">Review of Tags</h6>
                        </div>
                    </div>
                </div>
               
                <div class="card-body form-container">
                    <div class="row">
                        <form class="col">

                            <!-- Main Tag-->
                            <div class="row table-responsive">
                                <table class="table table-striped table-bordered">
                                    <thead class="info-subheader">
                                        <tr></tr>
                                    </thead>

                                    <tbody class="info-subheader">
                                        <tr><th colspan="2">Main Tag</th></tr>
                                        <tr>
                                            <td class="col-md-3">Classification:</td>
                                            <td>
                                                <div class="form-check">
                                                    <mat-radio-group class="example-radio-group d-flex flex-row justify-content-evenly">
                                                        <mat-radio-button [checked]="regulationsData.reg_tag_classification == 'Business'"
                                                        value="Business"
                                                        class="example-radio-button"
                                                        disabled readonly>Business</mat-radio-button>
                            
                                                    <mat-radio-button [checked]="regulationsData.reg_tag_classification == 'Non-Business'"
                                                    value="Non-Business"
                                                    class="example-radio-button"
                                                    disabled readonly>Non-Business</mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                        </td>
                                    </tbody>

                                    <tbody class="info-subheader" *ngIf=" regulationsData.reg_tag_classification ==  'Business'">
                                        <tr>
                                            <td>Sector:</td>
                                            <td><input class="form-control" [value]="regulationsData.reg_tag_division ? regulationsData.reg_tag_division : '--'" disabled readonly/></td>
                                        </tr>
                                        <tr>
                                            <td>Division:</td>
                                            <td><textarea class="form-control" [value]="regulationsData.reg_tag_sector ? regulationsData.reg_tag_sector : '--'" disabled readonly></textarea></td>
                                        </tr>

                                        <tr>
                                            <td>Stage of Business:</td>
                                            <td><input class="form-control" [value]="regulationsData.reg_tag_stage_of_business ? regulationsData.reg_tag_stage_of_business : '--'" disabled readonly/></td>
                                        </tr>
                                        <tr>
                                            <td>Case Use:</td>
                                            <td><input class="form-control" [value]="regulationsData.reg_tag_case_use ? regulationsData.reg_tag_case_use : '--'" disabled readonly/></td>
                                        </tr>
                                    </tbody>

                                    <tbody class="info-subheader" *ngIf="regulationsData.reg_tag_classification == 'Non-Business'">
                                        <tr>
                                            <td>Stage of Life:</td>
                                            <td><input type="text" class="form-control" [value]="regulationsData.reg_tag_stage_of_life ? regulationsData.reg_tag_stage_of_life : '--'" disabled readonly/></td>
                                        </tr>
                                        <tr>
                                            <td>Life Event:</td>
                                            <td><input type="text" class="form-control read-only" [value]="regulationsData.reg_tag_life_event ? regulationsData.reg_tag_life_event : '--'" disabled readonly/></td>
                                        </tr>
                                    </tbody>

                                    <tbody class="info-subheader">
                                        <tr>
                                            <td>Jurisdiction:</td>
                                            <td><input type="text" class="form-control" [value]="regulationsData.reg_tag_jurisdiction ? regulationsData.reg_tag_jurisdiction : '--'" disabled readonly/></td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>

                            <!-- Sub Tags-->
                            <div class="row table-responsive" *ngIf="regulationsData.has_sub_tags">
                                <table class="table table-striped table-bordered" *ngFor="let subTag of regulationsData.sub_tags; let i = index; let c = count">
                                    <thead class="info-subheader">
                                        <tr></tr>
                                    </thead>
                                            
                                    <tbody class="info-subheader">
                                        <tr><th colspan="2">Sub Tag/s</th></tr>

                                        <tr>
                                            <td class="col-md-3">Classification:</td>
                                            <ng-container *ngIf="regulationsData.has_sub_tags">
                                                <ng-container *ngFor="let subTag of regulationsData.sub_tags; let i = index; let c = count">
                                                    <td>
                                                        <div class="form-check">
                                                            <mat-radio-group class="example-radio-group d-flex flex-row  justify-content-evenly">
                                                                <mat-radio-button [checked]="subTag.reg_tag_classification == 'Business'"
                                                                value="Business"
                                                                class="example-radio-button"
                                                                disabled readonly>Business</mat-radio-button>
                                    
                                                                <mat-radio-button [checked]="subTag.reg_tag_classification == 'Non-Business'"
                                                                value="Non-Business"
                                                                class="example-radio-button"
                                                                disabled readonly>Non-Business</mat-radio-button>
                                                            </mat-radio-group>
                                                        </div>
                                                    </td>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                    </tbody>

                                    <tbody class="info-subheader" *ngIf=" subTag.reg_tag_classification ==  'Business'">
                                        <tr>
                                            <td>Sector:</td>
                                            <td><textarea class="form-control"  [value]="subTag.reg_tag_sector ? subTag.reg_tag_sector : '--'" disabled readonly></textarea></td>
                                        </tr>
                                        <tr>
                                            <td>Division:</td>
                                            <td><textarea class="form-control" [value]="subTag.reg_tag_division ? subTag.reg_tag_division : '--'" disabled readonly></textarea></td>
                                        </tr>

                                        <tr>
                                            <td>Stage of Business:</td>
                                            <td><input class="form-control" [value]="subTag.reg_tag_stage_of_business ? subTag.reg_tag_stage_of_business : '--'" disabled readonly/></td>
                                        </tr>
                                        <tr>
                                            <td>Case Use:</td>
                                            <td><input class="form-control" [value]="subTag.reg_tag_case_use ? subTag.reg_tag_case_use : '--'" disabled readonly/></td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>

                            <!-- Legal Bases & Intl Agreements -->
                            <div class="row table-responsive">
                                <table class="table table-striped table-bordered">
                                    <thead class="info-subheader">
                                        <tr></tr>
                                    </thead>

                                    <tbody class="info-subheader">
                                        <tr><th>Legal Bases</th></tr>
                                        <tr>
                                            <td>
                                                <ul *ngIf="regulationsData.reg_tag_legal">
                                                    <li *ngFor="let row of regulationsData.reg_tag_legal">
                                                        {{row}}
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>

                                        <tr><th>International Agreements</th></tr>
                                        <tr>
                                            <td>
                                                <ul *ngIf="regulationsData.reg_tag_intl">
                                                    <li *ngFor="let row of regulationsData.reg_tag_intl">
                                                        {{row}}
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>

                        </form>
                    </div>
                </div>
            
            </div>
        </div>
    </div>
</div>
