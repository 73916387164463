import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-existing-citizens-charter-report',
  templateUrl: './existing-citizens-charter-report.component.html',
  styleUrls: ['./existing-citizens-charter-report.component.scss']
})
export class ExistingCitizensCharterReportComponent implements OnInit {
  cc_count: any =[];
  phase1:boolean = false;
  phase2:boolean = false;
  phase: any;

  //COUNT PER STATUS
  draft_count: any = [];
  validation_count: any =[];  
  arta_review_count: any =[];
  modification_count: any =[];
  compliance_rate: any;

  //COUNT PER CATEGORY
  nat_government_count: any = [];
  gocc_count: any = [];
  
  /* LGUs */
  local_gov_count: any = [];
  province_count: any = [];
  city_count: any = [];
  municipality_count: any = [];
  barangay_count: any = [];

  /* SUC */
  stateuni_college_count:any =[];
  water_district_count:any =[];
  hospitals_count:any =[]





  //COUNT PER EFFECTIVITY <--- no data yet

  dateInfo: any;

  constructor(private afs: AngularFirestore, public auth: AngularFireAuth) { }

  ngOnInit(): void {
    
  } 
  
  receivedDatePicker(p:any){
    this.dateInfo = p;
  }

  activatePhase(phaseType:number){
    if(phaseType === 1){
      this.phase1 = true
      this.phase2 = false

      this.loadCounts("phase"+ phaseType);

      //console.log("phase"+ phaseType)
    }
    else if(phaseType === 2){
      this.phase2 = true
      this.phase1 = false

      this.loadCounts("phase" + phaseType);

      //console.log("phase"+ phaseType);
    }
    else{
      this.phase1 = false
      this.phase2 = false
    }
  }


  async loadCounts(phaseType:string) {
    this.phase = phaseType.toUpperCase()
    this.cc_count = [];
    this.draft_count = []  
    this.validation_count = []
    this.arta_review_count = [] 
    this.modification_count = []
    
  
    let collect:any;

    if(phaseType == "phase1")
    {
      this.phase = "Phase 1"
      collect = "citizens-charter-phase-01"

      this.generatePhase1(collect)
    }

    else if(phaseType == "phase2")
    {
      this.phase = "Phase 2"
      collect = "citizens-charter-phase-02"

      this.generatePhase2(collect)
    }

    else{
      this.phase = "Select Phase"
      collect = ''
    }
  
  }
  
  generatePhase1(collect:any){
    this.nat_government_count = [];
    this.gocc_count= [];
  
  /* LGUs */
  this.local_gov_count = [];
  this.province_count = [];
  this.city_count = [];
  this.municipality_count= [];
  this.barangay_count = [];

  /* SUC */
  this.stateuni_college_count =[];
  this.water_district_count =[];
  this.hospitals_count =[]

    this.afs.collection(collect).snapshotChanges()
      .pipe(
        tap(
          (data: any) => {
            data.forEach((info: any) => {
              let item: any = info.payload.doc.data();
              item.id = info.payload.doc.id;
              
              this.cc_count.push(item);
              /**
               * load un-posted services for editing & approving
               */
              if(item['status'] !== undefined){
                if(item['status'].toUpperCase()  === "DRAFT"){
                
                  this.draft_count.push(item)
                }
                 
                else if(item['status'].toUpperCase()  === "FOR VERIFICATION"){
                  this.validation_count.push(item)
                }
                else if(item['status'].toUpperCase()  === "FOR ARTA REVIEW"){
                   
                  this.arta_review_count.push(item)
                }
                else if(item['status'].toUpperCase()  === "FOR MODIFICATION"){
                   
                  this.modification_count.push(item)
                }
              }


              if(item['organization_grouping_01'] !== undefined){
                if(item['organization_grouping_01'].toUpperCase() == "EXECUTIVE"){
                  this.nat_government_count.push(item)
                }
                else if(item['organization_grouping_01'].toUpperCase() == "GOCC"){
                  this.gocc_count.push(item)
                }
                else if(item['organization_grouping_01'].toUpperCase() == "LGU"){
                  this.local_gov_count.push(item)
                }
                else if(item['organization_grouping_01'].toUpperCase() == "SUC"){
                  this.stateuni_college_count.push(item)
                }
                else if(item['organization_grouping_01'].toUpperCase() =="WATER DISTRICT"){
                  this.water_district_count.push(item)
                }
                else if(item['organization_grouping_01'].toUpperCase() == "HOSPITALS"){
                  this.hospitals_count.push(item)
                }
              }
            
            });
          }
        )
      )
      .subscribe()
  }

  generatePhase2(collect:any){
    this.nat_government_count = [];
    this.gocc_count= [];
  
  /* LGUs */
  this.local_gov_count = [];
  this.province_count = [];
  this.city_count = [];
  this.municipality_count= [];
  this.barangay_count = [];

  /* SUC */
  this.stateuni_college_count =[];
  this.water_district_count =[];
  this.hospitals_count =[]
    this.afs.collection(collect).snapshotChanges()
      .pipe(
        tap(
          (data: any) => {
            data.forEach((info: any) => {
              let item: any = info.payload.doc.data();
              item.id = info.payload.doc.id;
              
              this.cc_count.push(item);
              /**
               * load un-posted services for editing & approving
               */
               if(item['status']){
                if(item['status'].toUpperCase()  == "DRAFT"){
                
                  this.draft_count.push(item)
                }
                 
                else if(item['status'].toUpperCase()  == "FOR VERIFICATION"){
                  this.validation_count.push(item)
                }
                else if(item['status'].toUpperCase()  == "FOR ARTA REVIEW"){
                   
                  this.arta_review_count.push(item)
                }
                else if(item['status'].toUpperCase()  == "FOR MODIFICATION"){
                   
                  this.modification_count.push(item)
                }
               }


              if(item['organization_level_00'] !== undefined){
                if(item['organization_level_00'].toUpperCase() === "EXECUTIVE"){
                  this.nat_government_count.push(item)
                }
                else if(item['organization_level_00'].toUpperCase() ==="GOCC"){
                  this.gocc_count.push(item)
                }
                else if(item['organization_level_00'].toUpperCase() === "LGU"){
                  this.local_gov_count.push(item)
                }
                else if(item['organization_level_00'].toUpperCase() === "SUC"){
                  this.stateuni_college_count.push(item)
                }
                else if(item['organization_level_00'].toUpperCase() === "WATER DISTRICTS"){
                  this.water_district_count.push(item)
                }
                else if(item['organization_level_00'].toUpperCase() === "HOSPITALS"){
                  this.hospitals_count.push(item)
                }
              }
            
            });
          }
        )
      )
      .subscribe()
  }

}
