import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ModalManagerComponent } from 'src/app/modal-manager/modal-manager.component'

@Component({
  selector: 'app-artemis-home-page-announcements',
  templateUrl: './artemis-home-page-announcements.component.html',
  styleUrls: ['./artemis-home-page-announcements.component.scss']
})
export class ArtemisHomePageAnnouncementsComponent implements OnInit {
  modalRef?: MDBModalRef;

  list_of_announcements: any;

  constructor(private afs: AngularFirestore, public modalService: MDBModalService) { }

  ngOnInit(): void {
    this.list_of_announcements = [];
    this.getFirestoreJSON();
  }

  callModal(title: string, content: string){
    this.modalRef = this.modalService.show(ModalManagerComponent,
      { backdrop: 'static',
        data:{
          header_content: {
            title: title
          },
          body_content: {
            message: content
          },
          buttons: {
            cancel: "Close"
          },
        }
      });
  }

  async getFirestoreJSON(){
    this.afs.collection(`Announcements`, filter => filter.where('tag', 'in', ['common', 'artemis']).orderBy('date', 'desc').limit(5)).snapshotChanges().subscribe(
      (data: any) => {
        this.list_of_announcements = []
          data.forEach((info: any) => {
            
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.list_of_announcements.includes(item) === false){
              this.list_of_announcements.push(item);
            }     
          });
          
      });
  }

}
