
<div class = "container bg-light m-3">
    <!-- <h4>Quick Links</h4>
    
    <div class="col">
        <button class = "btn btn-lg btn-outline-dark m-3" (click)="toggle_link()">{{link_toggle_status}} Quick Links</button>
    <ng-container *ngIf="show_links">
        <a class = "btn btn-lg btn-link m-3">Quick Link 1</a>
    <a class = "btn btn-lg btn-link m-3">Quick Link 2</a>
    <a class = "btn btn-lg btn-link m-3">Quick Link 3</a>
    <a class = "btn btn-lg btn-link m-3">Quick Link 4</a>

    <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'executive'}" class="list-group-item align-self-center">
        <img src="../../assets/artemis_landing/nga.png" class="img-fluid m-0 align-self-center">
    </a>
    </ng-container>
    </div> -->
    
</div>