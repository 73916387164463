<div class="main-side-nav pb-5">
    <!-- <app-wel-navbar></app-wel-navbar> -->
    <app-artemis-navigation-header *ngIf="currentSystem == artemis"></app-artemis-navigation-header>
    <app-navbar *ngIf="currentSystem == pbris"></app-navbar>

    <div *ngIf="isAdmin" class="container-fluid">
        <div class="row">
            <div class="col-3 no-pad">
                <div class="card shadow m-5 mt-0">
                    <ul>
                        <li class="nav-items">
                            <a class="row d-flex justify-content-center align-items-center w-100" 
                            [ngClass]="pageTitle == ''? 'nav-active':''"
                            (click)="setPage('', '')"
                            style="cursor: auto;">
                                <span class="col-2 d-flex justify-content-center align-items-center"><i class="fas fa-users-cog"></i></span>
                                <span class="col-10 no-pad"><p> Admin Control</p></span>
                            </a>
                        </li>
                        <li class="nav-items">
                            
                            <a class="row d-flex justify-content-center align-items-centerw-100"
                            [ngClass]="pageTitle == 'User Accounts'? 'nav-active':''"
                            (click)="setPage('User Accounts', 'Common')" >
                                <span class="col-2 d-flex justify-content-center align-items-center"><i class="far fa-user"></i></span>
                                <span class="col-10 no-pad"><p> Users</p></span>
                            </a>

                        </li>

                        <li class=" nav-divider">
                            <a style="cursor: auto;">
                                <span>Registration Agreements</span>
                            </a>
                        </li>
                        
                        <li class="nav-items">
                            
                            <a class="row d-flex justify-content-center align-items-centerw-100"
                            [ngClass]="pageTitle == 'PBRIS: Terms, Conditions, & Privacy Policies'? 'nav-active':''"
                            (click)="setPage('PBRIS: Terms, Conditions, & Privacy Policies','PBRIS')">
                                <span class="col-2 d-flex justify-content-center align-items-center"><i class="fas fa-file-signature"></i></span>
                                <span class="col-10 no-pad"><p> ARTEMIS Registration Contracts</p></span>
                            </a>

                        </li>
                        <li class="nav-items">
                            
                            <a class="row d-flex justify-content-center align-items-centerw-100"
                            [ngClass]="pageTitle == 'ARTEMIS: Terms, Conditions, & Privacy Policies'? 'nav-active':''"
                            (click)="setPage('ARTEMIS: Terms, Conditions, & Privacy Policies','ARTEMIS')"  >
                                <span class="col-2 d-flex justify-content-center align-items-center"><i class="fas fa-file-signature"></i></span>
                                <span class="col-10 no-pad"><p> PBRIS Registration Contracts</p></span>
                            </a>

                        </li>

                        <li class=" nav-divider">
                            <a style="cursor: auto;">
                                <span>References</span>
                            </a>
                        </li>
                        <li class="nav-items">
                            
                            <a class="row d-flex justify-content-center align-items-centerw-100"
                            [ngClass]="pageTitle == 'Common List of Values'? 'nav-active':''"
                            (click)="setPage('Common List of Values')">
                                <span class="col-2 d-flex justify-content-center align-items-center"><i class="fas fa-th-list"></i></span>
                                <span class="col-10 no-pad"><p> Common: List of Values</p></span>
                            </a>

                        </li>
                        <li class="nav-items">
                            
                            <a class="row d-flex justify-content-center align-items-centerw-100"
                            [ngClass]="pageTitle == 'PBRIS List of Values'? 'nav-active':''"
                            (click)="setPage('PBRIS List of Values','PBRIS')">
                                <span class="col-2 d-flex justify-content-center align-items-center"><i class="far fa-list-alt"></i></span>
                                <span class="col-10 no-pad"><p> ARTEMIS: List of Values</p></span>
                            </a>

                        </li>
                        
                        <li class="nav-items">
                            
                            <a class="row d-flex justify-content-center align-items-centerw-100"
                            [ngClass]="pageTitle == 'ARTEMIS List of Values'? 'nav-active':''"
                            (click)="setPage('ARTEMIS List of Values','ARTEMIS')">
                                <span class="col-2 d-flex justify-content-center align-items-center"><i class="far fa-list-alt"></i></span>
                                <span class="col-10 no-pad"><p> PBRIS: List of Values</p></span>
                            </a>

                        </li>

                        
                        <li class=" nav-divider">
                            <a style="cursor: auto;">
                                <span>PBRIS Dashboard</span>
                            </a>
                        </li>
                        <li class="nav-items">
                            
                            <a class="row d-flex justify-content-center align-items-centerw-100"
                            [ngClass]="pageTitle == 'Essential Links'? 'nav-active':''"
                            (click)="setPage('Essential Links','PBRIS')">
                                <span class="col-2 d-flex justify-content-center align-items-center"><i class="fas fa-link"></i></span>
                                <span class="col-10 no-pad"><p> Essential Links</p></span>
                            </a>

                        </li>
                        <li class="nav-items">
                            
                            <a class="row d-flex justify-content-center align-items-centerw-100"
                            [ngClass]="pageTitle == 'Announcements'? 'nav-active':''"
                            (click)="setPage('Announcements','PBRIS')">
                                <span class="col-2 d-flex justify-content-center align-items-center"><i class="fas fa-bullhorn"></i></span>
                                <span class="col-10 no-pad"><p> Announcements</p></span>
                            </a>

                        </li>


                    </ul>
                    <!-- <div class="card-head text-center mt-5">
                        <h1>Admin Controls</h1>
                    </div>
                    <div class="card-body">
                        <div class="m-3">
                            <button (click)="setPage('User Accounts', 'Common')" class="btn btn-outline-dark m-1 w-100">Users</button>
                        </div>
                        <div class="m-3">
                            <label class="text-center w-100">Registration Agreements</label>
                            <button (click)="setPage('PBRIS: Terms, Conditions, & Privacy Policies','PBRIS')" class="btn btn-outline-dark m-1 w-100">PBRIS Registration Contracts</button>
                            <button (click)="setPage('ARTEMIS: Terms, Conditions, & Privacy Policies','ARTEMIS')" class="btn btn-outline-dark m-1 w-100">ARTEMIS Registration Contracts</button>
                        </div>
                        <div class="m-3">
                            <label class="text-center w-100">References</label>
                            <button (click)="setPage('Common List of Values')" class="btn btn-outline-dark m-1 w-100">Common: List of Values</button>
                            <button (click)="setPage('PBRIS List of Values','PBRIS')" class="btn btn-outline-dark m-1 w-100">PBRIS: List of Values</button>
                            <button (click)="setPage('ARTEMIS List of Values','ARTEMIS')" class="btn btn-outline-dark m-1 w-100">ARTEMIS: List of Values</button>
                        </div>
                        <div class="m-3">
                            <label class="text-center w-100">PBRIS Dashboard</label>
                            <button (click)="setPage('Essential Links','PBRIS')" class="btn btn-outline-dark m-1 w-100">Essential Links</button>
                            <button (click)="setPage('Announcements','PBRIS')" class="btn btn-outline-dark m-1 w-100">Announcements</button>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-xl-9">
                <div *ngIf="pageTitle" class="card shadow ms-0">
                    <div class="card-head text-center mt-5">
                        <h1>{{pageTitle}}</h1>
                    </div>
                    <div class="card-body">
                        <app-crud-users [PBRISorARTEMIS]="'Common'" *ngIf="pageTitle == 'User Accounts'"></app-crud-users>
                        <app-registration-agreements [PBRISorARTEMIS]="'PBRIS'" *ngIf="pageTitle == 'PBRIS: Terms, Conditions, & Privacy Policies'"></app-registration-agreements>
                        <app-registration-agreements [PBRISorARTEMIS]="'ARTEMIS'" *ngIf="pageTitle == 'ARTEMIS: Terms, Conditions, & Privacy Policies'"></app-registration-agreements>
                        <app-pbris-references [PBRISorARTEMIS]="'Common'" *ngIf="pageTitle == 'Common List of Values'"></app-pbris-references>
                        <app-pbris-references [PBRISorARTEMIS]="'PBRIS'" *ngIf="pageTitle == 'PBRIS List of Values'"></app-pbris-references>
                        <app-crud-lov [PBRISorARTEMIS]="'ARTEMIS'" *ngIf="pageTitle == 'ARTEMIS List of Values'"></app-crud-lov>
                        <app-essential-links [PBRISorARTEMIS]="'Common'" *ngIf="pageTitle == 'Essential Links'"></app-essential-links>
                        <app-announcements [PBRISorARTEMIS]="'Common'" *ngIf="pageTitle == 'Announcements'"></app-announcements>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>