<div class="content">
  <div class="container-fluid">

    <div class="row">
      <div class="card padded">
        <div class="card-header info-header">
          <div class="row">
            <div class="ms-3">
              <h6 class="fw-bold">PIS ASSESSMENT SUMMARY</h6>
            </div>
          </div>
        </div>

        <!-- Summary of Regulation Details -->
        <div class="card-body">
          <div class="row">

            <div class="card-body info-header" style="background-color:#f2f2f2;border-top:1px solid #FCD116;">
              <div class="row">
                <div class="ms-3">
                  <h6 class="float-start">Regulation Details</h6>
                </div>
              </div>
            </div>
            
            <div class="col">
              <div class="row">
                <table mat-table [dataSource]="pis_assessment_summary['tag_assessment']" class="mat-elevation-z5 demo-table">
                  <ng-container matColumnDef="section">
                    <th class="col-md-5 theme-default" mat-header-cell *matHeaderCellDef>
                      Section
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.section }}
                    </td>
                  </ng-container>
          
                  <ng-container matColumnDef="assessment">
                    <th class="col-md-4 theme-default" mat-header-cell *matHeaderCellDef>
                      Assessment
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.assessment }}
                    </td>
                  </ng-container>
          
                  <ng-container matColumnDef="remarks">
                    <th class="col-md-3 theme-default" mat-header-cell *matHeaderCellDef>
                      Remarks
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <button class="w-100 btn btn-outline-primary btn-custom btn-sm" (click)="checkRemarks(element)">
                        ({{element.previous_remarks ? element.previous_remarks.length : '0'}})
                        <i class="far fa-comments"></i> Remarks
                      </button>
                    </td>
                  </ng-container>
          
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsFinalizePis"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsFinalizePis"></tr>
                </table>
              </div>
            </div>

          </div>
        </div>

        <!-- Summary of PIA -->
        <div class="card-body">
          <div class="row">

            <div class="card-body info-header" style="background-color:#f2f2f2;border-top:1px solid #FCD116;">
              <div class="row">
                <div class="ms-3">
                  <h6 class="float-start">Preliminary Impact Assessment (PIA)</h6>
                </div>
              </div>
            </div>
            
            <div class="col">
              <div class="row">
                <table mat-table [dataSource]="pis_assessment_summary['pis_assessment']" class="mat-elevation-z5 demo-table">
                  <ng-container matColumnDef="section">
                    <th class="col-md-5 theme-default" mat-header-cell *matHeaderCellDef>
                      Section
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <ng-container *ngIf="element.url; else noURL">
                        <a [href]="element.url" target="_blank">
                          {{ element.section }}
                        </a>
                      </ng-container>
                      <ng-template #noURL>
                        {{ element.section }}
                      </ng-template>
                    </td>
                  </ng-container>
          
                  <ng-container matColumnDef="assessment">
                    <th class="col-md-4 theme-default" mat-header-cell *matHeaderCellDef>
                      Assessment
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.assessment }}
                    </td>
                  </ng-container>
          
                  <ng-container matColumnDef="remarks">
                    <th class="col-md-3 theme-default" mat-header-cell *matHeaderCellDef>
                      Remarks
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <button class="w-100 btn btn-outline-primary btn-custom btn-sm" (click)="checkRemarks(element)">
                        ({{element.previous_remarks ? element.previous_remarks.length : '0'}})
                        <i class="far fa-comments"></i> Remarks
                      </button>
                    </td>
                  </ng-container>
          
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsFinalizePis"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsFinalizePis"></tr>
                </table>
              </div>
            </div>
          
          </div>
        </div>

        <!-- Summary of Proportionality Assessment -->
        <div class="card-body">
          <div class="row">

            <div class="card-body info-header" style="background-color:#f2f2f2;border-top:1px solid #FCD116;">
              <div class="row">
                <div class="ms-3">
                  <h6 class="float-start">Proportionality Assessment</h6>
                </div>
              </div>
            </div>
            
            <div class="col">
              <div class="row">
                <table mat-table [dataSource]="pis_assessment_summary['proportionality_assessment']" class="mat-elevation-z5 demo-table">
                  <ng-container matColumnDef="section">
                    <th class="col-md-5 theme-default" mat-header-cell *matHeaderCellDef>
                      Section
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.section }}
                    </td>
                  </ng-container>
          
                  <ng-container matColumnDef="assessment">
                    <th class="col-md-4 theme-default" mat-header-cell *matHeaderCellDef>
                      Assessment
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.assessment }}
                    </td>
                  </ng-container>
          
                  <ng-container matColumnDef="remarks">
                    <th class="col-md-3 theme-default" mat-header-cell *matHeaderCellDef>
                      Remarks
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <button class="w-100 btn btn-outline-primary btn-custom btn-sm" (click)="checkRemarks(element)">
                        ({{element.previous_remarks ? element.previous_remarks.length : '0'}})
                        <i class="far fa-comments"></i> Remarks
                      </button>
                    </td>
                  </ng-container>
          
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsFinalizePis"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsFinalizePis"></tr>
                </table>
              </div>
            </div>
          
          </div>
        </div>

      </div>
    </div>

  </div>
</div>

<!-- File Uploads -->

<!-- Buttons -->
