
<div *ngIf="!loader">
  <!-- 700px -->
  <mat-sidenav-container hasBackdrop="false">
   <!-- <button mat-icon-button (click)="srvcInfo.toggle()" matTooltip="View Service Information" matTooltipPosition="below"> 
        <i class="fas fa-bars"></i>
    </button>-->
    <!--left-navigation - Scrollspy -->
    <mat-sidenav #srvcInfo style="width: 250px;" opened mode="side" fixedInViewport fixedTopGap="310">
       
      <div>
        <div style="border-bottom: 1px solid #E5E5E5;" class="pt-4">
                <span><h5 style="font-weight: 800; text-align: left; font-size: 16px; color: #2a2e34;" class="my-2 px-4" > Service Information</h5></span>
                <!--class="d-flex justify-content-center align-items-center p-0 m-0"
                    <span class="example-spacer"></span>
                <button mat-icon-button (click)="srvcInfo.toggle()" matTooltip="Close Service Information" matTooltipPosition="below"> 
                    <mat-icon>first_page</mat-icon>
                </button>-->
          
        </div>
        <div class="px-4 mt-2">
            <ol type="I" style="text-align: left;">
                <li>
                  <a (click)="scrollTo('mandate')">Mandate</a>  
                </li>
                <li> 
                  <a (click)="scrollTo('vision')">Vision</a>  
                </li>
                <li>
                  <a (click)="scrollTo('mission')">Mission</a>  
                </li>
                <li>
                  <a (click)="scrollTo('pledge')">Service Pledge</a>  
                </li>
                <!-- <li>
                  <a (click)="scrollTo('cart')">CART</a>  
                </li> -->
                <li>
                  <a (click)="scrollTo('listService')">List of Services</a>  
                </li>
              </ol>
        </div>
      </div>
    </mat-sidenav>

    <mat-card class="m-5">  
        <div *ngIf="currentCC; else noCC" class="p-2 m-5">
        
                <!-- image & title -->
        <section class="row justify-content-center" style="margin-bottom:3em;" >
            <ng-container *ngIf="currentAgency && (currentAgency.agencyDetails.logoURL)">
                <img style="width: 400px;" [src]="currentAgency.agencyDetails.logoURL">
            </ng-container>
            <div class="row" style="margin-top: 3em; margin-bottom: 3em;" *ngIf="currentCC && currentCC.fromAgencyName">
                <div class="col d-flex justify-content-center">
                    <label class="fw-bold">
                        <h1>
                            {{(currentCC.fromAgencyName)}}
                        </h1>
                        <p class="mb-0 text-center">Citizen's Charter {{ transform(currentCC.edition, currentCC.date_posted.toDate().getFullYear()) }}</p>
                    </label>
                </div>
            </div>
        </section>
        <!-- mandate -->
        <section style="margin-top: 3em; margin-bottom: 3em;">
            <div class="row">
                <div class="col fw-bold">
                    <!-- border-bottom border-2 border-dark -->
                    <div id="mandate"  class="row">
                        <div class="col-1">
                            <h3>I.</h3>
                        </div>
                        <div class="col">
                            <label>
                                <h3>Mandate</h3>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 1em;" *ngIf="currentCC && currentCC.agency_profile && currentCC.agency_profile.mandate; else noMandate">
                <div class="col">
                    <p [innerHTML]="currentCC.agency_profile.mandate"></p>
                </div>
            </div>
            <ng-template #noMandate>
                <div class="row" style="margin-top: 1em;">
                    <div class="col">
                        <p>No Data</p>
                    </div>
                </div>
            </ng-template>
        </section>

        <!-- vision -->
        <section style="margin-top: 3em; margin-bottom: 3em;">
            <div class="row">
                <div class="col fw-bold">
                    <!-- border-bottom border-2 border-dark -->
                    <div id="vision" class="row">
                        <div class="col-1">
                            <h3>II.</h3>
                        </div>
                        <div class="col">
                            <label>
                                <h3>Vision</h3>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 1em;" *ngIf="currentCC && currentCC.agency_profile && currentCC.agency_profile.vision; else noVision">
                <div class="col">
                    <p [innerHTML]="currentCC.agency_profile.vision"></p>
                </div>
            </div>
            <ng-template #noVision>
                <div class="row" style="margin-top: 1em;">
                    <div class="col">
                        <p>No Data</p>
                    </div>
                </div>
            </ng-template>
        </section>
        
        <!-- mission -->
        <section  style="margin-top: 3em; margin-bottom: 3em;">
            <div class="row">
                <div class="col fw-bold">
                    <!-- border-bottom border-2 border-dark -->
                    <div id="mission" class="row">
                        <div class="col-1">
                            <h3>III.</h3>
                        </div>
                        <div class="col">
                            <label>
                                <h3>Mission</h3>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 1em;" *ngIf="currentCC && currentCC.agency_profile && currentCC.agency_profile.mission; noMission">
                <div class="col">
                    <p [innerHTML]="currentCC.agency_profile.mission"></p>
                </div>
            </div>
            <ng-template #noMission>
                <div class="row" style="margin-top: 1em;">
                    <div class="col">
                        <p>No Data</p>
                    </div>
                </div>
            </ng-template>
        </section>

        <!-- pledge -->
        <section style="margin-top: 3em; margin-bottom: 3em;">
            <div class="row">
                <div class="col border-dark fw-bold">
                    <!-- border-bottom border-2 border-dark -->
                    <div id="pledge" class="row">
                        <div class="col-1">
                            <h3>IV.</h3>
                        </div>
                        <div class="col">
                            <label>
                                <h3>Service Pledge</h3>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 1em;" *ngIf="currentCC && currentCC.agency_profile && currentCC.agency_profile.service_pledge; noPledge">
                <div class="col">
                    <p [innerHTML]="currentCC.agency_profile.service_pledge"></p>
                </div>
            </div>
            <ng-template #noPledge>
                <div class="row" style="margin-top: 1em;">
                    <div class="col">
                        <p>No data</p>
                    </div>
                </div>
            </ng-template>
        </section>

       <!--   cart 
        <section class="row" style="margin-top: 5em; margin-bottom: 3em;">
            <div id="cart" class="row">
                <div class="col d-flex justify-content-center">
                    <label class="fw-bold">
                        <h2>Committee on Red Tape Authority</h2>
                    </label>
                </div>
            </div>

            HOA 
            <section class="row" style="margin-top: 2em; margin-bottom: 2em;">
                <div class="row">
                    <div class="col">
                        <label class="fw-bold">
                            <h3>Head of Agency :</h3>
                        </label>
                    </div>
                </div>
                <table class="table table-hover table-responsive">
                    <col style="width:34%"/>
                    <col style="width:33%"/>
                    <col style="width:33%"/>
                    <thead>
                        <tr>
                            <th>Head</th>
                            <th>Designation</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of headOfAgency">
                            <tr>
                                <th>
                                    {{item.name}}
                                </th>
                                <td>
                                    {{item.designation}}
                                </td>
                                <td>
                                    {{item.email}}
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="!headOfAgency || headOfAgency  .length == 0">
                                <tr>
                                    <th>
                                        No Data
                                    </th>
                                    <td>
                                        . . .
                                    </td>
                                    <td>
                                        . . .
                                    </td>
                                </tr>
                        </ng-container>
                    </tbody>
                </table>
            </section>
            
           chair
            <section class="row" style="margin-top: 2em; margin-bottom: 2em;">
                <div class="row">
                    <div class="col">
                        <label class="fw-bold">
                            <h3>Chairperson :</h3>
                        </label>
                    </div>
                </div>
                <table class="table table-hover table-responsive">
                    <col style="width:34%"/>
                    <col style="width:33%"/>
                    <col style="width:33%"/>
                    <thead>
                        <tr>
                            <th>Head</th>
                            <th>Designation</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of chairPerson">
                            <tr>
                                <th>
                                    {{item.name}}
                                </th>
                                <td>
                                    {{item.designation}}
                                </td>
                                <td>
                                    {{item.email}}
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="!chairPerson || chairPerson.length == 0">
                                <tr>
                                    <th>
                                        No Data
                                    </th>
                                    <td>
                                        . . .
                                    </td>
                                    <td>
                                        . . .
                                    </td>
                                </tr>
                        </ng-container>
                    </tbody>
                </table>
            </section>

        v-chair 
            <section class="row" style="margin-top: 2em; margin-bottom: 2em;">
                <div class="row">
                    <div class="col">
                        <label class="fw-bold">
                            <h3>Vice-Chairperson :</h3>
                        </label>
                    </div>
                </div>
                <table class="table table-hover table-responsive">
                    <col style="width:34%"/>
                    <col style="width:33%"/>
                    <col style="width:33%"/>
                    <thead>
                        <tr>
                            <th>Head</th>
                            <th>Designation</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of viceChairPerson">
                            <tr>
                                <th>
                                    {{item.name}}
                                </th>
                                <td>
                                    {{item.designation}}
                                </td>
                                <td>
                                    {{item.email}}
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="!viceChairPerson || viceChairPerson.length == 0">
                                <tr>
                                    <th>
                                        No Data
                                    </th>
                                    <td>
                                        . . .
                                    </td>
                                    <td>
                                        . . .
                                    </td>
                                </tr>
                        </ng-container>
                    </tbody>
                </table>
            </section>

      member
            <section class="row" style="margin-top: 2em; margin-bottom: 2em;">
                <div class="row">
                    <div class="col">
                        <label class="fw-bold">
                            <h3>Members :</h3>
                        </label>
                    </div>
                </div>
                <table class="table table-hover table-responsive">
                    <col style="width:34%"/>
                    <col style="width:33%"/>
                    <col style="width:33%"/>
                    <thead>
                        <tr>
                            <th>Head</th>
                            <th>Designation</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of member">
                            <tr>
                                <th>
                                    {{item.name}}
                                </th>
                                <td>
                                    {{item.designation}}
                                </td>
                                <td>
                                    {{item.email}}
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="!member || member.length == 0">
                                <tr>
                                    <th>
                                        No Data
                                    </th>
                                    <td>
                                        . . .
                                    </td>
                                    <td>
                                        . . .
                                    </td>
                                </tr>
                        </ng-container>
                    </tbody>
                </table>
            </section>
        </section> -->

        <!-- services -->
        <section class="row" style="margin-top: 5em; margin-bottom: 3em;">
            <!-- service list -->
            <div id="listService" class="row">
                <div class="col d-flex justify-content-center">
                    <label class="fw-bold">
                        <h2>List of Services</h2>
                    </label>
                </div>
            </div>
            <table class="table table-responsive">
                <tbody>
                    <h4 class="my-3 py-3">External Services</h4>
                    <ng-container  *ngFor="let owner of ExternalServiceProcessOwners; ">
                            <tr>
                                <td style="border: none; padding: 5px;">
                                   <app-citizens-charter-processowner-list [services] = "services" [processowner] = "owner"></app-citizens-charter-processowner-list> 
                                </td>
                            </tr>
                    </ng-container>
                 
                    <h4 class="my-3 py-3">Internal Services</h4>
                    <ng-container  *ngFor="let owner of InternalServiceProcessOwners; ">
                        <tr>
                            <td style="border: none; padding: 5px;">
                               <app-citizens-charter-processowner-list [services] = "services" [processowner] = "owner"></app-citizens-charter-processowner-list> 
                            </td>
                        </tr>
                    </ng-container>
                    <!-- <h4>External Services</h4>
                    <ng-container  *ngFor="let item of ExternalServices;let n = index">
                        
                        <tr  (click)="scrollTo(item.id)" style="cursor: pointer;" title="jump to {{item.serviceDetails.service_name}}">
                            <td>
                               {{n + 1}}.{{item.serviceDetails.service_name}}
                            </td>
                        </tr>
                    </ng-container>
                    <h4 class="mt-3">Internal Services</h4>
                    <ng-container *ngFor="let item of InternalServices;let n = index">
                        <tr  (click)="scrollTo(item.id)" style="cursor: pointer;" title="jump to {{item.serviceDetails.service_name}}">
                            <td>
                                {{(ExternalServices.length ) + (n + 1)}}.{{item.serviceDetails.service_name}}
                            </td>
                        </tr>
                    </ng-container> -->
                    <ng-container *ngIf="!services || services.length == 0">
                            <tr>
                                <td>
                                    No Data
                                </td>
                            </tr>
                    </ng-container>
                </tbody>
            </table>

            <section style="margin-top: 5em; margin-bottom: 3em;">
                <div class="d-flex justify-content-center">
                    <span ><label><h1>Service Details</h1></label></span>
                </div>
            </section>

            <!-- service details External -->

            <!-- <app-citizens-charter-service-list-view [services]="ExternalServices" [hasCounter]="true"></app-citizens-charter-service-list-view> -->
            <div>
                <h3 class="my-3 py-3" ><b>External Services</b></h3>
                <div *ngFor="let owner of ExternalServiceProcessOwners; ">
                    <app-citizens-charter-service-list-view [processowner] = "owner" [services]="services" ></app-citizens-charter-service-list-view>
                </div>
            </div>
             <!-- service details Internal -->

             <!-- <app-citizens-charter-service-list-view [lastCounter]="ExternalServices.length" [services]="InternalServices" [hasCounter]="true"></app-citizens-charter-service-list-view> -->
             <div>
                <h3 class="my-3 py-3">Internal Services</h3>
                <div *ngFor="let owner of InternalServiceProcessOwners; ">
                    <app-citizens-charter-service-list-view [processowner] = "owner" [services]="services"></app-citizens-charter-service-list-view>
                </div>
            </div>
        </section>
        
        </div>
    </mat-card>
    
  <ng-template #noCC>
      <div class="d-flex justify-content-center">
          No Data
      </div>
  </ng-template>
  </mat-sidenav-container>
</div>