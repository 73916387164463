<div class="container-fluid p-0">
  <div class="row g-0">
    <div class="col-12 py-3 theme-registration">
      <div class="form-container-header">
        <app-pbris-form-banner></app-pbris-form-banner>
      </div>
    </div>

    <div class="card form-column">
      <div class="form-container">
        <div class="card-header">
          <h5 class="text-uppercase fw-bolder m-0 p-3 text-center">
            TERMS, CONDITIONS, AND PRIVACY STATEMENT
          </h5>
        </div>

        <div class="card mb-5">
          <div class="card-body">
            <!-- Terms and Conditions -->
            <ng-container *ngIf="show_terms_and_conditions">
              <div class="form-group terms-conditions-container">
                <!--<app-terms-and-conditions-PBRIS></app-terms-and-conditions-PBRIS>-->
                <div class="d-flex justify-content-center align-self-center">
                  <div class="spinner-grow" role="status" [hidden]="terms_and_conditions_JSON.length > 0">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>

                <div class="termsandconditions" [hidden]="terms_and_conditions_JSON.length == 0">
                  <div *ngFor="let section of terms_and_conditions_JSON">
                    <p style="font-weight: bolder">{{ section.title }}</p>
                    <div [innerHtml]="section.description"></div>
                  </div>
                </div>

                <!--CHECKBOX-->
                <div class="form-check mx-auto py-4" [hidden]="terms_and_conditions_JSON.length == 0">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" (change)="checkbox($event)" ng-required="true"/>
                  <label class="form-check-label fw-bold" for="flexCheckDefault" style="color:red">
                    I consent to the terms and conditions in the use of the PBRIS
                  </label>
                </div>
              </div>
            </ng-container>

            <!-- Data Privacy Consent -->
            <ng-container *ngIf="show_data_privacy">
              <h5 class="text-uppercase fw-bolder m-0 p-3 text-center">
                CONSENT FORM
              </h5>
              <div class="form-group terms-conditions-container">
                <!--<app-terms-and-conditions-PBRIS></app-terms-and-conditions-PBRIS>-->
                <div class="termsandconditions" [hidden]="privacy_policy_JSON.length == 0">
                  <div *ngFor="let section of privacy_policy_JSON">
                    <p style="font-weight: bolder">{{ section.title }}</p>
                    <div [innerHtml]="section.description"></div>
                    <br />
                  </div>
                </div>

                <!--CHECKBOX-->
                <div class="form-check mx-auto py-4" [hidden]="privacy_policy_JSON.length == 0">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" (change)="checkbox($event)" ng-required="true" />
                  <label class="form-check-label fw-bold" for="flexCheckDefault" style="color:red;">
                    I consent to the processing of my personal information
                  </label>
                </div>
              </div>
            </ng-container>

            <!-- First Registration -->
            <ng-container *ngIf="show_first_registration">
              <!-- && accountType.selectedValue == 'Personal'-->
              <h5 class="text-center fw-bold pb-5">REGISTRATION</h5>
              <div class="form-group terms-conditions-container py-5">
                <!-- <p class="fw-bold border-bottom border-dark">
                  Log-In Credentials
                </p> -->
                <form [formGroup]="registrationform">
                  <!-- [formGroup]="form" (ngSubmit)="onSubmit()" -->
                  <div class="row">
                    <div class="form-group col-md-4">
                      <label class="form-input-header" for="email">EMAIL ADDRESS</label>
                      <input type="email" class="form-control" [class.bg-danger]="show_first_registration && showFormErrors && registrationform.controls.email.invalid"
                        placeholder="email address" formControlName="email" ng-required="true"
                      />
                      <div class="form-helper text-danger" *ngIf="show_first_registration && showFormErrors && registrationform.controls.email.hasError('required')">
                        Email Address is required
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label class="form-input-header" for="password">PASSWORD</label>
                      <input type="password" class="form-control" [class.bg-danger]="show_first_registration && showFormErrors && registrationform.controls.password.invalid" 
                        placeholder="password" formControlName="password" ng-required="true" #password 
                      />
                      <div class="form-helper text-danger" *ngIf="show_first_registration && showFormErrors && registrationform.controls.password.hasError('required')">
                        Password is required
                      </div>
                      <div class="form-helper text-danger" *ngIf="show_first_registration && showFormErrors && registrationform.controls.passwordStrength.hasError('needsStrongerPassword')">
                        Please make a stronger password
                      </div>
                      <mat-password-strength #passwordComponent (onStrengthChanged)="onStrengthChanged($event)" [password]="password.value"></mat-password-strength>
                      <div class="my-3">
                        <mat-password-strength-info [passwordComponent]="passwordComponent"></mat-password-strength-info>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label class="form-input-header" for="confirmpassword">CONFIRM PASSWORD</label>
                      <input type="password" class="form-control" [class.bg-danger]="show_first_registration && showFormErrors && registrationform.controls.confirmpassword.invalid"
                        placeholder="re-enter password" formControlName="confirmpassword" ng-required="true"
                      />
                      <div class="form-helper text-danger" *ngIf="show_first_registration && showFormErrors &&
                          (registrationform.controls.confirmpassword.hasError('required') || registrationform.controls.confirmpassword.hasError('matchingPassword'))">
                        Password does not match
                      </div>
                    </div>
                  </div>

                  <div class="row mt-4">
                    <div class="form-group col-md-6">
                      <label class="form-input-header" for="seconddropdowntitle">MOBILE NUMBER <small>(Two Factor Authentication)</small></label>
                      <input type="text" class="form-control" [class.bg-danger]="show_first_registration && showFormErrors && registrationform.controls.mobile_number.invalid"
                        placeholder="+63" formControlName="mobile_number" ng-required="true"
                      />
                      <div class="form-helper text-danger" *ngIf="show_first_registration && showFormErrors && registrationform.controls.mobile_number.hasError('required')">
                        Mobile Number is required
                      </div>
                      <div class="form-helper text-danger" *ngIf="show_first_registration && showFormErrors && registrationform.controls.mobile_number.hasError('invalidPhoneNumber')">
                        Invalid Mobile Number
                      </div>
                    </div>

                    <div class="form-group col-md-6">
                      <label class="form-input-header" for="seconddropdowntitle">TYPE OF ACCOUNT</label>
                      <div>
                        <select class="form-select" [class.bg-danger]="show_first_registration && showFormErrors && registrationform.controls.account_type.invalid"
                          formControlName="account_type" ng-required="true">
                          <option value="" disabled selected>
                            Personal/Government
                          </option>
                          <option *ngFor="let x of TypeOfAccount" [value]="x">
                            {{ x }}
                          </option>
                        </select>
                      </div>
                      <div class="form-helper text-danger" *ngIf="show_first_registration && showFormErrors && registrationform.controls.account_type.hasError('required')">
                        Type of Account is required
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </ng-container>

            <!-- Personal Details Registration -->
            <ng-container *ngIf="show_personal_registration">
              <h5 class="text-center fw-bold pb-5">
                <strong class="text-primary">PERSONAL ACCOUNT</strong>
                REGISTRATION
              </h5>
              
              <!-- <p class="fw-bold border-bottom border-dark">Personal Details</p> -->
              <form [formGroup]="personalregistrationform">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="lastname" class="form-input-header">LAST NAME</label>
                    <input type="text" class="form-control" [class.bg-danger]="show_personal_registration && showFormErrors && personalregistrationform.controls.last_name.invalid"
                      placeholder="" formControlName="last_name" ng-required="true"
                    />
                    <div class="form-helper text-danger" *ngIf="show_personal_registration && showFormErrors && personalregistrationform.controls.last_name.hasError('required')">
                      Last Name is required
                    </div>
                  </div>
                  
                  <div class="form-group col-md-6">
                    <label for="firstname" class="form-input-header">FIRST NAME</label>
                    <input type="text" class="form-control" [class.bg-danger]="show_personal_registration && showFormErrors && personalregistrationform.controls.first_name.invalid"
                      placeholder="" formControlName="first_name" ng-required="true"
                    />
                    <div class="form-helper text-danger"
                      *ngIf="show_personal_registration && showFormErrors && personalregistrationform.controls.first_name.hasError('required')">
                      First Name is required
                    </div>
                  </div>
                </div>

                <div class="row mt-5">
                  <div class="form-group col-md-6">
                    <label for="sex" class="form-input-header">SEX</label>
                    <div class="form-control" [class.bg-danger]="show_personal_registration && showFormErrors && personalregistrationform.controls.sex.invalid">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="sex" id="inlineRadio1" formControlName="sex" value="Male" />
                        <label class="form-check-label" for="inlineRadio1">Male</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="sex" id="inlineRadio2" formControlName="sex" value="Female" />
                        <label class="form-check-label" for="inlineRadio2">Female</label>
                      </div>
                    </div>
                    <div class="form-helper text-danger" *ngIf="show_personal_registration && showFormErrors && personalregistrationform.controls.sex.hasError('required')">
                      Sex is required
                    </div>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="areaofresidence" class="form-input-header">AREA OF RESIDENCE <small>(City/Municipality and Province)</small></label>
                    <input type="text" class="form-control" [class.bg-danger]="show_personal_registration && showFormErrors && personalregistrationform.controls.area_of_residence.invalid"
                      placeholder="" formControlName="area_of_residence" ng-required="true"
                    />
                    <div class="form-helper text-danger" *ngIf="show_personal_registration && showFormErrors && personalregistrationform.controls.area_of_residence.hasError('required')">
                      Area of Residence is required
                    </div>
                  </div>
                </div>

                <div class="row mt-5">
                  <div class="form-group col-md-6">
                    <label for="firstdropdowntitle" class="form-input-header">GOVERNMENT ISSUED ID</label>
                    <div>
                      <select class="form-select d-inline-block" [class.bg-danger]="show_personal_registration && showFormErrors && personalregistrationform.controls.idtype.invalid"
                        formControlName="idtype" ng-required="true">
                        <option value="" disabled selected>Type</option>
                        <option *ngFor="let x of GovernmentIssuedIDType" [value]="x">
                          {{ x }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="firstdropdowntitle" class="form-input-header">GOVERNMENT ID NUMBER</label>
                    <input type="text" class="form-control d-inline"
                      [class.bg-danger]="show_personal_registration && showFormErrors &&
                        personalregistrationform.controls.idnumber.invalid" placeholder="" formControlName="idnumber" ng-required="true"
                    />
                    <div class="form-helper text-danger"
                      *ngIf="show_personal_registration && showFormErrors && (personalregistrationform.controls.idtype.hasError('required') || personalregistrationform.controls.idnumber.hasError('required'))">
                      Government Issued ID is required
                    </div>
                  </div>
                </div>
              </form>
            </ng-container>

            <!-- Private Organization Registration -->
            <ng-container *ngIf="show_company_registration">
              <!--&& accountType.selectedValue === 'Company'-->
              <p class="text-center fw-bold">
                <strong class="text-primary">PRIVATE ORGANIZATION</strong>
                REGISTRATION
              </p>
              <p class="fw-bold border-bottom border-dark">
                Organization Details
              </p>
              <form [formGroup]="companyregistrationform">
                <div class="form-group">
                  <div class="form-group">
                    <label for="orgname">Name of Organization</label>
                    <input
                      type="text"
                      class="form-control"
                      [class.bg-danger]="
                        show_company_registration &&
                        showFormErrors &&
                        companyregistrationform.controls.name_of_organization
                          .invalid
                      "
                      placeholder="Organization/Company Name"
                      formControlName="name_of_organization"
                      ng-required="true"
                    />
                    <div
                      class="form-helper text-danger"
                      *ngIf="
                        show_company_registration &&
                        showFormErrors &&
                        companyregistrationform.controls.name_of_organization.hasError(
                          'required'
                        )
                      "
                    >
                      Name of Organization is required
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="orgaddr">Organization Address</label>
                    <input
                      type="text"
                      class="form-control"
                      [class.bg-danger]="
                        show_company_registration &&
                        showFormErrors &&
                        companyregistrationform.controls.organization_address
                          .invalid
                      "
                      placeholder="Organization Address"
                      formControlName="organization_address"
                      ng-required="true"
                    />
                    <div
                      class="form-helper text-danger"
                      *ngIf="
                        show_company_registration &&
                        showFormErrors &&
                        companyregistrationform.controls.organization_address.hasError(
                          'required'
                        )
                      "
                    >
                      Organization Address is required
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="officialdesignation">Official Designation</label>
                    <input
                      type="text"
                      class="form-control"
                      [class.bg-danger]="
                        show_company_registration &&
                        showFormErrors &&
                        companyregistrationform.controls.official_designation
                          .invalid
                      "
                      placeholder="Official Designation"
                      formControlName="official_designation"
                      ng-required="true"
                    />
                    <div
                      class="form-helper text-danger"
                      *ngIf="
                        show_company_registration &&
                        showFormErrors &&
                        companyregistrationform.controls.official_designation.hasError(
                          'required'
                        )
                      "
                    >
                      Official Designation is required
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="seconddropdowntitle">Sector</label>
                    <div>
                      <select
                        class="form-select"
                        [class.bg-danger]="
                          show_company_registration &&
                          showFormErrors &&
                          companyregistrationform.controls.sector.invalid
                        "
                        formControlName="sector"
                        ng-required="true"
                      >
                        <option value="" disabled selected>Sector</option>
                        <option *ngFor="let x of sectors" [value]="x['id']">
                          {{ x["name"] }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="form-helper text-danger"
                      *ngIf="
                        show_company_registration &&
                        showFormErrors &&
                        companyregistrationform.controls.sector.hasError(
                          'required'
                        )
                      "
                    >
                      Sector is required
                    </div>
                  </div>
                </div>
              </form>
            </ng-container>

            <!-- Government Account Registration -->
            <ng-container *ngIf="show_government_registration">
              <h5 class="text-center fw-bold pb-5">
                <strong class="text-primary">GOVERNMENT ACCOUNT</strong> REGISTRATION
              </h5>
              <!-- <p class="fw-bold border-bottom border-dark">Agency Details</p> -->
              <form [formGroup]="governmentregistrationform">
                <div class="form-group">
                  <div class="form-group">
                    <label class="form-input-header" for="seconddropdowntitle">AGENCY</label>
                    <div>
                      <select class="form-select" [class.bg-danger]="show_government_registration && showFormErrors && governmentregistrationform.controls.agency.invalid"
                        formControlName="agency" ng-required="true">
                        <option value="" disabled selected>
                          government agency
                        </option>
                        <option *ngFor="let x of gov_agency" [value]="x['id']">
                          {{ x["name"] }}
                        </option>
                      </select>
                    </div>

                    <div class="form-helper text-danger" *ngIf="show_government_registration && showFormErrors && governmentregistrationform.controls.agency.hasError('required')">
                      Government Agency is required
                    </div>
                  </div>

                  <div class="form-group mt-5">
                    <label class="form-input-header" for="subagency">SUB-AGENCY</label>
                    <input type="text" class="form-control" [class.bg-danger]="show_government_registration && showFormErrors && governmentregistrationform.controls.subagency.invalid"
                      placeholder="sub-agency" formControlName="subagency" ng-required="true"
                    />
                    <div class="form-helper text-danger" *ngIf="show_government_registration && showFormErrors && governmentregistrationform.controls.agency.hasError('required')">
                      Sub-Agency is required
                    </div>
                  </div>

                  <div class="form-group mt-5">
                    <label class="form-input-header" for="officialdesignation">OFFICIAL DESIGNATION</label>
                    <input type="text" class="form-control" [class.bg-danger]="show_government_registration && showFormErrors && governmentregistrationform.controls.official_designation.invalid"
                      placeholder="official designation"
                      formControlName="official_designation"
                      ng-required="true"
                    />
                    <div class="form-helper text-danger" *ngIf="show_government_registration && showFormErrors && governmentregistrationform.controls.agency.hasError('required')">
                      Official Designation is required
                    </div>
                  </div>
                </div>
              </form>
            </ng-container>

            <!-- Final Registration -->
            <ng-container *ngIf="show_final_registration">
              <h5 class="text-center fw-bold pb-5">
                <strong class="text-primary">FINALIZE</strong> REGISTRATION
              </h5>
              <p class="fw-bold border-bottom border-dark">Upload Required Documents</p>
              <form [formGroup]="finalregistrationform">
                <!-- [formGroup]="form" (ngSubmit)="onSubmit()" -->
                <div class="form-group">
                  <div class="form-group" *ngIf="show_government_id_input">
                    <label class="form-input-header" for="govid">Government Issued ID</label>
                    <input type="file" (change)="upload($event, 'gov_id')" class="form-control" formControlName="government_id" ng-required="true" style="display: none" #gov_id_input />
                    <!-- formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"  -->
                    <div class="file-input p-3">
                      <label>Drop files here or</label>
                      <button (click)="gov_id_input.click()" class="btn btn-dark ms-3 rounded-9">
                        Browse
                      </button>
                    </div>
                    <p>{{ display_government_id }}</p>
                    <div class="invalid-feedback">
                      <!-- *ngIf="submitted && f.username.errors"  -->
                      <div>Government Issued ID is required</div>
                      <!-- *ngIf="f.username.errors.required" -->
                    </div>

                    <!-- Progress Bar -->
                    <div class="progress m-3" style="height: 20px" *ngIf="gov_uploadProgress && !showFormErrors">
                      <div class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                        role="progressbar"
                        [style.width]="(gov_uploadProgress | async) + '%'"
                        [attr.aria-valuenow]="gov_uploadProgress | async"
                        aria-valuemin="0"
                        aria-valuemax="100">
                      </div>
                    </div>
                  </div>

                  <div class="form-group" *ngIf="show_agency_id_input">
                    <label for="agencyid">Company/Agency ID</label>
                    <input type="file" (change)="upload($event, 'company_id')" class="form-control" formControlName="company_id" ng-required="true" style="display: none" #agency_id_input />
                    <!-- formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"  -->
                    <div class="file-input p-3">
                      <label>Drop files here or </label>
                      <button (click)="agency_id_input.click()" class="btn btn-dark ms-3 rounded-9">
                        Browse
                      </button>
                    </div>
                    <p>{{ display_company_id }}</p>
                    <div class="invalid-feedback">
                      <!-- *ngIf="submitted && f.username.errors"  -->
                      <div>Company/Agency ID is required</div>
                      <!-- *ngIf="f.username.errors.required" -->
                    </div>

                    <!-- Progress Bar -->
                    <div class="progress m-3" style="height: 20px" *ngIf="com_uploadProgress && !showFormErrors">
                      <div class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                        role="progressbar"
                        [style.width]="(com_uploadProgress | async) + '%'"
                        [attr.aria-valuenow]="com_uploadProgress | async"
                        aria-valuemin="0"
                        aria-valuemax="100">
                      </div>
                    </div>
                  </div>

                  <div class="form-group" *ngIf="show_authorization_letter_input">
                    <label for="authid">Authorization Letter</label>
                    <input type="file" (change)="upload($event, 'auth_letter')" class="form-control" formControlName="authorization_letter"
                      ng-required="true" style="display: none" #auth_letter_input
                    />
                    <!-- formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"  -->
                    <div class="file-input p-3">
                      <label>Drop files here or </label>
                      <button (click)="auth_letter_input.click()" class="btn btn-dark ms-3 rounded-9">
                        Browse
                      </button>
                    </div>
                    <p>{{ display_authorization_letter }}</p>
                    <div class="invalid-feedback">
                      <!-- *ngIf="submitted && f.username.errors"  -->
                      <div>Authorization Letter is required</div>
                      <!-- *ngIf="f.username.errors.required" -->
                    </div>

                    <!-- Progress Bar -->
                    <div class="progress m-3" style="height: 20px" *ngIf="auth_uploadProgress && !showFormErrors">
                      <div class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                        role="progressbar"
                        [style.width]="(auth_uploadProgress | async) + '%'"
                        [attr.aria-valuenow]="auth_uploadProgress | async"
                        aria-valuemin="0"
                        aria-valuemax="100">
                      </div>
                    </div>
                  </div>
                  <div class="form-helper text-danger" *ngIf="show_final_registration && showFormErrors">
                    Invalid file type
                  </div>
                  <div class="form-group">
                    <label for="firstname">Valid file types include: jpg, jpeg, pdf, png. Each file cannot exceed 10MB.</label>
                  </div>
                </div>
              </form>
            </ng-container>

            <div class="my-5">
              <ng-container *ngIf="show_recaptcha_confirmation">
                <div class="form-group">
                  <re-captcha
                    #captchaRef="reCaptcha"
                    (resolved)="resolved($event)"
                    [siteKey]="recaptchaV2SiteKey"
                  ></re-captcha>
                </div>
              </ng-container>
            </div>

            <!-- ACTION BUTTONS -->
            <div class="card-footer">
              <ng-container class="form-group row ms-auto">
                <div class="py-2">
                  <button *ngIf="show_back_button" class="btn btn-dark btn-rounded col m-1" (click)="toggleBack()" id="bt">
                    <i class="fas fa-arrow-alt-circle-left me-2"></i> Back
                  </button>
                  <button *ngIf="show_terms_and_conditions || show_data_privacy" class="btn btn btn-info btn-rounded col m-1" (click)="loginAsGuest()">
                    Continue as Guest
                  </button>
                  <button *ngIf="show_next_button" class="btn btn-primary btn-rounded col m-1 float-end" (click)="toggleNext()" id="bt">
                    Next <i class="fas fa-arrow-circle-right ms-2"></i>
                  </button>
                  <button *ngIf="show_submit_button" class="btn btn-danger btn-rounded col m-1 float-end " [disabled]="uploading" (click)="toggleNext()">
                    Submit <i class="fas fa-share-square ms-2"></i>
                  </button>
                  <button *ngIf="show_final_send_button" class="btn btn-primary col m-1 float-end " [hidden]="needsRecaptcha" [disabled]="loading" (click)="submitForm()">
                    <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>
                    Register
                  </button>
                </div>
              </ng-container>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
