import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-artemis-comments',
  templateUrl: './artemis-comments.component.html',
  styleUrls: ['./artemis-comments.component.scss']
})
export class ArtemisCommentsComponent implements OnInit {

  @Input() comment:any

  @Input() isAdmin:boolean =false

  constructor() { }

  ngOnInit(): void {
    /* //console.log(this.comment
      ) */
  }

}
