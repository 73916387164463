import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-artemis-charter-inbox-page-arta',
  templateUrl: './artemis-charter-inbox-page-arta.component.html',
  styleUrls: ['./artemis-charter-inbox-page-arta.component.scss']
})
export class ArtemisCharterInboxPageArtaComponent implements OnInit {
  isCharterListView: boolean = true;
  isServiceListView: boolean = false;

  @Input() service_ids: string[] = [];
  @Input() citizens_charter: string = "";
  @Input() agency_id: string = "";
  @Input() agency_name: string = "";
  
  @Input() activateChild: any = false;
  @Input() activateObject: any = {};
  constructor() { }

  ngOnInit(): void {
    if(this.activateChild){
      this.service_ids = this.activateObject.serviceId
      this.citizens_charter = this.activateObject.ccId
      this.agency_id = this.activateObject.agencyId
      this.agency_name =this.activateObject.agency_name
      this.isCharterListView = false;
      this.isServiceListView = true;
    }
  }

  changeInboxViewPort(param: any) {
    this.service_ids = param?.service_ids || [];
    this.citizens_charter = param?.citizens_charter || "";
    this.agency_id = param?.agency_id || "";
    this.agency_name = param?.agency_name || "";
    this.isCharterListView = !this.isCharterListView;
    this.isServiceListView = !this.isServiceListView;
  }
}
