import { formatDate } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { SubmitAs } from 'src/app/entities/consultation';
import { RegulationCommentStatus } from 'src/app/entities/worklist';
import { NotificationsEmailer } from 'src/app/service/notifications-emailer.service';
import Swal from 'sweetalert2';
import { EmailAppCode, EmailTemplateCode } from 'src/app/entities/emailTemplates';

@Component({
  selector: 'app-pbris-comment-view',
  templateUrl: './pbris-comment-view.component.html',
  styleUrls: ['./pbris-comment-view.component.scss']
})
export class PbrisCommentViewComponent implements OnInit {

  @Input('consultation_id') consultation_id:any;
  @Input('view') view:any;

  commentList: any;
  //Modal
  url: any;
  user: any = [];
  comment: any;
  document: any;
  type: any;
  id: any;
  reply: any;
  files: any = [];

  loading: boolean = false;
  fetching: boolean = false;
  isEmpty: boolean = true;

  form = new FormGroup({
    reply: new FormControl(''),
  });

  submitAs = SubmitAs
 
  constructor(
    private afs: AngularFirestore,
    private store: AngularFireStorage,
    private nE: NotificationsEmailer,
  ) {}

  ngOnInit(): void {
    console.log('PbrisCommentViewComponent')
    this.getComments().subscribe(data => {      
      this.commentList = data.map(e => {        
        this.isEmpty = false;
        return {
          id: e.payload.doc.id,
          data: e.payload.doc.data()
        };
      })
    })

    // console.log('comments: ', this.getComments())
    
  }

  openModal(data: any) {
    this.files = [];
    this.url = data.data.comments.url;
    this.user = data.data.user
    console.log('user',this.user)
    this.comment = data.data.comments.comment;
    this.type = data.data.type
    this.reply = data.data.reply;
    this.id = data.id;
    this.fetching = true;
    if (data.data.comments.path && data.data.comments.document) {
      this.getFiles({ 
        path: data.data.comments.path, 
        documents: data.data.comments.document
      });
    } else {
      this.fetching = false;
      this.files = [];
    }
  }

  closeModal() {
    this.files = [];
  }

  getFiles(data: any): void {
    for (let i = 0; i < data.documents.length; i++) {
      this.store.ref(`${data.path}${data.documents[i].name}`)
        .getDownloadURL()
        .subscribe((url: string) => {
          this.files.push({name: data.documents[i].name ,url: url})
      }, err => { console.log("Error",err) });
    }
    this.fetching = false;
  }

  getComments() {
    switch(this.view){
      case 'consultation list':
        let commentFilter: QueryFn;

        commentFilter = (filter)=>
          filter
            .where('info.consultation_id','==',this.consultation_id)
            .orderBy('info.from_date','desc')

        return this.afs.collection('Comment PBRIS',commentFilter).snapshotChanges();
      default:
        return this.afs.collection('Comment PBRIS').snapshotChanges();
    }
  }

  submit(data: any) {
    this.loading = true;
    Swal.fire({
      title: 'Processing',
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
  
        this.afs.collection('Comment PBRIS')
          .doc(data)
          .update({
            reply: this.form.value.reply,
            status: RegulationCommentStatus.RESOLVED,
            resolved_at: formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0800')
          })
          .then((docRef:any) => {
            this.loading = false;
            Swal.close();
            Swal.fire({
              title:'Successfully processed',
              icon: 'info'
            });
            this.codeToSendForNotifEmail(data)
            this.resetForm();
        }).catch((error:any) =>{
          this.loading = false;
          Swal.close();
          Swal.fire({
            title:'System error',
            titleText: 'Please refresh the page',
            icon: 'error'
          });
        });
      }
    })
  }

  resetForm() {
    this.form.reset();
  }

  async codeToSendForNotifEmail(commentId:any){
    let templateCode: number = 0
    const commentCtr = this.commentList.map((res:any)=>res.id).indexOf(commentId)
    if(commentCtr >= 0){
      const currentComment:any = this.commentList[commentCtr]
      console.log('currentComment: ',this.commentList[commentCtr])
      const commentUID = currentComment.data.user.user_id
      const consultationId = currentComment.data.info.consultation_id
      // const currentConsultation:any = await this.getConsultation(consultationId)
      // const commentUser:any = await this.getUser(commentUID)
      Promise.all([this.getUser(commentUID),this.getConsultation(consultationId)])
      .then(async (response)=>{
        const commentUser:any = response[0]
        const currentConsultation:any = response[1]
        console.log('commentUser: ',commentUser)
        console.log('currentConsultation: ',currentConsultation)
        if(commentUser && currentConsultation){
          if(commentUser?.credentials?.account_subtype == 'Personal'
            || commentUser?.credentials?.account_type == 'Company'
            || commentUser?.credentials?.account_type == 'Government'
          ) templateCode = EmailTemplateCode.AGENCY_FEEDBACK_ON_USER_COMMENT
            // 6

          Promise.all([this.getAgency(currentComment.data.info.user_agency),this.getRegulation(currentConsultation.cris_id)])
          .then(async (response1)=>{
            const agency:any = response1[0]
            const cris:any = response1[1]
            if(agency && cris){
              if(templateCode > 0){
                let data = {
                  consultationTitle: cris.title,
                  consultationTrackNo: consultationId,
                  agencyName: agency?.agencyDetails?.agencyName ? agency?.agencyDetails?.agencyName : agency.name,
                  // feedbackLink: 'https://arta-respond.web.app/', //change
                  // commentsLink: 'https://arta-respond.web.app/', //change
                  // accountLink: 'https://arta-respond.web.app/', //change
                }
                console.log('sending codeToSendForNotifEmail: ',commentUser.credentials.email,{templateCode})
                // this.toSendNotifEmail([commentUser.credentials.email],templateCode,data)
                this.nE.emailServiceHandler([commentUser.credentials.email],EmailAppCode.ARTA,templateCode,data)
              } else console.error('PbrisConsultationsViewComponent emailer error: no email template for account type')
            }else console.warn('agency/cris of comment not found')
          })
          .catch((err)=>{
            console.error(err)
            return false
          })
          
          // this.getAgency(currentComment.data.info.user_agency)
          // .then((agency:any)=>{
          //   if(agency){
          //     // if(commentUser.settings && commentUser.settings.consultations.create){
          //   // } else console.warn('user subscribed to consultations is not set to true')
          //   } else console.warn('agency not found')
          // })
          .catch((err)=>{console.error(err)})
        } else console.warn('user/consultation of comment not found')
      })
      .catch((err)=>{
        console.error(err)
      })
    }else console.warn('comment not in list')
  }

  getConsultation(id:any){
    return new Promise((res,rej)=>{
      this.afs.collection('Public Consultations').doc(id).snapshotChanges()
      .pipe(first())
      .subscribe({
        next:(result)=>{
          let consultation:any = result.payload.data()
          console.log('consultation',consultation)
          return res(consultation)
        },
        error: (err)=>{
          return res(false)
        }
      })
    })
  }

  getUser(uid:any){
    return new Promise((res,rej)=>{
      this.afs.collection('Users').doc(uid).snapshotChanges()
      .subscribe({
        next:(result)=>{
          let userTemp = result.payload.data()
          return res(userTemp)
        },
        error:(err)=>{
          return res(false)
        }
      })
    })
  }

  getAgency(aid:any){
    return new Promise((res,rej)=>{
      this.afs.collection('Agency').doc(aid).snapshotChanges()
      .subscribe({
        next:(result)=>{
          let agency:any = result.payload.data()
          return res(agency)
        },
        error:(err)=>{
          return res(false)
        }
      })
    })
  }

  getRegulation(uid:any){
    return this.afs.collection('regulations-proposed-cris').doc(uid).get().toPromise()
    .then((result)=>{
      if(result.exists) return result.data()
      return false
    })
    .catch((err)=>{
      console.error(err)
      return false
    })

  }

  // toSendNotifEmail(currentUsers:any[], templateCode:number,data:any){
  //   console.log('sending email: ',currentUsers, templateCode)
  //   if(templateCode > 0 && currentUsers.length > 0){
  //     this.nE.emailServiceHandler(currentUsers,EmailAppCode.ARTA,templateCode,data)
  //   } else console.error('PbrisCommentViewComponent emailer error: no email template for accoun tyype')
  // }
}
