import { PbrisConsultationsViewComponent } from './pbris-consultation-view/pbris-consultation-view.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { first, map } from 'rxjs/operators';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore,QueryFn } from '@angular/fire/firestore';

import { Router, ActivatedRoute,NavigationEnd } from '@angular/router';

import {format, isThursday } from 'date-fns';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2';
// import {DateAdapter} from '@angular/material/core';
// import {
//   MatDateRangeSelectionStrategy,
//   DateRange,
//   MAT_DATE_RANGE_SELECTION_STRATEGY,
//   MatCalendar,
// } from '@angular/material/datepicker';
import firebase from 'firebase/app';
import Timestamp = firebase.firestore.Timestamp;
// import { UpdateCallback } from '@popperjs/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { ConsultationTypes } from 'src/app/entities/consultation';
import { Operators } from 'src/app/entities/firebaseQueryOperators';
import { Query } from '@angular/fire/firestore';
import { NotificationsEmailer } from 'src/app/service/notifications-emailer.service';
import { EmailTemplateCode } from 'src/app/entities/emailTemplates';
// var deepEqual = require('deep-equal')

export interface Consultation {
  rowId?: string;
  type: string;
  cris_id: string;
  regulation_id: string;
  link: string;
  purpose: string;
  stakeholder: string;
  to_date: string;
  from_date: string;
  consultation_id?: string;
  formated_to_date?: string;
  formated_from_date?: string;
  user_agency?: string;
  reg_agency:any[];
  regulation_title?: string;
  short_title?: string;
}

@Component({
  selector: 'app-pbris-consultation',
  templateUrl: './pbris-consultation.component.html',
  styleUrls: ['./pbris-consultation.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PbrisConsultationsComponent implements OnInit {
  style_theme: any = 'theme-default';
  
  filterValues:any = {};
  
  @ViewChild(PbrisConsultationsViewComponent, {static : true}) childPbrisViewConsultation : PbrisConsultationsViewComponent;

  consultationTypes = ConsultationTypes;

  consultations: Consultation[];
  consultations_ongoing: any[];
  consultations_trending: any[];
  consultations_upcoming: any[];
  consultations_due : any[];
  consultations_completed: any[];
  consultations_delayed: any[];

  // types:
  consultations_type_fgd : any[];
  consultations_type_oc: any[];
  consultations_type_survey: any[];

  displayed_consultations: any[];

  filter_counter:any;
  
  expandedCommentView: any | null;
  expandedCommentCreate: any | null;  
  expandedCommentPinned: any | null;
  consultationsClean: any[];

  cosultation_id:any;
  this_consultation:any;
  consultation_filter:any;

  chosenFilter:any=''

  picker:any;

  searchform: FormGroup = new FormGroup({
    keyword: new FormControl('', Validators.required),    
    eventCategory: new FormControl('', Validators.required),
  });

  filtersForm = this.formBuilder.group({
    search: new FormControl(''),
    select_type: new FormControl(''),
    range: this.formBuilder.group({
      sDate: new FormControl(null), // {value:null, disabled: true}
      eDate: new FormControl(null),
    })
  });
  currentFilter:any;
  sDateTS:any;
  eDateTS:any;

  // user info
  currentUser: any;
  userAgency: any;
  user_id: any;
  user_info: any;
  userType: any = false
  isAdmin: any = false

  loading = true;

  //searching
  // consultationTableDisplayCol: string[] = ['type','stakeholder', 'purpose','from_date','to_date'];
  consultationTableDisplayCol: string[] = ['rowId'];
  consultationTabledataSource: any; 

  filterList: any = [
    {name:'All',value:'', icon:'fas fa-list'},
    {name:'Ongoing',value:'Ongoing', icon:'fas fa-sync'},
    {name:'Trending',value:'Trending', icon:'fas fa-fire'},
    {name:'Upcoming',value:'Upcoming', icon:'far fa-comment-dots'},
    {name:'Closing in 7 Days',value:'Closing in 7 Days', icon:'fas fa-hourglass-half'},
    {name:'Completed',value:'Completed', icon:'fas fa-check'},
    {name:'Delayed',value:'Delayed', icon:'fas fa-fast-backward'},
  ]

  consultationType: any = [
    {name:'All', value:''},
    {name:'Focus Group Discussion', value:'Focus Group Discussion'},
    {name:'Online Commenting', value:'Online Commenting'},
    {name:'Survey', value:'Survey'},
  ]

  loadedData:any =  {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getType = {
    SINGLE: 'single',
    MULTI: 'multi',
    FILTERED: 'filtered',
  }

  constructor(
    public auth: AngularFireAuth, 
    private afs: AngularFirestore,
    public formBuilder: FormBuilder,
    private route: ActivatedRoute, 
    public router: Router,
    // private nE: NotificationsEmailer,
  ) {  }

  ngOnInit(): void {
    // console.log('date: ', firebase.firestore.Timestamp.fromDate(new Date()))
    this.consultations=[];
    this.consultations_ongoing=[];
    this.consultations_trending=[];
    this.consultations_upcoming=[];
    this.consultations_due =[];
    this.consultations_completed=[];
    this.consultations_delayed=[];

    this.auth.user.subscribe((data:any) => {
      if(data){
        this.user_info = {
          user_id: data.uid,
          name: data.displayName,
          email: data.email
        }
        this.afs.collection('Users').ref
        .where('credentials.uid','==',this.user_info.user_id)
        .get()
        .then(data=>{
          let tempArr:any = []
          data.forEach(info=>{
            let item: any = info.data()
            item.id = info.id
            tempArr.push(item)
          })
          this.currentUser = tempArr[0]

          if(this.currentUser.government){
            if(this.currentUser.government.agency){
              this.userAgency = this.currentUser.government.agency
            }else this.userAgency = false
          }else this.userAgency = false

          this.userType = sessionStorage.getItem("user_type");
          if(this.userType == 'arta')
            this.isAdmin = sessionStorage.getItem("arta_admin");

            // this.consultation_filter = this.afs.collection('Public Consultations', filter => filter.orderBy('to_date','desc')).snapshotChanges()
          this.loadConsultation('')
        })
        .catch(err=>{
          this.loadConsultation('')
        })
      }
      else this.loadConsultation('')
    });
  }

  async setTheme() {
    switch(sessionStorage.getItem("user_type")){
      case 'arta':
        this.style_theme = 'theme-arta';
        break;
      case 'agency':
        this.style_theme = 'theme-agency';
        break;
      case 'personal':
        this.style_theme = 'theme-default';
        break;
      default:
        this.style_theme = 'theme-default';
      }
  }

  //table and main window loaders
  settingMatSortPaging(){
    this.consultationTabledataSource.paginator = this.paginator;
    this.consultationTabledataSource.sort = this.sort;
  }

  async loadConsultation(filter:any){        
    // await this.filters(filter) 

    let consultationsFilter: QueryFn;

    if(sessionStorage.getItem("user_type") === "agency" && sessionStorage.getItem('agency_id')) {
      consultationsFilter = (filter) =>
      filter
        .where('agencyIds', 'array-contains', sessionStorage.getItem('agency_id'))
        .orderBy('from_date','desc');
    }
    else {
      consultationsFilter = (filter) =>
      filter
        .orderBy('from_date','desc');
    }

    return this.afs.collection('Public Consultations',
      consultationsFilter).snapshotChanges()
    // .pipe(first())
    .subscribe(data=>{
      // console.log('loaded consultations ')
      // let cleanArr:any = []
      // let allTemp:Consultation[] = []
      // let ongoingTemp:Consultation[] = []
      // let trendingTemp:Consultation[] = []
      // let upcomingTemp:Consultation[] = []
      // let dueTemp:Consultation[] = []
      // let completedTemp:Consultation[] = []
      // let delayedTemp:Consultation[] = []
      
      // let type_fgd:Consultation[] = []
      // let type_oc:Consultation[] = []
      // let type_survey:Consultation[] = []

      // let rowId: number = 1;
      let today = new Date();
      this.loading =false;

      /*
      // data.forEach(info=>{
      //   let consultation: any = info.payload.doc.data() as Consultation;
      //   consultation.id = info.payload.doc.id
      //   let consultationClean: any = info.payload.doc.data();
      
      //   consultationClean.consultation_id = info.payload.doc.id;
      //   consultationClean.short_title = consultationClean.purpose;
      //   consultationClean.regulation_type = "Proposed Regulation";
      //   consultationClean.active = false
      //   if(this.userAgency)
      //     consultationClean.user_agency = this.userAgency
      //   cleanArr.push(consultationClean)

      //   this.afs.collection('regulations-proposed-cris').doc(consultation.cris_id).snapshotChanges()
      //   .pipe(first())
      //   .subscribe(result=>{
      //     let regProposedCris: any =result.payload.data()
      //     consultation.regulation_title = regProposedCris.title
          
      //     consultation.short_title = regProposedCris.subject
      //     // consultation.regulation_subj = regProposedCris.subject
      //     consultation.reg_agency = regProposedCris.reg_agency;
      //     consultation.consultation_id = info.payload.doc.id;
      //     consultation.to_date_Date = consultation.to_date.toDate();
      //     consultation.from_date_Date = consultation.from_date.toDate();
      //     consultation.formated_to_date = format(consultation.to_date.toDate(), 'MM/dd/yyyy');
      //     consultation.formated_from_date = format(consultation.from_date.toDate(), 'MM/dd/yyyy');

      //     // better make this keep the first instance of the each sector so that next time a regulation has the same sector, the regulation will just get it from a container(ie. array or something)
      //     // then if sector is not in the container search for it in firebase
      //     if(regProposedCris.reg_sector){
      //       this.afs.collection('Sector').doc(regProposedCris.reg_sector).snapshotChanges()
      //       .pipe(first())
      //       .subscribe(result1=>{
      //         let regSector: any = result1.payload.data()
      //         consultation.life_status=regSector.name
      //       })
      //     }

      //     // do to stage of life what to do with sector
      //     if(regProposedCris.reg_stageoflife){
            
      //       this.afs.collection('Stage of Life').doc(regProposedCris.reg_stageoflife).snapshotChanges()
      //       .pipe(first())
      //       .subscribe(result1=>{
      //         let regStage: any = result1.payload.data()
      //         consultation.life_status=regStage.name
      //       })
      //     }

      //     consultation.rowId = rowId++;
          
      //     // console.log('result: ', this.consultations)

      //     if(this.userAgency)
      //       consultation.user_agency = this.userAgency
          
      //     if(consultation.completed == false){            
      //       ongoingTemp.push(consultation as Consultation)     
      //     }else if(consultation.completed == true){
      //       completedTemp.push(consultation as Consultation)       
      //     }   
          
      //     if(consultation.completed == false && this.dateChecker('Upcoming',consultation.from_date_Date,today,7) == true){
      //       upcomingTemp.push(consultation as Consultation)   
      //     } 
          
      //     if(consultation.completed == false && this.dateChecker('Delayed',consultation.to_date_Date,today) == true){
      //       delayedTemp.push(consultation as Consultation)   
      //     }

      //     if(consultation.completed == false && this.dateChecker('Closing',consultation.to_date_Date,today,7) == true){
      //       dueTemp.push(consultation as Consultation)   
      //     }

      //     // consultation types:
      //     if(consultation.type =='Focus Group Discussion'){
      //       type_fgd.push(consultation as Consultation)   
      //     }
      //     if(consultation.type =='Online Commenting'){
      //       type_oc.push(consultation as Consultation)   
      //     }
      //     if(consultation.type =='Survey'){
      //       type_survey.push(consultation as Consultation)   
      //     }
          
      //     allTemp.push(consultation as Consultation)
      //     console.log('allTemp: ',allTemp)
                 
      //     this.consultations_ongoing = ongoingTemp;     
      //     this.consultations_completed = completedTemp;      
      //     this.consultations_delayed = delayedTemp ;
      //     this.consultations_due = dueTemp;
      //     this.consultations_upcoming = upcomingTemp;
      //     this.consultations = allTemp;

      //     // Types:
      //     this.consultations_type_fgd = type_fgd;
      //     this.consultations_type_oc = type_oc;
      //     this.consultations_type_survey = type_survey;

      //     // console.log('date_closing: ', this.dateChecker('Delayed',consultation.to_date_Date,today)) 

      //     this.filters(filter)
      //     // this.filters(filter,tempArr)
      //     this.settingMatSortPaging();
      //     this.changeFilter('default')

      //   })

      //   this.loadPinnedRegulation(this.user_info.user_id, consultation.id).subscribe(data => {
      //     data.docs.map(res => {
      //       let info: any = res.data()
      //       consultation.pinned_id = res.id
      //       consultation.status = info.status
      //       // consultation.reg_id = res.id
      //       consultation.hasExist = true

      //       // console.log('test: ', info)
      //       // console.log('consultation: ', consultation)
      //     })
      //   });
        
      //   // consultation.consultation_id = info.payload.doc.id;
      //   // consultation.formated_to_date = format(consultation.to_date.toDate(), 'MM/dd/yyyy');
      //   // consultation.formated_from_date = format(consultation.from_date.toDate(), 'MM/dd/yyyy');
      //   // consultation.rowId = rowId++;
      //   // // consultation.type_title = consultation.type;
      //   // tempArr.push(consultation as Consultation)
      // })
      // */

      let consultationsList:any = {
        cleanArr: [],
        allTemp: [],
        ongoingTemp: [],
        completedTemp: [],
        upcomingTemp: [],
        delayedTemp: [],
        dueTemp: [],
        type_fgd: [],
        type_oc: [],
        type_survey: [],
        pinnedConsultations: []
      }
      Promise.all(data.map(async (info,index)=>{
          // console.log('-----')

          let consultationClean: any = info.payload.doc.data();
          consultationClean.consultation_id = info.payload.doc.id
          consultationClean.id = info.payload.doc.id
          consultationClean.short_title = consultationClean.purpose;
          consultationClean.regulation_type = "Proposed Regulation";
          consultationClean.active = false
          if(this.userAgency) consultationClean.user_agency = this.userAgency
          consultationsList.cleanArr.push(consultationClean)

          let consultation: any = info.payload.doc.data() as Consultation;
          consultation.consultation_id = info.payload.doc.id
          consultation.id = info.payload.doc.id
          
          consultation.rowId = index
          consultation.to_date_Date = consultation.to_date.toDate();
          consultation.from_date_Date = consultation.from_date.toDate();
          consultation.formated_to_date = format(consultation.to_date.toDate(), 'MM/dd/yyyy');
          consultation.formated_from_date = format(consultation.from_date.toDate(), 'MM/dd/yyyy');
          if(this.userAgency) consultation.user_agency = this.userAgency

          const regProposedCris:any = await this.getDocument1('proposedCris',this.getType.SINGLE,'regulations-proposed-cris',consultation.cris_id)
          // console.log('regProposedCris: ',regProposedCris)
          if(regProposedCris){
            consultation.regulation_title = regProposedCris.title
            consultation.short_title = regProposedCris.subject
            // consultation.regulation_subj = regProposedCris.subject
            consultation.reg_agency = regProposedCris.reg_agency;
            consultation.consultation_id = info.payload.doc.id;

            const regSector = await this.getDocument1('sector',this.getType.SINGLE,'Sector',regProposedCris.reg_sector)
            if(regSector) consultation.life_status = regSector.name
            
            const regStage = await this.getDocument1('stageOfLife',this.getType.SINGLE,'Stage of Life',regProposedCris.reg_stageoflife)
            if(regStage) consultation.life_status = regStage.name
          }

          if(this.user_info){
            const whereValues = [
              {
                fieldName: 'user_id',
                operator: Operators.EQUAL_TO,
                values: this.user_info.user_id,
              },
              {
                fieldName: 'consultation_id',
                operator: Operators.EQUAL_TO,
                values: consultation.id,
              },
            ]
            // const pinnedConsultations = await this.getDocument1('pinnedConsultations',this.getType.FILTERED,'PBRIS Pinned Consultations',null,whereValues)
            const pinnedConsultations:any =await this.loadFirebaseDocumentWithFilter('PBRIS Pinned Consultations',whereValues)
            if(pinnedConsultations){
              consultation.pinned_id = pinnedConsultations.id
              consultation.status = pinnedConsultations.status
              consultation.hasExist = true
            }
          }

          consultationsList.allTemp.push(consultation as Consultation)

          if(consultation.completed == false) consultationsList.ongoingTemp.push(consultation as Consultation)     
          else if(consultation.completed == true) consultationsList.completedTemp.push(consultation as Consultation)
        
          if(consultation.completed == false && this.dateChecker('Upcoming',consultation.from_date_Date,today,7) == true) consultationsList.upcomingTemp.push(consultation as Consultation)   
          
          if(consultation.completed == false && this.dateChecker('Delayed',consultation.to_date_Date,today) == true) consultationsList.delayedTemp.push(consultation as Consultation)   
          
          if(consultation.completed == false && this.dateChecker('Closing',consultation.to_date_Date,today,7) == true) consultationsList.dueTemp.push(consultation as Consultation)   
          
          if(consultation.type == this.consultationTypes.FOCUS_GROUP_DISCUSSION) consultationsList.type_fgd.push(consultation as Consultation)
          else if(consultation.type == this.consultationTypes.ONLINE_COMMENTING) consultationsList.type_oc.push(consultation as Consultation)
          else if(consultation.type == this.consultationTypes.SURVERY) consultationsList.type_survey.push(consultation as Consultation)
          return true
      }))
      .then((result:any)=>{
        // console.log('loadedData: ',this.loadedData)
        // this.consultationsClean = result.cleanArr;
        this.consultationsClean = consultationsList.cleanArr
                 
        this.consultations_ongoing = consultationsList.ongoingTemp;     
        this.consultations_completed = consultationsList.completedTemp;      
        this.consultations_delayed = consultationsList.delayedTemp ;
        this.consultations_due = consultationsList.dueTemp;
        this.consultations_upcoming = consultationsList.upcomingTemp;
        this.consultations = consultationsList.allTemp;

        // Types:
        this.consultations_type_fgd = consultationsList.type_fgd;
        this.consultations_type_oc = consultationsList.type_oc;
        this.consultations_type_survey = consultationsList.type_survey;

        this.filters(filter)
        this.settingMatSortPaging();
        this.changeFilter('default')
      })
      .catch((err)=>{
        console.error('Error: ',err)
      })
      // */

      /*
      // this.consultationsClean = cleanArr;
      // console.log('loaded consultations: ',cleanArr)
      // this.consultations = tempArr;
      // this.consultationTabledataSource = new MatTableDataSource<Consultation>(this.consultations)
      // // this.settingFilter()
      // */
    })
  }

  async getDocument1(loadCode:string,type:string,collection:string,id:any,whereValues?:any):Promise<any>{
    if(this.loadedData[loadCode]){
      let localData = this.loadedData[loadCode]
      if(collection){
        if(localData.length > 0){
          if(type == this.getType.SINGLE){
            const loadedDataCtr = localData.map((data:any)=>data.id).indexOf(id)
            if(loadedDataCtr >= 0) return localData[loadedDataCtr]
            else return this.getDocument2(loadCode,collection,id)
          }
          // else if(type == this.getType.FILTERED){
          //   // const loadedDataCtr = localData.map((data:any)=>(data.collection == collection && deepEqual(data.whereData,whereValues))).indexOf(true)
          //   const loadedDataCtr = localData.map((data:any)=>data.id).indexOf(id)
          //   if(loadedDataCtr >= 0){
          //     // check data
          //     if(localData[loadedDataCtr].data) return localData[loadedDataCtr].data
          //     else{
          //       // pull data from firebase
          //       // then add to localData[loadedDataCtr].data
          //       console.warn('failsafe trigger 1')
          //       return false
          //     }
          //   }
          //   else{
          //     // no loaded data
          //     console.warn('failsafe trigger 2')
          //     return false
          //   }
            
          //   // check wherevalue used previously
          //   // if wherevalues already used
          //     // get from loaded data

          //   // if wherevalues not yet used
          //     // get from firebase
          //     // store to loaded
          // }
          else return false
        }else{
          if(type == this.getType.SINGLE) return this.getDocument2(loadCode,collection,id)
          // else if(type == this.getType.FILTERED){
          //   console.warn('failsafe trigger 3')
          //   return false
          // }
          else return false
        }
      }else return false
    }else{
      this.loadedData[loadCode] = []
      // if(type == this.getType.FILTERED){
      //   this.loadedData[loadCode].push({
      //     whereData:whereValues,
      //     collection:collection,
      //     data:null
      //   })
      // }
      return this.getDocument1(loadCode,type,collection,id,whereValues)
    }
  }

  async getDocument2(loadCode:string,collection:string,id:string){
    if(id){
      const itemCtr:any = await this.loadFirebaseDocument(collection,id)
      if(itemCtr){
        if(!this.loadedData[loadCode].map((data:any)=>data.id).includes(itemCtr.id)) this.loadedData[loadCode].push(itemCtr)
        return itemCtr
      }else return false
    } else return false
  }

  async loadFirebaseDocument(collection:string,id:string){
    return new Promise((res,rej)=>{
      this.afs.collection(collection).doc(id).snapshotChanges()
      .subscribe({
        next:(result)=>{
          if(result.payload.exists){
            let regProposedCris: any = result.payload.data()
            regProposedCris.id = result.payload.id
            if(regProposedCris._createdBy) delete regProposedCris._createdBy
            if(regProposedCris._updatedBy) delete regProposedCris._updatedBy
            return res(regProposedCris)
          } else return res(false)
        },
        error:(err)=>{
          console.error('Error: ',err)
          return res(false)
        }
      })
    })
  } 

  async loadFirebaseDocumentWithFilter(collection:string,whereValues:any){
    if(whereValues){
      return new Promise((res,rej)=>{
        this.afs.collection(collection,filter=>this.filterFunc(filter, whereValues)).snapshotChanges()
        .subscribe({
          next:(result)=>{
            if(result.length > 0){
              const items = result.map((data)=>{
                if(data.payload.doc.exists){
                  let item:any = data.payload.doc.data()
                  item.id = data.payload.doc.id
                  // if(!this.loadedData[loadCode].map((val:any)=>val.id).indexOf(item.id)) this.loadedData[loadCode].push(item)
                  return item
                }
                return false
              })
              return res(items[0])
            }
            else return res(false)
          },
          error:(err)=>{
            console.error('Error: ',err)
            return res(false)
          }
        })
      })
    } else return false
  }

  filterFunc(filter:Query, whereValues:any){
    let query: Query = filter
    whereValues.map((whereValue:any)=>{
      query = query.where(whereValue.fieldName,whereValue.operator,whereValue.values)
    })
    return query
  }

  filterArrayFunc(){

  }

  loadPinnedRegulation(user_id: any, consultation_id: any){
    return this.afs.collection(`PBRIS Pinned Consultations`, ref => ref.where('user_id', '==', user_id).where('consultation_id', '==', consultation_id)).get()
  }

  filterCounter(counter:any){
    switch(counter){
      case'Ongoing':{
        
        return this.filter_counter=this.consultations_ongoing.length
      }
      case'Trending':{
        
        return this.filter_counter=this.consultations_trending.length
      }
      case'Upcoming':{
        
        return this.filter_counter=this.consultations_upcoming.length
      }
      case'Due in 7 Days':{
        
        return this.filter_counter=this.consultations_due.length
      }
      case'Completed':{
        
        return this.filter_counter=this.consultations_completed.length
      }
      case'Delayed':{
        
        return this.filter_counter=this.consultations_delayed.length
      }
      default:{
        
        return this.filter_counter=this.consultations.length
      }
    }
  }

  filters(consultation_filter:any){    

    // console.log('consultations_ongoing: ', this.consultations_ongoing) 
    // console.log('consultations_completed: ', this.consultations_completed)
    // console.log('consultations_delayed: ', this.consultations_delayed)
    // console.log('consultations: ', this.consultations)
    
    // let filtered_consultations

    switch (consultation_filter){
      case'Ongoing':{
        return this.consultationTabledataSource = new MatTableDataSource<Consultation>(this.consultations_ongoing)
      }
      case'Trending':{  

        return this.consultationTabledataSource = new MatTableDataSource<Consultation>(this.consultations_trending)
      }
      case'Upcoming':{
        return this.consultationTabledataSource = new MatTableDataSource<Consultation>(this.consultations_upcoming)
      }
      case'Due in 7 Days':{     
        
        return this.consultationTabledataSource = new MatTableDataSource<Consultation>(this.consultations_due)
      }
      case'Completed':{
        return this.consultationTabledataSource = new MatTableDataSource<Consultation>(this.consultations_completed)

      } 
      case'Delayed':{      
        return this.consultationTabledataSource = new MatTableDataSource<Consultation>(this.consultations_delayed)
      }   
      default:{
        // console.log('consultation:')
        return this.consultationTabledataSource = new MatTableDataSource<Consultation>(this.consultations)
        
      }

    }  
        
  }

  // Called on Filter change
  filterSelection( event:any) {
    //let filterValues = {}
    switch(event){
      case 'Focus Group Discussion':{
        this.settingMatSortPaging();
        return this.consultationTabledataSource = new MatTableDataSource<Consultation>(this.consultations_type_fgd)
      }
      case 'Online Commenting':{
        this.settingMatSortPaging();
        return this.consultationTabledataSource = new MatTableDataSource<Consultation>(this.consultations_type_oc)  
      }
      case 'Survey':{
        this.settingMatSortPaging();
        return this.consultationTabledataSource = new MatTableDataSource<Consultation>(this.consultations_type_survey)     
      }
        
      default:{
        // console.log('consultation:')
        this.settingMatSortPaging();
        return this.consultationTabledataSource = new MatTableDataSource<Consultation>(this.consultations)
        
      }

    }
    
    // this.consultationTabledataSource.filter = JSON.stringify(this.filterValues);
  }
  
  setFilter(set:any){
    // console.log('filter: ',set)
    this.chosenFilter=set;
    return this.loadConsultation(this.chosenFilter);
  }
  
  dateChecker(choice:any,day1:any,day2:any,days?:any){    
    switch(choice){
      case 'Upcoming':
        day1 = new Date(day1);   
        return new Date(day2) < day1.setDate(day1.getDate() + (days||0))
      case 'Closing':
        day1 = new Date(day1);   
        return new Date(day2) <= day1.setDate(day1.getDate() + (days||0))
      case 'Delayed':
          return new Date(day2) > day1.setDate(day1.getDate())
      default:
        return null
    }
  }

  // getThisConsultation(id:any){
    
  //   // console.log('id: ', id)
  //  return new Promise((res,rej)=>{
  //   this.afs.collection('Public Consultations').doc(id).snapshotChanges()
  //   .pipe(first())
  //   .subscribe(info=>{
  //     let item:any = info.payload.data()
      
  //     item.short_title = item.subject
  //     // console.log('this_consultation: ', item)

  //     return res(item)
  //   })
  //  })
  // }

  // filter funcs
  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.consultationTabledataSource.filter = filterValue.trim().toLowerCase();
    if (this.consultationTabledataSource.paginator) {
      this.consultationTabledataSource.paginator.firstPage();
    }
  }

  applyFilters(form:any): void {
    const results:any = [];
    this.consultations.forEach(row => {
      results.push(row);
    });
    this.consultationTabledataSource.data = results;
  }

  changeFilter(value:any){
    this.consultationTabledataSource.filterPredicate = this.settingFilters(value)
  }

  settingFilters(searchColumns:any){
    if(searchColumns == 'defualt'){
      this.currentFilter = 'default'
      return this.defaultSearch
    }
    else if(searchColumns == 'type'){
      this.currentFilter = 'type'
      return this.typeSearch
    }
    else if(searchColumns == 'purpose'){
      this.currentFilter = 'purpose'
      return this.purposeSearch
    }
    else if(searchColumns == 'date'){
      this.currentFilter = 'date'
      return this.dateSearch
    }
    // else if(searchColumns == 'startdate'){
    //   this.currentFilter = 'startdate'
    //   return this.startDateSearch
    // }
    // else if(searchColumns == 'enddate'){
    //   this.currentFilter = 'enddate'
    //   return this.endDateSearch 
    // }
    
    this.currentFilter = 'default'
    return this.defaultSearch
  }

  defaultSearch = (data:any, filter:string):boolean=>{
    return (data.regulation_title.toLowerCase().includes(filter.toLowerCase()))
    && this.dateRange(data)
  }  

  resetExpandables(){
    this.expandedCommentCreate = null;
    this.expandedCommentView = null;
    this.expandedCommentPinned = null;
  }

  async setActive(id:any,clicked:any,element:any, mouseEvent:Event){
    mouseEvent.stopPropagation()
    // doesnt set another rows values to false
    // set all value to false first
    if(this.expandedCommentCreate){
      this.expandedCommentCreate.expand_comment_create = false;
      this.expandedCommentCreate.expand_comment_pin = false;
      this.expandedCommentCreate.expand_comment_list = false;
    }

    if(this.expandedCommentView){
      this.expandedCommentView.expand_comment_create = false;
      this.expandedCommentView.expand_comment_pin = false;
      this.expandedCommentView.expand_comment_list = false;
    }
    
    switch(clicked){
      case'view_list':
        // element.expand_comment_create = false;
        // element.expand_comment_pin = false;
        // element.expand_comment_list = !element.expand_comment_list;
        

        this.expandedCommentCreate = null;
        this.expandedCommentView = this.expandedCommentView === element ? null : element
        if(this.expandedCommentView) this.expandedCommentView.expand_comment_list = true
        break;
      case'create':
        // console.log('id: ', id)
          // this.this_consultation = element
        // this.this_consultation = await this.getThisConsultation(id);

          // element.expand_comment_list = false;
          // element.expand_comment_create = !element.expand_comment_create; 

          this.expandedCommentView = null;
          this.expandedCommentCreate = this.expandedCommentCreate === element ? null : element
          if(this.expandedCommentCreate) this.expandedCommentCreate.expand_comment_create = true
          this.this_consultation = this.expandedCommentCreate
       
      break;
      case'pin':   
        this.onPinned(element)   
        element.expand_comment_pin = !element.expand_comment_pin;  
      break;
      default:
        element.expand_comment_list = false;
        element.expand_comment_create = false;
        element.expand_comment_pin = false;
        break;
    }   
    // console.log('element', element)
    this.cosultation_id = id
  }

  onPinned(data:any){
    // console.log('consultation',data)
    if (data.hasExist) {
      let stat: any;
      let detail: any;
      if (data.status) {
        stat = false;
        detail = "Successfully unpinned"
      }
      else {
        stat = true
        detail = "Successfully pinned"
      } 
 
      this.afs.collection(`PBRIS Pinned Consultations`)
        .doc(data.pinned_id)
        .update({status: stat, date_updated: new Date()})
        .then((docRef:any) => {
        this.loading = false;
        this.consultations.map((consultation: any) => {
          if(consultation.consultation_id === data.id) {
            consultation.status = data.hasExist ? data.status ? false : true : true;
          }
          return consultation
        })

        // const variables = {
        //   regulationTitle: data.regulation_title,
        //   shortTitle:data.short_title,
        //   toDate: data.formated_to_date,
        //   fromDate: data.formated_from_date,
        //   purpose: data.purpose,
        // }

        // if(stat) this.nE.emailServiceHandler(['email'], 1, EmailTemplateCode, variables)

        Swal.close();
        Swal.fire({
          title: detail,
          icon: 'info'
        });
      }).catch((error:any) =>{
        this.loading = false;
        console.log("Error",error)
        Swal.close();
        Swal.fire({
          title:'System error',
          titleText: 'Please refresh the page',
          icon: 'error'
        });
      });
    }
    else {
      let info: any = [];
      info.push({
        user_id: this.user_info.user_id,
        consultation_id: data.consultation_id,
        status: true,
        date_updated: new Date()
      });
      this.afs.collection(`PBRIS Pinned Consultations`)
        .add(info[0])
        .then((docRef:any) => {
        this.loading = false;
        this.ngOnInit()

        // const variables = {
        //   regulationTitle: data.regulation_title,
        //   shortTitle:data.short_title,
        //   toDate: data.formated_to_date,
        //   fromDate: data.formated_from_date,
        //   purpose: data.purpose,
        // }

        // this.nE.emailServiceHandler(['email'], 1, EmailTemplateCode, variables)

        Swal.close();
        Swal.fire({
          title:'Successfully pinned',
          icon: 'info'
        });
      }).catch((error:any) =>{
        this.loading = false;
        console.log("Error",error)
        Swal.close();
        Swal.fire({
          title:'System error',
          titleText: 'Please refresh the page',
          icon: 'error'
        });
      });
    }
  }

  toSendNotifEmail(currentUsers:any[], templateCode:number,variables:any){
  }

  openCommentCreate(element:any){
    if(this.expandedCommentCreate === element){
      this.expandedCommentCreate = null;
    }else{      
      this.expandedCommentCreate = element;
      // this.resetExpandables()
    }
  }
  
  openCommentView(element:any){
    
    if(this.expandedCommentView === element){
      this.expandedCommentView = null;
    }else{      
      this.expandedCommentView = element;
      // this.resetExpandables()
    }
    // this.resetExpandables()    
    // this.expandedCommentView = element;
  }

  openCommentPin(element:any){
    if(this.expandedCommentPinned === element){
      this.expandedCommentPinned = null;
    }else{      
      this.expandedCommentPinned = element;
      // this.resetExpandables()
    }
  }

  typeSearch = (data:any, filter:string):boolean=>{
    return (data.type.toLowerCase().includes(filter.toLowerCase())) 
    && this.dateRange(data)
  }

  purposeSearch = (data:any, filter:string):boolean=>{
    return (data.purpose.toLowerCase().includes(filter.toLowerCase()))
    && this.dateRange(data)
  }

  dateSearch = (data:any, filter:string):boolean=>{
    return (data.formated_to_date.toLowerCase().includes(filter.toLowerCase())
    || data.formated_from_date.toLowerCase().includes(filter.toLowerCase())
    || data.to_date_Date.toString().toLowerCase().includes(filter.toLowerCase())
    || data.from_date_Date.toString().toLowerCase().includes(filter.toLowerCase()))
    && this.dateRange(data)
  }

  // startDateSearch = (data:any, filter:string):boolean=>{
  //   return data.formated_to_date.toLowerCase().includes(filter.toLowerCase())
  //   || data.to_date_Date.toString().toLowerCase().includes(filter.toLowerCase())
  // }

  // endDateSearch = (data:any, filter:string):boolean=>{
  //   return data.formated_from_date.toLowerCase().includes(filter.toLowerCase())
  //   || data.from_date_Date.toString().toLowerCase().includes(filter.toLowerCase())
  // }

  dateRange(data:any){
    if(this.filtersForm.value.range.sDate != null && this.filtersForm.value.range.eDate != null){
      if(this.sDateTS <= data.to_date && this.eDateTS >= data.from_date) return true
    }
    // else if(this.filtersForm.value.range.sDate && this.filtersForm.value.range.eDate == null){
    //   if(this.filtersForm.value.range.sDate == data.sDate)
    // }
    else if(this.filtersForm.value.range.sDate == null && this.filtersForm.value.range.eDate == null){
      return true
    }
    return false
  }

  refreshDatePicker(){
    this.filtersForm.patchValue({
      search:'',
    });
    this.filtersForm.controls.range.patchValue({
      sDate:null,
      eDate:null,
    });
    this.sDateTS = null;
    this.eDateTS = null;
    this.consultationTabledataSource = new MatTableDataSource<Consultation>(this.consultations)
    this.settingMatSortPaging();
    // this.consultationTabledataSource = this.consultationTabledataSource.data.filter((data:any)=>(this.sDateTS == null && this.eDateTS == null) ? true : false)
    // if (this.consultationTabledataSource.paginator) {
    //   this.consultationTabledataSource.paginator.firstPage();
    // }
    this.changeFilter(this.currentFilter)
  }

  applyDateRange(){
    this.sDateTS = this.filtersForm.value.range.sDate ? Timestamp.fromDate(this.filtersForm.value.range.sDate) : null;
    let beforeFormatEDate = this.filtersForm.value.range.eDate ?  new Date(this.filtersForm.value.range.eDate) : null;
    let formatedEdate:any;
    if(beforeFormatEDate){
      formatedEdate = new Date(beforeFormatEDate.setHours(+beforeFormatEDate.getHours() + +23))
      formatedEdate = new Date(formatedEdate.setMinutes(+formatedEdate.getMinutes() + +59))
      formatedEdate = new Date(formatedEdate.setSeconds(+formatedEdate.getSeconds() + +59))
    }
    this.eDateTS = formatedEdate ? Timestamp.fromDate(formatedEdate) : null;
    if(this.sDateTS != null && this.eDateTS != null){
      // this.consultationTabledataSource = new MatTableDataSource<Consultation>(this.consultations)
      
      this.consultationTabledataSource = new MatTableDataSource<Consultation>(this.consultations.filter((data:any)=>(this.sDateTS <= data.to_date && this.eDateTS >= data.from_date) ? true : false))
      this.settingMatSortPaging();
      if (this.consultationTabledataSource.paginator) {
        this.consultationTabledataSource.paginator.firstPage();
      }
      this.changeFilter(this.currentFilter)
    }else{
      this.refreshDatePicker()
    }
  }
  //

  // subwindow loader
  openLinkNewWindow(link:any){
    window.open(link)
  }

  // chosenConsultation:any = {
  //   id: null,
  //   cris_id: null,
  //   regulation_id: null,
  //   link: null,
  //   purpose: null,
  //   type: null,
  //   stakeholder: null,
  //   to_date: null,
  //   from_date: null,
  // };

  // consultationData:any = {
  //   cris_id: null,
  //   regulation_id: null,
  //   link: null,
  //   purpose: null,
  //   type: null,
  //   stakeholder: null,
  //   to_date: null,
  //   from_date: null,
  // }

  toPage(choice:String,consultation:Consultation,mouseEvent:Event,){
    // console.log('reg view: ', consultation)
    switch(choice){
      case 'to-consultation':{
        this.router.navigate(['pbris/consultation/view/'+consultation.consultation_id])
        break;
      }
      case 'to-regulation':{
        let from = "proposed"
      
        mouseEvent.stopPropagation()
        this.router.navigate(['/pbris/regulations/view/',from,consultation.regulation_id]);        
        break;
      }
    }
    // console.log('consultation',consultation.consultation_id)
  }

  filterArrayWithDynamicWhere(array:Array<any>,whereValues:any){
    return array.filter((item)=>this.checkItemValidity(item,whereValues))
  }

  checkItemValidity(item:any,whereValues:any){
    return !whereValues.map((whereValue:any)=>{
      if(whereValue.operator == Operators.EQUAL_TO) return item[whereValue.fieldName] == whereValue.values
      else if(whereValue.operator == Operators.NOT_EQUAL_TO) return item[whereValue.fieldName] != whereValue.values
      else if(whereValue.operator == Operators.GREATER_THAN) return item[whereValue.fieldName] > whereValue.filterValuess
      else if(whereValue.operator == Operators.LESS_THAN) return item[whereValue.fieldName] < whereValue.values
      else if(whereValue.operator == Operators.GREATER_THAN_OR_EQUAL) return item[whereValue.fieldName] >= whereValue.values
      else if(whereValue.operator == Operators.LESS_THAN_OR_EQUAL) return item[whereValue.fieldName] <= whereValue.values
      else return false
    }).includes(false)
  }

  /*
  // consultationChosen(consultation:Consultation){
  //   const ctr = this.consultationsClean.map(result=>result.consultation_id).indexOf(consultation.consultation_id)
  //   this.chosenConsultation = consultation
  //   this.consultationData = this.consultationsClean[ctr]
  //   if(this.isAdmin){ //admin
  //     this.childPbrisViewConsultation.refreshTable('admin',consultation.consultation_id,this.user_info.user_id,[])
  //   }
  //   else{ //pthers
  //     let agencyIdArr = []
  //     for(let ctr in consultation.reg_agency){
  //       agencyIdArr.push(consultation.reg_agency[ctr])
  //     }
  //     if(consultation.user_agency){ //agency
  //       this.childPbrisViewConsultation.refreshTable('agency',consultation.consultation_id,this.user_info.user_id,agencyIdArr)
  //     } 
  //     else if((!consultation.reg_agency || consultation.reg_agency == null) && this.isAdmin === null){ //public
  //       this.childPbrisViewConsultation.refreshTable('public',consultation.consultation_id,this.user_info.user_id,[])
  //     }
  //     else{
  //       this.childPbrisViewConsultation.refreshTable('empty',null,null,[])
  //     }
  //   }
  // }
  // */
}
