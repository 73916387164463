import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-pbris-user-feedback-view',
  templateUrl: './pbris-user-feedback-view.component.html',
  styleUrls: ['./pbris-user-feedback-view.component.scss']
})
export class PbrisUserFeedbackViewComponent implements OnInit {
  feedbackList: any;
  //Modal
  application: any;
  created_at: any;
  discover: any;
  elaborate: any;
  enhance: any;
  experience: any;
  feature: any;
  improve: any;
  otherDiscover: any;
  others: any;
  overall: any;
  refer: any;
  visit: any;

  constructor(private afs: AngularFirestore) { }

  ngOnInit(): void {
    this.getFeedbacks().subscribe(data => {
      this.feedbackList = data.map(e => {
        return {
          id: e.payload.doc.id,
          data: e.payload.doc.data()
        };
      })
    })
  }

  openModal(data: any) {
    this.application = data.application;
    this.created_at = data.created_at;
    this.discover = data.discover;
    this.elaborate = data.elaborate;
    this.enhance = data.enhance;
    this.experience = data.experience;
    this.feature = data.feature;
    this.improve = data.improve;
    this.otherDiscover = data.otherDiscover;
    this.others = data.others;
    this.overall = data.overall;
    this.refer = data.refer;
    this.visit = data.visit;
  }

  getFeedbacks() {
    return this.afs.collection('Feedback PBRIS', ref => ref.where('application', '==', 'feedback-pbris')).snapshotChanges();
  }
}
