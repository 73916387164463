<div class="row g-0">
  <div class="col">
    <div
      id="carouselExampleCaptions"
      class="carousel slide carousel-fade"
      data-mdb-ride="carousel"
    >
      <div class="carousel-indicators">
        <button
          type="button"
          data-mdb-target="#carouselExampleCaptions"
          data-mdb-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <!-- <button
          type="button"
          data-mdb-target="#carouselExampleCaptions"
          data-mdb-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-mdb-target="#carouselExampleCaptions"
          data-mdb-slide-to="2"
          aria-label="Slide 3"
        ></button> -->
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            src="/assets/Assets/LoginPagePBRIS.jpg"
            class="h-100 w-100"
            alt="..."
          />
        </div>
        <!-- <div class="carousel-item">
          <img src="/assets/02.jpg" class="h-100 w-100" alt="..." />
          <div class="carousel-caption">
            <h1 class="text-uppercase">Article Title Two</h1>
            <h4 class="text-uppercase">Second article</h4>
            <a href="#read" class="btn btn-lg btn-rounded btn-danger"
              >Read now</a
            >
          </div>
        </div>
        <div class="carousel-item">
          <img src="/assets/03.jpg" class="h-100 w-100" alt="..." />
          <div class="carousel-caption">
            <h1 class="text-uppercase">Article Title Three</h1>
            <h4 class="text-uppercase">Third article</h4>
            <a href="#read" class="btn btn-lg btn-rounded btn-danger"
              >Read now</a
            >
          </div>
        </div> -->
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-mdb-target="#carouselExampleCaptions"
        data-mdb-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-mdb-target="#carouselExampleCaptions"
        data-mdb-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
  
  <div class="col-1 ph-banner-strip ph-banner-strip-red"></div>
  <div class="col-1 ph-banner-strip ph-banner-strip-yellow"></div>
  <div class="col-1 ph-banner-strip ph-banner-strip-blue"></div>

</div>
