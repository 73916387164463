<div class="container tab-pane">
    <!-- Tabs navs -->
    <button class="{{style_theme}}-btn tab-button {{activeRecent}}" aria-current="page" (click)="openRecentTab()">
        <i class="far fa-star fa-5x"></i>
        <p>Recent Changes</p>
    </button>
    <button class="{{style_theme}}-btn tab-button {{activeTrending}}" aria-current="page" (click)="openTrendingTab()">
        <i class="fas fa-fire fa-5x"></i>
        <p>Trending</p>
    </button>
    <div class="d-flex justify-content-center align-self-center">
        <div class="spinner-grow text-info" role="status" *ngIf="!recentList">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <!-- Recent Changes Page -->
    <table [hidden]="!recentList" class="table table-light table-hover table-responsive" *ngIf="recentTab">
        <tbody>
            <tr *ngFor="let row of recentList" class="border-top border-bottom" routerLink="/artemis/view/detail/{{row.id}}">
                <td>
                    <i class="fas fa-caret-right fa-10x"></i>
                </td>
                <td style="cursor: pointer;">
                    <h4 style="cursor: pointer;" class='list-header'><span>{{row.serviceDetails.service_name}}</span></h4>
                    <p class="m-0">Service Provider: {{row.serviceDetails? row.serviceDetails.agency? row.serviceDetails.agency : "" : ""}}</p>
                    <p class="m-0">Classification: {{row.serviceDetails? row.serviceDetails.classification? row.serviceDetails.classification: "" :""}}</p>
                    <p *ngIf="row.eodb_tags" class="m-0">Sector: {{row.eodb_tags?  row.eodb_tags?   row.eodb_tags.sector : "" : ""}}</p>
                </td>
                <td></td>
            </tr>
        </tbody>
        <!--
        <tfoot>
            <div class="btn-group m-3">
                <div class="btn btn-sm btn-light">Previous</div>
                <div class="btn btn-sm btn-light">Next</div>
            </div>
        </tfoot>
        -->
    </table>

    <!--Trending Page-->
    <table [hidden]="!trendingList" class="table table-light table-hover table-responsive" *ngIf="trendingTab">
        <tbody>
            <tr *ngFor="let row of trendingList" class="border-top border-bottom" routerLink="/artemis/view/detail/{{row.id}}">
                <td>
                    <i class="fas fa-caret-right fa-10x"></i>
                </td>
                <td style="cursor: pointer;">
                    <h4 style="cursor: pointer;" class='list-header'><span>{{row.serviceDetails.service_name}}</span></h4>
                    <p class="m-0">Service Provider: {{row.serviceDetails? row.serviceDetails.agency? row.serviceDetails.agency : "" : ""}}</p>
                    <p class="m-0">Classification: {{row.serviceDetails? row.serviceDetails.classification? row.serviceDetails.classification: "" :""}}</p>
                    <p *ngIf="row.eodb_tags" class="m-0">Sector: {{row.eodb_tags?  row.eodb_tags?   row.eodb_tags.sector : "" : ""}}</p>
                </td>
                <td></td>
            </tr>
        </tbody>
        <!--
        <tfoot>
            <div class="btn-group m-3">
                <div class="btn btn-sm btn-light">Previous</div>
                <div class="btn btn-sm btn-light">Next</div>
            </div>
        </tfoot>
        -->
    </table>

</div>