<section>

    <!-- head of agency -->
    <div class="staff">
        <form class="form-group d-flex flex-row align-items-center" [formGroup]="agencyHoAForm">
            <span *ngIf="(isAdmin || isAgencyMember) && !editHeadOfAgencyTitle"  class="p-2">
                <a (click)="toggleEdit('headOfAgencyTitle')" style="z-index: 100; cursor: pointer;"
                    class="report-title fw-bold"
                    title="Edit Agency Head Title">
                    <i class="fas fa-edit"></i>
                </a>
            </span>
            <span *ngIf="!editHeadOfAgencyTitle" style="margin-right: 1em;"><h2 class="fw-bold report-title theme-default-text sm-text">
                <!-- Agency Head -->
                {{cartDetails?.headOfAgencyTitle ? cartDetails.headOfAgencyTitle : 'Agency Head'}}
            </h2></span>
            <ng-container *ngIf="editHeadOfAgencyTitle">
                <button type="button" style="margin-left: 1em;" (click)="toggleEdit('headOfAgencyTitle')" class="btn btn-danger" [disabled]="headOfAgencyLoading">Cancel</button>
                <input class="form-control" formControlName="title" placeholder="Default Title: Agency Head">
                <button type="button" style="margin-right: 1em;" (click)="update('headOfAgencyTitle')" class="btn update-btn btn-primary" [disabled]="headOfAgencyLoading">
                    <span class="spinner-border spinner-border-sm mr-1" *ngIf="headOfAgencyLoading"></span>Update
                </button>
            </ng-container>
            <button *ngIf="(isAdmin || isAgencyMember) && getFormsArray('hOA').length == 0" type="button" class="btn btn-primary btn-circle" (click)="openDia('create','hOA',0)" ><span class="material-icons">add_circle_outline</span></button>
        </form>
        <div [formGroup]="agencyHoAForm" class="row staffs-container d-flex justify-content-evenly">
            <ng-container formArrayName="agencyHoAArray" >
                <ng-container *ngFor="let item of getFormsArray('hOA'); let i = index; let c = count">
                    <div class="col-sm-12 col-md-8 col-lg-5 card border-primary bottom-space">
                        <div class="card-body">
                            <div class="d-flex flex-row align-items-center mini-space">
                                <span class="staff-icon right-space">{{getAcronym(item.value.name)}}</span>
                                <p class="wrapper"><strong>{{item.value.name}}</strong></p>
                            </div>
                            <div class="mini-space d-flex flex-row align-items-center">
                                <strong  class="right-space"><i class="fas fa-id-badge"></i></strong>
                                <p class="wrapper"><i>{{item.value.designation}}</i></p>
                            </div>
                            <div class="d-flex flex-row align-items-center">
                                <strong class="right-space"><i class="fas fa-envelope"></i></strong>
                                <p class="wrapper">{{item.value.email}}</p>
                            </div>
                        </div>
                        <div *ngIf="isAdmin || isAgencyMember" class="card-footer d-flex flex-row justify-content-evenly align-items-center">
                            <a class="linkButtons" (click)="openDia('edit','hOA',i)"><i class="fas fa-edit"></i> Edit</a>
                            <!-- <a class="linkButtons" (click)="openDia('delete','hOA',i)" *ngIf="c > 1"><i class="fas fa-minus-circle"></i> Remove</a> -->
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <!-- Chaiperson -->
    <div class="staff">
        <!-- <div class="d-flex flex-row align-items-center">
            <span style="margin-right: 1em;"><h2 class="fw-bold report-title theme-default-text sm-text">
                Chairperson
                {{cartDetails?.chairPersonTitle ? cartDetails.chairPersonTitle : 'Chairperson/s'}}
            </h2></span>
            <button *ngIf="isAdmin || isAgencyMember" type="button" class="btn btn-primary btn-circle" (click)="openDia('create','cP',0)" ><span class="material-icons">add_circle_outline</span></button>
        </div> -->
        <form class="form-group d-flex flex-row align-items-center" [formGroup]="agencyCPForm">
            <span *ngIf="(isAdmin || isAgencyMember) && !editChairPersonTitle"  class="p-2">
                <a (click)="toggleEdit('chairPersonTitle')" style="z-index: 100; cursor: pointer;"
                    class="report-title fw-bold"
                    title="Edit Agency Head Title">
                    <i class="fas fa-edit"></i>
                </a>
            </span>
            <span *ngIf="!editChairPersonTitle" style="margin-right: 1em;"><h2 class="fw-bold report-title theme-default-text sm-text">
                <!-- Agency Head -->
                {{cartDetails?.chairPersonTitle ? cartDetails.chairPersonTitle : 'Chairpersons'}}
            </h2></span>
            <ng-container *ngIf="editChairPersonTitle">
                <button type="button" style="margin-left: 1em;" (click)="toggleEdit('chairPersonTitle')" class="btn btn-danger" [disabled]="chairPersonLoading">Cancel</button>
                <input class="form-control" formControlName="title" placeholder="Default Title: Chairpersons">
                <button type="button" style="margin-right: 1em;" (click)="update('chairPersonTitle')" class="btn update-btn btn-primary" [disabled]="chairPersonLoading">
                    <span class="spinner-border spinner-border-sm mr-1" *ngIf="chairPersonLoading"></span>Update
                </button>
            </ng-container>
            <button *ngIf="(isAdmin || isAgencyMember)" type="button" class="btn btn-primary btn-circle" (click)="openDia('create','cP',0)" ><span class="material-icons">add_circle_outline</span></button>
        </form>
        <div [formGroup]="agencyCPForm" class="row staffs-container d-flex justify-content-evenly">
            <ng-container formArrayName="agencyCPArray" >
                <ng-container *ngFor="let item of getFormsArray('cP'); let i = index; let c = count">
                    <div class="col-sm-12 col-md-8 col-lg-5 card border-primary bottom-space">
                        <div class="card-body">
                            <div class="d-flex flex-row align-items-center mini-space">
                                <span class="staff-icon right-space">{{getAcronym(item.value.name)}}</span>
                                <p class="wrapper"><strong>{{item.value.name}}</strong></p>
                            </div>
                            <div class="mini-space d-flex flex-row align-items-center">
                                <strong  class="right-space"><i class="fas fa-id-badge"></i></strong>
                                <p class="wrapper"><i>{{item.value.designation}}</i></p>
                            </div>
                            <div class="d-flex flex-row align-items-center">
                                <strong class="right-space"><i class="fas fa-envelope"></i></strong>
                                <p class="wrapper">{{item.value.email}}</p>
                            </div>
                        </div>
                        <div *ngIf="isAdmin || isAgencyMember" class="card-footer d-flex flex-row justify-content-evenly align-items-center">
                            <a class="linkButtons" (click)="openDia('edit','cP',i)"><i class="fas fa-edit"></i> Edit</a>
                            <a class="linkButtons" (click)="openDia('delete','cP',i)" *ngIf="c > 1"><i class="fas fa-minus-circle"></i> Remove</a>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <!-- vice Chaiperson -->
    <div class="staff">
        <!-- <div class="d-flex flex-row align-items-center">
            <span style="margin-right: 1em;"><h2 class="fw-bold report-title theme-default-text sm-text">
                Vice-Chairperson
                {{cartDetails?.viceChairPersonTitle ? cartDetails.viceChairPersonTitle : 'Vice-Chairperson/s'}}
            </h2></span>
            <button *ngIf="isAdmin || isAgencyMember" type="button" class="btn btn-primary btn-circle" (click)="openDia('create','vCP',0)" ><span class="material-icons">add_circle_outline</span></button>
        </div> -->
        <form class="form-group d-flex flex-row align-items-center" [formGroup]="agencyVCPForm">
            <span *ngIf="(isAdmin || isAgencyMember) && !editViceChairPersonTitle"  class="p-2">
                <a (click)="toggleEdit('viceChairPersonTitle')" style="z-index: 100; cursor: pointer;"
                    class="report-title fw-bold"
                    title="Edit Agency Head Title">
                    <i class="fas fa-edit"></i>
                </a>
            </span>
            <span *ngIf="!editViceChairPersonTitle" style="margin-right: 1em;"><h2 class="fw-bold report-title theme-default-text sm-text">
                <!-- Agency Head -->
                {{cartDetails?.viceChairPersonTitle ? cartDetails.viceChairPersonTitle : 'Vice-Chairpersons'}}
            </h2></span>
            <ng-container *ngIf="editViceChairPersonTitle">
                <button type="button" style="margin-left: 1em;" (click)="toggleEdit('viceChairPersonTitle')" class="btn btn-danger" [disabled]="viceChairPersonLoading">Cancel</button>
                <input class="form-control" formControlName="title" placeholder="Default Title: Vice-Chairpersons">
                <button type="button" style="margin-right: 1em;" (click)="update('viceChairPersonTitle')" class="btn update-btn btn-primary" [disabled]="viceChairPersonLoading">
                    <span class="spinner-border spinner-border-sm mr-1" *ngIf="viceChairPersonLoading"></span>Update
                </button>
            </ng-container>
            <button *ngIf="(isAdmin || isAgencyMember)" type="button" class="btn btn-primary btn-circle" (click)="openDia('create','vCP',0)" ><span class="material-icons">add_circle_outline</span></button>
        </form>
        <div [formGroup]="agencyVCPForm" class="row staffs-container d-flex justify-content-evenly">
            <ng-container formArrayName="agencyVCPArray" >
                <ng-container *ngFor="let item of getFormsArray('vCP'); let i = index; let c = count">
                    <div class="col-sm-12 col-md-8 col-lg-5 card border-primary bottom-space">
                        <div class="card-body">
                            <div class="d-flex flex-row align-items-center mini-space">
                                <span class="staff-icon right-space">{{getAcronym(item.value.name)}}</span>
                                <p class="wrapper"><strong>{{item.value.name}}</strong></p>
                            </div>
                            <div class="mini-space d-flex flex-row align-items-center">
                                <strong  class="right-space"><i class="fas fa-id-badge"></i></strong>
                                <p class="wrapper"><i>{{item.value.designation}}</i></p>
                            </div>
                            <div class="d-flex flex-row align-items-center">
                                <strong class="right-space"><i class="fas fa-envelope"></i></strong>
                                <p class="wrapper">{{item.value.email}}</p>
                            </div>
                        </div>
                        <div *ngIf="isAdmin || isAgencyMember" class="card-footer d-flex flex-row justify-content-evenly align-items-center">
                            <a class="linkButtons" (click)="openDia('edit','vCP',i)"><i class="fas fa-edit"></i> Edit</a>
                            <a class="linkButtons" (click)="openDia('delete','vCP',i)" *ngIf="c > 1"><i class="fas fa-minus-circle"></i> Remove</a>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <!-- Members -->
    <div class="staff">
        <!-- <div class="d-flex flex-row align-items-center">
            <span style="margin-right: 1em;"><h2 class="fw-bold report-title theme-default-text sm-text">
                Member/s
                {{cartDetails?.membersTitle ? cartDetails.membersTitle : 'Member/s'}}
            </h2></span>
            <button *ngIf="isAdmin || isAgencyMember" type="button" class="btn btn-primary btn-circle" (click)="openDia('create','memb',0)" ><span class="material-icons">add_circle_outline</span></button>
        </div> -->
        <form class="form-group d-flex flex-row align-items-center" [formGroup]="agencyMemForm">
            <span *ngIf="(isAdmin || isAgencyMember) && !editMembersTitle"  class="p-2">
                <a (click)="toggleEdit('membersTitle')" style="z-index: 100; cursor: pointer;"
                    class="report-title fw-bold"
                    title="Edit Agency Head Title">
                    <i class="fas fa-edit"></i>
                </a>
            </span>
            <span *ngIf="!editMembersTitle" style="margin-right: 1em;"><h2 class="fw-bold report-title theme-default-text sm-text">
                <!-- Agency Head -->
                {{cartDetails?.membersTitle ? cartDetails.membersTitle : 'Members'}}
            </h2></span>
            <ng-container *ngIf="editMembersTitle">
                <button type="button" style="margin-left: 1em;" (click)="toggleEdit('membersTitle')" class="btn btn-danger" [disabled]="membersLoading">Cancel</button>
                <input class="form-control" formControlName="title" placeholder="Default Title: Members">
                <button type="button" style="margin-right: 1em;" (click)="update('membersTitle')" class="btn update-btn btn-primary" [disabled]="membersLoading">
                    <span class="spinner-border spinner-border-sm mr-1" *ngIf="membersLoading"></span>Update
                </button>
            </ng-container>
            <button *ngIf="(isAdmin || isAgencyMember)" type="button" class="btn btn-primary btn-circle" (click)="openDia('create','memb',0)" ><span class="material-icons">add_circle_outline</span></button>
        </form>
        <div [formGroup]="agencyMemForm" class="row staffs-container d-flex justify-content-evenly">
            <ng-container formArrayName="agencyMemArray" >
                <ng-container *ngFor="let item of getFormsArray('memb'); let i = index; let c = count">
                    <div class="col-sm-12 col-md-8 col-lg-5 card border-primary bottom-space">
                        <div class="card-body">
                            <div class="d-flex flex-row align-items-center mini-space">
                                <span class="staff-icon right-space">{{getAcronym(item.value.name)}}</span>
                                <p class="wrapper"><strong>{{item.value.name}}</strong></p>
                            </div>
                            <div class="mini-space d-flex flex-row align-items-center">
                                <strong  class="right-space"><i class="fas fa-id-badge"></i></strong>
                                <p class="wrapper"><i>{{item.value.designation}}</i></p>
                            </div>
                            <div class="d-flex flex-row align-items-center">
                                <strong class="right-space"><i class="fas fa-envelope"></i></strong>
                                <p class="wrapper">{{item.value.email}}</p>
                            </div>
                        </div>
                        <div *ngIf="isAdmin || isAgencyMember" class="card-footer d-flex flex-row justify-content-evenly align-items-center">
                            <a class="linkButtons" (click)="openDia('edit','memb',i)"><i class="fas fa-edit"></i> Edit</a>
                            <a class="linkButtons" (click)="openDia('delete','memb',i)" *ngIf="c > 1"><i class="fas fa-minus-circle"></i> Remove</a>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</section>

<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-colName="colName" let-index="index" let-count="count">
        <button mat-menu-item (click)="openDia('view',colName,index)">View</button>
        <button mat-menu-item (click)="openDia('edit',colName,index)">Update</button>
        <button mat-menu-item *ngIf="count>1" (click)="openDia('delete',colName,index)">Delete</button>
    </ng-template>
</mat-menu>
