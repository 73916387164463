import { PDFDocumentProxy } from 'pdfjs-dist';
import { first } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { PdfRenderService } from '../../pdf-render.service';
import { format } from 'date-fns';
import firebase from "firebase/app";
import Swal from 'sweetalert2';
import { forkJoin,combineLatest } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl, FormGroup, FormArray, Validators, FormBuilder } from '@angular/forms';
// import { data } from 'jquery';
// import e from 'cors';

@Component({
  selector: 'app-regulations',
  templateUrl: './regulations.component.html',
  styleUrls: ['./regulations.component.scss']
})
export class RegulationsComponent implements OnInit {
  userAgencyId = sessionStorage.getItem('agency_id')
  userAgencyAllowed:boolean = false

  from:string = this.route.snapshot.params.from;
  id = this.route.snapshot.params.id;
  isAdmin: boolean = false;
  collection:any;
  prevProposedReg:boolean = false;
  isAgencyUser:boolean = false;
  isArtaUser:boolean = false;

  status: string = "non-specific";
  regulation: any = "";
  proposedRegulation:any = "";
  file: any;
  filePage:any = 1;
  fileTotalPage:any;

  mainPDFFile:any

  show_content: boolean = true;
  show_comment: boolean = false;

  loading: boolean = false;

  regulation_info: any;
  regulation_status: boolean = false;
  reg_id: any = null;
  user_id: any = null;

  intl_agreements: any = [];

  crisData:any;
  consultationsData:any = [];
  drisData:any;

  //tab views:
  pdfView:boolean = true;
  phase2View:boolean = false;
  textRegView:boolean = false;
  crisView:boolean = false;
  drisView:boolean = false;
  piaView:boolean = false;
  notificationsView:boolean = false;
  regDetailsView:boolean = false;
  piaAssessmentView: boolean = false;
  riaAssessmentView: boolean = false;
  submitCommentView: boolean = false;

  //
  has_existing:boolean = false;
  from_proposed:boolean = false;
  has_cris:boolean = false;


  pis_assessment: any;
  flagged_sections: FormArray;
  flaggedSectionIndexes: number[] = [];
  
  has_pia_assessment:boolean = false

  has_ria_assessment:boolean = false

  textRegulationData:any = {}

  regulationDetailsData:any = {};

  uploadDetailsData:any = {};
  
  collectionInfo = {
    existing:{
      baseCollection:'regulations-existing-phase-02',
      worklistCollection:'Worklist-Existing Regulations',
    },
    proposed:{
      baseCollection:'regulations-proposed-phase-02',
      worklistCollection:'Worklist-Proposed Regulations',
    },
    cris:{
      baseCollection:'regulations-proposed-cris',
      worklistCollection:'Worklist-Proposed Regulations',
    },
    dris:{
      baseCollection:'regulations-proposed-dris',
      worklistCollection:'Worklist-Proposed Regulations',
    },
    pbris1:{
      baseCollection:'PBRIS 1 Regulations',
      worklistCollection:null,
    },
  }

  regulationData:any = {
    // CURRENT:{},
    // WORKLIST:{},
    // EXISTING:{},
    // INITIAL:{},
    // CRIS:{},
    // DRIS:{},
  }

  hasData:any = {
    existing: false,
    initial: false,
    cris: false,
    dris: false,
    piaAssessment: false,
    riaAssessment: false,
    consultations: false,
    uploads: false,
  }

  regulationDetails:any = {
    EXISTING:{},
    INITIAL:{},
    CRIS:{},
    DRIS:{},
  }

  showViews:any = {
    pdfView: false,
    editRegulationDatesView: false,
    regulationDetailsView: false,
    textRegView: false,
    piaView: false,
    piaAssessmentView: false,
    riaAssessmentView: false,
    notificationsView: false,
    consultationsView: false,
    uploadsView: false,
    submitCommentView: false,
  }

  loadedData:any = {

  }

  constructor(private route: ActivatedRoute,
    private store: AngularFireStorage,
    private afs: AngularFirestore,
    private pdf_render: PdfRenderService,
    private router: Router, 
    public auth: AngularFireAuth,
    private fB: FormBuilder,) { }

  ngOnInit(): void {

    // this.onPDFView()
    this.onView('pdfView')
    if (sessionStorage.getItem('arta_admin') == 'true') {
      this.isAdmin = true;
      this.isArtaUser = true;
    }

    if(sessionStorage.getItem('user_type') == 'agency'){
      this.isAgencyUser = true;
    }

    this.loadStatus(this.status)
    if(this.from === "existing" || this.from === "proposed"){
      this.collection = this.collectionInfo[this.from].baseCollection
      this.loadData(this.id,this.collection,this.from)
      // if(this.from === "existing"){
      //   this.collection = "regulations-existing-phase-02"
      //   this.loadInfo(this.id,this.collection,this.from)
      // }
      // else if(this.from === "proposed"){
      //   this.collection = "regulations-proposed-phase-02"
      //   this.loadInfo(this.id,this.collection,this.from)
      // }
    }
    else{
      // console.log('hit PBRIS 1 Regulations: ')
      // this.collection = "PBRIS 1 Regulations"
      this.collection = this.collectionInfo['pbris1'].baseCollection
      // this.loadInfo(this.id,this.collection,null)
      this.loadData(this.id,this.collection,null)
    }
  }

  loadStatus(getStatus: string) {
    this.status = getStatus.charAt(0).toUpperCase() + getStatus.slice(1)
  }

  async loadData(id: any, collection:any, from:any){
    this.afs.collection(collection).doc(id).snapshotChanges()
    .subscribe({
      next:async (dataResult)=>{ 
        if(dataResult.payload.exists){
          let item:any = dataResult.payload.data()
          item.id = dataResult.payload.id;
          console.log('from: '+from+' | regulation: ',item)
          if(from == 'existing'){
            this.hasData.existing = true

            if(item.title) item.subject_full_title = item.title
            if(item.subject)item.short_title = item.subject
            if(item.doc_date) item.displayDate = item.doc_date

            this.regulationDetailsData.existing = {}
            this.regulationDetailsData.existing.controlCode = 'existing'
            this.regulationDetailsData.existing.data = {}
            this.regulationDetailsData.existing.data.title = item.title
            this.regulationDetailsData.existing.data.subject = item.subject

            if(item.proposed_id) this.loadRisDataByID(item.proposed_id,true)
  
            if(item.reg_agency){
              if(Array.isArray(item.reg_agency)) {
                if(item.reg_agency.length > 0){
                  const agency_names:any = await this.multiDataLoader('Agency','Agency',item.reg_agency)
                  if(agency_names && agency_names.length > 0){
                    this.regulationDetailsData.existing.data.agency_list = agency_names
                    item.issuing_agency = agency_names.toString()
                  }
                  else {
                    console.warn('Error: no agency name')
                    this.regulationDetailsData.proposed.data.agency_list = []
                  }
                }

                if(item.reg_agency.includes(this.userAgencyId)) this.userAgencyAllowed = true
                else this.userAgencyAllowed = false
              }else{
                let temp_agency_list:any = []
                temp_agency_list.push(item.reg_agency)
                const agency_names:any = await this.multiDataLoader('Agency','Agency',temp_agency_list)
                // console.log('agency_names: ',agency_names)
                if(agency_names && agency_names.length > 0){
                    this.regulationDetailsData.existing.data.agency_list = agency_names
                    item.issuing_agency = agency_names.toString()
                  }
                else {
                  console.warn('Error: no agency name')
                  this.regulationDetailsData.proposed.data.agency_list = []
                }

                if(item.reg_agency == this.userAgencyAllowed) this.userAgencyAllowed = true
                else this.userAgencyAllowed = false
              }
            }
            // this.regulationDetailsData.existing.data.agency_list = agency_list
            if(item.reg_classification){
              item.classification = item.reg_classification
              this.regulationDetailsData.existing.data.classification = item.reg_classification
              if (item.reg_classification == 'Business') {
                if(item.reg_sector){
                  const sectorData:any = await this.dataLoader('Sector','Sector',item.reg_sector)
                  if(sectorData){ 
                    item.sector_name = sectorData.name
                    this.regulationDetailsData.existing.data.sector_name = sectorData.name
                  }
                  else console.warn('No sector data')
                }
  
                if(item.reg_business){
                  const businessData:any = await this.dataLoader('StageOfBusiness','Stage of Business',item.reg_business)
                  if(businessData){
                    item.business_name = businessData.name
                    this.regulationDetailsData.existing.data.business_name = businessData.name
                  }
                  else console.warn('No business data')
                }
      
                if(item.reg_division){
                  const divisionData:any = await this.dataLoader('Division','Division',item.reg_division)
                  if(divisionData){
                    item.division_name = divisionData.name
                    this.regulationDetailsData.existing.data.division_name = divisionData.name
                  }
                  else console.warn('No division data')
                }
                
                if(item.reg_case){
                  const caseData:any = await this.dataLoader('CaseUse','Case Use',item.reg_case)
                  if(caseData){
                    item.case_name = caseData.name
                    this.regulationDetailsData.existing.data.case_name = caseData.name
                  }
                  else console.warn('No case use data')
                }
              }
              else if (item.reg_classification == 'Non-Business') {
                if(item.reg_stageoflife){
                  const solData:any = await this.dataLoader('StageOfLife','Stage of Life',item.reg_stageoflife)
                  if(solData){
                    item.sol_name = solData.stage
                    this.regulationDetailsData.existing.data.sol_name = solData.stage
                  }
                  else console.warn('No sol data')
                }
                
                if(item.reg_lifeevent){
                  const leData:any = await this.dataLoader('LifeEvent','Life Event',item.reg_lifeevent)
                  if(leData){
                    item.event_name = leData.event
                    this.regulationDetailsData.existing.data.event_name = leData.event
                  }
                  else console.warn('No event data')
                }
              }
            }

            await this.subTagsHandler(item, this.regulationDetailsData.existing.data);

            if(item.reg_juris){
              const jursiData:any = await this.loadDataId('Jurisdiction',item.reg_juris)
              if(jursiData){ 
                item.juris_name = jursiData.name
                this.regulationDetailsData.existing.data.juris_name = jursiData.name
              }
              else console.warn('No jurisdiction data')
            }

            if(item.reg_instrument){
              const loadedData:any = await this.dataLoader('Instrument','Instrument',item.reg_instrument)
              if(loadedData){ 
                item.instrument_name = loadedData.name
                this.regulationDetailsData.existing.data.instrument_name = loadedData.name
              }
              else console.warn('No instrument data')
            }

            if(item.reg_number) this.regulationDetailsData.existing.data.reg_number = item.reg_number

            if(item.reg_significance) this.regulationDetailsData.existing.data.reg_significance = item.reg_significance

            if(item.doc_date) this.regulationDetailsData.existing.data.doc_date = item.doc_date

            if(item.date_published_onar) this.regulationDetailsData.existing.data.date_published_onar = item.date_published_onar

            if(item.date_published_gazette) this.regulationDetailsData.existing.data.date_published_gazette = item.date_published_gazette

            if(item.effectivity_type) this.regulationDetailsData.existing.data.effectivity_type = item.effectivity_type

            if(item.date_effective) this.regulationDetailsData.existing.data.date_effective = item.date_effective

            if(item.reg_stageoflife) this.regulationDetailsData.existing.data.reg_stageoflife = item.reg_stageoflife

            if(item.lifeevent_name) this.regulationDetailsData.existing.data.lifeevent_name = item.lifeevent_name

            if(item.reg_legal){
              this.regulationDetailsData.existing.data.legalBases_list = item.reg_legal
            }

            if(item.reg_intl){
              this.regulationDetailsData.existing.data.internationalAgreement_list = item.reg_intl
            }
            
            if(item.firebase_folder && item.file_file_name){
              this.mainPDFFile = {}
              this.mainPDFFile.fileFolder = item.firebase_folder
              this.mainPDFFile.fileName = item.file_file_name
              let path = `${item.firebase_folder}/${item.file_file_name}`;
              this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
                // this.file = data; 
                this.mainPDFFile.file = data;
                this.mainPDFFile.file_page = 1;
              });
            }

            if(item.regulation_text) {
              this.textRegulationData.existing = {}
              this.textRegulationData.existing.controlCode = 'existing'
              this.textRegulationData.existing.data = await this.textRegulationHandler(item.regulation_text)
            }

            if(item.has_annex_files){
              let reg_text_annex_file:any = {
                has_annex_files: item.has_annex_files
              }
              
              if(item.regulation_text_annex_file_folder && item.regulation_text_annex_file_name){
                reg_text_annex_file.fileFolder = item.regulation_text_annex_file_folder
                reg_text_annex_file.fileName = item.regulation_text_annex_file_name
                let path = `${reg_text_annex_file.fileFolder}/${reg_text_annex_file.fileName}`;
  
                let tokenized = item.regulation_text_annex_file_name.split(".")
                if(tokenized && tokenized.length > 0 && tokenized[tokenized.length-1] == 'zip'){
                  reg_text_annex_file.file_type = 'zip';
                  this.store.ref(path).getDownloadURL()
                  .subscribe((url)=>{
                    reg_text_annex_file.file_download_link = url
                    this.textRegulationData.existing.reg_text_annex_file = reg_text_annex_file
                  })
                }
                else{
                  this.pdf_render.getFileUrl(path).toPromise()
                  .then((data: any) => {
                    // this.file = data; 
                    reg_text_annex_file.file = data;
                    reg_text_annex_file.file_page = 1;
                    this.textRegulationData.existing.reg_text_annex_file = reg_text_annex_file
                    // return true
                  })
                  .catch((err)=>{
                    reg_text_annex_file.file_page = 1;
                    this.textRegulationData.existing.reg_text_annex_file = reg_text_annex_file
                    console.error('Error: ',err)
                    // return true
                  });
                }
  
              }
              else{
                this.textRegulationData.existing.reg_text_annex_file = reg_text_annex_file
              }
            }
            
            
            this.regulationData.EXISTING = item
            this.regulationData.CURRENT = item
          }
          else if(from == 'proposed'){
            console.log('has existing: ',this.hasData.existing)
            this.hasData.initial = true;
            this.regulationDetailsData.proposed = {}

            this.regulationDetailsData.proposed.controlCode = 'proposed'
            this.regulationDetailsData.proposed.data = {}
            this.regulationDetailsData.proposed.data.title = item.title
            this.regulationDetailsData.proposed.data.subject = item.subject


            if(!this.hasData.existing) this.loadRisDataByWhere(item.id,false)

            if(item.regulation_text){
              this.textRegulationData.proposed = {}
              this.textRegulationData.proposed.controlCode = 'proposed'
              if(item.has_draft_text) this.textRegulationData.proposed.data = await this.textRegulationHandler(item.regulation_text)
            }

            if(item.has_annex_files){
              let reg_text_annex_file:any = {
                has_annex_files: item.has_annex_files,
              }
              if(item.regulation_text_annex_file_folder && item.regulation_text_annex_file_name){
                reg_text_annex_file.fileFolder = item.regulation_text_annex_file_folder
                reg_text_annex_file.fileName = item.regulation_text_annex_file_name
                let path = `${reg_text_annex_file.fileFolder}/${reg_text_annex_file.fileName}`;
  
                let tokenized = item.regulation_text_annex_file_name.split(".")
                if(tokenized && tokenized.length > 0 && tokenized[tokenized.length-1] == 'zip'){
                  reg_text_annex_file.file_type = 'zip';
                  this.store.ref(path).getDownloadURL()
                  .subscribe((url)=>{
                    reg_text_annex_file.file_download_link = url
                    this.textRegulationData.proposed.reg_text_annex_file = reg_text_annex_file
                  })
                }
                else{
                  this.pdf_render.getFileUrl(path).toPromise()
                  .then((data: any) => {
                    // this.file = data; 
                    reg_text_annex_file.file = data;
                    reg_text_annex_file.file_page = 1;
                    this.textRegulationData.proposed.reg_text_annex_file = reg_text_annex_file
                    // return true
                  })
                  .catch((err)=>{
                    reg_text_annex_file.file_page = 1;
                    this.textRegulationData.proposed.reg_text_annex_file = reg_text_annex_file
                    console.error('Error: ',err)
                    // return true
                  });
                }
  
              }
              else{
                this.textRegulationData.proposed.reg_text_annex_file = reg_text_annex_file
              }
            }

            if(item.reg_agency){
              if(Array.isArray(item.reg_agency)) {
                const agency_names:any = await this.multiDataLoader('Agency','Agency',item.reg_agency)
                if(agency_names && agency_names.length > 0){
                  this.regulationDetailsData.proposed.data.agency_list = agency_names
                  item.issuing_agency = agency_names.toString()
                }
                else {
                  console.warn('Error: no agency name')
                  this.regulationDetailsData.proposed.data.agency_list = []
                }
              }
              else{
                let temp_agency_list:any = []
                temp_agency_list.push(item.reg_agency)
                const agency_names:any = await this.multiDataLoader('Agency','Agency',temp_agency_list)
                if(agency_names && agency_names.length > 0){ 
                  this.regulationDetailsData.proposed.data.agency_list = agency_names
                  item.issuing_agency = agency_names.toString()
                  console.log(this.regulationDetailsData.proposed.data.issuing_agency)
                }
                else {
                  console.warn('Error: no agency name')
                  this.regulationDetailsData.proposed.data.agency_list = []
                }
              }
            }

            if(item.tags){
              if(item.tags.reg_classification){
                item.classification = item.tags.reg_classification
                item.reg_classification = item.tags.reg_classification
                this.regulationDetailsData.proposed.data.classification = item.tags.reg_classification
                if (item.tags.reg_classification == 'Business') {
                  if(item.tags.reg_sector){
                    const sectorData:any = await this.dataLoader('Sector','Sector',item.tags.reg_sector)
                    if(sectorData){ 
                      item.sector_name = sectorData.name
                      this.regulationDetailsData.proposed.data.sector_name = sectorData.name
                    }
                    else console.warn('No sector data')
                  }
    
                  if(item.tags.reg_business){
                    const businessData:any = await this.dataLoader('StageOfBusiness','Stage of Business',item.tags.reg_business)
                    if(businessData){
                      item.business_name = businessData.name
                      this.regulationDetailsData.proposed.data.business_name = businessData.name
                    }
                    else console.warn('No business data')
                  }
        
                  if(item.tags.reg_division){
                    const divisionData:any = await this.dataLoader('Division','Division',item.tags.reg_division)
                    if(divisionData){
                      item.division_name = divisionData.name
                      this.regulationDetailsData.proposed.data.division_name = divisionData.name
                    }
                    else console.warn('No division data')
                  }
                  
                  if(item.tags.reg_case){
                    const caseData:any = await this.dataLoader('CaseUse','Case Use',item.tags.reg_case)
                    if(caseData){
                      item.case_name = caseData.name
                      this.regulationDetailsData.proposed.data.case_name = caseData.name
                    }
                    else console.warn('No case use data')
                  }
                }
                else if (item.tags.reg_classification == 'Non-Business') {
                  if(item.tags.reg_stageoflife){
                    const solData:any = await this.dataLoader('StageOfLife','Stage of Life',item.tags.reg_stageoflife)
                    if(solData){
                      item.sol_name = solData.stage
                      this.regulationDetailsData.proposed.data.sol_name = solData.stage
                    }
                    else console.warn('No sol data')
                  }
                  
                  if(item.tags.reg_lifeevent){
                    const leData:any = await this.dataLoader('LifeEvent','Life Event',item.tags.reg_lifeevent)
                    if(leData){
                      item.event_name = leData.event
                      this.regulationDetailsData.proposed.data.event_name = leData.event
                    }
                    else console.warn('No event data')
                  }
                }
              }

              if(item.tags.reg_legal){
                item.reg_legal = item.tags.reg_legal
                this.regulationDetailsData.proposed.data.legalBases_list = item.tags.reg_legal
              }
    
              if(item.tags.reg_intl){
                item.reg_intl = item.tags.reg_intl
                this.regulationDetailsData.proposed.data.internationalAgreement_list = item.tags.reg_intl
              }

              if(item.tags.reg_juris){
                const loadedData:any = await this.loadDataId('Jurisdiction',item.tags.reg_juris)
                if(loadedData){ 
                  item.juris_name = loadedData.name
                  this.regulationDetailsData.proposed.data.juris_name = loadedData.name
                }
                else console.warn('No jurisdiction data')
              }
            }
           
            await this.subTagsHandler(item, this.regulationDetailsData.proposed.data);

            if(item.reg_instrument){
              const loadedData:any = await this.dataLoader('Instrument','Instrument',item.reg_instrument)
              if(loadedData){ 
                item.instrument_name = loadedData.name
                this.regulationDetailsData.proposed.data.instrument_name = loadedData.name
              }
              else console.warn('No instrument data')
            }

            if(item.reg_juris){
              const loadedData:any = await this.loadDataId('Jurisdiction',item.reg_juris)
              if(loadedData){ 
                item.juris_name = loadedData.name
                this.regulationDetailsData.proposed.data.juris_name = loadedData.name
              }
              else console.warn('No jurisdiction data')
            }

            if(item.pia_summary) item.reg_summary = item.pia_summary
            
            item.pia_sections = {
              pia_section_1: item.pia_section_1,
              pia_section_2: item.pia_section_2,
              pia_section_3: item.pia_section_3,
              pia_section_4: item.pia_section_4,
              pia_section_4_file: item.pia_section_4_file,
              pia_section_5: item.pia_section_5,
              pia_section_6: item.pia_section_6,
              pia_summary: item.pia_summary
            }

            if(item.pis_assessment){
              // console.log('pis_assessment',item.pis_assessment)
              this.hasData.piaAssessment = true
              // Initialize PIS Assessment
              // Use ? and || '' initialization to reduce the number of lines
              this.pis_assessment = {
                pia_summary: [
                  new FormGroup({
                    type: new FormControl('PIA'),
                    assessment: new FormControl(
                      {
                        value: item.pis_assessment?.pia_summary?.assessment || '',
                        disabled: true,
                      },
                      Validators.required
                    ),
                    remarks: new FormControl({
                      value: item.pis_assessment?.pia_summary?.remarks ||
                        'No remarks',
                      disabled: true,
                    }),
                    url: new FormControl('')
                  }),
                ],
                pia_section_1: [
                  new FormGroup({
                    type: new FormControl('PIA'),
                    assessment: new FormControl(
                      {
                        value:
                          item.pis_assessment?.pia_section_1?.assessment ||
                          '',
                        disabled: true,
                      },
                      Validators.required
                    ),
                    remarks: new FormControl({
                      value:
                        item.pis_assessment?.pia_section_1?.remarks ||
                        'No remarks',
                      disabled: true,
                    }),
                    url: new FormControl('')
                  }),
                ],
                pia_section_2: [
                  new FormGroup({
                    type: new FormControl('PIA'),
                    assessment: new FormControl(
                      {
                        value: item.pis_assessment?.pia_section_2?.assessment ||
                          '',
                        disabled: true,
                      },
                      Validators.required
                    ),
                    remarks: new FormControl({
                      value: item.pis_assessment?.pia_section_2?.remarks ||
                        'No remarks',
                      disabled: true,
                    }),
                    url: new FormControl('')
                  }),
                ],
                pia_section_3: [
                  new FormGroup({
                    type: new FormControl('PIA'),
                    assessment: new FormControl(
                      {
                        value: item.pis_assessment?.pia_section_3?.assessment ||
                          '',
                        disabled: true,
                      },
                      Validators.required
                    ),
                    remarks: new FormControl({
                      value: item.pis_assessment?.pia_section_3?.remarks ||
                        'No remarks',
                      disabled: true,
                    }),
                    url: new FormControl('')
                  }),
                ],
                pia_section_4: [
                  new FormGroup({
                    type: new FormControl('PIA'),
                    assessment: new FormControl(
                      {
                        value: item.pis_assessment?.pia_section_4?.assessment ||
                          '',
                        disabled: true,
                      },
                      Validators.required
                    ),
                    remarks: new FormControl({
                      value: item.pis_assessment?.pia_section_4?.remarks ||
                        'No remarks',
                      disabled: true,
                    }),
                    url: new FormControl('')
                  }),
                ],
                pia_section_5: [
                  new FormGroup({
                    type: new FormControl('PIA'),
                    assessment: new FormControl(
                      {
                        value: item.pis_assessment?.pia_section_5?.assessment ||
                          '',
                        disabled: true,
                      },
                      Validators.required
                    ),
                    remarks: new FormControl({
                      value: item.pis_assessment?.pia_section_5?.remarks ||
                        'No remarks',
                      disabled: true,
                    }),
                    url: new FormControl('')
                  }),
                ],
                pia_section_6: [
                  new FormGroup({
                    type: new FormControl('PIA'),
                    assessment: new FormControl(
                      {
                        value: item.pis_assessment?.pia_section_6?.assessment ||
                          '',
                        disabled: true,
                      },
                      Validators.required
                    ),
                    remarks: new FormControl({
                      value: item.pis_assessment?.pia_section_6?.remarks ||
                        'No remarks',
                      disabled: true,
                    }),
                    url: new FormControl('')
                  }),
                ],
                reg_significance: [
                  new FormGroup({
                    type: new FormControl('SIG'),
                    assessment: new FormControl(
                      {
                        value: item.pis_assessment?.reg_significance?.assessment ||
                          '',
                        disabled: true,
                      },
                      Validators.required
                    ),
                    remarks: new FormControl({
                      value: item.pis_assessment?.reg_significance?.remarks ||
                        'No remarks',
                      disabled: true,
                    }),
                    url: new FormControl('')
                  }),
                ],
                reg_lenses: [
                  new FormGroup({
                    type: new FormControl('LENS'),
                    assessment: new FormControl(
                      {
                        value: item.pis_assessment?.reg_lenses?.assessment || 'None',
                        disabled: true,
                      }
                    ),
                    remarks: new FormControl({
                      value:
                        item.pis_assessment?.reg_lenses?.remarks ||
                        'No remarks',
                      disabled: true,
                    }),
                    url: new FormControl('')
                  }),
                ],
                reg_details: [
                  new FormGroup({
                    type: new FormControl('PIA'),
                    assessment: new FormControl(
                      {
                        value: item.pis_assessment?.reg_details?.assessment || '',
                        disabled: true,
                      },
                      Validators.required
                    ),
                    remarks: new FormControl({
                      value: item.pis_assessment?.reg_details?.remarks ||
                        'No remarks',
                      disabled: true,
                    }),
                    url: new FormControl('')
                  }),
                ],
              };

              
              // pia 1
              if(item.pia_section_1.policy_problem_file_folder && item.pia_section_1.policy_problem_file_name){
                const path = item.pia_section_1.policy_problem_file_folder+"/"+item.pia_section_1.policy_problem_file_name
                this.store.ref(path).getDownloadURL()
                .subscribe({
                  next:(response)=>{
                    console.log('pia_section_1 doc loaded');
                    (this.pis_assessment.pia_section_1[0] as FormGroup).patchValue({url:response});
                  },
                  error:(err)=>{
                    console.error('Error no document found!: pia_section_1')
                  }
                })
              }

              // pia 4
              if(item.pia_section_4_file){
                if(item.pia_section_4_file.compliance_file_folder && item.pia_section_4_file.compliance_file_name){
                  const path = item.pia_section_4_file.compliance_file_folder+"/"+item.pia_section_4_file.compliance_file_name
                  this.store.ref(path).getDownloadURL()
                  .subscribe({
                    next:(response)=>{
                      console.log('pia_section_6 doc loaded');
                      (this.pis_assessment.pia_section_4[0] as FormGroup).patchValue({url:response});
                    },
                    error:(err)=>{
                      console.error('Error no document found!: pia_section_4')
                    }
                  })
                }
              }
              
              // pia 6
              if(item.pia_section_6.consultation_and_recommendation_file_folder && item.pia_section_6.consultation_and_recommendation_file_name){
                const path = item.pia_section_6.consultation_and_recommendation_file_folder+"/"+item.pia_section_6.consultation_and_recommendation_file_name
                this.store.ref(path).getDownloadURL()
                .subscribe({
                  next:(response)=>{
                    console.log('pia_section_6 doc loaded');
                    (this.pis_assessment.pia_section_6[0] as FormGroup).patchValue({url:response});
                  },
                  error:(err)=>{
                    console.error('Error no document found!: pia_section_6')
                  }
                })
              }

              this.flagged_sections = new FormArray([]);
              this.flaggedSectionIndexes = [];

              // Load flagged sections (part of PIS Assessment) here
              if (item.pis_assessment?.flagged_sections) {
                item.pis_assessment.flagged_sections.forEach(
                  (flaggedSection: any) => {
                    this.flagged_sections.push(
                      new FormGroup({
                        flaggedSectionIndex: new FormControl(
                          flaggedSection.flaggedSectionIndex
                        ),
                        flaggedSectionName: new FormControl(
                          flaggedSection.flaggedSectionName
                        ),
                        remarks: new FormControl({
                          value: flaggedSection.remarks,
                          disabled:  true,
                        }),
                      })
                    );
                  }
                );

                this.flaggedSectionIndexes =
                  item.pis_assessment.flagged_sections.map(
                    (flaggedSection: any) => {
                      return flaggedSection.flaggedSectionIndex;
                    }
                  );
              }
            }
            
            this.regulationData.INITIAL = item
            if(!this.hasData.existing) this.regulationData.CURRENT = item
            // console.log('loaded data: s',this.loadedData)
          }
          else{
            //       if(from === null || from === "regulations"){
            //         this.regulation = item
            //         this.regulation.displayDate = (this.regulation.doc_date instanceof firebase.firestore.Timestamp ? format(this.regulation.doc_date.toDate(), 'MM/dd/yyyy') : this.regulation.doc_date )
            //         this.auth.user.pipe(first()).subscribe(data => {
            //           this.user_id = data?.uid;
            //           this.loadRegulation(this.user_id,this.regulation)
            //         });
            //         let path = data.get('firebase_folder') + '/' + data.get('file_file_name');
            //         this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
            //           this.file = data; 
            //         });
            //       }
            this.regulationData.EXISTING = item
            this.regulationData.CURRENT = item
          }
        } else console.error('Regulation Error: doesnt exist')
      },
      error:(err)=>{
        console.error('Error: ',err)
      }
    })
  }

  async loadRisDataByID(id:any, lookForProposed:boolean){
    console.log('loadRisDataByID: ',id)
    const proposedWorklistData:any = await this.loadDataId(this.collectionInfo['proposed'].worklistCollection,id)
    if(proposedWorklistData) this.loadedRisData(proposedWorklistData, lookForProposed)
  }

  async loadRisDataByWhere(id:any, lookForProposed:boolean){
    console.log('loadRisDataByWhere: ',id)
    const proposedWorklistDatas:any = await this.loadDataWhere(this.collectionInfo['proposed'].worklistCollection,'documentId','==',id)
    if(proposedWorklistDatas && proposedWorklistDatas.length > 0) this.loadedRisData(proposedWorklistDatas[0], lookForProposed)
  }

  loadedRisData(regulation:any, lookForProposed:boolean){
    this.regulationData['PROPOSED_WORKLIST'] = regulation
    console.log('from: worklist | regulation: ',regulation)
    if(lookForProposed) this.loadData(regulation.documentId, this.collectionInfo['proposed'].baseCollection, 'proposed')
    if(regulation.crisID) this.loadData2(regulation.crisID, this.collectionInfo['cris'].baseCollection, 'CRIS')
    if(regulation.drisID) this.loadData2(regulation.drisID, this.collectionInfo['dris'].baseCollection, 'DRIS')
  }

  async loadData2(id: any, collection:any, from:any){
    this.afs.collection(collection).doc(id).snapshotChanges()
    .subscribe({
      next:async (dataResult)=>{
        if(dataResult.payload.exists){
          let item:any = dataResult.payload.data()
          item.id = dataResult.payload.id;
          console.log('from: '+from+' | regulation: ',item)

          this.regulationDetailsData[from.toLowerCase()] = {}
          this.regulationDetailsData[from.toLowerCase()].controlCode = from.toLowerCase()
          this.regulationDetailsData[from.toLowerCase()].data = {}
          this.regulationDetailsData[from.toLowerCase()].data.title = item.title
          this.regulationDetailsData[from.toLowerCase()].data.subject = item.subject

          this.uploadDetailsData[from.toLowerCase()] = {}
          this.uploadDetailsData[from.toLowerCase()].controlCode = from.toLowerCase()
          this.uploadDetailsData[from.toLowerCase()].data = []

          if(item.regulation_text){
            this.textRegulationData[from.toLowerCase()] = {}
            this.textRegulationData[from.toLowerCase()].controlCode = from.toLowerCase()
            this.textRegulationData[from.toLowerCase()].data = await this.textRegulationHandler(item.regulation_text)
          }

          if(item.has_annex_files){
            let reg_text_annex_file:any = {
              has_annex_files: item.has_annex_files
            }

            if(item.regulation_text_annex_file_folder && item.regulation_text_annex_file_name){
              reg_text_annex_file.fileFolder = item.regulation_text_annex_file_folder
              reg_text_annex_file.fileName = item.regulation_text_annex_file_name
              let path = `${reg_text_annex_file.fileFolder}/${reg_text_annex_file.fileName}`;
  
              let tokenized = item.regulation_text_annex_file_name.split(".")
              if(tokenized && tokenized.length > 0 && tokenized[tokenized.length-1] == 'zip'){
                reg_text_annex_file.file_type = 'zip';
                this.store.ref(path).getDownloadURL()
                .subscribe((url)=>{
                  reg_text_annex_file.file_download_link = url
                  this.textRegulationData[from.toLowerCase()].reg_text_annex_file = reg_text_annex_file
                })
              }
              else{
                this.pdf_render.getFileUrl(path).toPromise()
                .then((data: any) => {
                  // this.file = data; 
                  reg_text_annex_file.file = data;
                  reg_text_annex_file.file_page = 1;
                  this.textRegulationData[from.toLowerCase()].reg_text_annex_file = reg_text_annex_file
                  // return true
                })
                .catch((err)=>{
                  reg_text_annex_file.file_page = 1;
                  this.textRegulationData[from.toLowerCase()].reg_text_annex_file = reg_text_annex_file
                  console.error('Error: ',err)
                  // return true
                });
              }
            }
            else{
              this.textRegulationData[from.toLowerCase()].reg_text_annex_file = reg_text_annex_file
            }
          }

          if(item.reg_instrument){
            const loadedData:any = await this.dataLoader('Instrument','Instrument',item.reg_instrument)
            if(loadedData){ 
              item.instrument_name = loadedData.name
              this.regulationDetailsData[from.toLowerCase()].data.instrument_name = loadedData.name
            }
            else console.warn('No instrument data')
          }

          if(item.reg_agency){
            if(Array.isArray(item.reg_agency)) {
              const agency_names:any = await this.multiDataLoader('Agency','Agency',item.reg_agency)
              if(agency_names && agency_names.length > 0){
                this.regulationDetailsData[from.toLowerCase()].data.agency_list = agency_names
                item.issuing_agency = agency_names.toString()
              }
              else {
                console.warn('Error: no agency name')
                this.regulationDetailsData[from.toLowerCase()].data.agency_list = []
              }
            }
            else{
              let temp_agency_list:any = []
              temp_agency_list.push(item.reg_agency)
              const agency_names:any = await this.multiDataLoader('Agency','Agency',temp_agency_list)
              if(agency_names && agency_names.length > 0){ 
                this.regulationDetailsData[from.toLowerCase()].data.agency_list = agency_names
                item.issuing_agency = agency_names.toString()
                console.log(this.regulationDetailsData[from.toLowerCase()].data.agency_list)
              }
              else {
                console.warn('Error: no agency name')
                this.regulationDetailsData[from.toLowerCase()].data.agency_list = []
              }
            }
          }

          if(item.reg_classification){
            item.classification = item.reg_classification
            this.regulationDetailsData[from.toLowerCase()].data.classification = item.reg_classification
            if (item.reg_classification == 'Business') {
              if(item.reg_sector){
                const sectorData:any = await this.dataLoader('Sector','Sector',item.reg_sector)
                if(sectorData){ 
                  item.sector_name = sectorData.name
                  this.regulationDetailsData[from.toLowerCase()].data.sector_name = sectorData.name
                }
                else console.warn('No sector data')
              }

              if(item.reg_business){
                const businessData:any = await this.dataLoader('StageOfBusiness','Stage of Business',item.reg_business)
                if(businessData){
                  item.business_name = businessData.name
                  this.regulationDetailsData[from.toLowerCase()].data.business_name = businessData.name
                }
                else console.warn('No business data')
              }
            }
            else if (item.reg_classification == 'Non-Business') {
              if(item.reg_stageoflife){
                const solData:any = await this.dataLoader('StageOfLife','Stage of Life',item.reg_stageoflife)
                if(solData){
                  item.sol_name = solData.name
                  this.regulationDetailsData[from.toLowerCase()].data.sol_name = solData.name
                }
                else console.warn('No sol data')
              }
              
              if(item.reg_lifeevent){
                const leData:any = this.dataLoader('LifeEvent','Life Event',item.reg_lifeevent)
                if(leData){
                  item.event_name = await leData.name
                  this.regulationDetailsData[from.toLowerCase()].data.event_name = leData.name
                }
                else console.warn('No event data')
              }
            }
          }

          if(item.has_sub_tags == true){
            this.regulationDetailsData[from.toLowerCase()].data.has_sub_tags = item.has_sub_tags
            if(item.sub_tags && Array.isArray(item.sub_tags) && item.sub_tags.length > 0){
              this.regulationDetailsData[from.toLowerCase()].data.sub_tags = item.sub_tags
              this.regulationDetailsData[from.toLowerCase()].data.sub_tags.map(async (sub_tag:any)=>{
                switch(sub_tag.reg_classification){
                  case 'Business':{
                    if(sub_tag.reg_sector){
                      const sectorData:any = await this.dataLoader('Sector','Sector',sub_tag.reg_sector)
                      if(sectorData){ 
                        sub_tag.sector_name = sectorData.name
                      }
                      else console.warn('No sector data')
                    }
  
                    if(sub_tag.reg_business){
                      const businessData:any = await this.dataLoader('StageOfBusiness','Stage of Business',sub_tag.reg_business)
                      if(businessData){
                        sub_tag.business_name = businessData.name
                      }
                      else console.warn('No business data')
                    }
          
                    if(sub_tag.reg_division){
                      const divisionData:any = await this.dataLoader('Division','Division',sub_tag.reg_division)
                      if(divisionData){
                        sub_tag.division_name = divisionData.name
                      }
                      else console.warn('No division data')
                    }
                    
                    if(sub_tag.reg_case){
                      const caseData:any = await this.dataLoader('CaseUse','Case Use',sub_tag.reg_case)
                      if(caseData){
                        sub_tag.case_name = caseData.name
                      }
                      else console.warn('No case use data')
                    }

                    break;
                  }
                  case 'Non-Business':{
                    if(sub_tag.reg_stageoflife){
                      const solData:any = await this.dataLoader('StageOfLife','Stage of Life',sub_tag.reg_stageoflife)
                      if(solData){
                        sub_tag.sol_name = solData.stage
                      }
                      else console.warn('No sol data')
                    }
                    
                    if(sub_tag.reg_lifeevent){
                      const leData:any = await this.dataLoader('LifeEvent','Life Event',sub_tag.reg_lifeevent)
                      if(leData){
                        sub_tag.event_name = await leData.event
                      }
                      else console.warn('No event data')
                    }
                    break;
                  }
                }
              })
            } else this.regulationDetailsData[from.toLowerCase()].data.has_sub_tags = []
          }
          else this.regulationDetailsData[from.toLowerCase()].data.has_sub_tags = false
    
          if(item.reg_division){
            const divisionData:any = await this.dataLoader('Division','Division',item.reg_division)
            if(divisionData){
              item.division_name = divisionData.name
              this.regulationDetailsData[from.toLowerCase()].data.division_name = divisionData.name
            }
            else console.warn('No division data')
          }
          
          if(item.reg_case){
            const caseData:any = await this.dataLoader('CaseUse','Case Use',item.reg_case)
            if(caseData){
              item.case_name = caseData.name
              this.regulationDetailsData[from.toLowerCase()].data.case_name = caseData.name
            }
            else console.warn('No case use data')
          }

          if(item.reg_juris){
            const loadedData:any = await this.loadDataId('Jurisdiction',item.reg_juris)
            if(loadedData){ 
              item.juris_name = loadedData.name
              this.regulationDetailsData[from.toLowerCase()].data.juris_name = loadedData.name
            }
            else console.warn('No jurisdiction data')
          }

          if(item.reg_legal) this.regulationDetailsData[from.toLowerCase()].data.legalBases_list = item.reg_legal

          if(item.reg_intl) this.regulationDetailsData[from.toLowerCase()].data.internationalAgreement_list = item.reg_intl
          
          if(item.risData) this.hasData.riaAssessment = true

          if(from == 'CRIS') {
            this.hasData.consultations = true
            const consultations:any = await this.loadConsultations(item.id);
            if(consultations && consultations.length > 0) {item.consultationsData = consultations}
          }
          
          if(item.upload_folders){
            // this.uploadDetailsData[from.toLowerCase()].data = item.uploads
            Object.keys(item.upload_folders).map(async (key)=>{
              let value = item.upload_folders[key]
              // let value2 = value.split("/")
              // const fileData:any = await this.refToURL(value+"/"+value2[value2.length-1]) //remove others that value
              const fileData:any = await this.refToURL(value) //correct
              let name:any = ""
              if(key == 'policyObjective') name="Policy Objective"
              else if(key == 'policyOptions') name="Policy Options"
              else if(key == 'policyProblem') name="Policy Problem"
              else if(key == 'implementationEnforcement') name="Implementation Enforcement"
              else if(key == 'policyOptionsAssessment') name="Policy Options Assessment"
              else if(key == 'monitoringEvaluation') name="Monitoring Evaluation"
              else if(key == 'regulatoryImpactStatement') name="Regulatory Impact Statement"
              else if(key == 'impactAssessment') name="Impact Assessment"
              else if(key == 'consultationDocumentation') name="Consultation Documentation"
              else if(key == 'consultation') name="Consultation"
              let itemFile:any = {
                key: key,
                name: name,
                fileName: '',
                value: value,
                fileBaseTotalPage: 1,
                fileBasePage: 1,
                downloadUrl: false,
                contentType: false,
              }
              if(fileData){
                if(fileData.url) itemFile.downloadUrl = fileData.url
                if(fileData.metaData){
                  itemFile.contentType = fileData.metaData.contentType
                  itemFile.fileName = fileData.metaData.name
                } 

                this.uploadDetailsData[from.toLowerCase()].data.push(itemFile)
              }else{
                this.uploadDetailsData[from.toLowerCase()].data.push(itemFile)
              }
            })
          }

          this.hasData[from.toLowerCase()] = true
          this.hasData.uploads = true

          this.regulationData[from] = item
        }
      },
      error:(err)=>{
        console.error('Error: ',err)
      }
    })
  }

  async loadConsultations(id: any) {
    return new Promise((res,rej)=>{
      this.afs.collection(`Public Consultations`, (filter) => filter.where('cris_id', '==', id)).snapshotChanges()
      .subscribe({
        next:(result)=>{
          let reg_consultations: Array<any> = []
          reg_consultations = result.map((data)=>data.payload.doc.data())
          return res(reg_consultations)
        },
        error:(err)=>{
          console.error('Error: ',err)
          return res(false)
        }
      })
    })
  }
  
  refToURL(filePath:string){
    return new Promise((res,rej)=>{
      this.store.ref(filePath).getDownloadURL()
      .subscribe({
        next:(data1)=>{
          this.store.ref(filePath).getMetadata()
          .subscribe({
            next:(data2)=>{
              let item:any = {
                url: data1,
                metaData: data2,
              }
              return res(item)
            },
            error:(err)=>{
              // console.error('refToURL Error: ',err)
              return res(false)
            }
          })
        },
        error:(err)=>{
          // console.error('refToURL Error: ',err)
          return res(false)
        }
      })
    })
  }

  async multiDataLoader(code:string, collection:string, idArr:any):Promise<any>{
    // console.log('multiDataLoader ids: ',idArr)
    if(code && collection && idArr && idArr.length > 0){
      if(this.loadedData[code]){
        return this.multiDataLoader2(code,collection,idArr)
      }

      else{
        this.loadedData[code] = []
        return this.multiDataLoader(code,collection,idArr)
      }
    } else return false
  }

  async multiDataLoader2(code:string, collection:string, idArr:Array<string>){
    return Promise.all(
      idArr.map(async (id:string)=> this.asyncMapLoader(code,collection,idArr,id))
    )
    .then((data)=>{
      if(data && data.length > 0) return data.map((agency:any)=> agency.agencyDetails && agency.agencyDetails.agencyName ? agency.agencyDetails.agencyName : agency.name ? agency.name : '-- Not found')
      else return ['-- Not found']
    })
    .catch((err)=>{
      console.error('Error: ',err)
      return false
    })
  }

  asyncMapLoader(code:string, collection:string, idArr:Array<any>, id:string){
    const sureList = this.loadedData[code].filter((agency:any)=>idArr.includes(agency.id))
    if(sureList.length > 0) return sureList[0]
    else return this.dataLoader2(code,collection,id)
  }

  // async idToLoadedDataName(code:string, idArr:any){
  //   return idArr.map((id:string)=>{
  //     const ctr = this.loadedData[code].map((item:any)=>item.id).indexOf(id)
  //     if(ctr >= 0) return this.loadedData[code][ctr].name // make this dynamic regardless of json model
  //     else return '-- Not found'
  //   })
  // }

  async dataLoader(code:string, collection:string, id:any):Promise<any>{
    // console.log(code+' | '+collection+': '+id+' - start')
    // code is used for accessing loadedData object
    // check if code, collection, and id are valid
    if(code && collection && id){

      // check if loadedData.Object has a value
      if(this.loadedData[code]){

        // check if there loadedData.Object array contains an element
        if(this.loadedData[code].length > 0){

          // look for id wihtin the array and return the element
          const ctr = this.loadedData[code].map((item:any)=>item.id).indexOf(id)
          if(ctr >= 0){
            console.log(code+' | '+collection+': '+id+' - existing')
            return this.loadedData[code][ctr]
          }
          
          // if no element with that id get it from firebase
          else return await this.dataLoader2(code, collection, id)
        }

        // if loadedData.Object array doesnt contain an element get value from firebase
        else return await this.dataLoader2(code, collection, id)
      } 
      
      // if loadedData.Object has no value, initialized an array there
      else {
        this.loadedData[code] = []
        return await this.dataLoader(code,collection,id)
      }
    } else return false
  }

  async dataLoader2(code:string, collection:string, id:any): Promise<any>{
    // console.log(code+' | '+collection+': '+id+' - fetching')
    // console.log('dataLoader2 id: ',id)

    // get data from firebase
    const item = await this.loadDataId(collection,id)
    
    // if data exist add it to loaded.Object array
    if(item){
      // console.log('dataLoader2: ',item)
      if(this.loadedData[code].map((val:any)=>val.id).indexOf(id) == -1)this.loadedData[code].push(item)
      return item
    }
    
    // if data doesnt exist return false
    else return false
  }

  // async loadInfo(id: any, collection:any, from:any) {
  //   this.afs.collection(collection).doc(id).get()
  //   .subscribe(async (data: any) => {
  //     if(data.exists){
  //       let item:any = data.data();
  //       item.id = data.id
  //       console.log('from: '+from+' | regulation: ',item)
  //       // proposed_id
  //       if(from === "existing"){
  //         this.has_existing = true;
  //         console.log('existing')
  //         item.subject_full_title = item.title
  //         item.short_title = item.subject
  //         item.displayDate = item.doc_date

  //         // 
  //         if(item.proposed_id){
  //           const proposedWorklistData:any = await this.loadDataId('Worklist-Proposed Regulations',item.proposed_id)
  //           // console.log('proposedData',proposedWorklistData)
  //           if(proposedWorklistData){
  //             this.prevProposedReg = true
  //             if(proposedWorklistData.crisID){
  //               const crisRegData:any = await this.loadDataId('regulations-proposed-cris',proposedWorklistData.crisID)
  //               if(crisRegData){
  //                 // console.log('crisRegData',crisRegData)
  //                 this.crisData = crisRegData
  //                 if(crisRegData.regulation_text) {
  //                   this.textRegulationData.cris = {}
  //                   this.textRegulationData.cris.controlCode = 'cris'
  //                   this.textRegulationData.cris.data = await this.textRegulationHandler(crisRegData.regulation_text)
  //                 }
  //               }
  
  //               const crisConsultationData:any = await this.loadDataWhere('Public Consultations','cris_id','==',proposedWorklistData.crisID)
  //               if(crisConsultationData && crisConsultationData.length > 0){
  //                 this.consultationsData = crisConsultationData
  //               }
  //             }
  //             if(proposedWorklistData.drisID){
  //               const drisData:any = await this.loadDataId('regulations-proposed-dris',proposedWorklistData.drisID)
  //               if(drisData){
  //                 if(drisData.risData) this.has_ria_assessment = true
  //                 // console.log('drisData',drisData)
  //                 this.drisData = drisData
  //                 if(drisData.regulation_text) {
  //                   this.textRegulationData.dris = {}
  //                   this.textRegulationData.dris.controlCode = 'dris'
  //                   this.textRegulationData.dris.data = await this.textRegulationHandler(drisData.regulation_text)
  //                 }
  //               }
  //             }
  //             if(proposedWorklistData.documentId){
  //               this.loadInfo(proposedWorklistData.documentId,'regulations-proposed-phase-02','proposed')
  //             }
  //           }
  //         }
  
  //         if(item.reg_agency){
  //           if(typeof item.reg_agency === 'object') {
  //             if(item.reg_agency.includes(this.userAgencyId)) this.userAgencyAllowed = true
  //             else this.userAgencyAllowed = false
  //           }else{
  //             if(item.reg_agency == this.userAgencyAllowed) this.userAgencyAllowed = true
  //             else this.userAgencyAllowed = false
  //           }
  //         }
  
  //         if (item.reg_classification == 'Business') {
  //           const sectorData:any = await this.loadDataId('Sector',item.reg_sector)
  //           if(sectorData) item.sector_name = sectorData.name
  //           else console.warn('No sector data')
            
  //           const businessData:any = await this.loadDataId('Stage of Business',item.reg_business)
  //           if(businessData) item.business_name = businessData.name
  //           else console.warn('No business data')
  
  //           const divisionData:any = await this.loadDataId('Division',item.reg_division)
  //           if(divisionData) item.division_name = divisionData.name
  //           else console.warn('No division data')
  
  //           const caseData:any = await this.loadDataId('Case Use',item.reg_case)
  //           if(caseData) item.case_name = caseData.name
  //           else console.warn('No case use data')
  //         }
  //         else if (item.reg_classification == 'Non-Business') {
  //           const solData:any = await this.loadDataId('Stage of Life',item.reg_stageoflife)
  //           if(solData) item.sol_name = solData.name
  //           else console.warn('No sol data')
  
  //           const leData:any = this.loadDataId('Life Event',item.reg_lifeevent)
  //           if(leData) item.event_name = await leData.name
  //           else console.warn('No event data')
  //         }
  
  //         const jursiData:any = await this.loadDataId('Jurisdiction',item.reg_juris)
  //         if(jursiData){
  //           item.juris_name = jursiData.name
  //         }
  
  //         if(item.firebase_folder && item.file_file_name){
  //           this.mainPDFFile.fileFolder = item.firebase_folder
  //           this.mainPDFFile.fileName = item.file_file_name
  //           let path = `${item.firebase_folder}/${item.file_file_name}`;
  //           this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
  //             // this.file = data; 
  //             this.mainPDFFile.file = data;
  //             this.mainPDFFile.page = 1;
  //           });
  //         }

  //         if(item.regulation_text) {
  //           this.textRegulationData.existing = {}
  //           this.textRegulationData.existing.controlCode = 'existing' 
  //           this.textRegulationData.existing.data = await this.textRegulationHandler(item.regulation_text)
  //         }
          
  //         this.regulation = item
  //         // console.log('regulation',item)
  //       }
  //       else if(from === "proposed"){
  //         // item.subject_full_title = item.title
  //         // item.short_title = item.subject
  //         // item.displayDate = item.doc_date
  //         this.from_proposed = true;

  //         item.subject_full_title = item.title
  //         item.short_title = item.subject
  //         item.doc_date = item.reg_notif_form && item.reg_notif_form.doc_date ? item.reg_notif_form.doc_date : '--'
  //         item.displayDate = item.doc_date

  //         if(item.reg_notif_form && item.reg_notif_form.reg_classification){
  //           item.reg_classification = item.reg_notif_form.reg_classification
  //         }else{
  //           if(item.tags && item.tags.reg_classification){
  //             item.reg_classification = item.tags.reg_classification
  //           }
  //         }
  //         item.reg_legal = item.tags.sb      
  //         item.reg_intl = item.tags.reg_intl

  //         const agencyData:any = await this.loadDataId('Agency',item.reg_agency)
  //         if(agencyData){
  //           item.issuing_agency = agencyData.name
  //         }

  //         if(item.reg_notif_form){
  //           if (item.reg_notif_form.reg_classification == 'Business') {
  //             const sectorData:any = await this.loadDataId('Sector',item.reg_notif_form.reg_sector)
  //             if(sectorData){
  //               item.sector_name = sectorData.name
  //             }else console.warn('No sector data')
              
  //             const businessData:any = await this.loadDataId('Stage of Business',item.reg_notif_form.reg_business)
  //             if(businessData){
  //               item.business_name = businessData.name
  //             }else console.warn('No business data')
    
  //             if(item.reg_notif_form.reg_division){
  //               const divisionData:any = await this.loadDataId('Division',item.reg_notif_form.reg_division)
  //               if(divisionData){
  //                 item.division_name = divisionData.name
  //               }else console.warn('No division data')
  //             }else{
  //               item.division_name = 'NOT APPLICABLE'
  //             }
    
  //             const caseData:any = await this.loadDataId('Case Use',item.reg_notif_form.reg_case)
  //             if(caseData){
  //               item.case_name = caseData.name
  //             }else console.warn('No case use data')
  //           }
  //           else if (item.reg_notif_form.reg_classification == 'Non-Business') {
  //             const solData:any = await this.loadDataId('Stage of Life',item.reg_notif_form.reg_stageoflife)
  //             if(solData){
  //               item.sol_name = solData.name
  //             }
    
  //             const leData:any = this.loadDataId('Life Event',item.reg_notif_form.reg_lifeevent)
  //             if(leData){
  //               item.event_name = await leData.name
  //             }
  //           }

  //           const jursiData:any = await this.loadDataId('Jurisdiction',item.reg_notif_form.reg_juris)
  //           if(jursiData){
  //             item.juris_name = jursiData.name
  //           }
  //         }

  //         if(item.regulation_text) {
  //           this.textRegulationData.proposed = {}
  //           this.textRegulationData.proposed.controlCode = 'proposed' 
  //           // this.textRegulationData.proposed.data = await this.textRegulationHandler(item.regulation_text) <-- error here
  //         }

  //         const pia_sections:any ={
  //           pia_section_1: item.pia_section_1,
  //           pia_section_2: item.pia_section_2,
  //           pia_section_3: item.pia_section_3,
  //           pia_section_4: item.pia_section_4,
  //           pia_section_5: item.pia_section_5,
  //           pia_section_6: item.pia_section_6,
  //           pia_summary: item.pia_summary
  //         }
          
  //         item.pia_sections = pia_sections

  //         if(item.pis_assessment){
  //           // console.log('pis_assessment',item.pis_assessment)

  //           this.has_pia_assessment = true
  //           // Initialize PIS Assessment
  //           // Use ? and || '' initialization to reduce the number of lines
  //           this.pis_assessment = {
  //             pia_summary: [
  //               new FormGroup({
  //                 type: new FormControl('PIA'),
  //                 assessment: new FormControl(
  //                   {
  //                     value: item.pis_assessment?.pia_summary?.assessment || '',
  //                     disabled: true,
  //                   },
  //                   Validators.required
  //                 ),
  //                 remarks: new FormControl({
  //                   value: item.pis_assessment?.pia_summary?.remarks ||
  //                     'No remarks',
  //                   disabled: true,
  //                 }),
  //                 url: new FormControl('')
  //               }),
  //             ],
  //             pia_section_1: [
  //               new FormGroup({
  //                 type: new FormControl('PIA'),
  //                 assessment: new FormControl(
  //                   {
  //                     value:
  //                       item.pis_assessment?.pia_section_1?.assessment ||
  //                       '',
  //                     disabled: true,
  //                   },
  //                   Validators.required
  //                 ),
  //                 remarks: new FormControl({
  //                   value:
  //                     item.pis_assessment?.pia_section_1?.remarks ||
  //                     'No remarks',
  //                   disabled: true,
  //                 }),
  //                 url: new FormControl('')
  //               }),
  //             ],
  //             pia_section_2: [
  //               new FormGroup({
  //                 type: new FormControl('PIA'),
  //                 assessment: new FormControl(
  //                   {
  //                     value: item.pis_assessment?.pia_section_2?.assessment ||
  //                       '',
  //                     disabled: true,
  //                   },
  //                   Validators.required
  //                 ),
  //                 remarks: new FormControl({
  //                   value: item.pis_assessment?.pia_section_2?.remarks ||
  //                     'No remarks',
  //                   disabled: true,
  //                 }),
  //                 url: new FormControl('')
  //               }),
  //             ],
  //             pia_section_3: [
  //               new FormGroup({
  //                 type: new FormControl('PIA'),
  //                 assessment: new FormControl(
  //                   {
  //                     value: item.pis_assessment?.pia_section_3?.assessment ||
  //                       '',
  //                     disabled: true,
  //                   },
  //                   Validators.required
  //                 ),
  //                 remarks: new FormControl({
  //                   value: item.pis_assessment?.pia_section_3?.remarks ||
  //                     'No remarks',
  //                   disabled: true,
  //                 }),
  //                 url: new FormControl('')
  //               }),
  //             ],
  //             pia_section_4: [
  //               new FormGroup({
  //                 type: new FormControl('PIA'),
  //                 assessment: new FormControl(
  //                   {
  //                     value: item.pis_assessment?.pia_section_4?.assessment ||
  //                       '',
  //                     disabled: true,
  //                   },
  //                   Validators.required
  //                 ),
  //                 remarks: new FormControl({
  //                   value: item.pis_assessment?.pia_section_4?.remarks ||
  //                     'No remarks',
  //                   disabled: true,
  //                 }),
  //                 url: new FormControl('')
  //               }),
  //             ],
  //             pia_section_5: [
  //               new FormGroup({
  //                 type: new FormControl('PIA'),
  //                 assessment: new FormControl(
  //                   {
  //                     value: item.pis_assessment?.pia_section_5?.assessment ||
  //                       '',
  //                     disabled: true,
  //                   },
  //                   Validators.required
  //                 ),
  //                 remarks: new FormControl({
  //                   value: item.pis_assessment?.pia_section_5?.remarks ||
  //                     'No remarks',
  //                   disabled: true,
  //                 }),
  //                 url: new FormControl('')
  //               }),
  //             ],
  //             pia_section_6: [
  //               new FormGroup({
  //                 type: new FormControl('PIA'),
  //                 assessment: new FormControl(
  //                   {
  //                     value: item.pis_assessment?.pia_section_6?.assessment ||
  //                       '',
  //                     disabled: true,
  //                   },
  //                   Validators.required
  //                 ),
  //                 remarks: new FormControl({
  //                   value: item.pis_assessment?.pia_section_6?.remarks ||
  //                     'No remarks',
  //                   disabled: true,
  //                 }),
  //                 url: new FormControl('')
  //               }),
  //             ],
  //             reg_significance: [
  //               new FormGroup({
  //                 type: new FormControl('SIG'),
  //                 assessment: new FormControl(
  //                   {
  //                     value: item.pis_assessment?.reg_significance?.assessment ||
  //                       '',
  //                     disabled: true,
  //                   },
  //                   Validators.required
  //                 ),
  //                 remarks: new FormControl({
  //                   value: item.pis_assessment?.reg_significance?.remarks ||
  //                     'No remarks',
  //                   disabled: true,
  //                 }),
  //                 url: new FormControl('')
  //               }),
  //             ],
  //             reg_lenses: [
  //               new FormGroup({
  //                 type: new FormControl('LENS'),
  //                 assessment: new FormControl(
  //                   {
  //                     value: item.pis_assessment?.reg_lenses?.assessment || 'None',
  //                     disabled: true,
  //                   }
  //                 ),
  //                 remarks: new FormControl({
  //                   value:
  //                     item.pis_assessment?.reg_lenses?.remarks ||
  //                     'No remarks',
  //                   disabled: true,
  //                 }),
  //                 url: new FormControl('')
  //               }),
  //             ],
  //             reg_details: [
  //               new FormGroup({
  //                 type: new FormControl('PIA'),
  //                 assessment: new FormControl(
  //                   {
  //                     value: item.pis_assessment?.reg_details?.assessment || '',
  //                     disabled: true,
  //                   },
  //                   Validators.required
  //                 ),
  //                 remarks: new FormControl({
  //                   value: item.pis_assessment?.reg_details?.remarks ||
  //                     'No remarks',
  //                   disabled: true,
  //                 }),
  //                 url: new FormControl('')
  //               }),
  //             ],
  //           };

            
  //           // pia 1
  //           if(item.pia_section_1.policy_problem_file_folder && item.pia_section_1.policy_problem_file_name){
  //             let path = item.pia_section_1.policy_problem_file_folder+"/"+item.pia_section_1.policy_problem_file_name
  //             this.store.ref(path).getDownloadURL()
  //             .subscribe({
  //               next:(response)=>{
  //                 console.log('pia_section_1 doc loaded');
  //                 (this.pis_assessment.pia_section_1[0] as FormGroup).patchValue({url:response});
  //               },
  //               error:(err)=>{
  //                 console.error('Error no document found!: pia_section_1')
  //               }
  //             })
  //           }
            
  //           // pia 6
  //           if(item.pia_section_6.consultation_and_recommendation_file_folder && item.pia_section_6.consultation_and_recommendation_file_name){
  //             let path = item.pia_section_6.consultation_and_recommendation_file_folder+"/"+item.pia_section_6.consultation_and_recommendation_file_name
  //             this.store.ref(path).getDownloadURL()
  //             .subscribe({
  //               next:(response)=>{
  //                 console.log('pia_section_6 doc loaded');
  //                 (this.pis_assessment.pia_section_6[0] as FormGroup).patchValue({url:response});
  //               },
  //               error:(err)=>{
  //                 console.error('Error no document found!: pia_section_6')
  //               }
  //             })
  //           }

  //           this.flagged_sections = new FormArray([]);
  //           this.flaggedSectionIndexes = [];

  //           // Load flagged sections (part of PIS Assessment) here
  //           if (item.pis_assessment?.flagged_sections) {
  //             item.pis_assessment.flagged_sections.forEach(
  //               (flaggedSection: any) => {
  //                 this.flagged_sections.push(
  //                   new FormGroup({
  //                     flaggedSectionIndex: new FormControl(
  //                       flaggedSection.flaggedSectionIndex
  //                     ),
  //                     flaggedSectionName: new FormControl(
  //                       flaggedSection.flaggedSectionName
  //                     ),
  //                     remarks: new FormControl({
  //                       value: flaggedSection.remarks,
  //                       disabled:  true,
  //                     }),
  //                   })
  //                 );
  //               }
  //             );

  //             this.flaggedSectionIndexes =
  //               item.pis_assessment.flagged_sections.map(
  //                 (flaggedSection: any) => {
  //                   return flaggedSection.flaggedSectionIndex;
  //                 }
  //               );
  //           }
  //         }

  //         // console.log('has_existing',this.has_existing)

  //         if(!this.has_existing){
  //           this.regulation = item
  //           // console.log('proposed as base regulation',item)
  //         }
  //         else{
  //           this.proposedRegulation = item
  //           // console.log('proposed as sub regulation',this.proposedRegulation)
  //         }

  //         // console.log('proposed regulation: ',item)
  //       }
        
  //       if(from === null || from === "regulations"){
  //         this.regulation = item
  //         this.regulation.displayDate = (this.regulation.doc_date instanceof firebase.firestore.Timestamp ? format(this.regulation.doc_date.toDate(), 'MM/dd/yyyy') : this.regulation.doc_date )
  //         this.auth.user.pipe(first()).subscribe(data => {
  //           this.user_id = data?.uid;
  //           this.loadRegulation(this.user_id,this.regulation)
  //         });
  //         let path = data.get('firebase_folder') + '/' + data.get('file_file_name');
  //         this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
  //           this.file = data; 
  //         });
  //       }

  //       // console.log('regulations text regulation`',this.textRegulationData)
  //     }else console.error('Regulation Error: doesnt exist')
  //   });
  // }

  loadDataId(collection:any,id:any){
    // console.log(collection+': '+id+' - loading')
    // console.log('loadDataId: ',collection,id)
    return new Promise((res,rej)=>{
      if(collection && id){
        this.afs.collection(collection).doc(id).snapshotChanges()
        .subscribe({
          next:(data)=>{
            if(data.payload.exists){
              let item: any = data.payload.data()
              item.id = data.payload.id

              if(item.ref) delete item.ref
              if(item._updatedBy) delete item._updatedBy
              if(item._createdBy) delete item._createdBy

              // console.log(collection+': '+id+' - loaded',item)
              return res(item)
            }else return res(false)
          },
          error:(err)=>{
            console.error('loadData Error: '+collection+' | '+id+' : ',err)
            return res(false)
          }
        })
      }else return res(false)
    })
  }
  
  loadDataWhere(collection:any,fieldname:any,operator:any,value:any){
    return new Promise((res,rej)=>{
      this.afs.collection(collection, filter=>filter.where(fieldname,operator,value)).snapshotChanges()
      .subscribe({
        next:(data)=>{
          if(data.length > 0){
            let tempArr:any = []
            data.map((item)=>{
              if(item.payload.doc.exists){
                let element:any = item.payload.doc.data()
                element.id = item.payload.doc.id
                tempArr.push(element)
              }
            })
            // let item: any = data.payload.data()
            // item.id = data.payload.id
            return res(tempArr)
          }else return res(false)
        },
        error:(err)=>{
          console.error('loadData Error: '+collection+' | '+fieldname+' '+operator+' '+value+' : ',err)
          return res(false)
        }
      })
    })
  }

  async textRegulationHandler(textRegulation:any){
      // console.log('1: ',textRegulation)
      return Promise.all(
        textRegulation.map(async (subsection1:any,index1:any)=>{
          // console.log('subsection1['+index1+']',subsection1)
          if(subsection1.subsections && subsection1.subsections.length > 0){
            subsection1.subsections = await Promise.all(
              subsection1.subsections.map((subsection2:any,index2:any)=>{
                // console.log('subsection2['+index1+']['+index2+']',subsection2)
                if(subsection2.subsection_image_file && subsection2.subsection_image_folder){
                  const filePath = subsection2.subsection_image_folder+"/"+subsection2.subsection_image_file;
                  console.log('filePath: ',filePath)
                  return new Promise((res,rej)=>{
                    this.store.ref(filePath).getDownloadURL()
                    .subscribe({
                      next:(data)=>{
                        return res(data)
                      },
                      error:(err)=>{
                        return rej(err)
                      }
                    })
                  })
                  .then(url=>{
                    subsection2.fileUrl = url
                    return subsection2
                  })
                  .catch(err=>{
                    console.error('unabled to get file url')
                    return subsection2
                  })
                  
                }else return subsection2
              })
            )

            return subsection1
          } else return subsection1
        })
      )

      // return textRegulation
  }

  async subTagsHandler(item: any, setRegulationData: any) {
    if(item.has_sub_tags == true){
      setRegulationData.has_sub_tags = item.has_sub_tags
      if(item.sub_tags && Array.isArray(item.sub_tags) && item.sub_tags.length > 0){
        setRegulationData.sub_tags = item.sub_tags
        setRegulationData.sub_tags.map(async (sub_tag:any)=>{
          switch(sub_tag.reg_classification){
            case 'Business':{
              if(sub_tag.reg_sector){
                const sectorData:any = await this.dataLoader('Sector','Sector',sub_tag.reg_sector)
                if(sectorData){ 
                  sub_tag.sector_name = sectorData.name
                }
                else console.warn('No sector data')
              }

              if(sub_tag.reg_business){
                const businessData:any = await this.dataLoader('StageOfBusiness','Stage of Business',sub_tag.reg_business)
                if(businessData){
                  sub_tag.business_name = businessData.name
                }
                else console.warn('No business data')
              }
    
              if(sub_tag.reg_division){
                const divisionData:any = await this.dataLoader('Division','Division',sub_tag.reg_division)
                if(divisionData){
                  sub_tag.division_name = divisionData.name
                }
                else console.warn('No division data')
              }
              
              if(sub_tag.reg_case){
                const caseData:any = await this.dataLoader('CaseUse','Case Use',sub_tag.reg_case)
                if(caseData){
                  sub_tag.case_name = caseData.name
                }
                else console.warn('No case use data')
              }

              break;
            }
            case 'Non-Business':{
              if(sub_tag.reg_stageoflife){
                const solData:any = await this.dataLoader('StageOfLife','Stage of Life',sub_tag.reg_stageoflife)
                if(solData){
                  sub_tag.sol_name = solData.stage
                }
                else console.warn('No sol data')
              }
              
              if(sub_tag.reg_lifeevent){
                const leData:any = await this.dataLoader('LifeEvent','Life Event',sub_tag.reg_lifeevent)
                if(leData){
                  sub_tag.event_name = leData.event
                }
                else console.warn('No event data')
              }
              break;
            }
          }
        })
      } else setRegulationData.has_sub_tags = []
    }
    else setRegulationData.has_sub_tags = false
  }

  private loadAgencies(agencies:any){
    return new Promise((res,rej)=>{
      if(typeof agencies === 'string'){
        this.afs.collection('Agency').doc(agencies).snapshotChanges()
        .pipe(first())
        .subscribe({
          next:(data)=>{
            let agency_list:any=[]

            let agency_info:any = data.payload.data()
            agency_info.id = data.payload.id
            agency_list.push(agency_info)   

            return res(agency_list)
          },
          error:(err)=>{
            return res(false)
          }
        })
      }
      else if(typeof agencies === 'object'){  
        if(agencies.length > 0){
          for(let x of agencies){
            if(x !== ''){
              this.afs.collection('Agency').doc(x).snapshotChanges()
            .pipe(first())
            .subscribe({
              next:(data)=>{
                let agency_list:any = []
                let agency_info: any = data.payload.data()  
                agency_list.push(agency_info)   
                
                return res(agency_list)
              },
              error:(err)=>{
                return res(false)
              }
            })
            }
          }
        }
        else return res(false)
      }
      else return res(false)
    })
    
  }
 
  async loadRegulation(user_id: any,regulation: any) {
    this.loading = true;
    this.afs.collection(`PBRIS Pinned Regulation`, ref => ref.where('user_id', '==', user_id)
      .where('PRN', '==', regulation.PRN)).get().subscribe(data => {  
      this.regulation_info = data.docs.map(res => {
        this.loading = false;
        this.reg_id = res.id
        return res.data()
      })
      if (!!this.regulation_info)
      {
        this.regulation_status = this.regulation_info[0].status
      }
    });
  }

  deleteRegulation(collection:any) {
    this.afs.collection(collection).doc(this.route.snapshot.params.id).delete().then(() => {

      this.router.navigate(['/pbris/regulations/']).then(() => {
        alert('Regulation Successfully Deleted');
      });

    });
    
  }

  onPinned(status: any) {
    if (this.regulation_info.length > 0)
    {
      let stat: any;
      let detail: any;
      if (status) 
      {
        stat = false
        detail = "Successfully unpinned"
      }
      else
      {
        stat = true
        detail = "Successfully pinned"
      } 
      this.afs.collection(`PBRIS Pinned Regulation`)
                                      .doc(this.reg_id)
                                      .update({status: stat})
                                      .then((docRef:any) => {
        this.loading = false;
        this.regulation_status = stat
        Swal.close();
        Swal.fire({
          title: detail,
          icon: 'info'
        });
      }).catch((error:any) =>{
        this.loading = false;
        Swal.close();
        Swal.fire({
          title:'System error',
          titleText: 'Please refresh the page',
          icon: 'error'
        });
      });
    }
    else
    {
      let data: any = [];
      data.push({
        user_id: this.user_id,
        PRN: this.regulation.PRN,
        status: true
      });
      this.afs.collection(`PBRIS Pinned Regulation`)
        .add(data[0])
        .then((docRef:any) => {
        this.loading = false;
        this.loadRegulation(this.user_id,this.regulation)
        Swal.close();
        Swal.fire({
          title:'Successfully pinned',
          icon: 'info'
        });
      }).catch((error:any) =>{
        this.loading = false;
        Swal.close();
        Swal.fire({
          title:'System error',
          titleText: 'Please refresh the page',
          icon: 'error'
        });
      });
    }
  }

  onUser()
  { 
    this.auth.user.subscribe(data => {
      this.user_id = data?.uid;
    });
  }

  // control view
  showContent() {
    this.show_comment = false;
    this.show_content = true;
  }

  showComment() {
    this.show_comment = true;
    this.show_content = false;
  }

  resetFlag(){
    for(let code of Object.keys(this.showViews)) this.showViews[code] = false
  }

  onView(code:string){
    this.resetFlag()
    this.showViews[code] = true
  }

  // onPDFView(){ 
  //   this.resetFlag();   
  //   this.pdfView= true;
  // }

  // onPhase2View(){ 
  //   this.resetFlag();   
  //   this.phase2View= true;
  // }

  // onCrisView(){ 
  //   this.resetFlag();   
  //   this.crisView= true;
  // }

  // onDrisView(){ 
  //   this.resetFlag();   
  //   this.drisView= true;
  // }

  // onTextRegView(){
  //   this.resetFlag();
  //   this.textRegView= true
  // }

  // onPiaView(){ 
  //   this.resetFlag();   
  //   this.piaView= true;
  // }

  // onNotificationsView(){ 
  //   this.resetFlag();   
  //   this.notificationsView= true;
  // }

  // onRegDetailsView(){
  //   this.resetFlag();
  //   this.regDetailsView = true;
  // }

  // onPiaAssessmentView(){ 
  //   this.resetFlag();   
  //   this.piaAssessmentView= true;
  // }

  // onRiaAssessmentView(){ 
  //   this.resetFlag();   
  //   this.riaAssessmentView= true;
  // }


  // resetFlag(){
  //   this.phase2View= false;
  //   this.pdfView= false;
  //   this.textRegView= false;
  //   this.crisView= false;
  //   this.drisView= false;
  //   this.piaView = false;
  //   this.notificationsView = false;
  //   this.regDetailsView = false;
  //   this.piaAssessmentView = false;
  //   this.riaAssessmentView = false;
  // }
  // !control view

  // pdf functions
  
  pageShift(action:any,section:any,index?:any){
    if(section == 'file'){
      if(action == "next"){
        if(this.filePage < this.fileTotalPage)
          this.filePage++;
      }
      if(action == "back"){
        if(this.filePage > 1)
          this.filePage--;
      }
    }
    
  }

  afterLoadComplete(pdf: PDFDocumentProxy,section:any){
    if(section === 'file'){
      this.fileTotalPage = pdf.numPages;
    }
  }
  // !pdf functions

  toEditRegulation(param:any){
    if(this.from === "existing" || this.from === "proposed"){
      this.collection = this.collectionInfo[this.from].baseCollection
      this.toUpdate(this.collection,param)
      // if(this.from === "existing"){
      //   // this.collection = "regulations-existing-phase-02"
      //   this.collection = this.collectionInfo[this.from].baseCollection
      //   this.toUpdate(this.collection,param)
      // }
      // else if(this.from === "proposed"){
      //   this.collection = "regulations-proposed-phase-02"
      //   this.toUpdate(this.collection,param)
      // }
    }
    else{
      // this.collection = "PBRIS 1 Regulations"
      this.collection = this.collectionInfo['pbris1'].baseCollection
      this.toUpdate(this.collection,param)
    }

  }

  toUpdate(collection:any, param:any){
    this.afs.collection(collection).doc(this.id).update({
      date_published_gazette: param.date_published_gazette ? param.date_published_gazette : null,
      date_published_onar: param.date_published_onar ? param.date_published_onar : null
    }).then(()=>{
      this.regulation.date_published_gazette = param.date_published_gazette ? param.date_published_gazette : null
      this.regulation.date_published_onar = param.date_published_onar ? param.date_published_onar : null
      alert('Regulation Update Successfully')
    }).catch((error)=>{
      console.error('error',error)
      alert('Regulation Update Error')
    })
  }

  toCancel(){
    window.history.back();
  }
}
