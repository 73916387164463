<app-artemis-navigation-header [currentPage]="'SearchPage'"></app-artemis-navigation-header>

<div class="content">
  <div class="px-5
      tab-pane
      border 
      bg-light
      fade
      show
      active
    " id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
    <div class="d-flex">
      <h5>Citizen's Charter</h5>
    </div>
    <!---[formGroup]="searchform" (ngSubmit)="search()"-->
    <form>
      <!--
        <div class="row input-group my-3">
        <label for="keyword">Keywords:</label>
        <input
          type="text"
          class="form-control mx-2"
          placeholder="Keyword"
          formControlName="keyword"
        />
      </div>
      -->
      <div class="row px-2">
        <div class="col">
          <div class="row">
            <!-- <button class="btn btn-light col m-1">Organization Group</button>
              <button class="btn btn-light col m-1">Department</button>
              <button class="btn btn-light col m-1">Agency</button> -->

            <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'National Government Agencies'}"
              class="btn col m-1 align-self-center">
              <img src="../../assets/artemis_landing_icons_acronym/nga.png"  >
            </a>
            <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'Local Government Agencies'}"
              class=" btn col m-1 align-self-center">
              <img src="../../assets/artemis_landing_icons_acronym/lgu.png"  >
            </a>
            <a routerLink="/artemis/citizen-charter"
              [queryParams]="{keyword: 'Government-Owned & Controlled Corporations'}"
              class=" btn col m-1 align-self-center">
              <img src="../../assets/artemis_landing_icons_acronym/gocc.png"  >
            </a>
            <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'Hospitals'}"
              class=" btn col m-1 align-self-center">
              <img src="../../assets/artemis_landing_icons_acronym/hosp.png"  >
            </a>
            <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'State Universities and Colleges'}"
              class=" btn col m-1 align-self-center">
              <img src="../../assets/artemis_landing_icons_acronym/suc.png"  >
            </a>
            <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'Local Water Districts'}"
              class=" btn  col m-1 align-self-center">
              <img src="../../assets/artemis_landing_icons_acronym/wd.png"  >
            </a>
            <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'Constitutional Offices'}"
              class=" btn  col m-1 align-self-center">
              <img src="../../assets/artemis_landing_icons_acronym/cc.png"  >
            </a>
          </div>
        </div>
        <div class="col-auto mx-2">
          <div class="row form-group m-1">
            <button (click)="reloadpage()" class="btn btn-lg btn-light "><span> Reload Page 
              <i class="fas fa-sync-alt"></i></span>
            </button>
          </div>
          <!-- TODO: Advanced search for Citizen's Charter -->
          <!-- <div class="row form-group m-1">
            <button class="btn btn-lg btn-outline-dark fw-bold border-0" (click)="advanced_search()">
              Advanced Search?
            </button>
          </div> -->
        </div>
      </div>
    </form>
  </div>

  <!-- Charter List -->
  <a href="/artemis/citizen-charter/upload" class="btn btn-primary float-end m-3" *ngIf="userType == 'arta'">
    Upload
  </a>
  <app-quick-links-artemis></app-quick-links-artemis>
  <div class="container">
    <mat-card>
      <div class="col my-3">
        <div class="col d-inline-flex">
          <h5 class="text-primary fw-bold m-1">Citizen's Charter Results</h5>
          <!--
            <h3 class="text-danger fw-bold mt-2 mx-3" *ngIf="!loading">
            {{ charters.length }}
          </h3>
          -->
        </div>
        <p class="border border-primary w-80 m-3 text-center"></p>
  
        <div class="col my-3">
  
          <div class="d-flex justify-content-center align-self-center">
            <div class="spinner-grow " role="status" *ngIf="loading">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
  
          <table style="width: 100%;" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-hover" [hidden]="!charters">
            <thead hidden>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody >
              <!-- Table Item -->
              <tr *ngFor="let entry of charters" (click)="viewCitizensCharter(entry)" class="charter-row">
                <td style="width: 20%; padding-top: 20px;" >
                  <!-- <img
                    src="../../assets/ph-seal_small.png"
                    class="img-fluid m-0 align-self-center"
                  /> -->
                  <div class="d-flex justify-content-center">
                    <div class=" img-container">
                      <img [src]="getImage(entry)">
                    </div>
                  </div>
                </td>
                <td style="padding-top: 20px">
                  <h5 class="fw-bold mb-0" *ngIf="entry.agency_name">{{entry.agency_name }}</h5>
                  <div class="my-3">
                    <p class="mb-0" *ngIf="entry.agency_abv">Abbreviation: {{ entry.agency_abv }}</p>
                    <p class="mb-0" *ngIf="entry.consolidated">CONSOLIDATED SUBMISSION</p>
                    <p class="mb-0" *ngIf="entry.date">Citizen's Charter as of: {{ entry.displayDate }}</p>
                    <p class="mb-0" *ngIf="entry.agency_category">Category:  {{ entry.agency_category }}</p>
                    <p class="mb-0" *ngIf="entry.tags">Tags: {{ entry.tags }}</p>
                  </div>
                  <!-- <p>
                    <i class="mx-2" *ngIf="entry.organization_level_01"
                      ><strong class="fw-bold"> Name of Agency:</strong>
                       {{entry.organization_level_01 }}</i
                    >
                    <i class="mx-2" *ngIf="entry.agency_initials"
                      ><strong class="fw-bold">ACRONYM</strong>
                      {{ entry.agency_initials }}</i
                    >
                  </p> 
                  <p *ngIf="entry.agency_name || entry.agency_initials">
                    <i class="mx-2" *ngIf="entry.agency_name"><strong class="fw-bold"> Name of Agency:</strong>
                      {{entry.agency_name }}</i>
                    <i class="mx-2" *ngIf="entry.agency_abv"><strong class="fw-bold">ACRONYM</strong>
                      {{ entry.agency_abv }}</i>
                  </p>
                  <p *ngIf="entry.consolidated">
                    <i class="mx-2"><strong class="fw-bold">CONSOLIDATED SUBMISSION</strong>
                    </i>
                  </p>
                  <p *ngIf="entry.date">
                    <i class="mx-2"><strong class="fw-bold"> Citizen's Charter as of:</strong>
                      {{ entry.displayDate }}</i>
                  </p>
                 <p *ngIf="entry.organization_grouping_01">
                    <i class="mx-2" 
                      ><strong class="fw-bold">Category:</strong>
                      {{ entry.organization_grouping_01 }}</i
                    >
                  </p> 
                  <p *ngIf="entry.agency_category">
                    <i class="mx-2"><strong class="fw-bold">Category:</strong>
                      {{ entry.agency_category }}</i>
                  </p>
                  <p *ngIf="entry.tags">
                    <i class="mx-2"><strong class="fw-bold">Tags:</strong>
                      {{ entry.tags }}</i>
                  </p>-->
  
                  <!--modify this later
                  <div class="row" *ngIf="entry.services">
                  <div class="row">
                    <i *ngFor="let service of entry.services" class="mx-2"><strong class="fw-bold">Services:</strong>
                      {{ service.serviceDetails.service_name }}</i>
                  </div>
                </div>
                  -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-card>
    
  </div>
</div>