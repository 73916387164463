<app-artemis-navigation-header
  [currentPage]="'SearchPage'"
></app-artemis-navigation-header>

<div class="content">
    <div
        class="
        tab-pane
        border border-dark border-top-0
        bg-light
        rounded-bottom
        fade
        show
        active
        "
        id="ex1-tabs-1"
        role="tabpanel"
        aria-labelledby="ex1-tab-1"
    >
    <div class="d-flex">
        <h4>Agency</h4>
      </div>
      <form [formGroup]="searchform" (ngSubmit)="search()">
      <!-- <form> -->
        <div class="row input-group my-3">
          <div class="col">
            <label for="keyword">Keywords:</label>
            <input
              type="text"
              class="form-control mx-2"
              placeholder="Keyword"
              formControlName="keyword"
            />
          </div>
          <div class="col-1">
            <button type="submit" class="btn btn-lg btn-light p-3">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
</div>

<!-- Agency List -->

<div class="container">
    <mat-card>
      <div class="col my-3">
        <div class="col d-inline-flex mx-3">
            <h5 class="text-primary fw-bold">Agency Results</h5>
            <h5 class="text-danger fw-bold mx-3 " *ngIf="!loading">
              {{ agencies.length }}
            </h5>
        </div>
        <p class="border border-primary w-80 m-3 text-center"s></p>

        <div class="col my-3">
            <div class="d-flex justify-content-center align-self-center">
              <div class="spinner-grow " role="status" *ngIf="loading">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            
            <table style="width: 100%;"
              datatable
              [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger"
              class="table table-hover"
              [hidden]="!agencies"
            >
              <thead hidden>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <!-- Table Item -->
                <!-- <tr
                  *ngFor="let entry of services"
                  (click)="viewCitizensCharter(entry.id)"
                  class="charter-row"
                > -->
                <tr 
                  *ngFor="let entry of agencies"
                  (click)="viewAgency(entry.id)"
                  class="charter-row"
                >
                  <td style="width: 20%; padding-top: 20px;">
                    <!-- <img
                      src="../../assets/ph-seal_small.png"
                      class="img-fluid m-0 align-self-center"
                    /> -->
                    <div class="d-flex justify-content-center p-0 m-0">
                      <div class="img-container">
                        <img [src]="getImage(entry)">
                      </div>
                    </div>
                  </td>
                  <td style="padding-top: 20px">
                    <h5 class="fw-bold mb-0">{{entry.agencyDetails.agencyName }}</h5>
                    <div class="my-3">
                      <p class="mb-0" *ngIf="entry.abbreviation">Abbreviation: {{ entry.agencyDetails.agencyAbbreviation }}</p>
                      <p class="mb-0" *ngIf="entry.address">Address: {{ entry.address }}</p>
                    </div>
                    <!--<p>
                      <i class="mx-2" *ngIf="entry.abbreviation"
                        ><strong class="fw-bold">Abbreviation</strong>
                        {{ entry.agencyDetails.agencyAbbreviation }}</i
                      >
                    </p>
                    <p>
                      <i class="mx-2" *ngIf="entry.consolidated"
                        ><strong class="fw-bold">CONSOLIDATED SUBMISSION</strong>
                      </i>
                    </p> 
                    <p>
                      <i class="mx-2" *ngIf="entry.address"
                        ><strong class="fw-bold"> Address</strong>
                        {{ entry.address }}</i
                      >
                    </p>-->
                    <!-- <p>
                      <i class="mx-2" *ngIf="entry.organization_grouping_01"
                        ><strong class="fw-bold">Category:</strong>
                        {{ entry.organization_grouping_01 }}</i
                      >
                    </p> -->
                    <!-- <p>
                      <i class="mx-2" *ngIf="entry.tags"
                        ><strong class="fw-bold">Tags:</strong>
                        {{ entry.tags }}</i
                      >
                    </p> -->
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </mat-card>
    
</div>

