<!-- <app-artemis-navigation-header
  [currentPage]="''"
></app-artemis-navigation-header> -->

<div *ngIf="headervisible">
    <ng-container [ngSwitch]="currentSystem">
        <app-artemis-navigation-header  *ngSwitchCase="artemis"></app-artemis-navigation-header>
        <app-navbar *ngSwitchCase="pbris"></app-navbar>
        <app-artemis-navigation-header *ngSwitchDefault [currentPage]="''"></app-artemis-navigation-header>
    </ng-container>
</div>

<!-- Agency List -->
<div class="d-flex justify-content-center align-self-center">
    <div class="spinner-grow" role="status" *ngIf="loader">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<mat-card>
    <mat-card-body>
        <div class="container" *ngIf="!loader">

            <!-- Title -->
            <div class="row p-3">
                <div class="col-12">
                    <div class="row"><!-- here for form name and abb-->
                        <div class="col-8 relative hover-parent">
                            <div class="absolute top-pos rigth-pos hover-1" *ngIf="isAdmin || isLeadEncoder">
                                <div *ngIf="!editTitle">
                                    <button type="button" (click)="update('title')" class="btn update-btn btn-primary"
                                        [disabled]="loading">
                                        <span class="spinner-border spinner-border-sm mr-1"
                                            *ngIf="loading"></span>Update</button>
                                    <button type="button" (click)="toggleEdit('title')" class="btn btn-danger">Cancel</button>
                                </div>
                            </div>
                            <form class="form-group" [formGroup]="agencyTitleForm">
                                <div class=" d-flex flex-row">
                                    <div *ngIf="(isAdmin) && editTitle" class="p-2 centering">
                                        <a (click)="toggleEdit('title')" style=" z-index: 100; cursor: pointer;"
                                            class="{{ style_theme }}-text report-title fw-bold"
                                            title="Edit Agency Abbreviation, Agency Name, Agency Category">
                                            <i class="fas fa-edit"></i>
                                        </a>
                                    </div>
                                    <div class="p-2 centering" *ngIf="editTitle" style="width:100%">
                                        <h2 class="{{ style_theme }}-text report-title fw-bold">
                                            <!--  class="d-flex flex-row" -->
                                            <p class="wrapper">
                                                <span *ngIf="agency_abbreviation"
                                                    style="margin-right:1em;">({{agencyTitleForm.value.agencyAbbreviation}})</span>
                                                <span *ngIf="agency_name">{{agencyTitleForm.value.agencyName}}</span>
        
                                            </p>
                                        </h2>
                                    </div>
                                    <div class="p-2 centering" *ngIf="!editTitle" style="width:100%">
                                        <h2 class="{{ style_theme }}-text report-title fw-bold">
                                            <div>
                                                <span>
                                                    <h4>Agency Abbreviation</h4>
                                                </span>
                                                <input class="form-control" formControlName="agencyAbbreviation">
                                                <ng-container *ngFor="let item of checkAgencyTitleForm.agencyAbbreviation">
                                                    <mat-error style="color:red"
                                                        *ngIf="hasErrors('title','agencyAbbreviation',item.type)">
                                                        <h6>* {{item.message}}</h6>
                                                    </mat-error>
                                                </ng-container>
                                            </div>
                                            <div class="full-width" style="margin-top:1em">
                                                <span>
                                                    <h4>Agency Name</h4>
                                                </span>
                                                <input class="form-control full-width" formControlName="agencyName">
                                                <ng-container *ngFor="let item of checkAgencyTitleForm.agencyName">
                                                    <mat-error style="color:red"
                                                        *ngIf="hasErrors('title','agencyName',item.type)">
                                                        <h6>* {{item.message}}</h6>
                                                    </mat-error>
                                                </ng-container>
                                            </div>
                                        </h2>
                                    </div>
                                </div>
                                <div>
                                    <span
                                        *ngIf="editTitle && agency_category && (agency_category.abbreviation || agency_category.name) ">
                                        <span
                                            *ngIf="agency_category.abbreviation">({{agencyTitleForm.value.agencyCategory.abbreviation}})</span>
                                        {{agencyTitleForm.value.agencyCategory.name}}
                                    </span>
                                    <div class="row">
                                        <span *ngIf="editTitle">
                                            <span><span>Mother Agency:</span>{{agencyTitleForm.value.motherAgency.motheragency?
                                                agencyTitleForm.value.motherAgency.motheragency:""}}</span>
                                        </span>
                                    </div>
                                    <span class="p-2 centering" *ngIf="!editTitle">
                                        <h6 class="{{ style_theme }}-text report-title fw-bold row align-items-center">
                                            <div class="col-3">
                                                Agency Category :
                                            </div>
                                            <div class="col">
                                                <select class="form-select" #select_agencyCat
                                                    (change)="universalUpdater('agencyCat',select_agencyCat.value)">
                                                    <option selected disabled value="">Category</option>
                                                    <ng-container *ngFor="let item of agency_categories">
                                                        <option [selected]="universalSelected('agencyCat',item.id)"
                                                            [value]="item.id">({{item.abbreviation}}) {{item.name}}</option>
                                                    </ng-container>
                                                </select>
                                                <ng-container *ngFor="let item of checkAgencyTitleForm.id">
                                                    <mat-error style="color:red"
                                                        *ngIf="hasErrors('agencyCatID','id',item.type)">
                                                        <h6>* {{item.message}}</h6>
                                                    </mat-error>
                                                </ng-container>
                                            </div>
                                        </h6>
                                        <!--changing of mother agency only depends on ARTA IT admin-->
                                        <h6 *ngIf="isAdmin"
                                            class="{{ style_theme }}-text report-title fw-bold row align-items-center">
                                            <div class="col-3">
                                                Mother Agency :
                                            </div>
                                            <div class="col">
                                                <select class="form-select" #select_mother
                                                    (change)="universalUpdater('motherAgency',select_mother.value)">
                                                    <option selected disabled value="">Agency</option>
                                                    <ng-container *ngFor="let item of motheragencies">
                                                        <option [selected]="universalSelected('motherAgency',item.id)"
                                                            [value]="item.id">{{item.motheragency}}</option>
                                                    </ng-container>
                                                </select>
                                                <ng-container *ngFor="let item of checkAgencyTitleForm.motherAgencyID">
                                                    <mat-error style="color:red"
                                                        *ngIf="hasErrors('motherAgencyID','id',item.type)">
                                                        <h6>* {{item.message}}</h6>
                                                    </mat-error>
                                                </ng-container>
                                            </div>
                                        </h6>
                                    </span>
                                </div>
                            </form>
        
                        </div>
        
                        <div class="col-4 relative hover-parent">
                            <div class="hover-1" *ngIf="isAdmin || isLeadEncoder">
                                <!-- <div class="absolute minus-top-pos center-pos">
                                    <button *ngIf="editWebsite" type="button" class="edit-btn bg-white btn btn-outline-info" (click)="toggleEdit('website')"><i class="fas fa-edit"></i></button>
                                </div> -->
                                <div class="absolute minus-top-pos rigth-pos" *ngIf="!editWebsite">
                                    <button type="button" (click)="update('website')" class="btn update-btn btn-primary"
                                        [disabled]="loading">
                                        <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>
                                        Update
                                    </button>
                                    <button type="button" (click)="toggleEdit('website')" class="btn btn-danger">Cancel</button>
                                </div>
                            </div>
                            <div *ngIf="!editWebsite" class="{{ style_theme }}-text report-title fw-bold full-width">
                                <form [formGroup]="agencyWebsiteForm">
                                    <div class="row">
                                        <span>Website URL :</span>
                                    </div>
                                    <div class="row">
                                        <!-- <div class="col-5">
                                            <select class="form-select form-control" #website_protocol (change)="universalUpdater('website',website_protocol.value)">
                                                <option selected disabled value="">Protocol</option>
                                                <option *ngFor="let protocol of universalOption('website')"
                                                    [value]="protocol"
                                                    [selected]="universalSelected('website',protocol)">
                                                    {{protocol}}//</option>
                                            </select>
                                            <ng-container *ngFor="let item of checkAgencyWebsiteForm.protocol"> 
                                                <mat-error style="color:red; font-weight:normal;" *ngIf="hasErrors('website','protocol',item.type)"><h6>* {{item.message}}</h6></mat-error>
                                            </ng-container>
                                        </div> -->
                                        <div class="col">
                                            <input class="form-control" placeholder="Ex. example.com" formControlName="website">
                                            <ng-container *ngFor="let item of checkAgencyWebsiteForm.website">
                                                <mat-error style="color:red; font-weight:normal;"
                                                    *ngIf="hasErrors('website','website',item.type)">
                                                    <h6>* {{item.message}}</h6>
                                                </mat-error>
                                            </ng-container>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div *ngIf="editWebsite">
                                <a [href]="getAgencyFullURL()" target="_blank"
                                    class="{{ style_theme }}-text report-title fw-bold"
                                    style="float: right; text-align: right;">
                                    <span>Visit Agency Website:
                                        <h2>
                                            <i class="fas fa-globe"></i>
                                        </h2>
                                    </span>
                                </a>
                                <a *ngIf="isAdmin || isLeadEncoder" (click)="toggleEdit('website')"
                                    style="margin-right:1em; float: right; z-index: 100; cursor: pointer;"
                                    class="{{ style_theme }}-text report-title fw-bold" title="Edit Agency Website URL">
                                    <i class="fas fa-edit"></i>
                                </a>
        
                            </div>
        
                        </div>
                        <!-- <div class="col-1">
                                            <div class="{{ style_theme }}-text report-title fw-bold" style="float: right;">
        
                                            </div>
                                            <h2 class="{{ style_theme }}-text report-title fw-bold" style="float: right;">
                                                    <a [src]="agency_website">
                                                            <i class="fas fa-globe"></i>
                                                    </a>
                                            </h2>
                                    </div> -->
                    </div>
        
                    <div class="relative hover-parent">
        
                        <div class="absolute top-pos rigth-pos hover-1" *ngIf="isAdmin || isLeadEncoder">
                            <!-- <div>
                                <button *ngIf="editAddress" type="button" class="edit-btn btn btn-outline-info" (click)="toggleEdit('address')"><i class="fas fa-edit"></i></button>
                            </div> -->
                            <div *ngIf="!editAddress">
                                <button type="button" (click)="update('address')" class="btn update-btn btn-primary"
                                    [disabled]="loading">
                                    <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>Update</button>
                                <button type="button" (click)="toggleEdit('address')" class="btn btn-danger">Cancel</button>
                            </div>
                        </div>
        
                        <p class="border border-dark"></p>
                        <div *ngIf="editAddress" class="row">
        
                            <div class="col">
                                <!-- <p>{{agencyAddressForm.value.addressLine+" "+agencyAddressForm.value.barangay+" "+ agencyAddressForm.value.city+" "+agencyAddressForm.value.province+", "+agencyAddressForm.value.postal}}</p> -->
                                <p>
                                    <span *ngIf="(isAdmin || isLeadEncoder)">
                                        <a (click)="toggleEdit('address')" style=" z-index: 100; cursor: pointer;"
                                            class="{{ style_theme }}-text report-title fw-bold" title="Edit Agency Address">
                                            <i class="fas fa-edit"></i>
                                        </a>
                                    </span>
                                    {{agency_address}}
                                </p>
                            </div>
                            <!-- <div class="col">
                                            {{ service_category }} of the
                                            <strong class="text-danger">{{ service_agency }}</strong>
                                            issued on
                                            <strong class="text-danger">{{
                                                    service_date | date: "MM/dd/yyyy"
                                            }}</strong>
                                            </div> -->
                        </div>
                        <div *ngIf="!editAddress" class="row form-group">
                            <form [formGroup]="agencyAddressForm">
                                <div class="row" style="margin-bottom: 1em;">
                                    <div class="col full-width">
                                        <p style="margin-bottom:10px;">Address Line: House no / Building no. - Street name /
                                            Avenue name - Subdivision :</p>
                                        <input class="form-control" formControlName="addressLine">
                                        <ng-container *ngFor="let item of checkAgencyAddressForm.addressLine">
                                            <mat-error style="color:red" *ngIf="hasErrors('address','addressLine',item.type)">*
                                                {{item.message}}</mat-error>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col d-flex flex-row">
                                        <div class="p-2 centering" style="width: 33%;">
                                            <p>Barangay</p>
                                        </div>
                                        <div class="p-2 centering">:</div>
                                        <div class="p-2 centering full-width">
                                            <input class="form-control" formControlName="barangay">
                                            <ng-container *ngFor="let item of checkAgencyAddressForm.barangay">
                                                <mat-error style="color:red" *ngIf="hasErrors('address','barangay',item.type)">*
                                                    {{item.message}}</mat-error>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col d-flex flex-row">
                                        <div class="p-2 centering" style="width: 43%;">
                                            <p>City / Municipality</p>
                                        </div>
                                        <div class="p-2 centering">:</div>
                                        <div class="p-2 centering full-width">
                                            <input class="form-control" formControlName="city">
                                            <ng-container *ngFor="let item of checkAgencyAddressForm.city">
                                                <mat-error style="color:red" *ngIf="hasErrors('address','city',item.type)">*
                                                    {{item.message}}</mat-error>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col d-flex flex-row">
                                        <div class="p-2 centering" style="width: 33%;">
                                            <p>Province - Region</p>
                                        </div>
                                        <div class="p-2 centering">:</div>
                                        <div class="p-2 centering full-width">
                                            <input class="form-control" formControlName="province">
                                            <ng-container *ngFor="let item of checkAgencyAddressForm.province">
                                                <mat-error style="color:red" *ngIf="hasErrors('address','province',item.type)">*
                                                    {{item.message}}</mat-error>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col d-flex flex-row">
                                        <div class="p-2 centering" style="width: 43%;">
                                            <p>Postal code / Zip code</p>
                                        </div>
                                        <div class="p-2 centering">:</div>
                                        <div class="p-2 centering full-width">
                                            <input class="form-control" type="number" formControlName="postal">
                                            <ng-container *ngFor="let item of checkAgencyAddressForm.postal">
                                                <mat-error style="color:red" *ngIf="hasErrors('address','postal',item.type)">*
                                                    {{item.message}}</mat-error>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="row my-4">
                <!-- Tabs navs -->
                <div style="padding:1rem;">
                    <ul class="nav nav-tabs mb-3" id="ex1" role="tablist" style="border-top: 2px solid #eceef1; ">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link active" id="ex1-tab-1" data-mdb-toggle="tab" href="#ex1-tabs-1" role="tab"
                                aria-controls="ex1-tabs-1" aria-selected="true">Details</a>
                        </li>
                        <li class="nav-item" role="presentation" *ngIf="currentSystem == artemis">
                            <a class="nav-link" id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-2" role="tab"
                                aria-controls="ex1-tabs-2" aria-selected="false">Cart</a>
                        </li>
        
                        <li class="nav-item" role="presentation"
                            *ngIf="currentSystem == artemis">
                            <a class="nav-link" id="ex1-tab-9" data-mdb-toggle="tab" href="#ex1-tabs-9" role="tab"
                                aria-controls="ex1-tabs-9" aria-selected="false">Central Office</a>
                        </li>
                        <li class="nav-item" role="presentation" *ngIf="currentSystem == artemis">
                            <a class="nav-link" id="ex1-tab-6" data-mdb-toggle="tab" href="#ex1-tabs-6" role="tab"
                                aria-controls="ex1-tabs-6" aria-selected="false">Lower Level Operating Unit</a>
                        </li>

                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="ex1-tab-3" data-mdb-toggle="tab" href="#ex1-tabs-3" role="tab"
                                aria-controls="ex1-tabs-3" aria-selected="false">Feedback & Complaints Mechanism</a>
                        </li>
                        
                        <li class="nav-item" role="presentation" *ngIf="currentSystem == artemis">
                            <a class="nav-link" id="ex1-tab-4" data-mdb-toggle="tab" href="#ex1-tabs-4" role="tab"
                                aria-controls="ex1-tabs-4" aria-selected="false">Encoded Services</a>
                        </li>
        
                        <li class="nav-item" role="presentation"
                            *ngIf="currentSystem == artemis && (isAgencyMember || isAdmin || isArtaMember)">
                            <a class="nav-link" id="ex1-tab-8" data-mdb-toggle="tab" href="#ex1-tabs-8" role="tab"
                                aria-controls="ex1-tabs-8" aria-selected="false">Certificate of Compliance</a>
                        </li>
        
                        <li class="nav-item" role="presentation" *ngIf="currentSystem == artemis">
                            <a class="nav-link" id="ex1-tab-7" data-mdb-toggle="tab" href="#ex1-tabs-7" role="tab"
                                aria-controls="ex1-tabs-7" aria-selected="false">Posted Citizen's Charter</a>
                        </li>
        
        
                        <li class="nav-item" role="presentation" *ngIf="currentSystem == pbris">
                            <a class="nav-link" id="ex1-tab-5" data-mdb-toggle="tab" href="#ex1-tabs-5" role="tab"
                                aria-controls="ex1-tabs-5" aria-selected="false">Regulations</a>
                        </li>
                    </ul>
                </div>
                <!-- Tabs navs -->
        
                <!-- Tabs content -->
                <div class="tab-content" id="ex1-content">
                    <div class="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
                        <app-artemis-view-organization-details [isAdmin]="isAdmin" [isAgencyMember]="isLeadEncoder"
                            [agency_doc]="doc_id"></app-artemis-view-organization-details>
                    </div>
                  
                    <div class="tab-pane fade" id="ex1-tabs-9" role="tabpanel" aria-labelledby="ex1-tab-9"
                        *ngIf="currentSystem == artemis">
                        <app-agency-office-division [agency_id]="doc_id" [isAdmin]="isAdmin" [isAgencyMember]="isLeadEncoder"
                            [agency_doc]="doc_id"></app-agency-office-division>
                    </div>
        
                    <div class="tab-pane fade" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2"
                        *ngIf="currentSystem == artemis">
                        <app-artemis-view-organization-staffs [isAdmin]="isAdmin" [isAgencyMember]="isLeadEncoder" [cartDetails]="cartDetails"
                            [agency_doc]="doc_id"></app-artemis-view-organization-staffs>
                    </div>
        
                    <div class="tab-pane fade" id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
                        <app-artemis-view-organization-help-details [isAdmin]="isAdmin" [isAgencyMember]="isLeadEncoder"
                            [agency_doc]="doc_id"></app-artemis-view-organization-help-details>
                    </div>
        
                    <div class="tab-pane fade" id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4"
                        *ngIf="currentSystem == artemis">
                        <app-artemis-view-agency-services-details [isAdmin]="isAdmin" [isAgencyMember]="isLeadEncoder"
                            [agency_doc]="doc_id"></app-artemis-view-agency-services-details>
                    </div>
        
                    <div class="tab-pane fade" id="ex1-tabs-6" role="tabpanel" aria-labelledby="ex1-tab-6"
                        *ngIf="currentSystem == artemis">
                        <app-agency-offices [agency_id]="doc_id" [isAdmin]="isAdmin"
                            [isAgencyMember]="isLeadEncoder" [leadEncoder]="isLeadEncoder"></app-agency-offices>
                    </div>
        
                    <div class="tab-pane fade" id="ex1-tabs-8" role="tabpanel" aria-labelledby="ex1-tab-8"
                        *ngIf="currentSystem == artemis">
                        <app-coc-submissions [agency_id]="doc_id" [isAdmin]="isAdmin"
                            [isAgencyMember]="isLeadEncoder"></app-coc-submissions>
                    </div>
        
        
        
                    <div class="tab-pane fade" id="ex1-tabs-7" role="tabpanel" aria-labelledby="ex1-tab-7"
                        *ngIf="currentSystem == artemis">
                        <app-agency-citizens-charter [agency_id]="doc_id" [isAdmin]="isAdmin"
                            [isAgencyMember]="isLeadEncoder"></app-agency-citizens-charter>
                    </div>
        
                    <div class="tab-pane fade" id="ex1-tabs-5" role="tabpanel" aria-labelledby="ex1-tab-5"
                        *ngIf="currentSystem == pbris">
                        <app-artemis-view-agency-regulation-list
                            [agency_doc]="doc_id"></app-artemis-view-agency-regulation-list>
                    </div>
                </div>
                <!-- Tabs content -->
            </div>
        </div>
    </mat-card-body>
</mat-card>