<div class="container-fluid p-0">
    <div class="row g-0">

        <!-- Carousel -->
        <div class="col-5 d-none d-lg-block">
          <app-pbris-carousel></app-pbris-carousel> 
        </div>

        <!-- Login Card -->
        <div class="col-sm form-column">
            <div class="form-container mx-3">
                <app-pbris-form-banner></app-pbris-form-banner>
                <form [formGroup]="form">
                    <!-- <div class="form-group m-0">
                        <h1 class="text-center">Welcome!</h1>
                    </div> -->
                    <div class="form-group fw-bold mt-5 mb-4">
                        <label for="username" class="mb-1">USER ID</label>
                        <div class = "input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                            <img src="/assets/Assets/Icons/Login Page/LoginPage-User.png" height="20" alt="..." />
                            </span>
                        </div>
                        <input type="email" class="form-control" formControlName="username" />
                        </div>
                        <!--  [ngClass]="{ 'is-invalid': submitted && f.username.errors }" -->
                        <div class="invalid-feedback">
                            <!-- *ngIf="submitted && f.username.errors"  -->
                            <div>Username is required</div>
                            <!-- *ngIf="f.username.errors.required" -->
                        </div>
                    </div>

                    <div class="form-group fw-bold">
                        <label for="password" class="mb-1">PASSWORD</label>
                        <div class = "input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                            <img src="/assets/Assets/Icons/Login Page/LoginPage-PS.png" height="19" alt="..." />
                            </span>
                        </div>
                        <input type="password" class="form-control" formControlName="password" />
                        </div>
                        <!--  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" -->
                        <div class="invalid-feedback">
                            <!-- *ngIf="submitted && f.password.errors" -->
                            <div>Password is required</div>
                            <!-- *ngIf="f.password.errors.required" -->
                        </div>
                    </div>

                    <div class="row my-2">
                        <div class="col-md-7 mt-2">
                            <p class="text-start">
                                <a class="fw-500" routerLink="/forgot-password" [queryParams]="{requestFrom: 'pbris'}">Forgot password?</a>
                            </p>
                        </div>

                        <div class="d-grid my-2 col-5">
                            <button (click)="onSubmit()" class="btn btn-primary btn-rounded float-end" [disabled]="loading">
                                <span class="spinner-border spinner-border-sm mr-3" *ngIf="loading"></span> 
                                Log-in
                            </button>
                            <button class="btn btn btn-danger btn-rounded mt-2" (click)="loginAsGuest()">Continue as Guest</button>
                        </div>
                    </div>

                    <!-- <div class="form-group text-center mt-5 d-grid gap-2 d-md-block">
                        <p class="fw-bold">Don't have an account?</p>
                        <a class="btn btn-outline-primary col m-1" routerLink="/registration-pbris">Sign-up</a>
                        <button class="btn btn btn-outline-primary col m-1" (click)="loginAsGuest()">Continue as Guest</button>
                    </div> -->
                </form>
            </div>
        </div>
    </div>
</div>