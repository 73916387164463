<div class="content">
    <div class="container-fluid p-4">
        <div class="mb-3 row justify-content-between form-group">
            <div class="col-xs-3 col-sm-auto ">
                <button *ngIf="isAdmin" class="btn btn-sm btn-primary" (click)="btnAction('create',currentRef, '')">create {{currentRef}}</button>
            </div>
            <div class="col-lg-5 col-sm-8 d-flex align-items-center">
                <span style="margin:0.5em;">Search</span>
                <ng-container [formGroup]="searchForm">
                    <input class="form-control" (keyup)="search()" formControlName="search" type="text">
                </ng-container>
            </div>
        </div>
        <div class="mb-3 row">
            <table mat-table 
                class="table table-hover table-responsive"
                id="matTable"
                [dataSource]="refDataSource"
                matSort
                >

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let ref">
                        <div *ngIf="isAdmin" class="d-flex align-items-center" style="padding:0.3em;">
                            <button class="menuBtn"
                                mat-icon-button
                                [matMenuTriggerFor]="menu"
                                [matMenuTriggerData]="{ref: ref}"
                                #menuTrigger>
                                <mat-icon>more_vert</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="section">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Section</h5>  </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="d-flex align-items-center" style="padding:0.3em;">
                            <p style="text-align: justify;">{{element.section ? element.section : "N/A"}}</p>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>{{currentRef}} Name</h5>  </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="d-flex align-items-center" style="padding:0.3em;">
                            <p style="text-align: justify;">{{element.name ? element.name : "N/A"}}</p>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="definition">
                    <!--  mat-sort-header -->
                    <th mat-header-cell *matHeaderCellDef> <h5>Definition</h5>  </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="d-flex align-items-center" style="padding:0.3em;">
                            <p style="text-indent: 2em; text-align: justify;">{{element.definition ? element.definition : "N/A"}}</p>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="refDataSourceCol; sticky: true"></tr>
                <tr mat-row matRipple *matRowDef="let row; columns: refDataSourceCol;"></tr>

            </table>
            <mat-paginator pageSize="15" aria-label="Select page of users"></mat-paginator>
        </div>
    </div>
</div>
<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-ref="ref">
        <button mat-menu-item (click)="btnAction('view',currentRef,ref.id)">View</button>
        <button mat-menu-item (click)="btnAction('edit',currentRef,ref.id)">Update</button>
        <button mat-menu-item (click)="btnAction('delete',currentRef,ref.id )">Delete</button>
    </ng-template>
</mat-menu>