    import { Component, OnInit, Input } from '@angular/core';
    import { AngularFirestore } from '@angular/fire/firestore';
    // import { ActivatedRoute, Router } from '@angular/router';
    // import { AngularFireAuth } from '@angular/fire/auth';
    // import { PdfRenderService } from '../../pdf-render.service';
    // import { format } from 'date-fns';
    // import firebase from "firebase/app";
    // import Swal from 'sweetalert2';

    @Component({
    selector: 'app-regulations-ria-view',
    templateUrl: './regulations-ria-view.component.html',
    styleUrls: ['./regulations-ria-view.component.scss']
    })
    export class RegulationsRiaViewComponent implements OnInit {

    currentProposedRegulation:any;

    constructor(
        // private route: ActivatedRoute,
        private afs: AngularFirestore,
        // private pdf_render: PdfRenderService,
        // private router: Router, 
        // public auth: AngularFireAuth
    ) { }

  ngOnInit(): void {
    // this.loadPia(this.id);
    console.log("ria view")
  }

  // loadPia(id:any){
  //   this.afs.collection('').doc(id).snapshotChanges()
  //   .subscribe(info=>{
  //       let item:any = info.payload.data()
  //       item.id = info.payload.id
  //       this.currentProposedRegulation = item
  //   })
  // }



}
