<div class="content">
    <div class="container-fluid">
        <div class="card shadow p-3">
            <div class="card-body form-container">
                <div class="card-header border-bottom border-4 border-dark">
                    <h3>CRIS</h3>                           
                </div>
                <div class="row" style="padding-top: 2em; ">
                    <div class="row" style="padding-bottom: 2em;">
                        <form class="col">
                            <h3>Regulation Details</h3>
                            <div class="row">
                              <div class="col-6">
                                  <div class="form-group">
                                      <label class="form-input-header fw-bold" for="title">Regulation Title </label>
                                      <input type="text"  class="form-control" [value]="crisData.title" readOnly/>
                                  </div>
                              </div>
                              
                              <div class="col-6">                                
                                  <div class="form-group fw-bold">
                                      <label class="form-input-header" for="subject">Regulation Short Title </label>
                                      <input type="text" class="form-control" [value]="crisData.subject" readOnly/>
                                  </div>
                              </div>
                            </div>
                            
                            <div class="form-inline">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-inline fw-bold">
                                        <label class="form-input-header" for="subject">Regulation Instrument </label>
                                        <input type="text" class="form-control" [value]="crisData.instrument_name"  readOnly />
                                        </div>
                                    </div>
                                
                                    <div class="col-6">
                                        <div class="form-inline fw-bold">
                                            <label class="form-input-header" for="subject">Regulation Number</label>
                                            <input type="text" class="form-control" [value]="crisData.reg_number" readOnly/>
                                        </div>
                                    </div>
                                </div>
                            
                                <div class="form-inline fw-bold">
                                    <label class="form-input-header" for="subject">Issuing Agency </label>
                                    <div class="form-control" style="max-height:200px; min-height: 70px; background-color: #eee;">
                                        <ul>
                                            <ng-container *ngIf="agencyOption == 'single'">
                                                <li>
                                                    {{crisData.agency_item}}
                                                </li>
                                            </ng-container>
                                            <ng-container *ngIf="agencyOption == 'multi'">
                                                <li *ngFor="let row of crisData.agency_item">
                                                    {{row}}
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                            <hr />

                            <div class="form-group fw-bold">
                                <label class="form-input-header">Classification</label>
                                <div class="form-check p-3">
                                <mat-radio-group class="example-radio-group">
                                    <mat-radio-button [checked]="crisData.reg_classification == 'Business'"
                                    value="Business"
                                    class="example-radio-button"
                                    disabled>Business</mat-radio-button>

                                    <mat-radio-button [checked]="crisData.reg_classification == 'Non-Business'"
                                    value="Non-Business"
                                    class="example-radio-button"
                                    disabled>Non-Business</mat-radio-button>
                                </mat-radio-group>
                                </div>
                            </div>

                            
                            <div *ngIf=" crisData.reg_classification ==  'Business'">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-inline fw-bold">
                                            <label class="form-input-header" for="subject">Sector</label>
                                            <input type="text" class="form-control" [value]="crisData.sector_name" readOnly/>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-inline fw-bold">
                                            <label class="form-input-header" for="subject">Stage of Business </label>
                                            <input type="text" class="form-control" [value]="crisData.business_name" readOnly/>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-inline fw-bold">
                                    <label class="form-input-header" for="subject">Division</label>
                                    <input type="text" class="form-control" [value]="crisData.division_name" readOnly/>
                                </div>
                                <div class="form-inline fw-bold">
                                    <label class="form-input-header" for="subject">Case Use</label>
                                    <input type="text" class="form-control" [value]="crisData.case_name" readOnly/>
                                </div>
                            </div>

                          
                            <div *ngIf="crisData.reg_classification == 'Non-Business'">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-inline fw-bold">
                                            <label class="form-input-header" for="subject">Stage of Life </label>
                                            <input type="text" class="form-control" [value]="crisData.sol_name" readonly/>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-inline fw-bold">
                                            <label class="form-input-header" for="subject">Life Event</label>
                                            <input type="text" class="form-control read-only" [value]="crisData.event_name" readonly/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            <div class="form-inline fw-bold">
                                <label class="form-input-header" for="subject">Jurisdiction </label>
                                <input type="text" class="form-control" [value]="crisData.juris_name" readOnly/>
                            </div>

                            <hr />

                            <div class="form-inline fw-bold">
                                <label class="form-input-header" for="subject">Legal Bases </label>
                                <div class="form-control" style="max-height:200px; min-height: 70px; background-color: #eee;">
                                    <ul >
                                        <li *ngFor="let row of crisData.reg_legal">
                                            {{row}}
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="form-inline fw-bold">
                                <label class="form-input-header" for="subject">International Agreements</label>
                                <div class="form-control" style="max-height:200px;  min-height: 70px; background-color: #eee;">
                                    <ul >
                                        <li *ngFor="let row of crisData.reg_intl">
                                            {{row}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="border-bottom border-4 border-dark" >
                    </div>

                    <div class="row" style="margin-top: 2em;">
                        <h3>Consultations</h3>
                        <div class="col main-panel">
                            <ng-container *ngIf="consultationsData && consultationsData.length > 0; noConsultationData">
                                <table mat-table class="table table-hover table-responsive" id="matTable"  [dataSource]="consultationTabledataSource">
                                    <!-- multiTemplateDataRows -->
                                    <ng-container matColumnDef="rowId">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let element">
                                            <div style="padding:0.6">
                                                <div class="row align-items-center p-2">
                                                    <div class="col-md-8 col-lg-7">
                                                        <ul  class="list-unstyled">
                                                            <li>
                                                                <h4><u>{{element.purpose}}</u></h4>
                                                            </li>
                                                            <li>
                                                                Sector/Stage of Life: <strong>{{ crisLifeStatus }}</strong>
                                                            </li>
                                                            <li>
                                                                Duration of Consultation: <strong>{{element.formated_from_date}} to {{element.formated_to_date}} </strong>
                                                            </li>
                                                        </ul>
                                                    </div>
    
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
    
                                    <tr mat-header-row *matHeaderRowDef="consultationTableDisplayCol; sticky: true"></tr>
                                    <tr mat-row matRipple *matRowDef="let row; columns: consultationTableDisplayCol;" ></tr> 
                                    <!-- [ngClass]="expandedCommentCreate == row || expandedCommentView == row? 'row-active' :''" -->
                                    <!-- <tr mat-row *matRowDef="let row; " class="example-detail-row"></tr> -->
                                    <!-- columns: ['expandedDetail'] -->
                                </table>
                            </ng-container>
                            <ng-template #noConsultationData>
                                <div>
                                    <label class="form-input-header" for="subject">No Consultation Data</label>
                                </div>
                            </ng-template>
                        </div>
                    </div>

                    <div class="border-bottom border-4 border-dark" style="margin-top: 2em;">
                    </div>

                    <div class="row" style="margin-top: 2em; width: 100%;">
                        <h3>Uploaded documents</h3>
                        <!-- tab navs -->
                        <div id="nav-tabs">
                            <ul class="nav nav-tabs" id="navs-heads" role="tablist" style="border-bottom: 2px solid #eceef1;">
                                <ng-container *ngFor="let uploadData of this.crisUploads; let i = index; let c = count" >
                                    <li *ngIf="uploadData.downloadUrl" class="nav-item" role="presentation">
                                        <a class="nav-link" [ngClass]="i==0?'active':''" [id]="uploadData.key" data-mdb-toggle="tab" href="#{{uploadData.key}}-tab"
                                            role="tab" aria-controls="{{uploadData.key}}-tab" aria-selected="{{i==0?true:false}}">
                                            {{uploadData.name}}
                                        </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                        <!-- !tab navs -->
                        
                        <!-- tab content -->
                        <div class="tab-content" id="content">
                            <div *ngFor="let uploadData2 of this.crisUploads; let i = index; let c = count"
                                class="tab-pane fade" [ngClass]="i==0?'active show':''" id="{{uploadData2.key}}-tab" role="tabpanel" aria-labelledby="{{uploadData2.key}}">
                                <ng-container *ngIf="uploadData2.downloadUrl">
                                    <ng-container *ngIf="((checkAuthLetterFileType('image/jpeg',uploadData2.contentType) || checkAuthLetterFileType('image/jpg',uploadData2.contentType) || checkAuthLetterFileType('image/png',uploadData2.contentType)))">
                                        <img style="width: 600px;" [src]="uploadData2.downloadUrl">
                                    </ng-container>
                                    <ng-container *ngIf="(checkAuthLetterFileType('application/pdf',uploadData2.contentType))">
                                        <div class="d-flex justify-content-center flex-column relative button-hover w-100">
                                            <div class="d-flex justify-content-center">
                                                <b><h4>{{uploadData2.name}}</h4></b>
                                            </div>
                                            <div class="d-flex align-items-center flex-column">
                                                <div class="d-flex justify-content-center"><p>{{uploadData2.fileBasePage}} / {{uploadData2.fileBaseTotalPage ? uploadData2.fileBaseTotalPage : "1"}}</p></div>
                                                <pdf-viewer style="max-width: 600px;"
                                                    (after-load-complete)="afterLoadComplete($event,i)"
                                                    [page]="uploadData2.fileBasePage"
                                                    [show-all]="false" 
                                                    [original-size]=false 
                                                    [src]="uploadData2.downloadUrl">
                                                </pdf-viewer>
                                                <div class="d-flex justify-content-center"><p>{{uploadData2.fileBasePage}} / {{uploadData2.fileBaseTotalPage ? uploadData2.fileBaseTotalPage : "1"}}</p></div>
                                            </div>
                                            <button (click)="pageShift('back',i)" type="button" class="absolute left-button btn-dark">
                                                <
                                            </button>
                                            <button (click)="pageShift('next',i)" type="button" class="absolute right-button btn-dark">
                                                >
                                            </button>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                        <!-- !tab content -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>