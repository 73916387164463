import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';

export const MY_FORMATS = {
    parse: {
      dateInput: 'LL',
    },
    display: {
      dateInput: 'YYYY-MM-DD',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY',
    },
};

@Component({
    selector: 'app-regulation-view-proposed-pre-posting',
    templateUrl: './regulation-view-proposed-pre-posting.component.html',
    styleUrls: ['./regulation-view-proposed-pre-posting.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
})
export class RegulationViewProposedPrePostingComponent implements OnInit {

    @Output() PostRegulation = new EventEmitter<object>();
    @Output() ReturnDRIS = new EventEmitter<object>();
    @Output() ForRIA = new EventEmitter<object>();

    @Input() actionButtonAllowed: boolean = false;

    @Input() objectDataInitial:any;
    @Input() objectDataDRIS:any;
    
    regulationsData:any = {};

    checkPrePost = {
        doc_date:[
            {type:'required', message:'Issue Date is required'},
        ],
        date_effective:[
            {type:'required', message:'Effectivity Date is required'},
        ],
        date_published_gazette:[
            // {type:'required', message:'Effectivity Date is required'},
        ],
        date_published_onar :[
            // {type:'required', message:'Effectivity Date is required'},
        ],
    }

    prePostForm = new FormGroup({
        doc_date: new FormControl(moment(new Date()),[Validators.required]),
        date_effective: new FormControl(moment(new Date()),[Validators.required]),
        date_published_gazette: new FormControl(''),
        date_published_onar: new FormControl(''),
    })

    constructor(
    ) {}

    ngOnInit(): void {
        if(this.objectDataDRIS){
            this.regulationsData = this.objectDataDRIS;
        }
        else if (this.objectDataInitial) {
            this.regulationsData = this.objectDataInitial;
        }
    }

    submit(){
        this.validateForm()
        if(this.prePostForm.valid){
            let formValue:any = this.prePostForm.value
            try{
                this.PostRegulation.emit({
                    doc_date: formValue.doc_date.format('YYYY-MM-DD'),
                    date_effective: formValue.date_effective.format('YYYY-MM-DD'),
                    date_published_gazette: formValue.date_published_gazette ? formValue.date_published_gazette.format('YYYY-MM-DD') : '',
                    date_published_onar: formValue.date_published_onar ? formValue.date_published_onar.format('YYYY-MM-DD') : '',
                })
            }catch(error){
                console.error('Error: ',error)
            }
        }else{
            console.warn('Form invalid')
        }
    }

    forRIA(){
        try{
            this.ForRIA.emit({});
        }catch(error){
            console.error('Error: ',error)
        }
    }
    
    returnDRIS(){
        try{
            this.ReturnDRIS.emit({});
        }catch(error){
            console.error('Error: ',error)
        }
    }

    refreshDate(control:any){
        if(control == 'effective'){
            this.prePostForm.patchValue({date_effective:moment(new Date())})
        }else if(control == 'issue'){
            this.prePostForm.patchValue({doc_date:moment(new Date())})
        }else if(control == 'gazette'){
            this.prePostForm.patchValue({date_published_gazette:''})
        }else if(control == 'onar'){
            this.prePostForm.patchValue({date_published_onar:''})
        }
    }

    hasError(controlName:any, validType:any){
        return ((this.prePostForm.get(controlName) as FormControl).hasError(validType)
            && ((this.prePostForm.get(controlName) as FormControl).dirty
            || (this.prePostForm.get(controlName) as FormControl).touched)
        )
    }

    validateForm(){
        if(!this.prePostForm.valid){
            this.prePostForm.markAllAsTouched();
            for(const control in this.prePostForm.controls){
                if(this.prePostForm.controls.hasOwnProperty(control)){
                    this.prePostForm.controls[control].markAllAsTouched();
                    this.prePostForm.controls[control].markAsDirty();
                }
            }
        }
    }
}