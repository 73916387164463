<!--
    <div class="row mb-2">
    <div *ngIf="isAgencyMember || isAdmin" class="col-2 float-right">
     <button mat-flat-button color="primary"  (click)="addOffice()">Add Office / Division</button>
    </div>
 </div>
 <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger1"
     class="table table-striped table-responsive">
     <thead>
         <tr>
             <th class="table-header">Office / Division Name</th>
             <th class="table-header">Level</th>
             <th *ngIf="isAdmin" class="table-header">Status</th>
             <th *ngIf="isAgencyMember || isAdmin" class="table-header">Action</th>
         </tr>
     </thead>
     <tbody>
 
         <tr *ngFor="let office of officeCollection;let i = index">
             
             <td>
                {{ office.name }}
             </td>
             <td>
                {{ office.level }}
             </td>

             <td *ngIf="isAdmin">
                {{ office.Status }}
            </td>
 
             <td *ngIf="isAgencyMember || isAdmin">
                 <div [disabled]="loading" class="col align-right">
                     <button (click)="UpdateOffice(office)" mat-flat-button color="primary" class="mx-1">
                         Edit
                     </button>
                     <button mat-stroked-button (click)="removeOffice(office,i)" class="btn btn-warning">
                         <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>  Remove
                     </button>
                 </div>
             </td>
         </tr>
     </tbody>
 </table>
-->

<app-artemis-office-hierarchy-control [isLowerLevel]="false" [agency_id]="agency_id" [leadEncoder]="isAgencyMember"></app-artemis-office-hierarchy-control>