import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quick-links-artemis',
  templateUrl: './quick-links-artemis.component.html',
  styleUrls: ['./quick-links-artemis.component.scss']
})
export class QuickLinksArtemisComponent implements OnInit{
  link_toggle_status = "Show"
  show_links = false

  constructor() { }

  ngOnInit(): void {
  }

  toggle_link(){
    if(this.show_links){
      this.show_links = false;
      this.link_toggle_status = "Show"
    }
    else{
      this.show_links = true;
      this.link_toggle_status = "Hide"
    }
    
  }

}