<app-artemis-navigation-header [currentPage]="'CharterMain'"></app-artemis-navigation-header>

<div class="content">
    <div class="container-fluid p-3">
        <div class="card" *ngIf="isPhase1">
            <div class="card-head">
                <h3>Upload Citizen Charter</h3>
            </div>
            <div class="card-body form-container">
                <form [formGroup]="phase1form">
                    <div class="form-group fw-bold">
                        <label class="form-input-header" for="doc_date">Issue Date *</label>
                        <input type="text" placeholder="mm/dd/yyyy" class="form-control" formControlName="date" />
                    </div>

                    <div class="form-group fw-bold">

                        <label class="form-input-header" for="filename">Filename *</label>
                        <input type="file" class="form-control" (change)="saveFileInformation($event)" />
                    </div>
                    <div class="form-group fw-bold">
                        <label class="form-input-header" for="organization_grouping_01">Organizational Group *</label>
                        <input type="text" class="form-control" formControlName="organization_grouping_01" />
                    </div>
                    <div class="form-group fw-bold">
                        <label class="form-input-header" for="organization_level_00">Department *</label>
                        <input type="text" class="form-control" formControlName="organization_level_00" />
                    </div>
                    <div class="form-group fw-bold">
                        <label class="form-input-header" for="organization_level_01">Agency *</label>
                        <input type="text" class="form-control" formControlName="organization_level_01" />
                    </div>
                    <div class="form-group">
                        <label class="form-input-header" for="organization_level_02">Operating Unit</label>
                        <input type="text" class="form-control" formControlName="organization_level_02" />
                    </div>
                    <div class="form-group">
                        <label class="form-input-header" for="organization_level_03">Lower Operating Unit</label>
                        <input type="text" class="form-control" formControlName="organization_level_03" />
                    </div>
                    <div class="form-group" hidden>
                        <label class="form-input-header" for="psgc_location">psgc_location</label>
                        <input type="text" class="form-control" formControlName="psgc_location" />
                    </div>
                    <div class="form-group" hidden>
                        <label class="form-input-header" for="uacs_location_code">uacs_location_code</label>
                        <input type="text" class="form-control" formControlName="uacs_location_code" />
                    </div>
                    <div class="form-group" hidden>
                        <label class="form-input-header" for="uacs_org_description">uacs_org_description</label>
                        <input type="text" class="form-control" formControlName="uacs_org_description" />
                    </div>
                    <div class="form-group" hidden>
                        <label class="form-input-header" for="uacs_organization_code">uacs_organization_code</label>
                        <input type="text" class="form-control" formControlName="uacs_organization_code" />
                    </div>
                    <div class="form-group" hidden>
                        <label class="form-input-header" for="agency_initials">agency_initials</label>
                        <input type="text" class="form-control" formControlName="agency_initials" />
                    </div>
                    <div class="form-group" >
                        <label class="form-input-header" for="consolidated">Consolidated?</label>
                        <input type="text" class="form-control" formControlName="consolidated" />
                    </div>
                    <div class="form-group">
                        <p class="fst-italic" *ngIf="phase1form.invalid">* Required fields - must be filled in order to Submit the form</p>
                    </div>

                    
                    <button class="btn btn btn-primary m-3 float-end" (click)="saveAndUpload()" id="bt " [disabled]="phase1form.invalid || loading">
                <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span> Submit</button>
                </form>
            </div>
        </div>
    </div>
</div>