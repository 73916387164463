<div class="card">
  <h5 class="card-header">Feedback Inbox</h5>
  <div class="card-body p-0">
    <div class="table-responsive" style="max-height: 500px;">
      <table class="table table-striped">
        <thead class="text-muted">
          <tr>
            <th class="table-header">Application</th>
            <th class="table-header">Feature Accessed</th>
            <th class="table-header">Discover</th>
            <th class="table-header">Date Generated</th>
            <th class="table-header">Action</th>
          </tr>
        </thead>
        <tbody class="card-text" *ngFor="let list of feedbackList; let i = index">
          <tr>
            <th scope="row">{{ list.data.application }}</th>
            <td>{{ list.data.feature }}</td>
            <td>{{ list.data.discover }}</td>
            <td>{{ list.data.created_at }}</td>
            <td>
              <button type="button" class="btn btn-info" (click)="sampleModal.show(); openModal(list.data)" >View</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Modal -->
  <div style="overflow-y: auto" class="modal fade" mdbModal #sampleModal="mdbModal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Review Feedback</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="sampleModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <dl class="row">
            <div class="row pb-2 border-bottom">
              <dt class="col-sm-3">Application</dt>
              <dd class="col-sm-9">{{ application ? application : 'N/A' }}</dd>
            
              <dt class="col-sm-3">Feature Accessed</dt>
              <dd class="col-sm-9">{{ feature ? feature : 'N/A' }}</dd>

              <dt class="col-sm-4">How did you discover PBRIS?</dt>
              <dd class="col-sm-8">{{ otherDiscover ? otherDiscover : 'N/A' }}</dd>
            </div>
            
            <!-- <dt class="col-sm-3 text-truncate">Improvements</dt>
            <dd class="col-sm-9">{{ improve ? improve : 'N/A' }}</dd>
          
            <dt class="col-sm-3 text-truncate">Elaborate</dt>
            <dd class="col-sm-9">{{ elaborate ? elaborate : 'N/A' }}</dd> -->

            <!-- <dt class="col-sm-3 text-truncate">Others</dt>
            <dd class="col-sm-9">{{ others ? others : 'N/A' }}</dd> -->

            <div class="row py-2 border-bottom">
              <dt class="col-sm-3">Visit the site again</dt>
              <dd class="col-sm-9">{{ visit ? visit : 'N/A' }}</dd>

              <dt class="col-sm-3">Refer to a friend</dt>
              <dd class="col-sm-9">{{ refer ? refer : 'N/A' }}</dd>
            </div>

            <div class="row py-2 border-bottom">
              <dt class="col-sm-3">Overall experience</dt>
              <dd class="col-sm-9">{{ overall ? overall : 'N/A' }}</dd>

              <dt class="col-sm-5">Experience was poor/unsatisfactory</dt>
              <dd class="col-sm-7">{{ experience ? experience : 'N/A' }}</dd>
              <dt class="col-sm-3">Other</dt>
              <dd class="col-sm-9">{{ others ? others : 'N/A' }}</dd>
            </div>

            <div class="row pt-2">
              <dt class="col-sm-3">Could be further improved by:</dt>
              <dd class="col-sm-9">{{ enhance ? enhance : 'N/A' }}</dd>
            </div>
          
          </dl>
        </div> 
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="sampleModal.hide()">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>


