<app-artemis-navigation-header
  [currentPage]="'CharterMain'"
></app-artemis-navigation-header>

<div class="contianer my-3">
  <form [formGroup]="phase1form">
    <div class="row justify-content-center">
      <div class="col-10">
        <div class="d-flex">
          <h2>
            <strong>Submit a comment</strong> (Guide to Commenting)
          </h2>
        </div>
        <hr />
        <div class="form-group">
          <div class="my-3">
            <label for="comment" class="form-label mb-2 font-weight-bold">
              Write your comment
            </label>
            <textarea class="form-control col-12 border border-top-0 border-start-0 border-end-0 border-bottom-1" formControlName="comment" id="comment" rows="3" placeholder="Comments"></textarea>
          </div>
        </div>
        <div class="form-group">
          <div class="mb-3">
            <label for="document" class="form-label mb-2 font-weight-bold">
              Upload Relevant and/or Supporting Documents
            </label>
            <p class="fst-italic"> 
              Valid the types include bmp. docx, gif, jpg, jpeg, pdf, png, pptx <br>
              You can attach up to 5MB, but total of size should not exceed 20MB.</p>
            <div class="my-3">
              <input type="file" class="form-control" formControlName="document" (change)="documentOnChange($event)" id="document" name="document" multiple>
            </div>
            <div class="my-3" *ngIf="temp_file">
              <div class="row justify-content-center" *ngFor="let list of temp_file; let i = index">
                <div class="row col-lg-10 m-2">
                  <div class="col-lg-7 text-break">{{list.name}}</div>
                  <div class="col-lg-3">
                    <button class="btn btn-danger" (click)="deleteFile(list.name)">
                      <!-- <i class="fa fa-times" aria-hidden="true" style="font-size: large"></i> -->
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="my-3 text-center">
              <input type="text" class="form-control" formControlName="url" placeholder="Or add a URL">
            </div>
          </div>
        </div>
        <div class="form-group">
          <label><strong>Submitting as</strong></label>
          <div class="row justify-content-center mt-2">
            <div class="col-lg-10 col-sm-12">
              <div class="my-2">
                <input type="radio" class="btn-check" formControlName="user" id="individual" name="user" value="Individual" />
                <label for="individual" title="Individual" class="btn btn-outline-dark py-4 col-lg-12">
                  <div class="row align-items-center col-lg-12">
                    <div class="col-lg-2">
                      <i class="fa fa-user my-auto" aria-hidden="true" style="font-size: xxx-large;"></i>
                    </div>
                    <div class="col-lg-3">
                      <strong class="fs-4 my-auto text-wrap">Individual</strong>
                    </div>
                    <div class="col-lg-7">
                      <h6 class="my-auto" style="text-transform: none;">
                        submitting a comment as an individual, without any affiliation to any organization or business</h6>
                    </div>
                  </div>
                </label>
              </div>
              <div class="my-2">
                <input type="radio" class="btn-check" formControlName="user" id="organization" name="user" value="Organization" />
                <label for="organization" title="Organization" class="btn btn-outline-dark py-4 col-lg-12">
                  <div class="row align-items-center col-lg-12">
                    <div class="col-lg-2">
                      <i class="fa fa-users col-sm-2 my-auto" aria-hidden="true" style="font-size: xxx-large"></i>
                    </div>
                    <div class="col-lg-3">
                      <strong class="fs-4 my-auto text-wrap">Organization</strong>
                    </div>
                    <div class="col-lg-7">
                      <h6 class="my-auto" style="text-transform: none;">
                        submitting a comment as a representative of an academic institution, government body, or business entity</h6>
                    </div>
                  </div>
                  </label>
              </div>
              <div class="my-2">
                <input type="radio" class="btn-check" formControlName="user" id="anonymity" name="user" value="Anonymity" />
                <label for="anonymity" title="Anonymity" class="btn btn-outline-dark py-4 col-lg-12">
                  <div class="row align-items-center col-lg-12">
                    <div class="col-lg-2">
                      <i class="fa fa-user-secret col-sm-2 my-auto" aria-hidden="true" style="font-size: xxx-large"></i>
                    </div>
                    <div class="col-lg-3">
                      <strong class="fs-4 my-auto text-wrap">Anonymity</strong>
                    </div>
                    <div class="col-lg-7">
                      <h6 class="my-auto" style="text-transform: none;">
                        submitting a comment with the protection of hidden identity</h6>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="col my-2" style="text-align: -webkit-center;">
            <re-captcha 
              formControlName="captcha"
              (resolved)="resolved($event)" 
              (error)="onError($event)" 
              errorMode="handled" 
              [siteKey]="recaptchaV2SiteKey"
            ></re-captcha>
          </div>
        </div>
        <div class="form-group text-center">
          <button 
            class="btn btn-primary col-12 col-md-4 p-3 my-3" 
            style="letter-spacing: 2px;" 
            (click)="sampleModal.show();" 
            [disabled]="!this.phase1form.value.captcha"
          >
            <strong>Proceed</strong>  
          </button>
        </div>
      </div>
    </div>
  </form>

  <!-- Modal -->
  <div style="overflow-y: auto" class="modal fade" mdbModal #sampleModal="mdbModal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content bg-dark opacity-25 text-white">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">This is how your comment will appear</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="sampleModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group my-2">
            <label for="comment2" class=""><strong>Your comment :</strong></label>
            <p id="comment2" class="m-2 fs-6">{{phase1form.value.comment ? phase1form.value.comment : "N/A"}}</p>
          </div>
          <div class="form-group my-2">
            <ul class="list-unstyled">
              <li><label class=""><strong>Attachments</strong></label>
                <ul class="list-unstyled m-2">
                  <li>
                    <label for="url" class=""><strong>Url :</strong></label> {{phase1form.value.url ? phase1form.value.url : "N/A"}}
                  </li>
                  <li>
                    <label for="attachment" class=""><strong>File :</strong></label>
                    <div *ngIf="temp_file" >
                      <ul class="list-unstyled" *ngFor="let list of temp_file; let i = index">
                        <li>
                          <div id="attachment" class="row m-2">
                            <div class="col text-break">{{list.name}}</div>
                            <div class="col">{{list.type}}</div>
                            <div class="col">{{list.size}} kb/s</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="form-group mt-3 d-flex">
            <div class="d-flex ml-5">
              <label for="submit_as" class=""><strong>Submitting as :</strong></label>
              <p id="submit_as" class="mx-2">{{phase1form.value.user ? phase1form.value.user : "N/A"}}</p>
            </div>
          </div>
          <hr />
          <div class="form-group">
            <div class="row">
              <div class="col-3 text-center">
                <i class="fa fa-exclamation-triangle fa-5x text-warning"></i>
              </div>
              <div class="col-9">
                <p>The Anti-Red Tape Authority (ARTA) is guided by the principles of transparency, respect and proactive protection of personal information of our employees, co-workers in government, service providers, clients and other stakeholders in accordance with the requirements of RA 10173 or Data Privacy Act of 2012 and its Implementing Rules and Regulations including the Circulars and Advisories issued by the National Privacy Commission (NPC).

                  You are afforded certain rights and protection according to the Republic Act 10173 also known as the Data Privacy Act of 2012 and that you may email the ARTA Data Protection Officer at artadpo@arta.gov.ph for more information. The complete ARTA Privacy Policy is accessible at: https://arta.gov.ph/about/data-privacy-policy
                  
                  By providing the following data, I hereby consent to the processing of my personal information for documentation purposes</p>
                </div>
            </div>
          </div>
        </div> 
        <div class="modal-footer">
          <button type="button" class="btn btn-light" style="letter-spacing: 2px;" data-dismiss="modal" (click)="sampleModal.hide()"><strong>Cancel</strong></button>
          <button type="button" class="btn btn-primary" style="letter-spacing: 2px;" (click)="onSubmit(); sampleModal.hide()"><strong>Submit</strong></button>
        </div>
      </div>
    </div>
  </div>
</div>
