<app-wel-navbar></app-wel-navbar>
<div class="content">
    <div class="container-fluid p-4">
        <h2>Uploaded Files by</h2>
        <h4></h4>
        <!-- Sectors -->
        <div class="row m-3">
            <div class="card">
                <div class="card-header">
                    <div class="col">
                        <h3 class="card-title">Government Issued ID</h3>

                    </div>
                </div>
                <div class="card-body">
                    <a href="{{gov_url}}" class="btn btn-dark" [hidden]='!gov_url'>Download File</a>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <div class="col">
                        <h3 class="card-title">Company/Agency ID</h3>

                    </div>
                </div>
                <div class="card-body">
                    <a href="{{com_url}}" class="btn btn-dark" [hidden]='!com_url'>Download File</a>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <div class="col">
                        <h3 class="card-title">Authorization Letter</h3>

                    </div>
                </div>
                <div class="card-body">
                    <a href="{{auth_letter_url}}" class="btn btn-dark" [hidden]='!auth_letter_url'>Download File</a>
                </div>
            </div>

        </div>
    </div>
</div>