import { Component, OnChanges, Input } from '@angular/core';

@Component({
  selector: 'app-view-charter-public-details',
  templateUrl: './view-charter-public-details.component.html',
  styleUrls: ['./view-charter-public-details.component.scss']
})
export class ViewCharterPublicDetailsComponent implements OnChanges {
  isLoaded:boolean = false

  pinciple_list:any =[
    {name: 'Mission', value:'mission'},
    {name: 'Vision', value:'vision'},
    {name: 'Mandate', value:'mandate'},
    {name: 'Service Pledges', value:'pledges'},
  ]

  contact_list:any =[
    {icon: 'fa-envelope', value:'email'},
    {icon: 'fa-mobile-alt', value:'phoneNumber'},
    {icon: 'fa-phone-alt', value:'telephoneNumber'},
  ]

  @Input() agency_details:any;
  @Input() charter_list:any;

  constructor() { }

  // ngOnInit(): void {

  //   console.log('on child: ', this.agency_details )
  // }

  ngOnChanges() {
    if(this.agency_details && this.charter_list){
      this.isLoaded = true;   
      //console.log('charter_list: ', this.charter_list )
     // console.log('agency_details: ', this.agency_details )
    }
  }

}
