import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pbris-regulatory-policy',
  templateUrl: './pbris-regulatory-policy.component.html',
  styleUrls: ['./pbris-regulatory-policy.component.scss']
})
export class PbrisRegulatoryPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
