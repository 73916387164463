import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-artemis-view-fees',
  templateUrl: './artemis-view-fees.component.html',
  styleUrls: ['./artemis-view-fees.component.scss']
})
export class ArtemisViewFeesComponent implements OnInit {

  fees : any = {
    fee_type : '',
    amount : ''
  }

  constructor(
    public dialogRef: MatDialogRef<ArtemisViewFeesComponent>,
    private store: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) {

    this.fees = data.fees
    
   }

  ngOnInit(): void {
  }

}
