
<div *ngIf="!viewAddService">
  <div class="container-fluid p-0 m-0">
    <div class="row p-2" *ngIf="ifCountsAreAvailable">
      <div class="col-sm-2 align-items-middle header-title p-2 theme-agency" >
        <h3 class="pt-2">
              QUICK
              <br />
              STATS
            </h3>
      </div>
      <div class="col-sm-10 bg-gray">
        <div class="container p-2 m-0">
          <div class="list-container p-0 m-0">
            <mat-card class="blocks rounded-5" *ngIf="countDraft != 0" >
              <mat-card-content class="h-100 d-flex flex-row justify-content-center align-items-center pb-0" >
                <h6 (click)="filteredOffice('All','Draft')" class="align-self-start flex-fill me-auto">Drafted</h6> <h3><span class="badge badge-info rounded-7">{{ countDraft }}</span></h3>
              </mat-card-content>
            </mat-card>
             <mat-card class="blocks rounded-5"*ngIf="countVerification != 0">
              <mat-card-content class="h-100 d-flex flex-row justify-content-center align-items-center pb-0" >
                <h6 (click)="filteredOffice('All','For Verification')" class=" align-self-start flex-fill me-auto">For Verification</h6><h3><span class="badge badge-info rounded-7">{{ countVerification }}</span></h3>
              </mat-card-content> 
            </mat-card>
            <mat-card class="blocks rounded-5"*ngIf="countVerified != 0">
              <mat-card-content class="h-100 d-flex flex-row justify-content-center align-items-center pb-0" >
                <h6 (click)="filteredOffice('All','Verified')"  class=" align-self-start flex-fill me-auto">Verified</h6><h3><span class="badge badge-info rounded-7">{{ countVerified }}</span></h3>
              </mat-card-content> 
            </mat-card>
             <mat-card class="blocks rounded-5"*ngIf="countRevision != 0">
              <mat-card-content class="h-100 d-flex flex-row justify-content-center align-items-center pb-0" >
                <h6 (click)="filteredOffice('All','For Revision')" class=" align-self-start flex-fill me-auto">For Revision</h6><h3><span class="badge badge-info rounded-7">{{ countRevision }}</span></h3>
              </mat-card-content> 
            </mat-card>
             <mat-card class="blocks rounded-5"*ngIf="countAgencyReview!= 0">
              <mat-card-content class="h-100 d-flex flex-row justify-content-center align-items-center pb-0" >
                <h6 class=" align-self-start flex-fill me-auto">For Agency Review</h6><h3><span class="badge badge-info rounded-7">{{ countAgencyReview }}</span></h3>
              </mat-card-content> 
            </mat-card>
             <mat-card class="blocks rounded-5"*ngIf="countConsolidation != 0">
              <mat-card-content class="h-100 d-flex flex-row justify-content-center align-items-center pb-0" >
                <h6 (click)="filteredOffice('All','For Modification')" class=" align-self-start flex-fill me-auto">Service with ARTA Comments</h6><h3><span class="badge badge-info rounded-7">{{ countConsolidation }}</span></h3>
              </mat-card-content> 
            </mat-card>
             <mat-card class="blocks rounded-5"*ngIf="countApproval != 0">
              <mat-card-content class="h-100 d-flex flex-row justify-content-center align-items-center pb-0" >
                <h6 (click)="filteredOffice('All','Resubmitted','FOR APPROVAL')" class=" align-self-start flex-fill me-auto">Resubmitted</h6><h3><span class="badge badge-info rounded-7">{{ countApproval }}</span></h3>
              </mat-card-content> 
            </mat-card>
             <mat-card class="blocks rounded-5"*ngIf="countARTAReview != 0">
              <mat-card-content class="h-100 d-flex flex-row justify-content-center align-items-center pb-0" >
                <h6 class=" align-self-start flex-fill me-auto">For ARTA Review</h6><h3><span class="badge badge-info rounded-7">{{ countARTAReview }}</span></h3>
              </mat-card-content> 
            </mat-card>
             <mat-card class="blocks rounded-5"*ngIf="countModification != 0">
              <mat-card-content class="h-100 d-flex flex-row justify-content-center align-items-center pb-0" >
                <h6 class=" align-self-start flex-fill me-auto">For Modification</h6><h3><span class="badge badge-info rounded-7">{{ countModification }}</span></h3>
            </mat-card-content> 
            </mat-card>
          </div> 
        </div>
      </div>
    </div>
    
  </div>
  
  <!---new cards end-->
  <h5 *ngIf="account_role != 'undefined' && account_role != 'Basic'">
      {{account_role}}
  </h5>
  <mat-card>
    <mat-card-content >
      <div class="row mb-3" >
        <div class="col-sm-4">
          <h4 class="text-primary"><span><i class="fas fa-clipboard-list"></i></span><strong>  Worklists</strong></h4>
         <!--  <button (click)="testEmail()">TEst Email</button> -->
        </div>
        <div class="col-sm-8 d-flex justify-content-end mt-2" *ngIf="!loading" >
          <!-- For pre-submission of charter -->
          <div class="m-0" *ngIf="!ongoingCharter">
            <!-- If services were certified by the Encoder -->
            <div *ngIf="servicesCertified && countIncompleteService == 0">
              <div
                *ngIf="account_type == enum_account_subtype.AGENCY_VER &&
                  submitEnabled && countIncompleteService == 0 && account_role == user_account_role.Lead_Verifier">
                <h5 class="note note-info mb-3">
                  All services have complete details,are certified, and are verified.
                </h5>
                <button mat-stroked-button
                  class="btn btn-warning "  
                  (click)="SubmittoAgencyHeadDialog()" >
                  Submit to Agency Head
                </button>
              </div>
            </div>
  
            <!-- If services were *not yet* certified by the Encoder -->
            <!-- Only allow Submit Complete Services if there are any worklists eligible for the certification -->
            <div *ngIf="!servicesCertified">
              <button
                [disabled]="countDraft > 0"
                mat-stroked-button
                class="btn btn-warning "
                *ngIf="account_type == enum_account_subtype.AGENCY_ENC && !activeCertification  && account_role == user_account_role.Lead_Encoder && this.results.length > 0 "
                (click)="createCertificationDialog()">
                <mat-icon>assignment_turned_in</mat-icon> All Services Submitted
              </button>
  
              <button
                [disabled]="countDraft > 0"
                mat-stroked-button
                class="btn btn-warning "
                *ngIf="account_type == enum_account_subtype.AGENCY_ENC && activeCertification  && account_role == user_account_role.Lead_Encoder"
                (click)="appendCertificationDialog()">
                Append Certification
              </button>
              <div class="text-danger" *ngIf="countDraft > 0 ">Submit/Complete all draft services</div>
              <h5 class="note note-info mb-3" *ngIf="account_type == enum_account_subtype.AGENCY_VER && countIncompleteService > 0">
              Services must be  complete details, certified, and verified before submitting to the Agency Head.
              </h5>
            </div>
          </div>
          <!-- When charter was already submitted but was returned -->
          <div class="m-0" *ngIf="ongoingCharter">
            <button
              mat-stroked-button
              class="btn btn-warning"
              *ngIf="account_type == enum_account_subtype.AGENCY_VER && submitEnabled && account_role == user_account_role.Lead_Verifier"
              (click)="ResubmittoAgencyHeadDialog()">
              Re-submit to Agency Head
            </button>
  
            <h5 class="note note-info mb-3"
              *ngIf="(account_type != enum_account_subtype.AGENCY_VER || !submitEnabled) && activeCharter?.status === enum_active_charter_status.FOR_APPROVAL">
              A citizen's charter is currently ongoing review.
            </h5>
  
            <h5 class="note note-info mb-3"
              *ngIf="
                (account_type != enum_account_subtype.AGENCY_VER || !submitEnabled) 
                  && (
                    activeCharter?.status === enum_active_charter_status.FOR_ARTA_REVIEW ||
                    activeCharter?.status === enum_active_charter_status.ONGOING_ARTA_REVIEW ||
                    activeCharter?.status === enum_active_charter_status.FOR_DC_APPROVAL ||
                    activeCharter?.status === enum_active_charter_status.FOR_DIR_APPROVAL)">
              A Citizen's Charter has been submitted to ARTA and is ongoing validation.
            </h5>
  
            <h5 class="note note-info mb-3"
              *ngIf="activeCharter?.status === enum_active_charter_status.FOR_REVISION">
              A citizen's charter is for revision.
            </h5>
          </div>     
        </div>
      </div>
      <hr>
      <!--(valueChange)="officeValueChanged($event)"-->
      <div class="row mb-3" >
        <div class="col-sm-2">
          <mat-form-field class="w-100" style="font-size: 12px;" appearance="outline">
            <mat-label>Process Owner Filter</mat-label>
            <mat-select [formControl]="office_list"
               >
              <input placeholder="Filter..." class="form-control w-100 px-2" (keyup)="onkey($event)" type="text">
              <mat-option
              (click)="officeValueChanged('All')"
              value="All"
              >
              All
              </mat-option>
              <mat-option
              (click)="officeValueChanged(office)"
                *ngFor="let office of allOffices"
                [value]="office"
                >{{ office }}</mat-option
              >
            </mat-select>
          
          </mat-form-field>
        </div>
        <div class="col-sm-6" >
          <mat-form-field class="w-100" style="font-size: 12px;" appearance="outline">
            <mat-label>Search here...</mat-label>
            <input (keyup)="search($event)" matInput placeholder="Placeholder">
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>

          <!---
          <div class="input-group rounded">
            <input style="font-size: 12px;" class="form-control " placeholder="Search service..."  id="search" name="search" (keyup)="search($event)" />
            <span class="input-group-text border-0" id="search-addon">
              <i class="fas fa-search"></i>
            </span>
          </div>-->
        </div>
        <!--[routerLink]="['/artemis/charter/newcharter']"-->
        <!--disabled add new service if there is Ongoing Validation-->
        <div  class="col-sm-4 justify-content-end mt-2">
            <button mat-button
            *ngIf="account_type == enum_account_subtype.AGENCY_ENC"
            [disabled]="servicesCertified && !submitEnabled || ((
              activeCharter?.status === enum_active_charter_status.FOR_ARTA_REVIEW ||
              activeCharter?.status === enum_active_charter_status.ONGOING_ARTA_REVIEW ||
              activeCharter?.status === enum_active_charter_status.FOR_DC_APPROVAL ||
              activeCharter?.status === enum_active_charter_status.FOR_DIR_APPROVAL ||
              activeCharter?.status == enum_active_charter_status.FOR_APPROVAL))"
            [routerLink]="['serviceeditor','']"
            class=" mx-1 bg-gray"
            
          >
          <span><i class="fas fa-plus-circle"></i>  Add New Service</span>
          </button>
          <button mat-button
            *ngIf="account_type == enum_account_subtype.AGENCY_ENC"
            [disabled]="countDraft == 0 || ((
              activeCharter?.status === enum_active_charter_status.FOR_ARTA_REVIEW ||
              activeCharter?.status === enum_active_charter_status.ONGOING_ARTA_REVIEW ||
              activeCharter?.status === enum_active_charter_status.FOR_DC_APPROVAL ||
              activeCharter?.status === enum_active_charter_status.FOR_DIR_APPROVAL ||
              activeCharter?.status == enum_active_charter_status.FOR_APPROVAL))"
            class="bg-gray"
            (click)="submitAllworklistservice()"
            
          >
          <i class="fas fa-check-circle"></i> <span> Submit All Unverified Services</span>
            
          </button>

        </div>
      </div>
              <!--  style="max-height: 500px" -->
      <div class="pb-3 table-responsive">
        <table mat-table
          class="table table-hover table-striped  table-responsive"
          id="matTable"
          [dataSource]="worklistDataSource"
          matSort
          #sort
          (matSortChange)="sortChange($event)">
          <ng-container matColumnDef="dateGenerated">
            <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Date Generated</th>
            <td class="p-2" mat-cell *matCellDef="let worklist" >
                    {{
                      worklist.dateGenerated.toDate()
                        | date: "MMMM dd, yyyy hh:mm:ss"
                    }}
            </td>
          </ng-container>       
          <ng-container matColumnDef="process_owner">
            <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Process Owner</th>
            <td class="p-2" mat-cell *matCellDef="let worklist">
                <span style="font-size: .6rem;">{{ worklist?.process_owner || 'Undefined' }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="fromOfficer">
            <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Officer</th>
            <td class="p-2" mat-cell *matCellDef="let worklist">
                {{ worklist.fromOfficer }}
            </td>
          </ng-container>
          <ng-container matColumnDef="category">
            <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Classification</th>
            <td class="p-2" mat-cell *matCellDef="let worklist">
                {{ worklist?.category }}
            </td>
          </ng-container>
          <ng-container matColumnDef="documentTitle">
            <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Service</th>
            <td class="text-bold" mat-cell *matCellDef="let worklist">
              <span >{{ worklist.documentTitle }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th  class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td  *matCellDef="let worklist">
       
              <div class="align-items-center">
                <div [ngSwitch]="worklist.status" >
                  <div *ngSwitchCase="'VERIFIED'">
  
                    <span class="badge  bg-primary ">{{worklist.status}}</span>
  
                  </div>
                  <div *ngSwitchCase="'APPROVED'">
  
                    <span class="badge  bg-primary ">{{worklist.status}}</span>
  
                  </div>
                  <div *ngSwitchCase="'DRAFT'">
                    <span class="badge  bg-black ">{{worklist.status}}</span>
                  </div>
                  <div *ngSwitchCase="'FOR REVISION'">
                    <span class="badge  bg-warning ">{{worklist.status}}</span>
                  </div>
                  <div *ngSwitchDefault>
                        
                    <span class="badge  bg-danger ">{{worklist.status}}</span>
                  </div>
  
                </div>
              </div>
              
            </td>
          </ng-container>   
          <ng-container matColumnDef="effectivity">
            <th  class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Effectivity</th>
            <td  *matCellDef="let worklist">
  
              <div class="align-items-center">
                <div [ngSwitch]="worklist.effectivity" >
                  <div *ngSwitchCase="'NEW'">
  
                    <span class="badge bg-success" [innerHtml]='worklist.effectivity'></span>
                  </div>
  
                  <div *ngSwitchCase="'MODIFY - NEW'">
  
                    <span class="badge bg-info" [innerHtml]='worklist.effectivity'></span>
                  </div>
  
                  <div *ngSwitchCase="'UPDATE'">
  
                    <span class="badge bg-info" [innerHtml]='worklist.effectivity'></span>
                  </div>
  
                  <div *ngSwitchDefault>
                        
                    <span class="badge bg-danger" [innerHtml]='worklist.effectivity'></span>
                </div>
  
  
                  </div>
  
              </div>
              
             
                
            </td>
          </ng-container>
          <!-- 
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let worklist">
                <div class="d-flex align-items-center justify-content-center" style="padding:0.3em;">
                  <h5>{{ worklist.status }}</h5>
                </div>
            </td>
          </ng-container>
          
          <ng-container matColumnDef="effectivity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Effectivity</th>
            <td mat-cell *matCellDef="let worklist">
                <div class="d-flex align-items-center justify-content-center" style="padding:0.3em;">
                  <h5>{{ worklist.effectivity }}</h5>
                </div>
            </td>
          </ng-container> 
          --->
          <!-- old date due temporarily hidden 
           <ng-container matColumnDef="dateDue">
            <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Action Due</th>
            <td mat-cell *matCellDef="let worklist">
             
                    {{ worklist.dueDate.toDate() | date: "MMMM dd, yyyy hh:mm:ss" }}
                 
            </td>
          </ng-container>
          -->
          <!--check service if complete details-->
          <ng-container matColumnDef="completeness">
            <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Task</th>
            <td mat-cell *matCellDef="let worklist">
             
                   <div [ngSwitch]="worklist?.serviceCompleteness">
                    <div *ngSwitchCase="true">
                      <span><i matTooltip="Service Completed" class="fas fa-check-circle text-info"></i></span>
                     
                  </div>
                  <div *ngSwitchCase="false">
                    <span><i  matTooltip="Service Incomplete" class="fas fa-times-circle text-danger"></i></span>
                      
                  </div>
                  
                   </div>
                 
            </td>
          </ng-container>
          <!-- [disabled]=" ((worklist.status != enum_worklist_status.DRAFT && worklist.status != enum_worklist_status.FOR_REVISION)  && account_type != enum_account_subtype.AGENCY_VER) ||
                  ( (ongoingCharter && !charterForRevision) )  
                  "-->
          <ng-container matColumnDef="action">
            <th class="table-header" mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let worklist">
                <div class="d-flex align-items-center justify-content-center" style="padding:0.3em;">
                  <button class="mx-1" *ngIf="account_type === enum_account_subtype.AGENCY_ENC  "
                  mat-button
                  matTooltip="Clone Service"
                  [disabled]="(worklist.effectivity ==  enum_service_effectivity.DELIST || (servicesCertified && !submitEnabled)) || (ongoingCharter && !charterForRevision)  "
                  (click)="cloneService(worklist)">
                  <i class="fas fa-clone"></i> <span></span>
                  </button>
              
                  <button 
                  *ngIf="(worklist?.docOwner ? worklist?.docOwner.id === user.id ? true : false : true ) "
                  mat-flat-button
                  matTooltip="Edit Service"
                  color="primary"
                  [disabled]="worklist.status == enum_worklist_status.VERIFIED || worklist.effectivity ==  enum_service_effectivity.DELIST  || ((worklist.status != enum_worklist_status.DRAFT && worklist.status != enum_worklist_status.FOR_REVISION)  && account_type != enum_account_subtype.AGENCY_VER) ||
                  ( (ongoingCharter && !charterForRevision) )  || (worklist.status === enum_worklist_status.FOR_REVISION && account_type === enum_account_subtype.AGENCY_VER )
                  "
                  [routerLink]="['serviceeditor',worklist.documentId]"
                  [queryParams]="{worklistId: worklist.id}"
                 
                >
                <i class="fas fa-edit"></i> <span></span>
                </button>

                <button
                *ngIf="(worklist?.docOwner ? worklist?.docOwner.id === user.id ? false : true : false ) "

                [disabled]="worklist.status == enum_worklist_status.VERIFIED || worklist.effectivity ==  enum_service_effectivity.DELIST  || ((worklist.status != enum_worklist_status.DRAFT && worklist.status != enum_worklist_status.FOR_REVISION)  && account_type != enum_account_subtype.AGENCY_VER) ||
                ( (ongoingCharter && !charterForRevision) )  || (worklist.status === enum_worklist_status.FOR_REVISION && account_type === enum_account_subtype.AGENCY_VER )
                "
                mat-flat-button
                matTooltip="Edit Service"
                color="primary">
                  <i class="fas fa-edit"></i> <span></span>
                </button>
                  
                  <button 
                  mat-flat-button
                  matTooltip="View Service"
                  class="btn btn-warning mx-1"
                  [disabled]="
                  ( (ongoingCharter && !charterForRevision) && (account_type != enum_account_subtype.AGENCY_VER || !submitEnabled) && (worklist.status != 'VERIFIED') )
                  "
                  [routerLink]="['/artemis/view/detail', worklist.documentId]"
                  [queryParams]="{ worklistId: worklist.id }"
                
                >
                <i class="fas fa-file-alt"></i><span></span>
                </button>
               
                  
                 <!---
                 
                  <button *ngIf="
                  !( (ongoingCharter && !charterForRevision) && (account_type != enum_account_subtype.AGENCY_VER || !submitEnabled) )
                  "
                  [routerLink]="['/artemis/view/detail', worklist.documentId]"
                  [queryParams]="{ worklistId: worklist.id }"
                  
                  class="btn btn-danger m-2"
                >
                  Delete
                </button>-->
                  </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="worklist_columns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: worklist_columns;"></tr>
        </table>
        <mat-paginator  [pageSize]="15" #worklist_CC [pageSizeOptions]="[15, 30, 50 , 100]" aria-label="Select page of users"></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
  
  <br>
  
  <mat-card>
     
      <mat-card-header class="mb-2">
        <mat-card-title class="text-primary"><strong>Worklists not in the charter</strong></mat-card-title>
      </mat-card-header>
  
      <mat-card-content> 
        <table mat-table
          class="table table-responsive table-striped table-sm"
          id="matTable"
          [dataSource]="nonCCWorklistDataSource"
          matSort
          (matSortChange)="sortChange2($event)">
  
          <ng-container matColumnDef="dateGenerated">
            <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Date Generated</th>
            <td mat-cell *matCellDef="let worklist">
                    {{
                      worklist.dateGenerated.toDate()
                        | date: "MMMM dd, yyyy hh:mm:ss"
                    }}
                
            </td>
          </ng-container>
          
          <ng-container matColumnDef="fromOfficer">
            <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Officer</th>
            <td mat-cell *matCellDef="let worklist">
               {{ worklist.fromOfficer }}
            </td>
          </ng-container>

          <ng-container matColumnDef="category">
            <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Classification</th>
            <td mat-cell *matCellDef="let worklist">
               {{ worklist.category }}
            </td>
          </ng-container>
          
          <ng-container matColumnDef="documentTitle">
            <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Service</th>
            <td mat-cell *matCellDef="let worklist">
               {{ worklist.documentTitle }}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="status">
            <th  class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td  *matCellDef="let worklist">
       
              <div class="align-items-center">
                <div [ngSwitch]="worklist.status" >
                  <div *ngSwitchCase="'VERIFIED'">
  
                    <h6><span class="badge  bg-primary ">{{worklist.status}}</span></h6>
  
                  </div>
                  <div *ngSwitchCase="'APPROVED'">
  
                    <h6><span class="badge  bg-primary ">{{worklist.status}}</span></h6>
  
                  </div>
                  <div *ngSwitchCase="'DRAFT'">
                    <h6>
                      <span class="badge  bg-black ">{{worklist.status}}</span>
                    </h6>
                  </div>
                  <div *ngSwitchCase="'FOR REVISION'">
                    <h6>
                      <span class="badge  bg-warning ">{{worklist.status}}</span>
                    </h6>
                  </div>
                  <div *ngSwitchDefault>
                        
                      <h6>
                        <span class="badge  bg-danger ">{{worklist.status}}</span>
                      </h6>
                  </div>
  
                </div>
              </div>
              
            </td>
          </ng-container>   
          <ng-container matColumnDef="effectivity">
            <th  class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Effectivity</th>
            <td  *matCellDef="let worklist">
  
              <div class="align-items-center">
                <div [ngSwitch]="worklist.effectivity" >
                  <div *ngSwitchCase="'NEW'">
  
                    <h6>
                      <span class="badge bg-success" [innerHtml]='worklist.effectivity'></span>
                    </h6>
                  </div>
  
                  <div *ngSwitchCase="'MODIFY - NEW'">
  
                    <h6>
                      <span class="badge bg-info" [innerHtml]='worklist.effectivity'></span>
                    </h6>
                  </div>
  
                  <div *ngSwitchCase="'UPDATE'">
  
                    <h6>
                      <span class="badge bg-info" [innerHtml]='worklist.effectivity'></span>
                    </h6>
                  </div>
  
                  <div *ngSwitchDefault>
                        
                    <h6>
                      <span class="badge bg-danger" [innerHtml]='worklist.effectivity'></span>
                    </h6>
                </div>
  
  
                  </div>
  
              </div>
              
             
                
            </td>
          </ng-container>
          
          <ng-container matColumnDef="dateDue">
            <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Action Due</th>
            <td mat-cell *matCellDef="let worklist">
                {{ worklist.dueDate.toDate() | date: "MMMM dd, yyyy hh:mm:ss" }}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="action">
            <th class="table-header" mat-header-cell *matHeaderCellDef >Action</th>
            <td mat-cell *matCellDef="let worklist">
                <div class="d-flex align-items-center justify-content-center" style="padding:0.3em;">
                  <!-- <button *ngIf="
                    !( (ongoingCharter && !charterForRevision) && (account_type != enum_account_subtype.AGENCY_VER || !submitEnabled) )
                    "
                    [routerLink]="['/artemis/view/detail', worklist.documentId]"
                    [queryParams]="{ worklistId: worklist.id }"
                    mat-stroked-button
                    class="btn btn-warning m-2"
                  >
                    Review
                  </button> -->
                </div>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="nonCCworklist_columns"></tr>
          <tr mat-row *matRowDef="let row; columns: nonCCworklist_columns;"></tr>
        </table>
        <mat-paginator #worklist_NONCC pageSize="20" aria-label="Select page of users"></mat-paginator>
     
    </mat-card-content>
  </mat-card>
</div>







