<div class="content">
  <div class="container-fluid">

    <div class="row">
      <div class="card padded">
        <div class="card-header info-header">
            <div class="row">
                <div class="ms-3">
                    <h6 class="fw-bold">PRELIMINARY IMPACT STATEMENT (PIS) ASSESSMENT</h6>
                </div>
            </div>
        </div>

        <!-- Menu Tab -->
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" class="mt-4">

          <!-- Regulation Details -->
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fas fa-user-edit me-2"></i>Regulation Details
            </ng-template>

            <app-pis-assessment-phase2-view
              *ngIf="currentProposedRegulation"
              [phase2Worklist]="currentProposedRegulation">
            </app-pis-assessment-phase2-view>

            <app-pis-assessment-table
              [assessmentSection]="pis_assessment['reg_details']"
              [assessmentSectionTitle]="pis_assessment_section_title['reg_details']"
              [actionButtonAllowed]="actionButtonAllowed">
            </app-pis-assessment-table>

          </mat-tab>

          <!-- PIA Title -->
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fas fa-user-edit me-2"></i>PIA
            </ng-template>

            <div class="card-header info-header mt-5">
              <div class="row">
                <div class="ms-3">
                    <h6 class="fw-bold">PRELIMINARY IMPACT ASSESSMENT (PIA)</h6>
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="col">
                <div class="row">

                  <!-- Summary -->
                  <div class="row table-responsive">
                    <table class="table table-striped table-bordered">
                      <thead class="info-subheader">
                          <tr></tr>
                      </thead>
        
                      <tbody class="info-subheader">
                        <tr>
                          <th><h6>{{pis_assessment_section_title['pia_summary']}}</h6></th>
                        </tr>
                        <tr>
                          <td *ngIf="currentProposedRegulation && currentProposedRegulation.pia_summary">
                            <div [innerHtml]="currentProposedRegulation.pia_summary"></div>
                          </td>
                        </tr>
                      </tbody>

                      <tbody class="info-subheader">
                        <tr>
                          <td>
                            <!-- Assessment Per PIA Section (Summary of the regulatory proposal) -->
                            <app-pis-assessment-table
                              [assessmentSection]="pis_assessment['pia_summary']"
                              [assessmentSectionTitle]="pis_assessment_section_title['pia_summary']"
                              [actionButtonAllowed]="actionButtonAllowed">
                            </app-pis-assessment-table>

                          </td>
                        </tr>
                      </tbody>

                    </table>
                  </div>

                  <!-- Section 1 -->
                  <div class="row table-responsive">
                    <table class="table table-striped table-bordered">
                      <thead class="info-subheader">
                          <tr></tr>
                      </thead>
        
                      <tbody class="info-subheader">
                        <tr>
                          <th><h6>{{pis_assessment_section_title['pia_section_1']}}</h6></th>
                        </tr>
                        <ng-container *ngIf="currentProposedRegulation && currentProposedRegulation.pia_section_1">
                          <tr>
                            <td>
                              <div [innerHtml]="currentProposedRegulation.pia_section_1.policy_problem_desc"></div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div *ngIf="currentProposedRegulation.pia_section_1.policy_problem_file" class="row minispace">
                                <div class="d-flex justify-content-center">
                                  <pdf-viewer style="width: 700px" 
                                    (after-load-complete)="afterLoadComplete($event, 'pia1')"
                                    [stick-to-page]="true"
                                    [page]="currentProposedRegulation.pia_section_1.policy_problem_file_page"
                                    [show-all]="false"
                                    [original-size]="false"
                                    [src]="currentProposedRegulation.pia_section_1.policy_problem_file">
                                  </pdf-viewer>
                                </div>
                        
                                <div class="d-flex justify-content-evenly">
                                  <button (click)="pageShift('back', 'pia1')" class="btn btn-outline-primary"> < </button>
                                    <div>
                                      <p>{{ currentProposedRegulation.pia_section_1.policy_problem_file_page }} / {{ currentProposedRegulation.pia_section_1.policy_problem_file_total_page }}</p>
                                    </div>
                                  <button (click)="pageShift('next', 'pia1')" class="btn btn-outline-primary"> > </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>

                      <tbody class="info-subheader">
                        <tr>
                          <td>
                            <!-- Assessment Per PIA Section -->
                            <app-pis-assessment-table
                              [assessmentSection]="pis_assessment['pia_section_1']"
                              [assessmentSectionTitle]="pis_assessment_section_title['pia_section_1']"
                              [actionButtonAllowed]="actionButtonAllowed">
                            </app-pis-assessment-table>
                          </td>
                        </tr>
                      </tbody>

                    </table>
                  </div>

                  <!-- Section 2 -->
                  <div class="row table-responsive">
                    <table class="table table-striped table-bordered">
                      <thead class="info-subheader">
                          <tr></tr>
                      </thead>
        
                      <tbody class="info-subheader">
                        <tr>
                          <th><h6>{{pis_assessment_section_title['pia_section_2']}}</h6></th>
                        </tr>
                        <tr>
                          <td *ngIf="currentProposedRegulation && currentProposedRegulation.pia_section_2">
                            <div [innerHtml]="currentProposedRegulation.pia_section_2"></div>
                          </td>
                        </tr>
                      </tbody>

                      <tbody class="info-subheader">
                        <tr>
                          <td>
                            <!-- Assessment Per PIA Section -->
                            <app-pis-assessment-table
                              [assessmentSection]="pis_assessment['pia_section_2']"
                              [assessmentSectionTitle]="pis_assessment_section_title['pia_section_2']"
                              [actionButtonAllowed]="actionButtonAllowed">
                            </app-pis-assessment-table>

                          </td>
                        </tr>
                      </tbody>

                    </table>
                  </div>

                  <!-- Section 3 -->
                  <div class="row table-responsive">
                    <table class="table table-striped table-bordered">
                      <thead class="info-subheader">
                          <tr></tr>
                      </thead>
        
                      <tbody class="info-subheader" *ngIf="currentProposedRegulation && currentProposedRegulation.pia_section_3">
                        <tr>
                          <th colspan="2"><h6>{{pis_assessment_section_title['pia_section_3']}}</h6></th>
                        </tr>
                        <ng-container *ngFor="let pia3 of currentProposedRegulation.pia_section_3">
                          <tr>
                            <td class="col-md-3" style="text-align:left;">Policy Option Title:</td>
                            <td class="col-md-9 fw-bold">{{ pia3.policy_option }}</td>
                          </tr>
                          <tr>
                              <td class="col-md-3" style="text-align:left;">Policy Option Type:</td>
                              <td class="col-md-9">{{ pia3.policy_option_type }}</td>
                          </tr>
                          <tr>
                              <td class="col-md-3" style="text-align:left;">Policy Option Desciption:</td>
                              <td class="col-md-9">{{ pia3.policy_option_desc }}</td>
                          </tr>
                        </ng-container>
                      </tbody>

                      <tbody class="info-subheader">
                        <tr>
                          <td colspan="2">
                            <!-- Assessment Per PIA Section -->
                            <app-pis-assessment-table
                              [assessmentSection]="pis_assessment['pia_section_3']"
                              [assessmentSectionTitle]="pis_assessment_section_title['pia_section_3']"
                              [actionButtonAllowed]="actionButtonAllowed">
                            </app-pis-assessment-table>
                          </td>
                        </tr>
                      </tbody>

                    </table>
                  </div>

                  <!-- Section 4 -->
                  <div class="row table-responsive">
                    <table class="table table-striped table-bordered">
                      <thead class="info-subheader">
                          <tr></tr>
                      </thead>
        
                      <tbody class="info-subheader" *ngIf="currentProposedRegulation && currentProposedRegulation.pia_section_4">
                        <tr>
                          <th colspan="2"><h6>{{pis_assessment_section_title['pia_section_4']}}</h6></th>
                        </tr>
                        <ng-container *ngFor="let pia4 of currentProposedRegulation.pia_section_4;let i = index">
                          <tr>
                            <td class="col-md-3" style="text-align:left;">Policy Option Title:</td>
                            <td class="col-md-9 fw-bold">{{pia4.policy_option}}</td>
                          </tr>
                          <tr>
                            <td class="col-md-3" style="text-align:left;">Impacts:</td>
                            <td class="col-md-9">
                                <div class="d-flex justify-content-evenly">
                                    <span><mat-icon style="margin-left:40%">{{ pia4.impacts.impact_economic ? "check_circle_outline" : "cancel" }}</mat-icon><br> Economic</span>
                                    <span><mat-icon style="margin-left:40%">{{ pia4.impacts.impact_social ? "check_circle_outline" : "cancel" }}</mat-icon><br> Social</span>
                                    <span><mat-icon style="margin-left:40%">{{ pia4.impacts.impact_disaster_risk ? "check_circle_outline" : "cancel" }}</mat-icon><br> Disaster Risk</span>
                                    <span><mat-icon style="margin-left:40%">{{ pia4.impacts.impact_environment ? "check_circle_outline" : "cancel" }}</mat-icon><br> Environment</span>
                                </div>
                            </td>
                          </tr>
                          <tr>
                            <td class="col-md-3" style="text-align:left;">Narrative of Benefits:</td>
                            <td class="col-md-9">{{ pia4.benefits_desc }}</td>
                          </tr>
                          <tr>
                              <td class="col-md-3" style="text-align:left;">Narrative of Costs:</td>
                              <td class="col-md-9">{{ pia4.costs_desc }}</td>
                          </tr>
                          <tr>
                              <td class="col-md-3" style="text-align:left;">Narrative of Impacts:</td>
                              <td class="col-md-9">{{ pia4.impacts_desc }}</td>
                          </tr>
                        </ng-container>
                      </tbody>

                      <tbody class="info-subheader">
                        <tr>
                          <td colspan="2">
                            <!-- Assessment Per PIA Section -->
                            <app-pis-assessment-table
                              [assessmentSection]="pis_assessment['pia_section_4']"
                              [assessmentSectionTitle]="pis_assessment_section_title['pia_section_4']"
                              [actionButtonAllowed]="actionButtonAllowed">
                            </app-pis-assessment-table>
                          </td>
                        </tr>
                      </tbody>

                    </table>
                  </div>

                  <!-- Section 4 uploaded file NOT DISPLAYING -->
                  <!-- <div class="row table-responsive">
                    <table class="table table-striped table-bordered">
                      <thead class="info-subheader">
                          <tr></tr>
                      </thead>
        
                      <tbody class="info-subheader" *ngIf="currentProposedRegulation && currentProposedRegulation.pia_section_4">
                        <ng-container *ngFor="let pia4 of currentProposedRegulation.pia_section_4;let i = index">
                          <tr>
                            <div style="width: 700px" *ngIf="pia4.compliance_file" class="row minispace">
                              <div class="d-flex justify-content-center">
                                <pdf-viewer
                                  style="width: 700px"
                                  (after-load-complete)="afterLoadComplete($event, 'pia4', i)"
                                  [stick-to-page]="true"
                                  [page]="pia4.compliance_file_page"
                                  [show-all]="false"
                                  [original-size]="false"
                                  [src]="pia4.compliance_file">
                                </pdf-viewer>
                              </div>
                              <div class="d-flex justify-content-evenly">
                                <button (click)="pageShift('back', 'pia4', i)" class="btn btn-outline-primary"> < </button>
                                <div>
                                  <p>
                                    {{ pia4.compliance_file_page }} /
                                    {{ pia4.compliance_file_total_page }}
                                  </p>
                                </div>
                                <button (click)="pageShift('next', 'pia4', i)" class="btn btn-outline-primary"> > </button>
                              </div>
                            </div>
                          </tr>
                        </ng-container>
                      </tbody>

                    </table>
                  </div> -->

                  <!-- Section 5 -->
                  <div class="row table-responsive">
                    <table class="table table-striped table-bordered">
                      <thead class="info-subheader">
                          <tr></tr>
                      </thead>
        
                      <tbody class="info-subheader" *ngIf="currentProposedRegulation && currentProposedRegulation.pia_section_5">
                        <tr>
                          <th colspan="6"><h6>{{pis_assessment_section_title['pia_section_5']}}</h6></th>
                        </tr>
                        <ng-container *ngFor="let pia5 of currentProposedRegulation.pia_section_5;let i = index">
                          <tr>
                            <td class="col-md-3" style="text-align:left;">Consultation Title:</td>
                            <td class="col-md-9 fw-bold" colspan="5">{{ pia5.consultation_title }}</td>
                          </tr>
                          <tr>
                              <td class="col-md-3" style="text-align:left;">Your Agency already conducted a consultation on this policy?</td>
                              <td class="col-md-9" colspan="5">
                                <mat-icon>{{ pia5.has_agency_consulted ? "check_circle_outline" : "cancel" }}</mat-icon>
                              </td>
                          </tr>
                          <tr>
                            <td class="col-md-3" style="text-align:left;">Methods:</td>
                            <td class="col-md-9" colspan="5">
                              <div class="d-flex justify-content-evenly">
                                  <span><mat-icon style="margin-left:40%">{{ pia5.methods.method_public_commenting ? "check_circle_outline" : "cancel" }}</mat-icon><br> Public Commenting</span>
                                  <span><mat-icon style="margin-left:40%">{{ pia5.methods.method_forum ? "check_circle_outline" : "cancel" }}</mat-icon><br> Public Forum</span>
                                  <span><mat-icon style="margin-left:40%">{{ pia5.methods.method_focus_group ? "check_circle_outline" : "cancel" }}</mat-icon><br> Focus Group Discussion</span>
                                  <span><mat-icon style="margin-left:40%">{{ pia5.methods.method_other ? "check_circle_outline" : "cancel" }}</mat-icon><br> Other</span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td class="col-md-3" style="text-align:left;">Other Method:</td>
                            <td class="col-md-9" colspan="5">{{ pia5.method_other_desc }}</td>
                          </tr>
                          <tr>
                            <td class="col-md-3" style="text-align:left;">Summary of Consulation:</td>
                            <td class="col-md-9" colspan="5">{{ pia5.consultation_desc }}</td>
                          </tr>
                          <tr>
                            <td class="col-md-3 fw-bold" colspan="6">PARTICIPANTS</td>
                          </tr>
                          <tr>
                            <td class="fw-bold">Stakeholder</td>
                            <td class="fw-bold">Commenting As</td>
                            <td class="fw-bold">Gender</td>
                            <td class="fw-bold">Age</td>
                            <td class="fw-bold">Income Class</td>
                            <td class="fw-bold">Size of firm</td>
                          </tr>
                          <ng-container *ngFor="let participant of pia5.participants">
                            <tr>
                              <td rowspan="3">{{participant.stakeholder}}</td>
                              <td>{{participant.commenting_as}}</td>
                              <td>{{participant.gender}}</td>
                              <td>{{participant.age}}</td>
                              <td>{{participant.income_class}}</td>
                              <td>{{participant.size_of_firm}}</td>
                            </tr>
                            <tr>
                              <td colspan="5">
                                <div class="row">
                                  <div class="col">
                                    <label class="form-input-header fw-bold"  for="impacts_desc">Issues and Concerns:</label>
                                    <p class="desc">
                                      {{ participant.issues_and_concerns }}
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="5">
                                <div class="row">
                                  <div class="col">
                                    <label class="form-input-header fw-bold" for="impacts_desc">Actions:</label>
                                    <p class="desc">
                                      {{ participant.actions_taken }}
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </ng-container>
                        </ng-container>
                      </tbody>

                      <tbody class="info-subheader">
                        <tr>
                          <td colspan="6">
                            <!-- Assessment Per PIA Section -->
                            <app-pis-assessment-table
                              [assessmentSection]="pis_assessment['pia_section_5']"
                              [assessmentSectionTitle]="pis_assessment_section_title['pia_section_5']"
                              [actionButtonAllowed]="actionButtonAllowed">
                            </app-pis-assessment-table>
                          </td>
                        </tr>
                      </tbody>

                    </table>
                  </div>

                  <!-- Section 6 -->
                  <div class="row table-responsive">
                    <table class="table table-striped table-bordered">
                      <thead class="info-subheader">
                          <tr></tr>
                      </thead>
        
                      <tbody class="info-subheader" *ngIf="currentProposedRegulation && currentProposedRegulation.pia_section_6">
                        <tr>
                          <th colspan="2"><h6>{{pis_assessment_section_title['pia_section_6']}}</h6></th>
                        </tr>
                        <tr>
                          <td class="col-md-3" style="text-align:left;">Recommended Policy Option</td>
                          <td class="col-md-9 fw-bold">{{ currentProposedRegulation.pia_section_6.recommended_policy_option }}</td>
                        </tr>
                        <tr>
                            <td style="text-align:left;">Summary of Impact Analysis and Justification for Recommended Policy Option:</td>
                            <td>{{ currentProposedRegulation.pia_section_6.impact_summary_desc }}</td>
                        </tr>
                        <tr>
                            <td style="text-align:left;">Explanation for Implementation and Compliance of Stakeholders</td>
                            <td>{{ currentProposedRegulation.pia_section_6.compliance_desc }}</td>
                        </tr>
                      </tbody>

                      <tbody class="info-subheader">
                        <tr>
                          <td colspan="2">
                            <!-- Assessment Per PIA Section -->
                            <app-pis-assessment-table
                              [assessmentSection]="pis_assessment['pia_section_6']"
                              [assessmentSectionTitle]="pis_assessment_section_title['pia_section_6']"
                              [actionButtonAllowed]="actionButtonAllowed">
                            </app-pis-assessment-table>
                          </td>
                        </tr>
                      </tbody>

                    </table>
                  </div>

                </div>
              </div>
            </div>

          </mat-tab>

          <!-- Text of the Regulation Title -->
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fas fa-user-edit me-2"></i>Text of the Regulation
            </ng-template>

            <div class="card-header info-header mt-5">
              <div class="row">
                <div class="ms-3">
                    <h6 class="fw-bold">TEXT OF THE REGULATION</h6>
                </div>
              </div>
            </div>

            <div class="card-body" *ngIf="reg_has_draft_text">
              <!-- Section & Subsection -->
              <div class="row">
                <div class="row table-responsive" *ngFor="let section of reg_text; let $i = index">
                  <table class="table table-striped table-bordered">
                    <thead class="info-subheader">
                        <tr></tr>
                    </thead>
                          
                    <tbody class="info-subheader">
                      <tr>
                        <th>
                          <h6>{{ section.info.section_title }}
                            <span class="ms-3">
                              <button mat-icon-button color="primary" *ngIf="actionButtonAllowed && flaggedSectionIndexes.indexOf($i) == -1"
                                aria-label="Flag Section" (click)="flagSection($i, section.info.section_title)">
                                <mat-icon>outlined_flag</mat-icon> add remark
                              </button>

                              <button mat-icon-button color="warn" *ngIf="actionButtonAllowed && flaggedSectionIndexes.indexOf($i) > -1"
                                aria-label="Flag Section" (click)="unflagSection($i)">
                                <mat-icon>flag</mat-icon> add remark
                              </button>
                            </span>
                            </h6>
                        </th>
                      </tr>

                      <tr *ngFor="let type of section.info.section_type;let i = index">
                        <td><ng-container *ngIf="i > 0">, </ng-container>{{ type }}</td>
                      </tr>

                      <tr>
                        <td>
                          <div [innerHtml]="section.info.section_text"></div>
                          <ng-container *ngFor="let subsection of section.subsections">
                            <div class="row" style="padding-left: 3em">
                              <label class="form-input-header fw-bold" for="feedback_remark">
                                <h6 class="section-header-link fw-bold mb-3">{{ subsection.subsection_title }}</h6>
                              </label>
                              <div class="row" style="padding-left: 3em">
                                <div [innerHtml]="subsection.subsection_text"></div>
                                <div class="d-flex justify-content-center" *ngIf="subsection.fileUrl">
                                  <img style="width: 200px" [src]="subsection.fileUrl" />
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </td>
                      </tr>
                    </tbody>

                  </table>
                </div>
              </div>

              <!-- Flagged Remarks -->
              <div class="row">
                <table mat-table [dataSource]="flagged_sections_datasource" class="mat-elevation-z8 demo-table">
                  <!-- Name Column -->
                  <ng-container matColumnDef="flaggedSectionName">
                    <th class="theme-default" mat-header-cell *matHeaderCellDef>
                      Flagged Section
                    </th>
                    <td mat-cell *matCellDef="let element; let $i = index" [formGroup]="element">
                      {{ element.controls.flaggedSectionName.value }}
                    </td>
                  </ng-container>
      
                  <!-- Weight Column -->
                  <ng-container matColumnDef="remarks">
                    <th class="theme-default" mat-header-cell *matHeaderCellDef>
                      ARTA Remarks
                    </th>
                    <td style="width: 50%;" mat-cell *matCellDef="let element" [formGroup]="element">
                      <ng-container *ngIf="actionButtonAllowed; else noRemarksActionAllowed">
                        <button class="w-100 btn" (click)="checkRemarks(element)"
                          [ngClass]="(element.value.remarks != '' && element.value.remarks != null && element.value.remarks != undefined) ? 'btn-outline-danger' : 'btn-outline-primary'">
                          ({{element.value && element.value.previous_remarks ? element.value.previous_remarks.length : '0'}})
                          <i class="far fa-comments"></i> 
                          <span *ngIf="(element.value.remarks != '' && element.value.remarks != null && element.value.remarks != undefined)">
                            New
                          </span>
                          Remarks
                          <span *ngIf="(element.value.remarks != '' && element.value.remarks != null && element.value.remarks != undefined)">
                            Added
                          </span>
                        </button>
                      </ng-container>
                      <ng-template #noRemarksActionAllowed>
                        <button class="w-100 btn btn-outline-primary" (click)="checkRemarks(element)">
                          ({{element.value && element.value.previous_remarks ? element.value.previous_remarks.length : '0'}})
                          <i class="far fa-comments"></i> Remarks
                        </button>
                      </ng-template>
                    </td>      
                  </ng-container>
      
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsFlaggedSections"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsFlaggedSections"></tr>
                </table>
              </div>

            </div>
          </mat-tab>

          <!-- Proportionality -->
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fas fa-user-edit me-2"></i>Proportionality
            </ng-template>

            <div class="card-body mt-2">
              <div class="col">
                <div class="row">

                  <!-- Significance -->
                  <div class="row table-responsive" *ngIf="currentProposedRegulation && currentProposedRegulation.reg_significance">
                    <table class="table table-striped table-bordered">
                      <thead class="info-subheader">
                          <tr></tr>
                      </thead>
        
                      <tbody class="info-subheader">
                        <tr>
                          <th colspan="2"><h6 for="reg_significance">{{pis_assessment_section_title['reg_significance']}}</h6></th>
                        </tr>
                        <tr>
                          <td class="col-md-3">Agency Assessment:</td>
                          <td class="col-md-9 fw-bold">{{ currentProposedRegulation.reg_significance }}</td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <div [innerHtml]="currentProposedRegulation.reg_significance_comments"></div>
                          </td>
                        </tr>
                      </tbody>

                      <tbody class="info-subheader">
                        <tr>
                          <td colspan="2">
                            <!-- Assessment For Significance and Lenses as well -->
                            <app-pis-assessment-table
                              [assessmentSection]="pis_assessment['reg_significance']"
                              [assessmentSectionTitle]="pis_assessment_section_title['reg_significance']"
                              [actionButtonAllowed]="actionButtonAllowed">
                            </app-pis-assessment-table>
                          </td>
                        </tr>
                      </tbody>
                    
                    </table>
                  </div>

                  <!-- Lenses -->
                  <div class="row table-responsive" *ngIf="currentProposedRegulation && currentProposedRegulation.reg_lenses">
                    <table class="table table-striped table-bordered">
                      <thead class="info-subheader">
                          <tr></tr>
                      </thead>
        
                      <tbody class="info-subheader">
                        <tr>
                          <th colspan="2"><h6 for="reg_lenses">{{pis_assessment_section_title['reg_lenses']}}</h6></th>
                        </tr>
                        <tr>
                          <td class="col-md-3">Agency Assessment:</td>
                          <td class="col-md-9 fw-bold">
                            {{
                              ([
                                currentProposedRegulation.reg_lenses.lense_competition
                                  ? "CA"
                                  : "",
                                currentProposedRegulation.reg_lenses.lense_disaster
                                  ? "DRA"
                                  : "",
                                currentProposedRegulation.reg_lenses.lense_environmental
                                  ? "EA"
                                  : "",
                                currentProposedRegulation.reg_lenses.lense_inclusive
                                  ? "GESIA"
                                  : ""
                              ] | joinStringSeparator: "/") || "None"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <div [innerHtml]="currentProposedRegulation.reg_lenses_comments" ></div>
                          </td>
                        </tr>
                      </tbody>

                      <tbody class="info-subheader">
                        <tr>
                          <td colspan="2">
                            <!-- Assessment For Significance and Lenses as well -->
                            <app-pis-assessment-table
                            [assessmentSection]="pis_assessment['reg_lenses']"
                            [assessmentSectionTitle]="pis_assessment_section_title['reg_lenses']"
                            [actionButtonAllowed]="actionButtonAllowed">
                          </app-pis-assessment-table>
                          
                          </td>
                        </tr>
                      </tbody>

                    </table>
                  </div>

                </div>
              </div>
            </div>
          </mat-tab>

          <!-- Summary -->
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fas fa-user-edit me-2"></i>Assessment Summary
            </ng-template>

            <div class="mt-4">
              <app-pis-assessment-summary-view 
                *ngIf="pis_assessment"
                [pis_assessment]="pis_assessment"
                [flagged_sections]="flagged_sections">
              </app-pis-assessment-summary-view>
            </div>

          </mat-tab>

        </mat-tab-group>
      
      </div>
    </div>

  </div>
</div>
