import { FormatWidth } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { allMustBe } from '../validators';

export enum EntityBuilderIndex {
  SITUATIONAL_REQUIREMENT = 1,
  REQUIREMENT = 3,
  CLIENT_STEP_LIST_OF_POSSIBLE_FEES_OR_FORMULAS = 7,
  REQUIREMENT_TYPE_DOCUMENT = 8,
  REQUIREMENT_TYPE_DOCUMENT_ORIGINAL = 9,
  REQUIREMENT_TYPE_DOCUMENT_PHOTO = 10,
  REQUIREMENT_TYPE_DOCUMENT_ELECTRONIC = 11,
  REQUIREMENT_TYPE_OPERATOR = 12,
  REQUIREMENT_TYPE_OPERATOR_AND = 13,
  REQUIREMENT_TYPE_OPERATOR_OR = 14,
  REQUIREMENT_TYPE_PARENTHESIS = 15,
  REQUIREMENT_TYPE_PARENTHESIS_OPEN = 16,
  REQUIREMENT_TYPE_PARENTHESIS_CLOSE = 17,
  REQUIREMENT_TYPE_DOCUMENT_CERTIFIED_TRUE_COPY = 18,
  REQUIREMENT_CHOOSE_ANY = 20
}

@Injectable({
  providedIn: 'root'
})
export class RequirementBuilderService {

  constructor() { }

buildRequirement(entry_id: number, existing_obj?: any): FormGroup {
    switch (entry_id) {
      case EntityBuilderIndex.REQUIREMENT_CHOOSE_ANY:

        let chooseAnyRequirement: FormGroup = new FormGroup({
          title : new FormControl(existing_obj?.title || '',Validators.required),
          requirement: new FormArray([
            this.buildRequirement(EntityBuilderIndex.REQUIREMENT),
            this.buildRequirement(EntityBuilderIndex.REQUIREMENT)
          ],allMustBe(Validators.required)),
        })
/* 
        if(existing_obj){

          existing_obj.requirement.forEach((requirement:any)=>{
              const req = chooseAnyRequirement.get('requirement') as FormArray
              req.push()
          }) 
        }

 */

        return chooseAnyRequirement
        
      case EntityBuilderIndex.SITUATIONAL_REQUIREMENT:
        let newSituationalRequirement = new FormGroup({
          requirement: new FormControl(existing_obj?.requirement || '',
            Validators.required),
          requirementType: new FormControl(
            existing_obj?.requirementType 
          ,Validators.required),
          copyFormat: new FormArray([
            this.buildRequirement(
              EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ORIGINAL,
              existing_obj
            )
          ]),
          listOfIds: new FormControl({ value: '', disabled: true }),
          copyFormatDisabled: new FormControl(true),
          number: new FormControl('', Validators.required),
          unit: new FormControl('', Validators.required),
          agency: new FormControl('', Validators.required), //,[Validators.required]
          bureau_division: new FormControl('', Validators.required), //,[Validators.required]
          remarks_or_reminders: new FormControl(''),
          applicant: new FormControl(existing_obj?.applicant || false),
        });
        return newSituationalRequirement;
      case EntityBuilderIndex.REQUIREMENT:
        let newStandardRequirement = new FormGroup({
          requirement: new FormControl(existing_obj?.requirement || '', Validators.required),
          requirementType: new FormControl(existing_obj?.requirementType ,Validators.required),
          copyFormat: new FormArray([
            this.buildRequirement(
              EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ORIGINAL,
              existing_obj
            )
          ]),
          listOfIds: new FormControl({ value: '', disabled: true }),
          copyFormatDisabled: new FormControl(true),
          number: new FormControl('', Validators.required),
          unit: new FormControl('', Validators.required),
          agency: new FormControl('', Validators.required), //,[Validators.required]
          bureau_division: new FormControl('', Validators.required), //,[Validators.required]
          remarks_or_reminders: new FormControl(''),
          applicant: new FormControl(existing_obj?.applicant || false),
        });
        return newStandardRequirement;
      case EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT:{
          return new FormGroup({
            val: new FormControl({value:existing_obj.val || existing_obj.value || 'Original copy',disabled:false}),
            disabled:new FormControl(false),
            type:new FormControl('document'),
            copyCount: new FormControl({value: existing_obj.copyCount || 1,disabled:false}),
          })
        }

      case EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ORIGINAL: {
        return new FormGroup({
          val: new FormControl({
            value: 'Original Copy',
            disabled: existing_obj ? false : true,
          }),
          disabled: new FormControl(existing_obj ? false : true),
          type: new FormControl('document'),
          copyCount: new FormControl({
            value: 1,
            disabled: existing_obj ? false : true,
          }),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_PHOTO: {
        return new FormGroup({
          val: new FormControl({
            value: 'Photo Copy',
            disabled: existing_obj ? false : true,
          }),
          disabled: new FormControl(existing_obj ? false : true),
          type: new FormControl('document'),
          copyCount: new FormControl({
            value: 1,
            disabled: existing_obj ? false : true,
          }),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ELECTRONIC: {
        return new FormGroup({
          val: new FormControl({
            value: 'Electronic Copy',
            disabled: existing_obj ? false : true,
          }),
          disabled: new FormControl(existing_obj ? false : true),
          type: new FormControl('document'),
          copyCount: new FormControl({
            value: 1,
            disabled: existing_obj ? false : true,
          }),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_CERTIFIED_TRUE_COPY: {
        return new FormGroup({
          val: new FormControl({
            value: 'Certified True Copy',
            disabled: existing_obj ? false : true,
          }),
          disabled: new FormControl(existing_obj ? false : true),
          type: new FormControl('document'),
          copyCount: new FormControl({
            value: 1,
            disabled: existing_obj ? false : true,
          }),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR: {
        return new FormGroup({
          val: new FormControl({
            value: existing_obj.val || existing_obj.value || 'And',
            disabled: false,
          }),
          disabled: new FormControl(false),
          type: new FormControl('operator'),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_AND: {
        return new FormGroup({
          val: new FormControl({
            value: 'And',
            disabled: existing_obj ? false : true,
          }),
          disabled: new FormControl(existing_obj ? false : true),
          type: new FormControl('operator'),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_OR: {
        return new FormGroup({
          val: new FormControl({
            value: 'Or',
            disabled: existing_obj ? false : true,
          }),
          disabled: new FormControl(existing_obj ? false : true),
          type: new FormControl('operator'),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_PARENTHESIS: {
        return new FormGroup({
          val: new FormControl({
            value: existing_obj.val || existing_obj.value || '(',
            disabled: false,
          }),
          disabled: new FormControl(false),
          type: new FormControl('parenthesis'),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_PARENTHESIS_OPEN: {
        return new FormGroup({
          val: new FormControl({
            value: '(',
            disabled: existing_obj ? false : true,
          }),
          disabled: new FormControl(existing_obj ? false : true),
          type: new FormControl('parenthesis'),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_PARENTHESIS_CLOSE: {
        return new FormGroup({
          val: new FormControl({
            value: ')',
            disabled: existing_obj ? false : true,
          }),
          disabled: new FormControl(existing_obj ? false : true),
          type: new FormControl('parenthesis'),
        });
      }
      default:
        return new FormGroup({});
    }
  }
}
