<app-navbar [currentPage]="'ContactUs'"></app-navbar>
<div class="page-header theme-registration mb-3 py-3">
  <div class="container">
    <div class="row">
      <div class="col-6 title-widget info-widget">
        <h5 class="fw-bold py-2">CONTACT US</h5>
      </div>
      <div class="col-6 d-none d-md-block">
        <div class="float-end">
          <button type="button" class="btn btn-light btn-floating mx-1" style="width: auto; height: auto;" hidden>
            <a href="/pbris/regulations/existing" title="Existing Regulation">
              <img src="/assets/Assets/Icons/Existing%20Regulation.png" height="70" alt="Existing Regulation" />
            </a>
          </button>
          <button type="button" class="btn btn-light btn-floating mx-1" style="width: auto; height: auto;" hidden>
            <a href="/pbris/regulations/proposed" title="Proposed Regulation">
              <img src="/assets/Assets/Icons/Proposed%20Regulation.png" height="70" alt="Proposed Regulation" />
            </a>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="container">
    <div class="content-head">
      <h4 class="title">Have anymore questions?</h4>
      <h5 class="subtitle">Reach us through the following channels</h5>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <div class="map-responsive">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3859.9828685315947!2d121.04382741464977!3d14.656913679624072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397b7a03cd8922f%3A0xad6a5fc5239dd80e!2sTHE%20ANTI-RED%20TAPE%20AUTHORITY!5e0!3m2!1sen!2sph!4v1636455998949!5m2!1sen!2sph"
             width="1200" height="150" frameborder="0" style="border:0;" allowfullscreen loading="lazy">
          </iframe>
        </div>
      </div>

      <div class="col-12 my-5">
        <div class="row row-cols-2">
          
          <!-- <div class="col my-2">
            <div class="dgg-pill">
              <div class="head-and-photo">
                <div class="head-text">
                  <h4 class="pill-head">
                    Deputy Director General for Operations
                  </h4>
                </div>
                <div class="head-photo">
                  <img src="\assets\Assets\PBRIS-DDG-SQUARE.jpg" alt="">
                </div>
              </div>
              <h5 class="dgg-name">
                Atty. Ernesto V. Perez, CPA
              </h5>
              <div class="contact-pill">
                <p>
                  oddgoperations@arta.gov.ph
                </p>
              </div>
            </div>
          </div> -->
          
          <!-- BRO -->
          <div class="col-lg-4 col-sm-12 my-2 px-3">
            <h4 class="pill-head">Better Regulations Office</h4>
            <p class="arta-address">
              The BRO Sectoral Divisions are the main offices responsible for the oversight of the
              PBRIS, the RIA process and the review of the PIS and RIS.
            </p>
            <div class="contact-pill">
              <p>
                pbris@arta.gov.ph<br>
                regulatorymanagement@arta.gov.ph
              </p>
            </div>
          </div>

          <!-- ARTA -->
          <div class="col-lg-4 col-sm-12 ddg-pill">
            <h4 class="arta-head">Anti-Red Tape Authority</h4>
            <p class="arta-address">
              4th & 5th Floor, NFA Building, NFA Compound, Visayas Avenue, Brgy. Vasra, Diliman, Quezon City, Philippines 1128
            </p>
            <div class="contact-pill">
              <div class="row justify-content-center">
                <div class="col-6">
                  <p>
                    <a href="https://arta.gov.ph/" style="color:#FFF;">www.arta.gov.ph</a><br>
                    info@arta.gov.ph
                  </p>
                </div>
                <div class="col-6">
                  <p>
                    1-ARTA (12782)<br>
                    (02) 8246-7940
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- RMTD -->
          <div class="col-lg-4 col-sm-12 my-2 px-3">
            <h4 class="pill-head">Regulatory Management and Training Division</h4>
            <p class="arta-address">
              The RMTD conducts regulatory management training programs to capacitate
              NGAs and LGUs to comply with sound regulatory management practices.
            </p>
            <div class="contact-pill">
              <p>rmtd@arta.gov.ph</p>
            </div>
          </div>

        </div>
      </div>

      
    </div>

  </div>
</div>