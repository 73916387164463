import { AngularFireStorage } from '@angular/fire/storage';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import { GovernmentAccountSubtype } from 'src/app/entities/worklist';
import { PdfRenderService } from 'src/app/pdf-render.service';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { ARTEMIS, PBRIS } from 'src/app/entities/constant';

export interface User {
    registrationRecaptchaV3Token?: string,
    personal?:any,
    government?:any,
    file?:any,
    credentials?:any,
    company?:any,
    settings?:any,
    id?:string,
}

@Component({
    selector: 'app-pbris-account',
    templateUrl: './pbris-account.component.html',
    styleUrls: ['./pbris-account.component.scss']
})
export class PbrisAccountComponent  implements OnInit {
    pbris = PBRIS
    artemis = ARTEMIS
    currentSystem:any = sessionStorage.getItem('currentSystem');

    @ViewChild(MatTable) table: MatTable<any>;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    formLoader:boolean = false;

    userDataSource:any = [];
    userDataSourceColumns: string[] = ['first_name','email','account_type']
    allUsers:User[] = []
    allUsersS:User[] = []
    allAgencies:any = []

    chosenUser:any;

    filtersForm = new FormGroup({
        search: new FormControl(''),
    })

    personalEditForm:boolean = true;
    personalForm = this.fb.group({
        first_name: new FormControl(''),
        last_name: new FormControl(''),
        area_of_residence: new FormControl(''),
        sex: new FormControl(''),
        idnumber: new FormControl(''),
        idtype: new FormControl(''),
    })

    credentialEditForm:boolean = true;
    credentialForm = this.fb.group({
        access_artemis: new FormControl(''),
        access_pbris: new FormControl(''),
        account_type: new FormControl(''),
        account_subtype: new FormControl(''),
        admin: new FormControl(''),
        email: new FormControl(''),
        loggedIn: new FormControl(''),
        mobile_number: new FormControl(''),
        sinceMember: new FormControl(''),
        uid: new FormControl(''),
    })

    governmentEditForm:boolean = true;
    governmentForm = this.fb.group({
        agency: new FormControl(''),
        official_designation: new FormControl(''),
        subagency: new FormControl(''),
    })

    companyEditForm:boolean = true;
    companyForm = this.fb.group({
        name_of_organization: new FormControl(''),
        official_designation: new FormControl(''),
        organization_address: new FormControl(''),
        sector: new FormControl(''),
    })

    fileEditForm:boolean = true;
    fileForm = this.fb.group({
        company_id: new FormControl(''),
        government_id: new FormControl(''),
        government_id_registration_date: new FormControl(''),
        government_id_file_name: new FormControl(''),
        authorization_letter: new FormControl(''),
        authorization_letter_registration_date: new FormControl(''),
        authorization_letter_file_name: new FormControl(''),
        downloadLinkExpiresOn: new FormControl(''),
    })
    govIdSrc:any;
    govIdName:any;
    govIdImage:any;
    govIdType:any;
    authLetterSrc:any;
    authLetterName:any;
    authLetterFile:any;
    authLetterType:any;

    fileBasePage:any = 1;
    fileBaseTotalPage:any;
    fileNewPage:any = 1;
    fileNewTotalPage:any;

    // authLetterIsImage:boolean= true;

    settingsEditForm:boolean = true;
    settings:any = {
        // all
        regulations:{
            create: false, //all
            review: false, // admin / agencymemb / arta
        },
        consultations:{
            create: false, //all
            review: false, // admin / agencymemb / arta
        },
        citizenscharters:{
            create: false, //all
            review: false, // admin / agencymemb / arta
        },
        services:{
            create: false, //all
            review: false, // admin / agencymemb / arta
        },

        // admin / arta
        agency: {
            complaint: false,
            create: false,
        },

        //admin
        worklist: {
            create: false,
            review: false,
        },
        accounts: {
            create: false,
            review: false,
        }

    };

    constructor(
        // public auth: AngularFireAuth,
        // private router: Router,
        private afs: AngularFirestore,
        public fb: FormBuilder,
        private store: AngularFireStorage,
        private pdf_render: PdfRenderService,
        ) {}

    ngOnInit(): void {
        this.loadUsers()
        this.loadAgencies()
    }

    settingFilter(){
        this.userDataSource.filterPredicate = (data:any, filter:string): boolean=>{
            return data.personal.first_name.toLowerCase().includes(filter.toLowerCase())
            || data.personal.last_name.toLowerCase().includes(filter.toLowerCase())
            || (data.personal.first_name+" "+data.personal.last_name).toLowerCase().includes(filter.toLowerCase())
            || data.credentials.email.toLowerCase().includes(filter.toLowerCase())
            || data.credentials.account_type.toLowerCase().includes(filter.toLowerCase())
            // || data.credentials.account_subtype.toLowerCase().includes(filter.toLowerCase())
            || this.subFilter(data,filter)
        }
    }

    subFilter(data:any,filter:string){
        if('artemis' == filter.toLowerCase())
            if(data.credentials.access_artemis) return true
        
        if('pbris' == filter.toLowerCase())
            if(data.credentials.access_pbris) return true
        
        return false
    }

    applyFilters(form:any): void {
        const results:any = [];
        this.allUsers.forEach(row => {
          results.push(row);
        });
        this.userDataSource.data = results;
        // this.resetSortPage()
    }

    resetSortPage(){
        this.userDataSource.paginator = this.paginator
        this.userDataSource.sort = this.sort
        this.settingFilter()
    }

    search(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value
        this.userDataSource.filter = filterValue.trim().toLowerCase()
        // this.resetSortPage()
        
        if (this.userDataSource.paginator) {
            this.userDataSource.paginator.firstPage();
        }
    }

    sortChange(sort: Sort){
        const data = this.allUsers.slice()
        if(!sort.active || sort.direction === ''){
            this.allUsersS = data
            return;
        }
        this.allUsersS = data.sort((a:User,b:User)=>{
            const isAsc = sort.direction === 'asc'
            switch (sort.active){
                case 'first_name':
                return this.compare(a.personal.first_name.trim().toLowerCase(), b.personal.first_name.trim().toLowerCase(), isAsc)
                case 'email':
                return this.compare(a.credentials.email.trim().toLowerCase(), b.credentials.email.trim().toLowerCase(), isAsc)
                case 'account_type':
                return this.compare(a.credentials.account_type.trim().toLowerCase(), b.credentials.account_type.trim().toLowerCase(), isAsc)
                default:
                return 0
            }
        })
        this.userDataSource = new MatTableDataSource<User>(this.allUsersS)
        this.resetSortPage()
    }

    compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    loadUsers(){
        this.afs.collection('Users').snapshotChanges()
        // .pipe(first())
        .subscribe(data=>{
            let tempArr:any = []
            tempArr = data.map(info=>{
                let item:any = info.payload.doc.data()
                item.id = info.payload.doc.id
                // if(tempArr.filter((element:any)=>element.id == item.id).length == 0) tempArr.push(item)
                // tempArr.push(item)
                return item
            })
            this.allUsers = tempArr

            this.userDataSource = new MatTableDataSource<User>(this.allUsers)
            this.resetSortPage()
        })
    }

    htmlCodes:any=[
        {code:"%20",char:" "},{code:"%21",char:"!"},{code:"%22",char:'"'},{code:"%23",char:"#"},{code:"%24",char:"$"},{code:"%26",char:"&"},{code:"%27",char:"'"},{code:"%28",char:"("},{code:"%29",char:")"},{code:"%2A",char:"*"},
        {code:"%2B",char:"+"},{code:"%2C",char:","},{code:"%2D",char:"-"},{code:"%2E",char:"."},{code:"%2F",char:"/"},{code:"%3A",char:":"},{code:"%3B",char:";"},{code:"%3C",char:"<"},{code:"%3D",char:"="},{code:"%3E",char:">"},
        {code:"%3F",char:"?"},{code:"%40",char:"@"},{code:"%5B",char:"["},{code:"%5C",char:'\\'},{code:"%5D",char:"]"},{code:"%5E",char:"^"},{code:"%5F",char:"_"},{code:"%60",char:"`"},{code:"%7B",char:"{"},{code:"%7C",char:"|"},
        {code:"%7D  ",char:"}"},{code:"%7E",char:"~"},{code:"%25",char:"%"},
    ]

    resignHTMLcodes(str:any){
        for(const e of this.htmlCodes){
            str = str.replaceAll(e.code,e.char)
        }
        return str
    }

    loadAgencies(){
        this.afs.collection('Agency', filter => filter.orderBy("name", "asc")).snapshotChanges()
        .pipe(first())
        .subscribe(data=>{
            data.forEach(info=>{
                let agency:any = info.payload.doc.data()
                agency.id = info.payload.doc.id
                this.allAgencies.push(agency)
            })
        })
    }

    async userChosen(user:any){
        user.file = await this.loadFile(user,user.file.government_id,'gov_id')
        user.file = await this.loadFile(user,user.file.authorization_letter,'auth_letter')
        user.file.gov_id_url = (user.file.government_id_from === 'storage') ? await this.getFile(user.file,'gov_id') : null;
        user.file.auth_url = (user.file.authorization_letter_from === 'storage') ? await this.getFile(user.file,'auth_letter') : null;
        this.chosenUser = user
        console.log(user)
        if(this.chosenUser){
            this.cancelAllForms();
        }
    }

    async getFile(file:any,from:any){
        let folderPath:any = ""
        let folderUserTypePath:any = "";
        let folderTime:any = ""
        let fileName:any = ""
        if(from == 'gov_id'){
            folderPath = file.government_id_gov_folder
            if(file.government_id_gov_folder_user_type){
                folderUserTypePath = file.government_id_gov_folder_user_type+"/"
            }
            folderTime = file.government_id_registration_date
            fileName = file.government_id_file_name
        }
        else if(from == 'auth_letter'){
            folderPath = file.authorization_letter_gov_folder
            if(file.authorization_letter_gov_folder_user_type){
                folderUserTypePath = file.authorization_letter_gov_folder_user_type+"/"
            }
            folderTime = file.authorization_letter_registration_date
            fileName = file.authorization_letter_file_name
        }
        
        const fileRef:any = folderPath+"/"+folderUserTypePath+folderTime+"/"+fileName;
        return this.pdf_render.getFileUrl(fileRef).toPromise().then(url=> url);

    }

    async loadFile(item:any, rawLink:any, from:any){
        if(item.file && (from === 'gov_id' || from === 'auth_letter')){
            if(rawLink){
                let version:any;
                if(rawLink.trim().toLowerCase().includes("https://storage.googleapis.com")) version = "storage"
                else if(rawLink.trim().toLowerCase().includes("https://firebasestorage.googleapis.com")) version = "firestorage"
                else return item.file
                
                const linkSplit = rawLink.split('?')
                const linkTokenize = linkSplit[0].split('/')
                for(let ctr in linkTokenize){
                    linkTokenize[ctr] = this.resignHTMLcodes(linkTokenize[ctr])
                }
                let subLinkTokenize:any;
                if(version == "firestorage") subLinkTokenize = linkTokenize[linkTokenize.length - 1].split('/')
                
                if(version == 'storage'){
                    if(from === 'gov_id'){
                        item.file.government_id_registration_date = linkTokenize[linkTokenize.length-2]
                        item.file.government_id_file_name = linkTokenize[linkTokenize.length-1]
                        item.file.government_id_gov_folder_user_type = linkTokenize[linkTokenize.length-3];
                        item.file.government_id_gov_folder = linkTokenize[linkTokenize.length-4];
                        item.file.government_id_from = version
                    }else if(from === "auth_letter"){
                        item.file.authorization_letter_registration_date = linkTokenize[linkTokenize.length-2];
                        item.file.authorization_letter_file_name = linkTokenize[linkTokenize.length-1];
                        item.file.authorization_letter_gov_folder_user_type = linkTokenize[linkTokenize.length-3];
                        item.file.authorization_letter_gov_folder = linkTokenize[linkTokenize.length-4];
                        item.file.authorization_letter_from = version
                    }
                }
                
                if(version == 'firestorage'){
                    if(from === 'gov_id'){
                        item.file.government_id_registration_date = subLinkTokenize[1]
                        item.file.government_id_file_name = subLinkTokenize[2]
                        item.file.government_id_gov_folder = subLinkTokenize[0];
                        item.file.government_id_from = version
                    }else if(from === "auth_letter"){
                        item.file.authorization_letter_registration_date = subLinkTokenize[1]
                        item.file.authorization_letter_file_name = subLinkTokenize[2]
                        item.file.authorization_letter_gov_folder = subLinkTokenize[0];
                        item.file.authorization_letter_from = version
                    }
                }
            
                let refString:any;
                if(version == 'storage'){
                    let minus3rd = ""
                    if( linkTokenize[linkTokenize.length-3] === "artemis" ||  linkTokenize[linkTokenize.length-3] === 'pbris'){
                        minus3rd = linkTokenize[linkTokenize.length-3]+"/"
                    } 
                    refString = linkTokenize[linkTokenize.length-4]+"/"+minus3rd+linkTokenize[linkTokenize.length-2]+"/"+linkTokenize[linkTokenize.length-1]
                }
                // else if(version == 'firestorage') refString= subLinkTokenize[0]+"/"+subLinkTokenize[1]+"/"+subLinkTokenize[2]
                else if(version == 'firestorage'){
                    if(from == 'gov_id') refString = item.file.government_id
                    else if(from == 'auth_letter') refString = item.file.authorization_letter
                }
                if(!item.file.metadata || item.file.metadata === null) item.file.metadata = {}
                return new Promise((res,rej)=>{
                    if(version == 'storage'){
                        this.store.ref(refString).getMetadata()
                        .pipe(first())
                        .subscribe(result=>{
                            const name = result.name
                            const contentType = result.contentType
                            return res({
                                name:name,
                                contentType:contentType
                            });
                        });
                    }
                    else if(version == 'firestorage'){
                        this.store.refFromURL(refString).getMetadata()
                        .pipe(first())
                        .subscribe(result=>{
                            const name = result.name
                            const contentType = result.contentType
                            return res({
                                name:name,
                                contentType:contentType
                            });
                        });
                    }
                    else return rej(item.file)
                })
                .then((meta:any)=>{
                    if(!item.file.metadata || item.file.metadata == null) item.file.metadata = {}
                    if(meta){
                        if(from === 'gov_id'){
                            if(!item.file.metadata.gov || item.file.metadata.gov == null){
                                item.file.metadata.gov = {}
                            }
                            item.file.metadata.gov.name = meta.name
                            item.file.metadata.gov.contentType = meta.contentType
                        }else if(from === 'auth_letter'){
                            if(!item.file.metadata.auth || item.file.metadata.auth == null){
                                item.file.metadata.auth = {}
                            }
                            item.file.metadata.auth.name = meta.name
                            item.file.metadata.auth.contentType = meta.contentType
                        }
                    }else{
                        if(from === 'gov_id') item.file.metadata.gov = {}
                        else if(from === 'auth_letter') item.file.metadata.auth = {}
                    }

                    return item.file
                })
                .catch(err=>{
                    console.error("Error: getting metadata ",from,err)
                    return item.file
                })
            }return item.file
        }return item.file
    }

    cancelAllForms(){
        this.updateToggle('personal', 'cancel');
        this.updateToggle('credential','cancel');
        this.updateToggle('government','cancel');
        this.updateToggle('company','cancel');
        this.updateToggle('file','cancel');
        this.updateToggle('settings','cancel');
    }

    async patchValue(editName:any){
        if(editName === 'personal'){
            if(this.chosenUser.personal){
                this.personalForm.patchValue({
                    first_name: (this.chosenUser.personal.first_name) ? (this.chosenUser.personal.first_name) : "",
                    last_name: (this.chosenUser.personal.last_name) ? (this.chosenUser.personal.last_name) : "",
                    area_of_residence: (this.chosenUser.personal.area_of_residence) ? (this.chosenUser.personal.area_of_residence) : "",
                    sex: (this.chosenUser.personal.sex) ? (this.chosenUser.personal.sex) : "",
                    idnumber: (this.chosenUser.personal.idnumber) ? (this.chosenUser.personal.idnumber) : "",
                    idtype: (this.chosenUser.personal.idtype) ? (this.chosenUser.personal.idtype) : "",
                })
            }
            else this.personalForm.reset()
        }
        else if(editName === 'credential'){
            if(this.chosenUser.credentials){
                this.credentialForm.patchValue({
                    access_artemis: (this.chosenUser.credentials.access_artemis) ? (this.chosenUser.credentials.access_artemis) : "",
                    access_pbris: (this.chosenUser.credentials.access_pbris) ? (this.chosenUser.credentials.access_pbris) : "",
                    account_subtype: (this.chosenUser.credentials.account_subtype) ? (this.chosenUser.credentials.account_subtype) : "",
                    account_type: (this.chosenUser.credentials.account_type) ? (this.chosenUser.credentials.account_type) : "",
                    admin: (this.chosenUser.credentials.admin) ? (this.chosenUser.credentials.admin) : "",
                    email: (this.chosenUser.credentials.email) ? (this.chosenUser.credentials.email) : "",
                    loggedIn: (this.chosenUser.credentials.loggedIn) ? (this.chosenUser.credentials.loggedIn) : "",
                    mobile_number: (this.chosenUser.credentials.mobile_number) ? (this.chosenUser.credentials.mobile_number) : "",
                    sinceMember: (this.chosenUser.credentials.sinceMember) ? (this.chosenUser.credentials.sinceMember) : "",
                    uid: (this.chosenUser.credentials.uid) ? (this.chosenUser.credentials.uid) : "",
                })
            }
            else this.credentialForm.reset()
        }
        else if(editName === 'government'){
            if(this.chosenUser.government){
                this.governmentForm.patchValue({
                    agency: (this.chosenUser.government.agency) ? (this.chosenUser.government.agency) : "",
                    official_designation: (this.chosenUser.government.official_designation) ? (this.chosenUser.government.official_designation) : "",
                    subagency: (this.chosenUser.government.subagency) ? (this.chosenUser.government.subagency) : "",
                })
            }
            else this.governmentForm.reset()
        }
        else if(editName === 'company'){
            if(this.chosenUser.company){
                this.companyForm.patchValue({
                    name_of_organization: (this.chosenUser.company.name_of_organization) ? (this.chosenUser.company.name_of_organization) : "",
                    official_designation: (this.chosenUser.company.official_designation) ? (this.chosenUser.company.official_designation) : "",
                    organization_address: (this.chosenUser.company.organization_address) ? (this.chosenUser.company.organization_address) : "",
                    sector: (this.chosenUser.company.sector) ? (this.chosenUser.company.sector) : "",
                })
            }
            else this.companyForm.reset()
        }
        else if(editName === 'file'){ 
            if(this.chosenUser.file){
                this.fileForm.patchValue({
                    company_id: this.chosenUser.file.company_id,
                    government_id: (this.chosenUser.file.government_id_from === 'storage') ? (this.chosenUser.file.gov_id_url) : ((this.chosenUser.file.government_id) ? (this.chosenUser.file.government_id) : ""),
                    government_id_registration_date: this.chosenUser.file.government_id_registration_date,
                    government_id_file_name: (this.chosenUser.file.gov_id_url) ? (this.chosenUser.file.metadata.gov.name) : ((this.chosenUser.file.government_id) ? (this.chosenUser.file.metadata.gov.name) : ""),
                    authorization_letter: (this.chosenUser.file.authorization_letter_from === 'storage') ? (this.chosenUser.file.auth_url) :((this.chosenUser.file.authorization_letter) ? (this.chosenUser.file.authorization_letter) : ""),
                    authorization_letter_registration_date: this.chosenUser.file.authorization_letter_registration_date,
                    authorization_letter_file_name: (this.chosenUser.file.auth_url) ? this.chosenUser.file.metadata.auth.name : ((this.chosenUser.file.authorization_letter) ? (this.chosenUser.file.metadata.auth.name) : ""),
                    downloadLinkExpiresOn: this.chosenUser.file.downloadLinkExpiresOn,
                })
                this.fileBasePage = 1;
                this.fileBaseTotalPage = null;
                this.govIdSrc = (this.chosenUser.file.government_id_from === 'storage') ? (this.chosenUser.file.gov_id_url) : ((this.chosenUser.file.government_id) ? (this.chosenUser.file.government_id) : "");
                this.govIdName = (this.chosenUser.file.gov_id_url) ? (this.chosenUser.file.metadata.gov.name) : ((this.chosenUser.file.government_id) ? (this.chosenUser.file.metadata.gov.name) : ""); 
                this.govIdImage = null;
                this.govIdType = (this.chosenUser.file.gov_id_url) ? (this.chosenUser.file.metadata.gov.contentType) : ((this.chosenUser.file.government_id) ? (this.chosenUser.file.metadata.gov.contentType) : "")
                this.authLetterSrc = (this.chosenUser.file.authorization_letter_from === 'storage') ? (this.chosenUser.file.auth_url) : ((this.chosenUser.file.authorization_letter) ? (this.chosenUser.file.authorization_letter) : "");
                this.authLetterName = (this.chosenUser.file.auth_url) ? this.chosenUser.file.metadata.auth.name : ((this.chosenUser.file.authorization_letter) ? (this.chosenUser.file.metadata.auth.name) : "");
                this.authLetterType = (this.chosenUser.file.auth_url) ? this.chosenUser.file.metadata.auth.contentType : ((this.chosenUser.file.authorization_letter) ? (this.chosenUser.file.metadata.auth.contentType) : "");
                this.authLetterFile = null;
            }
            else this.fileForm.reset()
        }
        else if(editName === 'settings'){
            if(this.chosenUser.settings){
                this.settings = this.chosenUser.settings
            }
            else {
                this.settings = {
                    regulations:{
                        create: false,
                        review: false,
                    },
                    consultations:{
                        create: false,
                        review: false,
                    },
                    citizenscharters:{
                        create: false,
                        review: false,
                    },
                    services:{
                        create: false,
                        review: false,
                    },
                    agency: {
                        complaint: false,
                        create: false,
                    },
                    worklist: {
                        create: false,
                        review: false,
                    },
                    accounts: {
                        create: false,
                        review: false,
                    }
                };
            }
        }
    }

    universalOption(controlName:any){
        if(controlName === 'gender')
            return ['Male','Female']

        else if(controlName === 'agency'){
            let agencies:any = []
            if(this.chosenUser.credentials.account_type === "Government"){
                this.allAgencies.forEach((agency:any)=>{
                    agencies.push(agency)
                })
            }
            else{
                agencies.push({name:"Public/Non Government", id:"non-agency"})
            }
            return agencies
        }
        
        else if(controlName === 'govtype')
            return ['Personal','Company','Government'];

        else if(controlName === 'govsubtype'){
            let account_subtype:any = []
            if(this.chosenUser.credentials.account_type === "Personal"){
                account_subtype.push("Private Individual");
            }
            else if(this.chosenUser.credentials.account_type === "Company"){
                account_subtype.push("Organization");
            }
            else if(this.chosenUser.credentials.account_type === "Government"){
                account_subtype.push(GovernmentAccountSubtype.AGENCY_ENC);
                account_subtype.push(GovernmentAccountSubtype.AGENCY_VER);
                account_subtype.push(GovernmentAccountSubtype.AGENCY_DIR);
                account_subtype.push(GovernmentAccountSubtype.AGENCY_ADMIN);
                
                account_subtype.push(GovernmentAccountSubtype.ARTA_CMEO_PO);
                account_subtype.push(GovernmentAccountSubtype.ARTA_CMEO_DC);
                account_subtype.push(GovernmentAccountSubtype.ARTA_CMEO_DIR);
                account_subtype.push(GovernmentAccountSubtype.ARTA_BRO_PO);
                account_subtype.push(GovernmentAccountSubtype.ARTA_BRO_DC);
                account_subtype.push(GovernmentAccountSubtype.ARTA_BRO_DIR);

                account_subtype.push(GovernmentAccountSubtype.ARTA_ADMIN);
                account_subtype.push(GovernmentAccountSubtype.ARTA_DDG);
                account_subtype.push(GovernmentAccountSubtype.ARTA_DG);
            }
            return account_subtype;
        }

        else if(controlName === 'traulse')
            return [true,false]

        return []
    }

    universalSelector(controlName:any,selection:any){
        if(controlName === 'gender'){
            if(this.chosenUser.personal)
                if(this.chosenUser.personal.sex === selection) return true;
        }

        else if(controlName === 'agency'){
            if(this.chosenUser.government){
                if(selection === this.chosenUser.government.agency)return true;
                else if(selection === 'non-agency')return true;
            }
        }

        else if(controlName === 'govtype'){
            if(this.chosenUser.credentials)
                if(this.chosenUser.credentials.account_type === selection) return true
        }

        else if(controlName === 'govsubtype'){
            if(this.chosenUser.credentials)
                if(this.chosenUser.credentials.account_subtype === selection) return true;
        }

        else if(controlName === 'access_artemis'){
            if(this.chosenUser.credentials)
                if(this.chosenUser.credentials.access_artemis === selection) return true;
        }

        else if(controlName === 'access_pbris'){
            if(this.chosenUser.credentials)
                if(this.chosenUser.credentials.access_pbris === selection) return true;
        }

        return false
    }

    universalUpdater(controlName:any, value:any){
        if(controlName === 'gender'){
            this.personalForm.patchValue({
                sex:value,
            })
        }
        else if(controlName === 'agency'){
            this.governmentForm.patchValue({
                agency: value
            })
        }
        else if(controlName === 'govtype'){
            if(this.credentialForm){
                this.credentialForm.patchValue({
                    account_type: value,
                })
            }
        }
        else if(controlName === 'govsubtype'){
            if(this.credentialForm){
                this.credentialForm.patchValue({
                    account_subtype: value,
                })
            }
        }
        else if(controlName === 'access_artemis'){
            if(this.credentialForm){
                this.credentialForm.patchValue({
                    access_artemis:value,
                })
            }
        }
        else if(controlName === 'access_pbris'){
            if(this.credentialForm){
                this.credentialForm.patchValue({
                    access_pbris:value,
                })
            }
        }
    }

    getAgencyName(agencyId:any){
        const ctr = this.allAgencies.map((result:any)=>result.id).indexOf(agencyId)
        if(ctr >= 0){
            if(this.allAgencies[ctr].agencyDetails){
                if(this.allAgencies[ctr].agencyDetails.agencyName){
                    return this.allAgencies[ctr].agencyDetails.agencyName
                }
                else{
                    return this.allAgencies[ctr].name
                }
            }
            else if (this.allAgencies[ctr].name)
                return this.allAgencies[ctr].name
            else return "N/A"
        } else return "N/A"
    }

    getFileImage(uploadName:any){
        if(uploadName === 'gov_id'){
            if(this.govIdSrc) return this.govIdSrc;
            else if(this.fileForm.value.government_id) return this.checkLinkExpiry(this.fileForm.value.government_id,'gov_id');
            return "assets/GOP_Logo.png";
        }
        else if(uploadName === 'auth_letter'){
            if(this.authLetterSrc) return this.authLetterSrc;
            else if(this.fileForm.value.authorization_letter) return this.checkLinkExpiry(this.fileForm.value.authorization_letter,'auth_letter');
            return "../assets/GOP_Logo.png";
        }
        return "assets/GOP_Logo.png";
    }

    checkLinkExpiry(link:any,uploadName:any) {
        if(link){
            if(uploadName === 'gov_id'){
                if(link.trim().toLowerCase().includes("https://storage.googleapis.com")){
                    const linkExpiry:boolean = this.extractDateFromUrl(link)
                    if(linkExpiry) return link;
                    else return "assets/expired_gov_id.png";
                }
                else if(link.trim().toLowerCase().includes("https://firebasestorage.googleapis.com"))return link;
                return "assets/GOP_Logo.png"
            }else if(uploadName === 'auth_letter'){
                if(link.trim().toLowerCase().includes("https://storage.googleapis.com")){
                    const linkExpiry:boolean = this.extractDateFromUrl(link)
                    if(linkExpiry) return link
                    else return "./../../assets/expired_gov_id.png";
                }
                else if(link.trim().toLowerCase().includes("https://firebasestorage.googleapis.com")){
                    return link;
                }
                return "./../../assets/GOP_Logo.png"
            }
            return "assets/GOP_Logo.png"
        }
        return "assets/GOP_Logo.png"
    }

    checkAuthLetterFileType(type:any){
        if(this.authLetterType === type) return true
        return false
    }

    private extractDateFromUrl(link:any){
        const signedUrl = link;
        const urlHeaders:any = signedUrl.match(/X-Goog-Date=([^&]+)&X-Goog-Expires=([^&]+)/);
        const googleDate:string = urlHeaders[1];
        let gDate = new Date(googleDate.substr(0,4)+"-"+googleDate.substr(4,2)+"-"+googleDate.substr(6,2)+googleDate.substr(8,1)+googleDate.substr(9,2)+":"+googleDate.substr(11,2)+":"+googleDate.substr(13,2)+googleDate.substr(15,1));
        gDate.setSeconds(gDate.getSeconds() + parseInt(urlHeaders[2]))
        const currentDate = new Date()
        if(gDate>currentDate) return true; //link not expired
        else return false; //link expired
    }

    onFileChange(uploadName:any,event:any){
        if(event.target.files && event.target.files.length > 0){
            if(uploadName === 'gov_id'){
                const reader = new FileReader();
                const [file] = event.target.files;
                if(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'){
                    reader.readAsDataURL(file)
                    reader.onload = () =>{
                        this.govIdImage = event.target.files[0] as File
                        this.govIdName = event.target.files[0].name
                        this.govIdType = event.target.files[0].type
                        this.govIdSrc = reader.result as string;
                        this.fileForm.patchValue({
                            government_id_file_name:  event.target.files[0].name
                        }) 
                    }
                    reader.onerror = () =>{
                        alert("Error in uploading image")
                    }
                }
                else{
                    alert("Incorrect file type")
                }
            }
            else if(uploadName === 'auth_letter'){
                const reader = new FileReader();
                const [file] = event.target.files;
                if(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/png' || file.type === 'application/pdf'){
                    reader.readAsDataURL(file);
                    reader.onload = () =>{
                        this.fileBasePage = 1;
                        this.authLetterFile = event.target.files[0] as File
                        this.authLetterName = event.target.files[0].name
                        this.authLetterType = event.target.files[0].type
                        this.authLetterSrc = reader.result as string;
                        this.fileForm.patchValue({
                            authorization_letter_file_name:  event.target.files[0].name
                        })
                    }
                    reader.onerror = () =>{
                        alert("Error uoploading PDF")
                    }
                }
            }
        }
    }

    resetImg(uploadName:any){
        if(uploadName === 'gov_id'){
            this.govIdType = "";
            this.govIdImage = null;
            this.govIdSrc = "";
            this.govIdName = "";
        }
        else if(uploadName === 'auth_letter'){
            this.authLetterType = "";
            this.authLetterSrc = "";
            this.authLetterName = "";
            this.authLetterFile = null;
        }
        
    }

    toogleSlide(mainName:any,subName:any){
        this.settings[mainName][subName] = !this.settings[mainName][subName];
    }

    updateToggle(editName:any, btn:any){
        if(editName === 'personal'){
            if(btn === 'cancel'){
                this.patchValue('personal')
                this.personalEditForm = true
            }else{
                this.personalEditForm = false
            }
        }
        else if(editName === 'credential'){
            if(btn === 'cancel'){
                this.patchValue('credential')
                this.credentialEditForm = true
            }else{
                this.credentialEditForm = false
            }
        }
        else if(editName === 'government'){
            if(btn === 'cancel'){
                this.patchValue('government')
                this.governmentEditForm = true
            }else{
                this.governmentEditForm = false
            }
        }
        else if(editName === 'company'){
            if(btn === 'cancel'){
                this.patchValue('company')
                this.companyEditForm = true
            }else{
                this.companyEditForm = false
            }
        }
        else if(editName === 'file'){
            if(btn === 'cancel'){
                this.patchValue('file')
                this.fileEditForm = true
            }else{
                this.fileEditForm = false
            }
        }
        else if(editName === 'settings'){
            if(btn === 'cancel'){
                this.patchValue('settings')
                this.settingsEditForm = true
            }else{
                this.settingsEditForm = false
            }
        }
    }

    async submitUpdate(editName:any){
        this.formLoader = true;
        if(editName === 'personal'){
            this.validateField(editName)
            if(this.personalForm.valid){
                const personalData = this.personalForm.value;
                this.afs.collection('Users').doc(this.chosenUser.id).update({
                    personal: personalData
                })
                .then(()=>{
                    console.log("update successful")
                    this.chosenUser.personal = personalData;
                    const ctr = this.allUsers.map(res=>res.id).indexOf(this.chosenUser.id)
                    if(ctr>=0){
                        this.allUsers[ctr].personal=this.chosenUser.personal
                    }
                    this.updateToggle('personal', 'cancel');
                    this.formLoader = false;
                })
                .catch(err=>{
                    console.error("Error: ",err)
                    alert("Error Updating Personal Data")
                    this.formLoader = false;
                })
            }
            else{
                console.log("Error Form Invalid")
                this.formLoader = false;
            }
            
        }
        if(editName === 'credential'){
            this.validateField(editName)
            if(this.credentialForm.valid){
                const credentialData = this.credentialForm.value;
                this.afs.collection('Users').doc(this.chosenUser.id).update({
                    credentials: credentialData
                })
                .then(()=>{
                    console.log("update successful")
                    this.chosenUser.credentials = credentialData;
                    const ctr = this.allUsers.map(res=>res.id).indexOf(this.chosenUser.id)
                    if(ctr>=0){
                        this.allUsers[ctr].credentials=this.chosenUser.credentials
                    }
                    this.updateToggle('credential', 'cancel');
                    this.formLoader = false;
                })
                .catch(err=>{
                    console.error("Error: ",err)
                    alert("Error Updating Credential Data")
                    this.formLoader = false;
                })
            }
            else{
                console.log("Error Form Invalid")
                this.formLoader = false;
            }
        }
        if(editName === 'government'){
            this.validateField(editName)
            if(this.governmentForm.valid){
                const governmentData = this.governmentForm.value;
                this.afs.collection('Users').doc(this.chosenUser.id).update({
                    government: governmentData
                })
                .then(()=>{
                    console.log("update successful")
                    this.chosenUser.government = governmentData;
                    const ctr = this.allUsers.map(res=>res.id).indexOf(this.chosenUser.id)
                    if(ctr>=0){
                        this.allUsers[ctr].government=this.chosenUser.government
                    }
                    this.updateToggle('government', 'cancel');
                    this.formLoader = false;
                })
                .catch(err=>{
                    console.error("Error: ",err)
                    alert("Error Updating Government Data")
                    this.formLoader = false;
                })
            }
            else{
                console.log("Error Form Invalid")
                this.formLoader = false;
            }
        }
        if(editName === 'company'){
            this.validateField(editName)
            if(this.companyForm.valid){
                const companyData = this.companyForm.value;
                this.afs.collection('Users').doc(this.chosenUser.id).update({
                    company: companyData
                })
                .then(()=>{
                    console.log("update successful")
                    this.chosenUser.company = companyData;
                    const ctr = this.allUsers.map(res=>res.id).indexOf(this.chosenUser.id)
                    if(ctr>=0){
                        this.allUsers[ctr].company=this.chosenUser.company
                    }
                    this.updateToggle('company', 'cancel');
                    this.formLoader = false;
                })
                .catch(err=>{
                    console.error("Error: ",err)
                    alert("Error Updating Company Data")
                    this.formLoader = false;
                })
            }
            else{
                console.log("Error Form Invalid")
                this.formLoader = false;
            }
        }
        if(editName === 'file'){
            this.validateField(editName)
            if(this.fileForm.valid){
                const newGovIdSRC = await this.uploadingFile('gov_id')
                const newAuthFileSRC = await this.uploadingFile('auth_letter')
                if(newGovIdSRC && newAuthFileSRC){
                    this.fileForm.patchValue({
                        government_id: newGovIdSRC,
                        authorization_letter: newAuthFileSRC,
                    })
                }
                const fileData = this.fileForm.value
                this.afs.collection('Users').doc(this.chosenUser.id).update({
                    file: fileData
                })
                .then(()=>{
                    this.chosenUser.file = fileData;
                    const ctr = this.allUsers.map(res=>res.id).indexOf(this.chosenUser.id)
                    if(ctr>=0){
                        this.allUsers[ctr].file=this.chosenUser.file
                    }
                    this.updateToggle('file', 'cancel');
                    this.formLoader = false;
                })
                .catch(err=>{
                    alert("Error Updating File Data")
                    this.formLoader = false;
                })
            }
        }
        if(editName === 'settings'){
            const settingsData = this.settings;
            this.afs.collection('Users').doc(this.chosenUser.id).update({
                settings: settingsData
            })
            .then(()=>{
                console.log("update successful")
                this.chosenUser.settings = settingsData;
                const ctr = this.allUsers.map(res=>res.id).indexOf(this.chosenUser.id)
                    if(ctr>=0){
                        this.allUsers[ctr].settings=this.chosenUser.settings
                    }
                this.updateToggle('settings', 'cancel');
                this.formLoader = false;
            })
            .catch(err=>{
                console.error("Error: ",err)
                alert("Error Updating Settings Data")
                this.formLoader = false;
            })
        }
        else{
            this.formLoader = false
        }
    }

    async uploadingFile(uploadName:any){
        if(uploadName === 'gov_id'){
            if(this.govIdImage === null || this.govIdImage === undefined)
                return this.fileForm.value.government_id
            return await this.uploadFile('gov_id',this.fileForm.value,this.govIdImage)
        }
        else if(uploadName === 'auth_letter'){
            if(this.authLetterFile === null || this.authLetterFile === undefined)
                return this.fileForm.value.authorization_letter
            return await this.uploadFile('auth_letter',this.fileForm.value,this.authLetterFile)
        }
        return null;
    }

    uploadFile(uploadName:any,fileField:any, file:File){
        if(uploadName === 'gov_id'){
            if(this.chosenUser.file.government_id_from === 'storage'){
                const fileRef = this.chosenUser.file.government_id_gov_folder+"/"+this.chosenUser.file.government_id_gov_folder_user_type+"/"+this.chosenUser.file.government_id_registration_date+"/"+this.chosenUser.file.government_id_file_name
                this.store.ref(fileRef).delete()
                .subscribe(
                    ()=>{
                        console.log(uploadName,"deletion success")
                    },
                    error=>{
                        console.error(uploadName,"deletion error",error)
                    }
                )
            }
            else if(this.chosenUser.file.government_id_from === 'firestorage'){
                // const fileRef = this.chosenUser.file.government_id_gov_folder+"/"+this.chosenUser.file.government_id_registration_date+"/"+this.chosenUser.file.government_id_file_name
                this.store.refFromURL(this.chosenUser.file.government_id).delete()
                .subscribe(
                    ()=>{
                        console.log(uploadName,"deletion success")
                    },
                    error=>{
                        console.error(uploadName,"deletion error",error)
                    }
                )
            }


            const logoPath = "government-id"
            const timeFolder = fileField.government_id_registration_date;
            const fileName = fileField.government_id_file_name;
            const filePath = logoPath+"/"+timeFolder+"/"+fileName;
            return this.store.upload(filePath,file)
            .then(res=>{
                return res.ref.getDownloadURL()
            })
            .catch(rej=>{
                return this.chosenUser.file.government_id
            })
        }
        else if(uploadName === 'auth_letter'){
            if(this.chosenUser.file.authorization_letter_from === 'storage'){
                const fileRef = this.chosenUser.file.authorization_letter_gov_folder+"/"+this.chosenUser.file.authorization_letter_gov_folder_user_type+"/"+this.chosenUser.file.authorization_letter_registration_date+"/"+this.chosenUser.file.authorization_letter_file_name
                this.store.ref(fileRef).delete()
                .subscribe(
                    ()=>{
                        console.log(uploadName,"deletion success")
                    },
                    error=>{
                        console.error(uploadName,"deletion error",error)
                    }
                )
            }
            else if(this.chosenUser.file.authorization_letter_from === 'firestorage'){
                // const fileRef = this.chosenUser.file.authorization_letter_gov_folder+"/"+this.chosenUser.file.authorization_letter_registration_date+"/"+this.chosenUser.file.authorization_letter_file_name
                // this.store.ref(fileRef).delete()
                this.store.refFromURL(this.chosenUser.file.authorization_letter).delete()
                .subscribe(
                    ()=>{
                        console.log(uploadName,"deletion success")
                    },
                    error=>{
                        console.error(uploadName,"deletion error",error)
                    }
                )
            }


            const logoPath = "auth-letter"
            const timeFolder = fileField.authorization_letter_registration_date;
            const fileName = fileField.authorization_letter_file_name;
            const filePath = logoPath+"/"+timeFolder+"/"+fileName;
            return this.store.upload(filePath,file)
            .then(res=>{
                return res.ref.getDownloadURL()
            })
            .catch(rej=>{
                return this.chosenUser.file.authorization_letter
            })
        }
        return false;
    }

    validateField(editName:any){
        if(editName === 'personal'){
            if (!this.personalForm.valid) {
                // Mark the form and inputs as touched so the errors messages are shown
                this.personalForm.markAsTouched();
                for (const control in this.personalForm.controls) {
                    if (this.personalForm.controls.hasOwnProperty(control)) {
                        this.personalForm.controls[control].markAllAsTouched();
                        this.personalForm.controls[control].markAsDirty();
                    }
                }
            }
        }
        else if(editName === 'credential'){
            if (!this.credentialForm.valid) {
                // Mark the form and inputs as touched so the errors messages are shown
                this.credentialForm.markAsTouched();
                for (const control in this.credentialForm.controls) {
                    if (this.credentialForm.controls.hasOwnProperty(control)) {
                        this.credentialForm.controls[control].markAllAsTouched();
                        this.credentialForm.controls[control].markAsDirty();
                    }
                }
            }
        }
        else if(editName === 'government'){
            if (!this.governmentForm.valid) {
                // Mark the form and inputs as touched so the errors messages are shown
                this.governmentForm.markAsTouched();
                for (const control in this.governmentForm.controls) {
                    if (this.governmentForm.controls.hasOwnProperty(control)) {
                        this.governmentForm.controls[control].markAllAsTouched();
                        this.governmentForm.controls[control].markAsDirty();
                    }
                }
            }
        }
        else if(editName === 'company'){
            if (!this.companyForm.valid) {
                // Mark the form and inputs as touched so the errors messages are shown
                this.companyForm.markAsTouched();
                for (const control in this.companyForm.controls) {
                    if (this.companyForm.controls.hasOwnProperty(control)) {
                        this.companyForm.controls[control].markAllAsTouched();
                        this.companyForm.controls[control].markAsDirty();
                    }
                }
            }
        }
        else if(editName === 'file'){
            if (!this.fileForm.valid) {
                // Mark the form and inputs as touched so the errors messages are shown
                this.fileForm.markAsTouched();
                for (const control in this.fileForm.controls) {
                    if (this.fileForm.controls.hasOwnProperty(control)) {
                        this.fileForm.controls[control].markAllAsTouched();
                        this.fileForm.controls[control].markAsDirty();
                    }
                }
            }
        }
    }

    pageShift(action:any,section:any){
        if(section == 'baseFile'){
            if(action == "next"){
                if(this.fileBasePage < this.fileBaseTotalPage)
                    this.fileBasePage++;
            }
            if(action == "back"){
                if(this.fileBasePage > 1)
                    this.fileBasePage--;
            }
        }
        // else if(section == 'newFile'){
        //     if(action == "next"){
        //         if(this.fileNewPage < this.fileNewTotalPage)
        //             this.fileNewPage++;
        //     }
        //     if(action == "back"){
        //         if(this.fileNewPage > 1)
        //             this.fileNewPage--;
        //     }
        // }
    }

    afterLoadComplete(pdf: PDFDocumentProxy,section:any){
        if(section === 'baseFile') this.fileBaseTotalPage = pdf.numPages; 
        // else if(section === 'newFile') this.fileNewTotalPage = pdf.numPages; 
    }
    
}