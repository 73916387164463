  import { PdfRenderService } from 'src/app/pdf-render.service';
  import { Component, OnChanges, Input, OnInit, SimpleChanges } from '@angular/core';
  import { AngularFirestore } from '@angular/fire/firestore';
  import { PDFDocumentProxy } from 'pdfjs-dist';
  import { AngularFireStorage } from '@angular/fire/storage';
    // import { ActivatedRoute, Router } from '@angular/router';
    // import { AngularFireAuth } from '@angular/fire/auth';
    // import { PdfRenderService } from '../../pdf-render.service';
    // import { format } from 'date-fns';
    // import firebase from "firebase/app";
    // import Swal from 'sweetalert2';

    @Component({
    selector: 'app-regulations-pia-view',
    templateUrl: './regulations-pia-view.component.html',
    styleUrls: ['./regulations-pia-view.component.scss']
    })
    export class RegulationsPiaViewComponent implements OnInit, OnChanges {

    @Input() id:any;
    @Input() regulation:any;

    isLoaded:boolean = false;


    constructor(
        private afs: AngularFirestore,
        private pdf_render: PdfRenderService,
        private store: AngularFireStorage,
    ) { }

    ngOnInit(): void {
      this.loadTabInfo(this.regulation)
      this.isLoaded=true;
    }

    // ngOnChanges(): void {
    //   if(this.regulation){
    //     this.isLoaded=true;
    //     // console.log('regulation: ', this.regulation)
    //     this.loadTabInfo(this.regulation)
    //   }
    // }
    ngOnChanges(changes: SimpleChanges): void {
        if(changes.regulation.currentValue){
          this.regulation = changes.regulation.currentValue
          this.loadTabInfo(this.regulation)
          this.isLoaded=true;
        }
    }

  loadTabInfo(regulation:any){
    if(regulation.pia_sections.pia_section_1.policy_problem_file_folder && regulation.pia_sections.pia_section_1.policy_problem_file_name){
      regulation.pia_sections.pia_section_1.fileFolder = regulation.pia_sections.pia_section_1.policy_problem_file_folder
      regulation.pia_sections.pia_section_1.fileName = regulation.pia_sections.pia_section_1.policy_problem_file_name
      const path = `${regulation.pia_sections.pia_section_1.policy_problem_file_folder}/${regulation.pia_sections.pia_section_1.policy_problem_file_name}`;
      let tokenized = regulation.pia_sections.pia_section_1.policy_problem_file_name.split(".")
      if(tokenized && tokenized.length > 0 && tokenized[tokenized.length-1] == 'zip'){
        regulation.pia_sections.pia_section_1.file_type = 'zip';
        this.store.ref(path).getDownloadURL()
        .subscribe((url)=>{
          regulation.pia_sections.pia_section_1.file_download_link = url
        })
      }
      else{
        this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
          regulation.pia_sections.pia_section_1.file = data;
          regulation.pia_sections.pia_section_1.file_page = 1; 
        });
      }
    }
      
    for(let pia4Ctr in regulation.pia_sections.pia_section_4){
      // item.pia_section_4[pia4Ctr]
      // console.log(regulation.pia_sections.pia_section_4[pia4Ctr])
      if(regulation.pia_sections.pia_section_4[pia4Ctr].compliance_file_name && regulation.pia_sections.pia_section_4[pia4Ctr].compliance_file_folder){
        regulation.pia_sections.pia_section_4[pia4Ctr].fileFolder = regulation.pia_sections.pia_section_4[pia4Ctr].compliance_file_folder
        regulation.pia_sections.pia_section_4[pia4Ctr].fileName = regulation.pia_sections.pia_section_4[pia4Ctr].compliance_file_name
        const path = regulation.pia_sections.pia_section_4[pia4Ctr].compliance_file_folder+"/"+regulation.pia_sections.pia_section_4[pia4Ctr].compliance_file_name

        this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
          regulation.pia_sections.pia_section_4[pia4Ctr].file = data;
          regulation.pia_sections.pia_section_4[pia4Ctr].file_page = 1
        });
      }
    }

    if(regulation.pia_sections.pia_section_4_file){
      regulation.pia_sections.pia_section_4_file.fileFolder = regulation.pia_sections.pia_section_4_file.compliance_file_folder
      regulation.pia_sections.pia_section_4_file.fileName = regulation.pia_sections.pia_section_4_file.compliance_file_name
      let tokenized = regulation.pia_sections.pia_section_4_file.compliance_file_name.split(".")
      const path = `${regulation.pia_sections.pia_section_4_file.compliance_file_folder}/${regulation.pia_sections.pia_section_4_file.compliance_file_name}`;
      if(tokenized && tokenized.length > 0 && tokenized[tokenized.length-1] == 'zip'){
        regulation.pia_sections.pia_sections.pia_section_4_file.file_type = 'zip'
        this.store.ref(path).getDownloadURL()
        .subscribe((url)=>{
          regulation.pia_sections.pia_section_4_file.file_download_link = url
        })
      }else{
        this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
          regulation.pia_sections.pia_section_4_file.file = data;
          regulation.pia_sections.pia_section_4_file.file_page = 1; 
        });
      }
    }


    if(regulation.pia_sections.pia_section_6.consultation_and_recommendation_file_folder && regulation.pia_sections.pia_section_6.consultation_and_recommendation_file_name){
      regulation.pia_sections.pia_section_6.fileFolder = regulation.pia_sections.pia_section_6.consultation_and_recommendation_file_folder
      regulation.pia_sections.pia_section_6.fileName = regulation.pia_sections.pia_section_6.consultation_and_recommendation_file_name
      const path = `${regulation.pia_sections.pia_section_6.consultation_and_recommendation_file_folder}/${regulation.pia_sections.pia_section_6.consultation_and_recommendation_file_name}`;
      let tokenized = regulation.pia_sections.pia_section_6.consultation_and_recommendation_file_name.split(".")
      if(tokenized && tokenized.length > 0 && tokenized[tokenized.length-1] == 'zip'){
        regulation.pia_sections.pia_section_6.file_type = 'zip'
        this.store.ref(path).getDownloadURL()
        .subscribe((url)=>{
          regulation.pia_sections.pia_section_6.file_download_link = url
        })
      }else{
        this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
          regulation.pia_sections.pia_section_6.file = data;
          regulation.pia_sections.pia_section_6.file_page = 1; 
        });
      }
    }
    // console.log(regulation.pia_sections)
  }

  // pageShift(action:any,section:any,index?:any){
  //   if(section == 'pia1'){
  //     if(action == "next"){
  //       if(this.regulation.pia_section_1.policy_problem_file_page < this.regulation.pia_section_1.policy_problem_file_total_page)
  //         this.regulation.pia_section_1.policy_problem_file_page++;
  //     }
  //     if(action == "back"){
  //       if(this.regulation.pia_section_1.policy_problem_file_page > 1)
  //         this.regulation.pia_section_1.policy_problem_file_page--;
  //     }
  //   }
  //   else if(section == 'pia4'){
  //     if(action == "next"){
  //       if(this.regulation.pia_section_4[index].compliance_file_page < this.regulation.pia_section_4[index].compliance_file_total_page)
  //         this.regulation.pia_section_4[index].compliance_file_page++;
  //     }
  //     if(action == "back"){
  //       if(this.regulation.pia_section_4[index].compliance_file_page > 1)
  //         this.regulation.pia_section_4[index].compliance_file_page--;
  //     }
  //   }
  //   else if(section == 'pia6'){
  //     if(action == "next"){
  //       if(this.regulation.pia_section_6.consultation_and_recommendation_file_page < this.regulation.pia_section_6.consultation_and_recommendation_file_total_page)
  //         this.regulation.pia_section_6.consultation_and_recommendation_file_page++;
  //     }
  //     if(action == "back"){
  //       if(this.regulation.pia_section_6.consultation_and_recommendation_file_page > 1)
  //         this.regulation.pia_section_6.consultation_and_recommendation_file_page--;
  //     }
  //   }
    
  // }

  // afterLoadComplete(pdf: PDFDocumentProxy,section:any,index?:any){
  //   if(section === 'pia1'){
  //     this.regulation.pia_section_1.policy_problem_file_total_page = pdf.numPages;
  //   }
  //   else if(section === 'pia4'){
  //     this.regulation.pia_section_4[index].compliance_file_total_page = pdf.numPages;
  //   }
  //   else if(section === 'pia6'){
  //     this.regulation.pia_section_6.consultation_and_recommendation_file_total_page = pdf.numPages;
  //   }
  // }


  downloadFile(downloadLink:any){
    // var xhr = new XMLHttpRequest();
    // xhr.responseType = 'blob';
    // xhr.onload = (event) => {
    //   var blob = xhr.response;
    // };
    // xhr.onerror = (event) =>{
    //   console.error('Error: ',event)
    // }
    // xhr.open('GET', downloadLink);
    // xhr.send();

    // const link = document.createElement(section);
    // link.setAttribute('target', '_blank');
    // link.setAttribute('href', downloadLink);
    // link.setAttribute('download', fileName);
    // document.body.appendChild(link);
    // link.click();
    // link.remove();

    window.open(downloadLink, '_blank')
  }
}
