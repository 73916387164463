import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import firebase from "firebase/app";
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';
import { PdfRenderService } from 'src/app/pdf-render.service';

@Component({
    selector: 'app-proposed-text-regulation-view',
    templateUrl: './proposed-text-regulation-view.component.html',
    styleUrls: ['./proposed-text-regulation-view.component.scss'],
})
export class ProposedTextRegulationView implements OnInit, OnChanges {

    @Input() textRegulation:any;
    @Input() commentButtonsEnabled:any;
    @Input() paramFromInboxList:any;
    @Input() codeBase:string;

    reg_text:Array<any>;
    reg_text_annex_file:any;
    id:any;

    reg_text_annex_pagging:any = {
      annex_file_total_page:1,
      annex_file_page:1
    };

    constructor(
        private router: Router,
        private store: AngularFireStorage,
        private pdf_render: PdfRenderService,
    ) {}

    ngOnInit(): void {
      this.checkData()
    }

    ngOnChanges(changes: SimpleChanges): void {
      if(changes.textRegulation.currentValue){
        this.checkData()
      }
    }

    checkData(){
      if(this.textRegulation){
        this.id = this.textRegulation.id
        this.reg_text = this.textRegulation.regulation_text
        this.reg_text_annex_file = this.textRegulation.regulation_text_annex_file

        if(this.reg_text && this.reg_text_annex_file){
          
          this.reg_text_annex_file = {
            regulation_text_annex_file_folder:this.reg_text_annex_file.regulation_text_annex_file_folder,
            regulation_text_annex_file_name:this.reg_text_annex_file.regulation_text_annex_file_name,
          }

          let tokenized = this.reg_text_annex_file.regulation_text_annex_file_name.split(".")
          if(tokenized && tokenized.length > 0 && tokenized[tokenized.length-1] == 'zip'){
            this.reg_text_annex_file.annex_file_type = 'zip';
            this.store.ref(`${this.reg_text_annex_file.regulation_text_annex_file_folder}/${this.reg_text_annex_file.regulation_text_annex_file_name}`).getDownloadURL()
            .subscribe((url)=>{
              this.reg_text_annex_file.annex_file_download_link = url
            })
          } else{
            const path = `${this.reg_text_annex_file.regulation_text_annex_file_folder}/${this.reg_text_annex_file.regulation_text_annex_file_name}`;
  
            this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
              this.reg_text_annex_file.annex_file = data;
              this.reg_text_annex_file.annex_file_page = 1; 
            });
          }
        }
      }
    }

    goToComments(type: string, sectionId?: number, subsectionId?: number) {
      let queryParams: any = { worklistId: this.id, type: type };
      
      if(sectionId != null) {
        queryParams.sectionId = sectionId;
      }
  
      if(subsectionId != null) {
        queryParams.subsectionId = subsectionId;
      }

      switch(this.codeBase){
        case 'INITIAL':
          console.log('INITIAL comment text reg')
          this.router.navigate(
              ['/pbris/upload/proposed/comment/' + this.paramFromInboxList],
              { queryParams: queryParams }
            );
        break;
        case 'CRIS':
          console.log('CRIS comment text reg')

        break;
        case 'DRIS':
          console.log('DRIS comment text reg')

        break;
        default:
          console.error('Error: unkown status')
        break;
      }
      
      // if(sectionId != null) {
      //   queryParams.sectionId = sectionId;
      // }
  
      // if(subsectionId != null) {
      //   queryParams.subsectionId = subsectionId;
      // }
      
      // this.router.navigate(
      //   ['/pbris/upload/proposed/comment/' + this.paramFromInboxList],
      //   { queryParams: queryParams }
      // );
    }

    pageShift(action:any,section:any,index?:any){
      if(section == 'reg_text_annex'){
        if(action == "next"){
          if(this.reg_text_annex_pagging.annex_file_page < this.reg_text_annex_pagging.annex_file_total_page)
            this.reg_text_annex_pagging.annex_file_page++;
        }
        if(action == "back"){
          if(this.reg_text_annex_pagging.annex_file_page > 1)
            this.reg_text_annex_pagging.annex_file_page--;
        }
      }
    }
  
    afterLoadComplete(pdf: PDFDocumentProxy,section:any,index?:any){
      if(section === 'reg_text_annex'){
        this.reg_text_annex_pagging.annex_file_total_page = pdf.numPages;
      }
    }
  
    downloadFile(downloadLink:any, fileName:string, section:string){
      window.open(downloadLink, '_blank')
    }
}