import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AgencyRegisterMemberComponent } from '../agency-register-member/agency-register-member.component';

@Component({
  selector: 'app-agency-register-member-modal',
  templateUrl: './agency-register-member-modal.component.html',
  styleUrls: ['./agency-register-member-modal.component.scss']
})
export class AgencyRegisterMemberModalComponent{ //implements OnInit

  @ViewChild(AgencyRegisterMemberComponent) agencyRegisterMemberComponent:AgencyRegisterMemberComponent;

  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AgencyRegisterMemberModalComponent>
  ){}

  // ngOnInit(){
  // }
  
  register(){
    //console.log('AgencyRegisterMemberModalComponent Register')
    this.agencyRegisterMemberComponent.registerByModal()
  }

  buttonLoad(loading:boolean){
    //console.log('buttonLoad: ',loading)
    this.loading = loading
  }

  close(){
    this.dialogRef.close()
  }
}