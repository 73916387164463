import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-regulatory-stock',
  templateUrl: './regulatory-stock.component.html',
  styleUrls: ['./regulatory-stock.component.scss']
})
export class RegulatoryStockComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
