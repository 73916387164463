<td scope="row">
    {{index+1}}.{{requirement.requirement}} 
    <div [ngSwitch]="requirement.requirementType">
        <div *ngSwitchCase="'Sample'">({{ requirement.requirementType }}: {{requirement.number}} {{requirement.unit}}) </div>
        <div *ngSwitchCase="'Documentary'" [innerHtml]="requirement.documentString"></div>
        <div *ngSwitchCase="'Id'" [innerHtml]="requirement.listOfIds"></div>
    </div>

</td>

<td>
    <div *ngIf="!(requirement.requirementType == 'Id')">
        <p *ngIf="!requirement?.applicant"><span class="req-details-header">Agency - Division: </span>{{ requirement.agency }} - {{ requirement.bureau_division }}</p>
        <p *ngIf="requirement?.applicant">Applicant / Client</p>
    </div>
    <div *ngIf="requirement.remarks_or_reminders != ''">
        <p  class="req-details-header">Remarks:</p>
        <p [innerHTML]="requirement.remarks_or_reminders"></p>
    </div>

</td>