import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-regulation-view-proposed-final-reg',
    templateUrl: './regulation-view-proposed-final-reg.component.html',
    styleUrls: ['./regulation-view-proposed-final-reg.component.scss'],
})
export class RegulationViewProposedFinalRegComponent implements OnInit {
    constructor(
    ) {}

    ngOnInit(): void {
    }
}