import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-proposed-summary-view',
    templateUrl: './proposed-summary-view.component.html',
    styleUrls: ['./proposed-summary-view.component.scss'],
})
export class ProposedSummaryView implements OnInit, OnChanges {
    
    @Input() codeBase:string;
    @Input() objectData:any;

    summaryData:any = {};

    constructor()
    {}

    ngOnInit(): void {
        if(this.objectData){
            this.summaryData = this.objectData
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.objectData.currentValue) this.summaryData = changes.objectData.currentValue
    }
}