import { Injectable } from '@angular/core';
import { inherits } from 'util';
import { NewRequirementsModalComponent } from '../artemis/artemis-inbox-page/artemis-new-charter/new-service-modal/new-requirements-modal/new-requirements-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

export interface RequirementModel{
  agency: any;
  bureau_division: any;
  copyFormat: any;
  documentString: any;
  remarks_or_reminders: any;
  requirement: any;
  requirementType: any;
  listOfIds : any;
  unit : any
  number : any;
  subRequirement?: Array<RequirementwithTitle> | []
  
}

export interface RequirementWithSubrequirement {
  requirement : RequirementModel
  subRequirement?:RequirementwithTitle[]
}

export interface RequirementwithTitle {
 
  title?: String | ""; //situation title
  requirement?: Array<RequirementModel>; // standard requirement
  chooseany?:boolean;  //client can choose any of the ff requirement

}

export interface SituationalWithSubRequirement extends RequirementwithTitle{
  subRequirement?: RequirementwithTitle[]
}

export enum RequirementType{
  STANDARD_NO_TITLE  = 0,
  STNDARD_WITH_TITLE = 1,
  SITUATIONAL_REQUIREMENT = 3,
  SUB_SITUATIONAL_REQUIREMENT =4,
  STANDARD_WITH_SUB_REQUIREMENT =5
}




@Injectable({
  providedIn: 'root'
})
export class RequriementsService {

  constructor(
    private dialog: MatDialog
  ) { }


   //pre precessed/format requirements before loading to UI
   public preProcessStandardRequirements(standardRequirements: any) {
    if(standardRequirements && standardRequirements.length > 0){
      standardRequirements.map((docItem:any)=>{
        switch(docItem.requirementType){
          case 'Documentary':{
            if(docItem.documentFormat && docItem.documentFormat.length > 0){
              docItem.documentString = ''
              docItem.documentFormat.map((formatItem:any,index:any)=>{
                switch(formatItem.type){
                  case 'document':{
                    if(index > 0) docItem.documentString += ' '
                    switch(formatItem.value){
                      case 'Original Copy':{
                        docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.originalCopy ? docItem.originalCopy : 'Undefined')+') '+formatItem.value
                        break
                      }
                      case 'Photo Copy':{
                        docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.photoCopy ? docItem.photoCopy : 'Undefined')+') '+formatItem.value
                        break
                      }
                      case 'Electronic Copy':{
                        docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.electronicCopy ? docItem.electronicCopy : 'Undefined')+') '+formatItem.value
                        break
                      }
                      case 'Certified True Copy':{
                        docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.certifiedTrueCopy ? docItem.certifiedTrueCopy : 'Undefined')+') '+formatItem.value
                        break
                      }
                    }
                    break;
                  }
                  case 'operator':{
                    switch(formatItem.value){
                      case 'or':{
                        docItem.documentString +=formatItem.value
                        break
                      }
                      default:{
                        if(index > 0) docItem.documentString += ' '
                        docItem.documentString += formatItem.value
                      }
                    }
                    break;
                  }
                  default:{
                    if(index > 0) docItem.documentString += ' '
                    docItem.documentString += formatItem.value
                  }
                }
              })
            }
            break;
          }
        }
     
      })

    }
    return standardRequirements
  }

  preProcessMandatorySituationalREquirements(requirement:any ){
    let temp: any = []

    for (var req of requirement){
      temp.push({
        requirement
      })
    }
  }

   //to make sure that sub requirements are pre processed/formatted
   preprocessSubSituationalRequriements(subrequirement: any){

    let tempsubreq =[]

    for (var req of subrequirement){

      tempsubreq.push({
        title: req.title,
        requirement: this.preProcessStandardRequirements(req.requirement),

      })

    }

    return tempsubreq;

  }


  /**
   * ,situationIndex: number,removeSituationFrom: any
   * @param sourceArray
   * Source array
   *  
   * @param requirementIndex
   * requirement index 
   */

  removeRequirement(sourceArray:any , requirementIndex: number){
    sourceArray.splice(requirementIndex,1)

    //if requirements inside situational requirements
    //are all removed, then also remove the situation
    /**
     * if(array.length <= 0 ){
      removeSituationFrom.splice(situationIndex,1)
    }
     */
  }


  pushSubSituational(array:any, data:any){
    array.push({
      title: data.title,
      requirement: data.requirement,
    })
  }

  pushSituational(array:any,data:any){
    array.push({
      title: data.title,
      requirement: data.requirement,
      subRequirement: data.subRequirement || []
    })

    //console.log('Situational Array',array)
    //return array

  }

  

  addDatatoArray(destination:[],array2:[]){

    array2.forEach(data=>{

      destination.push(data)
    
    })

  }


  checkClipboard(type:any) : boolean {
    if(sessionStorage.getItem(type)) return true
    else return false
  }


  duplicate(object: any ,destinationArray: Array<any>){

    let copy = JSON.stringify(object)
    destinationArray.push(
      JSON.parse(copy)
    )

  }
  
  //copy requirement object to the destination array
  //we can copy whole situation, single requirement or whole sub situation object
  copyRequirement(object: any ,type: any){
    let copy = JSON.stringify(object)
    sessionStorage.setItem(type,copy)
    //destinationArray.push(copy)
  }

  pasteRequirement(destinationArray: Array<any>,type: any,index : number = 0){
    let refcopy:any = sessionStorage.getItem(type)
    //let copy = JSON.stringify(refcopy)
  
    destinationArray.splice(index,0,
      JSON.parse(refcopy)
    )
   /**
    *  destinationArray.push(
      JSON.parse(refcopy))
    */

      sessionStorage.removeItem(type)

  }

  //remove requirement on clipboard
  cancelcopyRequirement(type:any){
    sessionStorage.removeItem(type)
  }


  scrollto(id:any){
    const doc = document.getElementById(id);
      if (doc) {
        doc.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
  }


  alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
  printToLetter(number:number){
   let result = ''
   let charIndex = number % this.alphabet.length
   let quotient = number/this.alphabet.length
   if(charIndex-1 == -1){
       charIndex = this.alphabet.length
       quotient--;
   }
   result =  this.alphabet.charAt(charIndex-1) + result;
   if(quotient>=1){
      result = result + this.printToLetter(quotient);
   }
   return result


   
}

updateSituationalRequirement(situation:any, situationIndex: number, referenceArray:any,type: RequirementType){

  let result = this.dialog
  .open(NewRequirementsModalComponent, {
    width: '98vw',
    minHeight:'20vh',
    disableClose: true,
    data: {
      update : true,
      isSituational: true,
      requirementIndex: 0,
      type: type
    },
  })
  result.afterClosed().subscribe((data:any)=>{
    
   if(data.submitFlag){
    
    const subrequirement =  situation.subRequirement? situation.subRequirement : []

    data.subRequirement = subrequirement
    referenceArray[situationIndex] = data
    this.scrollto('situational_' + situationIndex)
   }
  })

}


newStandardRequirement(referenceArray: Array<any>,requirement_type :RequirementType,index : number = 0){

  let requirement  = this.dialog.open(
    NewRequirementsModalComponent,{
      width: '98vw',
      minHeight:'20vh',
      disableClose: true,
      data: {
        type:requirement_type,
        requirementIndex: index
      }
    }
  )

  requirement.afterClosed().subscribe((result : any) => {

    if(result.submitFlag){

      if(result.chooseany){
        let standard_requirement : RequirementwithTitle = {
          title : result.title,
          chooseany : result.chooseany,
          requirement: result.requirement
        }
        referenceArray.push(standard_requirement)
      }else{
        
        for(var model of result.requirement){
          this.addBasicRequirement(model, referenceArray)
        }

      }

      /**
       * 
       * switch(requirement_type){
        case RequirementType.STANDARD_NO_TITLE:
          for(var model of result.requirement){
            this.addBasicRequirement(model, referenceArray)
          }
          break;
        case RequirementType.STNDARD_WITH_TITLE:
          let standard_requirement : RequirementwithTitle = {
            title : result.title,
            chooseany : result.chooseany,
            requirement: result.requirement
          }
          referenceArray.push(standard_requirement)
          break;

        case RequirementType.SITUATIONAL_REQUIREMENT:
          let situational_req : any = {
            title : result.title,
            chooseany : result.chooseany,
            requirement: result.requirement,
            subRequirement: result?.subRequirement || []
          }
          referenceArray.push(situational_req)
          break;
      }
       */

     

    }

  });

}

updateRequirementWithTitle(referenceArray : Array<any>,situation: any , situationIndex: number,requirementIndex: number){
  let requirement = this.dialog
  .open(NewRequirementsModalComponent,{
    width: '98vw',
    minHeight:'20vh',
    disableClose: true,
    data: {
      type:  RequirementType.STNDARD_WITH_TITLE,
      update : true ,
      requirement: situation,
      requirementIndex: requirementIndex
    },
  });

  requirement.afterClosed().subscribe((result:any)=>{
      let updated_model: RequirementwithTitle = {
        title : result.title,
        requirement : result.requirement,
        chooseany: result.chooseany
      }

      referenceArray[situationIndex] = updated_model
  })
}

updateRequirementObjectWithSubRequirement(referenceArray: Array<any>,requirement_object : any,index : number){
  let requirement = this.dialog
  .open(NewRequirementsModalComponent, {
    width: '98vw',
    minHeight:'20vh',
    disableClose: true,
    data: {
      type:  RequirementType.STANDARD_WITH_SUB_REQUIREMENT,
      update : true ,
      requirement: requirement_object,
      requirementIndex: 0
    },
  });

  requirement.afterClosed().subscribe((result:any)=>{

    let updated_model : RequirementWithSubrequirement = {
      requirement : result.requirement[0],
      subRequirement : requirement_object.subRequirement
    }
    
    referenceArray[index] = updated_model

  })
}

updateRequirementObject(referenceArray: Array<any>,requirement_object : any,index : number){

  let requirement = this.dialog
  .open(NewRequirementsModalComponent, {
    width: '98vw',
    minHeight:'20vh',
    disableClose: true,
    data: {
      type:  RequirementType.STANDARD_NO_TITLE,
      update : true ,
      requirement: requirement_object,
      requirementIndex: 0
    },
  })

  requirement.afterClosed().subscribe((result : any) => {

    if(result.submitFlag){

      let updated_model : RequirementModel = result.requirement[0]
      
      referenceArray[index] = updated_model
     
    }

  })
}


//for standard requirement with title above and choose any of the following
addStandardRequirement(requirement: RequirementModel,destinationArray: Array<any> = []){
  destinationArray.push(requirement)
}

//for standard requirement with no situational requirement below
//use as standard requirement for sub situation only
addBasicRequirement(requirement: RequirementModel,destinationArray: Array<any> = []){
  destinationArray.push(requirement)
}

//for basic requirement with situational requirement below
//use for requirement without title above
addBasicSubRequirement(requirement : RequirementWithSubrequirement,destinationArray: Array<any> = []){
  destinationArray.push(requirement)
}

//addsituationalRequirement
addSituationalRequirement(requirement : SituationalWithSubRequirement,destinationArray: Array<any> = [] ){
  destinationArray.push(requirement)
}

//update any type of requirement
updateRequirement(requirement : any,index : number,destinationArray :Array<any> = []){
  destinationArray[index] = requirement
}




}
