<app-navbar [currentPage]="'Survey'"></app-navbar>

<div class="container">
  <div class="mt-2">
    <h1 class="text-primary">
      How was your experience? *
    </h1>
  </div>
  <hr />
  <div class="text-muted">
    Thank you for using the PBRIS! We are constantly working to improve our services. Please help us by sharing your overall experience in using the site. Your feedback will be anonymous and be processed in accordance with the Data Privacy Act of 2012.
  </div>
  <div class="row my-5 form-group">
    <div class="col-12 col-sm-6 mb-2">
      <div class="mb-2 font-weight-bold form-label">
        Feature Accessed
      </div>
      <div class="dropdown col-12 col-sm-8">
        <select class="form-select" aria-label="Default select example">
          <option selected></option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>
      </div>
    </div>
    <div class="col-12 col-sm-6">
      <div class="mb-2 font-weight-bold form-label">
        Please rate your overall experience *
      </div>
      <div>
        <p>
          <span class="mx-1" data-rating="1">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#1266f1" class="bi bi-star" viewBox="0 0 16 16">
              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
            </svg>
          </span>
          <span class="mx-1" data-rating="2">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#1266f1" class="bi bi-star" viewBox="0 0 16 16">
              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
            </svg>
          </span>
          <span class="mx-1" data-rating="3">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#1266f1" class="bi bi-star" viewBox="0 0 16 16">
              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
            </svg>
          </span>
          <span class="mx-1" data-rating="4">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#1266f1" class="bi bi-star" viewBox="0 0 16 16">
              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
            </svg>
          </span>
          <span class="mx-1" data-rating="5">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#1266f1" class="bi bi-star" viewBox="0 0 16 16">
              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
            </svg>
          </span>
        </p>
      </div>
      <div class="mb-2 font-italic text-muted">
        Needs Improvements
      </div>
    </div>
  </div>
  <div class="my-5 form-group">
    <div class="col-12 col-md-6 mb-5">
      <label for="elaborateFormControlTextarea1" class="form-label mb-2 font-weight-bold">
        Please elaborate on your experience *
      </label>
      <textarea class="form-control col-12 border border-top-0 border-start-0 border-end-0 border-bottom-1" id="elaborateFormControlTextarea1" rows="1" placeholder="Comments"></textarea>
    </div>
    <div class="col-12 col-md-6 mb-3">
      <label for="improveFormControlTextarea1" class="form-label mb-2 font-weight-bold">
        My experience was satisfactory but could be further improved by:*
      </label>
      <textarea class="form-control col-12 border border-top-0 border-start-0 border-end-0 border-bottom-1" id="improveFormControlTextarea1" rows="1" placeholder="Comments"></textarea>
    </div>
  </div>
  <div class="my-5 col-12 col-md-8 form-group">
    <div class="form-check">
      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
      <label class="form-check-label font-weight-bold text-muted mx-2" for="flexRadioDefault1">
        My experience was unsatisfactory as the PBRIS 
      </label>
      <div class="row col-md-12">
        <div class="col-12 col-md-6 mt-2">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="test1" id="test1">
            <label class="form-check-label font-weight-bold text-muted mx-2" for="test1">
              Did not have the information I need
            </label>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-2">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="test2" id="test2">
            <label class="form-check-label font-weight-bold text-muted mx-2" for="test2">
              Navigation was too confusing
            </label>
          </div>
        </div>
      </div>
      <div class="row col-md-12">
        <div class="col-12 col-md-6 mt-2">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="test3" id="test3">
            <label class="form-check-label font-weight-bold text-muted mx-2" for="test3">
              Had too much information
            </label>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-2">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="test4" id="test4">
            <label class="form-check-label font-weight-bold text-muted mx-2" for="test4">
              Service/s is/are out of date
            </label>
          </div>
        </div>
      </div>
      <div class="row col-md-12">
        <div class="col-12 col-md-8 mt-2">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="test5" id="test5">
            <label class="form-check-label font-weight-bold text-muted mx-2" for="test5">
              Other
            </label>
            <div class="mt-2">
              <textarea class="form-control col-12 border border-top-0 border-start-0 border-end-0 border-bottom-1" id="elaborateFormControlTextarea1" rows="1" placeholder="Comments"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="col-12 col-md-6 mb-3">
      <label for="improveFormControlTextarea1" class="form-label mb-2 font-weight-bold">
        My experience could be further improved by: *
      </label>
      <textarea class="form-control col-12 border border-top-0 border-start-0 border-end-0 border-bottom-1" id="improveFormControlTextarea1" rows="1" placeholder="Comments"></textarea>
    </div>
  </div>
  <div class="form-group my-3">
    <div class="col-12 col-md-6 mb-1">
      <label for="improveFormControlTextarea1" class="form-label mb-2 font-weight-bold">
        How likely are you to visit the site again? *
        <p class="d-inline-block">
          <span class="mx-1" data-rating="1">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#1266f1" class="bi bi-star" viewBox="0 0 16 16">
              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
            </svg>
          </span>
          <span class="mx-1" data-rating="2">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#1266f1" class="bi bi-star" viewBox="0 0 16 16">
              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
            </svg>
          </span>
          <span class="mx-1" data-rating="3">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#1266f1" class="bi bi-star" viewBox="0 0 16 16">
              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
            </svg>
          </span>
          <span class="mx-1" data-rating="4">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#1266f1" class="bi bi-star" viewBox="0 0 16 16">
              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
            </svg>
          </span>
          <span class="mx-1" data-rating="5">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#1266f1" class="bi bi-star" viewBox="0 0 16 16">
              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
            </svg>
          </span>
        </p>
      </label>
    </div>
    <div class="col-12 col-md-6 mb-1">
      <label for="improveFormControlTextarea1" class="form-label mb-2 font-weight-bold">
        How likely are you going to refer the PBRIS to a friend?
        <p class="d-inline-block">
          <span class="mx-1" data-rating="1">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#1266f1" class="bi bi-star" viewBox="0 0 16 16">
              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
            </svg>
          </span>
          <span class="mx-1" data-rating="2">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#1266f1" class="bi bi-star" viewBox="0 0 16 16">
              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
            </svg>
          </span>
          <span class="mx-1" data-rating="3">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#1266f1" class="bi bi-star" viewBox="0 0 16 16">
              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
            </svg>
          </span>
          <span class="mx-1" data-rating="4">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#1266f1" class="bi bi-star" viewBox="0 0 16 16">
              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
            </svg>
          </span>
          <span class="mx-1" data-rating="5">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#1266f1" class="bi bi-star" viewBox="0 0 16 16">
              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
            </svg>
          </span>
        </p>
      </label>
    </div>
    <div class="col-12 col-md-8 mb-1">
      <label for="improveFormControlTextarea1" class="form-label mb-2 font-weight-bold me-2">
        How did you discover PBRIS? *
      </label>
      <div class="d-inline-block col-12 col-sm-4">
        <select class="form-select" aria-label="Default select example">
          <option selected></option>
          <option value="Friends">Friends</option>
          <option value="Internet">Internet</option>
          <option value="Newspaper">Newspaper</option>
          <option value="Others">Others</option>
        </select>
      </div>
    </div>
  </div>
  <div class="my-5 text-center">
    <button type="button" class="btn btn-primary col-12 col-md-4">Submit</button>
  </div>
</div>
