import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { PdfRenderService } from 'src/app/pdf-render.service';

@Component({
  selector: 'app-artemis-charter-preview-page',
  templateUrl: './artemis-charter-preview-page.component.html',
  styleUrls: ['./artemis-charter-preview-page.component.scss']
})
export class ArtemisCharterPreviewPageComponent implements OnInit {
  
  userType: any = sessionStorage.getItem("user_type");

  file: any;

  constructor(private route: ActivatedRoute, private afs: AngularFirestore, private pdf_render: PdfRenderService) { }

  ngOnInit(): void {
    this.loadInfo(this.route.snapshot.params.id);
  }

  async loadInfo(id: any){
    this.afs.doc(`citizens-charter-phase-01/${id}`).snapshotChanges().subscribe(
      (data: any) => {
          //let citizens_charter = data.payload.data()

          let path = 'citizen_charter20210511/' + data.payload.get('filename')
          
          this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
            this.file = data; 
          });
      });
  }

}
