import { Component, OnInit, Input } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { PdfRenderService } from 'src/app/pdf-render.service';

@Component({
selector: 'app-uploaded-documents-view',
templateUrl: './uploaded-documents-view.component.html',
styleUrls: ['./uploaded-documents-view.component.scss']
})
// change to uploads
export class UploadedDocumentsViewComponent implements OnInit {

  @Input() objectData:any;
  @Input() baseCode:string = '';

  objectUploads: Array<any> = []

  constructor(
      private store: AngularFireStorage,
      // private pdf_render: PdfRenderService,
  ) { }

  ngOnInit(): void {
    if(this.objectData){
      this.checkUploads(this.objectData)
    }
  }

  checkUploads(upload_folders:any){
    // for(let key of Object.keys(upload_folders)){
    Object.keys(upload_folders).map(async (key)=>{
      let value = upload_folders[key]
      // let value2 = value.split("/")
      // const fileData:any = await this.refToURL(value+"/"+value2[value2.length-1]) //remove others that value
      const fileData:any = await this.refToURL(value) //correct
      let name:any = ""
      if(key == 'policyObjective') name="Policy Objective"
      else if(key == 'policyOptions') name="Policy Options"
      else if(key == 'policyProblem') name="Policy Problem"
      else if(key == 'implementationEnforcement') name="Implementation Enforcement"
      else if(key == 'policyOptionsAssessment') name="Policy Options Assessment"
      else if(key == 'monitoringEvaluation') name="Monitoring Evaluation"
      else if(key == 'regulatoryImpactStatement') name="Regulatory Impact Statement"
      else if(key == 'impactAssessment') name="Impact Assessment"
      else if(key == 'consultationDocumentation') name="Consultation Documentation"
      else if(key == 'consultation') name="Consultation"
      let item:any = {
        key: key,
        name: name,
        fileName:'',
        value: value,
        fileBaseTotalPage: 1,
        fileBasePage: 1,
        downloadUrl: false,
        contentType: false,
      }
      if(fileData){
        if(fileData.url) item.downloadUrl = fileData.url
        if(fileData.metaData){
          item.contentType = fileData.metaData.contentType
          item.fileName = fileData.metaData.name
        }
        console.log('w/ fD',item)
        this.objectUploads.push(item)
      }else{
        console.log('w/o fD',item)
        this.objectUploads.push(item)
      }
    })
    // }
  }

  refToURL(filePath:string){
    return new Promise((res,rej)=>{
      this.store.ref(filePath).getDownloadURL()
      .subscribe({
        next:(data1)=>{
          this.store.ref(filePath).getMetadata()
          .subscribe({
            next:(data2)=>{
              let item:any = {
                url: data1,
                metaData: data2,
              }
              return res(item)
            },
            error:(err)=>{
              // console.error('refToURL Error: ',err)
              return res(false)
            }
          })
        },
        error:(err)=>{
          // console.error('refToURL Error: ',err)
          return res(false)
        }
      })
    })
  }

  // file functions
  checkAuthLetterFileType(type:any,fileType:any){
    if(fileType === type) return true
    return false
  }

  pageShift(action:any,ctr:any){
    if(action == "next"){
        if(this.objectUploads[ctr].fileBasePage < this.objectUploads[ctr].fileBaseTotalPage) 
          this.objectUploads[ctr].fileBasePage++;
    }
    if(action == "back"){
        if(this.objectUploads[ctr].fileBasePage > 1)
          this.objectUploads[ctr].fileBasePage--;
    }
    
  }

  afterLoadComplete(pdf: PDFDocumentProxy, ctr:any){
    this.objectUploads[ctr].fileBaseTotalPage = pdf.numPages;
  }
  
  downloadFile(downloadLink:any){
    window.open(downloadLink, '_blank')
  }
  // !file functions
}
