import { Component, Input, OnInit } from '@angular/core';
import { NewRequirementsModalComponent } from '../new-requirements-modal/new-requirements-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { RequriementsService,RequirementType } from 'src/app/service/requriements.service';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';

@Component({
  selector: 'app-requirement-control',
  templateUrl: './requirement-control.component.html',
  styleUrls: ['./requirement-control.component.scss'],
})
export class RequirementControlComponent implements OnInit {
  
  @Input() requirement: any;
  @Input() referenceArray: any[];
  @Input() SituationIndex: number;
  @Input() requirementIndex: number;
  @Input() isSituational: boolean;
  @Input() chooseany:boolean;
  @Input() req_type: RequirementType

  enum_requirement_type=RequirementType
  hideButtons :boolean=false;
  constructor(
    private dialog: MatDialog,
    public requirementservice: RequriementsService,
    private snackbar : SnackbarNotifService
  ) {}

  ngOnInit(): void {}

  addSituational() {
    if(!this.requirement.subRequirement){
      this.requirement.subRequirement = []
    }

    let situational_req : any = {
      title : "",
      requirement: [],
    }

    this.requirementservice.pushSubSituational(
      this.requirement.subRequirement,situational_req
      
    );

    /**
     * 
     * let result = this.dialog.open(NewRequirementsModalComponent, {
      width: '98vw',
      minHeight: '20vh',
      disableClose: true,
      data: {
        isSituational: true,
        requirementIndex: 0,
        subsituational: true,
      },
    });

    result.afterClosed().subscribe(
      (data: any) => {
        if (data.submitFlag) {
          if(!this.requirement.subRequirement){
            this.requirement.subRequirement = []
          }
          this.requirementservice.pushSubSituational(
            this.requirement.subRequirement,
            data
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
     */
  }

  removeRequirement(){

    this.snackbar.confirmDialog("Remove Requirement").then(result => {
       if (result){
        this.requirementservice.removeRequirement(this.referenceArray,this.requirementIndex)

       }
    })


   
  }
  

  copyRequirement(){
    if(this.requirementservice.checkClipboard('requirement_copy')) this.cancelcopyRequirement();
    else this.requirementservice.copyRequirement(this.requirement,'requirement_copy')
  }

  pasteRequirement(){
    this.requirementservice.pasteRequirement(this.referenceArray,'requirement_copy',this.requirementIndex + 1)
  }

  cancelcopyRequirement(){
    this.requirementservice.cancelcopyRequirement('requirement_copy')
  }


  updateSituationalRequirement(array:any , requirementIndex: number,requirement:any ,situation:any,situationIndex:number){

    const req = situation.requirement
  
    let result = this.dialog
    .open(NewRequirementsModalComponent, {
      width: '98vw',
      minHeight:'20vh',
      disableClose: true,
      data: {
        isupdating : true,
        isSituational: true,
        requirement: situation,
        requirementIndex: requirementIndex
      },
    })

    result.afterClosed().subscribe((data:any)=>{
     if(data.submitFlag){

      data.subRequirement = situation.subRequirement
      
      array[situationIndex] = data
      this.requirementservice.scrollto('situational_' + situationIndex)
    
     }
    })
  }

  updateRequirement(){

    this.requirementservice.updateRequirementObject(this.referenceArray,this.requirement,this.requirementIndex)

   
   /**
    *  const subrequirement =  this.requirement.subRequirement? this.requirement.subRequirement : []

    let result = this.dialog
    .open(NewRequirementsModalComponent, {
      width: '98vw',
      minHeight:'20vh',
      disableClose: true,
      data: {
        isupdating : true,
        requirement: this.requirement,
        requirementIndex: 0,
        type : this.requirement.title? RequirementType.STNDARD_WITH_TITLE : RequirementType.STANDARD_NO_TITLE
      },
    })
    result.afterClosed().subscribe((data:any)=>{
      
     if(data.submitFlag){
      if(this.isSituational){
        
        this.requirement = data.requirement[0]
        this.requirement.subRequirement = subrequirement
        this.referenceArray[this.requirementIndex] =this.requirement
        this.requirementservice.scrollto('situational_' + this.requirementIndex)

      }else{

        this.referenceArray[this.requirementIndex] = data.requirement[0]
        this.requirementservice.scrollto('standard_' + this.requirementIndex)
  
      }
    
     }
    })
    */

  }
}
