import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linebreaks'
})
export class LinebreaksPipe implements PipeTransform {

    transform(value: string): string {
        // return value.replace(/\\n/g, '<br />');
        return value.replace(/(?:\r\n|\r|\n)/g, '<br/>')
    }
}