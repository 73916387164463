<ul class="list-group list-group-light">
   <a target="_blank" [routerLink]="['/artemis/history-view', i.id]" [queryParams]="{service_id: service_id}" *ngFor="let i of history_list" >
    <li   class="list-group-item d-flex justify-content-between align-items-center">
        <div>
          <div class="fw-bold">{{i.last_update.toDate() | date : 'MMMM dd, yyyy hh:mm '}}</div>
          <div class="text-muted">{{i.current_user?.name}}</div>
        </div>
       <!--  <span class="badge rounded-pill badge-success">Active</span> -->
      </li>
   </a>
   
  </ul>