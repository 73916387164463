import {
    ValidatorFn,
    FormGroup,
    ValidationErrors,
    AbstractControl,
} from "@angular/forms";

/* Phone Number must be fully numeric (although a + sign is allowed on the first character) */
export function phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const phoneNumberRegex = new RegExp("[0-9]+");
        if(control.value){
            return !phoneNumberRegex.test(control.value)
                ? { invalidPhoneNumber: { value: control.value } }
                : null;
        } else return null
    };
}

export function matchingPassword(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        let matchingPassword = control.value === control.parent?.value.password;
        return matchingPassword
            ? null
            : { matchingPassword: { value: control.value } };
    };
}

export function matchingGroupedPassword(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        let matchingPassword = control.value.password === control.value.confirmpassword;
        return matchingPassword
            ? null
            : { matchingPassword: { value: control.value } };
    };
}

export function needsStrongerPassword(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        let needsStrongerPassword = control.value > 0 && control.value < 100
        return needsStrongerPassword
            ? { needsStrongerPassword: { value: control.value } }
            : null;
    };
}

/* https://stackoverflow.com/a/45514884 */
export function atLeastOne(validator: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const group = control as FormGroup;
        const hasAtLeastOne =
            group &&
            group.controls &&
            Object.keys(group.controls).some((k) => !validator(group.controls[k]));

        return hasAtLeastOne ? null : { atLeastOne: true };
    };
}

export function allMustBe(validator: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const group = control as FormGroup;
        const hasAtLeastOne =
            group &&
            group.controls &&
            Object.keys(group.controls).every((k) => !validator(group.controls[k]));

        return hasAtLeastOne ? null : { atLeastOne: true };
    };
}


export function regulationRequiredByClassification(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const group = control as FormGroup;
        const classificationValue = group.get('reg_classification')?.value;

        let hasValuesRequiredByClassification = false;
        switch (classificationValue) {
            case 'Business':
                hasValuesRequiredByClassification = group.get('reg_sector')?.value && group.get('reg_business')?.value;
                return hasValuesRequiredByClassification ? null : { regulationRequiredByClassification: { value: classificationValue } }
            case 'Non-Business':
                hasValuesRequiredByClassification = group.get('reg_stageoflife')?.value && group.get('reg_lifeevent')?.value;
                return hasValuesRequiredByClassification ? null : { regulationRequiredByClassification: { value: classificationValue } }
            default:
                // classificationValue must be "Business" or "Non-Business"
                return { regulationRequiredByClassification: { value: classificationValue } };
        }
    };
}

export function hasSubTagValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const group = control as FormGroup;
        const hasSubTag = group.get('has_sub_tags')?.value;
        const subTagArray = group.get('sub_tags')?.value || [];
        const properSubTagCheck = !hasSubTag || (hasSubTag && subTagArray.length > 0)
        
        return properSubTagCheck ? null : { hasSubTagValidator: { value: subTagArray } }
    };
}

// function getControlName(control: AbstractControl): string | null{
//     let group = control.parent as FormGroup;
//     if (!group) return null;
//     let name: string | null = null;
//     Object.keys(group.controls).forEach(key => {
//         let childControl = group.get(key);
//         if (childControl !== control) return null;
//         name = key;
//     });
//     return name;
// }