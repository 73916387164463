import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-report-summary',
  templateUrl: './report-summary.component.html',
  styleUrls: ['./report-summary.component.scss']
})
export class ReportSummaryComponent implements OnInit {
  consultation_report: boolean = false;
  existing_report: boolean = false;
  proposed_report: boolean = false;
  tickets_report: boolean = false;
  use_mgt_report: boolean = false;
  agency_compliance_report: boolean = false;

  // global settings
  userType: any = sessionStorage.getItem("user_type");

  constructor(private router: Router, private afs: AngularFirestore, public auth: AngularFireAuth) { }

  ngOnInit(): void {
  }  

  resetMenu(){
    
    this.consultation_report = false;
    this.existing_report = false;
    this.proposed_report = false;
    this.tickets_report = false;
    this.use_mgt_report = false;
    this.agency_compliance_report = false;
  }

  openConsultationReport(){
    this.resetMenu()
    this.consultation_report = true;
  }

  openExistingReport(){
    this.resetMenu()
    this.existing_report = true;
  }  

  openProposedReport(){
    this.resetMenu()
    this.proposed_report = true;
  }

  openComplianceReport(){
    this.resetMenu()
    this.agency_compliance_report = true;
  }  

  openTicketReport(){
    this.resetMenu()
    this.tickets_report = true;
  }

  openUserManagementReport(){
    this.resetMenu()
    this.use_mgt_report = true;
  }

}
