import { Component, OnInit, QueryList, ViewChild, ViewChildren, Pipe } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { identity, Observable, Subject } from 'rxjs';
import { finalize, first, take, tap } from 'rxjs/operators';
import {
  CharterStatusCitizensCharter,
  RemarkLevelRoleMappingCitizensCharter,
  RemarkResolvedStatusCitizensCharter,
  WorklistAgencyMappedStatusCitizensCharter,
  WorklistStatusCitizensCharter,
} from 'src/app/entities/worklist';

import firebase from 'firebase';
import { MatDialog } from '@angular/material/dialog';
import { ArtemisDisapproveCharterReasonModalComponent } from '../artemis-disapprove-charter-reason-modal/artemis-disapprove-charter-reason-modal.component';
import { WorklistService } from 'src/app/service/worklist-service.service';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';
import { DataTableDirective } from 'angular-datatables';
import { Placeholder } from '@angular/compiler/src/i18n/i18n_ast';

import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ArtemisCocModalComponent } from '../artemis-coc-modal/artemis-coc-modal.component';
import Timestamp = firebase.firestore.Timestamp;
import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RemarkService } from 'src/app/service/remark.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { firestore } from 'firebase-admin';
import { GovServiceService } from 'src/app/service/gov-service.service';
import Swal from 'sweetalert2';
import { EmailerService } from 'src/app/service/emailer.service';
import { NotificationsEmailer } from 'src/app/service/notifications-emailer.service';
import { EmailAppCode, EmailTemplateCode } from 'src/app/entities/emailTemplates';


@Component({
  selector: 'app-artemis-charter-inbox-page-agency-head',
  templateUrl: './artemis-charter-inbox-page-agency-head.component.html',
  styleUrls: ['./artemis-charter-inbox-page-agency-head.component.scss'],
})


export class ArtemisCharterInboxPageAgencyHeadComponent implements OnInit {
  @Pipe({ name: 'ordinal' })

  //number formatting
  ordinals: string[] = ['th', 'st', 'nd', 'rd'];


  results1: any = []; // main countainer for the inbox results list
  results2: any = []; // main countainer for the inbox results list

  //table paginator
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  //dtTrigger: Subject<any> = new Subject<any>();
  dtTrigger1: Subject<any> = new Subject<any>();
  //dtTrigger2: Subject<any> = new Subject<any>();

  //mat table paginator
  displayedColumns: string[] = ['datemodified', 'servicename', 'status'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  //Certificate of Compliance Data
  loader: any = false
  latestCOCdoc: any;
  latestCOCdraft: any;
  cocData: any;
  cocDocId: any;
  cocEdition = 1;
  cocVersion = 0;
  phase_01_filepath: any;
  generatingcoc: any = false;
  phase1form = new FormGroup({
    cocurl: new FormControl('', Validators.required),

  });
  phase_01_folder: any = "certificate_of_compliance"
  loading: any;
  uploadProgress!: Observable<number | undefined>;
  latestcocURL: any;
  latestCCMaintained: any;
  artaongoingReview: boolean = false;


  private worklistFilters = new Map<string, string[]>([
    WorklistAgencyMappedStatusCitizensCharter.AGENCY_DIR,
  ]);

  submiCCenable: boolean = false;
  enum_service_status = WorklistStatusCitizensCharter;
  allWorklistsApproved: boolean = false;
  hasPendingWorklist: boolean = false;
  submittedFlag: boolean = false;
  ccisEmpty: boolean = false;
  dtInstanceTriggered: boolean = false;


  constructor(
    private router: Router,
    public auth: AngularFireAuth,
    private store: AngularFireStorage, private http: HttpClient,
    private afs: AngularFirestore,
    public dialog: MatDialog,
    public worklistService: WorklistService,
    private snackBarService: SnackbarNotifService,
    private remarkService: RemarkService,
    private govService: GovServiceService,
    private emailer : EmailerService,
    private Ne: NotificationsEmailer
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  user : any = {
    displayName  : '',
    email : ''

  }

  async loaduser(){
    await this.auth.user
    .pipe(first())
    .subscribe({

      next:(res)=>{
        // const id = res?.uid
        // console.log('res',res)
        this.user.displayName = res?.displayName
        this.user.email = res?.email
       
    }

    })
  }

  ngAfterViewInit(): void {
    //this.dtTrigger.next();
    this.dtTrigger1.next();

  }
  ngOnInit(): void {
    this.loadCounts();
    this.loaduser()
    this.loadAgencyServicesList()
    this.checkDraft()
    this.getLatestCOC();
    this.checkOngoingArtaReview();
    this.getLatestCitizensCharter()
    this.loadALlcitizensCharter();
    this.dtOptions = {
      processing: true,
      lengthMenu: [20, 50, 100],
      destroy: true,
      pagingType: 'full_numbers',
      pageLength: 20,
      searching: true,
      order:[3,'desc'],
    }


  }

  loadALlcitizensCharter() {

    this.afs.collection('citizens-charter-phase-02', ref =>
      ref.where('fromAgencyId', '==', sessionStorage.getItem('agency_id')))
      .snapshotChanges().pipe()
      .subscribe((data: any) => {

        data.forEach((info: any) => {

          //console.log("CC: ", info.payload.doc.id)
          /**
           *  this.afs.collection('citizens-charter-phase-02').doc(info.payload.doc.id).delete()
           .then(()=>{
             console.log("Deleted: ", info.payload.doc.id)
           });
           
           */
        })

      })

  }
  ///check existing coc draft
  checkDraft() {
    this.afs.collection('Certificate of Compliance Submissions',
      ref => ref.where('fromAgencyId', '==', sessionStorage.getItem('agency_id'))
        .where('cocYear', '==', firebase.firestore.Timestamp.now().toDate().getFullYear())
        .where('status', '==', 'Draft')
        .orderBy('createdAt', 'desc')
        .limit(1))
      .snapshotChanges()
      .subscribe({
        next: (resultArr) => {

          resultArr.map((info) => {
            if (info.payload.doc.exists) {
              let coc: any = info.payload.doc.data()
              coc.id = info.payload.doc.id
              this.latestCOCdraft = coc
            }
          })

        }

      })

  }

  //check if there is ongoing CC review
  //this function will fire if agency submit COC without CC or maintained CC
  checkOngoingArtaReview() {
    let inboxFilter: QueryFn;

    inboxFilter = (filter) =>
      filter
        .where('fromAgencyId', '==', sessionStorage.getItem('agency_id'))
        .where('status', '!=', CharterStatusCitizensCharter.POSTED);

    this.afs
      .collection('citizens-charter-phase-02', inboxFilter)
      .snapshotChanges().pipe()
      .subscribe({
        next: (cc) => {
          cc.map((info) => {
            if (info.payload.doc.exists) {
              this.artaongoingReview = true
              //console.log('Arta is reviewing the CC', this.artaongoingReview)
              return
            }
          })
        }
      }

      )
  }

  //get latest CC posted

  getLatestCitizensCharter() {
    let inboxFilter: QueryFn;

    inboxFilter = (filter) =>
      filter
        .where('fromAgencyId', '==', sessionStorage.getItem('agency_id'))
        .where('status', '==', CharterStatusCitizensCharter.POSTED)
        .orderBy('date', 'desc')
        .limit(1);

    this.afs
      .collection('citizens-charter-phase-02', inboxFilter)
      .snapshotChanges().pipe()
      .subscribe({
        next: (cc) => {
          cc.map((info) => {
            if (info.payload.doc.exists) {
              let ccData: any = info.payload.doc.data();
              ccData.id = info.payload.doc.id;
              //console.log('Arta Latest CC', ccData)
              this.latestCCMaintained = ccData
            }
          })
        }
      }

      )
  }




  //get the latest certificate of compliance
  //if exist it will generate 
  getLatestCOC() {

    this.afs.collection('Certificate of Compliance Submissions',
      ref => ref.where('fromAgencyId', '==', sessionStorage.getItem('agency_id'))
        .where('citizenscharterVersion.year', '==', firebase.firestore.Timestamp.now().toDate().getFullYear())
        .where('status', '==', 'Submitted')
        .orderBy('createdAt', 'desc')
        .limit(1))
      .snapshotChanges()
      .subscribe({
        next: (resultArr) => {

          resultArr.map((info) => {
            if (info.payload.doc.exists) {
              let coc: any = info.payload.doc.data()
              coc.id = info.payload.doc.id
              this.latestCOCdoc = coc
            }
          })

        }

      })
  }

  rerender(): void {

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger1.next();
    })

  }
  //set mat table datasource paginator
  resetSortPage() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort


  }

  //set mat table column sorting

  sortChange(sort: Sort) {
    const data = this.results2.slice()
    // console.log('sort.active',sort.active)
    // console.log('sort.direction',sort.direction)
    if (!sort.active || sort.direction === '') {
      this.results2 = data
      return;
    }
    this.results2 = data.sort((a: any, b: any) => {
      // console.log('results',this.results)
      const isAsc = sort.direction === 'asc'
      switch (sort.active) {

        case 'datemodified':
          return this.compare(a.date_posted, b.date_posted, isAsc)
        case 'servicename':
          return this.compare(a.serviceDetails.service_name.trim().toLowerCase(), b.serviceDetails.service_name.trim().toLowerCase(), isAsc)
        case 'status':
          return this.compare(a.posted_status.trim().toLowerCase(), b.posted_status.trim().toLowerCase(), isAsc)

        default:
          return null
      }
    })
    this.dataSource = new MatTableDataSource<any>(this.results2)
    this.resetSortPage()

  }
  //sort data 
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    //this.dtTrigger.unsubscribe();
    this.dtTrigger1.unsubscribe();
    //this.dtTrigger2.unsubscribe();
  }

  // loads data from database (firestore)
  async loadCounts() {
    this.results1 = [];

    // Enable when multiple users per role are created
    const worklistFilterArr = this.worklistFilters.get(
      sessionStorage.getItem('account_subtype') || ''
    );

    let inboxFilter: QueryFn;

    inboxFilter = (filter) =>
      filter
        .where('fromAgency', '==', sessionStorage.getItem('agency_id'))
        .where('status', 'in', worklistFilterArr)
        .orderBy('dueDate', 'asc');

    this.afs
      .collection('Worklist-Citizens Charter', inboxFilter)
      .snapshotChanges()
      .pipe(
        this.submittedFlag
          ? identity
          : tap((data: any) => {
            // Reset everytime snapshotChanges triggers
            this.hasPendingWorklist = false;
            var tempAllWorklistsApproved = true;

            data.forEach((info: any) => {
              let item: any = info.payload.doc.data();
              item.id = info.payload.doc.id;
              if (
                !this.hasPendingWorklist &&
                item.status === WorklistStatusCitizensCharter.FOR_APPROVAL
              ) {
                this.hasPendingWorklist = true;
              }

              if (
                tempAllWorklistsApproved && item.status !== WorklistStatusCitizensCharter.APPROVED
              ) {
                tempAllWorklistsApproved = false;
              }

              /* 
                Whenever worklist status is updated, snapshotChanges() fires off again,
                so ensure the snapshots do not get recklessly added to the list
              */
              if (info.type === 'modified') {
                // For modified snapshots, find and replace the entry
                for (var x in this.results1) {
                  const worklist = this.results1[x];
                  if (item.id === worklist.id) {
                    this.results1.splice(x, 1, item);
                  }
                }
              } else {
                // For added snapshots, check if worklist already exists in the list
                let addFlag = true;
                for (var worklist of this.results1) {
                  if (item.id === worklist.id) {
                    addFlag = false;
                    break;
                  }
                }

                if (addFlag) {
                  this.results1.push(item);
                }
              }
            });
            if (this.results1.length == 0) {
              this.ccisEmpty = true;
            } else {
              this.ccisEmpty = false;
            }
            this.allWorklistsApproved = tempAllWorklistsApproved;
            this.rerender();
            //this.dtTrigger1.next();
            // this.dtTrigger2.next();
            // TODO: Make pagination take effect when searching again when data already has been loaded
            // this.dtTrigger1.unsubscribe();
            // this.dtTrigger2.unsubscribe();
          })
      )
      .subscribe();
  }

  ccYear: any;
  //display modal coc editor

  async createCOC() {
    let ccmaintained: boolean = false;
    let ccid: any = "";
    this.ccYear = firebase.firestore.Timestamp.now().toDate().getFullYear();
    if (this.artaongoingReview && this.ccisEmpty) {

      alert("Please wait for ARTA to finish reviewing your Citizen's Charter.")
      return
    }

    if (this.ccisEmpty) {
      ccmaintained = true;
    }

    let isAdd: any = true;

    if (ccmaintained) {


      if (this.latestCCMaintained) {

        this.cocEdition = this.latestCCMaintained.edition ? this.latestCCMaintained.edition : 1
        this.ccYear = this.latestCCMaintained.dateSubmitted ? this.latestCCMaintained.dateSubmitted.toDate().getFullYear() : ""
        ccid = this.latestCCMaintained.id ? this.latestCCMaintained.id : ""

      } else {
        alert("You don't have any Citizen's Charter Submission to ARTA!")
        return
      }


    } else {
      //check existing draft
      if (this.latestCOCdraft) {

        isAdd = false
        this.cocEdition = this.latestCOCdraft.citizenscharterVersion.edition

      } else {

        //check latest COC submission
        if (this.latestCOCdoc) {

          //check if coc year is same as current year
          if (this.latestCOCdoc.citizenscharterVersion.year == firebase.firestore.Timestamp.now().toDate().getUTCFullYear()) {
            this.cocEdition = this.latestCOCdoc.citizenscharterVersion.edition + 1
          } else {
            this.cocEdition = this.latestCOCdoc.citizenscharterVersion.edition
          }
        } else {
          this.cocEdition = 1
        }

      }
    }


    const agencyCOCData: any = {
      fromAgencyName: sessionStorage.getItem('agency_name'),
      fromAgencyId: sessionStorage.getItem("agency_id"),
      citizensCharter_Id: ccid,
      createdAt: firebase.firestore.Timestamp.now(),
      citizenscharterVersion: {
        year: this.ccYear,
        edition: this.cocEdition
      },
      personResponsible: {
        name: this.latestCOCdraft ? this.latestCOCdraft.personResponsible.name : "",
        position: this.latestCOCdraft ? this.latestCOCdraft.personResponsible.position : ""
      },
      citizenscharterPosting: {
        billboard: this.latestCOCdraft ? this.latestCOCdraft.citizenscharterPosting.billboard : false,
        handbook: true,
        online: this.latestCOCdraft ? this.latestCOCdraft.citizenscharterPosting.online : false
      },
      offices: this.latestCOCdraft ? this.latestCOCdraft.offices : [],
      cocurl: "",
      status: "Draft",
      id: this.latestCOCdraft ? this.latestCOCdraft.id : ''

    };

    let dialogref = this.dialog.open(ArtemisCocModalComponent, {
      data: {
        agency: agencyCOCData,
      }

    });

    dialogref.afterClosed().subscribe(result => {
      if (result.submitFlag) {

        this.snackBarService.callLoadingmodal("Generating Certificate of Compliance...");

        this.generatingcoc = true
        this.cocData = result.cocData
        //console.log('COC Data', result.cocData)
        this.saveDraft(this.cocData, sessionStorage.getItem('agency_id'), isAdd)

        //console.log("Coc Data Return: ", this.cocData)
        //this.uploadCOC(this.cocData,sessionStorage.getItem('agency_id'))
      } else {
        this.generatingcoc = false
      }
    })
  }


  submitCOC(cocstatus: any, ccdocid: any, url: any, submissionStatus: any = "") {

    const status: any = {
      dateSubmitted: firebase.firestore.Timestamp.now(),
      status: cocstatus,
      citizensCharter_Id: ccdocid,
      cocurl: url,
      submission: submissionStatus

    }
    this.afs.collection('Certificate of Compliance Submissions')
      .doc(this.latestCOCdraft.id)
      .update(status).then(() => {
        //console.log("updating coc", status)
        this.phase1form.get('cocurl')?.setValue("")
        this.phase_01_filepath = ""
        this.removeCOCtemplateAfterUploadedSignedCOC();

      }).then(() => {
        //reload page after submitting cc or cc with coc  
        Swal.close();
        window.location.reload();
      })

  }
  /***
   * cocDoc: any = []

  getCocDocId() {
    this.afs.collection('Certificate of Compliance',
      ref => ref.where('fromAgencyId', '==', sessionStorage.getItem('agency_id'))).snapshotChanges()
      .pipe(first())
      .subscribe((data: any) => {
        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;
          this.cocDoc = item
          //console.log("Current COC Profile", item)

        })

      })
  }
   * 
   * 
   */


  async approveWorklist(id: string) {
    const batch = this.afs.firestore.batch(); // batch uploader, firestore

    let updateObj: Record<string, any> =
      await this.worklistService.updateWorklistStatusObj(
        WorklistStatusCitizensCharter.APPROVED
      );

    let worklist_ref = this.afs.firestore
      .collection(`Worklist-Citizens Charter`)
      .doc(id);

    batch.update(worklist_ref, updateObj);
    this.resolveRemarksOfApprovedWorklist(id);

    await batch.commit().then();
  }

  async approveAll() {
    const batch = this.afs.firestore.batch(); // batch uploader, firestore

    let updateObj: Record<string, any> =
      await this.worklistService.updateWorklistStatusObj(
        WorklistStatusCitizensCharter.APPROVED
      );

    for (var worklist of this.results1) {
      if (worklist.status === WorklistStatusCitizensCharter.FOR_APPROVAL) {
        let worklist_ref = this.afs.firestore
          .collection(`Worklist-Citizens Charter`)
          .doc(worklist.id);

        batch.update(worklist_ref, updateObj);
        this.resolveRemarksOfApprovedWorklist(worklist.id);
      }
    }

    await batch.commit();
  }

  async submitCCToARTA() {

    this.snackBarService.callLoadingmodal("Processing submission please wait...");

    let service_ids : any = []

    //if citizen's charter is maintained
    //upload the coc
    if (this.ccisEmpty && !this.artaongoingReview && this.latestCCMaintained) {
      this.snackBarService.callLoadingmodal("Uploading Certificate of Compliance for Maintained Citizen's Charter")
      this.sendEmailNotifForCOCMaintained()
      this.saveAndUpload(this.latestCCMaintained.id)
      return
    }

    this.submittedFlag = true;
    const batch = this.afs.firestore.batch(); // batch uploader, firestore

    let citizensCharterId = '';

    // Update worklist/s
    let updateWorklistObj: Record<string, any> =
      await this.worklistService.updateWorklistStatusObj(
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW
      );

    //when services is already approved by ARTA Director
    //change worklist status to Director Approved
    let autoApprovedCompliant: Record<string, any> =
      await this.worklistService.updateWorklistStatusObj(
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DIR_APPROVED
      );


    for (var worklist of this.results1) {
      if (worklist.status === WorklistStatusCitizensCharter.APPROVED) {

          //change worklist to director approved status
        if (worklist.fromOfficer == 'ARTA Director') {

          let worklist_ref = this.afs.firestore
            .collection(`Worklist-Citizens Charter`)
            .doc(worklist.id);

          batch.update(worklist_ref, autoApprovedCompliant);

        } else {

          let worklist_ref = this.afs.firestore
            .collection(`Worklist-Citizens Charter`)
            .doc(worklist.id);

          batch.update(worklist_ref, updateWorklistObj);

        }

      }
      service_ids.push(worklist.documentId)
      // Assumes that all worklist items are of one and the same CC
      const service_data = await this.afs
        .collection(`Services Phase 02`)
        .doc(worklist.documentId)
        .get()
        .toPromise();
      let service_data_data: any = service_data.data();
      citizensCharterId = service_data_data.citizens_charter;
    }

    // Empty worklist array after all worklist items have been processed
    this.results1 = [];
    this.hasPendingWorklist = false;

    // Update charter
    if (citizensCharterId) {
      let citizens_charter_ref = this.afs.firestore
        .collection(`citizens-charter-phase-02`)
        .doc(citizensCharterId);

      let updateCharterObj = {
        status: CharterStatusCitizensCharter.FOR_ARTA_REVIEW,
        dateSubmitted: firebase.firestore.Timestamp.now(),
        dueDate: firebase.firestore.Timestamp.now(),
        edition: this.latestCOCdraft.citizenscharterVersion.edition
      };

      batch.update(citizens_charter_ref, updateCharterObj);
    }

    await batch.commit().then(async () => {
      await this.govService.hideAllResolvedRemarks(service_ids)
      //console.log('Citizens Charter ID:', citizensCharterId);
      this.sendEmailnotifforCCsubmission()
      this.saveAndUpload(citizensCharterId)
      //this.snackBarService.openPlainNotif("Charter sent to ARTA!", "Close");
    });
  }

  sendEmailnotifforCCsubmission(){
    const docRef = this.emailer.pulloutAgencyEmails(sessionStorage.getItem('agency_id'))
    let emaillist : any[] = []
    docRef.pipe(take(1))
  .subscribe({
    next : (responses)=>{

        let item :any = responses.payload.data();
        
        const emails:any[] = item.contactDetails.emailArray

        for (var email of emails){
          emaillist.push(email.email)
        }
       const agencyName = item.agencyDetails.agencyName

        const ccVersion : any =   this.govService.transform(this.latestCOCdoc.citizenscharterVersion.edition,this.latestCOCdoc.citizenscharterVersion.year) 
        emaillist.push(this.user.email)
        this.emailer.sendCCNotifToARTA(emaillist, agencyName ,ccVersion )
        
    }
  })
  }

  sendEmailNotifForCOCMaintained(){
    const docRef = this.emailer.pulloutAgencyEmails(sessionStorage.getItem('agency_id'))
    let emaillist : any[] = []
    docRef.pipe(take(1))
  .subscribe({
    next : (responses)=>{

        let item :any = responses.payload.data();
        
        const emails:any[] = item.contactDetails.emailArray

        for (var email of emails){
          emaillist.push(email.email)
        }
       const agencyName = item.agencyDetails.agencyName

       //console.log("latest CC", this.latestCCMaintained)

        const ccVersion : any =   this.govService.transform(this.latestCCMaintained.edition,this.latestCCMaintained.dateSubmitted.toDate().getFullYear() ) 
        emaillist.push(this.user.email)
        this.emailer.sendCOCNotifOnlyToARTA(emaillist, agencyName ,ccVersion )
        
    }
  })
  }

  //for every submission change the latest edition and year of coc
  updateCOCDoc(data: any, docid: any) {

    this.afs.collection('Certificate of Compliance').doc(docid).update(
      data
    )
  }

  async saveDraft(data: any, ccid: any, upcoc: boolean = true) {

    let latestcocsubmissionid: any;
    if (upcoc) {
      await this.afs.collection('Certificate of Compliance Submissions').add(data)
        .then(coc => {

          latestcocsubmissionid = coc.id

        }).catch(() => {
          this.generatingcoc = false
        })
    } else {
      latestcocsubmissionid = this.latestCOCdraft.id
      await this.afs.collection('Certificate of Compliance Submissions').doc(latestcocsubmissionid)
        .update(data)

    }
    data.id = latestcocsubmissionid;
    //console.log('Draft Data', data)
    this.generateReport(data)


  }


  //coc report generation

  generateReport(doc: any) {

    if (doc) {

      this.generatePDFFile(doc)
    } else {

      this.generatingcoc = false

    }

  }

  //format certificate of compliance edition and version
  transform(n: number, keepNumber: boolean = true) {
    let v = n % 100;
    return (keepNumber ? n : '') + (this.ordinals[(v - 20) % 10] || this.ordinals[v] || this.ordinals[0]);
  }


  //get file uploaded
  saveFileInformation(event: any) {
    this.phase_01_filepath = event.target.files[0];
    if (this.phase_01_filepath) {
      this.submiCCenable = true
      this.phase1form.get("cocurl")?.setValue(this.phase_01_filepath.name);
      //this.saveAndUpload(sessionStorage.getItem('agency_id'));
    } else {
      this.submiCCenable = false
      this.loader = false
    }
  }

  //upload signed certificate of compliance to firestore storage
  async saveAndUpload(ccid: any) {
    this.snackBarService.callLoadingmodal("Uploading Certificate of Compliance....");

    this.loading = true;
    if (this.phase_01_filepath && ccid) {
      const destinationPath = `${this.phase_01_folder}/${sessionStorage.getItem('agency_id')}/${this.latestCOCdraft.id}/${this.phase_01_filepath.name}`;
      const destinationRef = this.store.ref(destinationPath);
      await this.store.upload(destinationPath, this.phase_01_filepath)
        .then(result => {
          result.ref.getDownloadURL().then(url => {

            if (this.ccisEmpty && !this.artaongoingReview && this.latestCCMaintained) this.submitCOC("Submitted", ccid, url, "Maintained")
            else this.submitCOC("Submitted", ccid, url, "New")
            


          })
        }).catch(err => {
          this.snackBarService.displayNotif('Error',err)
            
        }).finally(() => {
          this.loading = false
          this.submiCCenable = false 
          this.snackBarService.displayNotif('Success','Charter sent to ARTA!')
            
          //reload the page after uploading coc with or cc to arta
        });


    }

  }


  //generate certificate of compliance pdf file
  generatePDFFile(doc: any) {

    const apiUrl = "https://us-central1-arta-respond.cloudfunctions.net/generateArtemisCOC/cocapi/v1/generateDoc"; //cloudfunc endpoint / to generate /no checking
    const cocdata: any = {
      generationFor: "cocAgencyReports",
      id: doc.id,
      fromAgencyId: sessionStorage.getItem('agency_id'),
      personResponsible: doc.personResponsible,
      fromAgencyName: doc.fromAgencyName,
      year: doc.citizenscharterVersion.year,
      edition: doc.citizenscharterVersion.edition,
      format: this.transform(doc.citizenscharterVersion.edition, false),
      offices: doc.offices,
      handbook: doc.citizenscharterPosting.handbook,
      billboard: doc.citizenscharterPosting.billboard,
      online: doc.citizenscharterPosting.online,
      logourl: doc.logoURL,
      cocYear: doc.cocYear

    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http.post<any>(apiUrl, cocdata, httpOptions)
      .subscribe(
        (apiResponse: any) => {
          //console.log('Api Response', apiResponse)
          if (apiResponse && apiResponse.processDone && apiResponse.result) {
            this.latestcocURL = this.toGetDownloadURL(doc)
            this.generatingcoc = false
          } else {
            this.generatingcoc = false
            this.snackBarService.displayNotif("error","Unable to Generate Certificate of Compliance Document.")

          }
        },
        (error: any) => {
          this.snackBarService.displayNotif("error","Unable to Generate Certificate of Compliance Document.")

          this.generatingcoc = false
        }
      )


  }

  toGetDownloadURL(doc: any) {

    const privateBucket = 'artemis_coc_documents'

    const privateStorage = this.store.storage.app.storage(privateBucket)

    const storagePath = "reports/agency/" + doc.fromAgencyId + "/certificate_of_compliance/"

    const fileName = doc.id + "_coc.pdf"
    const filePath = storagePath + fileName
    privateStorage.ref(filePath).getDownloadURL()
      .then((url) => {
        if (url) {
          Swal.close()
          window.open(url)
          return url
        } else {
          this.generatingcoc = false
          alert('Failed to generate URL, try gain')

        }
      })
      .catch((err) => {
        console.error('Error: ', err)
        alert('Failed to generate URL, try again')
        this.generatingcoc = false
      })

  }

  removeCOCtemplateAfterUploadedSignedCOC() {

    const privateBucket = 'artemis_coc_documents'

    const privateStorage = this.store.storage.app.storage(privateBucket)

    const storagePath = "reports/agency/" + sessionStorage.getItem("agency_id") + "/certificate_of_compliance/"

    privateStorage.ref(storagePath).listAll()
      .then(result => {

        result.items.forEach((info: any) => {
          privateStorage.ref(storagePath + info.name).delete().then(() => {
            //console.log('Deleted', info.name)
          })
        })

      });


  }



  //end

  



  async openReasonForDisapproval() {

    const user = await this.govService.getCurrentUser()
    let remarks_reason : any = ""

    this.afs.collection("Service Remarks",
    filter => 
      filter
          .where('user.user_id','==',user.user_id)
          .where('status','==',RemarkResolvedStatusCitizensCharter.UNRESOLVED)
    )
    .snapshotChanges()
    .pipe(tap( (data:any)=>{

      data.forEach((info:any)=>{


          const remark :any = info.payload.doc.data();
          remarks_reason += (remark.message + ", ")
    
      })

    }),take(1)).subscribe(()=>{

      const dialogRef = this.dialog.open(
        ArtemisDisapproveCharterReasonModalComponent,
        {
          disableClose: true,
          height: '480px',
          width: '640px',
          data: {
            finalWarning: true,
            defaultReason: remarks_reason
          },
        }
      );
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result && result.submitFlag && result.submitValue) {
          this.disapproveCC(result.submitValue);
        } else {
          // console.log('cancelled!');
        }
      });

    })

    



  
    
  }

  private async disapproveCC(reason_for_disapproval: string) {
    this.submittedFlag = true;
    const batch = this.afs.firestore.batch(); // batch uploader, firestore

    let citizensCharterId = '';

    // Update worklist/s
    for (var worklist of this.results1) {
      let worklist_ref = this.afs.firestore
        .collection(`Worklist-Citizens Charter`)
        .doc(worklist.id);

      // Set worklist to VERIFIED (if worklist was APPROVED) or FOR_REVISION (if worklist was DISAPPROVED)
      let updateWorklistObj: Record<string, any> =
        await this.worklistService.updateWorklistStatusObj(
          worklist.status === WorklistStatusCitizensCharter.APPROVED
            ? WorklistStatusCitizensCharter.APPROVED
            : WorklistStatusCitizensCharter.FOR_REVISION
        );

      batch.update(worklist_ref, updateWorklistObj);

      // Assumes that all worklist items are of one and the same CC
      const service_data = await this.afs
        .collection(`Services Phase 02`)
        .doc(worklist.documentId)
        .get()
        .toPromise();
      let service_data_data: any = service_data.data();
      citizensCharterId = service_data_data.citizens_charter;
    }

    // Empty worklist array after all worklist items have been processed
    this.results1 = [];
    this.hasPendingWorklist = false;

    // Update charter
    if (citizensCharterId) {
      let citizens_charter_ref = this.afs.firestore
        .collection(`citizens-charter-phase-02`)
        .doc(citizensCharterId);

      let updateCharterObj = {
        status: CharterStatusCitizensCharter.FOR_REVISION,
        remark_disapproval_agency_head: reason_for_disapproval,
        //causing submission error when disapproving cc without a coc draft
        //edition: this.latestCOCdraft.citizenscharterVersion.edition + 1
      };

      batch.update(citizens_charter_ref, updateCharterObj);
    }

    await batch.commit().then(() => {
      this.snackBarService.displayNotif('Success','Returned charter to encoder!')
      window.location.reload();
    });
  }



  async loadAgencyServicesList() {
    let inboxFilter: QueryFn;

    inboxFilter = (filter) =>
      filter
        .where('is_posted', '==', true)
        .where('fromAgencyId', '==', sessionStorage.getItem('agency_id'));

    this.afs
      .collection('Services Phase 02', inboxFilter)
      .snapshotChanges()
      .pipe(
        tap((data: any) => {
          let temparray: any = []
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
            this.results2.push(item)

          });
          this.dataSource = new MatTableDataSource(this.results2)
          this.resetSortPage();
          let sortData: Sort = {
            active: 'status',
            direction: 'asc'
          }
          this.sortChange(sortData)
        }),
        take(1)
      )
      .subscribe()
  }

  async resolveRemarksOfApprovedWorklist(worklist_id: string) {
    const user_position = sessionStorage.getItem('account_subtype') || '';

    this.afs
      .collection(`Remarks Artemis New`, (filter) =>
        filter
          .where('remark_worklist_id', '==', worklist_id)
          .where('remark_level', 'in', [RemarkLevelRoleMappingCitizensCharter[user_position]])
          .orderBy('remark_date', 'asc')
      )
      .snapshotChanges()
      .pipe(
        tap(
          async (data: any) => {
            const test = await this.auth.currentUser;

            data.forEach((remarkObj: any) => {
              const remark_data = remarkObj?.payload?.doc?.data();

              const service_remark_obj = remark_data.service_remark_obj;

              const service_eodb_tags_remark_obj = remark_data.service_eodb_tags_remark_obj;

              const service_steps_remarks = remark_data.service_steps_remarks || new Map();

              const service_agency_actions_remarks = remark_data.service_agency_actions_remarks || new Map();

              if (service_remark_obj?.service_remark && service_remark_obj.resolved_status === RemarkResolvedStatusCitizensCharter.UNRESOLVED) {
                let addServiceRemark = this.remarkService.createRemarksArrayObj(remark_data, service_remark_obj, test?.uid || '', remarkObj?.payload?.doc?.id);
                this.remarkService.resolveRemark(addServiceRemark, 1);
              }

              if (service_eodb_tags_remark_obj?.service_eodb_tags_remark && service_eodb_tags_remark_obj.resolved_status === RemarkResolvedStatusCitizensCharter.UNRESOLVED) {
                let addEodbTagsRemark = this.remarkService.createRemarksArrayObj(remark_data, service_eodb_tags_remark_obj, test?.uid || '', remarkObj?.payload?.doc?.id);
                this.remarkService.resolveRemark(addEodbTagsRemark, 2);
              }

              for (let [k, v] of Object.entries<any>(service_steps_remarks)) {
                if (v.step_remark && v.resolved_status === RemarkResolvedStatusCitizensCharter.UNRESOLVED) {
                  let addStepRemark = this.remarkService.createRemarksArrayObj(remark_data, v, test?.uid || '', remarkObj?.payload?.doc?.id);
                  this.remarkService.resolveRemark(addStepRemark, 3);
                }
              }

              for (let [k, v] of Object.entries<any>(service_agency_actions_remarks)) {
                if (v.action_remark && v.resolved_status === RemarkResolvedStatusCitizensCharter.UNRESOLVED) {
                  let addActionRemark = this.remarkService.createRemarksArrayObj(remark_data, v, test?.uid || '', remarkObj?.payload?.doc?.id);;
                  this.remarkService.resolveRemark(addActionRemark, 4);
                }
              }
            });
          }
        ),
        take(1)
      )
      .subscribe();
  }
}
