import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { format } from 'date-fns';
import firebase from "firebase/app";
import { Subject, combineLatest, } from 'rxjs';
import { first } from 'rxjs/operators';
import { CharterStatusCitizensCharter } from 'src/app/entities/worklist';

@Component({
  selector: 'app-artemis-charter-crud',
  templateUrl: './artemis-charter-crud.component.html',
  styleUrls: ['./artemis-charter-crud.component.scss']
})
export class ArtemisCharterCrudComponent implements OnInit {
  status: string = "";
  charters: any = [];
  searchQueryJSON: any;
  loading: boolean = true;
  userType: any = sessionStorage.getItem("user_type");
  service_list: any = [];

  searchform: FormGroup = new FormGroup({
    keyword: new FormControl('', Validators.required),
  });

  //table paginator
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();

  searchFilters: Array<string> = ["organization_grouping_01", "organization_level_00", "organization_level_01", "agency_initials", "tags"];

  constructor(private route: ActivatedRoute,
    private afs: AngularFirestore,
    private router: Router) {


  }



  ngOnInit(): void {
    // console.log('userType: ', this.userType)


    this.loadStatus(this.route.snapshot.params.status);


    this.route.queryParams.subscribe((params: any) => {

      let JSONfields: Map<string, string> = new Map();

      // this.charterLoader(JSONfields);
      this.loadcitizensCharter();

      //this.searchform.controls.keyword.patchValue(params.keyword);
      this.dtOptions = {
        processing: true,
        lengthMenu: [20, 50, 100],
        destroy: true,
        pagingType: 'full_numbers',
        pageLength: 20,
        searching: true,
        search: {
          search: params.keyword
        }
      };


    })



    /***
     * 
     *  if (params.keyword === "") {
       JSONfields.set("title", "");
     }
     else {
       JSONfields.set("title", params.keyword);
     }

     for (var x in this.searchFilters) {
       const searchFilter = this.searchFilters[x];
       if (params.keyword === "") {
         JSONfields.set(searchFilter, "");
       }
       else {
         JSONfields.set(searchFilter, params.keyword);
       }
     }
     */





  }

  ngAfterViewInit(): void {
    //this.dtTrigger.next();
    this.dtTrigger.next();

  }
  rerender(): void {

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    })

  }




  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  loadStatus(getStatus: string) {
    if (getStatus) {
      this.status = getStatus.charAt(0).toUpperCase() + getStatus.slice(1)
    }
  }


  async loadcitizensCharter() {

    await this.afs.collection('citizens-charter-phase-02', filter => filter.orderBy('date')
      .where('status', '==', CharterStatusCitizensCharter.POSTED))
      .snapshotChanges().subscribe((results: any) => {
        this.charters = [];
     
        this.loading = true;

        results.forEach((content: any) => {
          let service_list: any = [];
          let item: any = content.payload.doc.data();
          //console.log('Charter Data',item)
          item.id = content.payload.doc.id;
          item.active = false;

          item.agency_name = (item.organization_level_01 || item.fromAgencyName) ? (item.organization_level_01 || item.fromAgencyName) : null

          item.agency_abv = (item.agency_initials || item.keywords && item.keywords.abbreviation) ? (item.agency_initials || item.keywords && item.keywords.abbreviation) : null

          item.agency_category = item.organization_grouping_01 ? item.organization_grouping_01 : (item.agencyCategory && item.agencyCategory.name) ? item.agencyCategory.name : null
          
          if(item.agency_category){

            if (item.service_id) {

              for (let service_id of item.service_id) {
                this.afs.collection('Services Phase 02').doc(service_id).snapshotChanges()
                  .subscribe(data => {
                    if (data.payload.exists) {
                      let service: any = data.payload.data()
                      service.id = data.payload.id
                      service_list.push(service);
                    }
                  })
  
              }
              item.services = service_list;

            }

            /** include this before roll out
             * if (item.service_id) {

              for (let service_id of item.service_id) {
                this.afs.collection('Services Phase 02').doc(service_id).snapshotChanges()
                  .subscribe(data => {
                    if (data.payload.exists) {
                      let service: any = data.payload.data()
                      service.id = data.payload.id
                      service_list.push(service);
                    }
                  })
  
              }
              item.services = service_list;
  
            }
             */
  
            item.displayDate = (item.date instanceof firebase.firestore.Timestamp ? format(item.date.toDate(), 'MM/dd/yyyy') : item.date)
            this.charters.push(item);

          }
          

        });


        this.loading = false;
        this.rerender();
      });

  }

  async charterLoader(inputJSON: Map<string, string>) {
    let batches: any = []

    // batches.push(await this.afs.collection('citizens-charter-phase-01', filter => filter.orderBy('date')).snapshotChanges())
    batches.push(await this.afs.collection('citizens-charter-phase-02', filter => filter.orderBy('date')
      // .where('is_posted','==',true)
      .where('status', '==', CharterStatusCitizensCharter.POSTED)
    )
      .snapshotChanges())

    combineLatest(batches)
      .subscribe(results => {
        this.charters = [];
        this.service_list = [];
        let service_list: any[] = [];

        results.forEach((content: any) => {
          content.forEach(async (result: any) => {
            let item: any = result.payload.doc.data()
            item.id = result.payload.doc.id
            item.active = false

            item.agency_name = (item.organization_level_01 || item.fromAgencyName) ? (item.organization_level_01 || item.fromAgencyName) : null

            item.agency_abv = (item.agency_initials || item.keywords && item.keywords.abbreviation) ? (item.agency_initials || item.keywords && item.keywords.abbreviation) : null

            item.agency_category = item.organization_grouping_01 ? item.organization_grouping_01 : (item.agencyCategory && item.agencyCategory.name) ? item.agencyCategory.name : null

            //console.log('cc: ',item)

            if (item.service_id) {
              for (let service_id of item.service_id) {
                this.afs.collection('Services Phase 02').doc(service_id).snapshotChanges()
                  .subscribe(data => {
                    if (data.payload.exists) {
                      let service: any = data.payload.data()
                      service.id = data.payload.id
                      service_list.push(service)
                    }
                  })

              }
              item.services = service_list
            }


            // console.log('charter: ',item.services)

            item.displayDate = (item.date instanceof firebase.firestore.Timestamp ? format(item.date.toDate(), 'MM/dd/yyyy') : item.date)
            this.charters.push(item);

            /***
             * 
             *  if (this.charters.includes(item) === false) {
               if (inputJSON.get("title") === undefined) {
                 this.isAcceptedItem("", inputJSON, item) //TODO: Shall we remove this??
                 item.displayDate = (item.date instanceof firebase.firestore.Timestamp ? format(item.date.toDate(), 'MM/dd/yyyy') : item.date )
                 this.charters.push(item);
               }
               else {
                 for (var x in this.searchFilters) {
                   const searchFilter = this.searchFilters[x];
                   let byName = this.isAcceptedItem(searchFilter, inputJSON, item)
                   let allBool = byName
     
                   if (allBool) {
                     item.displayDate = (item.date instanceof firebase.firestore.Timestamp ? format(item.date.toDate(), 'MM/dd/yyyy') : item.date )
                     this.charters.push(item);
                     break;
                   }
                 }
               }
             }
             */

          })
        })
        this.loading = false;
        this.rerender();
        // TODO: Make pagination take effect when searching again when data already has been loaded
        //this.dtTrigger.unsubscribe();
      })
  }


  viewService(entryId: string) {
    //TODO: Temporarily reusing the view details for worklisting
    //TODO: Create a new component for viewing separate from 
    this.router.navigate([`artemis/view/detail/${entryId}`])
  }

  isAcceptedItem(keyName: string, inputJSON: Map<string, string>, item: any) {

    // if field is left empty

    if (inputJSON.get(keyName) === '') {
      return true;
    }
    // if field is from keyword, convert all to lower-case then compare
    if (this.searchFilters.includes(keyName)) {
      let inputStr = inputJSON.get(keyName) || "";
      if (
        item[keyName] && item[keyName].toLowerCase().includes(inputStr.toLowerCase())) {
        return true;
      }
    }
    // if field has data that is not from keyword
    else if (item[keyName] === inputJSON.get(keyName)) {
      return true
    }
    else if (item[keyName] === undefined || inputJSON.get(keyName) === undefined) {
      return false;
    }
    return false;
  }

  reloadpage() {
    this.router.navigate([`/artemis/citizen-charter`]);
  }

  search() {
    let JSONarray = {
      keyword: this.searchform.value.keyword,
    };

    this.dtOptions.search = {
      search: this.searchform.value.keyword
    }

    //this.router.navigate([`/artemis/citizen-charter`], { queryParams: JSONarray });  // define your component where you want to go
  }

  viewCitizensCharter(entryId: any) {
    //console.log("Agency Services", entryId.services)
    //return;
    if (this.userType != 'Personal' || this.userType != 'default') {
      // this.router.navigate([`/artemis/citizen-charter/view/${entryId}`])
      this.router.navigate([`/artemis/citizen-charter/view/report`], {
        queryParams: {
          cc_id: entryId.id
        }
      })
    } else {
      this.router.navigate([`/artemis/citizen-charter/view/public/${entryId.id}`])
    }
  }

  getImage(cc: any) {
    if(cc?.logoURL){
      return cc.logoURL
    }
    return "/assets/GOP_Logo.png"
  }
}
