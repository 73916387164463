import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ServiceHistoryService {

  constructor(private afs: AngularFirestore) { }

  saveServicehistory(service:any,user:any): Promise<void>{


   const docref =  this.afs.collection("Services Phase 02").doc(service.id)

     return docref.get().toPromise().then(doc=>{
        if(doc.exists){
          let historydata =  service;
          historydata.last_update = new Date();
          historydata.current_user = user


          docref.collection("History").add(historydata)
          
        }
     })
  }

}
