import { regulationRequiredByClassification } from 'src/app/validators';
    import { Component, OnInit, Input } from '@angular/core';
    import { AngularFirestore } from '@angular/fire/firestore';
    // import { ActivatedRoute, Router } from '@angular/router';
    // import { AngularFireAuth } from '@angular/fire/auth';
    // import { PdfRenderService } from '../../pdf-render.service';
    // import { format } from 'date-fns';
    // import firebase from "firebase/app";
    // import Swal from 'sweetalert2';

    @Component({
    selector: 'app-regulations-notif-view',
    templateUrl: './regulations-notif-view.component.html',
    styleUrls: ['./regulations-notif-view.component.scss']
    })
    export class RegulationsNotifViewComponent implements OnInit {

    @Input() id:any;

    currentProposedRegulation:any;

    constructor(
        private afs: AngularFirestore,
    ) { }

  ngOnInit(): void {
    this.loadProposedRegulation(this.id);
  }

  loadProposedRegulation(id:any){
    this.afs.collection('regulations-proposed-phase-02').doc(id).snapshotChanges()
    .subscribe(info=>{
        let item:any = info.payload.data()
        item.id = info.payload.id
        if(item.reg_notif_form){
          if(item.reg_notif_form.reg_business){
            this.afs.collection('Stage of Business').doc(item.reg_notif_form.reg_business).snapshotChanges()
            .subscribe(info=>{
              item.reg_notif_form.reg_business_doc = info.payload.data()
            })
          }
          if(item.reg_notif_form.reg_case){
            this.afs.collection('Case Use').doc(item.reg_notif_form.reg_case).snapshotChanges()
            .subscribe(info=>{
              item.reg_notif_form.reg_case_doc = info.payload.data()
            })
          }
          if(item.reg_notif_form.reg_division){
            this.afs.collection('Division').doc(item.reg_notif_form.reg_division).snapshotChanges()
            .subscribe(info=>{
              item.reg_notif_form.reg_division_doc = info.payload.data()
            })
          }
          if(item.reg_notif_form.reg_juris){
            this.afs.collection('Jurisdiction').doc(item.reg_notif_form.reg_juris).snapshotChanges()
            .subscribe(info=>{
              item.reg_notif_form.reg_juris_doc = info.payload.data()
            })
          }
          if(item.reg_notif_form.reg_lifeevent){
            this.afs.collection('Life Event').doc(item.reg_notif_form.reg_lifeevent).snapshotChanges()
            .subscribe(info=>{
              item.reg_notif_form.reg_lifeevent_doc = info.payload.data()
            })
          }
          if(item.reg_notif_form.reg_sector){
            this.afs.collection('Sector').doc(item.reg_notif_form.reg_sector).snapshotChanges()
            .subscribe(info=>{
              item.reg_notif_form.reg_sector_doc = info.payload.data()
            })
          }
          if(item.reg_notif_form.reg_stageoflife){
            this.afs.collection('Stage of Life').doc(item.reg_notif_form.reg_stageoflife).snapshotChanges()
            .subscribe(info=>{
              item.reg_notif_form.reg_stageoflife_doc = info.payload.data()
            })
          }
        }
        this.currentProposedRegulation = item
    })
  }



}
