import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { AgencyOfficeModalComponent } from '../agency-office-modal/agency-office-modal.component';

@Component({
  selector: 'app-agency-office-division-modal',
  templateUrl: './agency-office-division-modal.component.html',
  styleUrls: ['./agency-office-division-modal.component.scss']
})
export class AgencyOfficeDivisionModalComponent implements OnInit {


  agency_id:any = ""
  officeData:any;
  office_id:any;
  isupdate:boolean =false;
  loading:any =false;

  officeGroup = this.fb.group({
      name: new FormControl('',Validators.required),
      level: new FormControl('',Validators.required)
  })

  constructor(private afs: AngularFirestore, public fb: FormBuilder,
    public dialogRef: MatDialogRef<AgencyOfficeDivisionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

    if(this.data.action == 'Add'){
      this.agency_id = this.data.agencyID
    }else{
      this.isupdate =true;
      this.loadData(this.data.id)
      this.officeGroup.controls['name'].setValue(this.data.name)
      this.officeGroup.controls['level'].setValue(this.data.level)
      this.office_id = this.data.id;
    }

  }


   save(){
    this.loading =true
    if(this.officeGroup.valid){
      let data: any = this.officeGroup.value
      data.Status = "Active"
      if (this.isupdate === true){
        this.updating(data);
      }else{
  
        data.fromAgencyId = this.agency_id;
        //console.log('Office Data:',data)
        let office =  this.afs.collection("Agency-Offices-Division")
        office.add(data)
        
        if (office){
          this.dialogRef.close();
        }
  
      } 
  }else{
    this.officeGroup.markAllAsTouched()
    this.loading =false
  }
  

}
  

  loadData(id:any){
    this.loading =true;
    this.afs.collection('Agency-Offices-Division').doc(id).snapshotChanges()
    .pipe(first())
    .subscribe(info=>{
      if(info.payload.exists){
        let data:any = info.payload.data();
        data.id = id
        this.officeData = data
        this.patchData(data)
      }
    })
  }

  updating(data:any){

      this.afs.collection('Agency-Offices-Division')
      .doc(this.data.id).update(data)
      .then(()=>{
        this.dialogRef.close();
      })
      .catch(err=>{
        this.loading = false;
        alert('Unable to edit ')
       
      })

  }

  patchData( data:any){
    this.officeGroup.patchValue({
      name: data.name ? data.name : "",
      level: data.level ? data.level : "",
    })
    this.loading =false
  }


}
