<app-navbar [currentPage]="'FAQs'"></app-navbar>
<div class="page-header theme-registration mb-3 py-3">
    <div class="container">
      <div class="row">
        <div class="col-6 title-widget info-widget">
          <h5 class="fw-bold py-2">FREQUENTLY ASKED QUESTIONS</h5>
        </div>
        <div class="col-6 d-none d-md-block">
          <div class="float-end">
            <button type="button" class="btn btn-light btn-floating mx-1" style="width: auto; height: auto;" hidden>
              <a href="/pbris/regulations/existing" title="Existing Regulation">
                <img src="/assets/Assets/Icons/Existing%20Regulation.png" height="70" alt="Existing Regulation" />
              </a>
            </button>
            <button type="button" class="btn btn-light btn-floating mx-1" style="width: auto; height: auto;" hidden>
              <a href="/pbris/regulations/proposed" title="Proposed Regulation">
                <img src="/assets/Assets/Icons/Proposed%20Regulation.png" height="70" alt="Proposed Regulation" />
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
</div>

<div class="container mt-5">
    <div class="row row-cols-3 justify-content-md-center">
        <div *ngFor="let item of faqTop">
            <div class="quest-pill quest-head mb-0" (click)="toggleQuestion(item)">
                {{ item.question}}
            </div>
            <div class="quest-body-pill quest-body mt-2 p-4" *ngIf="item.expanded">
                <p [innerHTML]="getSanitizedAnswer(item.answer)">
                </p>
            </div>
        </div>
        <div class="quest-pill" (click)="scrollToElement()">
            <div class="quest-head mb-0">
                Regulatory Management System (RMS)
            </div>
        </div>
    </div>
    <!-- <div class="row border-top border-primary border-3 py-4 px-2 my-5" style="background: rgba(239, 239, 239, .5);">
        <h5 class="faq-head">FREQUENTLY ASKED QUESTIONS</h5>
    </div> -->
    <div class="row border-top border-primary border-3 py-4 px-2 my-5" style="background: rgba(239, 239, 239, .5);">
        <div class="col">
            <div class="faq">
                <div class="private-pill mb-4">
                    For Private Sector
                </div>
                <ul>
                    <div class="faq-item" *ngFor="let item of faqItems">
                        <div class="faq-question" (click)="toggleQuestion(item)">
                            <li class="my-2">{{ item.question }}</li>
                        </div>
                        <div class="faq-answer my-4" *ngIf="item.expanded" [innerHTML]="getSanitizedAnswer(item.answer)"></div>
                    </div>
                </ul>
            </div>
        </div>
        <div class="col">
            <div class="faq">
                <div class="gov-pill mb-4">
                    For Government Agencies
                </div>
                <ul>
                    <div class="faq-item" *ngFor="let item of faqGovItems">
                        <div class="faq-question" (click)="toggleQuestion(item)">
                            <li class="my-2">{{ item.question }}</li>
                        </div>
                        <div class="faq-answer my-4" *ngIf="item.expanded" [innerHTML]="getSanitizedAnswer(item.answer)"></div>
                    </div>
                </ul>
            </div>
        </div>
    </div>
    <div class="row my-2">
        <div class="faq">
            <div class="rms-pill mb-4" #rms>
                Regulatory Management System (RMS)
            </div>
            <ul>
                <div class="faq-item" *ngFor="let item of faqRMS">
                    <div class="faq-question" (click)="toggleQuestion(item)">
                        <li class="my-2">{{ item.question }}</li>
                    </div>
                    <div class="faq-answer my-4" *ngIf="item.expanded" [innerHTML]="getSanitizedAnswer(item.answer)"></div>
                </div>
            </ul>
        </div>
    </div>
    <div class="row my-5">
        <div class="faq-contact">
            <h5 style="color:#0038A8;font-weight:600;">Still can't find the answer you are looking for?</h5>
            <h5 class="fw-bold">Contact us <a routerLink="/pbris/contact-us">here</a>.</h5>
        </div>
    </div>
</div>