<app-artemis-navigation-header [currentPage]="'CharterMain'"></app-artemis-navigation-header>
<app-artemis-loading-animation *ngIf="loader" ></app-artemis-loading-animation>

<mat-toolbar *ngIf="!loader" class="sticky-nav p-2" style="height: auto;">

    <div class="container-fluid">   
        <div class="row p-0 m-0 " >
            <button mat-icon-button (click)="returnToAgencyProfile()" matTooltip="Back to Agency Profile" matTooltipPosition="below" *ngIf="currentCC">
                <span><i class="fas fa-chevron-circle-left fa-lg"></i></span>
            </button>
            <button mat-icon-button [disabled]="currentCC.status != 'POSTED'" (click)="goToCC()" matTooltip="Download Citizens Charter" matTooltipPosition="below" *ngIf="currentCC">
                <i class="fas fa-file-download fa-lg"></i>
            </button>
            <!--Sitiuational Action-->
            <button mat-icon-button [disabled]="loader" (click)="checkReport()" matTooltip="See Report File" matTooltipPosition="below" class="st-act" *ngIf="isAgencyHead || isArta || isEncoder">
                <span *ngIf="loaderCheckFile" class="d-flex justify-content-center align-self-center">
                    <div class="spinner-border text-light" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                </span>
                <i class="fas fa-file-invoice fa-lg"></i>
            </button>
            <button mat-icon-button [disabled]="loader" (click)="generateReport()" matTooltip="Generate New Report" matTooltipPosition="below"  class="st-act" *ngIf="isAgencyHead || isArta">
                <span *ngIf="loaderGenerateDoc" class="d-flex justify-content-center align-self-center">
                    <div class="spinner-border text-light" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                </span>
                <i class="fas fa-file-pdf fa-lg"></i>
            </button>
        </div>   
        <div class="row pt-2 m-0" >
            <div *ngIf="currentCC" class="d-flex flex-column justify-content-center align-items-center agency-header" >
                <h1>{{ currentCC.fromAgencyName }}</h1>
                <p class="mb-0 ">Citizen's Charter {{ transform(currentCC?.edition ? currentCC.edition : null, currentCC.date_posted.toDate().getFullYear()) }}</p>
            </div>
        </div>  
    </div>
</mat-toolbar>
  


      <app-artemis-citizens-charter-view
    [cc_id]="cc_id"
    [agency_id]="agency_id"
    [loader]="loader"
    (changeDetect)="changeReceiver($event)"
></app-artemis-citizens-charter-view>

