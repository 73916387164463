import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { EffectivityCitizensCharter, RemarkResolvedStatusCitizensCharter, WorklistStatusCitizensCharter } from 'src/app/entities/worklist';
import { GovServiceService } from 'src/app/service/gov-service.service';
import { RequriementsService } from 'src/app/service/requriements.service';
import { ArtemisViewFeesComponent } from '../artemis-view-fees/artemis-view-fees.component';

@Component({
  selector: 'app-citizens-charter-service-view',
  templateUrl: './citizens-charter-service-view.component.html',
  styleUrls: ['./citizens-charter-service-view.component.scss']
})
export class CitizensCharterServiceViewComponent implements OnInit {
  @Input() service: any;
  @Input() counter: any;

  @Input() service_id :any;
  @Input() reviewMode : boolean = false;
  @Input() openRemarks :boolean = false;
  @Input() service_status : WorklistStatusCitizensCharter
  @Input() service_effectivity : EffectivityCitizensCharter

  client_Step:any = "ClientStep"
  agency_action: any = "AgencyAction"
  fees :any ='Fees'
  worklist_status = WorklistStatusCitizensCharter
  effectivity = EffectivityCitizensCharter
 
  constructor(
    private auth : AngularFireAuth,
    private afs :  AngularFirestore,
    private _service : GovServiceService,
    public requirementservice : RequriementsService,
    private matdialog :MatDialog
  ){

  }

   getJSONValue(val:any){
    let temp:any[] = []
    val.forEach((info:any)=>{
      temp.push(info.name)
    })
    return JSON.stringify(temp)
  }

  ngOnInit(): void {

    this._service.maxHours = this.service.serviceDetails?.hour_per_day || 8

    this.loadRemarksData()
 
  }

  remarks_thread: any = []
  

  loadRemarksData(){
    if(!this.service_id) return;
    this.afs.collection("Service Remarks",
    filter => filter.where("service_id","==",this.service_id)
    .where('status','!=',RemarkResolvedStatusCitizensCharter.DELETED))
    .snapshotChanges().pipe(tap((data:any)=>{
        this.remarks_thread = []

        data.forEach((info:any)=>{

            let remark: any = info.payload.doc.data()
            remark._id = info.payload.doc.id;
            this.remarks_thread.push(remark)
            
        })
    })).subscribe()
  }


 async getCurrentUser(){

  const test = await this.auth.currentUser;
  const user_position = sessionStorage.getItem('account_subtype') || '';


  const remark_user = {
    email: test?.email,
    name: test?.displayName,
    user_id: test?.uid,
    position: user_position
  };

  return remark_user


  }


  get group_office(){

    const temp :any[] = [...this.service.serviceDetails.office_division,this.service.serviceDetails.process_owner]
    return temp.filter((f:any)=> f?.groupOffice === true)
  }



  displaySteps(steps:any){
    //console.log(steps)
  }

  filterFormulaFees(clientSteps:any[]){
    let temp_array:any = []
      clientSteps.forEach((step:any)=>{

        if (step.info?.list_of_formula_fees_arr && step.info.list_of_formula_fees_arr.length > 0){

          step.info.list_of_formula_fees_arr.forEach((data:any)=>{
            if(temp_array.filter((row:any)=> row.fee_type == data.fee_type).length <= 0){
              data.situation = step.info?.situation
              temp_array.push(data)
            }
          })

        }

      })
      return temp_array;
  }

   situational_clientStep(step: number ,index: number){
    //console.log("Situational Step",this.service.clientSteps[step].clientStep[index])
    return this.service.clientSteps[step].clientStep[index]
  }

  get situational_steps() {
    if(!this.service) return [];
    
    let return_arr: any[] = [];
    this.service.clientSteps.forEach((data:any)=>{

      //console.log("Client Ste",data)
      //console.log(data);
      //situational client step object
      /**
       * data.situational : boolean
       * data.clientStep : array/ list of situational client step
       * data.situational_step_title title of situational step
       */
      if(data.situational){
        //loop to situation client steps
          data.clientStep.forEach((situation:any)=>{
            return_arr.push(situation);
          })
      }

    });

    return return_arr;
  }

  openFees(fees:any){

    this.matdialog.open(ArtemisViewFeesComponent, {
      data : {
        fees : fees
      }
    })

  }

  filterOfficeDivision(value:any,array:any[]= []){
    return array.filter((f:any)=> f !== value).slice()
  }

  // Proxy method for actual computeTotalFees service
  computeTotalFees(client_step_arr:any, situationalStep?:any){
    //console.log("Step array",this._service.computeTotalFees(client_step_arr, situationalStep))
    return this._service.computeTotalFees(client_step_arr, situationalStep);
  }

  // Proxy method for actual computeTotalProcessingTime service
  computeProcessingTime(client_step_arr:any, situationalStep?:any) {
    return this._service.computeTotalProcessingTime(client_step_arr, situationalStep).totalProcessingTimeStr
  }

  filterPossibleFees(clientSteps:any[]){
    let temp_array:any = []
      clientSteps.forEach((step:any)=>{

        if(step.info?.list_of_possible_fees_arr && step.info?.list_of_possible_fees_arr.length > 0){

          step.info.list_of_possible_fees_arr.forEach((data:any)=>{
            if(temp_array.filter((row:any) => row ==data).length <= 0){
              temp_array.push(data)
            }
          })

        }
  
      })
  
      return temp_array;
  }
}