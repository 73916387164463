import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-citizens-charter-processowner-list',
  templateUrl: './citizens-charter-processowner-list.component.html',
  styleUrls: ['./citizens-charter-processowner-list.component.scss']
})
export class CitizensCharterProcessownerListComponent implements OnInit {

  @Input() processowner: any[];
  @Input() services:any[] = [];
  
  ngOnInit(): void {
  }


  get ServiceList(){
    return this.services.filter(filter=> filter.serviceDetails.process_owner == this.processowner)
  }


  scrollTo(serviceId:any){
    if(serviceId){
      let element = document.getElementById(serviceId)
      if(element) element.scrollIntoView({ behavior:'auto', block: "center"});
    }
  }

}
