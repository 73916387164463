import { ReferenceCommonModalComponent } from './../modal/common-modal/reference-common-modal.component';
import { first } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-reference-sub-control',
  templateUrl: './reference-sub-control.component.html',
  styleUrls: ['./reference-sub-control.component.scss']
})
export class ReferenceSubControlComponent implements OnInit {
 
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @Input() currentRef:any = '';
  @Input() userAccess:any = '';
  @Input() isAdmin:any = '';
  refArr:any = []
  refDataSource:any = [];
  refDataSourceCol: string[] =['action','section','name','definition']

  searchForm = new FormGroup({
    search:new FormControl('')
  })

  constructor(
    private afs: AngularFirestore,
    public dialog: MatDialog,
  ) { } 

  ngOnInit(): void {
    this.loadRef(this.currentRef)
  }

  loadRef(refName:any){
    if(refName == 'Sector')
      this.loadData('Sector')
    else if(refName == 'Organization Code')
      this.loadData('Organization Code')
    else if(refName == 'Location Code')
      this.loadData('Location Code')
    else if(refName == 'PSIC Code')
      this.loadData('PSIC Code')
    
    if(this.userAccess == 'pbris'){
      if(refName == 'Stage of Business')
        this.loadData('Stage of Business')
      else if(refName == 'Division')
        this.loadData('Division')
      else if(refName == 'Jurisdiction')
        this.loadData('Jurisdiction')
      else if(refName == 'Case Use')
        this.loadData('Case Use')
      else if(refName == 'Agency Location')
        this.loadData('Agency Location')
      else if(refName == 'Document')
        this.loadData('Document')
      else if(refName == 'Instruments')
        this.loadData('Instruments')
    }

    if(this.userAccess == 'artemis'){
      if(refName == 'Agency')
        this.loadData('Agency')
      else if(refName == 'Agency Category')
        this.loadData('Agency Category')
      else if(refName == 'Classification')
        this.loadData('Classification')
      else if(refName == 'List of Services')
        this.loadData('List of Services')
      else if(refName == 'Service Category')
        this.loadData('Service Category')
      else if(refName == 'Stage of Life')
        this.loadData('Stage of Life')
    }
  }

  loadData(collection:any){
    this.afs.collection(collection, filter => filter.orderBy("section", "asc")).snapshotChanges()
    .pipe(first())
    .subscribe({
      next:(info)=>{
        let tempArr:any = []
        info.forEach(data=>{
          if(data.payload.doc.exists){
            let ref: any = data.payload.doc.data()
            ref.id = data.payload.doc.id
            if(!tempArr.includes(ref)) tempArr.push(ref)
          }
        })
        this.refArr = tempArr
        this.refDataSource = new  MatTableDataSource<any>(tempArr)
        this.resetSortPage()
      }
    })
  }

  
  search() {
    const filterValue = this.searchForm.value.search
    this.refDataSource.filter = filterValue.trim().toLowerCase();
    if (this.refDataSource.paginator) {
      this.refDataSource.paginator.firstPage();
    }
  }

  searchFilter(){
    this.refDataSource.filterPredicate = (data:any, filter:string):boolean=>{
      return data.name.toLowerCase().includes(filter.toLowerCase())
      || data.section.toLowerCase().includes(filter.toLowerCase())
      || data.definition.toLowerCase().includes(filter.toLowerCase())
    }
  }

  resetSortPage(){
    this.refDataSource.paginator = this.paginator
    this.refDataSource.sort = this.sort
    this.searchFilter()
  }

  btnAction(action:any,currentRef:any, refId:any){
    let data:any ={
      action:action,
      currentRef:currentRef,
      refId:refId,
    }
    const dialogRef = this.dialog.open(ReferenceCommonModalComponent,{data:data})

    dialogRef.afterClosed().subscribe((result:any)=>{
      if(result != undefined && result.success && (action == 'create' || action == 'edit')){
        this.afs.collection(currentRef).doc(result.refId).snapshotChanges()
        .pipe(first())
        .subscribe({
          next:info=>{
            let item:any = info.payload.data()
            if(item != undefined){
              item.id = info.payload.id
              if(action == 'create'){
                if(!this.refArr.includes(item)){
                  this.refArr.push(item)
                  this.refDataSource = new  MatTableDataSource<any>(this.refArr)
                  this.resetSortPage()
                }
              }

              else if(action == 'edit'){
                let ctr = this.refArr.map((info:any)=>info.id).indexOf(result.refId)
                if(ctr >= 0){
                  this.refArr[ctr] = item
                  this.refDataSource = new  MatTableDataSource<any>(this.refArr)
                  this.resetSortPage()
                }
              }

            }
          },
          error:err=>{
            console.warn('err: ',err)
          }
        })
      }
      
      else if(result != undefined && result.success && action == 'delete'){
        let ctr = this.refArr.map((info:any)=>info.id).indexOf(result.refId)
        this.refArr.splice(ctr,1)
        this.refDataSource = new  MatTableDataSource<any>(this.refArr)
        this.resetSortPage()
      }
    })
  }
}