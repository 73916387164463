<div style="padding: 3em 2em; height: 100%;" class="relative">
    <a class="absolute" style="top:1em; right:2em; cursor: pointer; font-weight: bold;" (click)="closeModal()">x</a>
    <div class="row">
        <h2>Remarks on {{data.control.section}}</h2>
        <hr>
    </div>
    <div style="min-height:80%; max-height:80%; overflow-y: auto;">
        <table class="table table-striped table-responsive">
            <tbody>
                <ng-container *ngIf="data.control.score.previous_remarks.length > 0;else noRemarks">
                    <tr *ngFor="let user of data.control.score.previous_remarks">
                        <td>
                            <div>
                                <div class="d-flex justify-content-between flex-row ">
                                    <!-- <div style="font-weight:bold">{{user.userName}}</div> -->
                                    <div style="font-weight:bold">{{user.currentAccountSubtype}}</div>
                                    <!-- <div>{{user.date ? new Date(user.date) ? : : '--'}}</div> -->
                                    <div>{{checkData(user.date)}}</div>
                                    <!-- {{user.date.toDate()}} -->
                                </div>
                                <hr>
                                <div style="padding: 0 1em;">
                                    <!-- <p>{{user.remark}}</p>  -->
                                    <p [innerHtml]="user.remark | linebreaks"></p>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
                <ng-template #noRemarks>
                    <tr>
                        <td>
                            <div class="d-flex justify-content-center align-items-center">
                                <h2>No Remarks</h2>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
    <div class="form-group" [formGroup]="remarksForm">
        <textarea [disabled]="!data.action" class="form-control textArea" formControlName="remarks" placeholder="No Remarks. . ."></textarea>
    </div>
</div>