import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/firestore';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { CollectionName } from 'src/app/entities/constant';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import firebase from "firebase/app";
import { format } from 'date-fns';

import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'app-artemis-view-agency-regulation-list',
  templateUrl: './agency-regulations.component.html',
  styleUrls: ['./agency-regulations.component.scss']
})
export class ArtemisViewAgencyRegulationComponent implements OnInit {

  @Input('agency_doc') doc_id:any;

  agencyRegulationsDataSource: MatTableDataSource<any>;
  regulationsMap:Map<string,any> = new Map();
  referenceMap:Map<string, any> = new Map();
  
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  columns = ['regulations']

  searchForm: FormGroup = new FormGroup({
    keyword: new FormControl('')
  })

  constructor(
    private afs: AngularFirestore,
    private router: Router,
  )
  { }

  ngOnInit(): void {
    this.loadRegulations()
  }

  loadRegulations(){
    let batchFetch:any = []
    batchFetch.push(this.afs.collection(CollectionName.REGULATION_EXISTING_PHASE_2, filter=>this.filterFunc(CollectionName.REGULATION_EXISTING_PHASE_2,filter,'==')).snapshotChanges())
    batchFetch.push(this.afs.collection(CollectionName.REGULATION_EXISTING_PHASE_2, filter=>this.filterFunc(CollectionName.REGULATION_EXISTING_PHASE_2,filter,'array-contains')).snapshotChanges())
    
    combineLatest(batchFetch)
    .subscribe({
      next:(res)=>{
        Promise.all(
          res.map((res2:any)=>{
            return Promise.all(
              res2.map(async (res3:any)=>{
                let item:any = res3.payload.doc.data()
                item.id = res3.payload.doc.id
    
                item.displayDate = item.doc_date ? (item.doc_date instanceof Timestamp ? format(item.doc_date.toDate(), 'MM/dd/yyyy') : item.doc_date) : ""
                item.effectiveDate = item.date_effective ? (item.date_effective instanceof Timestamp ? format(item.date_effective.toDate(), 'MM/dd/yyyy') : item.date_effective) : ""
                item.amendDate = item.date_repeal ? (item.date_repeal instanceof Timestamp ? format(item.date_repeal.toDate(), 'MM/dd/yyyy') : item.date_repeal) : ""
                
                switch(item.reg_classification){
                  case 'Business':{
                    if(item.reg_sector){
                      if(this.referenceMap.has(CollectionName.SECTOR) && (this.referenceMap.get(CollectionName.SECTOR) as Map<string,any>).has(item.reg_sector)){
                        const data = (this.referenceMap.get(CollectionName.SECTOR) as Map<string,any>).get(item.reg_sector)
                        item.sector = data.name;
                      } else {
                        const data = await this.loadDataFromCollectionByID(CollectionName.SECTOR,item.reg_sector);
                        if(data){
                          item.sector = data.name
                        } else item.sector = ''
                      }
                    }
        
                    if(item.reg_division){
                      if(this.referenceMap.has(CollectionName.DIVISION) && (this.referenceMap.get(CollectionName.DIVISION) as Map<string,any>).has(item.reg_division)){
                        const data = (this.referenceMap.get(CollectionName.DIVISION) as Map<string,any>).get(item.reg_division)
                        item.division = data.name;
                      } else {
                        const data = await this.loadDataFromCollectionByID(CollectionName.DIVISION,item.reg_division);
                        if(data){
                          item.division = data.name
                        } else item.division = ''
                      }
                    }
    
                    if(item.reg_business){
                      if(this.referenceMap.has(CollectionName.STAGE_OF_BUSINESS) && (this.referenceMap.get(CollectionName.STAGE_OF_BUSINESS) as Map<string,any>).has(item.reg_business)){
                        const data = (this.referenceMap.get(CollectionName.STAGE_OF_BUSINESS) as Map<string,any>).get(item.reg_business)
                        item.sob = data.name;
                      } else {
                        const data = await this.loadDataFromCollectionByID(CollectionName.STAGE_OF_BUSINESS,item.reg_business);
                        if(data){
                          item.sob = data.name
                        } else item.sob = ''
                      }
                    }
    
                    if(item.reg_case){
                      if(this.referenceMap.has(CollectionName.CASE_USE) && (this.referenceMap.get(CollectionName.CASE_USE) as Map<string,any>).has(item.reg_case)){
                        const data = (this.referenceMap.get(CollectionName.CASE_USE) as Map<string,any>).get(item.reg_case)
                        item.case = data.name;
                      } else {
                        const data = await this.loadDataFromCollectionByID(CollectionName.CASE_USE,item.reg_case);
                        if(data){
                          item.case = data.name
                        } else item.case = ''
                      }
                    }
                    break;
                  }
                  case 'Non-Business':{
                    if(item.reg_stageoflife){
                      if(this.referenceMap.has(CollectionName.STAGE_OF_LIFE) && (this.referenceMap.get(CollectionName.STAGE_OF_LIFE) as Map<string,any>).has(item.reg_stageoflife)){
                        const data = (this.referenceMap.get(CollectionName.STAGE_OF_LIFE) as Map<string,any>).get(item.reg_stageoflife)
                        item.sol = data.stage;
                      } else {
                        const data = await this.loadDataFromCollectionByID(CollectionName.STAGE_OF_LIFE,item.reg_stageoflife);
                        if(data){
                          item.sol = data.stage
                        } else item.sol = ''
                      }
                    }
    
                    if(item.reg_lifeevent){
                      if(this.referenceMap.has(CollectionName.LIFE_EVENT) && (this.referenceMap.get(CollectionName.LIFE_EVENT) as Map<string,any>).has(item.reg_lifeevent)){
                        const data = (this.referenceMap.get(CollectionName.LIFE_EVENT) as Map<string,any>).get(item.reg_lifeevent)
                        item.le = data.event;
                      } else {
                        const data = await this.loadDataFromCollectionByID(CollectionName.LIFE_EVENT,item.reg_lifeevent);
                        if(data){
                          item.le = data.event
                        } else item.le = ''
                      }
                    }
                    break;
                  }
                }
                
                if(!this.regulationsMap.has(item.id)) this.regulationsMap.set(item.id,item)

                console.log('regulations: ',item)
                return item
              })
            )
          })
        )
        .then(()=>{
          this.refreshTable()
        })
      },
      error:(err)=>console.error('Error: ',err)
    })
  }

  
  loadDataFromCollectionByID(collection:string,id:string){
    return this.afs.collection(collection).doc(id).get().toPromise()
    .then((value)=>{
      if(value.exists){
        let item:any = value.data()
        item.id = value.id

        if(item._createdBy) delete item._createdBy
        if(item._updatedBy) delete item._updatedBy
        if(item.ref) delete item.ref

        if(this.referenceMap.has(collection)){
          let currentMap = this.referenceMap.get(collection)
          if(!currentMap.has(item.id)){
            currentMap.set(item.id, item)
            this.referenceMap.set(collection,currentMap)
          }
        }
        else{
          let currentMap:Map<string,any> = new Map()
          currentMap.set(item.id, item)
          this.referenceMap.set(collection,currentMap)
        }

        return item
      } else return false
    })
    .catch((err)=>{
      console.error('Error getting document from '+collection+' with id: '+id,err)
      return false
    })
  }

  filterFunc(controlCode:any, filter:Query, additionalOptions?:any){
    switch(controlCode){
      case CollectionName.REGULATION_EXISTING_PHASE_2:{
        filter = filter.orderBy('doc_date','desc').where('is_posted','==',true).where('reg_agency',additionalOptions,this.doc_id)
        break;
      }
    }
    return filter
  }

  search(event:Event){
    let searchValue = (event.target as HTMLInputElement).value
    this.agencyRegulationsDataSource.filter = searchValue.trim().toLowerCase()
  }

  resetSandP(){
    this.agencyRegulationsDataSource.paginator = this.paginator
    this.agencyRegulationsDataSource.sort = this.sort
    this.settingFilters()
  }

  settingFilters(){
    this.agencyRegulationsDataSource.filterPredicate = (option:any, searchValue:string): boolean =>{
      if(searchValue){
        return (option.title)?.trim().toLowerCase().includes(searchValue)
          || (option.subject)?.trim().toLowerCase().includes(searchValue)
      }else return false
    }
  }

  refreshList(){
    this.searchForm.reset()
    this.refreshTable()
  }

  refreshTable(){
    console.log('refreshTable')
    this.agencyRegulationsDataSource = new MatTableDataSource<any>(Array.from(this.regulationsMap.values()))
    this.resetSandP()
  }

  goToRegulation(id:any){
    // this.router.navigate(['/artemis/view/detail',id])
    this.router.navigate(['/pbris/regulations/view/existing',id])
  }

}