import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { ReferenceService } from 'src/app/service/reference.service';

@Component({
  selector: 'app-reuse-login',
  templateUrl: './reuse-login.component.html',
  styleUrls: ['./reuse-login.component.scss']
})
export class ReuseLoginComponent implements OnInit {

  method!: string;
  collection!: string;
  id?: any;

  header_content?: any;
  body_content?: any;
  buttons?: any;

  // extra links
  login_route!: string;
  notif_message_code!: string;
  userId!: string;
  updateAccessObj?: any;

  constructor(public modalRef: MDBModalRef, private auth: AngularFireAuth, private router: Router,
    private referenceService: ReferenceService, private afs: AngularFirestore) { }

  ngOnInit(): void {
  }

  confirm() {
    this.modalRef.hide();
    if (this.updateAccessObj) {
      this.afs.doc(`Users/${this.userId}`).update(this.updateAccessObj).then(() => {
        this.referenceService.getNotification(this.notif_message_code).then((data) => {
          data.subscribe((entry) => {
            alert(entry);
            this.reRoute(this.login_route);
          });
        });
      }).catch((err) => {
        alert("Error occurred! " + err)
      });
    }
  }

  cancel() {
    alert("Please provide another email address for your new login.")
    this.modalRef.hide();
  }

  reRoute(link: any) {
    this.router.navigate([link]);
  }

}
