
<div class = "container bg-light m-3">
    <div class="col">
        <button class = "btn btn-lg btn-outline-dark m-3" (click)="toggle_link()">{{link_toggle_status}} Important Tags</button>
    <ng-container *ngIf="show_links">
        <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'CBP Agency'}" class="btn col m-1 align-self-center">CBP Agency</a>
        <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'Nehemia Agency'}" class="btn col m-1 align-self-center">Nehemia Agency</a>
        <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'DB Indicator Agency'}" class="btn col m-1 align-self-center">DB Indicator Agency</a>
        <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'Council Agency'}" class="btn col m-1 align-self-center">Council Agency</a>
    </ng-container>
    </div>
</div>