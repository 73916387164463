
<div class="row">
    <div class="col" >
        <div class="row my-4">
            <div class="col ms-5" style="padding-right:5em"> 
                <div class="row">
                    <div class="card-header border-bottom border-4 border-dark">
                        <h3 class="info-header">Summary of RIS Assessment</h3>
                    </div>

                    <table mat-table [dataSource]="ris_scoring_test.controls"  class="mat-elevation-z8 demo-table">
                        <ng-container matColumnDef="section">
                            <th style="width: 35%;" class="theme-default" mat-header-cell *matHeaderCellDef>
                                <h5>Section</h5>
                            </th>
                            <td style="width: 35%" mat-cell *matCellDef="let element" [class.theme-default]="element.value.overall_score_flag">
                                <ng-container *ngIf="element.value.url; else noURL">
                                  <a [href]="element.value.url" target="_blank">
                                    {{ element.value.section }}
                                  </a>
                                </ng-container>
                                <ng-template #noURL>
                                  {{ element.value.section }}
                                </ng-template>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="rating">
                            <th style="width: 35%" class="theme-default" mat-header-cell *matHeaderCellDef>
                                <h5>Rating</h5>
                            </th>
                            <td style="width: 35%" mat-cell *matCellDef="let element; let ratingIndex = index" >
                                <div class="d-flex justify-content-center">
                                    <span [ngClass]="getColor2(element.value)" style="font-weight:bold">{{getSelectionItem(element.value)}}</span>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="remarks">
                            <th style="width: 30%" class="theme-default" mat-header-cell *matHeaderCellDef>
                                <h5>Remarks for Agency</h5>
                            </th>
                            <td style="width: 30%" mat-cell *matCellDef="let element" >
                                <div class="d-flex justify-content-center">
                                    <button class="btn btn-outline-primary" (click)="checkRemarks(element)">
                                        ({{element.value.score && element.value.score.previous_remarks ? element.value.score.previous_remarks.length : '0'}})
                                        <i class="far fa-comments"></i>Remarks
                                    </button>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>
                </div>
            </div>
        </div>

        <div class="row my-4">
            <div class="col ms-5 m-5">
                <div class="row">
                    <div class="card-header border-bottom border-4 border-dark">
                        <h3 class="info-header">Upload Assessment Documents</h3>
                    </div>
                    <div class="row" *ngIf="ris_documents_test.length > 0; else noUploaded">
                        <table mat-table [dataSource]="ris_documents_test.controls" class="mat-elevation-z8 demo-table">
                        <ng-container matColumnDef="docname">
                            <th style="width: 60%" class="theme-default" mat-header-cell *matHeaderCellDef>
                                <h5>Assessment Documents</h5>
                            </th>
                            <td style="width: 60%" mat-cell *matCellDef="let element">
                            <a [href]="element.value.url" class="" target="_blank">
                                {{ element.value.doc_name }}
                            </a>
                            </td>
                        </ng-container>
            
                        <ng-container matColumnDef="remarks">
                            <th style="width: 40%"  class="theme-default" mat-header-cell *matHeaderCellDef>
                                <h5>Remarks</h5>
                            </th>
                            <td style="width: 40%"  mat-cell *matCellDef="let element" >
                                <div class="d-flex justify-content-center">
                                    <button class="btn btn-outline-primary" (click)="checkDocumentRemarks(element)">
                                        ({{element.value.remarksDetails.previous_remarks ? element.value.remarksDetails.previous_remarks.length : 0}})
                                        <i class="far fa-comments"></i> Remarks
                                    </button>
                                </div>
                            </td>
                        </ng-container>
            
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsRisDocs"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsRisDocs"></tr>
                        </table>
                    </div>
                    
                    <ng-template #noUploaded>
                        <div style="padding-top: 2em;" class="d-flex justify-content-center">
                            <h4>No Uploaded Documents</h4>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>