<div [ngClass]="req_type === enum_requirement_type.STNDARD_WITH_TITLE? 'requirement-container': 'situation-container'" 
(mouseover)="hideButtons=true"
(mouseleave)="hideButtons=false">
<input id="title"  class="form-control" [formControl]="title" type="text" [(ngModel)]="situation.title">
<small class="text-danger" *ngIf="!title.value">Title is required*</small>
  <h6 class="card-title m-0 p-2" *ngIf="!fromReq" [ngStyle]="{'background-color': indexName ? '#D7F2FF' : 'white'}">{{indexName ? indexName + "." : ''}}<span class="toLower">{{ req_type === enum_requirement_type.STNDARD_WITH_TITLE && !fromchooseany? situationIndex + 1: requirementservice.printToLetter(situationIndex + 1)  }}.</span>{{situation.title}}</h6>
  <h6 class="card-title m-0 p-2" *ngIf="fromReq" [ngStyle]="{'background-color': fromReq ? '#E3E2EA' : 'white'}">{{indexName ? indexName + "." : ''}}<span class="toLower">{{ req_type === enum_requirement_type.STNDARD_WITH_TITLE  && !fromchooseany? situationIndex + 1: requirementservice.printToLetter(situationIndex + 1)  }}.</span>{{situation.title}}</h6>
  
  <div class="card-subtitle m-3" *ngIf="situation.chooseany" ><b><small>Any of the following</small></b>
  <br>
  <small class="text-danger" *ngIf="situation.requirement.length < 2">Invalid  (must be two or more requirement to choose from)</small>
  </div>
  
  <div *ngFor="let requirement of situation.requirement; let index = index">
      <app-requirement-control 
      *ngIf="!requirement.chooseany"
      [requirement]="requirement"
      [requirementIndex]="index"
      [referenceArray]="situation.requirement"
      [SituationIndex]="situationIndex"
      [chooseany]="situation.chooseany"
      [isSituational]="true"
      [req_type]="enum_requirement_type.SUB_SITUATIONAL_REQUIREMENT"
   
      ></app-requirement-control>
      
      <app-subsituational-requirement-control
     *ngIf="requirement.chooseany"
     [situation]="requirement"
     [situationIndex]="index"
     [referenceArray]="situation.requirement"
     [fromchooseany]="requirement.chooseany"
     [req_type]="enum_requirement_type.STNDARD_WITH_TITLE"
     ></app-subsituational-requirement-control>


      <p class="m-3" *ngIf="situation.chooseany && index != situation.requirement.length - 1 " ><small>or</small></p>
      
  </div>
  
  <div  class="d-inline-flex mt-3">

    <button mat-button matTooltip="Add Requirement" (click)="updateSituationalRequirement()" ><span><i class="fas fa-plus-circle"></i> Add Requirement</span> </button>
    <button mat-stroked-button  matTooltip="Paste" *ngIf="requirementservice.checkClipboard('requirement_copy')" (click)="pasteRequirement()"><span><i class="fas fa-paste"></i><!--Paste--> </span></button>
    
    <div *ngIf="situation.chooseany">
      <button mat-icon-button matTooltip="{{requirementservice.checkClipboard('requirement_copy')? 'Cancel' : 'Copy'}}" (click)="copyRequirement()"><span><i [ngClass]="requirementservice.checkClipboard('requirement_copy')? 'fas fa-ban' : 'fas fa-copy'"></i> <!--Copy--></span></button>
    </div>
    <div *ngIf="!situation.chooseany">
      <button mat-stroked-button matTooltip="Paste" *ngIf="requirementservice.checkClipboard('situation_copy')"  (click)="pasteSituation()"><span><i class="fas fa-paste"></i> Paste Situation</span></button>
      <button mat-button matTooltip="Copy" (click)="copySituation()"><span><i [ngClass]="requirementservice.checkClipboard('situation_copy')? 'fas fa-ban': 'fas fa-copy'"></i> {{requirementservice.checkClipboard('situation_copy')? 'Cancel': 'Copy'}}</span></button>
      
    </div>
    <button mat-button matTooltip="Remove" color="warn" (click)="removeRequirement()" ><span><i class="fas fa-trash"></i> Remove</span></button>


</div>
</div>


