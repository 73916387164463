<app-navbar [currentPage]="'Policy'"></app-navbar>

<div class="page-header theme-registration mb-3 py-3">
  <div class="container">
    <div class="row">
      <div class="col-12 title-widget info-widget">
        <h5 class="fw-bold py-2">THE PHILIPPINE REGULATORY POLICY</h5>
      </div>
      <div class="col-6 d-none d-md-block">
        <div class="float-end">
          <button type="button" class="btn btn-light btn-floating mx-1" style="width: auto; height: auto;" hidden>
            <a href="/pbris/regulations/existing" title="Existing Regulation">
              <img src="/assets/Assets/Icons/Existing%20Regulation.png" height="70" alt="Existing Regulation" />
            </a>
          </button>
          <button type="button" class="btn btn-light btn-floating mx-1" style="width: auto; height: auto;" hidden>
            <a href="/pbris/regulations/proposed" title="Proposed Regulation">
              <img src="/assets/Assets/Icons/Proposed%20Regulation.png" height="70" alt="Proposed Regulation" />
            </a>
          </button>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="container py-2 mb-5">
  <div class="row">
    <div class="col-md-7 col-sm-12">
      <div style="text-align: justify;">
        <p class="mx-1">
          Regulations can help to improve the well-being of citizens and the
          competitiveness of business firms. However, governments should be
          careful with issuing regulations. Some regulations can hurt the
          economy and people too because they can be costly in both economic and
          social terms. This is the problem of unnecessary and burdensome
          regulations. Because of regulations’ significant impact on people and
          firms, there is a need to develop effective and efficient regulations.
        </p>
        <p class="mx-1">
          The National Policy on Regulatory Management System shall ensure the
          adoption of good regulatory practices in the Philippine Government
          through the application of regulatory policies, tools, processes and
          institutional arrangements in the exercise of government regulatory
          functions. The NPRMS shall coordinate all regulatory activities of
          government to ensure that the regulatory environment in the
          Philippines best serves the Filipino people.
        </p>
        <p class="m-3">
          The embedding of good regulatory practices as part of the
          implementation of regulatory reforms is in line with the activities
          for the achievement of seamless service delivery under the Philippine
          Development Plan 2017-2022. The achievement of the outcome
          necessitates the modernization of government regulatory processes
          which includes the development of an overarching National Regulatory
          Architecture with the following components:
        </p>
        <ul class="m-3">
          <li>a comprehensive national regulatory policy;</li>
          <li>an appropriate institutional infrastructure to manage regulations;</li>
          <li>regulatory making and review process;</li>
          <li>regulatory framework per priority industry;</li>
          <li>a quality regulatory management system;</li>
          <li>a public consultation process;</li>
          <li>competency development programs for relevant government bodies;</li>
          <li>the Philippine Business Regulatory Information System.</li>
        </ul>
      </div>
    </div>

    <div class="col-md-5 col-sm-12 d-none d-md-block text-center text-center">
      <div class="p-3">
        <a href="https://arta.gov.ph/nprms/" target="_blank">
          <img src="/assets/Assets/pbris/NPRMS.JPG" class="regulatory-policy-nprms" width="500" height="500" title="National Policy on Regulatory Management System"/>
        </a> 
      </div>
    </div>

  </div>
</div>
