import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/messaging';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../../../../environments/environment';
import { SwUpdate, SwPush } from '@angular/service-worker';

import { HttpEventType } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';
import { formatISO } from 'date-fns';
import { Observable, of, Subject } from 'rxjs';
import { FileUploadService } from 'src/app/file-upload.service';

@Component({
  selector: 'app-pbris-account-ticket',
  templateUrl: './pbris-account-ticket.component.html',
  styleUrls: ['./pbris-account-ticket.component.scss']
})
export class PbrisAccountTicketComponent implements OnInit {
  new_tag_panel: boolean = false;
  regulations: any = [];
  ticket: any = [];
  loading: boolean = false;

  newTicket: FormGroup;

  dtTrigger: Subject<any> = new Subject<any>();
  showFormErrors: boolean = false;
  uploading: boolean = true;
  display_ticket_id: string = "";
  ticket_id: string = "";
  ticket_file: string = "";
  ticket_id_path: any;
  uploadProgress!: Observable<number | undefined>;
  expiresOnDateISO: string = "";

  constructor(private afs: AngularFirestore, private fb:FormBuilder,
    private router: Router, private route: ActivatedRoute, public fileUploadService: FileUploadService) { 
    this.newTicket = this.fb.group({
      nature: '',
      regulation: '',
      ticket_id: '',
      ticket_text: '',
      tags_list: this.fb.array([]),
    });

  }

  ngOnInit(): void {

    this.loadRegulations();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }


  openNewTag(){
    this.new_tag_panel = true;
    this.quantities().push(this.newQuantity());
  }

  quantities() : FormArray {
    return this.newTicket.get("tags_list") as FormArray
  }
   
  newQuantity(): FormGroup {
    return this.fb.group({
      tag_type: '',  
      tag_name: '',
    })
  }
   
  addQuantity() {
    this.quantities().push(this.newQuantity());
  }
   
  removeQuantity(i:number) {
    this.quantities().removeAt(i);

    if(this.quantities().length < 1){
      this.new_tag_panel = false;
    }
  }

  upload(event: any, type: any) {
    var allowedExtensions = /(\.jpg|\.jpeg|\.pdf|\.png)$/i;
    if(!allowedExtensions.exec(event.target.files[0].name)) {
      //this.newTicket.reset();
      this.ticket_id = "";
      this.display_ticket_id = "";
     
      this.uploading = true;
      //this.showFormErrors = true;
      return;
    }
    this.showFormErrors = false;

    if (type === "ticket") {
      this.ticket_id_path = event.target.files[0]
      this.display_ticket_id = this.ticket_id_path.name;

      if (this.ticket_id_path !== null) {
        this.uploading = true
        // new path to make it somewhat less difficult to navigate
        const filePath = `ticket/files/${formatISO(Date.now())}`;

        //new method that uses an API endpoint to access file storage through google cloud
        //old method was due to firebase DNS issues
        this.fileUploadService.uploadFile(this.ticket_id_path, filePath)
          .subscribe((resp) => {
            if (resp.type === HttpEventType.Response) {
              console.log('Upload complete');
              this.expiresOnDateISO = resp.body.expiresOn;
              this.ticket_file = resp.body.downloadUrl;
              this.uploading = false;
            }
            if (resp.type === HttpEventType.UploadProgress) {
              const total = resp.total || 1
              const percentDone = Math.round(100 * resp.loaded / total);
              this.uploadProgress = of(percentDone);
            }
          });
      }
    }
  }

  
   
  onSubmit() {
    this.afs.collection(`Tickets`).add({
      nature: this.newTicket.controls['nature'].value,
      regulation: this.newTicket.controls['regulation'].value,
      ticket_text:  this.newTicket.controls['ticket_text'].value,
      ticket_file:  this.ticket_file,
      tags_list:  this.newTicket.controls['tags_list'].value,
    }).then((docRef: any) => {
      alert("create ticket successful");
      console.log(docRef)
      //const documentID = docRef.id;

      this.router.navigate(['/pbris/regulatory-management/']);
      this.loading = false
    }).catch(error => {
      this.loading = false
      alert(error.message)
    });

    console.log(this.newTicket.value);

    this.newTicket.reset;
  }

  async loadRegulations() {
    this.afs.collection('PBRIS 1 Regulations', filter => filter.orderBy('short_title')).snapshotChanges().subscribe((data: any) => 
    {
      this.regulations = []
      data.forEach((results: any) => {
        let item: any = results.payload.doc.data();
        item.id = results.payload.doc.id;

        this.regulations.push(item);
        })
      
      });
  } 
}
