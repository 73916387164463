import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pbris-account-page',
  templateUrl: './pbris-account-page.component.html',
  styleUrls: ['./pbris-account-page.component.scss']
})
export class PbrisAccountPageComponent implements OnInit {
  dashboard: boolean = false
  subscription: boolean = false
  regulation: boolean = false
  comment: boolean = false
  ticket: boolean = false
  management: boolean = false
  subscription_options: boolean = false
  ticket_options: boolean = false
  agency: boolean = false
  sector: boolean = false
  createTicket: boolean = false
  sub_inbox:boolean = false;

  user_info: any = []
  loading: boolean = false

  constructor(public auth: AngularFireAuth, private router: Router, private afs: AngularFirestore) {}

  ngOnInit(): void {
    this.loading = true
    this.onUser()
  }

  async onUser() {
    this.auth.user.subscribe(data => {
      this.afs.doc(`Users/${data?.uid}`).get().subscribe((cred: any) => {
        this.user_info = cred.data();
        this.openDashboard()
      });
    })
  }

  openDashboard() {
    this.loading = false
    this.dashboard = true
    this.subscription = false
    this.regulation = false
    this.management = false
    this.ticket = false
    this.comment = false
    this.sector = false
    this.agency = false
    this.ticket = false
    this.createTicket = false
    this.sub_inbox = false
  }

  openSubscription() {
    this.loading = false
    this.dashboard = false
    this.subscription = true
    this.regulation = false
    this.management = false
    this.ticket = false
    this.comment = false
    this.sector = false
    this.agency = false
    this.ticket = false
    this.createTicket = false
    this.sub_inbox = false
  }

  openRegulation() {
    this.loading = false
    this.dashboard = false
    this.subscription = false
    this.regulation = true
    this.management = false
    this.ticket = false
    this.comment = false
    this.sector = false
    this.agency = false
    this.ticket = false
    this.createTicket = false
    this.sub_inbox = false
  }

  openComment() {
    this.loading = false
    this.dashboard = false
    this.subscription = false
    this.regulation = false
    this.management = false
    this.ticket = false
    this.comment = true
    this.sector = false
    this.agency = false
    this.ticket = false
    this.createTicket = false
    this.sub_inbox = false
  }

  openTicket() {
    this.loading = false
    this.dashboard = false
    this.subscription = false
    this.regulation = false
    this.management = false
    this.ticket = true
    this.comment = false
    this.sector = false
    this.agency = false
    this.ticket = false
    this.createTicket = false
    this.sub_inbox = false
  }

  openManagement() {
    this.loading = false
    this.dashboard = false
    this.subscription = false
    this.regulation = false
    this.management = true
    this.ticket = false
    this.comment = false
    this.sector = false
    this.agency = false
    this.ticket = false
    this.createTicket = false
    this.sub_inbox = false
  }

  openAgency() {
    this.loading = false
    this.dashboard = false
    this.subscription = false
    this.regulation = false
    this.management = false
    this.ticket = false
    this.comment = false
    this.sector = false
    this.agency = true
    this.ticket = false
    this.createTicket = false
    this.sub_inbox = false
  }

  openSector() {
    this.loading = false
    this.dashboard = false
    this.subscription = false
    this.regulation = false
    this.management = false
    this.ticket = false
    this.comment = false
    this.sector = true
    this.agency = false
    this.ticket = false
    this.createTicket = false
    this.sub_inbox = false
  }

  onSubscription(data: boolean) {
    if(data) {
      this.subscription_options = false
      this.sub_inbox = false;
    } else {
      this.subscription_options = true
      this.sub_inbox = false;
    }
  }

  // onTicket(data: boolean) {
  //   if(data) {
  //     this.ticket_options = false
  //   } else {
  //     this.ticket_options = true
  //   }
  // }

  openTickets(){
    this.loading = false
    this.dashboard = false
    this.subscription = false
    this.regulation = false
    this.management = false
    this.ticket = false
    this.comment = false
    this.sector = false
    this.agency = false
    this.ticket = true
    this.createTicket = false
    this.sub_inbox = true
  }

  createTickets(){
    this.loading = false
    this.dashboard = false
    this.subscription = false
    this.regulation = false
    this.management = false
    this.ticket = false
    this.comment = false
    this.sector = false
    this.agency = false
    this.ticket = false
    this.createTicket = true
    this.sub_inbox = true
  }

  onLogout() {
    this.auth.signOut().then(() => {
      sessionStorage.clear();
      this.router.navigate(['/']);
    });
  }

}
