<!-- *ngIf="can_resolve  || isArtaRemark || user_position== position_type.AGENCY_ENC"-->
<div  style="border: 1px solid gainsboro; border-radius: 13px;background-color: rgb(255, 255, 255);" class="p-1 mb-1">
  <div [id]="remark._id" class="">
    <label class="card-title"
      ><i class="fas fa-user-circle"></i> {{ remark.user.name }} {{cur_user_id == remark.user.user_id ? '( Me )' : ''}}
    </label>
    <div class="remark-date">
       {{ remark.user.position }} |  <span
       [ngClass]="
         remark.status === enum_remark_resolved_status.UNRESOLVED
           ? 'badge badge-danger'
           : 'badge badge-primary'
       "
       ><i
         [ngClass]="
           remark.status === enum_remark_resolved_status.UNRESOLVED
             ? 'fas fa-exclamation-circle unresolve-status'
             : 'fas fa-check'
         "
       ></i
       ><span *ngIf="isArtaRemark">ARTA | </span>
       {{
         remark.status === enum_remark_resolved_status.UNRESOLVED
           ? "Unresolved"
           : "Resolved"
       }}
     </span>
    </div>
    <p matTooltip="{{'Remark Date : ' + remark.date.toDate() | date : 'MMMM dd, yyyy HH:mm:ss' }}" style="margin-left: 20px; margin-bottom: 1px; font-weight: 400">
      {{ remark.message }}
    </p>
    <form
      class="mx-3"
      *ngIf="
        !sending && remark.status === enum_remark_resolved_status.UNRESOLVED && allowedAction
      "
      action=""
      (click)="$event.stopPropagation()"
    >
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Reply Message</mat-label>
        <textarea (focusout)="writeReply(input)" required matInput #input></textarea>
        <mat-icon
          matTooltip="Send your reply"
          matSuffix
          >send</mat-icon
        >
      </mat-form-field>
    </form>
    <section *ngIf="sending">
      <label class="ms-3" for="">Sending...</label>
    </section>

    <!--remark reply-->
    <ng-container>
      <small *ngIf="replies.length" class="ms-3 text-muted">**User Replies**</small>
      <div *ngFor="let rep of replies; let i = index">
        <div
          *ngIf="i <= limit"
          class="ms-3 mt-1 p-1"
          style="border: 1px dotted #b1b1b1; border-radius: 7px;background-color: whitesmoke  ;"
          
        >
          <h6 class="card-title text-bold">
            <i class="fas fa-user-circle"></i> {{ rep.user.name }} {{cur_user_id == rep.user.user_id ? '( Me )' : ''}}
          </h6>
          <div class="remark-date"> {{ rep.user.position }}
          </div>
          <p matTooltip="{{ 'Reply Date : ' + rep.date?.toDate() | date : 'MMMM dd, yyyy HH:mm:ss' }}" style="margin-left: 20px; margin-bottom: 1px; font-weight: 400">
            {{ rep.message }}
          </p>
          <div class="d-flex justify-content-center align-items-center" *ngIf="cur_user_id == rep.user.user_id && allowedAction" >
            <button (click)="edit(true,rep)" *ngIf="remark.status != enum_remark_resolved_status.RESOLVED" mat-button >
              <i class="fas fa-edit"></i>Edit
            </button>
            <button (click)="removeReply(rep)" *ngIf="remark.status != enum_remark_resolved_status.RESOLVED" mat-button>
              <i class="fas fa-eraser"></i>Remove
            </button>
          </div>
        </div>
      </div>
      <button *ngIf="total > 5" mat-button (click)="seeMoreReply()">
        {{ limit == 4 ? "See more" : "See less" }}
      </button>
    </ng-container>

    <div class="button-container"></div>
    <div *ngIf="allowedAction" class="action-buttons">

      <button mat-button (click)="edit(false,remark)" *ngIf="can_resolve && remark.status != enum_remark_resolved_status.RESOLVED && allowedAction" >
        <i matTooltip="Edit Remark"  class="ms-2 fas fa-edit"></i> Edit 
      </button>

      <button
        color="primary"
        (click)="resolve()"
        class="mt-1"
        *ngIf="
          remark.status === enum_remark_resolved_status.UNRESOLVED &&
          can_resolve
        "
        mat-button
      >
        <i class="fas fa-check-circle"></i> Resolve
      </button>

      <button
        [disabled]="replies.length > 0"
        color="warn"
        [matMenuTriggerFor]="menu"
        *ngIf="
          remark.status === enum_remark_resolved_status.UNRESOLVED &&
          can_resolve
        "
        mat-button
      >
        <i class="fas fa-trash-alt"></i> Remove
      </button>
    </div>
    
    <mat-menu #menu="matMenu">
      <button (click)="remove()" mat-menu-item>
        <mat-icon>delete</mat-icon>
        <span class="text-dark">Remove</span>
      </button>
      <button color="warn" mat-menu-item>
        <mat-icon>cancel</mat-icon>
        <span class="text-danger">Cancel</span>
      </button>
    </mat-menu>
  </div>
</div>
