<div class="container-fluid">
    <table class="table table-responsive table-striped table-sm" mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChange($event)"  >
        
        <!-- Date CC posted -->
        <ng-container matColumnDef="dateposted">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Posted </th>
          <td mat-cell *matCellDef="let row">{{ row.date_posted.toDate() | date: "yyyy-MMM-dd" }} </td>
        </ng-container>
    
        <!-- CC Version -->
        <ng-container matColumnDef="ccversion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Citizen's Charter</th>
          <td mat-cell *matCellDef="let row " style="cursor:pointer;" class="text-primary"><u (click)="ViewCCDocument(row)">{{ transform(row.edition,row.date_posted.toDate().getFullYear())}}</u></td>
        </ng-container>
    
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
      </table>
      <mat-paginator [pageSizeOptions]="[10, 25, 50,100]" aria-label="Select page of users"></mat-paginator>
</div>