import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { first, take, tap } from 'rxjs/operators';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { RegulationTextNode } from 'src/app/entities/regulation-text-section';
import { AngularFireAuth } from '@angular/fire/auth';
import { PdfRenderService } from 'src/app/pdf-render.service';

@Component({
  selector: 'app-worklist-regulations-phase2-view',
  templateUrl: './worklist-regulations-phase2-view.component.html',
  styleUrls: ['./worklist-regulations-phase2-view.component.scss']
})
export class WorklistRegulationsPhase2ViewComponent implements OnInit {
  loading: boolean = false;
  isPhase2: boolean = true;
  isOnBeta: boolean = true;
  

  phase2Worklist: any;
  dataLoaded:any = {}

  edit_regulation_id=this.route.snapshot.params.id

  @Input() id:any;
  @Input() collection:any;
  @Input() commentButtonsEnabled = false;
  @Input() docuFromInboxList = '';
  @Input() paramFromInboxList = '';

  edit_worklist_id: any;

  agency_list:any=[]
  legalBases_list:any=[]
  internationalAgreement_list:any=[]

  existing_reg_download_url: string;

  // Tree selector START
  treeControl = new NestedTreeControl<RegulationTextNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<RegulationTextNode>();
  hasChild = (_: number, node: RegulationTextNode) => !!node.children && node.children.length > 0;
  regulation_sections_form: Map<string, any>;
  activeRegulationSection: any = null;
  activeRegulationSectionUuid: string = "";
  activeRegulationSubsection: any = null;
  activeRegulationSubsectionUuid: string = "";
  parentOfActiveRegulationSubsectionUuid: string = "";
  private _regulationTextFormIsInvalid: boolean = true;
  // Tree selector END

  constructor(private afs: AngularFirestore, private store: AngularFireStorage, private router: Router, private route: ActivatedRoute, public auth: AngularFireAuth, private pdf_render: PdfRenderService) {
    // Initial Regulation Section form
    this.loading=true
   
  }

  ngOnInit(): void {
    this.loadPhase2View()
  }

  private loadPhase2View(){
    this.afs.collection(this.collection)
    .doc(this.id)
    .snapshotChanges()
    .subscribe(
    async (data)=>{
      if(data.payload.exists){
        let reg_info:any = data.payload.data()
        this.phase2Worklist = reg_info
        console.log('reg_info: ',reg_info)
        if(this.collection == 'regulations-existing-phase-02'){
          if(reg_info.reg_instrument){
            // this.loadData('Instrument',reg_info.reg_instrument,'instrument_name')
             const item = await this.dataLoader('Instrument',reg_info.reg_instrument,'Instrument')
             if(item) this.phase2Worklist['instrument_name'] = item.name
          }
          if(reg_info.reg_sector){
            // this.loadData('Sector',reg_info.reg_sector,'sector_name')
            const item = await this.dataLoader('Sector',reg_info.reg_sector,'Sector')
            if(item) this.phase2Worklist['sector_name'] = item.name
          }
          if(reg_info.reg_business){
            // this.loadData('Stage of Business',reg_info.reg_business,'business_name')
            const item = await await this.dataLoader('Stage of Business',reg_info.reg_business,'StageOfBusiness')
            if(item) this.phase2Worklist['business_name'] = item.name
          }
          if(reg_info.reg_division){
            // this.loadData('Division',reg_info.reg_division,'division_name')
            const item = await this.dataLoader('Division',reg_info.reg_division,'Division')
            if(item) this.phase2Worklist['division_name'] = item.name
          }
          if(reg_info.reg_juris){
            // this.loadData('Jurisdiction',reg_info.reg_juris,'juris_name')
            const item = await this.dataLoader('Jurisdiction',reg_info.reg_juris,'Jurisdiction')
            if(item) this.phase2Worklist['juris_name'] = item.name
          }
          if(reg_info.reg_stageoflife){
            // this.loadData('Case Use',reg_info.reg_case,'case_name')
            const item = await this.dataLoader('Stage of Life',reg_info.reg_stageoflife,'StageOfLife')
            if(item) this.phase2Worklist['stageoflife_name'] = item.stage
          }
          if(reg_info.reg_lifeevent){
            // this.loadData('Case Use',reg_info.reg_case,'case_name')
            const item = await this.dataLoader('Life Event',reg_info.reg_lifeevent,'LifeEvent')
            if(item) this.phase2Worklist['lifeevent_name'] = item.event
          }
          if(reg_info.reg_case){
            // this.loadData('Case Use',reg_info.reg_case,'case_name')
            const item = await this.dataLoader('Case Use',reg_info.reg_case,'CaseUse')
            if(item) this.phase2Worklist['case_name'] = item.name
          }
          if(reg_info.reg_agency) 
              this.loadAgencies(reg_info.reg_agency)
          if(reg_info.reg_legal)
            // Legal Bases is text-based, the collection is only a list of suggestions
            this.legalBases_list=[]
            for( let x of reg_info.reg_legal){
              this.legalBases_list.push(x)
            }
          if(reg_info.reg_intl && reg_info.reg_intl.length >0){
            this.internationalAgreement_list=[]
            for( let x of reg_info.reg_intl){
              this.internationalAgreement_list.push(x)
            }
          }else this.internationalAgreement_list = []

          // file_file_name of existing
          if(reg_info.firebase_folder && reg_info.file_file_name){
            let path = `${reg_info.firebase_folder}/${reg_info.file_file_name}`;
            this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
              this.existing_reg_download_url = data; 
            });
          }

          console.log('subtags--')

          if(reg_info.has_sub_tags && reg_info.sub_tags){
            this.phase2Worklist['sub_tags'].map(async (sub_tag:any)=>{
              // sub_tag.classification = sub_tag.reg_classification
              if(sub_tag.reg_classification == 'Business'){
                if(sub_tag.reg_sector) {
                  const sector:any = await this.dataLoader('Sector',sub_tag.reg_sector,'Sector')
                  if(sector) sub_tag.sector_name = sector.name
                  else console.error('no sector retrieved')
                }
                if(sub_tag.reg_business){
                  const sob:any = await this.dataLoader('Stage of Business',sub_tag.reg_business,'StageOfBusiness')
                  if(sob) sub_tag.business_name = sob.name
                  else console.error('no sob retrieved')
                }

                if(sub_tag.reg_division) {
                  const division:any = await this.dataLoader('Division',sub_tag.reg_division,'Division')
                  if(division) sub_tag.div_name = division.name
                  else console.error('no division retrieved')
                }

                if(sub_tag.reg_case) {
                  const caseUse:any = await this.dataLoader('Case Use',sub_tag.reg_case,'CaseUse')
                  if(caseUse) sub_tag.case_name = caseUse.name
                  else console.error('no caseUse retrieved')
                }
              }

              else if (sub_tag.reg_classification == 'Non-Business'){
                if (sub_tag.reg_stageoflife) {
                  const sol:any = await this.dataLoader('Stage of Life',sub_tag.reg_stageoflife,'StageOfLife')
                  if(sol) sub_tag.sol_name = sol.stage
                  else console.error('no sol retrieved')
                }
                if (sub_tag.reg_lifeevent) {
                  const le:any = await this.dataLoader('Life Event',sub_tag.reg_lifeevent,'LifeEvent',)
                  if(le) sub_tag.le_name = le.event
                  else console.error('no life event retrieved')
                }
              }
            })
          }
        }
        // else if(this.collection == 'regulations-proposed-phase-02'){
        //   if(reg_info.tags && reg_info.reg_instrument) this.phase2Worklist['instrument_name'] = await this.dataLoader('Instrument',reg_info.reg_instrument,'Instrument')
        //     // this.loadData('Instrument',reg_info.reg_instrument,'instrument_name')
        //   if(reg_info.tags && reg_info.tags.reg_sector) this.phase2Worklist['sector_name'] = await this.dataLoader('Sector',reg_info.reg_sector,'Sector')
        //     // this.loadData('Sector',reg_info.tags.reg_sector,'sector_name')
        //   if(reg_info.tags && reg_info.tags.reg_business) this.phase2Worklist['business_name'] = await this.dataLoader('Stage of Business',reg_info.reg_business,'StageOfBusiness')
        //     // this.loadData('Stage of Business',reg_info.tags.reg_business,'business_name')
        //   if(reg_info.tags && reg_info.tags.reg_division) this.phase2Worklist['division_name'] = await this.dataLoader('Division',reg_info.reg_division,'Division')
        //     // this.loadData('Division',reg_info.tags.reg_division,'division_name')
        //   if(reg_info.tags && reg_info.tags.reg_juris) this.phase2Worklist['juris_name'] = await this.dataLoader('Jurisdiction',reg_info.reg_juris,'Jurisdiction')
        //     // this.loadData('Jurisdiction',reg_info.tags.reg_juris,'juris_name')
        //   if(reg_info.tags && reg_info.tags.reg_case) this.phase2Worklist['case_name'] = await this.dataLoader('Case Use',reg_info.reg_case,'CaseUse')
        //     // this.loadData('Case Use',reg_info.tags.reg_case,'case_name')
        //   if(reg_info.reg_agency)
        //       this.loadAgencies(reg_info.reg_agency)
        //   if(reg_info.tags && reg_info.tags.reg_legal)
        //     // Legal Bases is text-based, the collection is only a list of suggestions
        //     this.legalBases_list=[]
        //     for( let x of reg_info.tags.reg_legal){
        //       this.legalBases_list.push(x)
        //     }
        //   if(reg_info.tags && reg_info.tags.reg_intl && reg_info.tags.reg_intl.length >0){
        //     this.internationalAgreement_list=[]
        //     for( let x of reg_info.tags.reg_intl){
        //       this.internationalAgreement_list.push(x)
        //     }
        //   }
        // }
        this.loading=false
        // console.log("reg_info", reg_info)
        // if(reg_info && reg_info.reg_instrument){
        //   this.afs.collection('Instrument').doc(reg_info.reg_instrument)
        //   .snapshotChanges()
        //   .subscribe(
        //   data=>{
        //     let instrument_info: any = data.payload.data()  
        //     reg_info.instrument_name =instrument_info.name
            
        //     this.afs.collection('Sector').doc(reg_info.reg_sector)
        //     .snapshotChanges()
        //     .subscribe(
        //       data=>{   
        //         try{
        //           let sector_info: any = data.payload.data()  
        //           reg_info.sector_name =sector_info.name  

        //         }catch(e){
        //           // console.log('error getting Sector',e)
        //         } 

        //         this.afs.collection('Stage of Business').doc(reg_info.reg_business)
        //         .snapshotChanges()
        //         .subscribe(
        //           data=>{
        //             try{
                      
        //               let business_info: any = data.payload.data()  
        //               reg_info.business_name =business_info.name   
        //             }catch(e){
        //               // console.log('error getting Stage of Business',e)
        //             }

        //             this.afs.collection('Division').doc(reg_info.reg_division)
        //             .snapshotChanges()
        //             .subscribe(
        //               data=>{ 
        //                 try{
                      
        //                   let division_info: any = data.payload.data()  
        //                   reg_info.division_name =division_info.name  

        //                 }catch(e){
        //                   // console.log('error getting Division',e)
        //                 }

        //                 this.afs.collection('Jurisdiction').doc(reg_info.reg_juris)
        //                 .snapshotChanges()
        //                 .subscribe(
        //                   data=>{ 
        //                     try{
                            
        //                       let juris_info: any = data.payload.data()  
        //                       reg_info.juris_name =juris_info.name  

        //                     }catch(e){
        //                       // console.log('error getting Jurisdiction',e)
        //                     }
        //                     this.afs.collection('Case Use').doc(reg_info.reg_case)
        //                     .snapshotChanges()
        //                     .subscribe(
        //                       data=>{                        
        //                         try{
        //                           this.loading=false
        //                           let case_info: any = data.payload.data()  
        //                           reg_info.case_name =case_info.name  
          
        //                           this.phase2Worklist=reg_info    

        //                           if(reg_info.reg_agency){
        //                             this.loadAgencies(reg_info.reg_agency)
        //                           }
        //                           if(reg_info.reg_legal){
        //                             this.loadLegalBases(reg_info.reg_legal)
        //                           }
        //                           if(reg_info.reg_intl){
        //                             this.internationalAgreement_list=[]
        //                             for( let x of reg_info.reg_intl){
        //                               this.internationalAgreement_list.push(x)
        //                             }
        //                           }                              

        //                         }catch(e){
        //                           // console.log('error getting Case Use',e)
        //                         }
              
        //                       })
          
        //                   })
      
        //               })

        //           })
        //       })
        //   })
        // }
      }
    })
  }

  async dataLoader(collection:any,id:any,storeval:any):Promise<any>{
    if(collection && id && storeval){
      if(this.dataLoaded[storeval]){
        if(this.dataLoaded[storeval].length > 0){
          const loadedData = this.dataLoaded[storeval].map((val:any)=>val.id).indexOf(id)
          if(loadedData >= 0) return this.dataLoaded[storeval][loadedData]
          else return this.dataLoader2(collection, id, storeval)
        } else return this.dataLoader2(collection,id,storeval)
      } else {
        this.dataLoaded[storeval] = []
        return this.dataLoader(collection, id, storeval)
      }
    }
    else return false
  }

  async dataLoader2(collection:any,id:any,storeval:any){
    const item = await this.loadData(collection,id)
    if(item){
      if(this.dataLoaded[storeval].map((val:any)=>val.id).indexOf(id) == -1)this.dataLoaded[storeval].push(item)
      return item
    }
    else return false
  }

  loadData(collection:any, value:any){
    // get data from firebase if no data was retrieved return false
    return new Promise((res,rej)=>{
      this.afs.collection(collection).doc(value).snapshotChanges()
      .subscribe({
        next:(data)=>{  
          if(data.payload.exists){
            let item:any = data.payload.data()
            item.id = data.payload.id

            if(item.ref) delete item['ref']
            if(item._createdBy) delete item['_createdBy']
            if(item._updatedBy) delete item['_updatedBy']
            return res(item)
          } else return res(false)
        },
        error:(err)=>{
          console.error('loadData Error: ',err)
          return res(false)
        }
      })
    })
  }

  // loadData(collection:any,id:any,storeval:any){
  //   this.afs.collection(collection).doc(id).snapshotChanges()
  //   .pipe(first())
  //   .subscribe(data=>{
  //     let item:any = data.payload.data();
  //     this.phase2Worklist[storeval] = item.name
  //   })
  // }

  // Because reg_agency can be single input or multiple input, handle as if it were a strong or an object (array)
  private loadAgencies(agency_list:any){
    if(typeof agency_list === 'string') {
      this.afs.collection('Agency').doc(agency_list)
      .snapshotChanges()
      .subscribe(
        data=>{
          this.agency_list=[]
          let agency_info: any = data.payload.data()  
          this.agency_list.push(agency_info.name)          
  
      });
    }
    else if(typeof agency_list === 'object') {
      if(agency_list.length > 0){
        for(let x of agency_list){
          if(x !== ''){
            this.afs.collection('Agency').doc(x)
            .snapshotChanges()
            .subscribe(
              data=>{
                this.agency_list=[]
                let agency_info: any = data.payload.data()  
                this.agency_list.push(agency_info.name)          
        
            });
          }
        }
      }
    }
    else {
      this.agency_list = [];
    }
  }

  private loadLegalBases(bases_list:any){
    if(bases_list.length > 0){
      for(let x of bases_list){
        if(x !== ''){
          this.afs.collection('Legal Bases').doc(x)
          .snapshotChanges()
          .subscribe(
            data=>{
              this.legalBases_list=[]
              let bases_info: any = data.payload.data()  
              this.legalBases_list.push(bases_info.name)
          })
       }
      }
    } else this.legalBases_list = []
  }

  private loadInternationalAgreement(intAgreement_list:any){
    for(let x of intAgreement_list){
      this.afs.collection('International Agreements').doc(x)
      .snapshotChanges()
      .subscribe(
        data=>{
          this.internationalAgreement_list=[]
          let intAgree_info: any = data.payload.data()  
          this.internationalAgreement_list.push(intAgree_info.name)
          
          // console.log( this.internationalAgreement_list)
  
      })
    }
  }

  goToComments(type: string, sectionId?: number, subsectionId?: number) {
    let queryParams: any = { worklistId: this.docuFromInboxList, type: type };
    // Existing or proposed
    let commentUrl = '/pbris/upload-beta/existing/comment/';
    if(this.collection == 'regulations-proposed-phase-02') {
      commentUrl = '/pbris/upload/proposed/comment/';
    }
    
    if(sectionId != null) {
      queryParams.sectionId = sectionId;
    }

    if(subsectionId != null) {
      queryParams.subsectionId = subsectionId;
    }
    
    this.router.navigate(
      [commentUrl + this.paramFromInboxList],
      { queryParams: queryParams }
    );
  }
}
