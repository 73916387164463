import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { ARTEMIS, PBRIS } from 'src/app/entities/constant';

@Component({
  selector: 'app-wel-navbar',
  templateUrl: './wel-navbar.component.html',
  styleUrls: ['./wel-navbar.component.scss']
})
export class WelNavbarComponent implements OnInit {

  isAdmin: boolean = false;

  cred:any

  pbris = PBRIS
  artemis = ARTEMIS

  constructor(public auth: AngularFireAuth,
    private router: Router,
    private afs: AngularFirestore) { }

  ngOnInit(): void {
    this.loadUsers()
  }
  // routerLink="/pbris/"
  // routerLink="/artemis/citizen-charter"

  goTo(code:any){
    if(code == this.pbris){
      sessionStorage.setItem("currentSystem",PBRIS)
      this.router.navigate(['/pbris'])
    }
    else if(code == this.artemis){
      sessionStorage.setItem("currentSystem",ARTEMIS)
      this.router.navigate(['/artemis/citizen-charter'])
    }
  }

  async loadUsers(){
    this.auth.user.subscribe(data=>{
      this.afs.doc(`Users/${data?.uid}`).get().subscribe((cred:any) =>{
        this.cred = cred.data();
        if(cred.data() && cred.data().credentials.account_subtype.toLowerCase().includes('admin')) {
          this.isAdmin = true;
        }
      });
    })
  }

  async logout() {
    await this.auth.signOut().then(() => {
      sessionStorage.clear();
      this.router.navigate(['/']);
    });
  }
}