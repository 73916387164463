<!--registration card-->
<div class="card-header p-0 ">
  <app-artemis-login-banner></app-artemis-login-banner>
</div>

<div class="card-body">
  <!-- Terms and Conditions -->
  <ng-container *ngIf="show_terms_and_conditions">
    <h4 class="text-uppercase fw-bolder m-0 text-center">TERMS & CONDITIONS</h4>
    <div class="form-group terms-conditions-container">
      <!--<app-terms-and-conditions-PBRIS></app-terms-and-conditions-PBRIS>-->
      <div class="d-flex justify-content-center align-self-center">
        <div class="spinner-grow" role="status" [hidden]="terms_and_conditions_JSON">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div class="termsandconditions" [hidden]="!terms_and_conditions_JSON">
        <div *ngFor="let section of terms_and_conditions_JSON">
          <p style="font-weight: bolder">{{ section.title }}</p>
          <div [innerHtml]="section.description"></div>
          <br />
        </div>
      </div>

      <!--CHECKBOX-->
      <!--Seperate checkbox and label in a row using column-->
      <div class="row">
        <div class="col-1">
          <div class="form-check mx-auto" [hidden]="!terms_and_conditions_JSON">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" (change)="checkbox($event)"
              ng-required="true" />

          </div>

        </div>
        <div class="col">
          <label class="form-check-label fst-italic" for="flexCheckDefault">
            I consent to the terms and conditions in the use of the ARTEMIS
          </label>
        </div>

      </div>

    </div>
  </ng-container>

  <!-- Data Privacy Consent -->
  <ng-container *ngIf="show_data_privacy">
    <h4 class="text-uppercase fw-bolder m-0 text-center">DATA PRIVACY</h4>
    <div class="form-group terms-conditions-container">
      <!--<app-terms-and-conditions-PBRIS></app-terms-and-conditions-PBRIS>-->
      <div class="termsandconditions" [hidden]="!privacy_policy_JSON">
        <div *ngFor="let section of privacy_policy_JSON">
          <p style="font-weight: bolder">{{ section.title }}</p>
          <div [innerHtml]="section.description"></div>
          <br />
        </div>
      </div>

      <!--CHECKBOX-->
      <!--Seperate checkbox and label in a row using column-->
      <div class="row">
        <div class="col-1">
          <div class="form-check mx-auto" [hidden]="!privacy_policy_JSON">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" (change)="checkbox($event)"
              ng-required="true" />

          </div>

        </div>
        <div class="col">
          <label class="form-check-label fst-italic" for="flexCheckDefault">
            I consent to the processing of my personal information
          </label>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- First Registration -->
  <ng-container *ngIf="show_first_registration">
    <!-- && accountType.selectedValue == 'Personal'-->
    <p class="text-center fw-bold">R E G I S T R A T I O N</p>
    <div class="form-group terms-conditions-container">
      <p class="fw-bold border-bottom border-dark">Log-In Credentials</p>
      <form [formGroup]="registrationform">
        <!-- [formGroup]="form" (ngSubmit)="onSubmit()" -->
        <div class="form-group">
          <div class="form-group">
            <label for="email">Email Address</label>
            <!-- <input
              type="email"
              class="form-control"
              [class.bg-danger]="
                show_first_registration &&
                showFormErrors &&
                registrationform.controls.email.invalid
              "
              placeholder="Email Address"
              formControlName="email"
              ng-required="true"
            /> -->
            <input type="email" class="form-control" [class.bg-danger]="isInvalid('registrationform','email')"
              placeholder="Email Address" formControlName="email" ng-required="true" />
            <!-- <div
              class="form-helper text-danger"
              *ngIf="
                show_first_registration &&
                showFormErrors &&
                registrationform.controls.email.hasError('required')
              "
            >
              Email Address is required
            </div> -->
            <ng-container *ngFor="let item of checkRegistrationForm.email">
              <div class="form-helper text-danger" *ngIf="hasError('registrationform','email',item.type)">*
                {{item.message}}</div>
            </ng-container>
          </div>

          <ng-container formGroupName="passwordGroup">
            <div class="form-group">
              <label for="password">Password</label>
              <input type="password" class="form-control" [class.bg-danger]="isInvalid('passwordGroup','password')"
                placeholder="Password" formControlName="password" ng-required="true" #password />
              <mat-password-strength #passwordComponent (onStrengthChanged)="onStrengthChanged($event)"
                [password]="password.value">
              </mat-password-strength>

              <div class="my-3">
                <mat-password-strength-info [passwordComponent]="passwordComponent">
                </mat-password-strength-info>
              </div>
              <!-- password hint -->
              <mat-hint align="end" aria-live="polite">
                {{password.value.length}} / {{passwordComponent.max}}
              </mat-hint>

              <!-- password error msgs -->
              <mat-error *ngIf="passwordComponent.passwordFormControl.hasError('required')">
                Password is required
              </mat-error>
              <mat-error *ngIf="passwordComponent.passwordFormControl.hasError('pattern')">
                Password is not valid
              </mat-error>
            </div>
            <div class="form-group">
              <label for="confirmpassword">Confirm Password</label>
              <input type="password" class="form-control"
                [class.bg-danger]="isInvalid('passwordGroup','confirmpassword')" placeholder="Re-enter Password"
                formControlName="confirmpassword" ng-required="true" />
              <ng-container *ngFor="let item of checkRegistrationForm.confirmpassword">
                <div class="form-helper text-danger" *ngIf="hasError('passwordGroup','confirmpassword',item.type)">*
                  {{item.message}}</div>
              </ng-container>
              <ng-container *ngFor="let item of checkRegistrationForm.passwordGroup">
                <div class="form-helper text-danger" *ngIf="hasError('registrationform','passwordGroup',item.type)">*
                  {{item.message}}</div>
              </ng-container>
            </div>
          </ng-container>
          <!-- <div class="form-group">
            <label for="password">Password</label>
                                          <input
                                            type="password"
                                            class="form-control"
                                            [class.bg-danger]="
                                              show_first_registration &&
                                              showFormErrors &&
                                              registrationform.controls.password.invalid
                                            "
                                            placeholder="Password"
                                            formControlName="password"
                                            ng-required="true"
                                            #password
                                          />
            <input
              type="password"
              class="form-control"
              [class.bg-danger]="isInvalid('registrationform','password')"
              placeholder="Password"
              formControlName="password"
              ng-required="true"
              #password
            />
            <mat-password-strength
              #passwordComponent
              (onStrengthChanged)="onStrengthChanged($event)"
              [password]="password.value"
            >
            </mat-password-strength>

            <div class="my-3">
              <mat-password-strength-info
                [passwordComponent]="passwordComponent"
              >
              </mat-password-strength-info>
            </div>
                                                  password hint
            <mat-hint align="end" aria-live="polite">
              {{password.value.length}} / {{passwordComponent.max}}
            </mat-hint>
                                                  password error msgs
            <mat-error *ngIf="passwordComponent.passwordFormControl.hasError('required')">
              Password is required
            </mat-error>
            <mat-error *ngIf="passwordComponent.passwordFormControl.hasError('pattern')">
              Password is not valid
            </mat-error>
          </div>
          <div class="form-group">
            <label for="confirmpassword">Confirm Password</label>
                                                <input
                                                  type="password"
                                                  class="form-control"
                                                  [class.bg-danger]="
                                                    show_first_registration &&
                                                    showFormErrors &&
                                                    registrationform.controls.confirmpassword.invalid
                                                  "
                                                  placeholder="Re-enter Password"
                                                  formControlName="confirmpassword"
                                                  ng-required="true"
                                                />
            <input
              type="password"
              class="form-control"
              [class.bg-danger]="isInvalid('registrationform','confirmpassword')"
              placeholder="Re-enter Password"
              formControlName="confirmpassword"
              ng-required="true"
            />
            <ng-container *ngFor="let item of checkRegistrationForm.confirmpassword">
              <div class="form-helper text-danger" *ngIf="hasError('registrationform','confirmpassword',item.type)">* {{item.message}}</div>
            </ng-container> 
                                              <div
                                                class="form-helper text-danger"
                                                *ngIf="
                                                  show_first_registration &&
                                                  showFormErrors &&
                                                  (registrationform.controls.confirmpassword.hasError(
                                                    'required'
                                                  ) ||
                                                    registrationform.controls.confirmpassword.hasError(
                                                      'matchingPassword'
                                                    ))
                                                "
                                              >
                                                Password does not match
                                              </div>
          </div> -->
          <div class="form-group">
            <label for="seconddropdowntitle">Mobile Number</label>
            <!-- <input
              type="text"
              pattern="^[a-z0-9_-]{8,15}$"
              class="form-control"
              maxlength="10"
              [class.bg-danger]="
                show_first_registration &&
                showFormErrors &&
                registrationform.controls.mobile_number.invalid
              "
              (keypress)="numberInputOnly($event)"
              placeholder="+63"
              formControlName="mobile_number"
              ng-required="true"
            /> -->
            <input type="text" pattern="^[a-z0-9_-]{10,15}$" class="form-control" maxlength="10"
              [class.bg-danger]="isInvalid('registrationform','mobile_number')" (keypress)="numberInputOnly($event)"
              placeholder="+63" formControlName="mobile_number" ng-required="true" />
            <ng-container *ngFor="let item of checkRegistrationForm.mobile_number">
              <div class="form-helper text-danger" *ngIf="hasError('registrationform','mobile_number',item.type)">*
                {{item.message}}</div>
            </ng-container>
            <!-- <div
              class="form-helper text-danger"
              *ngIf="
                show_first_registration &&
                showFormErrors &&
                registrationform.controls.mobile_number.hasError('required')
              "
            >
              Mobile Number is required
            </div>
            <div
              class="form-helper text-danger"
              *ngIf="
                show_first_registration &&
                showFormErrors &&
                registrationform.controls.mobile_number.hasError(
                  'invalidPhoneNumber'
                )
              "
            >
              Invalid Mobile Number
            </div> -->
          </div>
          <div class="form-group">
            <label for="seconddropdowntitle" class="fw-bold">Type of Account</label>
            <div>
              <!-- <select
                class="form-select"
                [class.bg-danger]="
                  show_first_registration &&
                  showFormErrors &&
                  registrationform.controls.account_type.invalid
                "
                formControlName="account_type"
                ng-required="true"
              > -->
              <select class="form-select" [class.bg-danger]="isInvalid('registrationform','account_type')"
                formControlName="account_type" ng-required="true">
                <option value="" disabled selected>Personal/Government</option>
                <option *ngFor="let x of TypeOfAccount" [value]="x">
                  {{ x }}
                </option>
              </select>
            </div>
            <ng-container *ngFor="let item of checkRegistrationForm.account_type">
              <div class="form-helper text-danger" *ngIf="hasError('registrationform','account_type',item.type)">*
                {{item.message}}</div>
            </ng-container>
            <!-- <div
              class="form-helper text-danger"
              *ngIf="
                show_first_registration &&
                showFormErrors &&
                registrationform.controls.account_type.hasError('required')
              "
            >
              Type of Account is required
            </div> -->
          </div>
        </div>
      </form>
    </div>
  </ng-container>

  <!-- Personal Details Registration -->
  <ng-container *ngIf="show_personal_registration">
    <p class="text-center fw-bold">
      <strong class="text-primary">PERSONAL ACCOUNT</strong> REGISTRATION
    </p>
    <p class="fw-bold border-bottom border-dark">Personal Details</p>
    <form [formGroup]="personalregistrationform">
      <div class="form-group">
        <div class="form-group">
          <label for="lastname" class="fw-bold">Last Name</label>
          <input type="text" class="form-control" [class.bg-danger]="isInvalid('personalregistrationform','last_name')"
            placeholder="Last Name" formControlName="last_name" ng-required="true" />
          <!-- <input
            type="text"
            class="form-control"
            [class.bg-danger]="
              show_personal_registration &&
              showFormErrors &&
              personalregistrationform.controls.last_name.invalid
            "
            placeholder="Last Name"
            formControlName="last_name"
            ng-required="true"
          /> -->
          <!-- <div
            class="form-helper text-danger"
            *ngIf="
              show_personal_registration &&
              showFormErrors &&
              personalregistrationform.controls.last_name.hasError('required')
            "
          >
            Last Name is required
          </div> -->
          <ng-container *ngFor="let item of checkPersonalRegistrationForm.last_name">
            <div class="form-helper text-danger" *ngIf="hasError('personalregistrationform','last_name',item.type)">*
              {{item.message}}</div>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="firstname" class="fw-bold">First Name</label>
          <input type="text" class="form-control" [class.bg-danger]="isInvalid('personalregistrationform','first_name')"
            placeholder="First Name" formControlName="first_name" ng-required="true" />
          <!-- <input
            type="text"
            class="form-control"
            [class.bg-danger]="
              show_personal_registration &&
              showFormErrors &&
              personalregistrationform.controls.first_name.invalid
            "
            placeholder="First Name"
            formControlName="first_name"
            ng-required="true"
          /> -->
          <!-- <div
            class="form-helper text-danger"
            *ngIf="
              show_personal_registration &&
              showFormErrors &&
              personalregistrationform.controls.first_name.hasError('required')
            "
          >
            First Name is required
          </div> -->
          <ng-container *ngFor="let item of checkPersonalRegistrationForm.first_name">
            <div class="form-helper text-danger" *ngIf="hasError('personalregistrationform','first_name',item.type)">*
              {{item.message}}</div>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="sex" class="fw-bold">Sex</label>
          <!-- <div
            class="form-control"
            [class.bg-danger]="
              show_personal_registration &&
              showFormErrors &&
              personalregistrationform.controls.sex.invalid
            "
          > -->
          <div class="form-control" [class.bg-danger]="isInvalid('personalregistrationform','sex')">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="sex" id="inlineRadio1" formControlName="sex"
                value="Male" />
              <label class="form-check-label" for="inlineRadio1">Male</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="sex" id="inlineRadio2" formControlName="sex"
                value="Female" />
              <label class="form-check-label" for="inlineRadio2">Female</label>
            </div>
          </div>
          <!-- <div
            class="form-helper text-danger"
            *ngIf="
              show_personal_registration &&
              showFormErrors &&
              personalregistrationform.controls.sex.hasError('required')
            "
          >
            Sex is required
          </div> -->
          <ng-container *ngFor="let item of checkPersonalRegistrationForm.sex">
            <div class="form-helper text-danger" *ngIf="hasError('personalregistrationform','sex',item.type)">*
              {{item.message}}</div>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="areaofresidence" class="fw-bold">Area of Residence (City/Municipality and Province)</label>
          <input type="text" class="form-control"
            [class.bg-danger]="isInvalid('personalregistrationform','area_of_residence')"
            placeholder="City/Municipality and Province of Residence" formControlName="area_of_residence"
            ng-required="true" />
          <!-- <input
            type="text"
            class="form-control"
            [class.bg-danger]="
              show_personal_registration &&
              showFormErrors &&
              personalregistrationform.controls.area_of_residence.invalid
            "
            placeholder="City/Municipality and Province of Residence"
            formControlName="area_of_residence"
            ng-required="true"
          /> -->
          <!-- <div
            class="form-helper text-danger"
            *ngIf="
              show_personal_registration &&
              showFormErrors &&
              personalregistrationform.controls.area_of_residence.hasError(
                'required'
              )
            "
          >
            Area of Residence is required
          </div> -->
          <!-- if account registration is personal change  -->
          <ng-container *ngFor="let item of checkPersonalRegistrationForm.area_of_residence">
            <div class="form-helper text-danger"
              *ngIf="hasError('personalregistrationform','area_of_residence',item.type)">* {{item.message}}</div>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="firstdropdowntitle" class="fw-bold w-50">Government Issued ID</label>
          <div>
            <!-- <select
              class="form-select w-25 d-inline-block"
              [class.bg-danger]="
                show_personal_registration &&
                showFormErrors &&
                personalregistrationform.controls.idtype.invalid
              "
              formControlName="idtype"
              ng-required="true"
            > -->
            <select class="form-select w-25 d-inline-block"
              [class.bg-danger]="isInvalid('personalregistrationform','idtype')" formControlName="idtype"
              ng-required="true">
              <option value="" disabled selected>Type</option>
              <option *ngFor="let x of GovernmentIssuedIDType" [value]="x">
                {{ x }}
              </option>
            </select>
            <input class="form-control d-inline w-75"
              [class.bg-danger]="isInvalid('personalregistrationform','idnumber')" placeholder="Number"
              formControlName="idnumber" ng-required="true" />
            <!-- <input
              class="form-control d-inline w-75"
              [class.bg-danger]="
                show_personal_registration &&
                showFormErrors &&
                personalregistrationform.controls.idnumber.invalid
              "
              placeholder="Number"
              formControlName="idnumber"
              ng-required="true"
            /> -->
          </div>
          <!-- <div
            class="form-helper text-danger"
            *ngIf="
              show_personal_registration &&
              showFormErrors &&
              (personalregistrationform.controls.idtype.hasError('required') ||
                personalregistrationform.controls.idnumber.hasError('required'))
            "
          >
            Designation is required
          </div> -->
          <ng-container *ngFor="let item of checkPersonalRegistrationForm.idtype">
            <div class="form-helper text-danger" *ngIf="hasError('personalregistrationform','idtype',item.type)">*
              {{item.message}}</div>
          </ng-container>
          <ng-container *ngFor="let item of checkPersonalRegistrationForm.idnumber">
            <div class="form-helper text-danger" *ngIf="hasError('personalregistrationform','idnumber',item.type)">*
              {{item.message}}</div>
          </ng-container>
        </div>
      </div>
    </form>
  </ng-container>

  <!-- Private Organization Registration -->
  <ng-container *ngIf="show_company_registration">
    <!--&& accountType.selectedValue === 'Company'-->
    <p class="text-center fw-bold">
      <strong class="text-primary">PRIVATE ORGANIZATION</strong> REGISTRATION
    </p>
    <p class="fw-bold border-bottom border-dark">Organization Details</p>
    <form [formGroup]="companyregistrationform">
      <!-- [formGroup]="form" (ngSubmit)="onSubmit()" -->
      <div class="form-group">
        <div class="form-group">
          <label for="orgname">Name of Organization</label>
          <!-- <input
            type="text"
            class="form-control"
            [class.bg-danger]="
              show_company_registration &&
              showFormErrors &&
              companyregistrationform.controls.name_of_organization.invalid
            "
            placeholder="Organization/Company Name"
            formControlName="name_of_organization"
            ng-required="true"
          /> -->
          <input type="text" class="form-control"
            [class.bg-danger]="isInvalid('companyregistrationform','name_of_organization')"
            placeholder="Organization/Company Name" formControlName="name_of_organization" ng-required="true" />
          <!-- <div
            class="form-helper text-danger"
            *ngIf="
              show_company_registration &&
              showFormErrors &&
              companyregistrationform.controls.name_of_organization.hasError(
                'required'
              )
            "
          >
            Name of Organization is required
          </div> -->
          <ng-container *ngFor="let item of cehckCompanyRegistrationForm.name_of_organization">
            <div class="form-helper text-danger"
              *ngIf="hasError('cehckCompanyRegistrationForm','name_of_organization',item.type)">* {{item.message}}</div>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="orgaddr">Organization Address</label>
          <!-- <input
            type="text"
            class="form-control"
            [class.bg-danger]="
              show_company_registration &&
              showFormErrors &&
              companyregistrationform.controls.organization_address.invalid
            "
            placeholder="Organization Address"
            formControlName="organization_address"
            ng-required="true"
          /> -->
          <input type="text" class="form-control"
            [class.bg-danger]="isInvalid('companyregistrationform','organization_address')"
            placeholder="Organization Address" formControlName="organization_address" ng-required="true" />
          <!-- <div
            class="form-helper text-danger"
            *ngIf="
              show_company_registration &&
              showFormErrors &&
              companyregistrationform.controls.organization_address.hasError(
                'required'
              )
            "
          >
            Organization Address is required
          </div> -->
          <ng-container *ngFor="let item of cehckCompanyRegistrationForm.organization_address">
            <div class="form-helper text-danger"
              *ngIf="hasError('cehckCompanyRegistrationForm','organization_address',item.type)">* {{item.message}}</div>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="officialdesignation">Official Designation</label>
          <!-- <input
            type="text"
            class="form-control"
            [class.bg-danger]="
              show_company_registration &&
              showFormErrors &&
              companyregistrationform.controls.official_designation.invalid
            "
            placeholder="Official Designation"
            formControlName="official_designation"
            ng-required="true"
          /> -->
          <input type="text" class="form-control"
            [class.bg-danger]="isInvalid('companyregistrationform','official_designation')"
            placeholder="Official Designation" formControlName="official_designation" ng-required="true" />
          <!-- <div
            class="form-helper text-danger"
            *ngIf="
              show_company_registration &&
              showFormErrors &&
              companyregistrationform.controls.official_designation.hasError(
                'required'
              )
            "
          >
            Official Designation is required
          </div> -->
          <ng-container *ngFor="let item of cehckCompanyRegistrationForm.official_designation">
            <div class="form-helper text-danger"
              *ngIf="hasError('cehckCompanyRegistrationForm','official_designation',item.type)">* {{item.message}}</div>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="seconddropdowntitle">Sector</label>
          <div>
            <!-- <select
              class="form-select"
              [class.bg-danger]="
                show_company_registration &&
                showFormErrors &&
                companyregistrationform.controls.sector.invalid
              "
              formControlName="sector"
              ng-required="true"
            > -->
            <select class="form-select" [class.bg-danger]="isInvalid('companyregistrationform','sector')"
              formControlName="sector" ng-required="true">
              <option value="" disabled selected>Sector</option>
              <option *ngFor="let x of sectors" [value]="x['id']">
                {{ x["name"] }}
              </option>
            </select>
          </div>
          <!-- <div
            class="form-helper text-danger"
            *ngIf="
              show_company_registration &&
              showFormErrors &&
              companyregistrationform.controls.sector.hasError('required')
            "
          >
            Sector is required
          </div> -->
          <ng-container *ngFor="let item of cehckCompanyRegistrationForm.sector">
            <div class="form-helper text-danger" *ngIf="hasError('cehckCompanyRegistrationForm','sector',item.type)">*
              {{item.message}}</div>
          </ng-container>
        </div>
      </div>
    </form>
  </ng-container>

  <!-- Government Account Registration -->
  <ng-container *ngIf="show_government_registration">
    <p class="text-center fw-bold">
      <strong class="text-primary">GOVERNMENT ACCOUNT</strong> REGISTRATION
    </p>
    <p class="fw-bold border-bottom border-dark">Agency Details</p>
    <form [formGroup]="governmentregistrationform">
      <div class="form-group">
        <!--Mother Agency-->
        <div class="form-group">
          <label for="seconddropdowntitle">Mother Agency</label>
          <div>
            <!--get list of agencies that are tagged to its corresponding mother agency-->
            <select class="form-select" (change)="getAgencies($event)" 
              ng-required="true">
              <option value="" disabled selected>Mother Agency</option>
              <option *ngFor="let x of mother_agency" [value]="x['id']">
                {{ x["motheragency"] }}
              </option>
            </select>
          </div>
        </div>
        <!--End of Mother agency list-->
        <div class="form-group">
          <label for="seconddropdowntitle">Specific Agency</label>
          <div>
            <!-- <select
              class="form-select"
              [class.bg-danger]="
                show_government_registration &&
                showFormErrors &&
                governmentregistrationform.controls.agency.invalid
              "
              formControlName="agency"
              ng-required="true"
            > -->
            <select class="form-select" #agencydata (change)="onValueChange(agencydata.options[agencydata.selectedIndex].text)" [class.bg-danger]="isInvalid('governmentregistrationform','agency')"
              formControlName="agency" ng-required="true">
              <option value="" disabled selected>Specific Agency</option>
              <option *ngFor="let x of gov_agency" [value]="x['id']">
                {{ x["name"] }}
              </option>
            </select>
          </div>
          <!-- <div
            class="form-helper text-danger"
            *ngIf="
              show_government_registration &&
              showFormErrors &&
              governmentregistrationform.controls.agency.hasError('required')
            "
          >
          Mother Agency is required
          </div> -->
          <ng-container *ngFor="let item of checkGovernmentRegistrationForm.agency">
            <div class="form-helper text-danger" *ngIf="hasError('governmentregistrationform','agency',item.type)">*
              {{item.message}}</div>
          </ng-container>
        </div>

        <div class="form-group">
          <input  type="hidden"  class="form-control"
           placeholder="Specific Agency"
          formControlName="subagency" ng-required="true" />
        </div>
        <!--changed from text input to form select
        <div class="form-group">
          <label for="subagency">Specific Agency</label>
          <input
            type="text"
            class="form-control"
            [class.bg-danger]="
              show_government_registration &&
              showFormErrors &&
              governmentregistrationform.controls.subagency.invalid
            "
            placeholder="Specific Agency"
            formControlName="subagency"
            ng-required="true"
          /> 
          <input type="text" class="form-control"
            [class.bg-danger]="isInvalid('governmentregistrationform','subagency')" placeholder="Specific Agency"
            formControlName="subagency" ng-required="true" />
          <div
            class="form-helper text-danger"
            *ngIf="
              show_government_registration &&
              showFormErrors &&
              governmentregistrationform.controls.agency.hasError('required')
            "
          >
            Specific Agency is required
          </div> 
          <ng-container *ngFor="let item of checkGovernmentRegistrationForm.subagency">
            <div class="form-helper text-danger" *ngIf="hasError('governmentregistrationform','subagency',item.type)">*
              {{item.message}}</div>
          </ng-container>
        </div>
        -->
        <div class="form-group">
          <label for="officialdesignation">Official Designation</label>
          <!-- <input
            type="text"
            class="form-control"
            [class.bg-danger]="
              show_government_registration &&
              showFormErrors &&
              governmentregistrationform.controls.official_designation.invalid
            "
            placeholder="Official Designation"
            formControlName="official_designation"
            ng-required="true"
          /> -->
          <input type="text" class="form-control"
            [class.bg-danger]="isInvalid('governmentregistrationform','official_designation')"
            placeholder="Official Designation" formControlName="official_designation" ng-required="true" />
          <!-- <div
            class="form-helper text-danger"
            *ngIf="
              show_government_registration &&
              showFormErrors &&
              governmentregistrationform.controls.agency.hasError('required')
            "
          >
            Official Designation is required
          </div> -->
          <ng-container *ngFor="let item of checkGovernmentRegistrationForm.official_designation">
            <div class="form-helper text-danger"
              *ngIf="hasError('governmentregistrationform','official_designation',item.type)">* {{item.message}}</div>
          </ng-container>
        </div>
      </div>
    </form>
  </ng-container>

  <!-- Final Registration -->
  <ng-container *ngIf="show_final_registration">
    <p class="text-center fw-bold">
      <strong class="text-primary">FINALIZE</strong> REGISTRATION
    </p>
    <p class="fw-bold border-bottom border-dark">Upload Required Documents</p>
    <form [formGroup]="finalregistrationform">
      <!-- [formGroup]="form" (ngSubmit)="onSubmit()" -->
      <div class="form-group">
        <div class="form-group file-uploader-wrapper" *ngIf="show_government_id_input">
          <div appFileUploader class="dropzone" (filesChangeEmiter)="dropzone($event, 'gov_id')">
            <button type="button" class="btn btn-link dropzone-btn" (click)="gov_id.click()">
              <label for="govid"><strong>Government Issued ID</strong></label>
              <div *ngIf="display_government_id">
                {{ display_government_id }}
              </div>
              <div *ngFor="let formValid of checkUploadsForm.gov_id">
                <div class="error"
                  *ngIf="hasError('uploadsForm','gov_id', formValid.type == 'maxSize' ? 'max' : formValid.type, formValid.type == 'maxSize' ? true : false)">
                  {{formValid.message}}
                </div>
              </div>
              <span><strong>Drop file here to upload or click to choose file</strong></span>
              <span>(jpg, jpeg, png, pdf). file cannot exceed 10MB.</span>
              <div class="progress" style="height: 20px; width: 100%;" *ngIf="gov_uploadProgress">
                <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar"
                  [style.width]="(gov_uploadProgress | async) + '%'" [attr.aria-valuenow]="gov_uploadProgress | async"
                  aria-valuemin="0" aria-valuemax="100">
                </div>
              </div>
            </button>
            <input #gov_id id="gov_id" type="file" multiple (change)="buttonUpload($event,'gov_id')" />
          </div>
        </div>

        <div class="form-group file-uploader-wrapper" *ngIf="show_agency_id_input">
          <div appFileUploader class="dropzone" (filesChangeEmiter)="dropzone($event, 'company_id')">
            <button type="button" class="btn btn-link dropzone-btn" (click)="company_id.click()">
              <label for="govid"><strong>Company/Agency ID</strong></label>
              <div *ngIf="display_company_id">
                {{ display_company_id }}
              </div>
              <div *ngFor="let formValid of checkUploadsForm.company_id">
                <div class="error"
                  *ngIf="hasError('uploadsForm','company_id', formValid.type == 'maxSize' ? 'max' : formValid.type, formValid.type == 'maxSize' ? true : false)">
                  {{formValid.message}}
                </div>
              </div>
              <span><strong>Drop file here to upload or click to choose file</strong></span>
              <span>(jpg, jpeg, png, pdf). file cannot exceed 10MB.</span>
              <div class="progress" style="height: 20px; width: 100%;" *ngIf="com_uploadProgress">
                <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar"
                  [style.width]="(com_uploadProgress | async) + '%'" [attr.aria-valuenow]="com_uploadProgress | async"
                  aria-valuemin="0" aria-valuemax="100">
                </div>
              </div>
            </button>
            <input #company_id id="company_id" type="file" multiple (change)="buttonUpload($event,'company_id')" />
          </div>
        </div>

        <div class="form-group file-uploader-wrapper" *ngIf="show_authorization_letter_input">
          <div appFileUploader class="dropzone" (filesChangeEmiter)="dropzone($event, 'auth_letter')">
            <button type="button" class="btn btn-link dropzone-btn" (click)="auth_letter.click()">
              <label for="govid"><strong>Authorization Letter</strong></label>
              <div *ngIf="display_authorization_letter">
                {{ display_authorization_letter }}
              </div>
              <div *ngFor="let formValid of checkUploadsForm.auth_letter">
                <div class="error"
                  *ngIf="hasError('uploadsForm','auth_letter', formValid.type == 'maxSize' ? 'max' : formValid.type, formValid.type == 'maxSize' ? true : false)">
                  {{formValid.message}}
                </div>
              </div>
              <span><strong>Drop file here to upload or click to choose file</strong></span>
              <span>(jpg, jpeg, png, pdf). file cannot exceed 10MB.</span>
              <div class="progress" style="height: 20px; width: 100%;" *ngIf="auth_uploadProgress">
                <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar"
                  [style.width]="(auth_uploadProgress | async) + '%'" [attr.aria-valuenow]="auth_uploadProgress | async"
                  aria-valuemin="0" aria-valuemax="100">
                </div>
              </div>
            </button>
            <input #auth_letter id="auth_letter" type="file" multiple (change)="buttonUpload($event,'auth_letter')" />
          </div>
        </div>
        <!-- <div class="form-group" *ngIf="show_government_id_input">
          <label for="govid">Government Issued ID</label>
          <input
            type="file"
            (change)="upload($event, 'gov_id')"
            class="form-control"
            formControlName="government_id"
            ng-required="true"
            style="display: none"
            #gov_id_input
          />
                                                    formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" 
          <div class="file-input p-3">
            <label>Drop files here or </label>
            <button (click)="gov_id_input.click()" class="btn btn-light">
              Browse
            </button>
          </div>
          {{ display_government_id }}
          <div class="invalid-feedback">
                                                      *ngIf="submitted && f.username.errors" 
            <div>Government Issued ID is required</div>
                                                      *ngIf="f.username.errors.required"
          </div>
                                                      Progress Bar
          <div
            class="progress m-3"
            style="height: 20px"
            *ngIf="gov_uploadProgress && !showFormErrors"
          >
            <div
              class="
                progress-bar progress-bar-striped progress-bar-animated
                bg-success
              "
              role="progressbar"
              [style.width]="(gov_uploadProgress | async) + '%'"
              [attr.aria-valuenow]="gov_uploadProgress | async"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div> -->
        <!-- <div class="form-group" *ngIf="show_agency_id_input">
          <label for="agencyid">Company/Agency ID</label>
          <input
            type="file"
            class="form-control"
            (change)="upload($event, 'company_id')"
            formControlName="company_id"
            ng-required="true"
            style="display: none"
            #agency_id_input
          />
                                                  formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" 
          <div class="file-input p-3">
            <label>Drop files here or </label>
            <button (click)="agency_id_input.click()" class="btn btn-light">
              Browse
            </button>
          </div>
          {{ display_company_id }}
          <div class="invalid-feedback">
                                                  *ngIf="submitted && f.username.errors" 
            <div>Company/Agency ID is required</div>
                                                  *ngIf="f.username.errors.required"
          </div>
                                                  Progress Bar
          <div
            class="progress m-3"
            style="height: 20px"
            *ngIf="com_uploadProgress && !showFormErrors"
          >
            <div
              class="
                progress-bar progress-bar-striped progress-bar-animated
                bg-success
              "
              role="progressbar"
              [style.width]="(com_uploadProgress | async) + '%'"
              [attr.aria-valuenow]="com_uploadProgress | async"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div> -->
        <!-- <div class="form-group" *ngIf="show_authorization_letter_input">
          <label for="authid">Authorization Letter</label>
          <input
            type="file"
            (change)="upload($event, 'auth_letter')"
            class="form-control"
            formControlName="authorization_letter"
            ng-required="true"
            style="display: none"
            #auth_letter_input
          />
                                                  formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" 
          <div class="file-input p-3">
            <label>Drop files here or </label>
            <button (click)="auth_letter_input.click()" class="btn btn-light">
              Browse
            </button>
          </div>
          {{ display_authorization_letter }}
          <div class="invalid-feedback">
                                                  *ngIf="submitted && f.username.errors" 
            <div>Authorization Letter is required</div>
                                                  *ngIf="f.username.errors.required"
          </div>
                                                  Progress Bar
          <div
            class="progress m-3"
            style="height: 20px"
            *ngIf="auth_uploadProgress && !showFormErrors"
          >
            <div
              class="
                progress-bar progress-bar-striped progress-bar-animated
                bg-success
              "
              role="progressbar"
              [style.width]="(auth_uploadProgress | async) + '%'"
              [attr.aria-valuenow]="auth_uploadProgress | async"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div> -->
        <!-- <div
          class="form-helper text-danger"
          *ngIf="show_final_registration && showFormErrors"
        >
          Invalid file type
        </div>
        <div class="form-group">
          <label for="firstname"
            >Valid file types include: jpg, jpeg, pdf, png. Each file cannot
            exceed 10MB.</label
          >
        </div> -->
      </div>
    </form>
  </ng-container>

   <!-- Final Registration -->
  <ng-container *ngIf="show_recaptcha_confirmation">
    <div class="form-group text-xs-center" >
      <re-captcha class="cap1" #captchaRef="reCaptcha" (resolved)="resolved($event)" [siteKey]="recaptchaV2SiteKey"></re-captcha>
    </div>
  </ng-container>

  <ng-container class="form-group row ms-auto  ">
    <div class="float-end">
      <button *ngIf="show_back_button" class="btn btn-outline-dark col m-1" (click)="toggleBack(true)" id="bt ">
        <i class="fas fa-chevron-left"></i> Back
      </button>
      <button class="col m-1" *ngIf="show_terms_and_conditions || show_data_privacy" mat-flat-button color="warn"
        (click)="loginAsGuest()">
        Continue as Guest <i class="fas fa-sign-in-alt"></i>
      </button>
      <button [disabled]="!show_next_button" *ngIf="!show_final_registration" class="btn btn-primary col m-1"
        (click)="toggleNext()" id="bt ">
        Next <i class="fas fa-chevron-right"></i>
      </button>
      <button *ngIf="show_submit_button" class="btn btn-primary col m-1" [disabled]="uploading" (click)="toggleNext()">
        Submit <i class="fas fa-paper-plane"></i>
      </button>

      <button *ngIf="show_final_send_button" class="btn btn-primary col m-1" [hidden]="needsRecaptcha"
        [disabled]="loading" (click)="submitForm()">
        <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>
        Register <i class="fas fa-check-circle"></i>
      </button>
    </div>
  </ng-container>
</div>