<h2 mat-dialog-title class="mat-typography">Settings</h2>
<div mat-dialog-content>
<!-- <div mat-dialog-content class="content"> -->
    <div class="p-4">
        <div class="container-fluid">
            <div class="card group">
                <mat-slide-toggle class="sub-space"
                    [checked]="toggles.regulations.main"
                    (change)="toggleMain('regulations')"
                    color="primary">
                    <h5>Regulations</h5></mat-slide-toggle>
                <div class="row slide-indent" >
                    <p>
                        <mat-icon *ngIf="toggles.regulations.create" class="check-icons">check_circle_outline</mat-icon>
                        <mat-icon *ngIf="!toggles.regulations.create" class="cross-icons">cancel</mat-icon>
                        View Newly Published Regulations
                    </p>
                    <ng-container *ngIf="isAdmin || isAgencyMember || isArta">
                        <p>
                            <mat-icon *ngIf="toggles.regulations.review" class="check-icons">check_circle_outline</mat-icon>
                            <mat-icon *ngIf="!toggles.regulations.review" class="cross-icons">cancel</mat-icon>
                            Review Updated Regulations
                        </p>
                    </ng-container>
                </div>
            </div>

            <div class="card group">
                <mat-slide-toggle class="sub-space"
                    [checked]="toggles.consultations.main"
                    (change)="toggleMain('consultations')"
                    color="primary">
                    <h5>Consultations</h5></mat-slide-toggle>
                <div class="row slide-indent" >
                    <p>
                        <mat-icon *ngIf="toggles.consultations.create" class="check-icons">check_circle_outline</mat-icon>
                        <mat-icon *ngIf="!toggles.consultations.create" class="cross-icons">cancel</mat-icon>
                        View Newly Published Consultations
                    </p>
                    <ng-container *ngIf="isAdmin || isAgencyMember || isArta">
                        <p>
                            <mat-icon *ngIf="toggles.consultations.review" class="check-icons">check_circle_outline</mat-icon>
                            <mat-icon *ngIf="!toggles.consultations.review" class="cross-icons">cancel</mat-icon>
                            Review Updated Consultations
                        </p>
                    </ng-container>
                </div>
            </div>

            <div class="card group">
                <mat-slide-toggle class="sub-space"
                    [checked]="toggles.citizenscharters.main"
                    (change)="toggleMain('citizenscharters')"
                    color="primary">
                    <h5>Citizens Charter</h5></mat-slide-toggle>
                <div class="row slide-indent" >
                    <p>
                        <mat-icon *ngIf="toggles.citizenscharters.create" class="check-icons">check_circle_outline</mat-icon>
                        <mat-icon *ngIf="!toggles.citizenscharters.create" class="cross-icons">cancel</mat-icon>
                        View Newly Published Citizens Charter
                    </p>
                    <ng-container *ngIf="isAdmin || isAgencyMember || isArta">
                        <p>
                            <mat-icon *ngIf="toggles.citizenscharters.review" class="check-icons">check_circle_outline</mat-icon>
                            <mat-icon *ngIf="!toggles.citizenscharters.review" class="cross-icons">cancel</mat-icon>
                            Review Updated Citizens Charter
                        </p>
                    </ng-container>
                </div>
            </div>

            <div class="card group">
                <mat-slide-toggle class="sub-space"
                    [checked]="toggles.services.main"
                    (change)="toggleMain('services')"
                    color="primary">
                    <h5>Services</h5></mat-slide-toggle>
                <div class="row slide-indent" >
                    <p>
                        <mat-icon *ngIf="toggles.services.create" class="check-icons">check_circle_outline</mat-icon>
                        <mat-icon *ngIf="!toggles.services.create" class="cross-icons">cancel</mat-icon>
                        View Newly Published Services
                    </p>
                    <ng-container *ngIf="isAdmin || isAgencyMember || isArta">
                        <p>
                            <mat-icon *ngIf="toggles.services.review" class="check-icons">check_circle_outline</mat-icon>
                            <mat-icon *ngIf="!toggles.services.review" class="cross-icons">cancel</mat-icon>
                            Review Updated Services
                        </p>
                    </ng-container>
                </div>
            </div>

            <div class="card group"  *ngIf="isAdmin || isArta" >
                <mat-slide-toggle class="sub-space"
                [checked]="toggles.agency.main"
                (change)="toggleMain('agency')"
                color="primary">
                <h5>Agency</h5></mat-slide-toggle>
                <div class="row slide-indent">
                    <p>
                        <mat-icon *ngIf="toggles.agency.create" class="check-icons">check_circle_outline</mat-icon>
                        <mat-icon *ngIf="!toggles.agency.create" class="cross-icons">cancel</mat-icon>
                        View Newly Created Agencies
                    </p>
                    <p>
                        <mat-icon *ngIf="toggles.agency.complaint" class="check-icons">check_circle_outline</mat-icon>
                        <mat-icon *ngIf="!toggles.agency.complaint" class="cross-icons">cancel</mat-icon>
                        Check Complaints on Agencies
                    </p>
                </div>
            </div>

            <div class="card group"  *ngIf="isAdmin" >
                <mat-slide-toggle class="sub-space"
                [checked]="toggles.admin.main"
                (change)="toggleMain('admin')"
                color="primary">
                <h5>Admin</h5></mat-slide-toggle>
                <div class="row slide-indent">
                    <p>
                        <mat-icon *ngIf="toggles.admin.worklist.create" class="check-icons">check_circle_outline</mat-icon>
                        <mat-icon *ngIf="!toggles.admin.worklist.create" class="cross-icons">cancel</mat-icon>
                        View Newly Created Worklists
                    </p>
                    <p>
                        <mat-icon *ngIf="toggles.admin.worklist.review" class="check-icons">check_circle_outline</mat-icon>
                        <mat-icon *ngIf="!toggles.admin.worklist.review" class="cross-icons">cancel</mat-icon>
                        Review Worklists
                    </p>
                    <p>
                        <mat-icon *ngIf="toggles.admin.accounts.create" class="check-icons">check_circle_outline</mat-icon>
                        <mat-icon *ngIf="!toggles.admin.accounts.create" class="cross-icons">cancel</mat-icon>
                        View Newly Created Worklists
                    </p>
                    <p>
                        <mat-icon *ngIf="toggles.admin.accounts.review" class="check-icons">check_circle_outline</mat-icon>
                        <mat-icon *ngIf="!toggles.admin.accounts.review" class="cross-icons">cancel</mat-icon>
                        Review Accounts
                    </p>
                </div>
            </div>

        </div>
    </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-between">
    <button class="btn btn-dark" (click)="dialogRef.close({success: false})">Cancel</button>
    <button class="btn btn-primary" (click)="onSubmit()">Confirm</button>
</div>