import { Component, OnChanges, Input} from '@angular/core';

@Component({
  selector: 'app-view-charter-public-help-details',
  templateUrl: './view-charter-public-help-details.component.html',
  styleUrls: ['./view-charter-public-help-details.component.scss']
})
export class ViewCharterPublicHelpDetailsComponent implements OnChanges {
  isLoaded:boolean = false

  details:any =[
    {name:'How to send feedback', value:'howToSendFeedback'},
    {name:'How feedback is processed', value:'howFeedbackProcess'},
    {name:'How to file complaint', value:'howTofileComplaint'},
    {name:'How complaints are processed', value:'howComplaintsProcess'},
  ]

  @Input() agency_details:any;

  constructor() { }

  ngOnChanges(): void {
    if(this.agency_details){
      this.isLoaded = true;   
      // console.log('services: ', this.services)
      // console.log('agency_details: ', this.agency_details)
    }

  }

}
