import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable , MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ReferenceArtemisModalComponent } from '../modal/artemis-modal/reference-artemis-modal.component';
import { FormGroup, FormControl } from '@angular/forms';
import {Sort} from '@angular/material/sort';
import { AgencyAddComponent } from 'src/app/agency-control/agency-add/agency-add.component';

@Component({
    selector: 'app-artemis-reference-control',
    templateUrl: './artemis-reference-control.component.html',
    styleUrls: ['./artemis-reference-control.component.scss']
})
export class ArtemisReferenceControlComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatTable,{static:true}) table: MatTable<any>;
  @Input() currentRef:any = '';
  @Input() userAccess:any = '';
  @Input() isAdmin:any = '';
  refArr:any[] = []
  refArrS:any[] = []
  refDataSource:any = [];
  refDataSourceCol: string[] = ['action']
  activated:any;
//   refDataSourceCol: string[] =['action','section','name','definition']


  searchForm = new FormGroup({
    search:new FormControl('')
  })

  constructor(
    private afs: AngularFirestore,
    public dialog: MatDialog,
    private router: Router,
  ) { } 

  ngOnInit(): void {
    this.loadRef(this.currentRef ? this.currentRef : 'Agency')
  }

  loadRef(refName:any){
    if(this.userAccess == 'artemis'){
        switch(refName){
          case 'Agency':{
            this.refDataSourceCol =['action','agencyabbrv','agencyname','agencywebsite','id']
            this.activated = 'agency'
            this.loadData(refName,this.activated)
            break;
          }
          case 'Agency Category':{
            this.refDataSourceCol =['action','agencycatabbrv','agencycatname']
            this.activated = 'agencycat'
            this.loadData(refName,this.activated)
            break;
          }
          case 'Classification':{
            this.refDataSourceCol =['action','classsection','classname']
            this.activated = 'class'
            this.loadData(refName,this.activated)
            break;
          }
          case 'List of Services':{
            this.refDataSourceCol =['action','lossection','losname']
            this.activated = 'los'
            this.loadData(refName,this.activated)
            break;
          }
          case 'Service Category':{
            this.refDataSourceCol =['action','servcatsection','servcatname']
            this.activated = 'servcat'
            this.loadData(refName,this.activated)
            break;
          }
          case 'Stage of Life':{
            this.refDataSourceCol =['action','solstage']
            this.activated = 'sol'
            this.loadData(refName,this.activated)
            break;
          }
          case 'Mother Agency':{
            this.refDataSourceCol = ['action','motheragency']
            this.activated = 'motheragency'
            this.loadData(refName,this.activated)
            break;
          }
        }
        // if(refName == 'Agency') {
        //     this.refDataSourceCol =['action','agencyabbrv','agencyname','agencywebsite']
        //     this.activated = 'agency'
        //     this.loadData(refName,this.activated)
        // }
        // else if(refName == 'Agency Category') {
        //     this.refDataSourceCol =['action','agencycatabbrv','agencycatname']
        //     this.activated = 'agencycat'
        //     this.loadData(refName,this.activated)
        // }
        // else if(refName == 'Classification') {
        //     this.refDataSourceCol =['action','classsection','classname']
        //     this.activated = 'class'
        //     this.loadData(refName,this.activated)
        // }
        // else if(refName == 'List of Services') {
        //     this.refDataSourceCol =['action','lossection','losname']
        //     this.activated = 'los'
        //     this.loadData(refName,this.activated)
        // }
        // else if(refName == 'Service Category') {
        //     this.refDataSourceCol =['action','servcatsection','servcatname']
        //     this.activated = 'servcat'
        //     this.loadData(refName,this.activated)
        // }
        // else if(refName == 'Stage of Life') {
        //     this.refDataSourceCol =['action','solstage']
        //     this.activated = 'sol'
        //     this.loadData(refName,this.activated)
        // }else if(refName == 'Mother Agency'){
        //     this.refDataSourceCol = ['action','motheragency']
        //     this.activated = 'motheragency'
        //     this.loadData(refName,this.activated)
        // }
    }
  }

  loadData(collection:any,code:any){
    this.afs.collection(collection, filter => this.filterFunct(code,filter)).snapshotChanges()
    .pipe(first())
    .subscribe({
      next:(info)=>{
        let tempArr:any = []
        info.map(data=>{
          if(data.payload.doc.exists){
            let ref: any = data.payload.doc.data()
            ref.id = data.payload.doc.id
            if(!tempArr.includes(ref)) tempArr.push(ref)
          } else console.warn('no document')
        })
        this.refArr = tempArr
        this.refDataSource = new  MatTableDataSource<any>(tempArr)
        this.resetSortPage(collection)
      },
      error:(err)=>{
          console.error(err)
      }
    })
  }

  filterFunct(code:any, filter:any){
    if(code == 'agency') return filter.orderBy('name')
    else if(code == 'agencycat') return filter.orderBy('name')
    if(code == 'class') return filter.orderBy('section')
    // if(code == 'los') return filter.orderBy('name')
    if(code == 'servcat') return filter.orderBy('section')
    // if(code == 'sol') return filter.orderBy('name')
    if(code == 'motheragency') return filter.orderBy('motheragency')
    else return filter
  }
  
  search() {
    const filterValue = this.searchForm.value.search
    this.refDataSource.filter = filterValue.trim().toLowerCase();
    if (this.refDataSource.paginator) {
      this.refDataSource.paginator.firstPage();
    }
  }

  searchFilter(currentRef:any){
    if(currentRef == 'Agency') {
      this.refDataSource.filterPredicate = (data:any, filter:string):boolean=>{
        const filterVal = filter.toLowerCase()
        return ((data.agencyDetails ? data.agencyDetails.agenctName ? data.agencyDetails.agenctName : data.name : data.name)).toLowerCase().includes(filterVal)
        || ((data.agencyDetails ? data.agencyDetails.agencyAbbreviation ? data.agencyDetails.agencyAbbreviation : '' : '')).toLowerCase().includes(filterVal)
        || ((data.agencyDetails ? data.agencyDetails.website ? data.agencyDetails.website : '' : '')).toLowerCase().includes(filterVal)
      }
    }
    else if(currentRef == 'Agency Category') {
      this.refDataSource.filterPredicate = (data:any, filter:string):boolean=>{
        const filterVal = filter.toLowerCase()
        return data.abbreviation.toLowerCase().includes(filterVal)
        || data.name.toLowerCase().includes(filterVal)
      }
    }
    else if(currentRef == 'Classification') {
      this.refDataSource.filterPredicate = (data:any, filter:string):boolean=>{
        const filterVal = filter.toLowerCase()
        return data.name.toLowerCase().includes(filterVal)
        || data.section.toLowerCase().includes(filterVal)
      }
    }
    else if(currentRef == 'Mother Agency'){
      this.refDataSource.filterPredicate= (data:any, filter:string):boolean=>{
        const filterVal = filter.toLowerCase()
        return data.motheragency.toLowerCase().includes(filterVal)
      }
    }
    else if(currentRef == 'List of Services') {
      // this.refDataSource.filterPredicate = (data:any, filter:string):boolean=>{
      //   return data.name.toLowerCase().includes(filter.toLowerCase())
      //   || data.section.toLowerCase().includes(filter.toLowerCase())
      //   || data.definition.toLowerCase().includes(filter.toLowerCase())
      // }
    }
    else if(currentRef == 'Service Category') {
      this.refDataSource.filterPredicate = (data:any, filter:string):boolean=>{
        const filterVal = filter.toLowerCase()
        return data.name.toLowerCase().includes(filterVal)
        || data.section.toLowerCase().includes(filterVal)
      }
    }
    else if(currentRef == 'Stage of Life') {
      this.refDataSource.filterPredicate = (data:any, filter:string):boolean=>{
        const filterVal = filter.toLowerCase()
        return data.stage.toLowerCase().includes(filterVal)
      }
    }
  }
  
  resetSortPage(currentRef:any){
    this.refDataSource.paginator = this.paginator
    this.refDataSource.sort = this.sort
    // this.searchFilter(currentRef)
  }

  sortChange(sort: Sort){
    const data = this.refArr.slice()
    if(!sort.active || sort.direction === ''){
        this.refArrS = data
        return;
    }
    this.refArrS = data.sort((a:any,b:any)=>{
        const isAsc = sort.direction === 'asc'
        switch (sort.active){
          case 'motheragency':
            let amotheragencyname = a.motheragency  
            let bmotheragencyname = b.motheragency
            return this.compare(amotheragencyname.trim().toLowerCase(),bmotheragencyname.trim().toLowerCase(),isAsc)
          case 'agencyabbrv':
            let aValagencyabbrv = a.agencyDetails ? a.agencyDetails.agencyAbbreviation ? a.agencyDetails.agencyAbbreviation : a.abbreviation ?  a.abbreviation : '' : a.abbreviation ?  a.abbreviation : ''
            let bValagencyabbrv = b.agencyDetails ? b.agencyDetails.agencyAbbreviation ? b.agencyDetails.agencyAbbreviation : b.abbreviation ?  b.abbreviation : '' : b.abbreviation ?  b.abbreviation : ''
            return this.compare(aValagencyabbrv.trim().toLowerCase(), bValagencyabbrv.trim().toLowerCase(), isAsc)
          case 'agencyname':
            let aValagencyname = a.agencyDetails ? a.agencyDetails.agencyName ? a.agencyDetails.agencyName : a.name : a.name
            let bValagencyname = b.agencyDetails ? b.agencyDetails.agencyName ? b.agencyDetails.agencyName : b.name : b.name
            return this.compare(aValagencyname.trim().toLowerCase(), bValagencyname.trim().toLowerCase(), isAsc)
          case 'agencywebsite':
            let aValagencywebsite = a.agencyDetails ? a.agencyDetails.website ? a.agencyDetails.website : '' : ''
            let bValagencywebsite = b.agencyDetails ? b.agencyDetails.website ? b.agencyDetails.website : '' : ''
            return this.compare(aValagencywebsite.trim().toLowerCase(), bValagencywebsite.trim().toLowerCase(), isAsc)
          case 'agencycatabbrv':
            let aValagencycatabbrv = a.abbreviation
            let bValagencycatabbrv = b.abbreviation
            return this.compare(aValagencycatabbrv.trim().toLowerCase(), bValagencycatabbrv.trim().toLowerCase(), isAsc)
          case 'agencycatname':
            let aValagencycatname = a.name
            let bValagencycatname = b.name
            return this.compare(aValagencycatname.trim().toLowerCase(), bValagencycatname.trim().toLowerCase(), isAsc)
          case 'classsection':
            let aValclasssection = a.section
            let bValclasssection = b.section
            return this.compare(aValclasssection.trim().toLowerCase(), bValclasssection.trim().toLowerCase(), isAsc)
          case 'classname':
            let aValclassname = a.name
            let bValclassname = b.name
            return this.compare(aValclassname.trim().toLowerCase(), bValclassname.trim().toLowerCase(), isAsc)
          case 'servcatsection':
            let aValservcatsection = a.section
            let bValservcatsection = b.section
            return this.compare(aValservcatsection.trim().toLowerCase(), bValservcatsection.trim().toLowerCase(), isAsc)
          case 'servcatname':
            let aValservcatname = a.name
            let bValservcatname = b.name
            return this.compare(aValservcatname.trim().toLowerCase(), bValservcatname.trim().toLowerCase(), isAsc)
          case 'solstage':
            let aValsolstage = a.stage
            let bValsolstage = b.stage
            return this.compare(aValsolstage.trim().toLowerCase(), bValsolstage.trim().toLowerCase(), isAsc)
          default:
            return 0
        }
    })
    this.refDataSource = new MatTableDataSource<any>(this.refArrS)
    this.resetSortPage(this.currentRef)
}

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  
  btnAction(action:any,currentRef:any, refId:any){
    if(action == 'create' && currentRef == 'Agency'){
        const dialogref  = this.dialog.open(AgencyAddComponent,{
          disableClose: true,
          maxWidth: "87vw",
          width: "87vw",
          height: "85vh",
        })

        // if(currentRef == 'Agency'){
            //this.router.navigate(['/agency/add'])
        // }
    }else{
      let data:any ={
        action:action,
        currentRef:currentRef,
        refId:refId,
      }
      const dialogRef = this.dialog.open(ReferenceArtemisModalComponent,{data:data})
      this.afterDialogClosed(dialogRef,action,currentRef)
    }
  }

  afterDialogClosed(dialogRef:any,action:any,currentRef:any){
    dialogRef.afterClosed().pipe(first()).subscribe((result:any)=>{
      if(result != undefined && result.success && (action == 'create' || action == 'edit')){
        this.afs.collection(currentRef).doc(result.refId).snapshotChanges()
        .pipe(first())
        .subscribe({
          next:info=>{
            let item:any = info.payload.data()
            if(item != undefined){
              item.id = info.payload.id
              if(action == 'create'){
                if(!this.refArr.includes(item)){
                  this.refArr.push(item)
                  this.refArr.sort((a:any,b:any)=>{
                    if(currentRef == 'Agency Category') return a.name.localeCompare(b.name)
                    if(currentRef == 'Classification' || currentRef == 'Service Category')return a.section.localeCompare(b.section)
                    else return false
                  })
                  this.refDataSource = new  MatTableDataSource<any>(this.refArr)
                  this.resetSortPage(currentRef)
                }
              }

              else if(action == 'edit'){
                let ctr = this.refArr.map((info:any)=>info.id).indexOf(result.refId)
                if(ctr >= 0){
                  this.refArr[ctr] = item
                  this.refDataSource = new  MatTableDataSource<any>(this.refArr)
                  this.resetSortPage(currentRef)
                }
              }

            }
          },
          error:err=>{
            console.warn('err: ',err)
          }
        })
      }
      
      else if(result != undefined && result.success && action == 'delete'){
        let ctr = this.refArr.map((info:any)=>info.id).indexOf(result.refId)
        this.refArr.splice(ctr,1)
        this.refDataSource = new  MatTableDataSource<any>(this.refArr)
        this.resetSortPage(currentRef)
      }
    })
  }

  goToAgencyPage(agencyId:any){
    this.router.navigate(['artemis/view/agency/'+agencyId]);
  }

  goToAgencyMembersList(agencyId:any){ 
    this.router.navigate(['agency/view/'+agencyId+'/members']);
  }
}