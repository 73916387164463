import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-artemis-user-management',
  templateUrl: './artemis-user-management.component.html',
  styleUrls: ['./artemis-user-management.component.scss']
})
export class ArtemisUserManagementComponent implements OnInit {
  users_count: any = [];
  
  guests_count: any = [];
  individual_count: any = [];
  organization_count: any = [];
  government_count: any = [];

  constructor(private afs: AngularFirestore, public auth: AngularFireAuth) { }

  ngOnInit(): void {

    this.loadUsersCounts()
  }

  async loadUsersCounts() {
    this.users_count = [];
    this.individual_count = []
    this.guests_count = []
    this.organization_count = []
    
                 
    this.afs.collection("Users").snapshotChanges()
      .pipe(
        tap(
          (data: any) => {
            data.forEach((info: any) => {
              let item: any = info.payload.doc.data();
              item.id = info.payload.doc.id;
              
              if(item['credentials']['access_artemis'] === true){
                this.users_count.push(item);
              }
              

               if(item['credentials']['account_type'] === "Personal" && item['credentials']['access_artemis'] === true){
                 this.individual_count.push(item)
               } 
              /*if(item.credentials.acount_type && 
                item.credentials.acount_type.toLowerCase() == 'personal'){
                  this.individual_count = []
                  this.individual_count.push(item)
                }*/
               else if(item['credentials']['account_type'] === "Guest" && item['credentials']['access_artemis'] === true){
                 this.guests_count.push(item)
               }  
              else if(item['credentials']['account_type'] === "Organization" && item['credentials']['access_artemis'] === true){
                this.organization_count.push(item)
              }        
              else if(item['credentials']['account_type'] === "Government" && item['credentials']['access_artemis'] === true){
                this.organization_count.push(item)
              } 
              
              //console.log(" Individual: " + item.credentials.account_type);        
            });
          }
        )
      )
      .subscribe()
  }  

}
