<div class="card p-2 mb-2">
    <div *ngIf="!loader" >
        <div class="bg-image hover-overlay ripple text-center" data-mdb-ripple-color="light">
            <img class="" [src]="agency_data?.agency_logo_url || ''" alt="" height="50" >
         </div>

         <div class="card-body">
            <label class="fw-bold p-2">({{agency_data?.agency_abrev || ''}}) {{agency_data?.agency_name || ''}}</label>
            <div class="px-2">
                <section>
                    <span><i class="fas fa-landmark"></i></span> {{agency_data?.agency_motheragency || ''}}
                </section>
                <section *ngIf="agency_data?.agency_contact">
                    <span class="px-1"><i class="fas fa-mobile"></i></span> {{agency_data?.agency_contact}}
                </section>
                <section class="p-2" *ngIf="agency_data?.agency_email">
                   <ng-container *ngIf="agency_data?.agency_email.length > 0">
                    <label for=""> Emails:</label>
                    <ul>
                        <li *ngFor="let email of agency_data.agency_email">
                            <span><i class="fas fa-envelope"></i></span> {{email.email}}
                        </li>
                   </ul>
                   </ng-container>
                   <ng-container *ngIf="agency_data?.agency_email.length <= 0">
                    <label for="">Email</label>
                    <span><i class="fas fa-envelope"></i>{{agency_data?.agency_email}}</span>
                   </ng-container>
                   
                </section>
            </div>
         </div>
      
    </div>
</div>