import { Injectable } from '@angular/core';
import { Action, AngularFirestore, DocumentChangeAction, DocumentSnapshot } from '@angular/fire/firestore';
import { Observable, combineLatest } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { EmailAppCode, EmailTemplateCode } from '../entities/emailTemplates';
import { GovernmentAccountSubtype } from '../entities/worklist';
import { accountRole } from '../agency-control/agency-register-member/agency-register-member.component';
import { NotificationsEmailer } from './notifications-emailer.service';
import { SnackbarNotifService } from './snackbar-notif.service';

@Injectable({
  providedIn: 'root'
})
export class EmailerService {

  constructor(
    private afs : AngularFirestore,
    private Ne: NotificationsEmailer,
    private notif: SnackbarNotifService
  ) { }



   getUsers(emailCode : EmailTemplateCode,Id : any) : Observable<DocumentChangeAction<unknown>[]>  {



    switch(emailCode){

    
      //when lead encoder click all services submitted button
      case EmailTemplateCode.AGENCY_LEAD_ENCODER_SUBMIT_ALL_SERVICES_TO_VERIFIER:

            return  this.pullOutEmailBasedOnAccountType( GovernmentAccountSubtype.AGENCY_VER ,accountRole.Lead_Verifier,Id )

      
      //when agency verifier submit CC to head of agency
      case EmailTemplateCode.AGENCY_LEAD_VERIFIER_SUBMIT_CC_TO_AGENCY_HEAD:

            return this.pullOutEmailBasedOnAccountType( GovernmentAccountSubtype.AGENCY_DIR ,accountRole.Default,Id )
      

      //when encoder resubmit the service to verifier
      case EmailTemplateCode.AGENCY_ENCODER_RESUBMIT_SERVICE_TO_VERIFIER:

            return this.pulloutEmailBasedOnWorklistSubCollection(Id)

      case EmailTemplateCode.ARTA_DIR_POST_CC:

          return this.pullOutEmailBasedOnAccountType( GovernmentAccountSubtype.AGENCY_DIR ,accountRole.Default,Id )

      case EmailTemplateCode.ARTA_DIR_RETURN_CC_TO_ENCODER:

          return this.pullOutEmailBasedOnAccountType( GovernmentAccountSubtype.AGENCY_ENC ,accountRole.Lead_Encoder,Id )

      default: 

        return this.pullOutEmailBasedOnAccountType( GovernmentAccountSubtype.AGENCY_DIR ,accountRole.Default,Id )

      
    }







  }


  pulloutEmailBasedOnWorklistSubCollection(worklistId : any  ) : Observable<DocumentChangeAction<unknown>[]>  {


    return   this.afs.collection("Worklist-Citizens-Charter").doc(worklistId)
    .collection("Users").snapshotChanges()

  }


  pullOutEmailBasedOnAccountType(accountType: GovernmentAccountSubtype, accountRole : accountRole,fromAgencyId:any) : Observable<DocumentChangeAction<unknown>[]>   {

  return  this.afs.collection("Users" , filter => filter
    .where("government.agency","==", fromAgencyId )
    .where("credentials.account_subtype","==",accountType)
    .where("credentials.role","==", accountRole ? accountRole : 'Basic' )).snapshotChanges()
    

  }

  //when agency submit cc to ARTA
  sendCCNotifToARTA(agencyEmail : any[],Agency : any ,CCVersion : any ){

    const Variables = {
       Agency : Agency,
       CCVersion : CCVersion,
    }

    agencyEmail.push("artemis@arta.gov.ph")

    this.Ne.emailServiceHandler(agencyEmail,
      EmailAppCode.ARTEMIS,
      EmailTemplateCode.AGENCY_HEAD_SUBMIT_CC_TO_ARTA, Variables )

  }


  sendCOCNotifOnlyToARTA(agencyEmail : any,Agency : any , COCversion : any  ){

    const Variables = {
       Agency : Agency,
       COCversion : COCversion
    }

    agencyEmail.push("artemis@arta.gov.ph")
    
    this.Ne.emailServiceHandler(

      agencyEmail,
      EmailAppCode.ARTEMIS,
      EmailTemplateCode.AGENCY_HEAD_SUBMIT_COC_TO_ARTA, Variables )

  }

  sendPostNotifToAgency(agencyEmail : any,Agency :any,citizensCharter : any){

    const Variables = {
      Agency : Agency,
      CitizensCharter : citizensCharter
   }

   this.Ne.emailServiceHandler(
      agencyEmail,
      EmailAppCode.ARTEMIS,
      EmailTemplateCode.ARTA_DIR_POST_CC,
      Variables
   )

  }

  pulloutAgencyEmails(agencyId:any) : Observable<Action<DocumentSnapshot<unknown>>> {

   return this.afs.collection("Agency").doc(agencyId).snapshotChanges()

  }

  firebaseEmailer(emails:any[],subject:any,htmlBody:any){
    this.afs.collection('mail').add({
      to: emails,
      message: {
        subject: subject,
        html: htmlBody
      },
    }).then(()=>{
      this.notif.displayNotif('success','Email notification sent.')
    })
  }



}
