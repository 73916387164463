    import { Component, OnInit, Input } from '@angular/core';
    import { AngularFirestore } from '@angular/fire/firestore';
    import { AngularFireStorage } from '@angular/fire/storage';
    import { MatTableDataSource } from '@angular/material/table';
    import { format } from 'date-fns';
    import { PDFDocumentProxy } from 'pdfjs-dist';
    // import { ActivatedRoute, Router } from '@angular/router';
    // import { AngularFireAuth } from '@angular/fire/auth';
    // import { PdfRenderService } from '../../pdf-render.service';
    // import { format } from 'date-fns';
    // import firebase from "firebase/app";
    // import Swal from 'sweetalert2';

    @Component({
    selector: 'app-regulations-dris-view',
    templateUrl: './regulations-dris-view.component.html',
    styleUrls: ['./regulations-dris-view.component.scss']
    })
    export class RegulationsDrisViewComponent implements OnInit {

    @Input() drisData:any;
    agencyOption:string;
    drisUploads:any= []

    constructor(
        private afs: AngularFirestore,
        private store: AngularFireStorage,
    ) { }

    ngOnInit(): void {
      this.drisUploads = []
      console.log("drisData",this.drisData)
      this.checkData()
    }
  
    // load cris data
    async checkData(){
      if(this.drisData){
        this.drisData.instrument_name = await this.dataLoaded('Instrument',this.drisData.reg_instrument)
    
        if(this.drisData.reg_classification ==  'Business'){
          this.drisData.sector_name = await this.dataLoaded('Sector',this.drisData.reg_sector)
          this.drisData.business_name = await this.dataLoaded('Stage of Business',this.drisData.reg_business)
          this.drisData.division_name = await this.dataLoaded('Division',this.drisData.reg_division)
          this.drisData.case_name = await this.dataLoaded('Case Use',this.drisData.reg_case)
        }else if(this.drisData.reg_classification ==  'Non-Business'){
          this.drisData.sol_name = await this.dataLoaded('Stage of Life',this.drisData.reg_stageoflife)
          this.drisData.event_name = await this.dataLoaded('Life Event',this.drisData.reg_lifeevent)
        }
    
        this.drisData.juris_name = await this.dataLoaded('Jurisdiction',this.drisData.reg_juris)
    
        if(this.drisData.reg_agency) this.checkAgency(this.drisData.reg_agency)
        this.checkUploads(this.drisData.upload_folders)
      }
    }
  
    checkUploads(upload_folders:any){
      // for(let key of Object.keys(upload_folders)){
      Object.keys(upload_folders).map(async (key)=>{
        let value = upload_folders[key]
        let value2 = value.split("/")
        // const fileData:any = await this.refToURL(value+"/"+value2[value2.length-1]) //remove others that value
        const fileData:any = await this.refToURL(value) //correct
        let name:any = ""
        if(key == 'policyOptions') name="Policy Options"
        else if(key == 'policyProblem') name="Policy Problem"
        else if(key == 'implementationEnforcement') name="Implementation Enforcement"
        else if(key == 'policyOptionsAssessment') name="Policy Options Assessment"
        else if(key == 'monitoringEvaluation') name="Monitoring Evaluation"
        else if(key == 'regulatoryImpactStatement') name="Regulatory Impact Statement"
        else if(key == 'impactAssessment') name="Impact Assessment"
        else if(key == 'consultationDocumentation') name="Consultation Documentation"
        else if(key == 'consultation') name="Consultation"
        let item:any = {
          key: key,
          name: name,
          value: value,
          fileBaseTotalPage: 1,
          fileBasePage: 1,
          downloadUrl: false,
          contentType: false,
        }
        if(fileData){
          item.downloadUrl = fileData.url
          item.contentType = fileData.metaData.contentType
  
          // console.log('item',item)
          this.drisUploads.push(item)
        }else{
          // console.log('item',item)
          this.drisUploads.push(item)
        }
      })
      // }
    }
  
    refToURL(filePath:string){
      return new Promise((res,rej)=>{
        this.store.ref(filePath).getDownloadURL()
        .subscribe({
          next:(data1)=>{
            this.store.ref(filePath).getMetadata()
            .subscribe({
              next:(data2)=>{
                let item:any = {
                  url: data1,
                  metaData: data2,
                }
                return res(item)
              },
              error:(err)=>{
                // console.error('refToURL Error: ',err)
                return res(false)
              }
            })
          },
          error:(err)=>{
            // console.error('refToURL Error: ',err)
            return res(false)
          }
        })
      })
    }
  
    async checkAgency(reg_agency:any){
      if(reg_agency){
        // load data first
        if(typeof reg_agency == 'string'){
          // this.agencyOption = 'single'
          // this.agency_item = reg_agency
          const singleAgencyData:any = await this.loadData('Agency',reg_agency)
          if(singleAgencyData){
            this.agencyOption = 'single'
            // this.agency_item = singleAgencyData ? singleAgencyData.agencyDetails ? singleAgencyData.agencyDetails.agencyName ? singleAgencyData.agencyDetails.agencyName : : : (singleAgencyData.name ? singleAgencyData.namesingleAgencyData.name : "Cannot get Agency")
            if(singleAgencyData){
              console.log('singleAgencyData',singleAgencyData)
              this.drisData.agency_item = this.agencyNaming1(singleAgencyData) 
            }else this.drisData.agency_item = this.agencyNaming2(singleAgencyData) 
          }
        }else if(Array.isArray(reg_agency)){
          this.agencyOption = 'multi'
          // this.agency_item = reg_agency
          const multiAgencyData:any = await this.multipleLoadData('Agency',reg_agency)
          if(multiAgencyData){
            console.log('multiAgencyData',multiAgencyData)
            this.drisData.agency_item = []
            multiAgencyData.map((singleAgencyData:any)=>{
              this.drisData.agency_item.push(this.agencyNaming1(singleAgencyData))
            })
          } else this.drisData.agency_item.push(this.agencyNaming2(null));
        }
      }
    }
  
    agencyNaming1(singleAgencyData:any){
      if(singleAgencyData.agencyDetails){
        if(singleAgencyData.agencyDetails.agencyName){
          return singleAgencyData.agencyDetails.agencyName
        }else return this.agencyNaming2(singleAgencyData)
      } else return this.agencyNaming2(singleAgencyData)
    }
  
    agencyNaming2(singleAgencyData:any){
      if(singleAgencyData.name){
        return singleAgencyData.name
      }else return "Cannot get Agency"
    }
  
    multipleLoadData(collection:any, arrValue:any){
      let tempArr:any = []
      return Promise.all(
        arrValue.map(async (data:any)=>{
          const item:any = await this.loadData(collection,data)
          if(item){
            if(!tempArr.map((item:any)=>item.id).includes(item.id)) tempArr.push(item)
          }
          return true
        })
      ).then(()=>tempArr)
    }
  
    async dataLoaded(collection:any, value:any){
      if(collection && value){
        const loadedData:any = await this.loadData(collection,value)
        if(loadedData){
          // if(collection == 'Sector' || collection == 'Stage of Life') this.crisLifeStatus = loadedData
          return loadedData.name
        }else return ""
      }else return ""
    }
  
    loadData(collection:any, value:any){
      return new Promise((res,rej)=>{
        this.afs.collection(collection).doc(value).snapshotChanges()
        .subscribe({
          next:(data)=>{
            if(data.payload.exists){
              let item:any = data.payload.data()
              item.id = data.payload.id
              return res(item)
            } else return res(false)
          },
          error:(err)=>{
            console.error('loadData Error: ',err)
            return res(false)
          }
        })
      })
    }
    // !load cris data
  
    // file functions
    checkAuthLetterFileType(type:any,fileType:any){
      if(fileType === type) return true
      return false
    }
  
    pageShift(action:any,ctr:any){
      if(action == "next"){
          if(this.drisUploads[ctr].fileBasePage < this.drisUploads[ctr].fileBaseTotalPage)
              this.drisUploads[ctr].fileBasePage++;
      }
      if(action == "back"){
          if(this.drisUploads[ctr].fileBasePage > 1)
              this.drisUploads[ctr].fileBasePage--;
      }
    }
  
    afterLoadComplete(pdf: PDFDocumentProxy, ctr:any){
      this.drisUploads[ctr].fileBaseTotalPage = pdf.numPages;
    }
    // !file functions
}
