<div class="card shadow m-3">
    <div class="card-header">

        <a (click)="actionModal('create', 'Announcements', '')" class="btn btn-dark float-end">Create</a>


    </div>
    <div class="card-body">
        <table class="table table-hover table-responsive w-100">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Name of Announcement</th>
                    <th>Tag</th>
                    <th>Category</th>
                    <th>Definition</th>
                    <th colspan="3"></th>
                </tr>
            </thead>
            <div class="spinner-grow text-center" role="status" *ngIf="loader">
                <span class="visually-hidden">Loading...</span>
            </div>
            <tbody *ngIf="!loader">
                <tr *ngFor="let entry of announcements ">
                    <th>{{ entry.date.toDate() | date:'MM/dd/yyyy' }}</th>
                    <th>{{ entry.title }}</th>
                    <th>{{ entry.tag }}</th>
                    <th>{{ entry.category }}</th>
                    <td>{{ entry.description }}</td>

                    <td scope="row">
                        <div class="btn-group">
                            <a class="btn btn-sm btn-warning" (click)="actionModal('update', 'Announcements', entry)">Update</a>
                            <a class="btn btn-sm btn-danger" (click)="actionModal('delete', 'Announcements', entry)">Delete</a>
                        </div>


                </tr>
            </tbody>
            <tbody *ngIf="announcements.length < 1 && !loader">
                <tr>
                    <td colspan="4">Your Announcements are empty</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>