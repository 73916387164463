
<div class="content">
    <div class="container-fluid p-4">
        <h4 #common_home>Common</h4>
        <div class="btn-group ms-auto mb-3">
            <a class="btn btn-outline-dark" (click)="goTo(sector)">Sector</a>
            <a class="btn btn-outline-dark" (click)="goTo(org_code)">Organization Code</a>
            <a class="btn btn-outline-dark" (click)="goTo(loc_code)">Location Code</a>
            <a class="btn btn-outline-dark" (click)="goTo(psic_code)">PSIC Code</a>
        </div>

        <h4 #regulation_home>Regulations</h4>
        <div class="btn-group ms-auto mb-3">
            <a class="btn btn-outline-dark" (click)="goTo(sob)">Stage of Business</a>
            <a class="btn btn-outline-dark" (click)="goTo(division)">Division</a>
            <a class="btn btn-outline-dark" (click)="goTo(juris)">Jurisdiction</a>
            <a class="btn btn-outline-dark" (click)="goTo(case)">Case Use</a>
            <a class="btn btn-outline-dark" (click)="goTo(location)">Agency Location</a>
            <a class="btn btn-outline-dark" (click)="goTo(document)">Document</a>
            <a class="btn btn-outline-dark" (click)="goTo(instrument)">Instruments</a>
        </div>

        <div class="jumbotron-fluid bg-light shadow-lg p-3 m-3">
            <h2>Common</h2>
            <!-- Sectors -->
            <div class="row m-3" #sector>
                <div class="card">
                    <div class="card-header">
                        <div class="col">
                            <h3 class="card-title">Sectors</h3>
                            <small class="card-subtitle">Based on 2009 PSIC Sector List (Annex C)</small>
                        </div>
                        <div class="col btn-group">
                            <a (click)="actionModal('create', 'Sector', '')" class="btn btn-dark">Create</a>
                            <button class="btn btn-outline-dark" (click)="goTo(common_home)"><i class="fas fa-angle-double-up"></i> Back to Top</button>
                        </div>

                    </div>
                    <div class="card-body">
                        <table class="table table-hover table-responsive w-100">
                            <thead>
                                <tr>
                                    <th>Section</th>
                                    <th>Name of Sector</th>
                                    <th>Definition</th>
                                    <th colspan="3"></th>
                                </tr>
                            </thead>
                            <div class="spinner-grow text-center" role="status" *ngIf="loader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <tbody *ngIf="!loader">
                                <tr *ngFor="let entry of sectors ">
                                    <th>{{ entry.section }}</th>
                                    <th>{{ entry.name }}</th>
                                    <td>{{ entry.definition }}</td>
                                    <td scope="row">
                                        <a routerLink="sector" class="btn btn-sm btn-primary">View</a>
                                    </td>
                                    <td scope="row"><a (click)="actionModal('update', 'Sector', entry)" class="btn btn-sm btn-warning">Update</a></td>
                                    <td scope="row"><a (click)="actionModal('delete', 'Sector', entry)" class="btn btn-sm btn-danger">Delete</a></td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="sectors.length < 1 && !loader">
                                <tr>
                                    <td colspan="4">Your sectors are empty</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="row m-3" #org_code>
                <div class="card">
                    <div class="card-header">
                        <div class="col">
                            <h3 class="card-title">Organization Codes</h3>
                            <small class="card-subtitle">--</small>
                        </div>
                        <div class="col btn-group">
                            <a (click)="actionModal('create', 'Organization Code', '')" class="btn btn-dark">Create</a>
                            <button class="btn btn-outline-dark" (click)="goTo(common_home)"><i class="fas fa-angle-double-up"></i> Back to Top</button>
                        </div>

                    </div>
                    <div class="card-body">
                        <table class="table table-hover table-responsive w-100">
                            <thead>
                                <tr>
                                    <th>Section</th>
                                    <th>Name of Organization Code</th>
                                    <th>Definition</th>
                                    <th colspan="3"></th>
                                </tr>
                            </thead>
                            <div class="spinner-grow text-center" role="status" *ngIf="loader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <tbody *ngIf="!loader">
                                <tr *ngFor="let entry of org_codes ">
                                    <th>{{ entry.section }}</th>
                                    <th>{{ entry.name }}</th>
                                    <td>{{ entry.definition }}</td>
                                    <td scope="row">
                                        <a routerLink="sector" class="btn btn-sm btn-primary">View</a>
                                    </td>
                                    <td scope="row"><a (click)="actionModal('update', 'Organization Code', entry)" class="btn btn-sm btn-warning">Update</a></td>
                                    <td scope="row"><a (click)="actionModal('delete', 'Organization Code', entry)" class="btn btn-sm btn-danger">Delete</a></td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="org_codes.length < 1 && !loader">
                                <tr>
                                    <td colspan="4">Your organization codes are empty</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="row m-3" #loc_code>
                <div class="card">
                    <div class="card-header">
                        <div class="col">
                            <h3 class="card-title">Location Codes</h3>
                            <small class="card-subtitle">--</small>
                        </div>
                        <div class="col btn-group">
                            <a (click)="actionModal('create', 'Location Code', '')" class="btn btn-dark">Create</a>
                            <button class="btn btn-outline-dark" (click)="goTo(common_home)"><i class="fas fa-angle-double-up"></i> Back to Top</button>
                        </div>

                    </div>
                    <div class="card-body">
                        <table class="table table-hover table-responsive w-100">
                            <thead>
                                <tr>
                                    <th>Section</th>
                                    <th>Name of Location Code</th>
                                    <th>Definition</th>
                                    <th colspan="3"></th>
                                </tr>
                            </thead>
                            <div class="spinner-grow text-center" role="status" *ngIf="loader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <tbody *ngIf="!loader">
                                <tr *ngFor="let entry of location_codes ">
                                    <th>{{ entry.section }}</th>
                                    <th>{{ entry.name }}</th>
                                    <td>{{ entry.definition }}</td>
                                    <td scope="row">
                                        <a routerLink="sector" class="btn btn-sm btn-primary">View</a>
                                    </td>
                                    <td scope="row"><a (click)="actionModal('update', 'Location Code', entry)" class="btn btn-sm btn-warning">Update</a></td>
                                    <td scope="row"><a (click)="actionModal('delete', 'Location Code', entry)" class="btn btn-sm btn-danger">Delete</a></td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="location_codes.length < 1 && !loader">
                                <tr>
                                    <td colspan="4">Your location codes are empty</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="row m-3" #psic_code>
                <div class="card">
                    <div class="card-header">
                        <div class="col">
                            <h3 class="card-title">PSIC Codes</h3>
                            <small class="card-subtitle">--</small>
                        </div>
                        <div class="col btn-group">
                            <a (click)="actionModal('create', 'PSIC Code', '')" class="btn btn-dark">Create</a>
                            <button class="btn btn-outline-dark" (click)="goTo(common_home)"><i class="fas fa-angle-double-up"></i> Back to Top</button>
                        </div>

                    </div>
                    <div class="card-body">
                        <table class="table table-hover table-responsive w-100">
                            <thead>
                                <tr>
                                    <th>Section</th>
                                    <th>Name of PSIC Code</th>
                                    <th>Definition</th>
                                    <th colspan="3"></th>
                                </tr>
                            </thead>
                            <div class="spinner-grow text-center" role="status" *ngIf="loader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <tbody *ngIf="!loader">
                                <tr *ngFor="let entry of psic_codes ">
                                    <th>{{ entry.section }}</th>
                                    <th>{{ entry.name }}</th>
                                    <td>{{ entry.definition }}</td>
                                    <td scope="row">
                                        <a routerLink="sector" class="btn btn-sm btn-primary">View</a>
                                    </td>
                                    <td scope="row"><a (click)="actionModal('update', 'Sector', entry)" class="btn btn-sm btn-warning">Update</a></td>
                                    <td scope="row"><a (click)="actionModal('delete', 'Sector', entry)" class="btn btn-sm btn-danger">Delete</a></td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="psic_codes.length < 1 && !loader">
                                <tr>
                                    <td colspan="4">Your PSIC Codes are empty</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="jumbotron-fluid bg-light shadow-lg p-3 m-3">
            <h2>Regulations</h2>

            <!-- Stage of Business -->
            <div class="row m-3" #sob>
                <div class="card">
                    <div class="card-header">
                        <div class="col">
                            <h3 class="card-title">Stage of Business</h3>
                            <small class="card-subtitle">--</small>
                        </div>
                        <div class="col btn-group">
                            <a (click)="actionModal('create', 'Stage of Business', '')" class="btn btn-dark">Create</a>
                            <button class="btn btn-outline-dark" (click)="goTo(regulation_home)"><i class="fas fa-angle-double-up"></i> Back to Top</button>
                        </div>

                    </div>
                    <div class="card-body">
                        <table class="table table-hover table-responsive w-100">
                            <thead>
                                <tr>
                                    <th>Section</th>
                                    <th>Stage of Business</th>
                                    <th>Definition</th>
                                    <th colspan="3"></th>
                                </tr>
                            </thead>
                            <div class="spinner-grow text-center" role="status" *ngIf="loader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <tbody *ngIf="!loader">
                                <tr *ngFor="let entry of sobs ">
                                    <th>{{ entry.section }}</th>
                                    <th>{{ entry.name }}</th>
                                    <td>{{ entry.definition }}</td>
                                    <td scope="row">
                                        <a routerLink="sector" class="btn btn-sm btn-primary">View</a>
                                    </td>
                                    <td scope="row"><a (click)="actionModal('update', 'Stage of Business', entry)" class="btn btn-sm btn-warning">Update</a></td>
                                    <td scope="row"><a (click)="actionModal('delete', 'Stage of Business', entry)" class="btn btn-sm btn-danger">Delete</a></td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="sobs.length < 1 && !loader">
                                <tr>
                                    <td colspan="4">Your stages of business are empty</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <!-- Division -->
            <div class="row m-3" #division>
                <div class="card">
                    <div class="card-header">
                        <div class="col">
                            <h3 class="card-title">Division</h3>
                            <small class="card-subtitle">--</small>
                        </div>
                        <div class="col btn-group">
                            <a (click)="actionModal('create', 'Division', '')" class="btn btn-dark">Create</a>
                            <button class="btn btn-outline-dark" (click)="goTo(regulation_home)"><i class="fas fa-angle-double-up"></i> Back to Top</button>
                        </div>

                    </div>
                    <div class="card-body">
                        <table class="table table-hover table-responsive w-100">
                            <thead>
                                <tr>
                                    <th>Section</th>
                                    <th>Name of Division</th>
                                    <th>Definition</th>
                                    <th colspan="3"></th>
                                </tr>
                            </thead>
                            <div class="spinner-grow text-center" role="status" *ngIf="loader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <tbody *ngIf="!loader">
                                <tr *ngFor="let entry of divisions ">
                                    <th>{{ entry.section }}</th>
                                    <th>{{ entry.name }}</th>
                                    <td>{{ entry.definition }}</td>
                                    <td scope="row">
                                        <a routerLink="sector" class="btn btn-sm btn-primary">View</a>
                                    </td>
                                    <td scope="row"><a (click)="actionModal('update', 'Division', entry)" class="btn btn-sm btn-warning">Update</a></td>
                                    <td scope="row"><a (click)="actionModal('delete', 'Division', entry)" class="btn btn-sm btn-danger">Delete</a></td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="divisions.length < 1 && !loader">
                                <tr>
                                    <td colspan='4'>Your agency locations uses are empty</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <!-- Jurisdiction -->
            <div class="row m-3" #juris>
                <div class="card">
                    <div class="card-header">
                        <div class="col">
                            <h3 class="card-title">Jurisdiction</h3>
                            <small class="card-subtitle">--</small>
                        </div>
                        <div class="col btn-group">
                            <a (click)="actionModal('create', 'Jurisdiction', '')" class="btn btn-dark">Create</a>
                            <button class="btn btn-outline-dark" (click)="goTo(regulation_home)"><i class="fas fa-angle-double-up"></i> Back to Top</button>
                        </div>

                    </div>
                    <div class="card-body">
                        <table class="table table-hover table-responsive w-100">
                            <thead>
                                <tr>
                                    <th>Section</th>
                                    <th>Name of Jurisdiction</th>
                                    <th>Definition</th>
                                    <th colspan="3"></th>
                                </tr>
                            </thead>
                            <div class="spinner-grow text-center" role="status" *ngIf="loader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <tbody *ngIf="!loader">
                                <tr *ngFor="let entry of jurisdictions">
                                    <th>{{ entry.section }}</th>
                                    <th>{{ entry.name }}</th>
                                    <td>{{ entry.definition }}</td>
                                    <td scope="row">
                                        <a routerLink="sector" class="btn btn-sm btn-primary">View</a>
                                    </td>
                                    <td scope="row"><a (click)="actionModal('update', 'Jurisdiction', entry)" class="btn btn-sm btn-warning">Update</a></td>
                                    <td scope="row"><a (click)="actionModal('delete', 'Jurisdiction', entry)" class="btn btn-sm btn-danger">Delete</a></td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="jurisdictions.length < 1 && !loader">
                                <tr>
                                    <td colspan='4'>Your jurisdictions are empty</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <!-- Case Use -->
            <div class="row m-3" #case>
                <div class="card">
                    <div class="card-header">
                        <div class="col">
                            <h3 class="card-title">Case Use</h3>
                            <small class="card-subtitle">Transactions</small>
                        </div>
                        <div class="col btn-group">
                            <a (click)="actionModal('create', 'Case Use', '')" class="btn btn-dark">Create</a>
                            <button class="btn btn-outline-dark" (click)="goTo(regulation_home)"><i class="fas fa-angle-double-up"></i> Back to Top</button>
                        </div>

                    </div>
                    <div class="card-body">
                        <table class="table table-hover table-responsive w-100">
                            <thead>
                                <tr>
                                    <th>Section</th>
                                    <th>Case Use</th>
                                    <th>Definition</th>
                                    <th colspan="3"></th>
                                </tr>
                            </thead>
                            <div class="spinner-grow text-center" role="status" *ngIf="loader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <tbody *ngIf="!loader">
                                <tr *ngFor="let entry of cases ">
                                    <th>{{ entry.section }}</th>
                                    <th>{{ entry.name }}</th>
                                    <td>{{ entry.definition }}</td>
                                    <td scope="row">
                                        <a routerLink="sector" class="btn btn-sm btn-primary">View</a>
                                    </td>
                                    <td scope="row"><a (click)="actionModal('update', 'Case Use', entry)" class="btn btn-sm btn-warning">Update</a></td>
                                    <td scope="row"><a (click)="actionModal('delete', 'Case Use', entry)" class="btn btn-sm btn-danger">Delete</a></td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="cases.length < 1 && !loader">
                                <tr>
                                    <td colspan='4'>Your case uses are empty</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <!-- Agency Location -->
            <div class="row m-3" #location>
                <div class="card">
                    <div class="card-header">
                        <div class="col">
                            <h3 class="card-title">Agency Location</h3>
                            <small class="card-subtitle">--</small>
                        </div>
                        <div class="col btn-group">
                            <a (click)="actionModal('create', 'Agency Location', '')" class="btn btn-dark">Create</a>
                            <button class="btn btn-outline-dark" (click)="goTo(regulation_home)"><i class="fas fa-angle-double-up"></i> Back to Top</button>
                        </div>

                    </div>
                    <div class="card-body">
                        <table class="table table-hover table-responsive w-100">
                            <thead>
                                <tr>
                                    <th>Section</th>
                                    <th>Location</th>
                                    <th>Definition</th>
                                    <th colspan="3"></th>
                                </tr>
                            </thead>
                            <div class="spinner-grow text-center" role="status" *ngIf="loader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <tbody *ngIf="!loader">
                                <tr *ngFor="let entry of locations ">
                                    <th>{{ entry.section }}</th>
                                    <th>{{ entry.name }}</th>
                                    <td>{{ entry.definition }}</td>
                                    <td scope="row">
                                        <a routerLink="sector" class="btn btn-sm btn-primary">View</a>
                                    </td>
                                    <td scope="row"><a (click)="actionModal('update', 'Agency Location', entry)" class="btn btn-sm btn-warning">Update</a></td>
                                    <td scope="row"><a (click)="actionModal('delete', 'Agency Location', entry)" class="btn btn-sm btn-danger">Delete</a></td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="locations.length < 1 && !loader">
                                <tr>
                                    <td colspan='4'>Your agency locations are empty</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <!-- Document -->
            <div class="row m-3" #document>
                <div class="card">
                    <div class="card-header">
                        <div class="col">
                            <h3 class="card-title">Document</h3>
                            <small class="card-subtitle">--</small>
                        </div>
                        <div class="col btn-group">
                            <a (click)="actionModal('create', 'Document', '')" class="btn btn-dark">Create</a>
                            <button class="btn btn-outline-dark" (click)="goTo(regulation_home)"><i class="fas fa-angle-double-up"></i> Back to Top</button>
                        </div>

                    </div>
                    <div class="card-body">
                        <table class="table table-hover table-responsive w-100">
                            <thead>
                                <tr>
                                    <th>Section</th>
                                    <th>Name of Document</th>
                                    <th>Definition</th>
                                    <th colspan="3"></th>
                                </tr>
                            </thead>
                            <div class="spinner-grow text-center" role="status" *ngIf="loader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <tbody *ngIf="!loader">
                                <tr *ngFor="let entry of documents ">
                                    <th>{{ entry.section }}</th>
                                    <th>{{ entry.name }}</th>
                                    <td>{{ entry.definition }}</td>
                                    <td scope="row">
                                        <a routerLink="sector" class="btn btn-sm btn-primary">View</a>
                                    </td>
                                    <td scope="row"><a (click)="actionModal('update', 'Document', entry)" class="btn btn-sm btn-warning">Update</a></td>
                                    <td scope="row"><a (click)="actionModal('delete', 'Document', entry)" class="btn btn-sm btn-danger">Delete</a></td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="documents.length < 1 && !loader">
                                <tr>
                                    <td colspan='4'>Your documents are empty</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <!--Instrument -->
            <div class="row m-3" #instrument>
                <div class="card">
                    <div class="card-header">
                        <div class="col">
                            <h3 class="card-title">Name of Instrument</h3>
                            <small class="card-subtitle">--</small>
                        </div>
                        <div class="col btn-group">
                            <a (click)="actionModal('create', 'Instrument', '')" class="btn btn-dark">Create</a>
                            <button class="btn btn-outline-dark" (click)="goTo(regulation_home)"><i class="fas fa-angle-double-up"></i> Back to Top</button>
                        </div>

                    </div>
                    <div class="card-body">
                        <table class="table table-hover table-responsive w-100">
                            <thead>
                                <tr>
                                    <th>Section</th>
                                    <th>Name of Sector</th>
                                    <th>Definition</th>
                                    <th colspan="3"></th>
                                </tr>
                            </thead>
                            <div class="spinner-grow text-center" role="status" *ngIf="loader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <tbody *ngIf="!loader">
                                <tr *ngFor="let entry of instruments ">
                                    <th>{{ entry.section }}</th>
                                    <th>{{ entry.name }}</th>
                                    <td>{{ entry.definition }}</td>
                                    <td scope="row">
                                        <a routerLink="sector" class="btn btn-sm btn-primary">View</a>
                                    </td>
                                    <td scope="row"><a (click)="actionModal('update', 'Instrument', entry)" class="btn btn-sm btn-warning">Update</a></td>
                                    <td scope="row"><a (click)="actionModal('delete', 'Instrument', entry)" class="btn btn-sm btn-danger">Delete</a></td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="instruments.length < 1 && !loader">
                                <tr>
                                    <td colspan='4'>Your instruments are empty</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>