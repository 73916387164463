<div class="mx-auto">
    <table class = "table table-bordered">
        <thead class = "table table-dark text-center">
            <tr>
                <th colspan = "2">SUMMARY REPORT  - MULTI-STAGE PROCESSING SERVICES</th>
            </tr>
            <tr>
                <!-- <th colspan = "2">(October 1 - 30, 2020)</th> -->
                <th colspan = "2">
                   

                </tr>
        </thead>
        <tbody>
            <tr>
                <td class = "table-warning">TOTAL APPROVED:</td>
                <td></td>
            </tr>
            <tr>
                <td class = "table-warning">PENDING:</td>
                <td></td>
            </tr>
          <tr>
          </table>
          
                <table class = "table table-bordered mb-0">
                    
                        <thead class = "table table-light">
                            <tr>
                                <th colspan = "2">COUNT PER CATEGORY (BASED ON SERVICE OWNER)</th>
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr>
                                <td>National Government Agency: </td>
                                <td>#</td>
                            </tr>
                            <tr>
                                <td>Government-Owned or -Controlled Corporation: </td>
                                <td>#</td>
                            </tr>
                            <tr>
                                <td>Local Government Units: </td>
                                <td>#</td>
                            </tr>
                            <tr>
                                <td class = "text-end">Province: </td>
                                <td>#</td>
                            </tr>
                            <tr>
                                <td class = "text-end">City: </td>
                                <td>#</td>
                            </tr>
                            <tr>
                                <td class = "text-end">Municipality: </td>
                                <td>#</td>
                            </tr>
                            <tr>
                                <td class = "text-end">Barangay: </td>
                                <td>#</td>
                            </tr>
                            <tr>
                                <td>State University and College: </td>
                                <td>#</td>
                            </tr>
                            <tr>
                                <td>Water District: </td>
                                <td>#</td>
                            </tr>
                            <tr>
                                <td>Hospital: </td>
                                <td>#</td>
                            </tr>
                            
                        </tbody>
                        
                   </table>
                

                 
</div>