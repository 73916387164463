import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { CreateSectorComponent } from 'src/app/create-sector/create-sector.component'

@Component({
  selector: 'app-pbris-references',
  templateUrl: './pbris-references.component.html',
  styleUrls: ['./pbris-references.component.scss']
})
export class PbrisReferencesComponent implements OnInit {
  modalRef?: MDBModalRef;
  sectors:any[] = [];
  org_codes: any[] = [];
  location_codes: any[] = [];
  psic_codes: any[] = [];
  sobs:any[] = [];
  divisions:any[] = [];
  jurisdictions:any[] = [];
  cases:any[] = [];
  locations:any[] = [];
  documents:any[] = [];
  instruments:any[] = [];
  loader: boolean = true;

  constructor(private router: Router,
    public modalService: MDBModalService,
    private afs: AngularFirestore) { }

  ngOnInit(): void {
    this.loadSectors()
    this.loadOrgCodes()
    this.loadLocCodes()
    this.loadPSICCodes()
    this.loadSobs()
    this.loadDivisions()
    this.loadJuris()
    this.loadCases()
    this.loadLocations()
    this.loadDocuments()
    this.loadInstruments()
  }

  /**
   *  ________________________
   * |                        |
   * |        Reminder        |
   * |________________________|
   * 
   * Please move all firestore references
   * to 'References'=>'common/PBRIS/ARTEMIS'=>...
   * 
   */

  loadSectors(){
    this.afs.collection(`Sector`, filter => filter.orderBy('section')).snapshotChanges().subscribe(
      (data: any) => {
        this.sectors = []
        this.loader = false;
          data.forEach((info: any) => {
            
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.sectors.includes(item) === false){
              this.sectors.push(item);
            }     
          });
      });
  }

  loadOrgCodes(){
    this.afs.collection(`Organization Code`, filter => filter.orderBy('section')).snapshotChanges().subscribe(
      (data: any) => {
        this.org_codes = []
        this.loader = false;
          data.forEach((info: any) => {
            
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.org_codes.includes(item) === false){
              this.org_codes.push(item);
            }     
          });
      });
  }

  loadLocCodes(){
    this.afs.collection(`Location Code`, filter => filter.orderBy('section')).snapshotChanges().subscribe(
      (data: any) => {
        this.location_codes = []
        this.loader = false;
          data.forEach((info: any) => {
            
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.location_codes.includes(item) === false){
              this.location_codes.push(item);
            }     
          });
      });
  }

  loadPSICCodes(){
    this.afs.collection(`PSIC Code`, filter => filter.orderBy('section')).snapshotChanges().subscribe(
      (data: any) => {
        this.psic_codes = []
        this.loader = false;
          data.forEach((info: any) => {
            
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.psic_codes.includes(item) === false){
              this.psic_codes.push(item);
            }     
          });
      });
  }

  loadSobs(){
    this.afs.collection(`Stage of Business`, filter => filter.orderBy('section')).snapshotChanges().subscribe(
      (data: any) => {
        this.sobs = []
        this.loader = false;
          data.forEach((info: any) => {
            
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.sobs.includes(item) === false){
              this.sobs.push(item);
            }     
          });
      });
  }

  loadDivisions(){
    this.afs.collection(`Division`, filter => filter.orderBy('section')).snapshotChanges().subscribe(
      (data: any) => {
        this.divisions = []
        this.loader = false;
          data.forEach((info: any) => {
            
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.divisions.includes(item) === false){
              this.divisions.push(item);
            }     
          });
      });
  }

  loadJuris(){
    this.afs.collection(`Jurisdiction`, filter => filter.orderBy('section')).snapshotChanges().subscribe(
      (data: any) => {
        this.jurisdictions = []
        this.loader = false;
          data.forEach((info: any) => {
            
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.jurisdictions.includes(item) === false){
              this.jurisdictions.push(item);
            }     
          });
      });
  }

  loadCases(){
    this.afs.collection(`Case Use`, filter => filter.orderBy('section')).snapshotChanges().subscribe(
      (data: any) => {
        this.cases = []
        this.loader = false;
          data.forEach((info: any) => {
            
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.cases.includes(item) === false){
              this.cases.push(item);
            }     
          });
      });
  }

  loadLocations(){
    this.afs.collection(`Agency Location`, filter => filter.orderBy('section')).snapshotChanges().subscribe(
      (data: any) => {
        this.locations = []
        this.loader = false;
          data.forEach((info: any) => {
            
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.locations.includes(item) === false){
              this.locations.push(item);
            }     
          });
      });
  }

  loadDocuments(){
    this.afs.collection(`Document`, filter => filter.orderBy('section')).snapshotChanges().subscribe(
      (data: any) => {
        this.documents = []
        this.loader = false;
          data.forEach((info: any) => {
            
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.documents.includes(item) === false){
              this.documents.push(item);
            }     
          });
      });
  }

  loadInstruments(){
    this.afs.collection(`Instrument`, filter => filter.orderBy('section')).snapshotChanges().subscribe(
      (data: any) => {
        this.instruments = []
        this.loader = false;
          data.forEach((info: any) => {
            
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.instruments.includes(item) === false){
              this.instruments.push(item);
            }     
          });
      });
  }

  actionModal(method: string, collection: string, item: any){
    this.modalRef = this.modalService.show(CreateSectorComponent,
      { backdrop: 'static',
        data:{
        collection: collection,
        method: method,
        id: item.id
      }
      });
  }

  goTo(el: HTMLElement){
    el.scrollIntoView();
  }

}
