<app-navbar [currentPage]="'Regulations'"></app-navbar>
<div class="page-header theme-registration mb-3 py-3">
  <div class="container">
    <div class="row">
      <div class="col-6 title-widget info-widget">
        <h5 class="fw-bold py-2">REGULATIONS</h5>
      </div>
      <div class="col-6 d-none d-md-block">
        <div class="float-end">
          <button type="button" class="btn btn-light btn-floating mx-1" style="width: auto; height: auto;" hidden>
            <a href="/pbris/regulations/existing" title="Existing Regulation">
              <img src="/assets/Assets/Icons/Existing%20Regulation.png" height="70" alt="Existing Regulation" />
            </a>
          </button>
          <button type="button" class="btn btn-light btn-floating mx-1" style="width: auto; height: auto;" hidden>
            <a href="/pbris/regulations/proposed" title="Proposed Regulation">
              <img src="/assets/Assets/Icons/Proposed%20Regulation.png" height="70" alt="Proposed Regulation" />
            </a>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container py-2">
  <!-- Title -->
  <!--<div class="jumbotron bg-primary p-3 my-3"> -->
    <!-- PBRIS title with search Bar-->
    <!--<div class="container">
      <div class="row text-end">
        <div class="col">
          <h1 class="text-white">Search for Regulations</h1>
          <div class="row justify-content-end m-0">
            <p class="border border-warning w-50 me-1"></p>
          </div>
        </div>
        <div class="col-1 my-2">
          <h1 class="text-warning"><i class="fas fa-archive"></i></h1>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Tabs -->
    <!-- <ul class="nav nav-tabs mt-4" id="ex1" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link border border-bottom-0 border-dark rounded-top active" id="ex1-tab-1" data-mdb-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true">
            Existing Regulations</a>
        </li>
        <li class="nav-item mx-1" role="presentation">
            <a class="nav-link border border-bottom-0 border-dark rounded-top" id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-2" role="tab" aria-controls="ex1-tabs-2" aria-selected="true">
            Proposed Regulations</a>
        </li>
    </ul> -->

  <!-- Tabs content -->
  <div class="tab-content" id="ex1-content">
    <!-- existing selections -->
    <div class="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
      <h5 class="fw-bold mt-2" for="keyword">SEARCH REGULATIONS</h5>

      <!-- Advance Search -->
      <app-regulations-search></app-regulations-search> 

      <form [formGroup]="searchform">
        <!-- <div class="row input-group mt-4 p-2">
          <h6 class="fw-bold mb-3" for="keyword">Keyword/s</h6>
          <input type="search" class="form-control rounded" placeholder="-" formControlName="keyword">
        </div> -->

        <div class="row">
          
            <!-- Form button -->
            <div class="form-group p-0 m-0">
              <!-- <div class="row" role="tablist">
                <button class="btn btn-light col m-1">Classification</button>
                <button class="btn btn-light col m-1">Sector</button>
                <button class="btn btn-light col m-1">Stage of Business</button>
                <button class="btn btn-light col m-1">Jurisdiction</button>
              </div>

              <div class="row" role="tablist">
                <button class="btn btn-light col m-1">Effectivity</button>
                <button class="btn btn-light col m-1">Division</button>
                <button class="btn btn-light col m-1">Case Use</button>
                <button class="btn btn-light col m-1">Agency</button>
              </div>

              <ul class="nav nav-pills row" id="firstrow" role="tablist">
                <li class="nav-item m-1 col" role="presentation">
                  <button class="nav-link btn btn-light w-100" id="class-tab" data-bs-toggle="tab" data-bs-target="#class" type="button" role="tab" aria-controls="class" aria-selected="false">Classification</button>
                </li>
                <li class="nav-item m-1 col" role="presentation">
                  <button class="nav-link btn btn-light w-100" id="sector-tab" data-bs-toggle="tab" data-bs-target="#sector" type="button" role="tab" aria-controls="sector" aria-selected="false">Sector</button>
                </li>
                <li class="nav-item m-1 col" role="presentation">
                  <button class="nav-link btn btn-light w-100" id="sob-tab" data-bs-toggle="tab" data-bs-target="#sob" type="button" role="tab" aria-controls="sob" aria-selected="false">Stage of Business</button>
                </li>
                <li class="nav-item m-1 col" role="presentation">
                  <button class="nav-link btn btn-light w-100" id="juris-tab" data-bs-toggle="tab" data-bs-target="#juris" type="button" role="tab" aria-controls="juris" aria-selected="false">Jurisdiction</button>
                </li>
              </ul>

              <div class="tab-content">
                <div class="tab-pane fade" id="class" role="tabpanel" aria-labelledby="class-tab">class</div>
                <div class="tab-pane fade" id="sector" role="tabpanel" aria-labelledby="sector-tab">sector</div>
                <div class="tab-pane fade" id="sob" role="tabpanel" aria-labelledby="sob-tab">sob</div>
                <div class="tab-pane fade" id="juris" role="tabpanel" aria-labelledby="juris-tab">juris</div>
              </div> -->

              <!-- <div class="row">
                <button class="btn btn-ligth col m-1" type="button" [ngClass]="searchform.value.keyword_class? 'active':''" (click)="classDetailsView()">
                  <span [ngClass]="searchform.value.keyword_class? 'text-white':''">Classification</span>
                </button>

                <div class="col-lg-6 col-sm-12 p-3 my-1">
                  <h6 class="fw-bold mb-3">Select Classification</h6>
                  <select type="select" [disabled]="regulationsLoading" class="form-select" #select_class (change)="universalChange('class',select_class.value)">
                    <option selected readonly value="">-</option>
                    <ng-container *ngFor="let item of universalOption('class')">
                      <option [selected]="universalSelector('class',item)" [value]="item">{{item}}</option>
                    </ng-container>
                  </select>
                </div>

                <div class="col-lg-6 col-sm-12 p-3 my-1">
                  <h6 class="fw-bold mb-3">Select Effectivity</h6>
                  <select type="select" [disabled]="regulationsLoading" class="form-select" #select_effectivity (change)="universalChange('effectivity_status',select_effectivity.value)">
                    <option selected readonly value="">Select Effectivity</option>
                    <ng-container *ngFor="let item of universalOption('effectivity_status')">
                      <option [selected]="universalSelector('effectivity_status',item)" [value]="item">{{item}}</option>
                    </ng-container>
                  </select>
                </div>
                <button class="btn btn-light col m-1" [disabled]="regulationsLoading" type="button" [ngClass]="searchform.value.keyword_sector? 'active':''" (click)="sectorDetailsView()">
                  <span [ngClass]="searchform.value.keyword_sector? 'text-white':''">Sector</span>
                </button>
                <button class="btn btn-light col m-1" [disabled]="regulationsLoading" type="button" [ngClass]="searchform.value.keyword_sob? 'active':''" (click)="sobDetailsView()">
                  <span [ngClass]="searchform.value.keyword_sob? 'text-white':''">Stage of Business</span>
                </button>
                <button class="btn btn-light col m-1" [disabled]="regulationsLoading" type="button" [ngClass]="searchform.value.keyword_juris? 'active':''" (click)="jurisDetailsView()">
                  <span [ngClass]="searchform.value.keyword_juris? 'text-white':''">Jurisdiction</span>
                </button>
              </div> -->

              <!-- <div class="row">
                <button class="btn btn-ligth col m-1" type="button" [ngClass]="searchform.value.keyword_effectivity? 'active':''" (click)="effectivityDetailsView()">
                  <span [ngClass]="searchform.value.keyword_effectivity? 'text-white':''">Effectivity</span>
                </button>
                <select type="select" [disabled]="regulationsLoading" class="form-select col m-1" #select_effectivity (change)="universalChange('effectivity_status',select_effectivity.value)">
                  <option selected readonly value="">Select Effectivity</option>
                  <ng-container *ngFor="let item of universalOption('effectivity_status')">
                    <option [selected]="universalSelector('effectivity_status',item)" [value]="item">{{item}}</option>
                  </ng-container>
                </select>
                <button class="btn btn-light col m-1" [disabled]="regulationsLoading" type="button" [ngClass]="searchform.value.keyword_div? 'active':''" (click)="divDetailsView()">
                  <span [ngClass]="searchform.value.keyword_div? 'text-white':''">Division</span>
                </button>
                <button class="btn btn-light col m-1" [disabled]="regulationsLoading" type="button" [ngClass]="searchform.value.keyword_case? 'active':''" (click)="caseDetailsView()">
                  <span [ngClass]="searchform.value.keyword_case? 'text-white':''">Case Use</span>
                </button>
                <button class="btn btn-light col m-1" [disabled]="regulationsLoading" type="button" [ngClass]="searchform.value.keyword_agency? 'active':''" (click)="agencyDetailsView()">
                  <span [ngClass]="searchform.value.keyword_agency? 'text-white':''">Agency</span>
                </button>
              </div> -->

              <!-- <div class="row">
                <button class="btn btn-ligth col m-1" type="button" [ngClass]="onClassView? 'active':''" (click)="classDetailsView()">
                  <span [ngClass]="onClassView? 'text-white':''">Classification</span>
                </button>
                <button class="btn btn-ligth col m-1" type="button" [ngClass]="onSectorView? 'active':''" (click)="sectorDetailsView()">
                  <span [ngClass]="onSectorView? 'text-white':''">Sector</span>
                </button>
                <button class="btn btn-ligth col m-1" type="button" [ngClass]="onSobView? 'active':''" (click)="sobDetailsView()">
                  <span [ngClass]="onSobView? 'text-white':''">Stage of Business</span>
                </button>
                <button class="btn btn-ligth col m-1" type="button" [ngClass]="onJurisView? 'active':''" (click)="jurisDetailsView()">
                  <span [ngClass]="onJurisView? 'text-white':''">Jurisdiction</span>
                </button>
              </div>

              <div class="row">
                  <button class="btn btn-ligth col m-1" type="button" [ngClass]="onEffectivityView? 'active':''" (click)="effectivityDetailsView()">
                    <span [ngClass]="onEffectivityView? 'text-white':''">Effectivity</span>
                  </button>
                  <button class="btn btn-ligth col m-1" type="button" [ngClass]="onDivView? 'active':''" (click)="divDetailsView()">
                    <span [ngClass]="onDivView? 'text-white':''">Division</span>
                  </button>
                  <button class="btn btn-ligth col m-1" type="button" [ngClass]="onCaseView? 'active':''" (click)="caseDetailsView()">
                    <span [ngClass]="onCaseView? 'text-white':''">Case Use</span>
                  </button>
                  <button class="btn btn-ligth col m-1" type="button" [ngClass]="onAgencyView? 'active':''" (click)="agencyDetailsView()">
                    <span [ngClass]="onAgencyView? 'text-white':''">Agency</span>
                  </button>
              </div> -->

              <!-- <div *ngIf="onClassView">
                class
              </div>
              
              <div *ngIf="onSectorView">
                sector
              </div>
              
              <div *ngIf="onSobView">
                sob
              </div>
              
              <div *ngIf="onJurisView">
                juris
              </div> -->
              
            </div>
            
          <!-- <div class="d-flex justify-content-center mt-3 mb-5">
            <div class="row form-group">
              <div class="col-12">
                <button type="submit" [disabled]="regulationsLoading" class="btn btn-primary btn-rounded mx-1 my-1" (click)="search('existing')">
                  <p class="m-0">Search<i class="fas fa-search ms-2"></i></p>
                </button>
                <button type="reset" class="btn btn-dark btn-rounded mx-1 my-1" aria-label="Reset" routerLink="/pbris/regulations/existing">
                  <p class="m-0">Reset</p>
                </button>
              </div>
            </div>
            <div class="row form-group m-1">
              <button
                (click)="quick_search()"
                class="btn btn-lg btn-outline-dark fw-bold border-0">
                Quick Search?
              </button>
            </div>
            <div class="row form-group m-1">
              <button (click)="advanced_search()"
                class="btn btn-lg btn-outline-dark fw-bold border-0">
                Advanced Search?
              </button>
            </div>
          </div> -->
        </div>
      </form>
    </div>

  </div>

  <a href="#export" class="fw-bold text-dark float-end mx-4 my-2" hidden>Export in XML, JSON...</a>

  <!-- Regulation Results -->
  <!-- <app-quick-links></app-quick-links> -->

  <div class="mb-5">
    <div class="col">
      <div class="row border-top border-primary border-3 py-4 px-2 mb-5" style="background: rgba(239, 239, 239, .5);">
        <div class="col-12">
          <h4 class="text-primary fw-bold m-1">Regulation Results
            <span class="text-danger fw-bold mt-2 mx-3">{{ regulations.length }}</span>
          </h4>
        </div>
      </div>
      <div *ngIf="regulationsLoading && currentRegulationInPercent > 0" class="d-flex justify-content-center align-self-center relative">
        <span class="absolute percentLoader">{{currentRegulationInPercent}}%</span>
        <mat-spinner [diameter]="50" mode="determinate" [value]="currentRegulationInPercent"></mat-spinner>
      </div>
      
      <div class="col-xs-auto col-md-12 my-3">
        <!-- [hidden]="!regulationsLoading" -->
        <!-- loading -->
        <!-- <div class="d-flex justify-content-center align-self-center">
          <div class="spinner-grow text-info"
            role="status"
            [hidden]="!regulationsLoading">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div> -->

        <!-- content -->
          <table id="regulationsTable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-hover table-responsive">
          <!-- [hidden]="regulationsLoading" -->
            <thead hidden>
              <tr>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <!-- Table Item -->
              <tr *ngFor="let entry of regulations" class="regulation-row">
                <td [title]="entry.regulations_from" (click)="viewRegulation(entry.id, entry.regulations_from)">
                  <div class="d-none d-sm-block">
                    <ng-container class="d-flex align-items-center" *ngIf="entry.regulations_from == 'regulations' || entry.regulations_from == 'existing'">
                      <img src="/assets/Assets/Icons/Existing%20Regulation.png" class="reg-icon" alt="Existing Regulation"/>
                    </ng-container>
                    <!-- <ng-container *ngIf="entry.regulations_from == 'proposed'">
                      <img src="/assets/Assets/Icons/Proposed%20Regulation.png" class="reg-icon" alt="Proposed Regulation"/>
                    </ng-container> -->
                  </div>
                </td>
                <td style="width: 100%;" class="py-4" (click)="viewRegulation(entry.id, entry.regulations_from)">
                  <h6 class="fw-bold mb-3" [ngClass]="(entry.short_title || entry.title) ? '' : 'red-text'" style="text-align:justify;">
                    {{ entry.short_title ? entry.short_title : entry.title ? entry.title : 'No Title' }}
                  </h6>
                  <div class="d-flex flex-row">
                    <div class="d-flex flex-column">
                      <p *ngIf="entry.displayDate" class="me-2 mb-0"><span class="fw-bold">Date Issued:</span> {{ entry.displayDate }}</p>
                      <p *ngIf="entry.effectiveDate" class="me-2 mb-0"><span class="fw-bold">Date Effectivity:</span> {{ entry.effectiveDate }}</p>
                      <p *ngIf="entry.amendDate" class="me-2 mb-0"><span class="fw-bold">Date Amended/Repealed:</span> {{ entry.amendDate }}</p>
                    </div>
                    <div class="d-flex flex-column">
                      <p *ngIf="entry.sector" class="mx-2 mb-0"><span class="fw-bold">Sector:</span> {{ entry.sector }}</p>
                      <p *ngIf="entry.division" class="mx-2 mb-0"><span class="fw-bold">Division:</span> {{ entry.division }}</p>
                      <p *ngIf="entry.sob" class="mx-2 mb-0"><span class="fw-bold">Stage of Business:</span> {{ entry.sob }}</p>
                      <p *ngIf="entry.case" class="mx-2 mb-0"><span class="fw-bold">Case Use:</span> {{ entry.case }}</p>
                      <p *ngIf="entry.sol" class="mx-2 mb-0"><span class="fw-bold">Stage of Life:</span> {{ entry.sol }}</p>
                      <p *ngIf="entry.le" class="mx-2 mb-0"><span class="fw-bold">Life Event:</span> {{ entry.le }}</p>
                    </div>
                  </div>
                </td>

                <td>
                  <!-- <span *ngIf="entry.status" (click)="onPinned(entry)" style="color: red;" class="fs-1">
                    <i class="fas fa-bookmark"></i>
                  </span>
                  <span *ngIf="entry.status === false" (click)="onPinned(entry)" class="fs-1">
                    <i class="far fa-bookmark"></i>
                  </span> -->
                </td>
              </tr>
            </tbody>
          </table>
        
      </div>
    </div>
  </div>
</div>
