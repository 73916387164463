import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, ElementRef, Inject, OnInit, QueryList, ViewChildren, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ClientStepNode } from './client-step';
import { v4 as uuidv4 } from 'uuid';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { AngularFireStorage } from '@angular/fire/storage';
import { atLeastOne } from 'src/app/validators';
import { MatRow, MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import Swal from 'sweetalert2';

enum EntityBuilderIndex { 
  CLIENT_STEP_INFO = 1,
  AGENCY_ACTION = 2,
  REQUIREMENT = 3,
  EODB_TAG = 4,
  AGENCY_ACTION_PERSON_RESPONSIBLE = 5,
  CLIENT_STEP_STANDARD_FEE = 6,
  CLIENT_STEP_LIST_OF_POSSIBLE_FEES_OR_FORMULAS = 7,
  REQUIREMENT_TYPE_DOCUMENT = 8,
  REQUIREMENT_TYPE_DOCUMENT_ORIGINAL = 9,
  REQUIREMENT_TYPE_DOCUMENT_PHOTO = 10,
  REQUIREMENT_TYPE_DOCUMENT_ELECTRONIC = 11,
  REQUIREMENT_TYPE_OPERATOR = 12,
  REQUIREMENT_TYPE_OPERATOR_AND = 13,
  REQUIREMENT_TYPE_OPERATOR_OR = 14,
  REQUIREMENT_TYPE_PARENTHESIS = 15,
  REQUIREMENT_TYPE_PARENTHESIS_OPEN = 16,
  REQUIREMENT_TYPE_PARENTHESIS_CLOSE = 17,
}

@Component({
  selector: 'app-artemis-new-charter-client-service-modal',
  templateUrl: './artemis-new-charter-client-service-modal.component.html',
  styleUrls: ['./artemis-new-charter-client-service-modal.component.scss']
})
export class ArtemisNewCharterClientServiceModalComponent implements OnInit {

  @ViewChildren(MatRow, {read: ElementRef}) rows!: QueryList<ElementRef<HTMLTableRowElement>>;

  isChecked: boolean = false;
  activeTabIndex: number = 0;
  readonly clientStepTabIndex = 2;
  validityMap: any = {};

  // step 3 form
  service_name_and_details_form: FormGroup;
  // standard requirement in step 3 form
  progress: number = 0;

  standard_requirement_form: FormArray;
  standard_requirement_form_datasource: MatTableDataSource<AbstractControl>;
  // standardRequirementFormDisplayedColumns: string[] = [
  //   'requirement',
  //   'requirement_type',
  //   // 'original_copy',
  //   // 'photo_copy',
  //   // 'electronic_copy',
  //   'copy_format',
  //   'number',
  //   'unit',
  //   'is_gov_id',
  //   'agency',
  //   'bureau_division',
  //   'remarks_or_reminders',
  //   'delete',
  // ];  
  document_type_options = ['Original Copy', 'Photo Copy', 'Electronic Copy', 'Certified True Copy'];
  standardRequirementFormDisplayedColumns: string[] = [
    'content',
  ];  

  operatingHours: any[] = [    
    {value: '7 - 4', viewValue: '7:00 - 4:00'},
    {value: '7:30 - 4:30', viewValue: '7:30 - 4:30'},
    {value: '8 - 5', viewValue: '8:00 - 5:00'},
    {value: '8:30 - 5:30', viewValue: '8:30 - 5:30'},
    {value: '9 - 6', viewValue: '9:00 - 6:00'},
    {value: '24/7', viewValue: '24/7'},
    {value: 'Others', viewValue: 'Others'},
  ]  

  selected_requirement_tab: number = 0;
  situational_requirement_array = new FormArray([]);
  situational_requirement_form_datasource: MatTableDataSource<AbstractControl>;

  numberOfCopiesWO0 = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
  numberOfCopiesW0 = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
  type_of_requirement = ['Documentary', 'Sample','Id']

  copy_format_logic_options = ['And', 'Or'];

  // step 4 form
  client_steps_sequence_number = 0;
  client_steps_agency_action_sequence_number = 0;
  current_client_step_number = 0;
  client_steps_form: Map<string, any>;
  activeClientStep: any = null;
  activeClientStepUuid: string = "";
  activeAgencyAction: any = null;
  activeAgencyActionUuid: string = "";
  parentOfActiveAgencyActionUuid: string = "";

  // step 7 form
  eodb_tags_form: FormArray;
  eodb_tags_form_datasource: MatTableDataSource<AbstractControl>;
  eodbTagsFormDisplayedColumns: string[] = [
    'classification',
    'sector',
    'division',
    'stage_of_business',
    'case_use',
    'jurisdiction',
    'delete',
  ];

  valueSuggestions: any = {};

  treeControl = new NestedTreeControl<ClientStepNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<ClientStepNode>();
  hasChild = (_: number, node: ClientStepNode) => !!node.children && node.children.length > 0;

  // serviceGlobalPaymentForm = new FormGroup({
  //   servicePaymentOption: new FormControl(false)
  // })

  private maxDaysMap: any = {
    "Simple": 3,
    "Complex": 7,
    "Highly Technical": null,
    // By law, Highly Technical services should not exceed 20 days, but ARTA has requested not to impose a hard limit yet
    "By Law Highly Technical": 20,
  }
  
  exceedsMaxDays: boolean = false;

  schedule_of_fees_filepath: any = null;
  previewImgScheduleOfFees: any = null;
  public showScheduleOfFeesFileUploadErrors: boolean = false;

  checkRequirementForm = {
    requirement:[
      { type: "required", message: "Requirement title is required" },
      { type: "duplicate", message: "Duplicated requirement title" },
    ],
    // originalCopy:[
    //   { type: "required", message: "Original Copy is required" },
    // ],
    // photoCopy:[
    //   { type: "required", message: "Photo Copy is required" },
    // ],
    // electronicCopy:[
    //   { type: "required", message: "Electronic Copy is required" },
    // ],
    number:[
      { type: "required", message: "Number is required" },
    ],
    unit:[
      { type: "required", message: "Unit is required" },
    ],
    agency:[
      { type: "required", message: "Agency is required" },
    ],
    bureau_division:[
      { type: "required", message: "Bureau/Division is required" },
    ],
    requirementArray:[
      { type: "hasDuplicateRequirement", message: "Requirments has a duplicated requirement title" },
    ],
    copyFormat:[
      { type: "format", message: "Documentary Copies format invalid" },
      { type: "minlength", message: "Documentary Copies format is required" },
      { type: "required", message: "Documentary Copies format is required" },
    ],
    listOfIds:[
      { type: "required", message: "List of ID's is required" },
    ],
    remarks_or_reminders:[
      { type: "required", message: "Remarks is required" },
    ]
  }

  // hasSituationalStep:boolean = false;
  // hasStandardFee:boolean = false;
  // has

  doAutocompute:boolean = true;
  totalFeesReadOnly:boolean = true;
  situationalFeesReadOnly:boolean = false;
  hideFeesUpload:boolean = true;

  isSituationalStepReadOnly: boolean = false;
  
  constructor(
    public dialogRef: MatDialogRef<ArtemisNewCharterClientServiceModalComponent>,
    private decimalPipe: DecimalPipe,
    private currPipe: CurrencyPipe,
    private store: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private changeDetectorRef: ChangeDetectorRef
    ) {
    // console.log('data from edit',data)
    this.client_steps_sequence_number = 0;
    this.client_steps_agency_action_sequence_number = 0;
    this.current_client_step_number = 0;

    if(data.validityMap) {
      this.validityMap = data.validityMap;
    }

    // Initialize forms
    this.service_name_and_details_form = new FormGroup({});
    this.client_steps_form = new Map();

    if (data.serviceForm) {
      // Initialize and fill up if editing a service
      //console.log('for edit serviceForm: ',data.serviceForm)
      this.fillUpServiceModal(data.serviceForm);
    }
    else {
      // Initial empty form
      // Service details form
      this.service_name_and_details_form = new FormGroup({
        service_name: new FormControl('', Validators.required),
        service_category: new FormControl('', Validators.required),
        service_description: new FormControl('', Validators.required),
        classification: new FormControl('', Validators.required),
        type_of_transaction: new FormGroup(
          {
            type_g2c: new FormControl(false),
            type_g2g: new FormControl(false),
            type_g2b: new FormControl(false),
          },
          { validators: [atLeastOne(Validators.requiredTrue)] }
        ),
        agency: new FormControl({value: (sessionStorage.getItem("agency_name") || ''), disabled: true}, Validators.required),
        office_division: new FormControl('', Validators.required),
        process_owner: new FormControl('', Validators.required),
        who_may_avail: new FormControl('', Validators.required),
        operating_hours: new FormControl('', Validators.required),
        has_statute: new FormControl(false),
        statute: new FormControl({value: '', disabled: true}),
        total_processing_time: new FormControl('', Validators.required),
        total_fees: new FormControl(''),
        schedule_of_fees_file_name: new FormControl(''),
      });

      this.initFormListeners();

      // Standard requirement form
      this.standard_requirement_form = new FormArray([this.buildNewEntry(EntityBuilderIndex.REQUIREMENT)],[this.checkRequirementDuplicate()]);
      this.standard_requirement_form_datasource = new MatTableDataSource(this.standard_requirement_form.controls);

      const initialClientStepUuid = uuidv4();
      const initialAgencyActionUuid = uuidv4();

      // Initial Client Steps selection tree
      this.dataSource.data = [
        {
          name: 'New Client Step',
          type: 'client_step',
          uuid: initialClientStepUuid,
          children: [
            {
              name: 'New Agency Action',
              type: 'agency_action',
              uuid: initialAgencyActionUuid,
              parentUuid: initialClientStepUuid,
            }
          ]
        }
      ];

      // Initial client step form
      let newStepInfo = this.buildNewEntry(EntityBuilderIndex.CLIENT_STEP_INFO);

      // Initial agency actions
      let newClientStep = {
        sequence_number: (this.client_steps_sequence_number + 1),
        info: newStepInfo,
        agency_actions: new Map()
      }

      let newAgencyAction = this.buildNewEntry(EntityBuilderIndex.AGENCY_ACTION);
      newClientStep.agency_actions.set(initialAgencyActionUuid, newAgencyAction);

      this.client_steps_form.set(
        initialClientStepUuid,
        newClientStep
      );

      // EODB Tags
      this.eodb_tags_form = new FormArray([]);
      this.eodb_tags_form_datasource = new MatTableDataSource(this.eodb_tags_form.controls);

      this.serviceOptionsControl()
    }
    this.valueSuggestions = data.valueSuggestions;
  }

  ngOnInit(): void {
  }

  fillUpServiceModal(serviceForm: any) {
    // console.log('serviceForm',serviceForm)
    this.standard_requirement_form = new FormArray([],[this.checkRequirementDuplicate()]);
    this.situational_requirement_array = new FormArray([]);
    this.eodb_tags_form = new FormArray([]);
    // Load service details
    if (serviceForm.serviceDetails) {
      const serviceDetails = serviceForm.serviceDetails;
      this.service_name_and_details_form = new FormGroup({
        service_name: new FormControl(serviceDetails.service_name, Validators.required),
        service_category: new FormControl(serviceDetails.service_category, Validators.required),
        service_description: new FormControl(serviceDetails.service_description, Validators.required),
        classification: new FormControl(serviceDetails.classification, Validators.required),
        type_of_transaction: new FormGroup(
          {
            type_g2c: new FormControl(serviceDetails.type_of_transaction?.type_g2c),
            type_g2g: new FormControl(serviceDetails.type_of_transaction?.type_g2g),
            type_g2b: new FormControl(serviceDetails.type_of_transaction?.type_g2b),
          },
          { validators: [atLeastOne(Validators.requiredTrue)] }
        ),
        agency: new FormControl({value: serviceDetails.agency, disabled: true}, Validators.required),
        office_division: new FormControl(serviceDetails.office_division, Validators.required),
        process_owner: new FormControl(serviceDetails.process_owner, Validators.required),
        who_may_avail: new FormControl(serviceDetails.who_may_avail, Validators.required),
        operating_hours: new FormControl(serviceDetails.operating_hours, Validators.required),
        has_statute: new FormControl(serviceDetails.has_statute),
        statute: new FormControl({value: serviceDetails.statute, disabled: !serviceDetails.has_statute}),
        total_processing_time: new FormControl(serviceDetails.total_processing_time, Validators.required),
        total_fees: new FormControl(
          typeof serviceDetails.total_fees == 'string'
            ? serviceDetails.total_fees.replace(/(?![\d\.])./g, '')
            : serviceDetails.total_fees
        ),
        schedule_of_fees_file_name: new FormControl(serviceDetails.schedule_of_fees_file_name),
      });

      // Load image/PDF
      if(serviceDetails.firebase_folder && serviceDetails.schedule_of_fees_file_name) {
        const destinationRef = this.store.ref(`${serviceDetails.firebase_folder}/${serviceDetails.schedule_of_fees_file_name}`);
        destinationRef
        .getDownloadURL()
        .toPromise()
        .then((url) => {
          var xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            var blob = xhr.response;
            
            this.schedule_of_fees_filepath = new File(
              [blob],
              serviceDetails.schedule_of_fees_file_name,
              { type: blob.type }
            );

            this.showScheduleOfFeesImagePreview();
          };
          xhr.open('GET', url);
          xhr.send();
        });
      }

      this.initFormListeners();
      this.service_name_and_details_form.markAllAsTouched();
    }

    // Load standard requirements
    if (serviceForm.standardRequirements) {
      for (let standardRequirement of serviceForm.standardRequirements) {
        switch(standardRequirement.requirementType){
          case 'Documentary':{
            let copyFormat = new FormArray([],[this.checkDocumentFormat(),Validators.required])
            for(let var1 of standardRequirement.documentFormat){
              switch(var1.type){
                case 'operator': copyFormat.push(this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR,var1));break;
                case 'parenthesis': copyFormat.push(this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_PARENTHESIS,var1));break;
                case 'document': 
                default: copyFormat.push(this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT,var1));break;
              }
            }
            let newStandardRequirement = new FormGroup({
              requirement: new FormControl(standardRequirement.requirement?standardRequirement.requirement:'', [Validators.required]),
              requirementType: new FormControl(standardRequirement.requirementType?standardRequirement.requirementType:'Documentary'),
              copyFormatDisabled: new FormControl(false),
              copyFormat: copyFormat,
              number: new FormControl({value:'',disabled:true}),
              unit: new FormControl({value:'',disabled:true}),
              listOfIds: new FormControl({value:'',disabled:true}),
              agency: new FormControl(standardRequirement.agency?standardRequirement.agency:''),//,[Validators.required]
              bureau_division: new FormControl(standardRequirement.bureau_division?standardRequirement.bureau_division:''),//,[Validators.required]
              remarks_or_reminders: new FormControl(standardRequirement.remarks_or_reminders?standardRequirement.remarks_or_reminders:''),
            });
            this.standard_requirement_form.push(newStandardRequirement);
            break;
          }
          case 'Sample':{
            let newStandardRequirement = new FormGroup({
              requirement: new FormControl(standardRequirement.requirement?standardRequirement.requirement:'', [Validators.required]),
              requirementType: new FormControl('Sample'),
              copyFormat: new FormArray([
                this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ORIGINAL),
                this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_AND),
                this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_PHOTO),
                this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_AND),
                this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ELECTRONIC),
              ]),
              copyFormatDisabled: new FormControl(true),
              number: new FormControl(standardRequirement.number?standardRequirement.number:'', [Validators.required]),
              unit: new FormControl(standardRequirement.unit?standardRequirement.unit:'', Validators.required),
              agency: new FormControl(standardRequirement.agency?standardRequirement.agency:''),//, Validators.required
              bureau_division: new FormControl(standardRequirement.bureau_division?standardRequirement.bureau_division:''),//,[Validators.required]
              listOfIds: new FormControl({value:'',disabled:true}),
              remarks_or_reminders: new FormControl(standardRequirement.remarks_or_reminders?standardRequirement.remarks_or_reminders:''),
            });
            this.standard_requirement_form.push(newStandardRequirement);
            break;
          }
          case 'Id':{
            let newStandardRequirement = new FormGroup({
              requirement: new FormControl(standardRequirement.requirement?standardRequirement.requirement:'', [Validators.required]),
              requirementType: new FormControl('Id'),
              copyFormat: new FormArray([
                this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ORIGINAL),
                this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_AND),
                this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_PHOTO),
                this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_AND),
                this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ELECTRONIC),
              ]),
              copyFormatDisabled: new FormControl(true),
              number: new FormControl({value:'',disabled:true}),
              unit: new FormControl({value:'',disabled:true}),
              listOfIds: new FormControl(standardRequirement.listOfIds?standardRequirement.listOfIds:'',[Validators.required]),
              agency: new FormControl({value:'',disabled:true}),
              bureau_division: new FormControl({value:'',disabled:true}),
              remarks_or_reminders: new FormControl(standardRequirement.remarks_or_reminders?standardRequirement.remarks_or_reminders:''),
            });
            this.standard_requirement_form.push(newStandardRequirement);
            break;
          }
        }
      }
      
      this.standard_requirement_form_datasource = new MatTableDataSource(this.standard_requirement_form.controls);
      this.standard_requirement_form.markAllAsTouched();
    }

    // load situational steps
    if(serviceForm.situationalRequirementsArray){
      for (let situationalRequirementForm of serviceForm.situationalRequirementsArray){
        let situational_requirement_form = new FormArray([],[this.checkRequirementDuplicate()]);
        // console.log('situationalRequirementForm',situationalRequirementForm)
        for(let situationalRequirement of situationalRequirementForm.situationalRequirements){
          switch(situationalRequirement.requirementType){
            case 'Documentary':{
              let copyFormat = new FormArray([],[this.checkDocumentFormat(),Validators.required])
              for(let var1 of situationalRequirement.documentFormat){
                switch(var1.type){
                  case 'operator': copyFormat.push(this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR,var1));break;
                  case 'parenthesis': copyFormat.push(this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_PARENTHESIS,var1));break;
                  case 'document': 
                  default: copyFormat.push(this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT,var1));break;
                }
              }
              let newSituationalRequirement = new FormGroup({
                requirement: new FormControl(situationalRequirement.requirement?situationalRequirement.requirement:'', [Validators.required]),
                requirementType: new FormControl(situationalRequirement.requirementType?situationalRequirement.requirementType:'Documentary'),
                copyFormatDisabled: new FormControl(false),
                copyFormat: copyFormat,
                number: new FormControl({value:'',disabled:true}),
                unit: new FormControl({value:'',disabled:true}),
                listOfIds: new FormControl({value:'',disabled:true}),
                agency: new FormControl(situationalRequirement.agency?situationalRequirement.agency:''),//,[Validators.required]
                bureau_division: new FormControl(situationalRequirement.bureau_division?situationalRequirement.bureau_division:''),//,[Validators.required]
                remarks_or_reminders: new FormControl(situationalRequirement.remarks_or_reminders?situationalRequirement.remarks_or_reminders:''),
              });
              situational_requirement_form.push(newSituationalRequirement);
              break;
            }
            case 'Sample':{
              let newSituationalRequirement = new FormGroup({
                requirement: new FormControl(situationalRequirement.requirement?situationalRequirement.requirement:'', [Validators.required]),
                requirementType: new FormControl('Sample'),
                copyFormat: new FormArray([
                  this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ORIGINAL),
                  this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_AND),
                  this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_PHOTO),
                  this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_AND),
                  this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ELECTRONIC),
                ]),
                copyFormatDisabled: new FormControl(true),
                number: new FormControl(situationalRequirement.number?situationalRequirement.number:'', [Validators.required]),
                unit: new FormControl(situationalRequirement.unit?situationalRequirement.unit:'', Validators.required),
                agency: new FormControl(situationalRequirement.agency?situationalRequirement.agency:''),//, Validators.required
                bureau_division: new FormControl(situationalRequirement.bureau_division?situationalRequirement.bureau_division:''),//,[Validators.required]
                listOfIds: new FormControl({value:'',disabled:true}),
                remarks_or_reminders: new FormControl(situationalRequirement.remarks_or_reminders?situationalRequirement.remarks_or_reminders:''),
              });
              situational_requirement_form.push(newSituationalRequirement);
              break;
            }
            case 'Id':{
              let newSituationalRequirement = new FormGroup({
                requirement: new FormControl(situationalRequirement.requirement?situationalRequirement.requirement:'', [Validators.required]),
                requirementType: new FormControl('Id'),
                copyFormat: new FormArray([
                  this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ORIGINAL),
                  this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_AND),
                  this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_PHOTO),
                  this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_AND),
                  this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ELECTRONIC),
                ]),
                copyFormatDisabled: new FormControl(true),
                number: new FormControl({value:'',disabled:true}),
                unit: new FormControl({value:'',disabled:true}),
                listOfIds: new FormControl(situationalRequirement.listOfIds?situationalRequirement.listOfIds:'',[Validators.required]),
                agency: new FormControl({value:'',disabled:true}),
                bureau_division: new FormControl({value:'',disabled:true}),
                remarks_or_reminders: new FormControl(situationalRequirement.remarks_or_reminders?situationalRequirement.remarks_or_reminders:''),
              });
              situational_requirement_form.push(newSituationalRequirement);
              break;
            }
          }
        }
        let newForm:any =  new FormGroup({
          situational_requirement_form: situational_requirement_form,
          title: new FormControl(situationalRequirementForm.title?situationalRequirementForm.title:'New Situational Requirements'),
        })

        this.situational_requirement_array.push(newForm);
      }
      this.situational_requirement_form_datasource = new MatTableDataSource(this.situational_requirement_array.controls);
      this.situational_requirement_array.markAllAsTouched();
    }

    // Load client steps
    if (serviceForm.clientSteps) {
      let newData: ClientStepNode[] = [];

      for (let clientStep of serviceForm.clientSteps) {
        const initialClientStepUuid = uuidv4();

        // Client step agency action
        let newClientStepDataSourceChildren: any[] = [];
        let newClientStepDataSource = {
          name: clientStep.info.title,
          type: 'client_step',
          uuid: initialClientStepUuid,
          children: newClientStepDataSourceChildren
        }

        let standard_fees_arr = new FormArray([]);
        if(clientStep.info.fees_arr) {
          for (let item of clientStep.info.fees_arr) {
            standard_fees_arr.push(this.buildNewEntry(EntityBuilderIndex.CLIENT_STEP_STANDARD_FEE, item));
          } 
        }

        let list_of_fees_formulas_arr = new FormArray([]);
        if(clientStep.info.list_of_fees_arr) {
          for (let item of clientStep.info.list_of_fees_arr) {
            list_of_fees_formulas_arr.push(this.buildNewEntry(EntityBuilderIndex.CLIENT_STEP_LIST_OF_POSSIBLE_FEES_OR_FORMULAS, item));
          }          
        }

        // console.log('fill clientStep: ',clientStep.info)
        // Client step form
        let newStepInfo = new FormGroup({
          client_step_payment_option: new FormControl(clientStep.info.client_step_payment_option == true ? true : false),
          client_step_sequence_number: new FormControl(clientStep.info.client_step_sequence_number, Validators.required),
          title: new FormControl(clientStep.info.title, Validators.required),
          location: new FormControl(clientStep.info.location, Validators.required),
          notes: new FormControl(clientStep.info.notes),
          fees: new FormControl(
            typeof clientStep.info.fees == 'string'
              ? clientStep.info.fees.replace(/(?![\d\.])./g, '')
              : clientStep.info.fees,
            Validators.required
          ),
          list_of_fees_val: new FormControl(clientStep.info.list_of_fees_val),
          is_situational_step: new FormControl(clientStep.info.is_situational_step == true? true : false, Validators.required),
          situation: new FormControl(clientStep.info.situation),
          standard_fees: new FormControl(clientStep.info.standard_fees),
          list_of_possible_fees_or_formulas: new FormControl(clientStep.info.list_of_possible_fees_or_formulas || false),

          fees_arr: standard_fees_arr,
          list_of_fees_arr: list_of_fees_formulas_arr,
        });

        // this.isSituationalStepReadOnly = !(clientStep.info.is_situational_step == true? true : false);

        let newClientStep = {
          sequence_number: clientStep.info.client_step_sequence_number,
          info: newStepInfo,
          agency_actions: new Map()
        };

        for (let agencyAction of clientStep.agency_actions) {
          const initialAgencyActionUuid = uuidv4();

          // Agency action data source
          const newAgencyActionDataSource = {
            name: agencyAction.title,
            type: 'agency_action',
            uuid: initialAgencyActionUuid,
            parentUuid: initialClientStepUuid,
          }
          // Push each agency action to children array of parent client step first
          newClientStepDataSource.children.push(newAgencyActionDataSource);

          // Load persons responsible as FormArray
          let persons_responsible = new FormArray([]);
          if(agencyAction.persons_responsible) {
            for (let item of agencyAction.persons_responsible) {
              persons_responsible.push(this.buildNewEntry(EntityBuilderIndex.AGENCY_ACTION_PERSON_RESPONSIBLE, item));
            }
          }
          else {
            // Legacy feature for old person responsible input
            let item = {
              designation: agencyAction.designation,
              division_group: agencyAction.division_group,
            };
            persons_responsible.push(this.buildNewEntry(EntityBuilderIndex.AGENCY_ACTION_PERSON_RESPONSIBLE, item));
          }

          // TODO: function to handle FormControl-to-FormArray legacy form controls

          let newAgencyAction = new FormGroup({
            agency_action_sequence_number: new FormControl(agencyAction.agency_action_sequence_number, Validators.required),
            title: new FormControl(agencyAction.title, Validators.required),
            persons_responsible: persons_responsible,
            processing_time_days: new FormControl(agencyAction.processing_time_days, Validators.required),
            processing_time_minutes: new FormControl(agencyAction.processing_time_minutes, Validators.required),
            processing_time_hours: new FormControl(agencyAction.processing_time_hours, Validators.required),
          });

          // Integer pipe
          newAgencyAction.get('processing_time_days')?.valueChanges.subscribe((value: any) => {
            newAgencyAction.patchValue(
              this.formatProcessingTime(value, 'days'),
              { emitEvent: false }
            );

            this.printTotalProcessingTime();
          });

          newAgencyAction.get('processing_time_hours')?.valueChanges.subscribe((value: any) => {
            newAgencyAction.patchValue(
              this.formatProcessingTime(value, 'hours'),
              { emitEvent: false }
            );

            this.printTotalProcessingTime();
          });

          newAgencyAction.get('processing_time_minutes')?.valueChanges.subscribe((value: any) => {
            newAgencyAction.patchValue(
              this.formatProcessingTime(value, 'minutes'),
              { emitEvent: false }
            );

            this.printTotalProcessingTime();
          });

          // Agency action form
          newClientStep.agency_actions.set(initialAgencyActionUuid, newAgencyAction);
          newAgencyAction.markAllAsTouched();
        }

        // Push to form
        this.client_steps_form.set(
          initialClientStepUuid,
          newClientStep
        ); 
        newStepInfo.markAllAsTouched();

        // Finally, push client step to newData which will be the final dataSource.data
        newData.push(newClientStepDataSource);
      }

      // Initial Client Steps selection tree (loaded)
      this.dataSource.data = newData;
    }

    // Load EODB Tags
    if (serviceForm.eodbTags) {

      for (let eodbTag of serviceForm.eodbTags) {
        this.eodb_tags_form.push(
          new FormGroup({
            classification: new FormControl(eodbTag.classification, Validators.required),
            sector: new FormControl(eodbTag.sector, Validators.required),
            division: new FormControl(eodbTag.division, Validators.required),
            stage_of_business: new FormControl(eodbTag.stage_of_business, Validators.required),
            case_use: new FormControl(eodbTag.case_use, Validators.required),
            jurisdiction: new FormControl(eodbTag.jurisdiction, Validators.required),
          })
        );
      }

      this.eodb_tags_form_datasource = new MatTableDataSource(this.eodb_tags_form.controls);
      this.eodb_tags_form.markAllAsTouched();
    }
    
    this.serviceOptionsControl()
  }

  initFormListeners() {
    if(this.service_name_and_details_form) {
      // Processing time limits based on service classification
      this.service_name_and_details_form.get('classification')?.valueChanges.subscribe((value: any) => {
        // this.maxDaysByClassification does not work because the change has not registered yet
        if (!this.service_name_and_details_form.get('has_statute')?.value && this.maxDaysMap[value]) {
          let totalProcessingTimeMinutes = this.computeTotalProcessingTime();

          this.exceedsMaxDays = (totalProcessingTimeMinutes > this.maxDaysMap[value] * 8 * 60);
        }
        else {
          this.exceedsMaxDays = false;
        }
      });

      // On/off for Statute, no need for anything elegant...yet
      this.service_name_and_details_form.get('has_statute')?.valueChanges.subscribe((value: any) => {
        if(value) {
          this.service_name_and_details_form.get('statute')?.enable();
          this.service_name_and_details_form.get('statute')?.setValidators(Validators.required);

          // Processing time has no limits if service has statute
          this.exceedsMaxDays = false;
        }
        else {
          this.service_name_and_details_form.get('statute')?.clearValidators();
          this.service_name_and_details_form.get('statute')?.disable();

          // If service has no statute, 
          let classification = this.service_name_and_details_form.get('classification')?.value;
          if(this.maxDaysMap[classification]) {
            let totalProcessingTimeMinutes = this.computeTotalProcessingTime();

            this.exceedsMaxDays = (totalProcessingTimeMinutes > this.maxDaysMap[value] * 8 * 60);
          }
          else {
            this.exceedsMaxDays = false;
          }
        }
      });
    }
  }

  getOthers(selected:any){
    if(selected === 'Others'){
      this.service_name_and_details_form.addControl('operating_hours_other', new FormControl ('',{validators: [Validators.required]}))
    }else{
      this.service_name_and_details_form.removeControl('operating_hours_other')
    }
  }

  getClientStepInfo(node: ClientStepNode) {
    const uuid = node.uuid;
    this.activeAgencyAction = null;
    this.activeAgencyActionUuid = "";
    this.activeClientStep = this.client_steps_form.get(uuid);
    this.activeClientStepUuid = uuid;

    // Automatically expand node upon adding new agency action
    this.treeControl.expand(node);
  }

  getAgencyActionInfo(clientStepUuid: string, uuid: string) {
    this.activeClientStep = null;
    this.activeClientStepUuid = "";
    this.parentOfActiveAgencyActionUuid = clientStepUuid;
    const parentClientStep = this.client_steps_form.get(clientStepUuid);
    if (parentClientStep) {
      this.activeAgencyAction = parentClientStep.agency_actions.get(uuid);
      this.activeAgencyActionUuid = uuid;
    }
  }
  
 
  get active_persons_responsible() {
    return this.activeAgencyAction
      ?.get('persons_responsible') as FormArray;
  }

  get active_persons_responsible_arr() {
    return this.activeAgencyAction
      ?.get('persons_responsible').controls as FormGroup[];
  }

  addPersonResponsible() {
    this.active_persons_responsible.push(
      this.buildNewEntry(EntityBuilderIndex.AGENCY_ACTION_PERSON_RESPONSIBLE)
    );
  }

  removePersonResponsible(index: any) {
    this.active_persons_responsible.removeAt(index);
  }

  get active_standard_fees() {
    return this.activeClientStep?.info
      ?.get('fees_arr') as FormArray;
  }

  get active_standard_fees_arr() {
    return this.activeClientStep?.info
      ?.get('fees_arr').controls as FormGroup[];
  }

  addStandardFee() {
    this.active_standard_fees.push(
      this.buildNewEntry(EntityBuilderIndex.CLIENT_STEP_STANDARD_FEE)
    );

    if(this.active_standard_fees_arr.length > 0) {
      this.activeClientStep?.info.controls.standard_fees?.disable();
    }
  }

  removeStandardFee(index: any) {
    this.active_standard_fees.removeAt(index);

    if(this.active_standard_fees_arr.length == 0) {
      this.activeClientStep?.info.controls.standard_fees?.enable();
      this.activeClientStep?.info.patchValue({standard_fees: false});
    }
  }

  get active_list_of_fees_formulas() {
    return this.activeClientStep?.info
      ?.get('list_of_fees_arr') as FormArray;
  }

  get active_list_of_fees_formulas_arr() {
    return this.activeClientStep?.info
      ?.get('list_of_fees_arr').controls as FormGroup[];
  }

  addListOfFeesFormulas() {
    this.active_list_of_fees_formulas.push(
      this.buildNewEntry(EntityBuilderIndex.CLIENT_STEP_LIST_OF_POSSIBLE_FEES_OR_FORMULAS)
    );

    if(this.active_list_of_fees_formulas_arr.length > 0) {
      this.activeClientStep?.info.controls.list_of_possible_fees_or_formulas?.disable();
    }
  }

  removeListOfFeesFormulas(index: any) {
    this.active_list_of_fees_formulas.removeAt(index);

    if(this.active_list_of_fees_formulas_arr.length == 0) {
      this.activeClientStep?.info.controls.list_of_possible_fees_or_formulas?.enable();
      this.activeClientStep?.info.patchValue({list_of_possible_fees_or_formulas: false});
    }
  }
  
  tabChanged(event: MatTabChangeEvent) {
    this.activeTabIndex = event.index;
    // console.log(this.activeTabIndex);
  }

  setTab(index: number) {
    this.activeTabIndex = index;
  }

  get activeClientStepName() {
    if(this.activeTabIndex == this.clientStepTabIndex) {
      if(this.activeClientStep) {
        return this.activeClientStep?.info.get('title')?.value;
      }
      else if(this.activeAgencyAction && this.parentOfActiveAgencyActionUuid) {
        return this.client_steps_form.get(this.parentOfActiveAgencyActionUuid)?.info.get('title')?.value;
      }
      else {
        return '';
      }
    }
    else {
      return '';
    }
  }
  
  addNewClientStep() {
    this.client_steps_sequence_number++;
    this.current_client_step_number = this.client_steps_sequence_number;

    const newClientStepUuid = uuidv4();
    const newAgencyActionUuid = uuidv4();

    let newData = this.dataSource.data;
    let newNode = {
      name: 'New Client Step',
      type: 'client_step',
      uuid: newClientStepUuid,
      children: [
        {
          name: 'New Agency Action',
          type: 'agency_action',
          uuid: newAgencyActionUuid,
          parentUuid: newClientStepUuid,
        }
      ]
    };
    newData.push(newNode);

    this.refreshNodeTree();

    let newStepInfo = this.buildNewEntry(EntityBuilderIndex.CLIENT_STEP_INFO);

    let newClientStep = {
      sequence_number: (this.client_steps_sequence_number + 1),
      info: newStepInfo,
      agency_actions: new Map()
    }

    let newAgencyAction = this.buildNewEntry(EntityBuilderIndex.AGENCY_ACTION);
    newClientStep.agency_actions.set(newAgencyActionUuid, newAgencyAction);

    this.client_steps_form.set(
      newClientStepUuid,
      newClientStep
    );

    this.getClientStepInfo(newNode);
  }

  addNewAgencyAction(node: ClientStepNode) {
    const newAgencyActionUuid = uuidv4();
    const clientStepUuid = node.parentUuid;

    this.dataSource.data.forEach(e => {
      if (e && e.uuid === clientStepUuid) {
        const parentClientStep: ClientStepNode = e;

        parentClientStep.children?.push(
          {
            name: 'New Agency Action',
            type: 'agency_action',
            uuid: newAgencyActionUuid,
            parentUuid: clientStepUuid,
          }
        )

        this.refreshNodeTree();

        const parentClientStepForm = this.client_steps_form.get(clientStepUuid);

        let newAgencyAction = this.buildNewEntry(EntityBuilderIndex.AGENCY_ACTION);
        parentClientStepForm.agency_actions.set(newAgencyActionUuid, newAgencyAction);

        // Select the parent client step to force it to expand, it will be deselected by getAgencyActionInfo
        this.getClientStepInfo(parentClientStep);
        this.getAgencyActionInfo(clientStepUuid, newAgencyActionUuid);

        return;
      }
    });

    // Automatically expand node upon adding new agency action
    this.treeControl.expand(node);
  }

  removeClientStep(uuid: string) {
    this.activeClientStep = null;
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].uuid === uuid) {
        this.dataSource.data.splice(i, 1);
        this.refreshNodeTree();
      }
    }
    this.client_steps_form.delete(uuid);
    this.refreshSequenceNumbers();
    this.serviceOptionsControl()

    // Any "total value" fields can be affected by removing client steps; make recomputation calls here
    this.printTotalProcessingTime();
    // TODO: Recomputation of total fees
  }

  refreshSequenceNumbers() {
    this.client_steps_sequence_number = 0;
    for (var getRow of this.dataSource.data) {
      let editClientStepForm = this.client_steps_form.get(getRow.uuid);
      editClientStepForm.sequence_number = (this.client_steps_sequence_number + 1);
      editClientStepForm.info.patchValue({
        client_step_sequence_number: (this.client_steps_sequence_number + 1).toString()
      });
      this.client_steps_sequence_number++;
    }
  }

  removeAgencyAction(clientStepUuid: string, uuid: string) {
    this.activeAgencyAction = null;
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].uuid === clientStepUuid) {

        var agencyActions = this.dataSource.data[i].children || [];

        if (agencyActions.length < 2) {
          // Remove parent client step if only agency action to be removed is the only/last one
          this.dataSource.data.splice(i, 1);
          this.client_steps_form.delete(clientStepUuid);
        }
        else {
          // Else, standard agency action removal
          for (var j = 0; j < agencyActions.length; j++) {
            if (agencyActions[j].uuid === uuid) {
              agencyActions.splice(j, 1)

            }
          }

          if (this.client_steps_form.get(clientStepUuid)) {
            this.client_steps_form.get(clientStepUuid).agency_actions.delete(uuid);
          }
        }
        this.refreshNodeTree();

        // Any "total value" fields can be affected by removing agency actions; make recomputation calls here
        this.printTotalProcessingTime();
      }
    }
  }

  private refreshNodeTree() {
    let newData = this.dataSource.data;
    // Workaround for updating nested tree nodes, do not remove
    this.dataSource.data = [];
    this.dataSource.data = newData;
  }

  updateTreeClientStepName(event: any) {
    if (this.activeClientStep && this.activeClientStepUuid) {
      this.dataSource.data.forEach(e => {
        if (e && e.uuid === this.activeClientStepUuid) {
          const parentRegulationSection: ClientStepNode = e;
          parentRegulationSection.name = event.target.value;
          return;
        }
      });
    }
  }

  updateTreeAgencyActionName(event: any) {
    if (this.activeAgencyAction && this.activeAgencyActionUuid) {
      this.dataSource.data.forEach(e => {
        if (e && e.uuid === this.parentOfActiveAgencyActionUuid) {
          const parentRegulationSection: ClientStepNode = e;
          parentRegulationSection.children?.forEach(f => {
            if (f && f.uuid === this.activeAgencyActionUuid) {
              const subsection: ClientStepNode = f;
              subsection.name = event.target.value;
              return;
            }
          })
          return;
        }
      });
    }
  }

  addNewEodbTag() {
    this.eodb_tags_form.push(this.buildNewEntry(EntityBuilderIndex.EODB_TAG));
    this.eodb_tags_form_datasource = new MatTableDataSource(this.eodb_tags_form.controls);
  }

  removeEodbTag(index: any) {
    this.eodb_tags_form.removeAt(index);
    this.eodb_tags_form_datasource = new MatTableDataSource(this.eodb_tags_form.controls);
  }

  save() {
    // console.log('standard_requirement_form',this.standard_requirement_form.value)
    // console.log('situational_requirement_array',this.situational_requirement_array.value)
    var standardRequirements:any = [];
    var situationalRequirementsArray:any = []
    var eodbTags = [];
    // Must be an array
    var clientSteps: Array<any> = [];

    for (var x of this.standard_requirement_form.controls) {
      let val = x.value
      // if (val.requirementType == 'Documentary'){
      //   let documentFormat:any = []
      //   val.copyFormat.forEach((item:any,index:number)=>{
      //     if(item.type == 'document'){
      //       documentFormat.push({
      //         value:item.val,
      //         sequence:index+1,
      //         type:item.type,
      //         copyCount:item.copyCount?item.copyCount:1
      //       })
      //     }else{
      //       documentFormat.push({
      //         value:item.val,
      //         sequence:index+1,
      //         type:item.type,
      //       })
      //     }
      //   })
      //   let newVal:any = {
      //     documentFormat: documentFormat,
      //     bureau_division: val.bureau_division,
      //     remarks_or_reminders: val.remarks_or_reminders,
      //     // isGovId: val.isGovId,
      //     listOfIds:val.listOfIds,
      //     // originalCopy: val.originalCopy,
      //     // photoCopy: val.photoCopy,
      //     // electronicCopy: val.electronicCopy,
      //     requirement: val.requirement,
      //     requirementType: val.requirementType,
      //     agency: val.agency,
      //   }
      //   standardRequirements.push(newVal);
      // }
      // else if (val.requirementType == 'Sample'){
      //   let newVal:any = {
      //     bureau_division: val.bureau_division,
      //     number: val.number,
      //     remarks_or_reminders: val.remarks_or_reminders,
      //     requirement: val.requirement,
      //     requirementType: val.requirementType,
      //     unit: val.unit,
      //     agency: val.agency,
      //   }
      //   standardRequirements.push(newVal);
      // }

      switch(val.requirementType){
        case 'Documentary':{
          let documentFormat:any = []
          val.copyFormat.forEach((item:any,index:number)=>{
            switch(item.type){
              case 'document':{
                documentFormat.push({
                  value:item.val,
                  sequence:index+1,
                  type:item.type,
                  copyCount:item.copyCount?item.copyCount:1
                })
                break;
              }
              default:{
                documentFormat.push({
                  value:item.val,
                  sequence:index+1,
                  type:item.type,
                })
                break;
              }
            }
          })
          let newVal:any = {
            documentFormat: documentFormat,
            bureau_division: val.bureau_division,
            remarks_or_reminders: val.remarks_or_reminders,
            requirement: val.requirement,
            requirementType: val.requirementType,
            agency: val.agency,
          }
          standardRequirements.push(newVal);
          break;
        }
        case 'Sample':{
          let newVal:any = {
            bureau_division: val.bureau_division,
            number: val.number,
            remarks_or_reminders: val.remarks_or_reminders,
            requirement: val.requirement,
            requirementType: val.requirementType,
            unit: val.unit,
            agency: val.agency,
          }
          standardRequirements.push(newVal);
          break;
        }
        case 'Id':{
          let newVal:any = {
            remarks_or_reminders: val.remarks_or_reminders,
            requirement: val.requirement,
            requirementType: val.requirementType,
            listOfIds: val.listOfIds
          }
          standardRequirements.push(newVal);
          break;
        }
      }
      // standardRequirements.push(x.value);
    }

    //console.log('standardRequirements: ',standardRequirements)

    for(var form of (this.situational_requirement_array.controls as FormGroup[])){
      // console.log('control',form)
      let val1 = form.value
      let newForm:any = {
        situationalRequirements: [],
        title: val1.title
      }

      let val2 = form.controls;
      for(var x of ((val2.situational_requirement_form as FormArray).controls)){
        let val = x.value

        switch(val.requirementType){
          case 'Documentary':{
            let documentFormat:any = []
            val.copyFormat.forEach((item:any,index:number)=>{
              switch(item.type){
                case 'document':{
                  documentFormat.push({
                    value:item.val,
                    sequence:index+1,
                    type:item.type,
                    copyCount:item.copyCount?item.copyCount:1
                  })
                  break;
                }
                default:{
                  documentFormat.push({
                    value:item.val,
                    sequence:index+1,
                    type:item.type,
                  })
                  break;
                }
              }
            })
            let newVal:any = {
              documentFormat: documentFormat,
              bureau_division: val.bureau_division,
              remarks_or_reminders: val.remarks_or_reminders,
              requirement: val.requirement,
              requirementType: val.requirementType,
              agency: val.agency,
            }
            newForm.situationalRequirements.push(newVal);
            break;
          }
          case 'Sample':{
            let newVal:any = {
              bureau_division: val.bureau_division,
              number: val.number,
              remarks_or_reminders: val.remarks_or_reminders,
              requirement: val.requirement,
              requirementType: val.requirementType,
              unit: val.unit,
              agency: val.agency,
            }
            newForm.situationalRequirements.push(newVal);
            break;
          }
          case 'Id':{
            let newVal:any = {
              remarks_or_reminders: val.remarks_or_reminders,
              requirement: val.requirement,
              requirementType: val.requirementType,
              listOfIds: val.listOfIds
            }
            newForm.situationalRequirements.push(newVal);
            break;
          }
        }
      }
      
      situationalRequirementsArray.push(newForm)
    }

    //console.log('situationalRequirementsArray: ',situationalRequirementsArray)

    for (var y of this.eodb_tags_form.controls) {
      eodbTags.push(y.value);
    }

    /* 
      Because order matters for client steps and agency actions,
      client step data and agency action data must be stored in Firestore as an ordered array.
      Currently no other use for the UUIDs generated
    */
    this.client_steps_form.forEach((v: any) => {
      // Do not return the FormGroup objects associated with client step and agency actions
      // Only return the actual FormGroup value

      let saveInfo = v.info.value;
      // Gotcha: disabled form controls will not be part of form.value, similar to disabled input tags in a form tag
      // You need to get values from a disabled form control through controls['controlname'].value
      saveInfo.standard_fees = v.info.controls.standard_fees.value;
      saveInfo.list_of_possible_fees_or_formulas = v.info.controls.list_of_possible_fees_or_formulas.value;

      let returnClientStep =
      {
        info: saveInfo,
        // Must be an array
        agency_actions: [] as Array<any>
      };

      // Must be an array
      let returnAgencyActions: Array<any> = [];

      v.agency_actions.forEach((v: FormGroup) => {
        returnAgencyActions.push(v.value);
      })

      returnClientStep.agency_actions = returnAgencyActions;
      clientSteps.push(returnClientStep);
    })

    // Include this.schedule_of_fees_filepath in serviceDetails
    // Only one filepath per service
    if(this.schedule_of_fees_filepath) {
      this.service_name_and_details_form.addControl('schedule_of_fees_filepath', new FormControl(this.schedule_of_fees_filepath));
    }

    let entireServiceIsValid = false;
    let serviceFormIsValid = this.service_name_and_details_form.valid;
    let requirementsIsValid = this.standard_requirement_form.valid && this.situational_requirement_array.valid;
    let clientStepsIsValid = !this.checkClientStepsFormIsInvalid() && !this.exceedsMaxDays;
    let eodbTagsIsValid = this.eodb_tags_form.valid;
    // let serviceGlobalPaymentValue = this.serviceGlobalPaymentForm.value

    entireServiceIsValid = serviceFormIsValid
      && requirementsIsValid
      && clientStepsIsValid
      && eodbTagsIsValid;

    let saveServiceDetails = this.service_name_and_details_form.value;
    
    // Add a flag for Highly Technical services which exceed 20 days (by law, but in practice in existing CCs)
    if(this.service_name_and_details_form.value.classification === "Highly Technical") {
      let totalProcessingTimeMinutes = this.computeTotalProcessingTime();

      saveServiceDetails.highly_technical_past_max_err = (totalProcessingTimeMinutes > this.maxDaysMap["By Law Highly Technical"] * 8 * 60);
    }
    
    // Gotcha: disabled form controls will not be part of form.value, similar to disabled input tags in a form tag
    // You need to get values from a disabled form control through controls['controlname'].value
    saveServiceDetails.agency = this.service_name_and_details_form.controls.agency.value

    // console.log('clientSteps: ',clientSteps)
    let returnServiceEntity = {
      // servicePaymentOption: serviceGlobalPaymentValue.servicePaymentOption,
      serviceDetails: saveServiceDetails,
      standardRequirements: standardRequirements,
      situationalRequirementsArray: situationalRequirementsArray,
      clientSteps: clientSteps,
      eodbTags: eodbTags,
      validityMap : {
        serviceForm: serviceFormIsValid,
        requirements: requirementsIsValid,
        clientSteps: clientStepsIsValid,
        eodbTags: eodbTagsIsValid,
        overall: entireServiceIsValid
      }
    };

    //console.log('service valid: ',returnServiceEntity)

    this.dialogRef.close(
      returnServiceEntity
    );
  }

  cancel() {
    this.dialogRef.close();
  }
  
  get maxDaysByClassification() {
    return this.maxDaysMap[this.service_name_and_details_form.value.classification];
  } 


  // client step functions
  serviceOptionsControl(){
    // let servicePaymentOption = this.serviceGlobalPaymentForm.value.servicePaymentOption
    // let hasNoClientStepPayment = this.checkClientStepMap('client_step_payment_option');
    let hasSituationalStep = this.checkClientStepMap('hasSituationalStep'); //this.checkForServiceOptions('hasSituationalStep'); 
    // let hasSituationalStep = !this.isSituationalStepReadOnly
    let isStandardFee = this.checkClientStepMap('isStandardFee'); //this.checkForServiceOptions('isStandardFee');  
    let isPossibleFee = this.checkClientStepMap('isPossibleFee'); //this.checkForServiceOptions('isPossibleFee');
 
    // totalFees is now a string to allow appending of list of possible fees
    let totalFees:any = '';
    let standardFees:number = 0;
    let scheduleFeesStr = '';
    // console.log('servicePaymentOption',servicePaymentOption)
    // if(!servicePaymentOption){
    // if(!hasNoClientStepPayment){
      // set total fee input readonly
      // console.log('hasSituationalStep',hasSituationalStep)
      // console.log('isStandardFee',isStandardFee)
      // console.log('isPossibleFee',isPossibleFee)

      // enable checkboxes`
      // this.optionCheckboxes(true)

      this.situationalFeesReadOnly = false

      // doAutocompute is gone because autocompute should always happen now
      // totalFeesReadOnly to be removed because schedule of fees upload is to be removed as well
    
      // TODO: Always autocompute now
      for(let [key, value] of this.client_steps_form){
        let feeByClientStep = 0;
        if(value && value.info){
          let formGroup = value.info as FormGroup
          // console.log(key,(formGroup.controls["fees"] as FormControl).value)
          if((formGroup.controls["standard_fees"] as FormControl).value == true && formGroup.controls["fees_arr"]) {
            let fees_arr = formGroup.controls["fees_arr"] as FormArray;
            // https://stackoverflow.com/questions/44898010/form-control-valuechanges-gives-the-previous-value
            for(var x of fees_arr.controls) {
              if(x.get('amount')?.value) {
                standardFees += x.get('amount')?.value;
                feeByClientStep += x.get('amount')?.value;
              }
            }
          }

          if((formGroup.controls["list_of_possible_fees_or_formulas"] as FormControl).value == true && formGroup.controls["list_of_fees_arr"]) {
            let fees_arr = formGroup.controls["list_of_fees_arr"] as FormArray;
            for(var x of fees_arr.controls) {
              if(x.get('fee_type')?.value && x.get('list_fee_desc')?.value) {
                // list_fee_desc is an HTML string, so put each fee type in a <p> tag
                scheduleFeesStr = scheduleFeesStr + `<p>${x.get('fee_type')?.value || 'N/A'}:</p>${x.get('list_fee_desc')?.value}`;
              }
            }
          }

          // Set fees by client step
          formGroup.patchValue({
            fees: feeByClientStep
          })
        }
      }

      const standardFeesStr = this.currPipe.transform(standardFees, "PHP", "₱");
      totalFees = '' + standardFeesStr + ' plus: ' + scheduleFeesStr;
      this.service_name_and_details_form.patchValue({
        total_fees:totalFees
      })

      // show upload
      if(isPossibleFee){
        this.hideFeesUpload = false
      }else  this.hideFeesUpload = true
    // }
    // else{
    //   this.service_name_and_details_form.patchValue({total_fees:totalFees})
    //   this.totalFeesReadOnly = true
    //   this.situationalFeesReadOnly = true
    //   this.hideFeesUpload = true
    //   // disable other checkboxes
    //   // this.optionCheckboxes(false)
    // }
    // console.log('totalFeesReadOnly',this.totalFeesReadOnly)
    // console.log('situationalFeesReadOnly',this.situationalFeesReadOnly)
    // console.log('hideFeesUpload',this.hideFeesUpload)
  }

  checkAddNewFee(key: string) {
    switch(key) {
      case 'standard-fees':
        if(this.active_standard_fees_arr.length == 0) {
          this.addStandardFee();
        }
        break;
      case 'list-of-possible-fees':
        if(this.active_list_of_fees_formulas_arr.length == 0) {
          this.addListOfFeesFormulas();
        }
        break;
      default:
        break;
    }
  }

  // optionCheckboxes(control:any){
  //   if(this.client_steps_form.size > 0){
  //     for(let [key, value] of this.client_steps_form){
  //       if(control){
  //         ((value.info as FormGroup).controls['is_situational_step']).enable({onlySelf:true});
  //         // this.isSituationalStepReadOnly = false;
  //         ((value.info as FormGroup).controls['list_of_possible_fees_or_formulas']).enable({onlySelf:true});
  //         ((value.info as FormGroup).controls['standard_fees']).enable({onlySelf:true});
  //       }
  //       else{
  //         ((value.info as FormGroup).patchValue({
  //           is_situational_step:false,
  //           situation:''
  //         }));
  //         ((value.info as FormGroup).controls['is_situational_step']).disable({onlySelf:true});
  //         // this.isSituationalStepReadOnly = true;
  //         ((value.info as FormGroup).controls['list_of_possible_fees_or_formulas']).disable({onlySelf:true});
  //         ((value.info as FormGroup).controls['standard_fees']).disable({onlySelf:true});
  //       }
  //     }
  //   }
  // }

  clientStepPaymentOptionChange(){
    if (this.activeClientStep && this.activeClientStepUuid){
      let activeClientStepFormGroup: FormGroup =  this.client_steps_form.get(this.activeClientStepUuid).info;
      const activeClientStepVal:any = activeClientStepFormGroup.value

      // if client_step_payment_option is true reset situational step checkbox and situation input and hide them
      if(activeClientStepVal.client_step_payment_option == true){

        Swal.fire({
          title: 'All your encoded fees will be removed!',
          text: "Are you sure?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then((result) => {
          if (result.isConfirmed) {
            activeClientStepFormGroup.patchValue({
              is_situational_step: false,
              situation: '',
              standard_fees: false,
              fees: 0,
              list_of_possible_fees_or_formulas: false,
              list_of_fees_val: '',              
            })
            this.active_standard_fees.clear();
            this.active_list_of_fees_formulas.clear();

            activeClientStepFormGroup.updateValueAndValidity()
            this.serviceOptionsControl()
          }
          else {
            activeClientStepFormGroup.patchValue({
              client_step_payment_option: false
            })            
          }
        })
        
      }
      else {
        activeClientStepFormGroup.updateValueAndValidity()
        this.serviceOptionsControl()
      }

      // console.log('activeClientStepFormGroup: ',activeClientStepFormGroup.value)      
    }
  }

  checkClientStepMap(control?:any){
    if(this.client_steps_form.size > 0){
      // if(control == 'clientStepOptions' && !this.hideFeesUpload) return true
      for(let [key, value] of this.client_steps_form){
        if(value && value.info){
          let formGroup = value.info as FormGroup
          // console.log('formGroup',formGroup)

          // if(control == 'client_step_payment_option'){
          //   if((formGroup.controls["client_step_payment_option"] as FormControl).value == true) return true;
          // }
          if(control == 'hasSituationalStep'){
            if((formGroup.controls["is_situational_step"] as FormControl).value == true) return true;
          }
          else if(control == 'isPossibleFee'){
            if((formGroup.controls["list_of_possible_fees_or_formulas"] as FormControl).value == true) return true;
          }
          else if(control == 'isStandardFee'){
            if((formGroup.controls["standard_fees"] as FormControl).value == true) return true;
          }
        }
      }
      return false;
    }

    return false
  }

  servicePaymentOptionChange(){
    // this.removeUploadedFee()
    this.serviceOptionsControl()
  }

  // Returns an object to feed into patchValue
  formatProcessingTime(timeVal: string, timeInputType: string) {
    // Allow leading zeroes in processing time
    const retVal = this.decimalPipe.transform(
      timeVal.replace(/\D/g, '').replace(/^0+(?=\d+)/, ''),
      '1.0-2'
    );

    const timeValNum = retVal ? +retVal : null;
    switch (timeInputType) {
      case 'days':
        return {
          processing_time_days: retVal || '0',
        }
      case 'hours':
        if (timeValNum && timeValNum > 7) {
          return {
            processing_time_hours: '7',
          };
        }
        else {
          return {
            processing_time_hours: retVal || '0',
          };
        }
      case 'minutes':
        if (timeValNum && timeValNum > 59) {
          return {
            processing_time_minutes: '59',
          };
        }
        else {
          return {
            processing_time_minutes: retVal || '0',
          };
        }
      default:
        return {};
    }
  }

  private computeTotalProcessingTime() {
    let totalProcessingTimeMinutes = 0;

    this.client_steps_form.forEach((v: any) => {
      v.agency_actions.forEach((v: FormGroup) => {
        // Remove currencyPipe-formatted commas because day input can have no limits
        if(!isNaN(v.value.processing_time_days.replace(/\D/g, ''))){
          // 8 working hours/day rather than the usual 24 hours/day
          totalProcessingTimeMinutes += +v.value.processing_time_days.replace(/\D/g, '') * 8 * 60;
        }

        if(!isNaN(v.value.processing_time_hours)){
          totalProcessingTimeMinutes += +v.value.processing_time_hours * 60;
        }

        if(!isNaN(v.value.processing_time_minutes)){
          totalProcessingTimeMinutes += +v.value.processing_time_minutes;
        }
      })
    });

    return totalProcessingTimeMinutes;
  }

  private printTotalProcessingTime() {
    let totalProcessingTimeMinutes = this.computeTotalProcessingTime();

    // Split totalProcessingTimeMinutes into days/hours/minutes
    const getHoursFromMin = Math.floor(totalProcessingTimeMinutes/60);
    const getRemainingMinutes = totalProcessingTimeMinutes % 60;
    const getRemainingHours = getHoursFromMin % 8;
    const getDays = Math.floor(getHoursFromMin/8);
    
    const minutesStr = getRemainingMinutes > 0 ? `${this.decimalPipe.transform(getRemainingMinutes,'1.0-2')} minute/s` : ``;
    const hoursStr = getRemainingHours > 0 ? `${this.decimalPipe.transform(getRemainingHours,'1.0-2')} hour/s` : ``;
    const daysStr = getDays > 0 ? `${this.decimalPipe.transform(getDays,'1.0-2')} day/s` : ``;
    
    this.service_name_and_details_form.patchValue({
      total_processing_time: `${daysStr}${daysStr && hoursStr ? ", " : ""}${hoursStr}${(daysStr || hoursStr) && minutesStr ? ", " : ""}${minutesStr}`
    });

    let classification = this.service_name_and_details_form.get('classification')?.value;
    if(this.maxDaysMap[classification]) {
      let totalProcessingTimeMinutes = this.computeTotalProcessingTime();

      this.exceedsMaxDays = (totalProcessingTimeMinutes > this.maxDaysMap[classification] * 8 * 60);
    }
    else {
      this.exceedsMaxDays = false;
    }          
  }
  // !client step functions


  // Partial existing_obj handling for Agency Action's persons_responsible FormArray property, but should refactored to fully match the style used in PBRIS modules
  private buildNewEntry(entry_id: number, existing_obj?: any): FormGroup {
    switch (entry_id) {
      case EntityBuilderIndex.CLIENT_STEP_INFO:
        let newStepInfo = new FormGroup({
          client_step_payment_option: new FormControl(false),
          client_step_sequence_number: new FormControl((this.client_steps_sequence_number + 1).toString(), Validators.required),
          title: new FormControl('New Client Step', Validators.required),
          location: new FormControl('', Validators.required),
          notes: new FormControl(''),
          fees: new FormControl(0, Validators.required),
          list_of_fees_val: new FormControl(''),
          is_situational_step: new FormControl(false, Validators.required),
          situation: new FormControl(''),
          standard_fees: new FormControl(false),
          list_of_possible_fees_or_formulas: new FormControl(false),
          fees_arr: new FormArray([]),
          list_of_fees_arr: new FormArray([]),
        });

        // this.isSituationalStepReadOnly = false;
        
        return newStepInfo;
      case EntityBuilderIndex.AGENCY_ACTION:
        let newAgencyAction = new FormGroup({
          agency_action_sequence_number: new FormControl((this.client_steps_agency_action_sequence_number + 1).toString(), Validators.required),
          title: new FormControl('New Agency Action', Validators.required),
          persons_responsible: new FormArray([
            this.buildNewEntry(EntityBuilderIndex.AGENCY_ACTION_PERSON_RESPONSIBLE)
          ]),
          processing_time_days: new FormControl('0', Validators.required),
          processing_time_hours: new FormControl('0', Validators.required),
          processing_time_minutes: new FormControl('0', Validators.required),
        });

        // Integer pipe
        newAgencyAction.get('processing_time_days')?.valueChanges.subscribe((value: any) => {
          newAgencyAction.patchValue(
            this.formatProcessingTime(value, 'days'),
            { emitEvent: false }
          );

          this.printTotalProcessingTime();
        });

        newAgencyAction.get('processing_time_hours')?.valueChanges.subscribe((value: any) => {
          newAgencyAction.patchValue(
            this.formatProcessingTime(value, 'hours'),
            { emitEvent: false }
          );

          this.printTotalProcessingTime();
        });

        newAgencyAction.get('processing_time_minutes')?.valueChanges.subscribe((value: any) => {
          newAgencyAction.patchValue(
            this.formatProcessingTime(value, 'minutes'),
            { emitEvent: false }
          );

          this.printTotalProcessingTime();
        });
        return newAgencyAction;
      case EntityBuilderIndex.REQUIREMENT:
        let newStandardRequirement = new FormGroup({
          requirement: new FormControl('', [Validators.required]),
          requirementType: new FormControl('Sample'),
          copyFormat: new FormArray([
            this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ORIGINAL,existing_obj),
            this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_AND,existing_obj),
            this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_PHOTO,existing_obj),
            this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_AND,existing_obj),
            this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ELECTRONIC,existing_obj),
          ]),
          listOfIds: new FormControl({value:'',disabled:true}),
          copyFormatDisabled: new FormControl(true),
          number: new FormControl('', Validators.required),
          unit: new FormControl('', Validators.required),
          // isGovId: new FormControl({value:false,disabled:true}), // disables bureau_division if true
          agency: new FormControl(''),//,[Validators.required]
          bureau_division: new FormControl(''),//,[Validators.required]
          remarks_or_reminders: new FormControl(''),//,[Validators.required]
        });
        return newStandardRequirement;
      case EntityBuilderIndex.EODB_TAG:
        let newEodbTag = new FormGroup({
          classification: new FormControl('', Validators.required),
          sector: new FormControl('', Validators.required),
          division: new FormControl('', Validators.required),
          stage_of_business: new FormControl('', Validators.required),
          case_use: new FormControl('', Validators.required),
          jurisdiction: new FormControl('', Validators.required),
        });
        return newEodbTag;
      case EntityBuilderIndex.AGENCY_ACTION_PERSON_RESPONSIBLE:
        let newPersonResponsible = new FormGroup({
          designation: new FormControl(existing_obj?.designation || '', Validators.required),
          division_group: new FormControl(existing_obj?.division_group || '', Validators.required),
        });
        return newPersonResponsible;
      case EntityBuilderIndex.CLIENT_STEP_STANDARD_FEE:
        let newStandardFee = new FormGroup({
          fee_type: new FormControl(existing_obj?.fee_type || '', Validators.required),
          amount: new FormControl(existing_obj?.amount || 0, Validators.required),
        });

        newStandardFee.get('amount')?.valueChanges.subscribe(() => {
          this.serviceOptionsControl();
        });
        return newStandardFee;
      case EntityBuilderIndex.CLIENT_STEP_LIST_OF_POSSIBLE_FEES_OR_FORMULAS:
        let newListOfFeeFormula = new FormGroup({
          fee_type: new FormControl(existing_obj?.fee_type || '', Validators.required),
          list_fee_desc: new FormControl(existing_obj?.list_fee_desc || '', Validators.required),
        });

        newListOfFeeFormula.get('list_fee_desc')?.valueChanges.subscribe(() => {
          this.serviceOptionsControl();
        });
        return newListOfFeeFormula;
      case EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT:{
        return new FormGroup({
          val: new FormControl({value:existing_obj.val || existing_obj.value || 'Original copy',disabled:false}),
          disabled:new FormControl(false),
          type:new FormControl('document'),
          copyCount: new FormControl({value: existing_obj.copyCount || 1,disabled:false}),
        })
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ORIGINAL:{
        return new FormGroup({
          val: new FormControl({value:'Original Copy',disabled:existing_obj?false:true}),
          disabled:new FormControl(existing_obj?false:true),
          type:new FormControl('document'),
          copyCount: new FormControl({value:1,disabled:existing_obj?false:true}),
        })
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_PHOTO:{
        return new FormGroup({
          val: new FormControl({value:'Photo Copy',disabled:existing_obj?false:true}),
          disabled:new FormControl(existing_obj?false:true),
          type:new FormControl('document'),
          copyCount: new FormControl({value:1,disabled:existing_obj?false:true}),
        })
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ELECTRONIC:{
        return new FormGroup({
          val: new FormControl({value:'Electronic Copy',disabled:existing_obj?false:true}),
          disabled:new FormControl(existing_obj?false:true),
          type:new FormControl('document'),
          copyCount: new FormControl({value:1,disabled:existing_obj?false:true}),
        })
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR:{
        return new FormGroup({val: new FormControl({value: existing_obj.val || existing_obj.value || 'And',disabled:false}), disabled:new FormControl(false), type:new FormControl('operator')})
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_AND:{
        return new FormGroup({val: new FormControl({value:'And',disabled:existing_obj?false:true}), disabled:new FormControl(existing_obj?false:true), type:new FormControl('operator')})
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_OR:{
        return new FormGroup({val: new FormControl({value:'Or',disabled:existing_obj?false:true}), disabled:new FormControl(existing_obj?false:true), type:new FormControl('operator')})
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_PARENTHESIS:{
        return new FormGroup({val: new FormControl({value: existing_obj.val || existing_obj.value || '\(',disabled:false}), disabled:new FormControl(false), type:new FormControl('parenthesis')})
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_PARENTHESIS_OPEN:{
        return new FormGroup({val: new FormControl({value:'\(',disabled: existing_obj?false:true}), disabled:new FormControl(existing_obj?false:true), type:new FormControl('parenthesis')})
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_PARENTHESIS_CLOSE:{
        return new FormGroup({val: new FormControl({value:'\)',disabled: existing_obj?false:true}), disabled:new FormControl(existing_obj?false:true), type:new FormControl('parenthesis')})
      }
      default:
        return new FormGroup({});
    }
  }


  // file upload
  saveFileInformation(event: any) {

    var allowedExtensions = /(\.jpg|\.jpeg|\.gif|\.png|\.pdf)$/i;

    if (!allowedExtensions.exec(event.target.files[0].name)) {
      this.schedule_of_fees_filepath = null;
      this.service_name_and_details_form
            .get('schedule_of_fees_file_name')
            ?.reset();
      this.showScheduleOfFeesFileUploadErrors = true;
      
    } else {
      this.showScheduleOfFeesFileUploadErrors = false;

      this.schedule_of_fees_filepath = event.target.files[0];
      this.service_name_and_details_form.patchValue({
        schedule_of_fees_file_name: event.target.files[0].name,
        
      });

      this.showScheduleOfFeesImagePreview();
    }
  }

  showScheduleOfFeesImagePreview() {
    var allowedImageExtensions = /(\.jpg|\.jpeg|\.gif|\.png)$/i;
    this.progress = 0;
    const fileReader = new FileReader();    
    fileReader.readAsDataURL(this.schedule_of_fees_filepath);
    
    fileReader.onprogress=(data) => {
      if (data.lengthComputable) {
        this.progress = ((data.loaded / data.total) * 100);
      }
    };

    if (this.schedule_of_fees_filepath && allowedImageExtensions.exec(this.schedule_of_fees_filepath.name)) {
     
      // console.log('fileReader: ',fileReader)
      fileReader.onload = () => {
        this.previewImgScheduleOfFees = fileReader.result as string;
      }
    }
    else {
      this.previewImgScheduleOfFees = null;
    }
  }

  removeUploadedFee(){
    this.service_name_and_details_form.get('schedule_of_fees_file_name')?.reset();
    this.previewImgScheduleOfFees = null
    this.schedule_of_fees_filepath = null
    this.progress = 0;
    // this.serviceOptionsControl()
  }
  // !file upload

  // requirements tab row functions
  valueChanged(controlName:any,rowCtr:number){
    switch(controlName){
      case 'requirementType':{
        let formGroup = ((this.standard_requirement_form.controls as FormGroup[])[rowCtr] as FormGroup);
        const requirementType = formGroup.controls[controlName].value;
        switch(requirementType){
          case 'Documentary':{
            // default: requirement title, requirement type,
            // require:  documentary copies
            // optional: agency, div
            // disable: number, unit, list of ids
            if((formGroup.value['copyFormatDisabled'] == true)) formGroup = this.valueChangedFormArraySetValidators(formGroup,'copyFormat')

            if((formGroup.controls['agency'] as FormControl).disabled) formGroup = this.valueChangedFormControlSetValidators(formGroup,'agency')
            if((formGroup.controls['bureau_division'] as FormControl).disabled) formGroup = this.valueChangedFormControlSetValidators(formGroup,'bureau_division')
            
            if(!(formGroup.controls['unit'] as FormControl).disabled) formGroup = this.valueChangedFormControlRemoveValidators(formGroup,'unit')
            if(!(formGroup.controls['number'] as FormControl).disabled) formGroup = this.valueChangedFormControlRemoveValidators(formGroup,'number')

            if(!(formGroup.controls['listOfIds'] as FormControl).disabled) formGroup = this.valueChangedFormControlRemoveValidators(formGroup,'listOfIds')
            break;
          }
          case 'Sample':{
            // default: requirement title, requirement type,
            // require: number, unit
            // optional: agency, div
            // disable: documentary copies, list of ids

            if((formGroup.controls['unit'] as FormControl).disabled) formGroup = this.valueChangedFormControlSetValidators(formGroup,'unit',true)
            if((formGroup.controls['number'] as FormControl).disabled) formGroup = this.valueChangedFormControlSetValidators(formGroup,'number',true)

            if((formGroup.controls['agency'] as FormControl).disabled) formGroup = this.valueChangedFormControlSetValidators(formGroup,'agency')
            if((formGroup.controls['bureau_division'] as FormControl).disabled) formGroup = this.valueChangedFormControlSetValidators(formGroup,'bureau_division')

            if((formGroup.value['copyFormatDisabled'] == false))formGroup = this.valueChangedFormArrayRemoveValidators(formGroup,'copyFormat')

            if(!(formGroup.controls['listOfIds'] as FormControl).disabled) formGroup = this.valueChangedFormControlRemoveValidators(formGroup,'listOfIds')
            break;
          }
          case 'Id':{
            // default: requirement title, requirement type,
            // require: list of ids
            // disable: documentary copies, number, unit, agency, div
            
            if((formGroup.controls['listOfIds'] as FormControl).disabled) formGroup = this.valueChangedFormControlSetValidators(formGroup,'listOfIds',true)

            if((formGroup.value['copyFormatDisabled'] == false))formGroup = this.valueChangedFormArrayRemoveValidators(formGroup,'copyFormat')
            
            if(!(formGroup.controls['number'] as FormControl).disabled) formGroup = this.valueChangedFormControlRemoveValidators(formGroup,'number')
            if(!(formGroup.controls['unit'] as FormControl).disabled) formGroup = this.valueChangedFormControlRemoveValidators(formGroup,'unit')
            
            if(!(formGroup.controls['agency'] as FormControl).disabled) formGroup = this.valueChangedFormControlRemoveValidators(formGroup,'agency')
            if(!(formGroup.controls['bureau_division'] as FormControl).disabled) formGroup = this.valueChangedFormControlRemoveValidators(formGroup,'bureau_division')
            break;
          }
        }
        break;
      }
    }
  }

  valueChangedFormControlRemoveValidators(formGroup: FormGroup, controlName:any){
    (formGroup.controls[controlName] as FormControl).clearValidators();
    (formGroup.controls[controlName] as FormControl).disable({onlySelf:true});
    (formGroup.controls[controlName] as FormControl).updateValueAndValidity();
    return formGroup
  }

  valueChangedFormControlSetValidators(formGroup: FormGroup, controlName:any,hasValidators?:boolean){
    (formGroup.controls[controlName] as FormControl).enable({onlySelf:true});
    if(hasValidators == true) (formGroup.controls[controlName] as FormControl).setValidators([Validators.required]);
    (formGroup.controls[controlName] as FormControl).updateValueAndValidity();
    return formGroup
  }

  valueChangedFormArraySetValidators(formGroup: FormGroup, controlName:any){
    formGroup.patchValue({copyFormatDisabled:false});
    let formArray = (formGroup.controls[controlName] as FormArray)
    
    formArray.setValidators([this.checkDocumentFormat(),Validators.required]);
    for(let ctr in formArray.controls as FormGroup[]){
      ((formArray.controls as FormGroup[])[ctr] as FormGroup).patchValue({disabled:false});
      ((formArray.controls as FormGroup[])[ctr] as FormGroup).updateValueAndValidity();
      let val = (((formArray.controls as FormGroup[])[ctr] as FormGroup).controls['type'] as FormControl).value;
      if(val == 'operator' || val == 'document') {
        (((formArray.controls as FormGroup[])[ctr] as FormGroup).controls['val'] as FormControl).enable({onlySelf:true});
        (((formArray.controls as FormGroup[])[ctr] as FormGroup).controls['val'] as FormControl).updateValueAndValidity();
        if(val == 'document'){
          (((formArray.controls as FormGroup[])[ctr] as FormGroup).controls['copyCount'] as FormControl).enable({onlySelf:true});
          (((formArray.controls as FormGroup[])[ctr] as FormGroup).controls['copyCount'] as FormControl).updateValueAndValidity();
        }
      }
    }
    (formGroup.controls[controlName] as FormArray) = formArray
    return formGroup
  }

  valueChangedFormArrayRemoveValidators(formGroup: FormGroup, controlName:any){
    formGroup.patchValue({copyFormatDisabled:true});
    let formArray = (formGroup.controls[controlName] as FormArray)
    
    formArray.setValidators([this.checkDocumentFormat(),Validators.required]);
    for(let ctr in formArray.controls as FormGroup[]){
      ((formArray.controls as FormGroup[])[ctr] as FormGroup).patchValue({disabled:true});
      ((formArray.controls as FormGroup[])[ctr] as FormGroup).updateValueAndValidity();
      let val = (((formArray.controls as FormGroup[])[ctr] as FormGroup).controls['type'] as FormControl).value;
      if(val == 'operator' || val == 'document') {
        (((formArray.controls as FormGroup[])[ctr] as FormGroup).controls['val'] as FormControl).disable({onlySelf:true});
        (((formArray.controls as FormGroup[])[ctr] as FormGroup).controls['val'] as FormControl).updateValueAndValidity();
        if(val == 'document'){
          (((formArray.controls as FormGroup[])[ctr] as FormGroup).controls['copyCount'] as FormControl).disable({onlySelf:true});
          (((formArray.controls as FormGroup[])[ctr] as FormGroup).controls['copyCount'] as FormControl).updateValueAndValidity();
        }
      }
    }
    (formGroup.controls[controlName] as FormArray) = formArray
    return formGroup
  }

  situationalValueChanged(controlName:any,formCtr:number,rowCtr:number){
    switch(controlName){
      case 'requirementType':{
        let formGroup = (((((this.situational_requirement_array.controls as FormGroup[])[formCtr] as FormGroup).controls['situational_requirement_form'] as FormArray).controls as FormGroup[])[rowCtr] as FormGroup);
        const requirementType = formGroup.controls[controlName].value;
        switch(requirementType){
          case 'Documentary':{
            // default: requirement title, requirement type,
            // require:  documentary copies
            // optinal: agency, div
            // disable: number, unit, list of ids
            if((formGroup.value['copyFormatDisabled'] == true)) formGroup = this.valueChangedFormArraySetValidators(formGroup,'copyFormat')

            if((formGroup.controls['agency'] as FormControl).disabled) formGroup = this.valueChangedFormControlSetValidators(formGroup,'agency')
            if((formGroup.controls['bureau_division'] as FormControl).disabled) formGroup = this.valueChangedFormControlSetValidators(formGroup,'bureau_division')
            
            if(!(formGroup.controls['unit'] as FormControl).disabled) formGroup = this.valueChangedFormControlRemoveValidators(formGroup,'unit')
            if(!(formGroup.controls['number'] as FormControl).disabled) formGroup = this.valueChangedFormControlRemoveValidators(formGroup,'number')

            if(!(formGroup.controls['listOfIds'] as FormControl).disabled) formGroup = this.valueChangedFormControlRemoveValidators(formGroup,'listOfIds')
            break;
          }
          case 'Sample':{
            // default: requirement title, requirement type,
            // require: number, unit
            // optinal: agency, div
            // disable: documentary copies, list of ids

            if((formGroup.controls['unit'] as FormControl).disabled) formGroup = this.valueChangedFormControlSetValidators(formGroup,'unit',true)
            if((formGroup.controls['number'] as FormControl).disabled) formGroup = this.valueChangedFormControlSetValidators(formGroup,'number',true)

            if((formGroup.controls['agency'] as FormControl).disabled) formGroup = this.valueChangedFormControlSetValidators(formGroup,'agency')
            if((formGroup.controls['bureau_division'] as FormControl).disabled) formGroup = this.valueChangedFormControlSetValidators(formGroup,'bureau_division')

            if((formGroup.value['copyFormatDisabled'] == false))formGroup = this.valueChangedFormArrayRemoveValidators(formGroup,'copyFormat')

            if(!(formGroup.controls['listOfIds'] as FormControl).disabled) formGroup = this.valueChangedFormControlRemoveValidators(formGroup,'listOfIds')
            break;
          }
          case 'Id':{
            // default: requirement title, requirement type,
            // require: list of ids
            // disable: documentary copies, number, unit, agency, div
            
            if((formGroup.controls['listOfIds'] as FormControl).disabled) formGroup = this.valueChangedFormControlSetValidators(formGroup,'listOfIds',true)

            if((formGroup.value['copyFormatDisabled'] == false))formGroup = this.valueChangedFormArrayRemoveValidators(formGroup,'copyFormat')
            
            if(!(formGroup.controls['number'] as FormControl).disabled) formGroup = this.valueChangedFormControlRemoveValidators(formGroup,'number')
            if(!(formGroup.controls['unit'] as FormControl).disabled) formGroup = this.valueChangedFormControlRemoveValidators(formGroup,'unit')
            
            if(!(formGroup.controls['agency'] as FormControl).disabled) formGroup = this.valueChangedFormControlRemoveValidators(formGroup,'agency')
            if(!(formGroup.controls['bureau_division'] as FormControl).disabled) formGroup = this.valueChangedFormControlRemoveValidators(formGroup,'bureau_division')
            break;
          }
        }
        break;
      }
    }
  }
  // !requirements tab row functions


  // requirement tab functions
  addNewRequirement() {
    this.standard_requirement_form.push(this.buildNewEntry(EntityBuilderIndex.REQUIREMENT));
    this.standard_requirement_form_datasource = new MatTableDataSource(this.standard_requirement_form.controls);
    this.changeDetectorRef.detectChanges()
    const standard_row_item = this.rows.find(row => row.nativeElement.id === "standard_row_"+(this.standard_requirement_form.value.length-1))
    standard_row_item?.nativeElement.scrollIntoView({block: 'start', behavior: 'smooth',  inline: "nearest"})
  }

  removeStandardRequirement(index: any) {
    this.standard_requirement_form.removeAt(index);
    this.standard_requirement_form_datasource = new MatTableDataSource(this.standard_requirement_form.controls);
  }

  addSituationalForm(){
    let newForm:any =  new FormGroup({
      situational_requirement_form: new FormArray([ this.buildNewEntry(EntityBuilderIndex.REQUIREMENT)],[this.checkRequirementDuplicate()]),
      title: new FormControl('New Situational Requirements'),
    })
    this.situational_requirement_array.push(newForm);
    // const ctr:any = this.situational_requirement_array.value.indexOf(newForm)
    const ctr:any = this.situational_requirement_array.value.length
    if(ctr >= 0){
      this.selected_requirement_tab = ctr
    }
  }

  getDataSource(ctr:any){
    let formGroup = (((this.situational_requirement_array.controls as FormGroup[])[ctr] as FormGroup).controls['situational_requirement_form'] as FormArray).controls;
    return new MatTableDataSource(formGroup);
  }

  deleteSituationalForm(ctr:any){
    this.situational_requirement_array.removeAt(ctr)
    const ctr2:any = this.situational_requirement_array.value.length
    if(ctr2 >= 0) this.selected_requirement_tab = ctr2;
  }

  addNewRequirementToSituational(ctr:any){
    const currentSituationalRequirment = ((((this.situational_requirement_array.controls) as FormGroup[])[ctr] as FormGroup).controls['situational_requirement_form'] as FormArray)
    currentSituationalRequirment.push(this.buildNewEntry(EntityBuilderIndex.REQUIREMENT));
    
    this.changeDetectorRef.detectChanges()
    const situational_row_item = this.rows.find(row => row.nativeElement.id === "situational_"+ctr+"_row_"+(currentSituationalRequirment.value.length-1))
    situational_row_item?.nativeElement.scrollIntoView({block: 'start', behavior: 'smooth',  inline: "nearest"})
  }

  removeSituationalRequirement(ctr1:any, ctr2:any){
    (((this.situational_requirement_array.controls as FormGroup[])[ctr1] as FormGroup).controls['situational_requirement_form'] as FormArray).removeAt(ctr2);
  }

  indexChange(temp:any){
    this.selected_requirement_tab = temp;
  }

  get situational_requirement_tab(){
    return (this.situational_requirement_array.controls as FormGroup[])
  }
  // requirement tab functions


  // Requirement Validators
  checkRequirementHint(control:any){
    // if(control && ((control as any)._rawValidators) != null && ((control as any)._rawValidators).map((item:any)=>item.name).includes('required') && !(control as FormControl).touched) return true;
    if(control && ((control as FormControl).enabled) && !(control as FormControl).touched) return true;
    return false;
  }

  checkRequirementDuplicate(): ValidatorFn{
    // console.log('trying validator')
    return (control: AbstractControl): ValidationErrors | null =>{
      // console.log('checkRequirementDuplicate control',formArray)
      if(control instanceof FormArray){
        let tempArr:any = []
        let errors = {
          hasDuplicateRequirement: false
        }
        let tempbool:boolean = false
        control.controls.forEach((item,index)=>{
          let unprocessedErrors = ((control.controls[index] as FormGroup).controls['requirement'] as FormControl).errors;
          // console.log(index+': unprocessedErrors',unprocessedErrors);
          let processedErrors = this.removeErrors(unprocessedErrors,['duplicate']);
          // console.log(index+': processedErrors',processedErrors);
          ((control.controls[index] as FormGroup).controls['requirement'] as FormControl).setErrors(processedErrors);
          let name = item.value.requirement.trim().toLowerCase();
          if(name && name != '' && name != undefined && name != null){
            const ctr = tempArr.map((item:any)=>item.name.trim().toLowerCase()).indexOf(name)
            if(ctr >= 0){
              tempArr[ctr].value = tempArr[ctr].value + 1;
              tempArr[ctr].indexes.push(index)
              tempbool = true
              errors.hasDuplicateRequirement = true
            }else{
              tempArr.push({name:name,value:1,indexes:[index]})
            }
          }
        })
        // console.log('tempArr',tempArr)
        tempArr.forEach((item:any)=>{
          if(item.indexes.length > 1){
            item.indexes.forEach((item2:any)=>{
              ((control.controls[item2] as FormGroup).controls['requirement'] as FormControl).setErrors({duplicate:true});
              // ((control.controls[index] as FormGroup).controls['requirement'] as FormControl).updateValueAndValidity();
            })
          }
        })
        return tempbool ? errors : null
      }
      return null
    }
  }

  checkDocumentFormat():ValidatorFn{
    return (control: AbstractControl): ValidationErrors | null =>{
      if(control instanceof FormArray){
        let len:any= 0;
        let documentArr:any = 0
        let origArr:any = 0
        let photoArr:any = 0
        let electronicArr:any = 0
        let certifiedTrueArr:any = 0
        let openArr:any = 0
        let parenthesisArr:any = 0
        let closeArr:any = 0
        let operatorArr:any = 0
        let errors = {
          format: false,
          hasOriginalVal: false,
          hasPhotoVal: false,
          hasElectronicVal: false,
          hasCertifiedTrueVal: false,
        }
        let tempbool:boolean = false

        control.controls.forEach((item:any)=>{
          len++;
          if(item.value.type == 'document'){
            documentArr++;
            if(item.controls.val.value == 'Original Copy') {
              origArr++;
            }
            if(item.controls.val.value == 'Photo Copy') {
              photoArr++;
            }
            if(item.controls.val.value == 'Electronic Copy') {
              electronicArr++;
            }
            if(item.controls.val.value == 'Certified True Copy') {
              certifiedTrueArr++;
            }
          }
          else if(item.value.type == 'operator'){
            operatorArr++;
          }
          else if(item.value.type == 'parenthesis'){
            parenthesisArr++;
            if(item.controls.val.value == '\(') openArr++;
            if(item.controls.val.value == '\)') closeArr++;
          }
        })
        // console.log('len',len);
        // console.log('documentArr',documentArr);
        // console.log('origArr',origArr);
        // console.log('photoArr',photoArr);
        // console.log('ElectronicArr',ElectronicArr);
        // console.log('openArr',openArr);
        // console.log('parenthesisArr',parenthesisArr);
        // console.log('closeArr',closeArr);
        // console.log('operatorArr',operatorArr);
        if(len > 0){
          if(documentArr > 0){
            // if(origArr > 1 || photoArr > 1 || ElectronicArr > 1){
            //   tempbool = true
            //   errors.format = true
            // }else 
            if(origArr == 0 && photoArr == 0 && electronicArr == 0 && certifiedTrueArr == 0){
              tempbool = true
              errors.format = true
            }
          }
          else{
            tempbool = true
            errors.format = true
          }
          if(parenthesisArr%2 == 0){
            if(openArr != closeArr){
              tempbool = true
              errors.format = true
            }
          }else{
            tempbool = true
            errors.format = true
          }
          if(operatorArr != (documentArr - 1)){
            tempbool = true
            errors.format = true
          }
        }

        if(tempbool == false && len > 0){
          let tempArr:any = [];
          control.value.forEach((item2:any)=>{tempArr.push(item2.val)})
          // console.log(tempArr)
          let boolTemp = this.checkExpressionValidity(tempArr)
          // console.log('boolTemp',boolTemp)
          if(boolTemp == false){
            tempbool = true;
            errors.format = true
          }
        }

        return tempbool ? errors : null
      }
      return null;
    }
  }

  removeErrors(errors:any,toRemove:any[]){
    if(!errors || Object.keys(errors).length == 0) return null
    if(toRemove.length > 0){
      toRemove.forEach((item)=>{
        delete errors[item]
      })
    }
    if(Object.keys(errors).length == 0) return null
    return errors
  }
  // !Requirement Validators


  // validations
  hasError(control: FormControl, validType:string){
    return ((control.hasError(validType))
      && (control.dirty
      || control.touched))
  }

  checkClientStepsFormIsInvalid() {
    for (let [k, v] of this.client_steps_form) {
     // console.log('client step: ',v.info.value,v.info.valid)
      if (!v.info.valid) {
        // console.warn('errors: ',(v.info as FormGroup).errors);
        let infoControls =(v.info as FormGroup).controls
        for(let controlName of Object.keys(infoControls)){
          if(infoControls[controlName].invalid) console.warn(controlName)
        }
        console.warn('errors above');
        return true;
      }

      for (let [k, v2] of v.agency_actions) {
        //console.log('agency action: ',v2.value,v2.valid)
        if (!v2.valid) {
          return true;
        }
      }
    }
    return false;
  }

  /*
  checkClientStepsFormIsInvalid() {
    for (let [k, v] of this.client_steps_form) {
      console.log('client step: ',v.info.value,v.info.valid)
      if (!v.info.valid) {
        // console.warn('errors: ',(v.info as FormGroup).errors);
        let infoControls =(v.info as FormGroup).controls
        for(let controlName of Object.keys(infoControls)){
          if(infoControls[controlName].invalid) console.warn(controlName)
        }
        console.warn('errors above');
        return true;
      }

      for (let [k, v2] of v.agency_actions) {
        console.log('agency action: ',v2.value,v2.valid)
        if (!v2.valid) {
          return true;
        }
      }
    }
    return false;
  }
  // */
  // !validations


  // document format functions
  drop(controlCode:string, event: CdkDragDrop<string[]>, rowCtr:number, formCtr?:any) {
    // console.log('controlCode: ',controlCode)
    if(event.currentIndex != event.previousIndex){
      // console.log('rowCtr: ',rowCtr);
      // console.log('changed');

      switch(controlCode){
        case 'standard':{
          let formArray = (((this.standard_requirement_form.controls as FormGroup[])[rowCtr] as FormGroup).controls['copyFormat'] as FormArray)
          formArray = this.drop2(formArray, event)
          break;
        }
        case 'situational':{
          let formArray = ((((((this.situational_requirement_array.controls as FormGroup[])[formCtr] as FormGroup).controls['situational_requirement_form'] as FormArray).controls as FormGroup[])[rowCtr] as FormGroup).controls['copyFormat'] as FormArray)
          formArray = this.drop2(formArray, event)
          break;
        }
      }
    }
  }

  drop2(formArray:FormArray,event: CdkDragDrop<string[]>){
    formArray.markAllAsTouched();
    formArray.markAsDirty();
    formArray = this.moveItemInFormArray( formArray, event.previousIndex, event.currentIndex);
    return formArray
  }

  moveItemInFormArray( formArray: FormArray, fromIndex: number, toIndex: number ) {
    const dir = toIndex > fromIndex ? 1 : -1;
    const item = formArray.at(fromIndex);
    for (let i = fromIndex; i * dir < toIndex * dir; i = i + dir) {
      const current = formArray.at(i + dir);
      formArray.setControl(i, current);
    }
    formArray.setControl(toIndex, item);
    return formArray;
  } 

  addNode(controlCode:string,nodeType:any,rowCtr:any,formCtr?:any){
    switch(controlCode){
      case 'standard':{
        let formArray = (((this.standard_requirement_form.controls as FormGroup[])[rowCtr] as FormGroup).controls['copyFormat'] as FormArray)
        formArray = this.addNode2(formArray,nodeType)
        break
      }
      case 'situational':{
        let formArray = ((((((this.situational_requirement_array.controls as FormGroup[])[formCtr] as FormGroup).controls['situational_requirement_form'] as FormArray).controls as FormGroup[])[rowCtr] as FormGroup).controls['copyFormat'] as FormArray)
        formArray = this.addNode2(formArray,nodeType)
        break
      }

    }
  }

  addNode2(formArray:FormArray,nodeType:any){
    formArray.markAllAsTouched();
    formArray.markAsDirty();
    switch(nodeType){
      case 'parenthesis':{
        formArray.push(this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_PARENTHESIS_OPEN,true))
        formArray.push(this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_PARENTHESIS_CLOSE,true))
        return formArray
      }
      case 'document':{
        formArray.push(this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ORIGINAL,true))
        return formArray
      }
      case 'operator':{
        formArray.push(this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_AND,true))
        return formArray
      }
      default: return formArray 
    }
  }

  removeDocumentNode(controlCode:string,formatCtr:any,rowCtr:any,formCtr?:any){
    switch(controlCode){
      case 'standard':{
        let formArray = (((this.standard_requirement_form.controls as FormGroup[])[rowCtr] as FormGroup).controls['copyFormat'] as FormArray)
        formArray = this.removeDocumentNode2(formArray,formatCtr)
        break;
      }
      case 'situational':{
        let formArray = ((((((this.situational_requirement_array.controls as FormGroup[])[formCtr] as FormGroup).controls['situational_requirement_form'] as FormArray).controls as FormGroup[])[rowCtr] as FormGroup).controls['copyFormat'] as FormArray);
        formArray = this.removeDocumentNode2(formArray,formatCtr)
        break;
      }
    }
  }

  removeDocumentNode2(formArray:FormArray,formatCtr:number){
    formArray.markAllAsTouched();
    formArray.markAsDirty();
    formArray.removeAt(formatCtr);
    return formArray
  }

  clearNodes(controlCode:string,rowCtr:any,formCtr?:any){
    switch(controlCode){
      case 'standard':{
        let formArray = (((this.standard_requirement_form.controls as FormGroup[])[rowCtr] as FormGroup).controls['copyFormat'] as FormArray);
        formArray = this.clearNodes2(formArray)
        break;
      }
      case 'situational':{
        let formArray = ((((((this.situational_requirement_array.controls as FormGroup[])[formCtr] as FormGroup).controls['situational_requirement_form'] as FormArray).controls as FormGroup[])[rowCtr] as FormGroup).controls['copyFormat'] as FormArray);
        formArray = this.clearNodes2(formArray)
        break;
      }
    }
  }

  clearNodes2(formArray:FormArray){
    formArray.markAllAsTouched();
    formArray.markAsDirty();
    formArray.clear()
    return formArray
  }
  
  checkExpressionValidity(arr:any):boolean{
    // console.log('checkExpressionValidity: ',arr)
    if(arr.length > 0){
      if(arr[0] == '(' && arr[arr.length-1] == ')'){
        
        // arr.shift();
        // arr.splice(-1);
        // // console.log('after shift: ',arr)
        // if((arr.length) > 0){
        //   return this.checkExpressionValidity(arr);
        // }
        // else return false

        
        let tempPar = 0;
        let ctr = 0;
        let tempArr:any = []
        arr.every((item:any)=>{
          ctr++
          tempArr.push(item)
          if(item == ")") {
            tempPar--;
            if(tempPar == 0){
              return false
            }else{
              // tempArr.push(item)
              return true
            }
          }
          else {
            if( item == "(") tempPar++;
            // tempArr.push(item)
            return true
          }
        })
        if(arr.length == ctr){
          arr.shift();
          arr.splice(-1);
          if((arr.length) > 0){
            return this.checkExpressionValidity(arr);
          }
          else return false
        }else{
          let newArr = arr.slice(ctr,arr.length)
          if(newArr.length == 0){
            return true
          }
          else{
            let bool1 = this.checkOper(newArr[0])
            if(!bool1) return false
            newArr.shift()
            return this.checkExpressionValidity(newArr)
          }
        }
      }

      else if(arr[0] != '(' || arr[arr.length-1] != ')'){
        // console.log('checking for "Doc": ',arr)
        // check for (A or B) and C kind
        if(arr[0] == '('){
          let tempPar = 1;
          let tempArr:any = []
          arr.shift()
          arr.every((item:any)=>{
            if(item == ")") {
              tempPar--;
              if(tempPar == 0){
                return false
              }else{
                tempArr.push(item)
                return true
              }
            }
            else {
              if(item == "(") tempPar++;
              tempArr.push(item)
              return true
            }
          })
          // console.log('after every: ',tempArr)
          return this.checkExpressionValidity(tempArr)
        }else{
          let bool1 = this.checkDoc(arr[0])
          if(!bool1) return false
  
          arr.shift();
          // console.log('after shift: ',arr)
          if(arr.length == 0){
            return true
          }
          else{
            // console.log('checking for Doc Oper "S": ',arr)
            let bool2 = this.checkOper(arr[0])
            if(!bool2) return false
            arr.shift()
            // console.log('after shift: ',arr)
            return this.checkExpressionValidity(arr)
          }
        }
      }

      else return false;
    }
    else return false;
  }

  checkDoc(val:string){
    // console.log('checkDoc: ',val)
    if(val == 'Original Copy' || val == 'Photo Copy' || val == 'Electronic Copy' || val == 'Certified True Copy'){
      return true;
    }
    return false;
  }

  checkOper(val:string){
    // console.log('checkOper: ',val)
    if(val == 'And' || val == 'Or'){
      return true;
    }
    return false;
  }
  // !document format functions


  // where to secure functions

  // !where to secure functions


  // Filtered dropdowns for use in EODB tags
  filteredDivision(reg_sector: string): any[] {
    let section = this.valueSuggestions.sector.find((option: any) => option.name === reg_sector)?.section?.toLowerCase() || '';
    return this.valueSuggestions.division.filter((option: any) => section.trim() != '' && option.section.toLowerCase().includes(section));
  }

  filteredCaseUse(reg_business: string): any[] {
    let section = this.valueSuggestions.stage_of_business.find((option: any) => option.name === reg_business)?.section?.toLowerCase() || '';
    return this.valueSuggestions.case_use.filter((option: any) => section.trim() != '' && option.section.toLowerCase().includes(section));
  }
}
