<p>artemis-requirement-builder works!</p>


<button class="btn btn-primary" (click)="addRequirement(reqType.REQUIREMENT)">Add Requirement</button>

<form [formGroup]="formGroup">

    <ng-container formArrayName="requirementArray" >

        <app-artemis-requirement-form *ngFor="let req of requirementArray.controls;let i = index" [groupName]="i"></app-artemis-requirement-form>
    
    </ng-container>
</form>


<button class="btn btn-primary" (click)="addRequirement(reqType.REQUIREMENT_CHOOSE_ANY)">Choose Any Requirement</button>