<app-navbar [currentPage]=""></app-navbar>
<div class="content padded">
    <button type="button" class="btn btn-dark" (click)="toSendEmail()">send test email</button>
    <div class="p-4">
        <div class="container-fluid">
            <div class="card">
                
                <mat-toolbar class="d-flex justify-content-between">
                    <h5 class="card-header">Notifications</h5>
                    <!-- <button [matMenuTriggerFor]="settingsMenu" (click)="resetToggles()" mat-icon-button type="button">
                        <mat-icon>settings</mat-icon>
                    </button> -->

                    <form [formGroup]="filtersForm">
                        <div class="row info-search sticky sticky-search form-group">
                            <div class="col-2" style="display:flex; align-items: center; justify-content:center;">
                                <label>Search</label>
                            </div>
                            <div class="col">
                                <input class="full-width form-control" (keyup)="search($event)" formControlName="search" type="text">
                                <!-- <input class="full-width form-control" type="text"> -->
                            </div>
                        </div>
                    </form>

                    <button (click)="openDialog()" mat-icon-button type="button">
                        <mat-icon>settings</mat-icon>
                    </button>
                </mat-toolbar>
                <div class="card-body p-0">
                    <!-- <table class="table table-responsive table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th>Event</th>
                                <th>Type</th>
                                <th>Message</th>
                            </tr>
                        </thead>
                        <tbody >
                            <ng-container *ngIf="loader">
                                <tr>
                                    <th scope="row">Loading . . .</th>
                                    <td>Loading . . .</td>
                                    <td>Loading . . .</td>
                                    <td>Loading . . .</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="!loader">
                                <ng-container *ngIf="loadedData.length > 0 ">
                                    <tr *ngFor="let item of loadedData; let i = index">
                                        <th scope="row">{{i+1}}</th>
                                        <td>{{item.event}}</td>
                                        <td>{{item.notif_type}}</td>
                                        <td>{{item.message}}</td>
                                    </tr>
                                </ng-container>
                                <ng-container *ngIf="loadedData.length == 0 ">
                                    <tr>
                                        <th scope="row">No Data</th>
                                        <td>. . .</td>
                                        <td>. . .</td>
                                        <td>. . .</td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table> -->
                
                    <table mat-table
                        class="table table-hover table-responsive"
                        id="matTable"
                        [dataSource]="notificationsDataSource"
                        matSort>
                        <ng-container matColumnDef="event">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Event</h5>  </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="d-flex align-items-center">
                                    <ng-container *ngIf="loader">
                                        <p>Loading . . .</p>
                                    </ng-container>
                                    <ng-container *ngIf="!loader">
                                        <ng-container *ngIf="loadedData.length > 0 ">
                                            <p>{{element.event}}</p>
                                        </ng-container>
                                        <ng-container *ngIf="loadedData.length == 0 ">
                                            <p>No Data</p>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="notif_type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Type</h5>  </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="d-flex align-items-center">
                                    <ng-container *ngIf="loader">
                                        <p>Loading . . .</p>
                                    </ng-container>
                                    <ng-container *ngIf="!loader">
                                        <ng-container *ngIf="loadedData.length > 0 ">
                                                <p>{{element.notif_type}}</p>
                                        </ng-container>
                                        <ng-container *ngIf="loadedData.length == 0 ">
                                            <p>No Data</p>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="message">
                            <th mat-header-cell *matHeaderCellDef> <h5>Messages </h5>  </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="d-flex align-items-center">
                                    <ng-container *ngIf="loader">
                                        <p>Loading . . .</p>
                                    </ng-container>
                                    <ng-container *ngIf="!loader">
                                        <ng-container *ngIf="loadedData.length > 0 ">
                                            <p>{{element.message}}</p> 
                                        </ng-container>
                                        <ng-container *ngIf="loadedData.length == 0 ">
                                            <p>No Data</p>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="notificationsDataSourceColumns; sticky: true"></tr>
                        <tr mat-row matRipple *matRowDef="let row; columns: notificationsDataSourceColumns;"></tr>
                    
                    </table>
                    <mat-paginator pageSize="15" aria-label="Select page of users"></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <mat-menu #settingsMenu="matMenu" >
    <div mat-menu-item disableRipple (click)="$event.stopPropagation();">
        <mat-slide-toggle
        [checked]="regulationsToggle == true ? true : false"
        (change)="toogleSubscription('regulation')"
        color="primary">Regulations</mat-slide-toggle>
    </div>
    <div mat-menu-item disableRipple (click)="$event.stopPropagation();">
        <mat-slide-toggle
        [checked]="consultationsToggle == true ? true : false"
        (change)="toogleSubscription('consultation')"
        color="primary">Consultations</mat-slide-toggle>
    </div>
    <div mat-menu-item disableRipple (click)="$event.stopPropagation();">
        <mat-slide-toggle
        [checked]="citizenschartersToggle == true ? true : false"
        (change)="toogleSubscription('citizencharter')"
        color="primary">Citizen's Charters</mat-slide-toggle>
    </div>
    <div mat-menu-item disableRipple (click)="$event.stopPropagation();">
        <mat-slide-toggle 
        [checked]="servicesToggle == true ? true : false"
        (change)="toogleSubscription('service')"
        color="primary">Services</mat-slide-toggle>
    </div>
    <div *ngIf="isAdmin || isAgencyMember  || this.userType === 'arta'" mat-menu-item disableRipple (click)="$event.stopPropagation();">
        <mat-slide-toggle 
        [checked]="agencyToggle == true ? true : false"
        (change)="toogleSubscription('agency')"
        color="primary">Agency</mat-slide-toggle>
    </div>
    <div *ngIf="isAdmin  || this.userType === 'arta'" mat-menu-item disableRipple (click)="$event.stopPropagation();">
        <mat-slide-toggle 
        [checked]="worklistToggle == true ? true : false"
        (change)="toogleSubscription('worklist')"
        color="primary">Worklist</mat-slide-toggle>
    </div>
    <div disableRipple class="d-flex justify-content-center">
        <button mat-menu-item (click)="updateSettings()" color="primary" class="btn btn-primary">Confirm</button>
    </div>
</mat-menu> -->