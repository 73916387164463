import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface templateData {
    id: string;
    configCode: number;
    content: string;
    enabled: boolean;
    recipient: string;
    subject: string;
    templateName: string;
    trigger: string;
    variables: Array<variablesData>,
}

export interface variablesData{
    key: string,
    title: string,
    type: string
}


@Component({
    selector: 'app-email-configuration-template',
    templateUrl: './email-configuration-template.component.html',
    styleUrls: ['./email-configuration-template.component.scss']
})
export class EmailConfigurationTemplateDialogComponent implements OnInit {

    typeOptions=[
        'string','number','array','date','link'
    ]

    createTemplateForm = new FormGroup({
        configCode: new FormControl(999,[Validators.required,Validators.min(1)]),
        content: new FormControl(''),
        enabled: new FormControl(true),
        recipient: new FormControl(''),
        subject: new FormControl(''),
        templateName:new FormControl(''),
        trigger: new FormControl(''),
        variables: new FormArray([])
    })

    constructor(
        public dialogRef: MatDialogRef<EmailConfigurationTemplateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: templateData,
        public formBuilder: FormBuilder,
        private afs: AngularFirestore,
    ){ }

    ngOnInit(): void {

    }

    get variables(){
        return (this.createTemplateForm.controls.variables as FormArray)
    }

    get variablesFormGroup(){
        return (this.variables.controls as FormGroup[])
    }

    addNewVariable(){
        this.variables.push(
            new FormGroup({
                key: new FormControl(''),
                title: new FormControl(''),
                others: new FormControl(''), // for update
                type: new FormControl(''),
            })
        )
    }

    removeVariable(index:number){
        this.variables.removeAt(index)
    }

    submitCreate(){
        // console.log(this.createTemplateForm.value)
        if(this.createTemplateForm.valid){
            this.afs.collection('Email Configuration').add(this.createTemplateForm.value)
            .then((result)=>{
                console.log('Success created template')
                this.goBack()
            })
            .catch((err)=>{
                console.error('Error: ',err)
            })
        }
    }

    goBack(){
      this.dialogRef.close(this.data)
    }
}