<mat-dialog-content>
    <div>
      <div class="m-4 row">
        <div class="col">
          <h4 class="text-danger"><i class="fas fa-minus-circle text-danger"></i> <strong> Delisting - Service</strong></h4>
          <h6>{{serviceName}}</h6>
        </div>
      </div>
      <div class="m-4 row">
        <b>
          Please provide your reason on delisting the service
          currently provided in the Citizen's Charter.
        </b>
      </div>
      <div class="mt-4 mx-4 row">
        <mat-form-field style="width: 100%" hideRequiredMarker appearance="outline">
          <mat-label>Reason for delisting</mat-label>
          <textarea
            matInput
            placeholder="Type here..."
            type="text"
            class="reason-textarea"
            [formControl]="reason_for_delisting"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="row mx-4">
        <div class="col">
          <div class="float-end">

            <button mat-stroked-button 
              (click)="cancel()" 
              class="mb-2 mx-2" >Cancel <i class="fas fa-times-circle"></i></button>

              <button mat-flat-button  
                  (click)="submit()"
                  [disabled]="!reason_for_delisting.valid"
                  color="warn">Submit For Delisting <i class="fas fa-check-circle"></i> </button>
    

          </div>
        </div>
        
        <!---
        <div class="col-2">
          
          <button (click)="cancel()"  class="btn btn-dark  mb-2">
            Cancel
          </button>
        </div>
        <div class="col-3">
          

        
            <button
            (click)="submit()"
            class="btn btn-danger mb-2 btn-min_width"
            [disabled]="!reason_for_delisting.valid"
          >
            Submit For Delisting
          </button>
     
          
        </div>-->
 
      </div>
    </div>
  </mat-dialog-content>
  