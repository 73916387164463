import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-artemis',
  templateUrl: './artemis.component.html',
  styleUrls: ['./artemis.component.scss']
})
export class ArtemisComponent implements OnInit {
  public page:any = "home";
  cred:any

  constructor(
    public auth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadUsers()
  }

  continueAsGuest(){
    this.page = "home";
  }

  async loadUsers(){
    await this.auth.user.subscribe(data=>{
      this.afs.doc(`Users/${data?.uid}`).get().subscribe((cred:any) =>{
        this.cred = cred.data()
        if(this.cred && !this.cred.credentials.access_artemis){
          this.router.navigate(['/restricted']);
        }
        else{
          //alert("Welcome to ARTEMIS!")
        }
      })
    })
  }
  
}
