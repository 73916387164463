

<ng-container>

  
        <mat-accordion>
            <div [formGroupName]="groupName">

                <input type="text" formArrayName="requirement">
            </div>
          </mat-accordion>
   

</ng-container>

