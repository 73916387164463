<app-navbar></app-navbar>

<div class="advanced-search-card shadow">
  <div style="height: 30px"></div>
  
  <div class="{{style_theme}} advanced-search-title">
    <i class="advanced-search-title-icon fas fa-search fa-3x"></i>&nbsp;&nbsp;
    <h1> Quick Search</h1>
  </div>
  <div class="text-center">
    <form [formGroup]="searchform" class="form-group">
      <!-- user type -->
      <div class="row mt-3">
        <div class="col-2">
            I am an/a
        </div>
        <div class="col">
          <select id="user_type_select" class="form-select text-center service-finder-input" aria-label="User type" #select_user_type (change)="universalSelect('user_type',select_user_type.value)">
            <option selected readonly value="">Select User Type</option>
            <option *ngFor="let entry of universalOption('user_type')" [value]="entry">{{entry}}</option>
          </select>
          <ng-container *ngFor="let entry of checkSearchForm.user">
            <mat-error style="color:red" *ngIf="hasError('user',entry.type)">* {{entry.message}}</mat-error>
          </ng-container>
        </div>
      </div>

      <!-- individual -->
      <ng-container *ngIf="searchform.value.user == 'Individual'">
        <!-- sol -->
        <div class="row mt-3">
          <div class="col-2">
            currently
          </div>
          <div class="col">
            <input type="text"
              class="form-control text-center service-finder-input"
              formControlName="stageoflife"
              [matAutocomplete]="stageoflife" 
              placeholder="Stage of Life">
              <mat-autocomplete #stageoflife="matAutocomplete">
                <mat-option *ngFor="let entry of autoCSol | async" [value]="entry.stage">
                    {{entry.stage}}
                </mat-option>
              </mat-autocomplete>
              
            <ng-container *ngFor="let entry of checkSearchForm.stageoflife">
              <mat-error style="color:red" *ngIf="hasError('stageoflife',entry.type)">* {{entry.message}}</mat-error>
            </ng-container>
          </div>
        </div>

        <!-- life -->
        <div class="row mt-3">
          <div class="col-2">
            intending to
          </div>
          <div class="col">
            <input type="text"
              class="form-control text-center service-finder-input"
              formControlName="lifeevent"
              [matAutocomplete]="lifeevent"
              placeholder="Life Event">
              <mat-autocomplete #lifeevent="matAutocomplete">
                <mat-option *ngFor="let entry of autoCLife | async" [value]="entry">
                    {{entry}}
                </mat-option>
              </mat-autocomplete>
            <ng-container *ngFor="let entry of checkSearchForm.lifeevent">
              <mat-error style="color:red" *ngIf="hasError('lifeevent',entry.type)">* {{entry.message}}</mat-error>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <!-- business -->
      <ng-container *ngIf="searchform.value.user == 'Business'">
        <!-- sector -->
        <div class="row mt-3">
          <div class="col-2">
            under the
          </div>
          <div class="col">
            <input type="text"
              class="form-control text-center service-finder-input"
              formControlName="sector"
              [matAutocomplete]="sector"
              placeholder="Sector">
              <mat-autocomplete #sector="matAutocomplete">
                <mat-option *ngFor="let entry of autoCSector | async" [value]="entry.name">
                    {{entry.name}}
                </mat-option>
              </mat-autocomplete>
              <ng-container *ngFor="let entry of checkSearchForm.sector">
                <mat-error style="color:red" *ngIf="hasError('sector',entry.type)">* {{entry.message}}</mat-error>
              </ng-container>
          </div>
        </div>

        <!-- sob -->
        <div class="row mt-3">
          <div class="col-2">
            intending to
          </div>
          <div class="col">
            <input type="text"
              class="form-control text-center service-finder-input"
              formControlName="stageofbusiness"
              [matAutocomplete]="stageofbusiness"
              placeholder="Stage of Business">
              <mat-autocomplete #stageofbusiness="matAutocomplete">
                <mat-option *ngFor="let entry of autoCSob | async" [value]="entry.name">
                    {{entry.name}}
                </mat-option>
              </mat-autocomplete>
              <ng-container *ngFor="let entry of checkSearchForm.stageofbusiness">
                <mat-error style="color:red" *ngIf="hasError('stageofbusiness',entry.type)">* {{entry.message}}</mat-error>
              </ng-container>
          </div>
        </div>
      </ng-container>

      <!-- juris -->
      <div class="row mt-3">
        <div class="col-2">
          at
        </div>
        <div class="col">
          <input type="text"
            class="form-control text-center service-finder-input"
            formControlName="jurisdiction"
            [matAutocomplete]="jurisdiction"
            placeholder="Jurisdiction">
            <mat-autocomplete #jurisdiction="matAutocomplete">
              <mat-option *ngFor="let entry of autoCJuris | async" [value]="entry.name">
                  {{entry.name}}
              </mat-option>
            </mat-autocomplete>
            <ng-container *ngFor="let entry of checkSearchForm.jurisdiction">
              <mat-error style="color:red" *ngIf="hasError('jurisdiction',entry.type)">* {{entry.message}}</mat-error>
            </ng-container>
        </div>
      </div>

      <!-- status -->
      <div class="row mt-3">
        <div class="col-2">
          looking for
        </div>
        <div class="col">
          <select id="status_select" class="form-select text-center service-finder-input" aria-label="User type" #select_status (change)="universalSelect('status',select_status.value)">
            <option selected readonly value="">Select Regulation Status</option>
            <option *ngFor="let entry of universalOption('status')" value="{{entry.toLowerCase()}}">{{entry}} Regulation</option>
          </select>
          <ng-container *ngFor="let entry of checkSearchForm.status">
            <mat-error style="color:red" *ngIf="hasError('status',entry.type)">* {{entry.message}}</mat-error>
          </ng-container>
        </div>
      </div>

      <!-- agency -->
      <div class="row mt-3">
        <div class="col-2">
          of
        </div>
        <div class="col">
          <input type="text"
            class="form-control text-center service-finder-input"
            formControlName="agency"
            [matAutocomplete]="agency"
            placeholder="Agency">
            <mat-autocomplete #agency="matAutocomplete">
              <mat-option *ngFor="let entry of autoCAgency | async" [value]="entry.agencyDetails ? entry.agencyDetails.agencyName : entry.name">
                {{entry.agencyDetails ? entry.agencyDetails.agencyName : entry.name}}
              </mat-option>
            </mat-autocomplete>
            <ng-container *ngFor="let entry of checkSearchForm.agency">
              <mat-error style="color:red" *ngIf="hasError('agency',entry.type)">* {{entry.message}}</mat-error>
            </ng-container>
        </div>
      </div>

    </form>
  </div>
  <div class="m-3 text-center row">
    <div class="col">
      <button class="btn" (click)="formReset()">Reset Form</button>
    </div>
    <div class="col">
        <button class="{{style_theme}}-btn-inverted theme-button" (click)="formSubmit()">SEARCH</button>
    </div>
  </div>
  <div class="m-3 text-center"></div>
</div>