
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ArtemisOfficeControlComponent } from '../artemis-office-control/artemis-office-control.component';

import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';
import { GovServiceService } from 'src/app/service/gov-service.service';
import { Subscription } from 'rxjs';

import { tap } from 'rxjs/operators';

interface office{
  name: string,
  cssClass: string,
  image: string,
  title: string,
  childs: any[]

}


@Component({
  selector: 'app-artemis-office-hierarchy-control',
  templateUrl: './artemis-office-hierarchy-control.component.html',
  styleUrls: ['./artemis-office-hierarchy-control.component.scss']
})
export class ArtemisOfficeHierarchyControlComponent implements OnInit {

 dataSource:any[] = []
 @Input() leadEncoder :boolean =false
 @Input() agency_id:any
 @Input() isLowerLevel: boolean ;


 nodes: any = []

 Subscription: Subscription

  constructor(
    private afs: AngularFirestore,
    private dialog: MatDialog,
    private notif: SnackbarNotifService,
    private gservice: GovServiceService
  ) {

   this.Subscription = this.gservice.officeUpdate.subscribe((value:any)=>{

      if(value){

        //console.log(value)
        let temp_source:any[] = [...this.dataSource,...this.lower_level_unit]
        let temp : any = temp_source.filter(f=>f.id === value);
      
       this.afs.collection("Agency Office Hierarchy").doc(value)
       .update({  
        office : temp[0].office
       }).then(()=>{
        this.notif.displayNotif("success","Office Updated")
       })
        
        

      }

    })
    
  }

  data:any[];

  ngOnInit(): void {

    this.loadoffices();

  
   
  
  }

  ngOnDestroy():void{
    this.Subscription.unsubscribe()
  }

  lower_level_unit:any[] = []

  preFormatOffice(office:any){

    return {
      name: office.name,
      title: office.type,
      cssClass: 'ngx-org',
      image: '/assets/Assets/Department.png',
      childs: this.preFormatChildOffice(office.children)
    }



  }

  preFormatChildOffice(childOffice:any[]) {

    let temp:any =  childOffice.map((item:any,index: number)=>{
        return this.preFormatOffice(item)
    })

    return temp

  }

  gridView:boolean =true;


  loadoffices(){

    this.afs
    .collection('Agency Office Hierarchy', 
    filter => filter.where('fromAgencyId','==',this.agency_id)
    .orderBy('createdAt','asc'))
    .snapshotChanges()
    .pipe(tap((data:any)=>{
      this.dataSource = []
      this.lower_level_unit = []
      data.forEach((info:any)=>{

          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;
          item.value = 10
          item.alignment='vertical'
          item.officeFormat = [this.preFormatOffice(item.office)]


          if (this.isLowerLevel){
            if(item?.isLowerLevelUnit){
                this.lower_level_unit.push(item)
            }

          }else{
            if(!item?.isLowerLevelUnit){
              this.dataSource.push(item)
          }
          }
      })
      

    })).subscribe()


  }

  org_view:any[]



  get offices(){
    if(this.isLowerLevel) return this.lower_level_unit;
    else return this.dataSource
}

  addNode(islowerlevelUnit:boolean =false){
    this.dialog.open(ArtemisOfficeControlComponent, {
    }).afterClosed().subscribe((value:any)=>{
      if(value.submitFlag){
        this.afs.collection("Agency Office Hierarchy")
        .add({
          fromAgencyId : this.agency_id,
          isLowerLevelUnit: this.isLowerLevel ? true : false,
          office: value?.office || [],
          createdAt: new Date()
        }).then(()=>{
          this.notif.displayNotif('success', this.isLowerLevel? 'Lower level office added ' : '' + "Success")
        })
      }
    });

  }

  chartOptions = {
    nodeAlign: 'BOTTOM',
    orientation: 'TB',
    margin: { top: 20, left: 20, right: 20, bottom: 20 },
    direction: 'LR',
    scale: 1 // Set initial scale
  };

  transformOrigin = [0.5, 0];
  value:number =10

  setZoom(zoom: number, el: HTMLElement) {
    const p = ["webkit", "moz", "ms", "o"];
    const s = `scale(${zoom})`;
    const oString = `${this.transformOrigin[0] * 100}% ${this.transformOrigin[1] * 100}%`;


    for (const prefix of p) {
      (el.style as any)[`${prefix}Transform`] = s;
      (el.style as any)[`${prefix}TransformOrigin`] = oString;
    }

    el.style.transform = s;
    el.style.transformOrigin = oString;
  }

  showVal(a: any,element:any) {
    //console.log("Element data",element)
    const zoomScale = Number(a.target.value) / 10;
    const containerElement = document.getElementsByClassName(element)[0] as HTMLElement;
    this.setZoom(zoomScale, containerElement);
  }

//setZoom(5,document.getElementsByClassName('container')[0]);




}


