import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree, Router, } from '@angular/router';
import { Observable } from 'rxjs';
import { verify } from 'crypto';
import { emailVerified } from '@angular/fire/auth-guard';
import { AngularFireAuth } from '@angular/fire/auth';
import { ARTEMIS, PBRIS } from '../entities/constant';

@Injectable({
  providedIn: 'root'
})
export class LoginAuthGuard implements CanActivate, CanActivateChild {
  constructor(
    public router: Router,
    public auth: AngularFireAuth,
  ){ }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree{

      return new Promise((res,rej)=>{
        this.auth.authState.subscribe({
          next:(result)=>{
            // console.log('result: ',result)
            if(result){
              const userType = sessionStorage.getItem("user_type")
              if(userType == 'personal' && result.emailVerified == false){
                return res(true)
              }else{
                const currentSystem = sessionStorage.getItem('currentSystem')
                if(currentSystem == PBRIS) this.router.navigate(['/pbris'])
                else if(currentSystem == ARTEMIS) this.router.navigate(['/artemis/charter'])
                return res(false)
              }
            }else return res(true)
          },
          error:(err)=>{
            return res(true)
          }
        })
      })
    //   return true
    //   .then(async(user:any)=>{
    //   })
    //   .catch((err)=>{
    //     return true
    //   })
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  
}
