<div class="{{ style_theme }} pt-5">
  <!-- PBRIS title with search Bar -->
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-7 col-lg-7 align-content-start mt-5">
        <img src="/assets/Assets/pbris_logo/PBRIS Logo White - Header x 100.png" class="img-fluid" alt="ARTA - PBRIS" style="max-width: 45% !important;" />
      </div>
      <!-- <div class="col-sm-12 col-md-7 col-lg-7 align-content-start mt-5 mb-4" *ngIf="userType === 'agency'">
        <img src="/assets/Assets/pbris_logo/PBRIS Long Colored-cropped.png" height="100" alt="ARTA - PBRIS"/></div>-->

      <div class="col-sm-12 col-md-5 col-lg-5 mb-3 align-content-end d-flex flex-column">
        <p class="d-flex w-auto mt-5 mb-1" style="font-size:14px;color:#FFFFFF;">Are you looking for regulations?</p>
        <!-- <p *ngIf="userType === 'agency'" class="d-flex w-auto mt-5 mb-1" style="font-size:14px;color:#000000;">Are you looking for regulations?</p> -->
        <!-- Search form -->
        <form class="d-flex input-group w-auto mb-4" [formGroup]="searchform" (submit)="search()">
            
          <input type="search" class="form-control"
            placeholder="Enter keywords here.."
            aria-label="Search Regulations" formControlName="searchfield"/>

          <button class="btn btn-light" data-mdb-ripple-color="dark">
            <i class="fas fa-search"></i>
          </button>
        </form>

        <div class="d-flex justify-content-end">
          <div class="btn-group flex-shrink">
            <a href="/pbris/regulations/proposed" class="btn btn-light" title="Proposed Regulation" hidden>
              <img src="/assets/Assets/Icons/Proposed%20Regulation.png" height="36" alt="Proposed Regulation"/>
            </a>
            <a href="/pbris/regulations/existing" class="btn btn-light" title="Existing Regulation" hidden>
              <img src="/assets/Assets/Icons/Existing%20Regulation.png" height="36" alt="Existing Regulation"/>
            </a>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>