<app-artemis-navigation-header
  [currentPage]="''"
></app-artemis-navigation-header>

<!-- Agency List -->
<div class="d-flex justify-content-center align-self-center">
    <div class="spinner-grow" role="status" *ngIf="loader">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<div class="container"  *ngIf="!loader">
    <!-- Title -->
    <div class="row p-3">
        <div class="col-12">
            <div class="row"  ><!-- here for form name and abb-->
                <div class="col-8 relative hover-parent">
                    <div class=" d-flex flex-row">
                        <div class="p-2 centering" style="width:100%">
                            <h2 class="{{ style_theme }}-text report-title fw-bold" > 
                                <!--  class="d-flex flex-row" -->
                                <p class="wrapper">
                                    <span *ngIf="agency_abbreviation" style="margin-right:1em;">({{agency_abbreviation}})</span>
                                    <span *ngIf="agency_name">{{agency_name}}</span>
                                </p>
                            </h2>
                        </div>                            
                    </div>    
                    
                </div>

                <div class="col-4 relative hover-parent">
                    <div >
                        <a [href]="getAgencyFullURL()" target="_blank" class="{{ style_theme }}-text report-title fw-bold" style="float: right; text-align: right;">
                            <span >Visit Agency Website: 
                                <h2 >
                                    <i class="fas fa-globe"></i>
                                </h2> 
                            </span>
                        </a>
                        <!-- <a *ngIf="isAdmin || isAgencyMember"
                            (click)="toggleEdit('website')"
                            style="margin-right:1em; float: right; z-index: 100; cursor: pointer;"
                            class="{{ style_theme }}-text report-title fw-bold"
                            title="Edit Agency Website URL">
                            <i class="fas fa-edit"></i>
                        </a> -->

                    </div>
                    
                </div>
            </div>

            <div class="relative hover-parent">
                <p class="border border-dark"></p>
                <div class="row">
                
                    <div class="col">
                        <!-- <p>{{agencyAddressForm.value.addressLine+" "+agencyAddressForm.value.barangay+" "+ agencyAddressForm.value.city+" "+agencyAddressForm.value.province+", "+agencyAddressForm.value.postal}}</p> -->
                        <p>
                            {{agency_address}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row my-4">
        <!-- Tabs navs -->
        <div style="padding:1rem;">
            <ul class="nav nav-tabs mb-3" id="ex1" role="tablist" style="border-bottom: 2px solid #eceef1;">
                <li class="nav-item" role="presentation">
                <a
                    class="nav-link active"
                    id="ex1-tab-5"
                    data-mdb-toggle="tab"
                    href="#ex1-tabs-5"
                    role="tab"
                    aria-controls="ex1-tabs-5"
                    aria-selected="true"
                    >Citizen's Charter</a
                >
                </li>
                <li class="nav-item" role="presentation">
                <a
                    class="nav-link"
                    id="ex1-tab-1"
                    data-mdb-toggle="tab"
                    href="#ex1-tabs-1"
                    role="tab"
                    aria-controls="ex1-tabs-1"
                    aria-selected="false"
                    >Services</a
                >
                </li>
                <li class="nav-item" role="presentation">
                <a
                    class="nav-link"
                    id="ex1-tab-2"
                    data-mdb-toggle="tab"
                    href="#ex1-tabs-2"
                    role="tab"
                    aria-controls="ex1-tabs-2"
                    aria-selected="false"
                    >Details</a
                >
                </li>
                <li class="nav-item" role="presentation">
                <a
                    class="nav-link"
                    id="ex1-tab-3"
                    data-mdb-toggle="tab"
                    href="#ex1-tabs-3"
                    role="tab"
                    aria-controls="ex1-tabs-3"
                    aria-selected="false"
                    >Cart</a
                >
                </li>
                <li class="nav-item" role="presentation">
                <a
                    class="nav-link"
                    id="ex1-tab-4"
                    data-mdb-toggle="tab"
                    href="#ex1-tabs-4"
                    role="tab"
                    aria-controls="ex1-tabs-4"
                    aria-selected="false"
                    >Feedback & Complaints Mechanism</a
                >
                </li>
            
            </ul>
        </div>
        <!-- Tabs navs -->
        
        <!-- Tabs content -->
        <div class="tab-content" id="ex1-content">
            <div class="tab-pane fade show active" id="ex1-tabs-5" role="tabpanel" aria-labelledby="ex1-tabs-5">
                <app-view-charter-public-cc [fileURL]="fileURL"></app-view-charter-public-cc>
            </div>
            <div class="tab-pane fade" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tabs-1">
                <app-view-charter-public-services [services]="services" [agency_details]="agency_details"></app-view-charter-public-services>
            </div>
            <div class="tab-pane fade" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                <app-view-charter-public-details [agency_details]="agency_details" [charter_list]="charter_list" ></app-view-charter-public-details>
            </div>

            <div class="tab-pane fade" id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
                <app-view-charter-public-staffs [charter_list]="charter_list" [agency_details]="agency_details"></app-view-charter-public-staffs>
            </div>

            <div class="tab-pane fade" id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4">
                <app-view-charter-public-help-details [agency_details]="agency_details"></app-view-charter-public-help-details>
            </div>

        </div>
        <!-- Tabs content -->
    </div>
</div>