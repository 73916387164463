import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as admin from 'firebase-admin';
import { GovernmentAccountSubtype } from '../entities/worklist';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  cred: any;
  users:any[] = [];
  toggleAll: boolean = false;
  triggerAll: boolean = false;
  entries: any = [];
  loader: boolean = true;
  enum_govt_account_subtype = GovernmentAccountSubtype;

  public TypeOfAccount = Array<string>();

  constructor(public auth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router) { }



  ngOnInit(): void {
    this.loadUsers()
    this.TypeOfAccount = Array<string>();
  }

  filterType(type: string){
    this.TypeOfAccount = [];
    if(type === "Personal" ){
      this.TypeOfAccount.push("Private Individual");
    }
    else if(type === "Company"){
      this.TypeOfAccount.push("Organization");
    }
    else if(type === "Government"){
      this.TypeOfAccount.push(GovernmentAccountSubtype.AGENCY_ENC);
      this.TypeOfAccount.push(GovernmentAccountSubtype.AGENCY_VER);
      this.TypeOfAccount.push(GovernmentAccountSubtype.AGENCY_DIR);
      
      this.TypeOfAccount.push(GovernmentAccountSubtype.ARTA_CMEO_PO);
      this.TypeOfAccount.push(GovernmentAccountSubtype.ARTA_CMEO_DC);
      this.TypeOfAccount.push(GovernmentAccountSubtype.ARTA_CMEO_DIR);
      this.TypeOfAccount.push(GovernmentAccountSubtype.ARTA_BRO_PO);
      this.TypeOfAccount.push(GovernmentAccountSubtype.ARTA_BRO_DC);
      this.TypeOfAccount.push(GovernmentAccountSubtype.ARTA_BRO_DIR);

      this.TypeOfAccount.push(GovernmentAccountSubtype.ARTA_ADMIN);
      this.TypeOfAccount.push(GovernmentAccountSubtype.ARTA_DDG);
      this.TypeOfAccount.push(GovernmentAccountSubtype.ARTA_DG);

    }

    return this.TypeOfAccount;
  }

  async updateSubType(entry:any, option: any){
    let pbris:any;
    let artemis: any;

    if(option === GovernmentAccountSubtype.ARTA_ADMIN){
       pbris = true
       artemis = true
    }

    else{
      pbris = entry.credentials.access_pbris
      artemis = entry.credentials.access_artemis
    }

    await this.afs.doc(`Users/${entry.id}`).update({
      credentials:{
        access_artemis: artemis,
        access_pbris: pbris,
        account_type: entry.credentials.account_type,
        account_subtype: option,
        email: entry.credentials.email,
        loggedIn: entry.credentials.loggedIn,
        mobile_number: entry.credentials.mobile_number,
        sinceMember: entry.credentials.sinceMember,
        uid: entry.credentials.uid,
        
      }
     }).then(() => {
       alert("User subtype updated!")
     }).catch(error =>{
       alert(error.message)
     });
  }

  triggerToggleAll(event: any){
    this.entries = []
    if(event.target.checked){
      this.toggleAll = true
      this.users.forEach((users:any )=>{
        this.entries.push(users)
      })
    }
    else{
      this.toggleAll = false
      this.entries = []
    }
    //console.log(this.entries)
  }

  checkCheckBoxvalue(event:any, entry:any){
    if(event.target.checked){
      this.entries.push(entry)
      if(this.entries.length < 4){
        this.triggerAll = false;
      }
      else{
        this.triggerAll = true;
      }
    }
    else{
      this.entries.pop(entry)
      this.toggleAll = false
    }
    //console.log(this.entries.length)
  }

  checkCheckBoxSelected(entry:any){
    if(this.entries.indexOf(entry) == -1)
      return false;
    else
      return true;
  }

  loadUsers(){
    this.auth.user.subscribe(data=>{
      this.afs.doc(`Users/${data?.uid}`).get().subscribe((cred:any) =>{
        this.cred = cred.data()
        //console.log(this.cred.credentials.account_subtype)
        //if(this.cred.credentials.account_subtype === "ARTA - IT/Admin"){
          this.afs.collection(`Users`, filter => filter.orderBy('credentials.sinceMember', "asc")).snapshotChanges().subscribe(
            (data: any) => {
              this.loader = false;
              this.users = []
                data.forEach((info: any) => {  
                  let item: any = info.payload.doc.data();
                  item.id = info.payload.doc.id;
        
                  if (this.users.includes(item) === false){
                    this.users.push(item);
                  }     
                });
            });
          
        //}
        //else{
          //this.router.navigate(['/restricted']);
        //}
      })
    })

    
  }

  setARTAccessibility(entry:any, status: boolean){
    let pbris = entry.credentials.access_artemis
    let artemis = status

    this.afs.doc(`Users/${entry.id}`).update({
     credentials:{
       access_artemis: status,
       access_pbris: entry.credentials.access_pbris,
       account_type: entry.credentials.account_type,
       account_subtype: entry.credentials.account_subtype,
       email: entry.credentials.email,
       loggedIn: entry.credentials.loggedIn,
       mobile_number: entry.credentials.mobile_number,
       sinceMember: entry.credentials.sinceMember,
       uid: entry.credentials.uid,
       
     }
    }).then(() => {
    }).catch(error =>{
      alert(error.message)
    });
  }

  setPBRAccessibility(entry:any, status: boolean){
    let pbris = status
    let artemis = entry.credentials.access_artemis
    this.afs.doc(`Users/${entry.id}`).update({
      credentials:{
        access_pbris: pbris,
        access_artemis: artemis,
        account_type: entry.credentials.account_type,
        account_subtype: entry.credentials.account_subtype,
        email: entry.credentials.email,
        loggedIn: entry.credentials.loggedIn,
        mobile_number: entry.credentials.mobile_number,
        sinceMember: entry.credentials.sinceMember,
        uid: entry.credentials.uid,
        
      }
    }).then(() => {
    }).catch(error =>{
      alert(error.message)
    });
  }

  setAllAccessibility(entry:any, status: boolean){
    this.afs.doc(`Users/${entry.id}`).update({
      credentials:{
        access_pbris: status,
        access_artemis: status,
        account_type: entry.credentials.account_type,
        account_subtype: entry.credentials.account_subtype,
        email: entry.credentials.email,
        loggedIn: entry.credentials.loggedIn,
        mobile_number: entry.credentials.mobile_number,
        sinceMember: entry.credentials.sinceMember,
        uid: entry.credentials.uid,
        
      }
    }).then(() => {
      this.loadUsers()
    }).catch(error =>{
      alert(error.message)
    });
  }

  multPBRIS(status: boolean){
    this.entries.forEach((entry:any) =>{
      //console.log(entry)
      this.setPBRAccessibility(entry, status)
    })
    this.entries = [];
  }

  multARTEMIS(status: boolean){
    this.entries.forEach((entry:any) =>{
      this.setARTAccessibility(entry, status)
    })
    this.entries = [];
  }

  multAll(status: boolean){
    this.entries.forEach((entry:any) =>{
      this.setAllAccessibility(entry, status)
    })
    this.entries = [];
  }

  multDelete(){
    this.entries.forEach((entry:any) => {
      this.afs.doc(`Users/${entry.id}`).delete().then((data:any) => {
        alert("Remove successful")
      })
    })
    this.entries = []
  }

}
