import { Component, OnChanges, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-charter-public-cc',
  templateUrl: './view-charter-public-cc.component.html',
  styleUrls: ['./view-charter-public-cc.component.scss']
})
export class ViewCharterPublicCComponent implements OnInit {
  @Input() fileURL:any;

  constructor() { }

  ngOnInit(): void {

  }

}
