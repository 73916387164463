<div class="container">
    <div
          class="ms-3 mt-1 p-1 mb-3"
          style="border: 1px dotted #b1b1b1; border-radius: 7px"
        >
          <h6 class="card-title text-bold">
            <i class="fas fa-user-circle"></i> {{ temp_obj.user.name }}
          </h6>
          <div class="remark-date">
            {{ temp_obj.date?.toDate() | date : "MMMM dd, yyyy HH:mm:ss" }}
            | {{ temp_obj.user.position }}
          </div>
          <p style="margin-left: 20px; margin-bottom: 1px; font-weight: 400">
            {{ temp_obj.message }}
          </p>
        </div>

        <form class="ms-3" [formGroup]="form">
    
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Message</mat-label>
                <textarea rows="10" required matInput formControlName="message" ></textarea>
             
              </mat-form-field>
        
        </form>

        <mat-card-actions align="end">
            <button mat-button mat-dialog-close>Cancel</button>
            <button mat-flat-button color="primary" (click)="editMessage()" > Save Changes 
                   </button>
        </mat-card-actions>
</div>