import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-create-sector',
  templateUrl: './create-sector.component.html',
  styleUrls: ['./create-sector.component.scss']
})
export class CreateSectorComponent implements OnInit {
  method!: string;
  collection!: string;
  id?:any;
  show_form: boolean = false;
  del_confirm: boolean = false;
  
  form = new FormGroup({
    section: new FormControl('', Validators.required),
    sector_name: new FormControl('', Validators.required),
    sector_def: new FormControl('')
  });
  
  title: string = "";

  constructor(private afs: AngularFirestore, private router: Router,
    public modalRef: MDBModalRef) {
  }

  ngOnInit(): void {
    this.title = this.method.charAt(0).toUpperCase() + this.method.slice(1) + " " + this.collection;

   if(this.method === "create"){
    this.show_forms();
   }
   if(this.method === "update"){
    this.show_forms();

    this.afs.doc(`${this.collection}/${this.id}`).get().subscribe(data => {
      this.form.patchValue({
        section: data.get('section'),
        sector_name: data.get('name'),
        sector_def: data.get('definition'),
      })
    })
   }
   else if(this.method === "delete"){
    this.show_form = false;
    this.del_confirm = true;
   }
  }

  show_forms(){
    this.show_form = true;
    this.del_confirm = false;
  }

  delete(){
    this.afs.doc(`${this.collection}/${this.id}`).delete().then((data => {
      alert("One of your " + this.method + " has been deleted");
      this.modalRef.hide()
    }));
  }

  update(){
    let section = this.form.value.section;
    let name = this.form.value.sector_name;
    let definition = this.form.value.sector_def;

    this.afs.doc(`${this.collection}/${this.id}`).update({
      section, name, definition
    }).then(() => {
      alert(this.title + " successful")
      this.modalRef.hide();
    }).catch(error =>{
      alert(error.message)
    });
  }

  create(){
    let section = this.form.value.section;
    let name = this.form.value.sector_name;
    let definition = this.form.value.sector_def;

    this.afs.collection(`${this.collection}`).add({
      section, name, definition
    }).then(() => {
      alert(this.title + " successful")
      this.modalRef.hide();
    }).catch(error =>{
      alert(error.message)
    })
  }

}
