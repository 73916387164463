<div class="mx-auto">
    <table class = "table table-bordered">
        <thead class = "table table-dark text-center">
            <tr>
                <th colspan = "2">SUMMARY REPORT  - PBRIS TICKETS</th>
            </tr>
            <tr>
                <!-- <th colspan = "2">(October 1 - 30, 2020)</th> -->
                <th colspan = "2">
                    <app-date-picker 
                    (datePickerEvent) = "receivedDatePicker($event)"
                    ></app-date-picker>{{dateInfo}}</th>              
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class = "table-warning">TOTAL COUNT RECEIVED:</td>
                <td class = "h3">{{ticket_count.length}}</td>
            </tr>
            <!-- PROCESSED -->
          <tr>
            <td colspan = "4">
                <table class = "table table-bordered mb-0">
                    
                        <thead class = "table table-dark">
                            <tr>
                                <th colspan = "2">PROCESSED</th>
                                
                            </tr>                            
                        </thead>
                        <tbody>
                            <tr>
                                <td class = "table-warning">TOTAL COUNT PROCESSED:</td>
                                <td></td>
                            </tr>
                            <tr class = "table-warning">
                                <td>SYSTEM ISSUE</td> 
                                <td>AVERAGE PROCESSING TIME</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr class = "table-warning">
                                <td>TECHINCAL ISSUE</td>
                                <td>AVERAGE PROCESSING TIME</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                        
                   </table>
               </td>
          </tr>

                      <!-- PENDING -->
                      <tr>
                        <td colspan = "4">
                            <table class = "table table-bordered mb-0">
                                
                                    <thead class = "table table-dark">
                                        <tr>
                                            <th colspan = "2">PENDING</th>
                                            
                                        </tr>
                                        
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class = "table-warning">COUNT PENDING:</td>
                                            <td></td>
                                        </tr>
                                        <tr class = "table-warning">
                                            <td>SYSTEM ISSUE</td> 
                                            <td>TOTAL PENDING TIME</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr class = "table-warning">
                                            <td>TECHINCAL ISSUE</td>
                                            <td>TOTAL PENDING TIME</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                    
                               </table>
                           </td>
                      </tr>
            

        </tbody>
        
    </table>
</div>