<app-navbar [currentPage]="'Consultation'"></app-navbar>

<div *ngIf="!loading">
    <div class="row" >
        <h5 class="fw-bold text-white p-3 fw-bold {{style_theme}}" ><span class="ps-3">CONSULTATIONS</span></h5>
    </div>

    <div class="row padded">
        <div class="col-md-4 col-lg-3 side-panel"> 
            <ul class="list-unstyled " *ngFor="let filter of filterList">
                <li class="row m-2 border-bottom side-filter" (click)="setFilter(filter.value)" 
                [ngClass]="chosenFilter == filter.value? 'active':''">
                    <span class="col-2 side-icon"><i class="{{filter.icon}}"></i></span>
                    <span class="col-6">{{filter.name}}</span>
                    <span class="col-4 counter">{{filterCounter(filter.value)}}</span>
                </li>
            </ul>
        </div>
        
        <div class="col-md-8 col-lg-9 main-panel p-2"> 
            <div class="" >                
                <table mat-table class="table table-hover table-responsive" id="matTable" multiTemplateDataRows [dataSource]="consultationTabledataSource" matSort>
                    <ng-container matColumnDef="rowId">
                        <th mat-header-cell *matHeaderCellDef> 
                            <ul  [formGroup]="filtersForm" class="list-unstyled table-header w-100">
                                <li>
                                    <div class="row">
                                        <div class="col-5">
                                            <div class="row">
                                                <h6 class="col-2 d-flex align-items-center justify-content-end search">Search</h6>                                                
                                            
                                                <div class="col-10">
                                                    <mat-form-field appearance="outline" class="w-100 px-2">
                                                        <!-- <mat-label>DTI Case No.</mat-label> -->
                                                        <input matInput type="text" (keyup)="search($event)"  formControlName="search" style="font-size: 18px;">
                                                    </mat-form-field>
                                                    <!-- <input class="form-control form-control-lg" (keyup)="search($event)" formControlName="search" type="text" placeholder="Search here"> -->
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-3">
                                            <div class="row">
                                                <h6 class="col-2 d-flex align-items-center justify-content-end search">Type</h6>                                                
                                            
                                                <div class="col-10">
                                                    <mat-form-field appearance="outline" class="w-100 px-2">
                                                        <!-- <mat-label> Filter {{filter.name}} </mat-label> -->
                                                        <mat-select formControlName="select_type"
                                                            (selectionChange)="filterSelection($event.value)"> 
                                                            <mat-option [value]="item.value" *ngFor="let item of consultationType">{{item.name}}</mat-option>
                                                            </mat-select >
                                                    </mat-form-field>                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-4">
                                            
                                           <div class="row">
                                                <span class="col-md-2 col-lg-2 d-flex align-items-center justify-content-end"  style="font-size: 2rem;"><i class="far fa-calendar" ></i></span>
                                            
                                                <div class="col-md-10 col-lg-10">
                                                    <mat-form-field appearance="outline" class="w-100" >
                                                        <form formGroupName="range">
                                                            <mat-date-range-input  [rangePicker]="pickedDate">
                                                                <input readonly matInput matStartDate formControlName="sDate" style="font-size: 15px;">
                                                                <input readonly matInput matEndDate  formControlName="eDate" style="font-size: 15px;">
                                                            </mat-date-range-input>
                                                        </form>
                                                        <mat-datepicker-toggle matSuffix [for]="pickedDate"></mat-datepicker-toggle>
                                                        <mat-date-range-picker #pickedDate>
                                                            <mat-date-range-picker-actions>
                                                                <button mat-button matDateRangePickerCancel>Cancel</button>
                                                                <button mat-raised-button color="secondary" (click)="refreshDatePicker()" matDateRangePickerCancel>Refresh</button>
                                                                <button mat-raised-button color="primary" matDateRangePickerApply (click)="applyDateRange()">Apply</button>
                                                            </mat-date-range-picker-actions>
                                                        </mat-date-range-picker>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </th>
                        <td mat-cell *matCellDef="let element" (click)="toPage('to-consultation',element,$event)">
                            <div class="py-3">
                                <div>
                                    <a class="row align-items-center p-2">
                                        <div class="col-md-2 col-lg-2 d-flex justify-content-center">      
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <a><img _ngcontent-dlr-c277="" src="/assets/Assets/Icons/Proposed%20Regulation.png" alt="Existing Regulation"></a> 
                                                </div>  
                                                <!-- <div class="col-lg-6">
                                                    <a><img _ngcontent-dlr-c277="" src="/assets/Assets/Icons/Proposed%20Regulation.png" alt="Existing Regulation"></a>   
                                                </div>-->
                                            </div>    
                                            <!-- <div class="row">
                                                <a class="col-6">
                                                    <img _ngcontent-dlr-c277="" src="/assets/Assets/Icons/Proposed%20Regulation.png" height="80" width="80" alt="Existing Regulation">
                                                </a>
                                                
                                                <a class="col-6">
                                                    <img _ngcontent-dlr-c277="" src="/assets/Assets/Icons/Proposed%20Regulation.png" height="80" width="80" alt="Existing Regulation">
                                                </a>
                                            </div>-->
                                        </div>

                                        <div class="col-md-8 col-lg-7" style="cursor:pointer;">
                                            <ul  class="list-unstyled">
                                                <li>
                                                    <a (click)="toPage('to-regulation',element,$event)"><h6>{{element.regulation_title}}</h6></a>
                                                </li>
                                                <!-- <li>
                                                    <strong>{{element.id}}</strong>
                                                </li> -->
                                                <li>
                                                    Sector/Stage of Life: <b>{{element.life_status}}</b>
                                                </li>
                                                <li>
                                                    Duration of Consultation: <b>{{element.formated_from_date}} to {{element.formated_to_date}} </b>
                                                </li>
                                            </ul>
                                        </div>
                    
                                        <div class="col-md-2 col-lg-3 interactables justify-content-center">
                                            <div class="row">
                                                <ng-container *ngIf="element.type == consultationTypes.ONLINE_COMMENTING && (userAgency || isAdmin)">
                                                    <a class="col-lg-3" (click)="setActive(element.id,'view_list',element, $event) " 
                                                        matTooltip="View all comments">
                                                        <!-- <div *ngIf="element.type == 'Online Commenting'"> -->
                                                            <i class="far fa-comments"  [ngClass]="{'active': element.expand_comment_list}"></i>
                                                        <!-- </div> -->
                                                    </a>
                                                    <a class="col-lg-3" (click)="setActive(element.id,'create',element, $event);" 
                                                        matTooltip="Submit a comment">
                                                        
                                                        <!-- <div *ngIf="element.type == 'Online Commenting'">                                                         -->
                                                            <i class="far fa-comment-dots" [ngClass]="{'active': element.expand_comment_create}"></i>
                                                        <!-- </div> -->
                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="element.type == consultationTypes.FOCUS_GROUP_DISCUSSION && (userAgency || isAdmin)">
                                                    <a class="col-lg-3" matTooltip="Go to Focus Group Discussion" [href]="element.link" target="_blank">
                                                        <i class="fas fa-users"></i>
                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="element.type == consultationTypes.SURVERY && (userAgency || isAdmin)">
                                                    <a class="col-lg-3" matTooltip="Go to Survey" [href]="element.link" target="_blank">
                                                        <i class="fas fa-list-ol"></i>
                                                    </a>
                                                </ng-container>
                                                <!-- <a class="col-lg-3" (click)="setActive(element.id,'view_list',element, $event) " 
                                                    matTooltip="View all comments">
                                                    <div *ngIf="element.type == 'Online Commenting'">
                                                        <i class="far fa-comments"  [ngClass]="{'active': element.expand_comment_list}"></i>
                                                    </div>
                                                </a>
                                                <a class="col-lg-3" (click)="setActive(element.id,'create',element, $event);" 
                                                    matTooltip="Submit a comment">
                                                    
                                                    <div *ngIf="element.type == 'Online Commenting'">                                                        
                                                        <i class="far fa-comment-dots" [ngClass]="{'active': element.expand_comment_create}"></i>
                                                    </div>
                                                </a> -->
                                                <a class="col-lg-3" *ngIf="userAgency || isAdmin" (click)=" setActive(element.id,'pin',element, $event); " 
                                                    matTooltip="Bookmark this consultation">
                                                    <i class="far fa-bookmark" [ngClass]="{'active': element.status}"></i>
                                                </a>
                                                <a class="col-lg-3">
                                                    <i class="far fa-share-square"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <!-- <p>{{element.rowId}}</p> -->
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="consultationTabledataSource.length">
                            <div class="row expanded-detail"
                                [@detailExpand]="element == expandedCommentCreate ? 'expanded' : 'collapsed'">
                                <div *ngIf="element == expandedCommentCreate" class="row w-100 p-3">
                                    <app-pbris-comment-create class="z150" *ngIf="expandedCommentCreate != null" 
                                    [data_catch_id]="cosultation_id" 
                                    [data_catch]="this_consultation"></app-pbris-comment-create>

                                </div>
                            </div>

                            <div class="expanded-detail"
                            [@detailExpand]="element == expandedCommentView ? 'expanded' : 'collapsed'">
                           
                                <div *ngIf="element == expandedCommentView" class="row w-100 p-3">
                                    <app-pbris-comment-view *ngIf="expandedCommentView"
                                    [view]="'consultation list'" 
                                    [consultation_id]="cosultation_id"></app-pbris-comment-view>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="consultationTableDisplayCol; sticky: true"></tr>
                    <tr mat-row matRipple *matRowDef="let row; columns: consultationTableDisplayCol;" 
                        [ngClass]="expandedCommentCreate == row || expandedCommentView == row? 'row-active' :''"
                         ></tr> 
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>                   
                </table>
                <mat-paginator [pageSizeOptions]="[5, 10, 25]" aria-label="Select page of users"></mat-paginator>
                
            </div>
        </div>
    </div>
</div>

<!-- <div class="content">
    <div class="container-fluid p-4">
        <div class="card shadow my-3">
            <div class="card-header text-primary theme-default">
                <h2 class="fw-bold">Consultation</h2>
            </div>
            <div class="row px">
                <div class="col-xs-12 col-sm-12 col-md-6">

                    <table mat-table class="table table-hover table-responsive" id="matTable" [dataSource]="consultationTabledataSource" matSort>

                        <ng-container matColumnDef="rowId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>#</h5>  </th>
                            <td mat-cell *matCellDef="let element">
                                <div style="padding:0.3">
                                    <p>{{element.rowId}}</p>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Type</h5> </th>
                            <td mat-cell *matCellDef="let element">
                                <div style="padding:0.3">
                                    <p>{{element.type}}</p>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="purpose">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Purpose</h5> </th>
                            <td mat-cell *matCellDef="let element">
                                <div style="padding:0.3">
                                    <p>{{element.purpose}}</p>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="formated_from_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Start Date</h5> </th>
                            <td mat-cell *matCellDef="let element">
                                <div style="padding:0.3">
                                    <p>{{element.formated_from_date}}</p>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="formated_to_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>End Date</h5> </th>
                            <td mat-cell *matCellDef="let element">
                                <div style="padding:0.3">
                                    <p>{{element.formated_to_date}}</p>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="consultationTableDisplayCol; sticky: true"></tr>
                        </table>
                    <mat-paginator [pageSizeOptions]="[5, 10, 25]" aria-label="Select page of users"></mat-paginator>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 card shadow info-corner sticky sticky-info info-col">
                    <form [formGroup]="filtersForm">
                        <div class="row info-search sticky sticky-search form-group align-items-center">
                            <div class="col-1">
                                <label>Search</label>
                            </div>
                            <div class="col row">
                                <div class="col-7 d-flex align-items-center">
                                    <input class="form-control" (keyup)="search($event)" formControlName="search" type="text">
                                </div>
                                   <mat-form-field class="col-5" style="padding:0.5em" >
                                        <form formGroupName="range">
                                            <mat-date-range-input  [rangePicker]="pickedDate">
                                                <input readonly matInput matStartDate formControlName="sDate">
                                                <input readonly matInput matEndDate  formControlName="eDate">
                                            </mat-date-range-input>
                                        </form>
                                        <mat-datepicker-toggle matSuffix [for]="pickedDate"></mat-datepicker-toggle>
                                        <mat-date-range-picker #pickedDate>
                                            <mat-date-range-picker-actions>
                                                <button mat-button matDateRangePickerCancel>Cancel</button>
                                                <button mat-raised-button color="secondary" (click)="refreshDatePicker()" matDateRangePickerCancel>Refresh</button>
                                                <button mat-raised-button color="primary" matDateRangePickerApply (click)="applyDateRange()">Apply</button>
                                            </mat-date-range-picker-actions>
                                        </mat-date-range-picker>
                                    </mat-form-field>
                                </div>
                            <div class="col-3">
                                <select class="form-select" #filter_select (change)="changeFilter(filter_select.value)">
                                    <option value="defualt">No Filter</option>
                                    <option value="type">Type</option>
                                    <option value="purpose">Purpose</option>
                                    <option value="date">Date</option>
                                </select>
                            </div>
                        </div>
                    </form>

                    <ul class="nav nav-tabs nav-fill mb-3" id="myTab0" role="tablist">
                        <li class="nav-item" role="presentation">
                                <button
                                class="nav-link active"
                                id="details-tab"
                                data-mdb-toggle="tab"
                                data-mdb-target="#details"
                                type="button"
                                role="tab"
                                aria-controls="details"
                                aria-selected="true"
                                > Details </button>    
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                            class="nav-link"
                            id="submit-tab"
                            data-mdb-toggle="tab"
                            data-mdb-target="#submit"
                            type="button"
                            role="tab"
                            aria-controls="submit"
                            aria-selected="false"
                            > Submit a comment </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="comments-tab"
                                data-mdb-toggle="tab"
                                data-mdb-target="#comments"
                                type="button"
                                role="tab"
                                aria-controls="comments"
                                aria-selected="false"
                            > Your Comments </button>
                        </li>
                    </ul>
                    <div class="overauto tab-content" id="consultationDetails">
                        <div class="tab-pane fade show active" id="details" role="tabpanel" aria-labelledby="details-tab">
                            
                            <div class="info-details">
                                <div class="card-header border-bottom border-4 border-dark">
                                    <h4 class="fw-bold">Consultation Details</h4>
                                </div>
                                <div class="info-details-content">

                                    <div class="row info-details-content">
                                        <div class="col-3">
                                            <label>Regulation Title</label>
                                        </div>
                                        <div class="col-1">
                                            <label>:</label>
                                        </div>
                                        <div class="col">
                                            <p>{{chosenConsultation.regulation_title}}</p>
                                        </div>
                                    </div>

                                    <div class="row info-details-content">
                                        <div class="col-3">
                                            <label>Regulation Info</label>
                                        </div>
                                        <div class="col-1">
                                            <label>:</label>
                                        </div>
                                        <div class="col">
                                            <p>{{chosenConsultation.regulation_subj}}</p>
                                        </div>
                                    </div>

                                    <div class="row info-details-content">
                                        <div class="col-2">
                                            <label>Stakeholder</label>
                                        </div>
                                        <div class="col-1">
                                            <label>:</label>
                                        </div>
                                        <div class="col">
                                            <p>{{chosenConsultation.stakeholder}}</p>
                                        </div>
                                    </div>
        
                                    <div class="row info-details-content">
                                        <div class="col-2">
                                            <label>Type</label>
                                        </div>
                                        <div class="col-1">
                                            <label>:</label>
                                        </div>
                                        <div class="col">
                                            <p>{{chosenConsultation.type}}</p>
                                        </div>
                                    </div>
        
                                    <div class="row info-details-content">
                                        <div class="col-2">
                                            <label>Link</label>
                                        </div>
                                        <div class="col-1">
                                            <label>:</label>
                                        </div>
                                        <div class="col">
                                            <a href="{{chosenConsultation.link}}" target="_blank">{{chosenConsultation.link}}</a>
                                        </div>
                                    </div>
        
                                    <div class="row info-details-content">
                                        <div class="col-2">
                                            <label>Purpose</label>
                                        </div>
                                        <div class="col-1">
                                            <label>:</label>
                                        </div>
                                        <div class="col">
                                            <p>{{chosenConsultation.purpose}}</p>
                                        </div>
                                    </div>
        
                                    <div class="row info-details-content">
                                        <div class="col-2">
                                            <label>Date from</label>
                                        </div>
                                        <div class="col-1">
                                            <label>:</label>
                                        </div>
                                        <div class="col">
                                            <p>{{chosenConsultation.formated_from_date}}</p>
                                        </div>
                                    </div>
        
                                    <div class="row info-details-content">
                                        <div class="col-2">
                                            <label>Date to</label>
                                        </div>
                                        <div class="col-1">
                                            <label>:</label>
                                        </div>
                                        <div class="col">
                                            <p>{{chosenConsultation.formated_to_date}}</p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>

                        <div class="tab-pane fade" id="submit" role="tabpanel" aria-labelledby="submit-tab">
                            <div class="info-details">
                                <div class="card-header border-bottom border-4 border-dark">
                                    <h4 class="fw-bold">Submission Details</h4>
                                </div>
                                <div *ngIf="!consultationData.consultation_id"><h5 class="fw-bold">Choose a consultation.</h5></div>
                                <app-pbris-comment-create class="z150" *ngIf="consultationData.consultation_id" [data_catch_id]="consultationData.regulation_id" [data_catch]="consultationData"></app-pbris-comment-create>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="comments" role="tabpanel" aria-labelledby="comments-tab">
                            <div  class="info-comments">
                                <div class="card-header border-bottom border-4 border-dark d-flex">
                                    <h4 *ngIf="!isAdmin" class="fw-bold">Comments</h4>
                                    <h4 *ngIf="isAdmin" class="fw-bold">All Comments</h4>
                                </div>
                                <app-pbris-consultation-view></app-pbris-consultation-view>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
