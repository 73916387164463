import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WorklistStatusPBRISRegulation, WorklistActionFlagPBRISRegulation } from 'src/app/entities/worklist';

@Component({
  selector: 'app-regulation-view-confirmation-modal',
  templateUrl: './regulation-view-confirmation-modal.component.html',
  styleUrls: ['./regulation-view-confirmation-modal.component.scss']
})
export class RegulationViewConfirmationModalComponent implements OnInit {

  enum_regulation_status = WorklistStatusPBRISRegulation;
  enum_action_flag = WorklistActionFlagPBRISRegulation;
  currentStatus: String;
  finalWarning: boolean = false;
  private flags: any;

  constructor(
    public dialogRef: MatDialogRef<RegulationViewConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currentStatus = this.data.currentStatus;
    this.flags = this.data.flags;
  }

  ngOnInit(): void {
  }

  get forReconsideration(): boolean {
    return this.flags?.forReconsideration;
  }

  // EXISTING or PROPOSED, will come from either regulation-view-existing or regulation-view-proposed
  get regulationType(): string {
    return this.flags?.regulationType;
  }

  cancel() {
    this.dialogRef.close();
  }

  submit(actionFlag: number) {
  // final warning if CC is about to get posted, submit otherwise

    const returnRegulationEntity = {
      submitFlag: true,
      actionFlag: actionFlag,
    };

    this.dialogRef.close(returnRegulationEntity);
    
  }
}
