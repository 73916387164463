<div class="container">
    <div class="row my-5">

        <!--<div class="col-md">

            //Carousel
            <div id="carouselExampleCaptions" class="carousel slide carousel-fade" data-mdb-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-mdb-target="#carouselExampleCaptions" data-mdb-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-mdb-target="#carouselExampleCaptions" data-mdb-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-mdb-target="#carouselExampleCaptions" data-mdb-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src="/assets/01.jpg" class="d-block w-100" alt="..." />
                        <div class="carousel-caption">
                            <h1 class="text-uppercase">Article Title One</h1>
                            <h4 class="text-uppercase">First article</h4>
                            <a href="#read" class="btn btn-lg btn-rounded btn-danger">Read now</a>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="/assets/02.jpg" class="d-block w-100" alt="..." />
                        <div class="carousel-caption">
                            <h1 class="text-uppercase">Article Title Two</h1>
                            <h4 class="text-uppercase">Second article</h4>
                            <a href="#read" class="btn btn-lg btn-rounded btn-danger">Read now</a>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="/assets/03.jpg" class="d-block w-100" alt="..." />
                        <div class="carousel-caption">
                            <h1 class="text-uppercase">Article Title Three</h1>
                            <h4 class="text-uppercase">Third article</h4>
                            <a href="#read" class="btn btn-lg btn-rounded btn-danger">Read now</a>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-mdb-target="#carouselExampleCaptions" data-mdb-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-mdb-target="#carouselExampleCaptions" data-mdb-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div> -->

        <div class="col-12">

            <!-- Login Card -->
            <div class="card mb-5 text-center">
                <div class="card-header bg-gray py-4" style="color:#CE1126;">
                    <h4 class="fw-bold mb-0">Restricted Page</h4>
                </div>
                <div class="card-body bg-light py-5">
                    <div style="padding:30px;"><i class="fas fa-exclamation-triangle" style="color:#CE1126;font-size:60px;"></i></div>
                    <p class="text-justify">
                        You are not allowed to access this page.<br>You might need to contact the administrator to give you an access to this page.
                    </p>
                </div>
                <div class="card-footer bg-gray py-3">
                    <button class="btn btn-light rounded-9 mx-1" (click)="homepage()">
                        <i class="fas fa-share-square fa-flip-horizontal me-2"></i>Return to Home Page
                    </button>
                    <button class="btn btn-dark rounded-9 mx-1" (click)="logout()">
                        <i class="fas fa-sign-out-alt me-2"></i>Logout
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>