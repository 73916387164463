


<div class="row" *ngIf="page === 'login' || page === 'register'">
    <div class="col-md">
        <app-artemis-login-promotions></app-artemis-login-promotions>
    </div>
    <div class="col-sm">
        <app-artemis-login *ngIf="page === 'login'"></app-artemis-login>
        <app-artemis-register *ngIf="page === 'register'"></app-artemis-register>
    </div>
</div>



<app-artemis-home-page *ngIf="page === 'home'"></app-artemis-home-page>