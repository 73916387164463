
<div id="requirement-container" 
(mouseover)="hideButtons=true"
(mouseleave)="hideButtons=false">

  <div class="row">
        
    <div class="col ps-1">
      <label for="" class="text-bold">  <span >{{ chooseany? requirementservice.printToLetter(requirementIndex + 1) : requirementIndex + 1 }}</span>.{{ requirement.requirement }}

      </label>
      <ng-container [ngSwitch]="requirement.requirementType">
        <div class="ms-1" *ngSwitchCase="'Sample'">({{ requirement.requirementType }}: {{requirement.number}} {{requirement.unit}}) </div>
        <div class="ms-1"  *ngSwitchCase="'Documentary'" [innerHtml]="requirement.documentString"></div>
        <div class="ms-1"  *ngSwitchCase="'Id'" [innerHtml]="requirement.listOfIds"></div>
      </ng-container> 

    </div>
    <div *ngIf="requirement.requirementType != 'Id'" class="col-5 ">
        <div *ngIf="!requirement?.applicant">
        Agency : Division
        <br>
        {{ requirement.agency }} - {{ requirement.bureau_division }}

       </div>
       <div *ngIf="requirement?.applicant">
          Applicant/Client
       </div>
    
  </div>

  <div *ngIf="requirement.remarks_or_reminders" class="row ps-1 mt-1">


    <label class="remarks"><strong><i>General Remarks</i></strong></label>
    <div id="remarks" [innerHTML]="requirement.remarks_or_reminders" >
    </div>

  </div>


  <div class="d-inline-flex  button-container mt-2" >

    <button mat-button  matTooltip="Add Sub Requirement" id="situationalSub" (click)="addSituational()"> <span><i class="fas fa-plus"></i> Sub Requirement</span> </button>
    <button mat-icon-button matTooltip="Edit" (click)="updateRequirement()" > <span><i class="fas fa-edit"></i></span> <!--Edit--> </button> 
    <button mat-stroked-button  matTooltip="Paste" *ngIf="requirementservice.checkClipboard('requirement_copy')" (click)="pasteRequirement()"><span><i class="fas fa-paste"></i><!--Paste--> </span></button>
    <button mat-icon-button matTooltip="{{requirementservice.checkClipboard('requirement_copy')? 'Cancel' : 'Copy'}}" (click)="copyRequirement()"><span><i [ngClass]="requirementservice.checkClipboard('requirement_copy')? 'fas fa-ban' : 'fas fa-copy'"></i> <!--Copy--></span></button>
    <button mat-icon-button matTooltip="Remove" color="warn" (click)="removeRequirement()" ><span><i class="fas fa-trash"></i></span> <!--Remove--></button>
  </div>


  <div class="ms-1" *ngIf="requirement.subRequirement">
    <ul class="tree">
      <li  *ngFor="let situation of requirement.subRequirement;let index = index">
        <app-subsituational-requirement-control
        [situation]="situation"
        [situationIndex]="index"
        [indexName]="requirementIndex + 1"
        [fromReq] = "true"
        [referenceArray]="requirement.subRequirement"
        ></app-subsituational-requirement-control>

    </li>
    </ul>
    
    

   </div>

</div>

   
   