import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pbris-account-regulation',
  templateUrl: './pbris-account-regulation.component.html',
  styleUrls: ['./pbris-account-regulation.component.scss']
})
export class PbrisAccountRegulationComponent implements OnInit {
  @Input() data_catch: any;

  pinnedList: any = [];
  loading: boolean = false;
  user_id: any = null;

  constructor(private afs: AngularFirestore, public auth: AngularFireAuth, private router: Router,) {}

  ngOnInit(): void {
    this.getPinned();
  }

  async getPinned() {
    this.loading = true;
    this.pinnedList = []
    this.afs.collection('PBRIS Pinned Regulation', ref => ref.where('user_id', '==', this.data_catch.credentials?.uid).where('status', '==', true)).get().subscribe(data => {
      data.docs.map(res => {
        let a: any = res.data()
        let reg_id: any = res.id
        this.getRegulation(a.PRN)
            .subscribe(data => {
              data.docs.map(res => {
                let b: any = res.data();
                b.reg_id = reg_id
                b.id = res.id
                this.loading = false;
                this.pinnedList.push(b)
              })
            })
      })
    });
    this.loading = false;
  }

  getRegulation(PRN: any) 
  {
    return this.afs.collection('PBRIS 1 Regulations', ref => ref.where('PRN', '==', PRN)).get()
  }

  onReg(entryId: string){
    this.router.navigate([`/pbris/regulations/view/${entryId}`])
  }

  onRemove(data: any)
  { 
    this.afs.collection(`PBRIS Pinned Regulation`)
            .doc(data.reg_id)
            .update({status: false})
            .then((docRef:any) => {
              this.loading = false;
              this.pinnedList.find((curr:any, idx:any, arr:any) => {
                if (curr.PRN == data.PRN) {
                  this.pinnedList.splice(idx,1)
                  return arr
                } 
              })
            }).catch((error:any) =>{
              this.loading = false;
              console.log("Error",error)
              Swal.close();
              Swal.fire({
                title:'System error',
                titleText: 'Please refresh the page',
                icon: 'error'
              });
            });
  }
}
