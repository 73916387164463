<div class="container mt-5">
    <mat-card>

        <mat-card-content>
            <form [formGroup]="form">
    
                <mat-form-field appearance="outline">
                <mat-label>Identification</mat-label>
                <input formControlName="identification" matInput placeholder="Identification Card Name">
                </mat-form-field>
        
        
                <mat-form-field appearance="outline">
                <mat-label>Agency or Bureau</mat-label>
                <input formControlName="bureau_agency" matInput placeholder="Where to secure">
                </mat-form-field>
        
                <div class="form-group row mt-2">
                 
                  <mat-radio-group
                    formControlName="option"
                    class="mx-3 mt-1 bg-light py-4"
                    aria-label="Select an option"
                  >
                    <mat-radio-button color="primary" selected value="Primary ID"
                      >Primary</mat-radio-button
                    >
                    <mat-radio-button color="primary" value="Secondary ID"
                      >Secondary</mat-radio-button
                    >
                   
                  </mat-radio-group>
                   </div>
                
                
            <div class="form-group row">
                <section class="example-section">
                  <div class="col">
                    <div class="mt-3">
                      <div class="form-check form-check-inline">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="active_status"
                          value="true"
                          formControlName="active_status"
                        />
                        <label class="form-check-label" for="active_status"
                          ><strong>Active</strong></label
                        >
                      </div>
                    </div>
                  </div>
                </section>
              </div>
                
            
            </form>
        </mat-card-content>
    
        <mat-card-actions align="end">
            <button (click)="resetform()"  mat-button> Cancel</button>
            <button *ngIf="!updating" (click)="saveIdentification()" mat-button> Save</button>
            <button *ngIf="updating" (click)="update()" mat-button> Update</button>

        
        </mat-card-actions>
        
    </mat-card>
    
    
    
    
    <mat-form-field appearance="standard">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
      </mat-form-field>
      
      <div class="mat-elevation-z8">
        <table mat-table 
        [dataSource]="dataSource" 
        matSort
        #sort
        (matSortChange)="sortChange($event)"
        id="matTable"
        class="table table-hover table-responsive"
        >
      
          <!-- ID Column -->
          <ng-container matColumnDef="identification">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Identification Card </th>
            <td mat-cell *matCellDef="let row"> {{row.identification}} </td>
          </ng-container>
      
          <!-- Progress Column -->
          <ng-container matColumnDef="agency">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Agency </th>
            <td mat-cell *matCellDef="let row"> {{row.bureau_agency}} </td>
          </ng-container>
      
          <!-- Name Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Active Status </th>
            <td mat-cell *matCellDef="let row"> {{row.active_status ? "Active": "Inactive"}} </td>
          </ng-container>

           <!-- Name Column -->
           <ng-container matColumnDef="level">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID Level </th>
            <td mat-cell *matCellDef="let row"> {{row.option}} </td>
          </ng-container>
      
          <!-- Fruit Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
            <td mat-cell *matCellDef="let row"> 
                <button (click)="updateIdentification(row)" mat-button><i class="fa fa-edit"></i></button>
                <button mat-button (click)="removeId(row)"><i class="fa fa-trash"></i></button>
             </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
          </tr>
        </table>
      
        <mat-paginator [pageSize]="10" #idlist [pageSizeOptions]="[10, 20, 50, 100]" aria-label="Select page of users"></mat-paginator>
      </div>
      
</div>