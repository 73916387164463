<div>
    <div class="col">
        <table class="table table-hover table-responsive" >
            <tbody>
                <th class="charter-row">{{processowner}}</th>
                <ng-container  *ngFor="let item of ServiceList;let n = index">          
                    <tr (click)="scrollTo(item.id)" style="cursor: pointer;" title="jump to {{item.serviceDetails.service_name}}">
                        <td>
                         {{n+1}}. {{item.serviceDetails.service_name}}
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
  </div>