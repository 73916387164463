


<div class="row mb-3">
  <div class="col">
    <button   mat-button (click)="gridView = !gridView"><i [ngClass]="gridView ? leadEncoder? 'fas fa-edit':'fas fa-list' :'fas fa-sitemap' " ></i> {{gridView ? leadEncoder? ' Edit' : ' List View': 'Grid View'}}</button>

  </div>
</div>
<button mat-raised-button color="primary" *ngIf="leadEncoder && !gridView" (click)="addNode()">{{isLowerLevel? 'Add Lower Level Office':'Add Central Office'}}</button>

<ng-container *ngIf="!gridView">
    <h5 class="mt-3">{{isLowerLevel? 'Lower level Offices': 'Central Office'}}</h5>

<ul class="tree" *ngFor="let node of offices;let i = index">
    
    <li>
        <ng-container >
            <app-artemis-office-model-view
            [node]="node.office"
            [parent_node_array]="isLowerLevel? lower_level_unit : dataSource"
            [index]="i"
            [office_id]="node.id"
            [referenceArray]="isLowerLevel? lower_level_unit : dataSource"
            [parent]="true"
            [leadEncoder]="leadEncoder"
            ></app-artemis-office-model-view>
        </ng-container>
    </li>

</ul>
</ng-container>




<div class="scrollable-div center" cdkDropListGroup *ngIf="gridView">

 
  

   <div class="row">

  

    <section *ngFor="let office of offices;let i = index" class="org-chart-data" >

      <div  class=" mb-3 d-flex justify-content-center" >
        <label for="range">Zoom Level : {{ office.value / 10 * 100  }}%</label>
        <input #inputrange  id="range" [(ngModel)]="office.value" type="range"  min="1" max="10" step="1" [value]="office.value" (input)="showVal($event,isLowerLevel ? 'lower' + i:''+i)" >
      
      </div>

      <div  cdkDrag [ngClass]="isLowerLevel ? 'lower' + i:'' + i">

        <div style="border: 1px dotted grey; padding: 20px;">

          <ngx-org-chart   [nodes]="office.officeFormat"  [direction]="office.alignment" ></ngx-org-chart>

        </div>

       
      </div>
    </section>
   
   </div>
</div>

<!-- <div class="org-chart">
    <ul>
      <li>
        <app-org-chart-node [node]="orgData"></app-org-chart-node>
        <ul *ngIf="getChildren(orgData).length > 0">
          <li *ngFor="let child of getChildren(orgData)">
            <app-org-chart-node [node]="child"></app-org-chart-node>
          </li>
        </ul>
      </li>
    </ul>
  </div> -->

 
  <!-- <div class="row mt-4 w-80"  *ngFor="let node of dataSource;let i = index" >
    <ng-container >
        <app-artemis-office-model-view
        [node]="node.office"
        [parent_node_array]="dataSource"
        [index]="i"
        [office_id]="node.id"
        [referenceArray]="dataSource"
        [parent]="true"
        [leadEncoder]="leadEncoder"
        ></app-artemis-office-model-view>
    </ng-container>
</div> -->