	



<!--Main Navigation-->

<nav class="navbar navbar-expand-lg sticky-top navbar-light bg-light p-0" style="max-width: 100%;">
  <!-- Container wrapper -->
  <div class="container-fluid">
     <!-- Navbar brand -->
     <a class="navbar-brand" href="https://www.gov.ph/" target="_blank">GOVPH</a>
    <!-- Toggle button -->
    <button class="navbar-toggler" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarRightAlignExample" aria-controls="navbarRightAlignExample" aria-expanded="false" aria-label="Toggle navigation">
      <i class="fas fa-bars"></i>
    </button>

    <!-- Collapsible wrapper -->
    <div class="collapse navbar-collapse" id="navbarRightAlignExample">
  
      <!-- Left links -->
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <button class="nav-button {{ activeHome }}" aria-current="page"routerLink="/artemis">Home</button>
        </li>
        <li class="nav-item">
          <button class="nav-button {{ activeAboutUs }}" routerLink="/artemis/about-us">About Us</button>
        </li>
        <li class="nav-item">
          <button class=" nav-button {{ activeHelp }}" routerLink="/artemis/help">Help</button>
        </li>
        <li class="nav-item">
          <button class=" nav-button {{ activeContactUs }}" routerLink="/artemis/contact-us" > Contact Us</button>
        </li>
      </ul>

      
      <!-- Right Links-->
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li *ngIf="isCharterAccessible" class="nav-item">
          <button class=" nav-button {{ activeCharter }}" routerLink="/artemis/charter"><i class="fas fa-inbox"></i>  Citizen's Charter Management (Admin)</button>
        </li>
        <!-- Navbar Search dropdown -->
        <li class="nav-item dropdown">
          <button class="nav-button dropdown-toggle"  href="#" id="navbarDropdown"  role="button" data-mdb-toggle="dropdown" aria-expanded="false"><i class="fa fa-search fa-md"></i>
            Search 
          </button>
          <!-- Dropdown menu -->
          <ul class="dropdown-menu nav-search" aria-labelledby="navbarDropdown">
            <li>
              <a routerLink="/artemis/services"class="dropdown-item">Service List</a>
            </li>
            <li>
              <a class="dropdown-item" routerLink="/artemis/citizen-charter">Citizen's Charter List</a>
            </li>
            <li>
              <a class="dropdown-item" routerLink="/artemis/agencies" >Government List</a>
            </li>
          </ul>
        </li>

        <!---Notifications Temporarily disabled
          activate it if email notification is functioning
        <li class="nav-item" *ngIf="isCharterAccessible">
          <button class="nav-button" href="#" id="navbarDropdown" role="button" data-mdb-toggle="dropdown" aria-expanded="false"><i class="fa fa-bell fa-md"></i>
           <span class="badge rounded-pill badge-notification bg-danger">12</span>
          </button>
        </li> -->

        <!--Account Dropdown-->
        <li class="nav-item dropdown" *ngIf="auth.user | async as user; else showLogin">
          <button
            class="nav-button dropdown-toggle"
            href="#"
            id="AccountnavbarDropdown"
            role="button"
            data-mdb-toggle="dropdown"
            aria-expanded="false">
            <img  class="list-header" height='18' width='18' src="/assets/profile-gray.png" class="rounded-circle img-fluid" >
          </button>
          <!-- Dropdown menu -->
          <ul class="dropdown-menu dropdown-menu-end nav-pos" aria-labelledby="AccountnavbarDropdown">
            <span>
              <p class="pt-3 pb-0" style="color:#FCD116;font-size:10px;text-align:center;text-transform:uppercase;">{{userSubtype ? userSubtype : userType }}</p>
              <p class="fw-bold pt-2 ps-3 pe-3" style="font-size:12px;text-align:center;">{{user.displayName}}</p>
              <p class="pt-2 ps-3 pe-3 pb-3" style="font-size:12px;text-align:center;">{{agencyName}}</p>
            </span>
            <li>
              <a class="dropdown-item" routerLink="/">Return to Homepage</a>
            </li>
            <li>
              <hr class="dropdown-divider" />
            </li>

            <!--moved to side menu nav
            <li>
              <a
                class="dropdown-item"
                *ngIf="isAdmin"
                routerLink="/admin/controls/"
                >Admin Controls</a
              >
            </li>

             <li>
              <div  *ngIf="loader"><div class="dropdown-item">Loading . . .</div></div>
              <div *ngIf="!loader" >
                <a class="dropdown-item" *ngIf="isAdmin" routerLink="/agency/">Agency Controls</a>
                    <a class="dropdown-item" *ngIf="!isAdmin && !hasPage" routerLink="/agency/create">Create Agecy Page</a> 
                    <a class="dropdown-item" *ngIf="!isAdmin && hasPage" [routerLink]="toAgencyPage">Agency Page</a>
                  </div>
                </li>
         
            <li>
              <div  *ngIf="loader"><div class="dropdown-item">Loading . . .</div></div>
              <div *ngIf="!loader" >
                <a class="dropdown-item" *ngIf="!isAdmin && canViewCC" (click)="goToCCReport()">Agency Citizen's Charter</a>
              
              </div>
            </li>

             <li>
              <a
                class="dropdown-item"
                *ngIf="isAdmin"
                routerLink="/account/"
                >Admin Account Settings</a
              >
            </li>
            -->
            <li>
              <a class="dropdown-item"
                *ngIf="isAdmin"
                routerLink="/email-config/">
                Email Config
              </a>
            </li>
            <li>
              <div *ngIf="loader"><div class="dropdown-item">Loading . . .</div></div>
              <div *ngIf="!loader">
                <a class="dropdown-item" *ngIf="!isAdmin && agencyAdmin && hasPage" [routerLink]="toAgencyMemberPage">Agency Member Controls</a>
              </div>
            </li>
            <li *ngIf="isReportAccessible">
              <a 
                class="dropdown-item"
                routerLink="/artemis/report">
                Reports</a>
            </li>

            <li *ngIf="isCharterAccessible">
              <a 
                class="dropdown-item"
                routerLink="/artemis/charter/feedback">
                Survey
              </a>
            </li>
            <li>
              <a
                class="dropdown-item"
                [routerLink]="accountSettingsLink"
                >Your Account Settings</a>
            </li>
            <li>
              <hr class="dropdown-divider" />
            </li>
            <li>
              <a class="dropdown-item" (click)="logout()"><i class="fas fa-sign-out-alt"></i> Logout</a>
            </li>
          </ul>
          
        </li>
        <ng-template #showLogin>
          <li class="nav-item">
            <button class="nav-button fw-bold" routerLink="/artemis/login"> Login</button>
          </li>
        </ng-template>
      </ul>
      <!-- Left links -->
    </div>
    <!-- Collapsible wrapper -->
  </div>
  <!-- Container wrapper -->
</nav>
<!--Main Navigation-->

<!-- logo with search Bar-->
<div class="{{ style_theme }} sticky-top sc-stck" >
  <div class="row">
    <div class="logo-container col-md-4 d-flex justify-content-center align-items-center" > 
      <img
      src="/assets/Assets/WHITE-TEXT-TRANSPARENT.png"
      alt="Anti-Red Tape Electronic Management Information System"
      class="logo"
      />
    </div>
    <div class="col-md-8 d-flex align-items-center form-container"  >
      <div>
        <p class="d-flex w-auto mt-4 mb-1" for="searchControl" style="font-size:14px;color:#FFFFFF;">Are you looking for Citizen's Charter?</p>
        <form
          [formGroup]="searchform"
          class="d-flex input-group mb-4" 
          (submit)="search()"
        >
          <!-- Reinstate (input)="getSuggestions()" once service finder is for actual implementation RWL 20210614-->
    
          <input
            id="searchControl"
            type="search"
            class="form-control"
            placeholder="Enter keywords here..."
            aria-label="Search Charters"
            formControlName="searchfield"
          />
          <button class="btn btn-light" data-mdb-ripple-color="dark">
            <i class="fas fa-search"></i>
          </button>
          <!--
                        <select class="form-control" formControlName="searchsuggestions">
                            <option *ngFor="let result of searchSuggestions">
                            {{ result }}
                            </option>
                        </select>
                        -->
        </form>
        <div class="d-flex justify-content-end">
          <ul class="list-group">
            <li
              *ngFor="let result of searchSuggestions"
              (click)="selectItem(result)"
              class="list-group-item text-dark"
            >
              {{ result }}
            </li>
          </ul>
        </div>
      </div>
    </div>
</div>
</div>
<!-- Navbar -->