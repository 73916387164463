import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-productivity-existing-regulations',
  templateUrl: './productivity-existing-regulations.component.html',
  styleUrls: ['./productivity-existing-regulations.component.scss']
})
export class ProductivityExistingRegulationsComponent implements OnInit {

  reg_count: any =[];

  //COUNT PER STATUS
  draft_count: any =[];
  validation_count: any =[];
  arta_review_count: any =[];
  modification_count: any =[];

  //COUNT PER EFFECTIVITY <--- no data yet

  dateInfo: any;

  constructor(private afs: AngularFirestore, public auth: AngularFireAuth) { }

  ngOnInit(): void {
    this.loadCounts();
  } 
  
  receivedDatePicker(p:any){
    this.dateInfo = p;
  }


  async loadCounts() {
    this.reg_count = [];
    this.afs.collection("Worklist-Existing Regulations").snapshotChanges()
      .pipe(
        tap(
          (data: any) => {
            data.forEach((info: any) => {
              let item: any = info.payload.doc.data();
              item.id = info.payload.doc.id;
              
              this.reg_count.push(item);
              /**
               * load un-posted services for editing & approving
               */
               if(item['status'] == "DRAFT"){
                //this.draft_count = []  
                this.draft_count.push(item)
              }
               
              else if(item['status'] == "FOR VERIFICATION"){
                //this.validation_count = []
                this.validation_count.push(item)
              }
              else if(item['status'] == "FOR ARTA REVIEW"){
                //this.arta_review_count = []  
                this.arta_review_count.push(item)
              }
              else if(item['status'] == "FOR MODIFICATION"){
                //this.modification_count = []  
                this.modification_count.push(item)
              }
            });
          }
        )
      )
      .subscribe()
  }  
}
