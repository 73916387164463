import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { debounceTime, first, flatMap, startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-pbris-quick-adv-search',
  templateUrl: './pbris-quick-adv-search.component.html',
  styleUrls: ['./pbris-quick-adv-search.component.scss']
})
export class PbrisQuickAdvSearchComponent implements OnInit {

  style_theme: any = 'theme-default'; //current theme

  dataLoader:boolean = false
  
  searchform: FormGroup = this.fb.group({
    user: new FormControl('', Validators.required),

    // classification: new FormControl('', Validators.required),

    // stageoflife: new FormControl(''),
    // lifeevent: new FormControl(''),

    // sector: new FormControl(''),
    // stageofbusiness: new FormControl(''),

    jurisdiction: new FormControl('', Validators.required),
    status: new FormControl('', Validators.required),
    agency: new FormControl('', Validators.required),
  })

  
  returnedSol:any=[];
  returnedLife:any=[];
  returnedSector:any=[];
  returnedSob:any=[];
  returnedJuris:any=[];
  returnedAgency:any=[];

  autoCSol:Observable<any[]>;
  autoCLife:Observable<any[]>;
  autoCSector:Observable<any[]>;
  autoCSob:Observable<any[]>;
  autoCJuris:Observable<any[]>;
  autoCAgency:Observable<any[]>;

  solList:any[]= []
  lifeList:any[]= []
  sectorList:any[]= []
  sobList:any[]= []
  jurisList:any[]= []
  agencyList:any[]= []

  solLoaded:boolean = false;
  lifeLoaded:boolean = false;
  sectorLoaded:boolean = false;
  sobLoaded:boolean = false;

  checkSearchForm = {
    user:[
      { type: "required", message: "User type is required" },
    ],
    // classification:[
    //   { type: "required", message: "Classification is required" },
    // ],
    jurisdiction:[
      { type: "required", message: "Jurisdication is required" },
    ],
    status:[
      { type: "required", message: "Regulation Status is required" },
    ],
    agency:[
      { type: "required", message: "Agency is required" },
    ],
    stageoflife:[
      { type: "required", message: "Stage of Life is required" },
    ],
    lifeevent:[
      { type: "required", message: "Life Event is required" },
    ],
    sector:[
      { type: "required", message: "Sector is required" },
    ],
    stageofbusiness:[
      { type: "required", message: "Stage of Business is required" },
    ],
  }

  constructor(
    public auth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    public fb: FormBuilder) { }

  ngOnInit(): void {
    this.setTheme()
    this.loadAllData()
  }

  async setTheme() {
    switch(sessionStorage.getItem("user_type")){
      case 'arta':
        this.style_theme = 'theme-arta';
        // this.isArta = true
        break;
      case 'agency':
        this.style_theme = 'theme-agency';
        break;
      default:
        this.style_theme = 'theme-default';
    }
  }

  async loadAllData(){
    this.dataLoader = true
    // const solFlag = await this.loadData('stageoflife','Stage of Life')
    // const lifeFlag = await this.loadData('lifeevent','Life Event')
    // const sectorFlag = await this.loadData('sector','Sector')
    // const sobFlag = await this.loadData('stageofbusiness','Stage of Business')
    const jurrisFlag = await this.loadData('jurisdiction','Jurisdiction')
    const agencyFlag = await this.loadData('agency','Agency')
    // if(solFlag && lifeFlag && sectorFlag && sobFlag && jurrisFlag && agencyFlag){
    if(jurrisFlag && agencyFlag){
    // if(sectorFlag && sobFlag){
      console.log('load finished')
      this.dataLoader = false;
    }
  }

  universalOption(codeType:any){
    if(codeType == 'user_type'){
      return ['Individual','Business']
    }
    if(codeType == 'status'){
      return ['Existing','Proposed']
    }
    return []
  }

  async loadData(load:any,collection:any){
    const arrayData = await this.afs.collection(collection,
      filter => this.filterFunc(filter,load))
      .snapshotChanges()
      .pipe(first())

      if(load == 'stageoflife'){
        this.returnedSol = arrayData
        this.autoCSol = this.mapper(load)
        return true
      }
      else if(load == 'lifeevent'){
        this.returnedLife = arrayData
        this.autoCLife = this.mapper(load)
        return true
      }
      else if(load == 'sector'){
        this.returnedSector = arrayData
        this.autoCSector = this.mapper(load)
        return true
      }
      else if(load == 'stageofbusiness'){
        this.returnedSob = arrayData
        this.autoCSob = this.mapper(load)
        return true
      }
      else if(load == 'jurisdiction'){
        this.returnedJuris = arrayData
        this.autoCJuris = this.mapper(load)
        return true
      }
      else if(load == 'agency'){
        this.returnedAgency = arrayData
        this.autoCAgency = this.mapper(load)
        return true
      }
      else return true
  }

  private filterFunc(filter:any,load:any){
    if(load == 'stageoflife'){
      return filter.orderBy('stage')
    }
    else if(load == 'lifeevent'){
      return filter.orderBy('event')
    }
    else if(load == 'sector'){
      return filter.orderBy('name')
    }
    else if(load == 'stageofbusiness'){
      return filter.orderBy('name')
    }
    else if(load == 'jurisdiction'){
      return filter.orderBy('name')
    }
    else if(load == 'agency'){
      return filter.orderBy(("agencyDetails" ? "agencyDetails.agencyName" : "name"))
    }
    else
      return filter
  }

  private mapper(load:any){
    return (this.searchform.controls[load] as FormControl).valueChanges
    .pipe(
      startWith(''),
      debounceTime(300),
      flatMap((value) => this.mapFilter(value,load))
    );
  }

  private mapFilter(value:string, load:any):Observable<any[]>{
    const filteredVal = value.toLowerCase()
    if(load == 'stageoflife'){
      return this.returnedSol.pipe(
        map((code:any)=>{
          let modifiedCode: any = code.map((element:any)=>{
            let item:any = element.payload.doc.data()
            item.id = element.payload.doc.id
            return item
          });
          return modifiedCode.filter((option:any)=>
            filteredVal.trim() != '' && option.stage.toLowerCase().includes(filteredVal)
          )
        })
      )
    }
    else if(load == 'lifeevent'){
      return this.returnedLife.pipe(map((code:any)=>{
        let modifiedCode: any = []
        // let modifiedCode: any = code.map((element:any)=>{
        // console.log(this.searchform.value.stageoflife)
        code.map((element:any)=>{
          let item:any = element.payload.doc.data()
          item.id = element.payload.doc.id
          if(!modifiedCode.includes(item.event) && item.stage == this.searchform.value.stageoflife) modifiedCode.push(item.event)
          // return item
        });
        return modifiedCode.filter((option:any)=>
          filteredVal.trim() != '' && option.toLowerCase().includes(filteredVal)
        )
      }))
    }
    else if(load == 'sector'){
      return this.returnedSector.pipe(
        map((code:any)=>{
          let modifiedCode: any = code.map((element:any)=>{
            let item:any = element.payload.doc.data()
            item.id = element.payload.doc.id
            return item
          });
          return modifiedCode.filter((option:any)=>
            filteredVal.trim() != '' && option.name.toLowerCase().includes(filteredVal)
          )
        })
      ) 
    }
    else if(load == 'stageofbusiness'){
      return this.returnedSob.pipe(map((code:any)=>{
        let modifiedCode: any = code.map((element:any)=>{
          let item:any = element.payload.doc.data()
          item.id = element.payload.doc.id
          return item
        });
        return modifiedCode.filter((option:any)=>
          filteredVal.trim() != '' && option.name.toLowerCase().includes(filteredVal)
        )
      }))
    }
    else if(load == 'jurisdiction'){
      return this.returnedJuris.pipe(map((code:any)=>{
        let modifiedCode: any = code.map((element:any)=>{
          let item:any = element.payload.doc.data()
          item.id = element.payload.doc.id
          return item
        });
        return modifiedCode.filter((option:any)=>
          filteredVal.trim() != '' && option.name.toLowerCase().includes(filteredVal)
        )
      }))
    }
    else if(load == 'agency'){
      return this.returnedAgency.pipe(
        map((code:any) => {
          let modifiedCode: any = code.map((element:any) => {
            let item:any = element.payload.doc.data()
            item.id = element.payload.doc.id
            return item
          });
          this.agencyList = modifiedCode
          return modifiedCode.filter(
            (option:any) =>  (filteredVal.trim() != '' && ( option.agencyDetails ? option.agencyDetails.agencyName.toLowerCase().includes(filteredVal) : option.name.toLowerCase().includes(filteredVal)))
          );
        })
      );
    }
    return of([])
  }

  universalSelect(codeType:any, value:any){
    if(codeType == 'user_type'){
      this.searchform.patchValue({
        user:value,
        // classification:value,
        // stageoflife:'',
        // lifeevent:'',
        // sector:'',
        // stageofbusiness:'',
      })
      if(value == 'Individual'){
        this.searchform.addControl('stageoflife',new FormControl('', Validators.required))
        if(this.returnedSol.length == 0 && (this.autoCSol == null || this.autoCSol == undefined) && this.solList.length == 0){
          this.loadData('stageoflife','Stage of Life')
          this.solLoaded = true
        }
        else if(this.solLoaded == true){
          this.autoCSol = this.mapper('stageoflife')
        }
        this.searchform.addControl('lifeevent',new FormControl('', Validators.required))
        if(this.returnedLife.length == 0  && (this.autoCLife == null || this.autoCLife == undefined) && this.lifeList.length == 0 ){
          this.loadData('lifeevent','Life Event')
          this.lifeLoaded = true
        }
        else if(this.lifeLoaded == true){
          this.autoCLife = this.mapper('lifeevent')
        }
        if(this.searchform.contains('sector') == true){
          this.searchform.removeControl('sector')
        }
        if(this.searchform.contains('stageofbusiness') == true){
          this.searchform.removeControl('stageofbusiness')
        }
      }
      else if(value == 'Business'){
        this.searchform.addControl('sector',new FormControl('', Validators.required))
        if(this.returnedSector.length == 0  && (this.autoCSector == null || this.autoCSector == undefined) && this.sectorList.length == 0 ){
          this.loadData('sector','Sector')
          this.sectorLoaded = true
        }
        else if(this.sectorLoaded == true){
          this.autoCSector = this.mapper('sector')
        }
        this.searchform.addControl('stageofbusiness',new FormControl('', Validators.required))
        if(this.returnedSob.length == 0  && (this.autoCSob == null || this.autoCSob == undefined) && this.sobList.length == 0 ){
          this.loadData('stageofbusiness','Stage of Business')
          this.sobLoaded = true
        }
        else if(this.sobLoaded == true){
          this.autoCSob = this.mapper('stageofbusiness')
        }
        if(this.searchform.contains('stageoflife') == true){
          this.searchform.removeControl('stageoflife')
        }
        if(this.searchform.contains('lifeevent') == true){
          this.searchform.removeControl('lifeevent')
        }
      }
    }
    else if(codeType == 'status'){
      this.searchform.patchValue({
        status:value,
      })
    }
  }

  formReset(){
    // this.searchform.reset()
    this.searchform.patchValue({
      user:'',
      // classification:'',
      jurisdiction:'',
      status:'',
      agency:'',
    });

    ((document.getElementById('user_type_select') as HTMLSelectElement).getElementsByTagName('option') as HTMLCollectionOf<HTMLOptionElement>)[0].selected = true;
    ((document.getElementById('status_select') as HTMLSelectElement).getElementsByTagName('option') as HTMLCollectionOf<HTMLOptionElement>)[0].selected = true;
    if(this.searchform.contains('sector') == true){
      this.searchform.removeControl('sector')
    }
    if(this.searchform.contains('stageofbusiness') == true){
      this.searchform.removeControl('stageofbusiness')
    }
    if(this.searchform.contains('stageoflife') == true){
      this.searchform.removeControl('stageoflife')
    }
    if(this.searchform.contains('lifeevent') == true){
      this.searchform.removeControl('lifeevent')
    }

    this.searchform.markAsPristine();
    this.searchform.markAsUntouched();
  }
  
  formSubmit(){
    this.validateFields()
    console.log(this.searchform.value)
    if(this.searchform.valid){
      let JSONarray:any = this.searchform.value
      this.router.navigate([`/pbris/regulations/${JSONarray.status}`], {queryParams: JSONarray});
    }else{
      console.warn('form invalid')
    }
  }

  hasError(controlName:any, validType:any){
    return ((this.searchform.get(controlName) as FormControl).hasError(validType) 
    && ((this.searchform.get(controlName) as FormControl).dirty 
    || (this.searchform.get(controlName) as FormControl).touched));
  }

  validateFields(){
    if (!this.searchform.valid) {
      // Mark the form and inputs as touched so the errors messages are shown
      this.searchform.markAsTouched();
      for (const control in this.searchform.controls) {
        if (this.searchform.controls.hasOwnProperty(control)) {
          this.searchform.controls[control].markAllAsTouched();
          this.searchform.controls[control].markAsDirty();
        }
      }
    }
  }
}
