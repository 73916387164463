<mat-dialog-content class="text-center">
  <h1 class="card-header bg-dark text-warning p-3">
    <i class="fas fa-exclamation-triangle me-4"></i>IMPORTANT
  </h1>
  <h5 style="background-color: red" *ngIf="forReconsideration">
    Warning! Please reconsider posting this regulation!
  </h5>

  <div class="card-body border-top bg-dark p-4" style="text-align:justify;border-radius:0 !important;">
    <div class="row">
      <p class="text-white fw-bold">
        By proceeding, you certify that all necessary corrections / modifications raised by Agency / ARTA have been addressed. Furthermore, you also certify that 
        all texts and information included are consistent with the text of the existing regulation. Any inaccuracy and / or misinterpretation of facts is
        punishable by existing Civil Service Commission Rules on Administrative Cases in the Civil Service, and other relevant laws, rules, and regulations.
      </p>
    </div>
  </div>

  <div class="card-footer border-top bg-dark p-3">
    <div class="row">
      <div class="col-3"></div>
      <div class="col-3">
        <button (click)="submit(enum_action_flag.POST_REGULATION)" class="btn btn-warning btn-rounded my-2">
          Proceed
        </button>
      </div>
      <div class="col-3">
        <button (click)="cancel()" class="btn btn-dark border border-white btn-rounded my-2">
          Cancel
        </button>
      </div>
      <div class="col-3"></div>
    </div>
  </div>
</mat-dialog-content>
