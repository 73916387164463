import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-artemis-about-us-page',
  templateUrl: './artemis-about-us-page.component.html',
  styleUrls: ['./artemis-about-us-page.component.scss']
})
export class ArtemisAboutUsPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
