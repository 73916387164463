import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { data } from 'jquery';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-regulation-proposed-notifications',
  templateUrl: './regulation-proposed-notifications.component.html',
  styleUrls: ['./regulation-proposed-notifications.component.scss']
})
export class RegulationProposedNotificationsComponent implements OnInit {
  @Input() regulationProposedNotificationCollectionName: string = '';
  @Input() regulationWorklistCollectionName: string = '';

  proposedNotifList:any =[];
  workList:any =[];

  date_generated: any;
  agency: any;
  officer: any;
  status: any;
  short_title: any;

  id:any;

  //For Notif:
   
  has_consultation_comments: any = null;
  part_of_reg_plan: any = null;
  title: any = null;
  subject: any = null;
  reg_instrument: any = null;
  reg_agency: any = [];

  // reg_action_classification

    reg_action_new: any;
    reg_action_modification: any;
    reg_action_repeal: any;

  // reg_notif_form: 
    reg_problem: any;
    reg_classification:any;
    reg_sector:any;
    reg_business: any;
    reg_stageoflife: any;
    reg_lifeevent:any;
    reg_division: any;
    reg_juris: any;
    reg_case: any;
    reg_remarks: any;
    doc_date: any;
    pia_ria_timeline: any = [];
    pia_ria_timeline_activity_title: any = [];
    pia_ria_timeline_end_date: any = [];
    pia_ria_timeline_start_date: any = [];

  constructor(
    private afs: AngularFirestore,
    ) { }

  ngOnInit(): void {

    
    this.getWorkList();
    // this.getProposedNotifs();
  }

  openModal(id: any) {
    this.getProposedNotifs(id)
    // console.log("workList: ", this.proposedNotifList)
    
    console.log("workList: ", this.proposedNotifList)
  }
  closeModal() {    
  }

  getProposedNotifs(id:any){

    const regRef = this.afs
      .collection(this.regulationProposedNotificationCollectionName)
      .doc(id);
    regRef
    .snapshotChanges()
    .pipe(
      tap((data:any)=>{
        let item = data.payload.data()
        if(item.has_consultation_comments){
          this.has_consultation_comments=item.has_consultation_comments;

        }if(item.part_of_reg_plan){
          this.part_of_reg_plan=item.part_of_reg_plan;

        }if(item.title){
          this.title=item.title;

        }if(item.subject){
          this.subject = item.subject;
          
        }if(item.reg_instrument){
          this.afs.collection('Instrument').doc(item.reg_instrument).snapshotChanges().subscribe(
            (data:any)=>{
              let itemInstrument = data.payload.data();

              this.reg_instrument = itemInstrument.name;
            }
          )

        }if(item.reg_agency){         
          this.reg_agency = [];

          if(typeof item.reg_agency == 'string'){
            const agencyRef = this.afs.collection(`Agency`).doc(item.reg_agency);
            agencyRef.snapshotChanges().pipe(
              tap((data: any) => {
                let agencyRef_info = data.payload.data();
  
                agencyRef_info.reg_agency_name = agencyRef_info.name;
  
                this.reg_agency.push(agencyRef_info.reg_agency_name);
              }),
              take(1)
            ).subscribe();
          }
          else if(Array.isArray(item.reg_agency)){
            for (let agency of item.reg_agency) {
              const agencyRef = this.afs.collection(`Agency`).doc(agency);
              agencyRef.snapshotChanges().pipe(
                tap((data: any) => {
                  let agencyRef_info = data.payload.data();
    
                  agencyRef_info.reg_agency_name = agencyRef_info.name;
    
                  this.reg_agency.push(agencyRef_info.reg_agency_name);
                }),
                take(1)
              ).subscribe();
            }
          }

        }if(item.reg_action_classification.reg_action_new){
          this.reg_action_new = item.reg_action_classification.reg_action_new
          
        }if(item.reg_action_classification.reg_action_modification){
          this.reg_action_modification = item.reg_action_classification.reg_action_modification
          
        }if(item.reg_action_classification.reg_action_repeal){
          this.reg_action_repeal =item.reg_action_classification.reg_action_repeal
          
        }if(item.reg_notif_form.reg_problem){
          this.reg_problem =item.reg_notif_form.reg_problem

        }if(item.reg_notif_form.reg_classification){          
          this.reg_classification =item.reg_notif_form.reg_classification

        }if(item.reg_notif_form.reg_sector){ 
          this.afs.collection('Sector').doc(item.reg_notif_form.reg_sector).snapshotChanges().pipe(
           tap((data:any)=>{
            let itemSector = data.payload.data();

            this.reg_sector = itemSector.name;
          }),
           take(1) 
          ).subscribe()

        }if(item.reg_notif_form.reg_business){ 
          this.afs.collection('Stage of Business').doc(item.reg_notif_form.reg_business).snapshotChanges().pipe(
            tap((data:any)=>{
              let itemBusiness = data.payload.data();

              this.reg_business = itemBusiness.name;
            }),
            take(1) 
           ).subscribe()

        }if(item.reg_notif_form.reg_stageoflife){ 
          this.afs.collection('Stage of Life').doc(item.reg_notif_form.reg_stageoflife).snapshotChanges().pipe(
            tap((data:any)=>{
              let itemStageofLife = data.payload.data();

              this.reg_stageoflife = itemStageofLife.code;
            }),
            take(1) 
           ).subscribe()

        }if(item.reg_notif_form.reg_lifeevent){ 
          this.afs.collection('Stage of Life').doc(item.reg_notif_form.reg_lifeevent).snapshotChanges().pipe(
            tap((data:any)=>{
              let itemLifeEvent = data.payload.data();

              this.reg_lifeevent = itemLifeEvent.event;
            }),
            take(1) 
           ).subscribe()

        }if(item.reg_notif_form.reg_division){ 
          this.afs.collection('Division').doc(item.reg_notif_form.reg_division).snapshotChanges().pipe(
            tap((data:any)=>{
              let itemDivision = data.payload.data();

              this.reg_division = itemDivision.name;
            }),
            take(1) 
           ).subscribe()

        }if(item.reg_notif_form.reg_juris){ 
          this.afs.collection('Jurisdiction').doc(item.reg_notif_form.reg_juris).snapshotChanges().pipe(
            tap((data:any)=>{
              let itemJurisdiction = data.payload.data();

              this.reg_juris = itemJurisdiction.name;
            }),
            take(1) 
           ).subscribe()

        }if(item.reg_notif_form.reg_case){ 
          this.afs.collection('Case Use').doc(item.reg_notif_form.reg_case).snapshotChanges().pipe(
            tap(
              (data:any)=>{
                let itemCaseUse = data.payload.data();
  
                this.reg_case = itemCaseUse.name;
              }
            ),
            take(1) 
           ).subscribe()
        }if(item.reg_notif_form.pia_ria_timeline){ 
          this.pia_ria_timeline = [];

          for (let timeline of item.reg_notif_form.pia_ria_timeline) {

            this.pia_ria_timeline.push(timeline)
          }

        }if(item.reg_notif_form.reg_remarks){ 
          this.reg_remarks =item.reg_notif_form.reg_remarks

        }if(item.reg_notif_form.doc_date){
 
          this.doc_date =item.reg_notif_form.doc_date
        }
      }),
      take(1)
    )
    .subscribe()
  }

  getWorkList(){
    return  this.afs
    .collection(this.regulationProposedNotificationCollectionName)
    .snapshotChanges()
    .subscribe(data =>{
      this.workList= data.map(e => {
        return {
          id: e.payload.doc.id,
          notif_data: e.payload.doc.data()
        };
      });
    });
  }
}
