import { AngularFireStorage } from '@angular/fire/storage';
import { first } from 'rxjs/operators';
import { FormControl, FormBuilder, Validators, AbstractControl, ValidatorFn, ValidationErrors, FormArray, FormGroup } from '@angular/forms';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable } from 'rxjs'
import { isArray } from 'ngx-bootstrap/chronos';
import { atLeastOne } from 'src/app/validators';

@Component({
  selector: 'app-artemis-view-organization-help-details',
  templateUrl: './artemis-view-organization-help-details.component.html',
  styleUrls: ['./artemis-view-organization-help-details.component.scss']
})
export class ArtemisViewOrganizationHelpDetailsComponent implements OnInit {

  agency_id:any;
  loading: boolean = false;
  has_agency_feeback_measures: boolean = false;
  agency_howComplaint:any = "";
  agency_fileComplaint:any = "";
  agency_howFeedback:any = "";
  agency_sendFeedback:any = "";

 
  @Input('agency_doc') doc_id:any;
  @Input('isAdmin') isAdmin:any;
  @Input('isAgencyMember') isAgencyMember:any;

  editHowComplaint:boolean= true;
  editFileComplaint:boolean= true;
  editHowFeedback:boolean= true;
  editSendFeedback:boolean= true;

  howComplaintProcessedForm = this.fb.group({
    howComplaintProcessed: new FormControl('',), // Validators.required
  })

  
  fileHowComplaintForm =  this.fb.group({
    fileHowComplaint: new FormControl('',), // Validators.required
  })

  
  howFeedbackProcessedForm =  this.fb.group({
    howFeedbackProcessed: new FormControl('',), // Validators.required
  })

  
  sendFeedbackForm =  this.fb.group({
    sendFeedback: new FormControl('',), // Validators.required
  })


  checkFeedbackMeasuresForm = {
    howComplaint:[
      { type: "required", message: "Mission is required" },
    ],
    fileComplaint:[
      { type: "required", message: "Service Pledges required" },
    ],
    howFeedback:[
      { type: "required", message: "Vision is required" },
    ],
    sendFeedback:[
      { type: "required", message: "Mandate is required" },
    ],
  };


  constructor(
    private afs: AngularFirestore,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.loadInfo(this.doc_id);
  }

  loadInfo(id:any){
    this.afs.collection('Agency').doc(id).snapshotChanges()
    .pipe(first())
    .subscribe(
      data =>{
        if(data){
          let item:any  = data.payload.data();
          item.id = data.payload.id;

          this.agency_id = item.id

          if(item.feedbackMeasures != null){
            this.has_agency_feeback_measures = true
            this.agency_howComplaint = item.feedbackMeasures.howComplaintsProcess ? item.feedbackMeasures.howComplaintsProcess : "" ;
            this.agency_fileComplaint = item.feedbackMeasures.howTofileComplaint ? item.feedbackMeasures.howTofileComplaint : "" ;
            this.agency_howFeedback =item.feedbackMeasures.howFeedbackProcess ? item.feedbackMeasures.howFeedbackProcess : "";
            this.agency_sendFeedback = item.feedbackMeasures.howToSendFeedback ? item.feedbackMeasures.howToSendFeedback : "" ;
          }

          this.howComplaintProcessedForm.patchValue({
            howComplaintProcessed: this.agency_howComplaint
          })
          
          this.fileHowComplaintForm.patchValue({
            fileHowComplaint: this.agency_fileComplaint
          })

          this.howFeedbackProcessedForm.patchValue({
            howFeedbackProcessed: this.agency_howFeedback
          })
          
          this.sendFeedbackForm.patchValue({
            sendFeedback: this.agency_sendFeedback
          })
        }
      })
  }



  update(editName:any){
    this.loading = true
    if(this.isAdmin || this.isAgencyMember){
      if(editName === 'howComplaint'){
        this.updateFirebase(editName, this.howComplaintProcessedForm.value.howComplaintProcessed)
      }
      else if(editName === 'fileComplaint'){
        this.updateFirebase(editName, this.fileHowComplaintForm.value.fileHowComplaint)
      }
      else if(editName === 'howFeedback'){
        this.updateFirebase(editName, this.howFeedbackProcessedForm.value.howFeedbackProcessed)
      }
      else if(editName === 'sendFeedback'){
        this.updateFirebase(editName, this.sendFeedbackForm.value.sendFeedback)
      }
    }
  }

  updateFirebase(code:any, data:any){
    let profileData = {}
    if(code == 'howComplaint') profileData = { 'feedbackMeasures.howComplaintsProcess': data, howComplaint: data } 
    if(code == 'fileComplaint') profileData = { 'feedbackMeasures.howTofileComplaint': data, fileComplaint: data } 
    if(code == 'howFeedback') profileData = { 'feedbackMeasures.howFeedbackProcess': data, howFeedback: data } 
    if(code == 'sendFeedback') profileData = { 'feedbackMeasures.howToSendFeedback': data, sendFeedback: data }
    
    // console.log('profileData',profileData)
    if(Object.keys(profileData).length > 0){
      this.afs.collection('Agency').doc(this.doc_id).update(profileData)
      .then(()=>{
        //console.log('Update '+code+' successful')
        if(code == 'howComplaint') { this.agency_howComplaint = data;}
        if(code == 'fileComplaint') { this.agency_fileComplaint = data;}
        if(code == 'howFeedback') { this.agency_howFeedback = data;}
        if(code == 'sendFeedback') { this.agency_sendFeedback = data;}
        this.toggleEdit(code)
        this.loading = false;
        alert("Agency Feedback Measures Update Successful")
      })
      .catch((err)=>{
        this.loading = false;
        console.error("Update "+code+" Failed ",err)
        alert("Agency Feedback Measures on "+code+" Update Failed")
      })
    
    } else   
    this.loading = false;
     console.warn('Invalid update code')
  }


  // error handlers
  toggleEdit(editName:any){
    if(this.isAdmin || this.isAgencyMember){
      if(editName === 'howComplaint'){
        this.editHowComplaint = !this.editHowComplaint
        this.howComplaintProcessedForm.patchValue({
          howComplaint:this.agency_howComplaint
        })
        this.howComplaintProcessedForm.markAsUntouched()
        this.howComplaintProcessedForm.markAsPristine()
      }
      else if(editName === 'fileComplaint'){
        this.editFileComplaint = !this.editFileComplaint
        this.fileHowComplaintForm.patchValue({
          fileComplaint:this.agency_fileComplaint
        })
        this.fileHowComplaintForm.markAsUntouched()
        this.fileHowComplaintForm.markAsPristine()
      }
      else if(editName === 'howFeedback'){
        this.editHowFeedback = !this.editHowFeedback
        this.howFeedbackProcessedForm.patchValue({
          howFeedback:this.agency_howFeedback
        })
        this.howFeedbackProcessedForm.markAsUntouched()
        this.howFeedbackProcessedForm.markAsPristine()
      }
      else if(editName === 'sendFeedback'){
        this.editSendFeedback = !this.editSendFeedback
        this.sendFeedbackForm.patchValue({
          sendFeedback:this.agency_sendFeedback
        })
        this.sendFeedbackForm.markAsUntouched()
        this.sendFeedbackForm.markAsPristine()
      }
    }
  }

  hasErrors(editName:any, controlName:any, validType:any, ctr?:any){
    if(editName === 'howComplaint'){
      return ((this.fileHowComplaintForm.get(controlName) as FormControl).hasError(validType) 
      && ((this.fileHowComplaintForm.get(controlName) as FormControl).dirty 
      || (this.fileHowComplaintForm.get(controlName) as FormControl).touched));
    }
    if(editName === 'fileComplaint'){
      return ((this.fileHowComplaintForm.get(controlName) as FormControl).hasError(validType) 
      && ((this.fileHowComplaintForm.get(controlName) as FormControl).dirty 
      || (this.fileHowComplaintForm.get(controlName) as FormControl).touched));
    }
    if(editName === 'howFeedback'){
      return ((this.howFeedbackProcessedForm.get(controlName) as FormControl).hasError(validType) 
      && ((this.howFeedbackProcessedForm.get(controlName) as FormControl).dirty 
      || (this.howFeedbackProcessedForm.get(controlName) as FormControl).touched));
    }
    if(editName === 'sendFeedback'){
      return ((this.sendFeedbackForm.get(controlName) as FormControl).hasError(validType) 
      && ((this.sendFeedbackForm.get(controlName) as FormControl).dirty 
      || (this.sendFeedbackForm.get(controlName) as FormControl).touched));
    }
    else return null
  }

  formErrors(controls:any){
    let tempArr:any= []
    for (const control in controls){
      if(controls[control].invalid){
        tempArr.push(control)
      }
    }
    return tempArr
  }

  validateFields(editName:any){
    if(this.isAdmin || this.isAgencyMember){
      
    }
  }
  // !error handlers
}


