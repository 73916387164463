import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-citizens-charter-service-list-view',
  templateUrl: './citizens-charter-service-list-view.component.html',
  styleUrls: ['./citizens-charter-service-list-view.component.scss']
})
export class CitizensCharterServiceListViewComponent implements OnInit {
  @Input() services: any[];
  @Input() processowner : any[];
 // @Input() hasCounter: boolean;
 // @Input() lastCounter : number = 0 ;
  @Input() hasCounter: boolean = true;
 

  ngOnInit(): void {
      
  }

  get ServiceList(){
    return this.services.filter(filter=> filter.serviceDetails.process_owner == this.processowner)
  }
}