import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, Inject  } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { EmailAppCode, EmailTemplateCode } from 'src/app/entities/emailTemplates';
import { GovernmentAccountSubtype } from 'src/app/entities/worklist';
import { NotificationsEmailer } from 'src/app/service/notifications-emailer.service';
import Swal from 'sweetalert2';
import { matchingGroupedPassword, matchingPassword, needsStrongerPassword, phoneNumberValidator } from 'src/app/validators';
import { environment } from 'src/environments/environment';
import { ARTEMIS, FireAuthError, PBRIS } from 'src/app/entities/constant';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { formatISO, parseISO } from 'date-fns';
import { FileUploadService } from 'src/app/file-upload.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { start } from 'repl';
import { mergeMap, startWith } from 'rxjs/operators';
import { FIREBASE_REF } from 'src/app/app.module';

export enum accountRole{
  Default = 'Basic',
  Lead_Encoder = 'Lead Encoder',
  Lead_Verifier = 'Lead Verifier'
}

@Component({
  selector: 'app-agency-register-member',
  templateUrl: './agency-register-member.component.html',
  styleUrls: ['./agency-register-member.component.scss']
})
export class AgencyRegisterMemberComponent implements OnInit, OnDestroy{
  // agencyId
  private currentUserAuth: AngularFireAuth
  private registeredUsersAuth: AngularFireAuth

  ARTEMIS = ARTEMIS
  PBRIS = PBRIS

  public currentSystem = sessionStorage.getItem("currentSystem");

  @Input('hideModal') hideModal:boolean = false;
  @Input('agency') modalAgency:any;
  @Input('user') modalUser:any;
  @Output() closeModalEmitter = new EventEmitter<any>();
  @Output() closeButtonLoadEmitter = new EventEmitter<boolean>();

  hasOwnAgency = false;
  agency:any = null
  agency_id = null
  user:any = null

  isArtaAdmin = sessionStorage.getItem("arta_admin") == 'true' ? true : false
  isAgencyAdmin = sessionStorage.getItem("agency_admin") == 'true' ? true : false

  list_of_role :any[]= []

  filterRow(value: any){
    this.list_of_role = []

    switch(value){
      case GovernmentAccountSubtype.AGENCY_ENC:
           this.list_of_role = [accountRole.Default,accountRole.Lead_Encoder]
           break;
      case GovernmentAccountSubtype.AGENCY_VER:
          this.list_of_role = [accountRole.Default,accountRole.Lead_Verifier]
          break;
      default: 
          this.list_of_role = [accountRole.Default]
    }

    return this.list_of_role
  }

  loading = {
    register:false
  }
  
  acceptedFileType:any=[
    'application/pdf',
    'image/png',
    'image/jpeg',
    'image/jpg',
  ]

  terms_and_conditions_JSON: any = []
  privacy_policy_JSON: any = []

  registrationForm = this._formBuilder.group({
    credentials: this._formBuilder.group({
      access_artemis:new FormControl(true),
      access_pbris:new FormControl(true),
      account_type:new FormControl('Government',[Validators.required]),
      account_subtype:new FormControl('',[Validators.required]),
      admin:new FormControl(false),
      email:new FormControl(null,[Validators.email,Validators.required]),
      loggedIn:(null),
      mobile_number:new FormControl(null,[this.checkPhoneNumber]),
      sinceMember:new FormControl(null),
      uid:new FormControl(null),
      role : new FormControl(accountRole.Default)
    }),
    personal: this._formBuilder.group({
      first_name:new FormControl(null,[Validators.required]),
      last_name:new FormControl(null,[Validators.required]),
      sex:new FormControl(''),
    }),
    government: this._formBuilder.group({
      agency:new FormControl('',[Validators.required]),
      official_designation:new FormControl(null),
      subagency:new FormControl(null),
    }),
    company: this._formBuilder.group({
      name_of_organization: new FormControl(null),
      official_designation: new FormControl(null),
      organization_address: new FormControl(null),
      sector: new FormControl(null),
    }),
    file: this._formBuilder.group({
      government_id: new FormControl(null),
      company_id: new FormControl(null),
      authorization_letter: new FormControl(null),
      downloadLinkExpiresOn: new FormControl(null),
    })
  })

  registrationAgencyForm = this._formBuilder.group({
    agencyInput: new FormControl('',[Validators.required,this.withinList()]),
    motherAgency: new FormControl('')
  })

  registrationContractsForm = this._formBuilder.group({
    tnc: this._formBuilder.group({
      accept: new FormControl(false,Validators.requiredTrue)
    }),
    privatePolicy: this._formBuilder.group({
      accept: new FormControl(false,Validators.requiredTrue)
    }),
  })

  oneMBinByte = 1000000
  uploadLimits = {
    gov_id:{
      size: 0,
      unit: '',
    },
    auth_letter:{
      size: 0,
      unit: '',
    },
  }
  allUploadUnits = ['GB','MB','KB']

  public showFormErrors: boolean = false;
  uploading: boolean = true;

  uploadsForm = this._formBuilder.group({
    gov_id: new FormGroup({
      file: new FormControl(null),
      count: new FormControl(0,[Validators.min(1),Validators.max(1)]),
      type: new FormControl('',this.checkUploadType()),
      // size: new FormControl(0,Validators.max(this.convertFileSizeToCurrentUnit(this.uploadLimits.gov_id.size,this.uploadLimits.gov_id.unit))),
      size: new FormControl(0,Validators.max(0)),
    }),
    auth_letter: new FormGroup({
      file: new FormControl(null),
      count: new FormControl(0,[Validators.min(1),Validators.max(1)]),
      type: new FormControl('',this.checkUploadType()),
      // size: new FormControl(0,Validators.max(this.convertFileSizeToCurrentUnit(this.uploadLimits.auth_letter.size,this.uploadLimits.auth_letter.unit))),
      size: new FormControl(0,Validators.max(0)),
    }),
  })

  display_government_id: string = "";
  display_authorization_letter: string = "";
  gov_uploadProgress!: Observable<number | undefined>;
  auth_uploadProgress!: Observable<number | undefined>;

  genderOptions = ['Male','Female']//,'Others'

  subTypeOptions = [
    // {id:GovernmentAccountSubtype.ARTA_ADMIN,name:'Arta IT/Admin'},
    // {id:GovernmentAccountSubtype.ARTA_DDG,name:'Arta DDG'},
    // {id:GovernmentAccountSubtype.ARTA_DG,name:'Arta DG'},
    // {id:GovernmentAccountSubtype.ARTA_BRO_DIR,name:'Arta BRO Director'},
    // {id:GovernmentAccountSubtype.ARTA_BRO_DC,name:'Arta BRO Division Chief'},
    // {id:GovernmentAccountSubtype.ARTA_BRO_PO,name:'Arta BRO Program Officer'},
    // {id:GovernmentAccountSubtype.ARTA_CMEO_DIR,name:'Arta CMEO Director'},
    // {id:GovernmentAccountSubtype.ARTA_CMEO_DC,name:'Arta CMEO Division Chief'},
    // {id:GovernmentAccountSubtype.ARTA_CMEO_PO,name:'Arta CMEO Program Officer'},
    // {id:GovernmentAccountSubtype.AGENCY_ADMIN,name:'Agency IT/Admin'},
    // {id:GovernmentAccountSubtype.AGENCY_DIR,name:'Agency Head'},
    // {id:GovernmentAccountSubtype.AGENCY_VER,name:'Agency Verifier'},
    // {id:GovernmentAccountSubtype.AGENCY_ENC,name:'Agency Encoder'},
    
    GovernmentAccountSubtype.ARTA_ADMIN,
    GovernmentAccountSubtype.ARTA_DDG,
    GovernmentAccountSubtype.ARTA_DG,
    GovernmentAccountSubtype.ARTA_BRO_DIR,
    GovernmentAccountSubtype.ARTA_BRO_DC,
    GovernmentAccountSubtype.ARTA_BRO_PO,
    GovernmentAccountSubtype.ARTA_CMEO_DIR,
    GovernmentAccountSubtype.ARTA_CMEO_DC,
    GovernmentAccountSubtype.ARTA_CMEO_PO,
    GovernmentAccountSubtype.AGENCY_ADMIN,
    GovernmentAccountSubtype.AGENCY_DIR,
    GovernmentAccountSubtype.AGENCY_VER,
    GovernmentAccountSubtype.AGENCY_ENC,
  ]

  agencyChoices: Observable<any[]>
  agencyOptions:any = []

  checkForm = {
    personal:{
      first_name:[
        { type: "required", message: "First name is required" },
      ],
      last_name:[
        { type: "required", message: "Last name is required" },
      ],
    },
    credentials:{
      email:[
        { type: "email", message: "Email incorrect format" },
        { type: "required", message: "Email is required" },
      ],
      account_type:[
        { type: "required", message: "Account type is required" },
      ],
      account_subtype:[
        { type: "required", message: "Position is required" },
      ],
      mobile_number:[
        { type: "required", message: "Phone number is required" },
        { type: "pattern", message: "Phone number must only contain numbers" },
        { type: 'minlength', message: 'Phone Number must contain atleast 10 digits'},
      ],
    },
    government:{
      agency:[
        { type: "required", message: "Agency is required" },
        { type: "notInList", message: "Agency is required" },
      ]
    },
    uploadFile:{
      gov_id:[
        {type: 'min', message: 'No Uploaded file'},
        {type: 'max', message: 'Invalid multiple file uploads'},
        {type: 'type', message: 'File type not accepted'},
        {type: 'maxSize', message: 'File size exceeded allowed limit'},
      ],
      auth_letter:[
        {type: 'min', message: 'No Uploaded file'},
        {type: 'max', message: 'Invalid multiple file uploads'},
        {type: 'type', message: 'File type not accepted'},
        {type: 'size', message: 'File size exceeded allowed limit'},
      ],
    }
  }

  constructor(
    public _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public auth: AngularFireAuth,
    private afs: AngularFirestore,
    // private nE: NotificationsEmailer,
    private http: HttpClient,
    public fileUploadService: FileUploadService,
    private store: AngularFireStorage,
    @Inject(FIREBASE_REF.TEMP_INSTANCE_DB_AUTH) private readonly tempAuth: AngularFireAuth
  ){
    this.currentUserAuth = auth
    this.registeredUsersAuth = auth
  }


  // loaders
  ngOnInit(){
    this.agencyOptions = []
    this.changeSetters()
    this.getRegistrationContracts()
    this.getUploadLimit()
    
    // access as a modal w/ agency
    if(this.modalAgency){
      this.agency = this.modalAgency
      this.addAgencyToOptions(this.agency)
      this.agencyChoices = this.mapper('agencyInput')
      this.registrationAgencyForm.patchValue({
        agencyInput:this.agency.id
      })
      this.toValidate('agency')
      if(this.governmentForm.value.agency)
        this.hasOwnAgency = true
    }

    // access as a page w/ agency
    else if(this.route.snapshot.params.agencyId) this.loadAgency(this.route.snapshot.params.agencyId)
    
    // access w/o agency
    else this.loadAgencies()

    if(this.modalUser) this.user = this.modalUser
    else if(this.route.snapshot.params.userId){
      // get user from session storage
    }

  }

  get governmentForm(): FormGroup{
    return this.registrationForm.controls.government as FormGroup
  }

  get personalForm(): FormGroup{
    return this.registrationForm.controls.personal as FormGroup
  }

  get credentialsForm(): FormGroup{
    return this.registrationForm.controls.credentials as FormGroup
  }

  get uploadFilesForm():FormGroup{
    return this.uploadsForm as FormGroup
  }

  get tncForm():FormGroup{
    return this.registrationContractsForm.get("tnc") as FormGroup
  }

  get privatePolicyForm():FormGroup{
    return this.registrationContractsForm.get("privatePolicy") as FormGroup
  }

  get govIdForm():FormGroup{
    return (this.uploadsForm as FormGroup).get('gov_id') as FormGroup
  }

  get authLetterForm():FormGroup{
    return (this.uploadsForm as FormGroup).get('auth_letter') as FormGroup
  }

  get govIdFormControlsArr():any[]{
    return Object.keys(this.uploadsForm.value.gov_id)
  }

  get authLetterFormControlsArr():any[]{
    return Object.keys(this.uploadsForm.value.auth_letter)
  }

  changeSetters(){
    this.credentialsForm.get('account_subtype')?.valueChanges.subscribe({
      next:(change)=>{
        if(change.includes('IT/Admin')){
          this.credentialsForm.patchValue({
            admin: true
          })
        }
        else{
          this.credentialsForm.patchValue({
            admin: false
          })
        }
      }
    })
  }

  loadAgency(agencyId:string){
    this.registrationAgencyForm
    this.afs.collection('Agency').doc(agencyId).get().toPromise()
    .then((res)=>{
      if(res.exists){
        let agency:any = res.data()
        this.agency = agency
        agency.id = res.id
        this.addAgencyToOptions(agency)
      }
      this.agencyChoices = this.mapper('agencyInput')
      this.registrationAgencyForm.patchValue({
        agencyInput:this.agency.id
      })
      this.toValidate('agency')
      if(this.governmentForm.value.agency)
        this.hasOwnAgency = true
    })
    .catch((err)=>{
      console.log('loadAgency: ',err)
      this.agencyChoices = this.mapper('agencyInput')
      this.registrationAgencyForm.patchValue({
        agencyInput:this.agency.name
      })
      this.toValidate('agency')
    })
  }

  loadAgencies(){
    this.afs.collection('Agency').get().toPromise()
    .then((res)=>{
      res.forEach((data)=>{
        let agency:any = data.data()
        agency.id = data.id
        this.addAgencyToOptions(agency)
      })
      this.agencyChoices = this.mapper('agencyInput')
    })
    .catch((err)=>{
      console.log('loadAgencies: ',err)
      this.agencyChoices = this.mapper('agencyInput')
    })
  }

  addAgencyToOptions(agency:any){
    if(!this.agencyOptions.find((item:any)=>item.id == agency.id)){
      this.agencyOptions.push({
        motherAgency:agency?.agencyDetails?.motherAgency?.motheragency ? agency.agencyDetails.motherAgency.motheragency : null,
        id:agency.id,
        name:agency?.agencyDetails?.agencyName ? agency.agencyDetails.agencyName : agency.name
      })
    }
  }

  getUploadLimit(){
    this.afs.collection('Configuration').doc('GOVERNMENT').get().toPromise()
    .then((response)=>{
      if(response.exists){
        const config:any = response.data()
        const limits = config?.UPLOADING_LIMITS?.ADMIN_CREATES_USER
        const govIdLimits = limits?.gov_id
        const authLetterLimits = limits?.auth_letter
        this.uploadLimits.gov_id = govIdLimits
        this.uploadLimits.auth_letter = authLetterLimits
        this.uploadsForm.get('gov_id')?.get('size')?.setValidators(Validators.max(this.convertFileSizeToCurrentUnit(this.uploadLimits.gov_id.size,this.uploadLimits.gov_id.unit)))
        this.uploadsForm.get('auth_letter')?.get('size')?.setValidators(Validators.max(this.convertFileSizeToCurrentUnit(this.uploadLimits.auth_letter.size,this.uploadLimits.auth_letter.unit)))
      }
      console.log('uploadLimits: ',this.uploadLimits)
    })
    .catch((err)=>{
      console.error('getUploadLimit: ',err)
    })
  }

  getRegistrationContracts() {
    let currentSystem = ''
    switch(this.currentSystem){
      case PBRIS: currentSystem = PBRIS;break;
      case ARTEMIS: currentSystem = ARTEMIS;break;
    }
    this.getTermsConditions(currentSystem);
    this.getPrivacyPolicy(currentSystem);
  }

  getTermsConditions(currentSystem:string) {
    // console.log('getTermsConditions '+currentSystem)
    this.terms_and_conditions_JSON = [];
    this.afs.collection("registration contracts").doc(currentSystem).collection("terms_and_conditions").get().toPromise()
    .then((data)=>{
      // console.log('getTermsConditions L: ',data.size)
      data.forEach((info) => {
        let item: any = info.data()
        item.id = info.id;

        if (this.terms_and_conditions_JSON.includes(item) == false) {
          this.terms_and_conditions_JSON.push(item);
        }
      });
      // console.log('terms_and_conditions_JSON: ',this.terms_and_conditions_JSON)
    })
    .catch((err)=>{
      console.log('getTermsConditions: ',err)
    });
  }

  getPrivacyPolicy(currentSystem:string) {
    // console.log('getPrivacyPolicy '+currentSystem)
    this.privacy_policy_JSON = [];
    this.afs.collection("registration contracts").doc(currentSystem).collection("privacy_policy").get().toPromise()
    .then((data)=>{
      // console.log('getPrivacyPolicy L: ',data.size)
      data.forEach((info) => {
        let item: any = info.data();
        item.id = info.id;

        if (this.privacy_policy_JSON.includes(item) == false) {
          this.privacy_policy_JSON.push(item);
        }
      });
      // console.log('privacy_policy_JSON: ',this.privacy_policy_JSON)
    })
    .catch((err)=>{
      console.log('getPrivacyPolicy: ',err)
    });
  }
  // !loaders


  // auto c handler
  mapper(controlName:string):Observable<any[]>{
    let formGroup:FormGroup | null = null;
    switch(controlName){
      case 'agencyInput':{
        formGroup = this.registrationAgencyForm as FormGroup
        break;
      }
    }
    try{
      if(formGroup != null && formGroup != undefined && formGroup.contains(controlName)){
        const formControl:FormControl = formGroup.get(controlName) as FormControl
        return formControl.valueChanges.pipe(
          startWith(''),
          mergeMap(value=>this._filter(controlName,value))
        )
      } else return of([])
    }
    catch(err){
      console.error('mapper: ',err)
      return of([])
    }
  }

  _filter(controlName:string,value:any){
    const filterValue = value ? value.toLowerCase() : value;
    switch(controlName){
      case 'agencyInput':{
        if(filterValue){
          const tempArr = this.agencyOptions.filter((item:any)=>item.name.trim().toLowerCase().includes(filterValue))
          return of(tempArr)
        } else return of(this.agencyOptions)
      }
      default: return of([])
    }
  }
  // !auto c handler


  // auto c display with
  getAgencyOptionName(option:any):string{
    const agency = this.agencyOptions.find((data:any)=>data.id == option)
    if(agency){
      this.setAgencyDetails(agency.id)
      this.showMotheryAgency(agency?.motherAgency ? agency.motherAgency : null)
      return agency.name
    } else{
      this.setAgencyDetails(null)
      this.showMotheryAgency(null)
      return option
    }
  }

  setAgencyDetails(agencyId:string|null){
    this.governmentForm.patchValue({
      agency:agencyId
    })
    // console.log('setAgencyDetails registrationAgencyForm',this.registrationAgencyForm.value)
    // console.log('setAgencyDetails governmentForm',this.governmentForm.value)
  }

  showMotheryAgency(motherAgency:string|null){
    console.log('showMotheryAgency motherAgency',motherAgency)
    this.registrationAgencyForm.patchValue({
      motherAgency:motherAgency
    })
    console.log('showMotheryAgency registrationAgencyForm',this.registrationAgencyForm.value)
  }
  // !auto c display with
  

  // submissions
  registerByModal(){
    //console.log('AgencyRegisterMemberComponent Register modal')
    this.register()
    // .then(()=>{

    // })
  }

  register(){
    this.loading.register = true
    this.closeButtonLoad()/* 
    console.log('AgencyRegisterMemberComponent Register')
    console.log(this.registrationForm.value) */
    
    this.toValidate()
    if(this.registrationForm.valid){
      const currentEmail = this.user.credentials.email
      let registrationForm = this.registrationForm.value
      // make all check part of cloud function
      Swal.fire({
        title:'User Authentication',
        allowOutsideClick:false,
        showCancelButton:true,
        input:'password',
        inputLabel:'Confirm Registration',
        inputPlaceholder:'Password',
        icon:'warning',
        inputValidator:async(password)=>{
          const allowConfirmation = await this.auth.signInWithEmailAndPassword(currentEmail,password).then((response)=>{
            //console.log('signin auth: ',response)
            return true
          })
          .catch((err)=>{
            console.error('signin auth: ',err)
            return false
          })
          if(!allowConfirmation) return "Incorrect Password"
          else return null
          // return ""
        },
        showConfirmButton:true,
        confirmButtonText:'Confirm',
        focusConfirm:true,
      })
      .then((result)=>{
        //console.log('result: ',result)
        if(result.isConfirmed == true){
          // pass to internal endpoint
          const apiUrl = "https://us-central1-arta-respond.cloudfunctions.net/userCreationByAdmin/api/v1/register"

          const currentPass = result.value
          Swal.fire({
            title:'Creating User',
            icon:'question',
            allowOutsideClick:false,
            didOpen:()=>{
              Swal.showLoading()
            }
          })

          const  data = {
            emailAppCode: this.currentSystem,
            registrationForm: registrationForm
          }
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            })
          };
          let unsub = this.http.post<any> (apiUrl,data,httpOptions)
          .subscribe(
            (apiResponse:any)=>{
              console.log({apiResponse})
              if(apiResponse.success == true){
                const responseData = apiResponse.data
                if(responseData.error == true){
                  Swal.fire({
                    title:responseData.message,
                    icon:'error',
                    allowOutsideClick:false,
                    confirmButtonText:'Close'
                  })
                  .then(()=>{
                    Swal.close()
                  })
                  this.loading.register = false
                  this.closeButtonLoad()
                }
                else if(responseData.limit == true || responseData.exists == true){
                  Swal.fire({
                    title:responseData.message,
                    icon:'warning',
                    allowOutsideClick:false,
                    confirmButtonText:'Close'
                  })
                  .then(()=>{
                    Swal.close()
                  })
                  this.loading.register = false
                  this.closeButtonLoad()
                }
                else if(responseData.registration == true){
                  // Swal.fire({
                  //   title:data.message,
                  //   icon:'success',
                  //   confirmButtonText:'Continue'
                  // })
                  // .then(()=>{
                  //   Swal.close()
                  //   this.closeModal()
                  // })
                  // this.loading.register = false

                  Promise.all([
                    this.uploadingFiles(responseData.uid),
                    this.emailerVerificationLink(registrationForm.credentials.email,responseData.pass)
                  ])
                  .then((postRegistrationProcess)=>{
                    let hasError = false
                    let message = ''
                    postRegistrationProcess.forEach((item:any)=>{
                      if(!item.success){
                        switch(item.code){
                          case 'upload': message+=(message?' and ':'')+'Documents failed to Upload';break;
                          case 'email': message+=(message?' and ':'')+'Failed to send Verification Email';break;
                        }
                      }
                    })
                    Swal.fire({
                      title:responseData.message+(hasError ? ' with issues: '+message : ''),
                      icon:(hasError?'warning':'success'),
                      confirmButtonText:'Continue'
                    })
                    .then(()=>{
                      Swal.close()
                      this.closeModal()
                    })
                    this.loading.register = false
                  })
                  .catch((err)=>{
                    console.log('postRegistrationProcess: ',err)
                    Swal.fire({
                      title:responseData.message+' with Errors',
                      icon:'warning',
                      confirmButtonText:'Continue'
                    })
                    .then(()=>{
                      Swal.close()
                      this.closeModal()
                    })
                    this.loading.register = false
                  })
                }
                else{
                  Swal.fire({
                    title:'Registration Error',
                    icon:'error',
                    allowOutsideClick:false,
                    confirmButtonText:'Close'
                  })
                  .then(()=>{
                    Swal.close()
                  })
                  this.loading.register = false
                  this.closeButtonLoad()
                }
              }else{
                Swal.fire({
                  title:'Registration Error',
                  icon:'error',
                  allowOutsideClick:false,
                  confirmButtonText:'Close'
                })
                .then(()=>{
                  Swal.close()
                })
                this.loading.register = false
                this.closeButtonLoad()
              }
              unsub.unsubscribe()
            },
            (err:any)=>{
              console.error('register: ',err)
              Swal.fire({
                title:'Registration Error',
                icon:'error',
                allowOutsideClick:false,
                confirmButtonText:'Close'
              })
              .then(()=>{
                Swal.close()
              })
              this.loading.register = false
              this.closeButtonLoad()
              unsub.unsubscribe()
            }
          )
        }else{
          this.loading.register = false
          this.closeButtonLoad()
        }
      })
      .catch((err)=>{
        console.error('register swal: ',err)
        console.error('alert: ',err)
        this.loading.register = false
        this.closeButtonLoad()
      })
    }
  }

  uploadingFiles(userId:string){
    const currentDate = Date.now()
    let currentSystem = ''
    switch(this.currentSystem){
      case PBRIS: currentSystem = 'pbris';break;
      case ARTEMIS: currentSystem = 'artemis';
    }
    const govFilePath = `government-id/${currentSystem}/${formatISO(currentDate)}`
    const authFilePath = `auth-letter/${currentSystem}/${formatISO(currentDate)}`
    console.log({govFilePath})
    console.log({authFilePath})
    return Promise.all([
      this.uploadToFirestorage('gov_id',govFilePath+'/'+this.uploadsForm.value.gov_id.file.name, this.uploadsForm.value.gov_id.file),
      this.uploadToFirestorage('auth_letter',authFilePath+'/'+this.uploadsForm.value.auth_letter.file.name, this.uploadsForm.value.auth_letter.file)
    ])
    .then((res)=>{
      console.log('upload res: ',res)
      let updateData:any = {}
      res.map((item)=>{
        if(item){
          switch(item.type){
            case 'gov_id':{
              updateData["file.government_id"] = item.url
              break;
            }
            case 'auth_letter':{
              updateData["file.authorization_letter"] = item.url
            }
          }
        }
      })

      if(Object.keys(updateData).length > 0){
        return this.afs.collection('Users').doc(userId).update(updateData)
        .then(()=>{
          console.log('update success: ',updateData)
          return {
            success:true,
            code:'upload'
          }
        })
        .catch((err)=>{
          console.error('update: ',err)
          return {
            success:false,
            code:'upload'
          }
        })
      } else{
        console.error('update: updateData empty')
        return {
          success:false,
          code:'upload'
        }
      }
    })
    .catch((err)=>{
      console.error('uploading: ',err)
      return {
        success:false,
        code:'upload'
      }
    })
  }

  emailerVerificationLink(email:string,pass:string){
    return this.tempAuth.signInWithEmailAndPassword(email,pass)
    .then((apiResponse)=>{
      return apiResponse.user?.sendEmailVerification({
        url: environment.verificationRedirectUrls.pbris
      })
      .then(()=>{
        this.tempAuth.signOut()
        return {
          success:true,
          code:'email'
        }
      })
      .catch((err)=>{
        console.error('verifcation emailer: ',err)
        this.tempAuth.signOut()
        return {
          success:false,
          code:'email'
        }
      })
    })
    .catch(err=>{
      this.tempAuth.signOut()
      console.error('sign in: ',err)
      return {
        success:false,
        code:'email'
      }
    })
  }

  async uploadToFirestorage(type:string, filePath:any, file:File){
    return this.store.upload(filePath,file)
    .then(async(res)=>{
      return {
        type: type,
        url: await res.ref.getDownloadURL()
      }
    })
    .catch((err)=>{
      console.error('uploadToFirestorage '+type+': ',err)
      return null
    })
  }

  toNotifications(agency:any, uid:any){
    let toNotifCollectionJSON = {
      uid:uid,
      agency_id:agency,
      event: 'New User',
      message: 'This is a notification Message',
      level: 'Level test',
      notif_type: 'Account-Creation',
      notif_in:'pbris',
    }
    this.afs.collection('Notifications').add(toNotifCollectionJSON)
  }
  // !submissions

  
  // file uploads
  dropzone(files: any, type: any){
    this.upload(files,type)
  }

  buttonUpload(event: any, type: any){
    this.upload(event.target.files,type)
  }

  upload(files: any, type: any) {
    switch(type){
      case 'gov_id': this.gov_uploadProgress = of(undefined); break;
      case 'auth_letter': this.auth_uploadProgress = of(undefined); break;
    }

    //console.log('files: ',files);
    const formGroup = this.uploadsForm.get(type) as FormGroup;
    this.toValidate(type)
    // var allowedExtensions = /(\.jpg|\.jpeg|\.pdf|\.png)$/i;
    if(files.length > 0){
      if(files.length > 1){
        const file:File = files[0];
        switch(type){
          case 'gov_id': this.display_government_id = files[0].name+', ...'; break;
          case 'auth_letter': this.display_authorization_letter = files[0].name+', ...'; break;
        }

        formGroup?.patchValue({
          file:file,
          count: files.length,
          type:'',
          size:0
        });

        switch(type){
          case 'gov_id': this.display_government_id = ''; this.gov_uploadProgress = of(undefined);break;
          case 'auth_letter': this.display_authorization_letter = ''; this.auth_uploadProgress = of(undefined);break;
        }
      }
      else{
        const file:File = files[0];
        formGroup?.patchValue({
          file:file,
          count:1,
          type:file.type,
          size:file.size
        });
      
        // let collectionName: any;
        switch (type) {
          case 'gov_id': this.display_government_id = file.name; break;
          case 'auth_letter': this.display_authorization_letter = file.name; break;
        }
        
        this.onFileRead(type,formGroup,file,formGroup.get('file')?.value)
      }
    }else{
      // switch(type){
      //   case 'gov_id': this.display_government_id = ''; break;
      //   case 'auth_letter': this.display_authorization_letter = ''; break;
      // }
      // (document.getElementById(type) as HTMLInputElement).value = "";
      // (this.uploadsForm.controls[type] as FormGroup).patchValue({
      //   count:0,
      //   type:'',
      //   size:0
      // })
      this.clearFile(type)
    } 
  }

  onFileRead(type:string, formGroup:FormGroup, file:File, store: any){
    const reader = new FileReader();
    let unitCtr = 0
    // store.unit = this.allUploadUnits[unitCtr]
    let bool = false
    let total = 0
    do{
      // console.log('unit: ',this.allUploadUnits[unitCtr])
      total = (+this.convertFileSizeToByte(file.size,this.allUploadUnits[unitCtr]))
      // console.log('tempTotal: ',total)
      if(total < 1) {
        unitCtr++
        total= (+this.convertFileSizeToByte(file.size,this.allUploadUnits[unitCtr]))
      }
      else bool = true
    }while(!bool && unitCtr < this.allUploadUnits.length)
    store.total = total
    store.unit = this.allUploadUnits[unitCtr]
    // console.log('total: ',store.total)
    store.progress = 0;
    switch(type){
      case 'gov_id':this.gov_uploadProgress = of(0);break;
      case 'auth_letter':this.auth_uploadProgress = of(0);break;
    }
    store.loaded = 0;
        
    reader.onload = (process:any) => {
      store.url = process.target.result
      if(process.lengthComputable){
        store.progress = Math.round((process.loaded / process.total) * 100);
        switch(type){
          case 'gov_id':this.gov_uploadProgress = of(store.progress);break;
          case 'auth_letter':this.auth_uploadProgress = of(store.progress);break;
        }
        
        store.loaded = this.convertFileSizeToByte(process.loaded,this.allUploadUnits[unitCtr])
      }
      formGroup.patchValue({file:store})
    }
    reader.readAsDataURL(file);
  }

  convertFileSizeToCurrentUnit(size:number, unit:string){
    switch(unit){
      case 'KB': case 'kB':{
        return size*= 1024
      }
      case 'MB': case 'mB':{
        return size*= 1024 ** 2
      }
      case 'GB': case 'gB':{
        return size*= 1024 ** 3
      }
      default: return 0
    }
  }

  convertFileSizeToByte(size:number, unit:string){
    switch(unit){
      case 'KB': case 'kB':{
        return (size/= 1024).toFixed(2)
      }
      case 'MB': case 'mB':{
        return (size/= 1024 ** 2).toFixed(2)
      }
      case 'GB': case 'gB':{
        return (size/= 1024 ** 3).toFixed(2)
      }
      default: return 0
    }
  }

  clearFile(type:any){
    switch(type){
      case 'gov_id': this.display_government_id = ''; this.gov_uploadProgress = of(undefined);break;
      case 'auth_letter': this.display_authorization_letter = ''; this.auth_uploadProgress = of(undefined);break;
    }
    const formGroup = this.uploadsForm.get(type) as FormGroup;
    formGroup?.patchValue({
      file: null,
      count: 0,
      type:'',
      size:0
    });
    (document.getElementById(type) as HTMLInputElement).value = "";
  }
  // !file uploads

  
  // validators
  checkPhoneNumber():ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>{
      if(this.credentialsForm != undefined){
        const val = control.value ? control.value : null // read '' as any in indexOf
        if(val){
          let tempbool: boolean = false
          let errors = {
            minlength: false,
            // pattern: false,
          }
          if(val.length > 0){
            if( val.length != 12){
              tempbool = true;
              errors.minlength = true;
            }
            // console.log(val.match(/(\d{0,9})/g))
            // if(){ 
            //   tempbool = true;
            //   errors.pattern = true;
            // }
          }
          return tempbool ? errors : null
        }
      }
      return null
    }
  }
  
  checkUploadType():ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>{
      if(this.uploadsForm != undefined){
        const val = control.value ? control.value : null // read '' as any in indexOf
        if(val){
          let tempbool: boolean = false
          if(!this.acceptedFileType.includes(val))
            tempbool = true
          return tempbool ? {type: true} : null
        }
      }
      return null
    }
  }
  
  withinList(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>{
      const val = control.value ? control.value : null // read '' as any in indexOf
      if(this.registrationAgencyForm != undefined){
        let tempBool:boolean = true;
        if(val){
          const ctrId = this.agencyOptions.map((info:any)=>info.id).indexOf(val)
          const ctrName = this.agencyOptions.map((info:any)=>info.name.toLowerCase()).indexOf(val.toLowerCase())

          if(ctrId >= 0){
            this.setAgencyDetails(this.agencyOptions[ctrId].id)
            this.showMotheryAgency(this.agencyOptions[ctrId]?.motherAgency ? this.agencyOptions[ctrId].motherAgency : null)
            tempBool = false
          }
          else if(ctrName >= 0){
            this.setAgencyDetails(this.agencyOptions[ctrName].id)
            this.showMotheryAgency(this.agencyOptions[ctrName]?.motherAgency ? this.agencyOptions[ctrName].motherAgency : null)
            tempBool = false
          }
          else{
            this.setAgencyDetails(null)
            this.showMotheryAgency(null)
          }

          return tempBool == true ? {notInList:true} : null ; 
        }else{
          this.setAgencyDetails(null)
          this.showMotheryAgency(null)
        }
      }
      return null
    }
  }
  // !validators
  

  // validations
  checkRegistrationAllowed(registrationForm:any){
    const account_subtype = registrationForm.credentials.account_subtype
    const agencyId = registrationForm.government.agency

    return Promise.all([this.getAgencyLimit(account_subtype,agencyId),this.getGovMembersForPosition(agencyId,account_subtype)])
    .then((response)=>{
      const govPositionLimit = response[0];
      const currentPositionCount = response[1];
      /* console.log('govPositionLimit: '+govPositionLimit)
      console.log('currentPositionCount: '+currentPositionCount) */
      if(govPositionLimit.success == true && currentPositionCount.success == true){
        if(parseInt(currentPositionCount.count) < parseInt(govPositionLimit.limit)) return {
          allow:true,
        }
        else{
          //console.warn('Position limit reached: '+account_subtype)
          return {
            allow:false,
            msg:'Position Limit Reached',
          }
        }
      }
      else{
        /* console.warn('missing check data govPositionLimit: '+govPositionLimit)
        console.warn('missing check data currentPositionCount: '+currentPositionCount) */
        return {
          allow:false,
          msg:'Registration Error',
        }
      }
    })
    .catch((err)=>{
      console.error('checkRegistrationAllowed: ',err)
      return {
        allow:false,
        msg:'Registration Error',
      }
    })
  }

  getAgencyLimit(position:string,agencyId:string){
    if(this.agency?.limits?.members[position] || this.agency?.limits?.members[position] == 0) 
      return {
        success:true,
        limit:this.agency?.limits?.members[position]
      }
    else{
      return this.afs.collection('Configuration').doc('GOVERNMENT').get().toPromise()
      .then((response)=>{
        if(response.exists){
          const limit:any = response.data()
          if(limit.ACCOUNT_MEMBERS_COUNT[position] || limit.ACCOUNT_MEMBERS_COUNT[position] == 0){
            return {
              success:true,
              limit: limit.ACCOUNT_MEMBERS_COUNT[position]
            }
          }
          else{
            //console.warn('Missing Config for position limit: '+position)
            return {
              success:false,
              limit: 0,
            }
          }
        }
        else{
          //console.warn('Missing Config GOVERNMENT ')
          return {
            success:false,
            limit: 0,
          }
        }
      })
      .catch((err)=>{
        //console.error('checkRegistrationAllowed: ',err)
        return {
          success:false,
          limit: 0,
        }
      })
    }
  }

  getGovMembersForPosition(agencyId:string,position:string):any{
    return this.afs.collection('Users').ref.where('government.agency','==',agencyId).where('credentials.account_subtype','==',position).get()
    .then((response)=>{
      //console.log('getGovMembersForPosition: ',response.size)
      return {
        success:true,
        count:response.size
      }
    })
    .catch((err)=>{
      console.error('getGovMembersForPosition: ',err)
      return {
        success:false,
        count: 0,
      }
    })
  }

  toValidate(formGroupName?:any){
    switch(formGroupName){
      case 'agency':{
        const formControl= this.registrationAgencyForm.get('agencyInput') as FormControl
        formControl.markAsTouched()
        formControl.markAsDirty()

        const govControl= this.governmentForm.get('agency') as FormControl
        govControl.markAsTouched()
        govControl.markAsDirty()
        break;
      };
      case 'personal':{
        // console.log('personalForm: ',this.personalForm.value)
        this.personalForm.markAllAsTouched()
        this.personalForm.markAsDirty()
        Object.keys(this.personalForm.controls).map((control)=>{
          if(this.personalForm.controls.hasOwnProperty(control)){
            if(this.personalForm.get(control) instanceof FormControl){
              this.personalForm.get(control)?.markAsTouched()
              this.personalForm.get(control)?.markAsDirty()
            }
          }
        })
        console.log('personalForm valid: ',this.personalForm.valid)
        if(!this.personalForm.valid)  this.caseOfFormGroupErrors(this.personalForm)
        break;
      };
      case 'government':{
        // console.log('governmentForm: ',this.governmentForm.value)
        this.governmentForm.markAllAsTouched()
        this.governmentForm.markAsDirty()
        Object.keys(this.governmentForm.controls).map((control)=>{
          if(this.governmentForm.controls.hasOwnProperty(control)){
            if(this.governmentForm.get(control) instanceof FormControl){
              this.governmentForm.get(control)?.markAsTouched()
              this.governmentForm.get(control)?.markAsDirty()
            }
          }
        })
        console.log('governmentForm valid: ',this.governmentForm.valid)
        if(!this.governmentForm.valid)  this.caseOfFormGroupErrors(this.governmentForm)
        break;
      };
      case 'credentials':{
        // console.log('credentialsForm: ',this.credentialsForm.value)
        this.credentialsForm.markAllAsTouched()
        this.credentialsForm.markAsDirty()
        Object.keys(this.credentialsForm.controls).map((control)=>{
          if(this.credentialsForm.controls.hasOwnProperty(control)){
            if(this.credentialsForm.get(control) instanceof FormControl){
              this.credentialsForm.get(control)?.markAsTouched()
              this.credentialsForm.get(control)?.markAsDirty()
            }
          }
        })
        console.log('credentialsForm valid: ',this.credentialsForm.valid)
        if(!this.credentialsForm.valid)  this.caseOfFormGroupErrors(this.credentialsForm)
        break;
      }
      case 'uploadFile':{
        this.uploadsForm.markAllAsTouched()
        this.uploadsForm.markAsDirty()
        Object.keys(this.uploadsForm.controls).map((control)=>{
          if(this.uploadsForm.controls.hasOwnProperty(control)){
            if(this.uploadsForm.get(control) instanceof FormControl){
              this.uploadsForm.get(control)?.markAsTouched()
              this.uploadsForm.get(control)?.markAsDirty()
            }
          }
        })
        this.toValidate('gov_id')
        this.toValidate('auth_letter')
        break;
      }
      case 'gov_id': case 'auth_letter':{
        const formGroup = this.uploadsForm.get(formGroupName) as FormGroup
        formGroup.markAllAsTouched()
        formGroup.markAsDirty()
        Object.keys(formGroup.controls).map((control)=>{
          if(this.uploadsForm.controls.hasOwnProperty(control)){
            if(formGroup.get(control) instanceof FormControl){
              formGroup.get(control)?.markAsTouched()
              formGroup.get(control)?.markAsDirty()
            }
          }
        })
        if(!this.uploadsForm.valid) this.caseOfFormGroupErrors(formGroup)
        break;
      }
      case 'tnc':{
        this.tncForm.markAllAsTouched()
        this.tncForm.markAsDirty()
        Object.keys(this.tncForm.controls).map((control)=>{
          if(this.tncForm.controls.hasOwnProperty(control)){
            if(this.tncForm.get(control) instanceof FormControl){
              this.tncForm.get(control)?.markAsTouched()
              this.tncForm.get(control)?.markAsDirty()
            }
          }
        })
        console.log('tncForm valid: ',this.tncForm.valid)
        if(!this.tncForm.valid)  this.caseOfFormGroupErrors(this.tncForm)
        break;
      }
      case 'privatePolicy':{
        this.privatePolicyForm.markAllAsTouched()
        this.privatePolicyForm.markAsDirty()
        Object.keys(this.privatePolicyForm.controls).map((control)=>{
          if(this.privatePolicyForm.controls.hasOwnProperty(control)){
            if(this.privatePolicyForm.get(control) instanceof FormControl){
              this.privatePolicyForm.get(control)?.markAsTouched()
              this.privatePolicyForm.get(control)?.markAsDirty()
            }
          }
        })
        console.log('privatePolicyForm valid: ',this.privatePolicyForm.valid)
        if(!this.privatePolicyForm.valid)  this.caseOfFormGroupErrors(this.privatePolicyForm)
        break;
      }
      default:{
        this.registrationForm.markAllAsTouched()
        this.registrationForm.markAsDirty()
        this.toValidate('agency')
        this.toValidate('personal')
        this.toValidate('credentials')
        this.toValidate('government')
        this.toValidate('uploadFile')
        this.toValidate('tnc')
        this.toValidate('privatePolicy')
        break;
      }
    }
  }

  hasError(codeName:any,controlName:any, validType:any, groupName?:any){
    switch(codeName){
      case 'personal':{
        return ((this.personalForm.get(controlName) as FormControl).hasError(validType) 
        && ((this.personalForm.get(controlName) as FormControl).dirty 
        || (this.personalForm.get(controlName) as FormControl).touched));
      }
      case 'credentials':{
        return ((this.credentialsForm.get(controlName) as FormControl).hasError(validType) 
        && ((this.credentialsForm.get(controlName) as FormControl).dirty 
        || (this.credentialsForm.get(controlName) as FormControl).touched));
      }
      case 'government':{
        return ((this.governmentForm.get(controlName) as FormControl).hasError(validType) 
        && ((this.governmentForm.get(controlName) as FormControl).dirty 
        || (this.governmentForm.get(controlName) as FormControl).touched));
      }
      case 'agency':{
        return ((this.registrationAgencyForm.get(controlName) as FormControl).hasError(validType) 
        && ((this.registrationAgencyForm.get(controlName) as FormControl).dirty 
        || (this.registrationAgencyForm.get(controlName) as FormControl).touched));
      }
      case 'uploads':{
        const formGroup = this.registrationAgencyForm.get(groupName) as FormGroup;
        if(formGroup){
          const formControl = (formGroup.get(controlName) as FormControl);
          switch(controlName){
            case 'size':{
              return (formControl?.hasError(validType == 'maxSize' ? 'max' : validType) && (formControl?.dirty || formControl?.touched));
            };
            default:
              return (formControl?.hasError(validType) && (formControl?.dirty || formControl?.touched));
          }
        } return null
      }
      case 'gov_id':{
        return ((this.govIdForm.get(controlName) as FormControl).hasError(controlName ==  'size' ? validType == 'maxSize' ? 'max' : validType : validType) 
        && ((this.govIdForm.get(controlName) as FormControl).dirty 
        || (this.govIdForm.get(controlName) as FormControl).touched));
      }
      case 'auth_letter':{
        return ((this.authLetterForm.get(controlName) as FormControl).hasError(controlName ==  'size' ? validType == 'maxSize' ? 'max' : validType : validType) 
        && ((this.authLetterForm.get(controlName) as FormControl).dirty 
        || (this.authLetterForm.get(controlName) as FormControl).touched));
      }
      default:return null
    }
  }
  
  caseOfFormGroupErrors(formGroup:FormGroup){
    Object.keys(formGroup.controls).forEach((control)=>{
      const controlErrors = formGroup.get(control)?.errors;
      console.log('controlErrors: ',controlErrors)
      if(controlErrors){
        Object.keys(controlErrors).forEach((keyError)=>{
          console.log('control: '+control+', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        })
      }
    })
  }
  // !validations

  closeButtonLoad(){
    this.closeButtonLoadEmitter.emit(this.loading.register)
  }

  closeModal(){
    this.closeModalEmitter.emit()
  }

  ngOnDestroy(): void {
      
  }
}