<!--NAVBAR-->
<app-artemis-navigation-header
  [currentPage]="'ContactUs'"
></app-artemis-navigation-header>

<div class="content">
  <div class="container">
    <!-- TABLE OF CONTENTS -->
    <div class="card shadow my-5">
      <div class="card-header text-primary theme-default">
        <h2 class="fw-bold">TABLE OF CONTENTS</h2>
      </div>
      <div class="card-body p-3 bg-gray rights-container">
        <ul type="none">
          <li><a [routerLink]="" fragment="ContactUs">Contact Us</a></li>
          <li>
            <a [routerLink]="" fragment="ForComplaintRelatedInquiries"
              >For Complaint related inquiries</a
            >
          </li>
          <li><a [routerLink]="" fragment="Telephone">Telephone</a></li>
          <li><a [routerLink]="" fragment="SocialMedia">Social Media</a></li>
        </ul>
      </div>
    </div>

    <!-- ARTEMIS -->
    <div id="ContactUs" class="card shadow my-5">
      <div class="card-header text-primary theme-default">
        <h5 class="fw-bold">
          THE ANTI-RED TAPE ELECTRONIC MANAGEMENT INFORMATION SYSTEM
        </h5>
        <h2 class="fw-bold">CONTACT US</h2>
      </div>
      <div class="card-body p-3 bg-gray rights-container">
        <p class="m-3">
          For any questions or concerns regarding the ARTEMIS or a Citizen’s
          Charter of any agency, you may reach us at
          <a class="fw-bold" href="mailto:compliance@arta.gov.ph"
            >compliance@arta.gov.ph</a
          >.
        </p>
        <p><strong>Office Address:</strong></p>
        <p class="m-3">
          4th & 5th Floor, NFA Building, NFA Compound, Visayas Avenue, Brgy.
          Vasra, Diliman, Quezon City, Philippines 1128
        </p>
      </div>
    </div>

    <div id="ForComplaintRelatedInquiries" class="card shadow my-5">
      <div class="card-header text-primary theme-default">
        <h5>For Complaint related inquiries</h5>
      </div>
      <div class="card-body p-3 bg-gray">
        <p class="m-3">
          Email us at <a href="mailto:complaints@arta.gov.ph"
            >complaints@arta.gov.ph</a
          >
        </p>
        <p class="m-3">
          To file a complaint, please click
          <a href="https://arta.gov.ph/complaint-form/">here</a>.
        </p>
      </div>
    </div>

    <div id="Telephone" class="card shadow my-5">
      <div class="card-header text-primary theme-default">
        <h5>Telephone</h5>
      </div>
      <div class="card-body p-3 bg-gray">
        <ul class="ms-3">
          <li>8478-5091</li>
          <li>8478-5093</li>
          <li>8478-5099</li>
        </ul>
      </div>
    </div>

    <div id="SocialMedia" class="card shadow my-5">
      <div class="card-header text-primary theme-default">
        <h5>Social Media</h5>
      </div>
      <div class="card-body p-3 bg-gray">
        <ul class="ms-4">
          <li>
            Facebook
            <a href="https://www.facebook.com/ARTAPh/">@ARTAPh</a>
          </li>
          <li>
            Instagram
            <a href="https://www.instagram.com/artagovph/">@artagovph</a>
          </li>
          <li>
            Twitter
            <a href="https://twitter.com/ARTAgovph">@artagovph</a>
          </li>
          <li>
            Youtube
            <a
              href="https://www.youtube.com/channel/UChQr6Tl3lqcKfMd4ANNN75w?view_as=subscriber"
              >@Anti-Red Tape Authority</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
