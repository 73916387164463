import { DecimalPipe } from '@angular/common';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { first, startWith, map } from 'rxjs/operators';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { Component, OnInit, PipeTransform } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { ReferenceCommonModalComponent } from './modal/common-modal/reference-common-modal.component';
import { secondsToHours } from 'date-fns';
import { Observable } from 'rxjs';
import { ARTEMIS, PBRIS } from '../entities/constant';
@Component({
  selector: 'app-reference-control',
  templateUrl: './reference-control.component.html',
  styleUrls: ['./reference-control.component.scss']
})
export class ReferenceControlComponent implements OnInit {
  pbris = PBRIS
  artemis = ARTEMIS
  currentSystem:any = sessionStorage.getItem('currentSystem');

  loader: boolean = false;
  agencies: any[] = []

  hidesubcontrol:boolean =false

  isAgency: boolean = false;
  isAdmin: boolean = false;

  // division controller set to false, load the needed files then set to true
  artaType: boolean = false;
  pbrisType: boolean = false;
  //

  userType: any = sessionStorage.getItem("user_type");


  //common collection variable
  sectors: any[]=[];
  sectorsR: any[]=[];

  orgCodes: any[] = [];
  orgCodesR: any[] = [];

  locationCodes: any[] = [];
  locationCodesR: any[] = [];

  psicCodes: any[] = [];
  psicCodesR: any[] = [];
  //
  modalRef?: MDBModalRef;

  sectorObs$: Observable<any[]>;
  sectorFilter = new FormControl('')

  constructor(
    private afs: AngularFirestore,
    private router: Router,
    public auth: AngularFireAuth,
    public formBuilder: FormBuilder,
    public modalService: MDBModalService,
    public pipe: DecimalPipe,
    ) { } 

  ngOnInit(): void {
    const adminSession:any = sessionStorage.getItem("arta_admin")
    console.log("this.userType ",this.userType)
    if(this.userType === 'arta'){
      this.artaType = true
      this.pbrisType = false
      if(adminSession === 'true'){
        this.isAdmin = true
      }
    }else if(this.userType === 'agency'){
      this.artaType = false
      this.pbrisType = true
    }
    console.log(this.isAdmin)
  }

  //reroutes
  toHome(){
    this.router.navigate(['/']) 
  }

  hideSubCntrl(value:boolean){
    this.hidesubcontrol =value
  }
  //
}