import { Component, Input, OnInit, Output } from '@angular/core';
import { RequirementType, RequriementsService } from 'src/app/service/requriements.service';
import { NewRequirementsModalComponent } from '../new-requirements-modal/new-requirements-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';

@Component({
  selector: 'app-situational-requirement-control',
  templateUrl: './situational-requirement-control.component.html',
  styleUrls: ['./situational-requirement-control.component.scss']
})
export class SituationalRequirementControlComponent implements OnInit {

  @Input() situation : any
  @Input() situationIndex: any
  @Input() referenceArray:any
  enum_requirement_type = RequirementType

  title = new FormControl('',Validators.required)

  hideButtons: boolean =false

  constructor(
    public requirementService: RequriementsService,
    private dialog: MatDialog,
    private snackbar : SnackbarNotifService
  ) { }

  ngOnInit(): void {
  }


  pasteRequirement(){
    this.requirementService.pasteRequirement(this.situation.requirement,'requirement_copy',this.situation.requirement.length + 1)
  }

  pasteSituation(){

    this.requirementService.pasteRequirement(this.situation.subRequirement,'situation_copy',this.referenceArray.length + 1)

  }

  copySituation(){

    this.requirementService.duplicate(this.situation, this.referenceArray)

  }

  removeRequirement(){

    this.snackbar.confirmDialog("Remove Requirement").then(result => {
      if (result){
        this.requirementService.removeRequirement(this.referenceArray,this.situationIndex)
      
      }
   })
  }



  updateSituationalRequirement(){

   //this.requirementService.updateSituationalRequirement(this.situation, this.situationIndex,this.referenceArray,RequirementType.SITUATIONAL_REQUIREMENT);

    this.requirementService.newStandardRequirement(
      this.situation.requirement,RequirementType.STANDARD_NO_TITLE
    )
    

  }


  addSituational() {


    let situational_req : any = {
      title : "Situational Requirement",
      requirement: []
    }

    this.situation.subRequirement.push(situational_req)

    

    /**
     * let result = this.dialog.open(NewRequirementsModalComponent, {
      width: '98vw',
      minHeight: '20vh',
      disableClose: true,
      data: {
        isSituational: true,
        requirementIndex: 0,
        subsituational: true,
      },
    });

    result.afterClosed().subscribe(
      (data: any) => {
        if (data.submitFlag) {

          if(!this.situation.subRequirement){
            this.situation.subRequirement = []
          }
          this.requirementService.pushSubSituational(
            this.situation.subRequirement,
            data
          );

          console.log(this.situation)
        }
      },
      (error) => {
        console.log(error);
      }
    );
     */
  }

}
