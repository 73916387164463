import { Component, OnChanges,Input } from '@angular/core';

@Component({
  selector: 'app-view-charter-public-staffs',
  templateUrl: './view-charter-public-staffs.component.html',
  styleUrls: ['./view-charter-public-staffs.component.scss']
})
export class ViewCharterPublicStaffsComponent implements OnChanges {
  isLoaded:boolean = false

  headers:any=[
    {name: 'Agency Head', value:'agency_head'},
    {name: 'Chairperson', value:'chairperson'},
    {name: 'Vice-Chairperson', value:'vice_person'},
    {name: 'Member/s', value:'members'},
  ]

  @Input() charter_list:any;
  @Input() agency_details:any;

  constructor() { }

  ngOnChanges(): void {
    if(this.charter_list && this.agency_details){
      this.isLoaded = true;   
      // console.log('charter_list: ', this.charter_list )
      // console.log('agency_details: ', this.agency_details)
    }
  }

  getAcronym(str:any){
    let matches = str.match(/\b(\w)/g);
    let acronym = matches.join('');
    return acronym;
  }

}
