import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl, FormGroup } from '@angular/forms';
import { CitizensCharterCommentStatus } from 'src/app/entities/worklist';
import Swal from 'sweetalert2';
import { filter, tap } from 'rxjs/operators';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {
  GovernmentAccountSubtype
} from 'src/app/entities/worklist';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { resolve } from 'dns';

enum CommentStatus {
  ALL_COMMENTS = 0,
  RESOLVED = 1,
  UNRESOLVED = 2

}


@Component({
  selector: 'app-artemis-comment-view',
  templateUrl: './artemis-comment-view.component.html',
  styleUrls: ['./artemis-comment-view.component.scss']
})
export class ArtemisCommentViewComponent implements OnInit {

  //Mat table with pagination
  results: any = []; // main countainer for the inbox results list
  allResults: any = [];
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  displayedColumns = ['No', 'Name', 'Service', 'UserType', 'DateGenerated', 'Status', 'Actions']
  has_actions: boolean = false;
  account_type: string = '';
  enum_account_subtype = GovernmentAccountSubtype;
  rowNumber = 0;
  isAgency: boolean = false;
  SelectedStatus: CommentStatus = CommentStatus.ALL_COMMENTS;
  searchkeyword:any = ""

  //commentList: any;
  //Modal
  url: any;
  user: any = [];
  comment: any;
  document: any;
  type: any;
  id: any;
  reply: any;
  files: any = [];
  status: string = '';
  enum_comment_status = CitizensCharterCommentStatus;

  loading: boolean = false;
  fetching: boolean = false;

  form = new FormGroup({
    reply: new FormControl(''),
  });

  constructor(
    private afs: AngularFirestore,
    private store: AngularFireStorage,
    private _liveAnnouncer: LiveAnnouncer,
  ) {
    this.account_type = sessionStorage.getItem('user_type') || '';
    if (this.account_type == 'agency') {
      this.isAgency = true;
    }

  }

  ngOnInit(): void {

    this.loadCommentList('');

    /**
     * this.getComments().subscribe(data => {
     this.commentList = data.map(e => {
       return {
         id: e.payload.doc.id,
         data: e.payload.doc.data()
       };
     })
   })
     */


  }

  filterBy(keyword:any){
    this.searchkeyword = keyword
    this.dataSource = new MatTableDataSource(keyword? this.results.filter((filter:any) => filter.statusIndex.includes(keyword)): this.results);
    this.resetSortPage()
  }

  //load all agency comments,
  //changed table status filter process from query to system settingFilter

  loadCommentList(status: string) {

    this.rowNumber = 0;
    let commentfilter: QueryFn;

    //Default sort set unresolved always comments on top

    //collect data based on status selected
    //if (status.trim() != ''){

    //commentfilter = (filter) =>
    // filter
    //   .where('type.type_agencyId', '==', sessionStorage.getItem('agency_id'))
    //  .where('status','==',status)

    // }else{

    //collect all comments based on current user agency

    //if (this.account_type == 'agency'){

    // commentfilter = (filter) =>
    // filter
    //   .where('type.type_agencyId', '==', sessionStorage.getItem('agency_id'))
    //   .orderBy('status','desc')

    //}else{

    // commentfilter = (filter) =>
    //   filter
    //    .orderBy('status','desc')


    // }
    
    //}

    //removed commentFilter

    this.afs
      .collection('Comment Artemis', ref => ref.where('type.type_agencyId', '==', sessionStorage.getItem('agency_id'))
        .orderBy('status', 'desc'))
      .snapshotChanges()
      .pipe(tap((data:any)=>{
        //clear table before adding new snapshot 
        this.results= []
        data.forEach((info:any)=>{
            let item:any = info.payload.doc.data();
            item.id = info.payload.doc.id
            this.rowNumber++
            item.no  = this.rowNumber.toString()

            if(item.status == 'RESOLVED'){
              item.statusIndex = "0";
            }else{
              item.statusIndex = "1";
            }      
            this.results.push(item)
        })

        this.filterBy(this.searchkeyword)
      })).subscribe();
      

  }

  sortChange(sort: Sort) {
    // console.log('sort', sort)
    const data = this.results.slice()
    if (!sort.active || sort.direction === '') {
      this.allResults = data
      return;
    }
    this.allResults = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc'
      switch (sort.active) {
        case 'DateGenerated':
          return this.compare(a.created_at.trim().toLowerCase(), b.created_at.trim().toLowerCase(), isAsc)
        case 'Service':
          return this.compare(a.type.type_title.trim().toLowerCase(), b.type.type_title.trim().toLowerCase(), isAsc)
        case 'Status':
          return this.compare(a.status.trim().toLowerCase(), b.status.trim().toLowerCase(), isAsc)
        default:
          return 0
      }
    })
    this.dataSource = new MatTableDataSource<any>(this.allResults)
    this.resetSortPage()
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }



  resetSortPage() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort
    this.settingFilter();
  }

  settingFilter() {
    this.dataSource.filterPredicate = (data: any, filter: string): boolean => {
      return data.type.type_title.toLowerCase().includes(filter.toLowerCase())
      || data.status.toLowerCase().includes(filter.toLowerCase())
      || data.user.name.toLowerCase().includes(filter.toLocaleLowerCase())
      || data.comments.user.toLowerCase().includes(filter.toLocaleLowerCase())

    }
  }


  openModal(data: any) {
    this.files = [];
    this.url = data.comments.url;
    this.user = data.user
    this.comment = data.comments.comment;
    this.type = data.type
    this.reply = data.reply;
    this.status = data.status;
    this.id = data.id;
    this.fetching = true;
    if (data.comments.path && data.comments.document) {
      this.getFiles({
        path: data.comments.path,
        documents: data.comments.document
      });
    } else {
      this.fetching = false;
      this.files = [];
    }
  }

  closeModal() {
    this.files = [];
  }

  getFiles(data: any): void {
    for (let i = 0; i < data.documents.length; i++) {
      this.store.ref(`${data.path}${data.documents[i].name}`)
        .getDownloadURL()
        .subscribe((url: string) => {
          this.files.push({ name: data.documents[i].name, url: url })
        }, err => { console.log("Error", err) });
    }
    this.fetching = false;
  }

  /**
   * change comments container to mattable datasource also apply filter,
   * this method is now replace with loadCommentList
   *  getComments() {
     return this.afs.collection('Comment Artemis').snapshotChanges();
   }
   */

  submit(data: any) {
    this.loading = true;
    Swal.fire({
      title: 'Processing',
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();

        this.afs.collection('Comment Artemis')
          .doc(data)
          .update({
            reply: this.form.value.reply,
            status: CitizensCharterCommentStatus.RESOLVED,
            resolved_at: formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0800')
          })
          .then((docRef: any) => {
            this.loading = false;
            Swal.close();
            Swal.fire({
              title: 'Response Submitted',
              icon: 'info'
            });
            this.resetForm();

          }).catch((error: any) => {
            this.loading = false;
            Swal.close();
            Swal.fire({
              title: 'System error',
              titleText: 'Response not submitted!',
              icon: 'error'
            });
          });
      }
    })
  }

  resetForm() {
    this.form.reset();
  }
}
