import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-consultation-comment-modal',
    templateUrl: './consultation-comment-modal.component.html',
    styleUrls: ['./consultation-comment-modal.component.scss']
})
export class ConsultationCommentModal implements OnInit{

    recaptchaV2SiteKey: string = environment.recaptchaSiteKeys.v2;
    captcha: any;

    constructor(
        public dialogRef: MatDialogRef<ConsultationCommentModal>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ){ }
    
    ngOnInit(): void {
        // console.log('ConsultationCommentModal',this.data)
    }

    goBack(){
        this.dialogRef.close({
            resultFlag: false,
        })
    }

    onSubmit(){
        this.dialogRef.close({
            resultFlag: true,
            resultCaptcha: this.captcha,
        })
    }

    public resolved(captchaResponse: string): void {
        this.captcha = captchaResponse;
        console.log(`Resolved captcha with response: ${this.captcha}`);
      }
    
      public onError(errorDetails: RecaptchaErrorParameters): void {
        console.log(`reCAPTCHA error encountered; details:`, errorDetails);
      }
}