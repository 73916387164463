import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-artemis-by-agency-report',
  templateUrl: './artemis-by-agency-report.component.html',
  styleUrls: ['./artemis-by-agency-report.component.scss']
})
export class ArtemisByAgencyReportComponent implements OnInit {
  collection_service: any;
  agency_list: any = []

  service:any = [];
  simple_svc:any = []  
  complex_svc:any = []
  high_tech_svc:any = [] 
  high_tech_excess_svc:any = []

  cc:any[];

  svc_count:any;
  simple_svc_count: any;
  complex_svc_count: any;
  high_tech_svc_count: any;
  high_tech_excess_svc_count: any;

  constructor(private afs: AngularFirestore, public auth: AngularFireAuth) { }

  ngOnInit(): void {
    this.loadAgencies()
  }

  ngOnDestroy(): void{
    this.collection_service.unsubscribe()
  }

  updateAgency(entry: any){
    let agency = entry.toUpperCase()
    //console.log(agency)
    this.loadServices(agency)
  }

  async loadAgencies() {
    let collect:any = "Agency";
  

    this.collection_service = this.afs.collection(collect).snapshotChanges()
      .pipe(
        tap(
          (data: any) => {
            this.agency_list = []
            data.forEach((info: any) => {
            

              let item: any = info.payload.doc.data();
              item.id = info.payload.doc.id;
              
              this.agency_list.push(item);
            });

            
          }

          
        )
      )
      .subscribe()

      

  }

  async loadServices(agency: any) {
    let collect:any = "Services Phase 02";
  

    this.collection_service = this.afs.collection(collect).snapshotChanges()
      .pipe(
        tap(
          (data: any) => {
            this.service = [];
            this.simple_svc = []  
            this.complex_svc = []
            this.high_tech_svc = [] 
            this.high_tech_excess_svc = []
            data.forEach((info: any) => {
            

              let item: any = info.payload.doc.data();
              item.id = info.payload.doc.id;
              let agency_look_up = (item['serviceDetails']['agency']).toUpperCase()
              
              //this.loadCC(item['citizens_charter'])

              //if(agency_look_up === agency){
                this.service.push(item);
              // console.log()
              /**
               * load un-posted services for editing & approving
               */ 
               if(item['serviceDetails']['classification'].trim().toUpperCase() === "SIMPLE"){
                
                this.simple_svc.push(item)
              }
               
              else if(item['serviceDetails']['classification'].trim().toUpperCase() === "COMPLEX"){
                this.complex_svc.push(item)
              }
              else if(item['serviceDetails']['classification'].trim().toUpperCase() === "HIGHLY - TECHNICAL"){
                 
                this.high_tech_svc.push(item)
              }
              else if(item['serviceDetails']['classification'].trim().toUpperCase() === "HIGHLY - TECHNICAL EXCEEDING 20 DAYS"){
                 
                this.high_tech_excess_svc.push(item)
              }
              //}
            });

            this.svc_count = this.service.length
            this.simple_svc_count = this.simple_svc.length  
            this.complex_svc_count = this.complex_svc.length
            this.high_tech_svc_count = this.high_tech_svc.length 
            this.high_tech_excess_svc_count = this.high_tech_excess_svc.length
          }

          
        )
      )
      .subscribe()

      

  }

  async loadCC(id: any) {
    let collect:any = "Worklist-Citizens Charter";

    let data = this.afs.collection(collect).doc(id).get()
      data.subscribe(info => {
        //console.log(info.get('name'))
      })
    
    //let collect:any = "Worklist-Citizens Charter";
  

    /*this.collection_service = this.afs.doc(`$collect/$id`)
      .pipe(
        tap(
          (data: any) => {
            this.cc = [];
    
            data.forEach((info: any) => {
            

              let item: any = info.payload.doc.data();
              item.id = info.payload.doc.id;
              let agency_look_up = (item['fromAgency']).toUpperCase()
              
              console.log(agency_look_up)
              if(agency_look_up === agency){
                this.cc.push(item);

                

              console.log()
             
               if(item['serviceDetails']['classification'].trim().toUpperCase() === "SIMPLE"){
                
                this.simple_svc.push(item)
              }
               
              else if(item['serviceDetails']['classification'].trim().toUpperCase() === "COMPLEX"){
                this.complex_svc.push(item)
              }
              else if(item['serviceDetails']['classification'].trim().toUpperCase() === "HIGHLY - TECHNICAL"){
                 
                this.high_tech_svc.push(item)
              }
              else if(item['serviceDetails']['classification'].trim().toUpperCase() === "HIGHLY - TECHNICAL EXCEEDING 20 DAYS"){
                 
                this.high_tech_excess_svc.push(item)
              }
              }
            });

            this.svc_count = this.service.length
      this.simple_svc_count = this.simple_svc.length  
      this.complex_svc_count = this.complex_svc.length
      this.high_tech_svc_count = this.high_tech_svc.length 
      this.high_tech_excess_svc_count = this.high_tech_excess_svc.length
          }

          
        )
      )
      .subscribe()*/

      

  }

}
