<div class="content col-md-12">
    <div class="container-fluid">    

        <div class="row">
            <div class="card padded">
                <div class="card-header info-header">
                    <div class="row">
                        <div class="ms-3">
                            <h6 class="fw-bold">Text of the Regulation</h6>
                            
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div class="row">

                        <!-- Section & Subsection -->
                        <div class="row table-responsive" *ngFor="let section of reg_text; let secidx = index">
                            <table class="table table-striped table-bordered">
                                <thead class="info-subheader">
                                    <tr></tr>
                                </thead>

                                <tbody class="info-subheader">
                                    <tr>
                                        <th>
                                            <h6>{{section.info.section_title}}
                                                <!-- <span>
                                                <button class="btn btn-primary btn-sm ms-4" *ngIf="commentButtonsEnabled" aria-label="Comment on this section" (click)="goToComments('regpia',secidx)">
                                                    <i class="fas fa-comments me-2"></i>add comment
                                                </button>
                                                </span> -->
                                            </h6>
                                        </th>
                                    </tr>

                                    <tr *ngFor="let type of section.info.section_type; let i = index">
                                        <td><ng-container *ngIf="i > 0">, </ng-container>{{type}}</td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div [innerHtml]="section.info.section_text"></div>
                                            <ng-container *ngFor="let subsection of section.subsections; let subsecidx = index">
                                                <div class="row" style="padding-left:2em;">
                                                    <label class="form-input-header fw-bold" for="feedback_remark">
                                                    <h6 class="section-header-link fw-bold mb-3">{{subsection.subsection_title}}
                                                        <!-- <button *ngIf="commentButtonsEnabled" mat-icon-button aria-label="Comment on this subsection" (click)="goToComments('regtext',secidx,subsecidx)">
                                                        <mat-icon>edit</mat-icon>
                                                        </button> -->
                                                    </h6>
                                                    </label>
                                                    <div class="row" style="padding-left:2em;">
                                                        <div [innerHtml]="subsection.subsection_text"></div>
                                                        <div class="d-flex justify-content-center" *ngIf="subsection.fileUrl">
                                                            <img style="width:200px;" [src]="subsection.fileUrl">
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
            
                        <ng-container *ngIf="reg_text_annex_file && reg_text_annex_file.has_annex_files">
                            <div class="card padded">
                                <div class="card-header info-header">
                                    <div class="row">
                                    <div class="ms-3">
                                        <h6 class="fw-bold">Annexes to Regulation</h6>
                                    </div>
                                    </div>
                                </div>

                                <div class="row card-body">
                                    <ng-container *ngIf="reg_text_annex_file.annex_file || reg_text_annex_file.annex_file_download_link; else noAnnexFile">
                                        <div class="d-flex justify-content-center"  *ngIf="reg_text_annex_file.annex_file_type == 'zip'; else regTextNotZipFile">
                                            <button class="btn btn-primary" type="button" (click)="downloadFile(reg_text_annex_file.annex_file_download_link,reg_text_annex_file.annex_file_name,'reg_text_annex')">
                                                Annexes to Regulation - Download: {{reg_text_annex_file.reg_text_annex_file_name}}
                                            </button>
                                        </div>
                                        <ng-template #regTextNotZipFile>
                                            <div class="py-2 px-0" >
                                                <div class="col-1">
                                                    <button *ngIf="reg_text_annex_file.annex_file" (click)="pageShift('back','reg_text_annex')" type="button" class=" h-100 left-button btn-dark">
                                                        <i class="fas fa-chevron-left"></i>
                                                    </button>
                                                </div>
                                                <div class=" col-10">
                                                    <div *ngIf="reg_text_annex_file.annex_file" >
                                                        <div class="d-flex justify-content-center"><p>{{reg_text_annex_file.annex_file_page}} / {{reg_text_annex_file.annex_file_total_page ? reg_text_annex_file.annex_file_total_page : "1"}}</p></div>
                                                            <pdf-viewer *ngIf="reg_text_annex_file.annex_file"
                                                            style="min-height: 1070px; max-height: 1200px;"
                                                            (after-load-complete)="afterLoadComplete($event,'reg_text_annex')"
                                                            [show-all]="false"
                                                            [page]="reg_text_annex_file.annex_file_page"
                                                            [stick-to-page]="true"
                                                            [src]="reg_text_annex_file.annex_file"></pdf-viewer>
                                                        <div class="d-flex justify-content-center"><p>{{reg_text_annex_file.annex_file_page}} / {{reg_text_annex_file.annex_file_total_page ? reg_text_annex_file.annex_file_total_page : "1"}}</p></div>
                                                    </div>
                                                </div>
                                                <div class="col-1">
                                                    <button *ngIf="reg_text_annex_file.annex_file" (click)="pageShift('next','reg_text_annex')" type="button" class=" h-100 right-button btn-dark">
                                                        <i class="fas fa-chevron-right"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                    <ng-template #noAnnexFile>
                                        <div class="card d-flex justify-content-center" style="padding:1em;" >
                                            <div class="row" style="padding:0.5em;">
                                                <label class="form-input-header fw-bold">
                                                    <h6><b>No Annexes to Regulation File</b></h6>
                                                </label>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </ng-container>
                        
                    </div>
                </div>
            
            </div>
        </div>
    
    </div>
</div>