<div class="container">
    <div class="row">

        <div class="col-md">

            <!-- Carousel -->
            <div id="carouselExampleCaptions" class="carousel slide carousel-fade" data-mdb-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-mdb-target="#carouselExampleCaptions" data-mdb-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-mdb-target="#carouselExampleCaptions" data-mdb-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-mdb-target="#carouselExampleCaptions" data-mdb-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src="/assets/01.jpg" class="d-block w-100" alt="..." />
                        <div class="carousel-caption">
                            <h1 class="text-uppercase">Article Title One</h1>
                            <h4 class="text-uppercase">First article</h4>
                            <a href="#read" class="btn btn-lg btn-rounded btn-danger">Read now</a>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="/assets/02.jpg" class="d-block w-100" alt="..." />
                        <div class="carousel-caption">
                            <h1 class="text-uppercase">Article Title Two</h1>
                            <h4 class="text-uppercase">Second article</h4>
                            <a href="#read" class="btn btn-lg btn-rounded btn-danger">Read now</a>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="/assets/03.jpg" class="d-block w-100" alt="..." />
                        <div class="carousel-caption">
                            <h1 class="text-uppercase">Article Title Three</h1>
                            <h4 class="text-uppercase">Third article</h4>
                            <a href="#read" class="btn btn-lg btn-rounded btn-danger">Read now</a>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-mdb-target="#carouselExampleCaptions" data-mdb-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-mdb-target="#carouselExampleCaptions" data-mdb-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>

        <div class="col-sm">


            <!-- Login Card -->
            <div class="card shadow-sm">
                <div class="card-body">
                    <form [formGroup]="form">
                        <div class="form-group m-3">
                            <h1 class="text-center">Forgot Password</h1>
                        </div>
                        <div class="form-group m-3">
                            <p class="text-justify">
                                We will send a confirmation message to your email.
                            </p>
                            <div class="form-group">
                                <label for="username">Email address</label>
                                <input type="email" class="form-control" formControlName="username" />
                                <!--  [ngClass]="{ 'is-invalid': submitted && f.username.errors }" -->
                                <div class="invalid-feedback">
                                    <!-- *ngIf="submitted && f.username.errors"  -->
                                    <div>Username is required</div>
                                    <!-- *ngIf="f.username.errors.required" -->
                                </div>
                            </div>

                        </div>
                        <div class="form-group row mx-3">
                            <button class="btn btn-outline-primary col m-1" (click)="sendPasswordResetEmail()">
                        Send Reset Password Link
                        </button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</div>