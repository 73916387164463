<!-- <app-wel-navbar></app-wel-navbar> -->
<!-- <app-artemis-navigation-header *ngIf="currentSystem == artemis"></app-artemis-navigation-header> -->
<app-navbar *ngIf="currentSystem == pbris"></app-navbar>

<!-- <div class="content">
    <div [innerHtml]="configForm.value.testContent"></div>
    <hr>
    <div [formGroup]="configForm" class="form-group container-fluid p-3 d-flex flex-column justify-content-evenly">
        <div class="d-flex flex-row align-items-center">
            <label for="testSubject">Subject:</label> <input type="text" name="subject" id="subject" formControlName="subject" class="form-control">
        </div>
        <quill-editor id="quillEditor" name="quillEditor" formControlName="content" (onEditorCreated)="editorCreated($event)"></quill-editor>
        [config]="editorConfig"
        <ckeditor  [editor]="Editor" formControlName="testContent"></ckeditor>
        <editor formControlName="testContent"
            [init]="{
                base_url: '/tinymce',
                suffix: '.min',
                height: 400,
                menubar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste help wordcount',
                    'hr twigvariable'
                ],
                toolbar: 'undo redo | formatselect | bold italic backcolor | '+
                    'alignleft aligncenter alignright alignjustify | '+
                    'bullist numlist outdent indent | hr | link code | removeformat | preview help'
            }">
        </editor>
        <div>
            <button type="button" class="btn btn-primary" (click)="addVariable('key1')">key1</button>
        </div>
        <div class="d-flex flex-row-reverse align-items-center">
            <button type="button" class="btn btn-primary" (click)="updateTemplate()">Update</button>
        </div>
        <hr>
        <div class="d-flex flex-row align-items-center">
            <div>
                <button type="button" class="btn btn-primary" (click)="sendEmail()">Send test email</button>
            </div>
            <input type="email" formControlName="testEmail" class="form-control">
        </div>
    </div>
</div> -->
<div>
    <button type="button" class="btn btn-primary" (click)="addTemplate()">Add Template</button>
</div>
<div class="content" style="padding-top: 2em; margin-bottom: 2em;">
    <div class="row">
        <div class="col-2" style="height: 100%;">
            <table class="table table-hover">
                <thead>
                    <tr><th class="d-flex justify-content-center" style="background-color: black; color: white;">
                        <b><h3>Email Templates</h3></b>
                    </th></tr>
                </thead>
                <!-- max-height:105vh; -->
                <tbody style="display: block; max-height:105vh; overflow: auto;">
                    <ng-container *ngIf="templateList && templateList.length > 0; else noTemplates">
                        <tr style="display: table; width: 100%; table-layout: fixed" *ngFor="let template of templateList">
                            <td (click)="selectTemplate(template)">
                                {{template.templateName}}
                            </td>
                        </tr>
                    </ng-container>
                    <ng-template #noTemplates>
                        <tr>
                            <td>
                                No Templates Available
                            </td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
        </div>
        <div class="col form-group" style="height: 100%;">
            <div>
                <h2>Template: <span *ngIf="selectedTemplate && selectedTemplate.templateName">{{selectedTemplate.templateName}}</span></h2>
            </div>
            <div>
                <h4>Template ID: <span *ngIf="selectedTemplate && selectedTemplate.id">{{selectedTemplate.id}}</span></h4>
            </div>
            <hr>
            <div class="row">
                <div class="col-6">
                    Trigger: <span *ngIf="selectedTemplate && selectedTemplate.trigger">{{selectedTemplate.trigger}}</span>
                </div>
                <div class="col-6">
                    Recipient: <span *ngIf="selectedTemplate && selectedTemplate.recipient">{{selectedTemplate.recipient}}</span>
                </div>
            </div>
            <hr>    
            <div [formGroup]="emailForm" class="d-flex flex-row" style="padding-top: 1em;">
                <mat-form-field style="width: 100%;padding: 0;margin: 0;" class="example-chip-list" appearance="fill">
                    <mat-label>Email/s</mat-label>
                    <mat-chip-list #chipList aria-label="Email/s">
                        <mat-chip *ngFor="let email of emails" (removed)="removeEmail(email)" color="primary" selected class="d-flex justify-content-between">
                            {{email}}
                            <button title="Remove email" matChipRemove class="d-flex justify-content-center align-items-center">
                                <mat-icon color="accent" style="z-index: 100;">close</mat-icon>
                            </button>
                        </mat-chip>
                        <input placeholder="Enter Email . . ." formControlName="emails"
                            [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="addEmail($event)">
                    </mat-chip-list>
                </mat-form-field>
                <div class="d-flex align-items-center">
                    <button style="margin-bottom: 1em" [disabled]="!selectedTemplate && emails && emails.length > 0" class="btn btn-primary" type="button" (click)="sendEmail()">
                        Send Test Email
                    </button>
                </div>
            </div>
            <div [formGroup]="configForm"  class="row">
                <ng-container *ngIf="selectedTemplate; else noSelectedTemplate">
                    <div>
                        <div *ngIf="!toEdit" class="d-flex flex-row-reverse" style="padding:1em">
                            <button type="button" class="btn btn-primary" (click)="editTemplate(selectedTemplate)">Edit</button>
                        </div>
                        <div *ngIf="toEdit"  class="d-flex justify-content-between" style="padding:1em">
                            <button type="button" class="btn btn-danger" (click)="cancelTemplate(selectedTemplate)">Cancel</button>
                            <button type="button" class="btn btn-danger" (click)="deleteTemplate(selectedTemplate)">Delete Template</button>
                        
                            <button type="button" class="btn btn-primary" (click)="updateTemplate()">Apply Changes</button>
                        </div>
                    </div>
                    <div class="d-flex flex-row-reverse" style="padding-right: 2em;">
                        <div class="fw-bold" *ngIf="toEdit == false" [ngClass]="selectedTemplate.enabled== true ? 'enabled' : 'disabled'">{{selectedTemplate.enabled == true ? 'Enabled' : 'Disabled'}}</div>
                        <div class="fw-bold" *ngIf="toEdit == true"><mat-slide-toggle formControlName="enabled" [ngClass]="configForm.value.enabled == true ? 'enabled' : 'disabled'">{{configForm.value.enabled == true ? 'Enabled' : 'Disabled'}}</mat-slide-toggle></div>
                    </div>
                    <div class="d-flex flex-row align-items-center" style="margin-bottom: 1em; padding-right: 2em;">
                        <label style="padding-right:2em; font-weight: bold;">Subject: </label>
                        <div *ngIf="toEdit == false">{{selectedTemplate.subject}}</div>
                        <input *ngIf="toEdit == true" type="text" class="form-control" formControlName="subject">
                    </div>
                    <div>
                        <div *ngIf="toEdit == false" style="padding:0 1em">
                            <div class="content-style" style="background-color: rgb(235, 235, 235); padding: 1em;" [innerHtml]="selectedTemplate.content"></div>
                        </div>
                        <ng-container *ngIf="toEdit == true">
                            <div class="row">
                                <div class="col">
                                    <div style="padding:0;margin:0;" class="form-group container-fluid d-flex flex-column justify-content-evenly">
                                        <quill-editor id="quillEditor" name="quillEditor" formControlName="content" (onEditorCreated)="editorCreated($event)"></quill-editor>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <!--  style="max-height:60vh; overflow-y:auto" -->
                                    <table class="table" >
                                        <thead>
                                            <tr><th class="d-flex justify-content-center" style="background-color: black; color: white;">
                                                <b><h3>Variables</h3></b>
                                            </th></tr>
                                        </thead>
                                        <!-- 67vh -->
                                        <tbody style="display: block; max-height:67vh; overflow: auto;">
                                            <ng-container *ngIf="selectedTemplate.variables && selectedTemplate.variables.length > 0; else noVariables">
                                                <tr style="display: table; width: 100%; table-layout: fixed" *ngFor="let variable of selectedTemplate.variables">
                                                    <td style="padding:0; margin:0;">
                                                        <button style="width: 100%;height: 100%;" type="button" class="btn btn-primary" (click)="addVariable(variable.key)">{{variable.title}}</button>
                                                    </td>
                                                </tr>
                                            </ng-container> 
                                            <ng-template #noVariables>
                                                <tr>
                                                    <td>
                                                        No Variables Available
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div [formGroup]="variablesForm" class="form-group">
                        <table class="table">
                            <thead style="display: table; width: 100%; table-layout: fixed">
                                <tr>
                                    <th>Key</th>
                                    <th style="width:85%">Test Values</th>
                                </tr>
                            </thead>
                            <tbody style="display: block; max-height:40vh; width:100%; overflow: auto;">
                                <ng-container formArrayName="variablesArray">
                                    <ng-container *ngIf="getFormsArray().length > 0; else noVariableTestList">
                                        <tr style="display: table; width: 100%; table-layout: fixed" 
                                            *ngFor="let variableGroup of getFormsArray(); let c = count; let i = index">
                                            <ng-container [formGroup]="variableGroup">
                                                <td>{{variableGroup.value.title}}</td>
                                                <td style="width:85%">
                                                    <input type="text" formControlName="itemValue" class="form-control">
                                                </td>
                                            </ng-container>
                                            <!-- <hr *ngIf="i < c"> -->
                                        </tr>
                                    </ng-container>
                                    <ng-template #noVariableTestList>
                                        <tr style="display: table; width: 100%; table-layout: fixed">
                                            <td colspan="2" >No Variables Available . . .</td>
                                        </tr>
                                    </ng-template>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>

                </ng-container>
                <ng-template #noSelectedTemplate>
                    <div class="d-flex justify-content-center">
                        <h2>Choose a template</h2>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
