import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup} from '@angular/forms';
import firebase from "firebase/app";
import Timestamp = firebase.firestore.Timestamp;

@Component({
	selector: 'app-regulation-view-proposed-arta-remarks-modal',
	templateUrl: './regulation-view-proposed-arta-remarks-modal.component.html',
	styleUrls: ['./regulation-view-proposed-arta-remarks-modal.component.scss'],
})
export class RegulationViewProposedArtaRemarks implements OnInit {
	remarksForm = new FormGroup({
		remarks: new FormControl('')
	})

	constructor(
		public dialogRef: MatDialogRef<RegulationViewProposedArtaRemarks>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {
		if(this.data.remarks){
			this.remarksForm.patchValue({
					remarks: this.data.remarks
			})
		}

		if(!this.data.action) {
			this.remarksForm.get('remarks')?.disable();
		}
	}

	closeModal(bool:boolean){
		if(bool == true) this.dialogRef.close(this.remarksForm.value)
		else this.dialogRef.close()
	}

	checkData(value:any){
		try{
			if(value){
				if(value instanceof Timestamp){
					return value.toDate()
				}
				else{
					if(value.seconds && value.nanoseconds){
						const timestamp = new Timestamp(value.seconds, value.nanoseconds)
						return timestamp.toDate()
					}
				}
			}
			return ''
		}catch(err){
			console.error('value error: ',err)
			return ''
		}
	}
}