import { Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-pdf-file-view',
  templateUrl: './pdf-file-view.component.html',
  styleUrls: ['./pdf-file-view.component.scss']
})
export class PDFFileViewComponent implements OnChanges {
  @Input() pdfFileData:any;

  constructor(
  ) { }

  // ngOnInit(): void {
    
  // }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes.pdfFileData.currentValue){
        this.pdfFileData = changes.pdfFileData.currentValue
      }
  }

  pageShift(action:any){
    if(action == "next"){
      if(this.pdfFileData.file_page < this.pdfFileData.file_total_page)
        this.pdfFileData.file_page++;
    }
    if(action == "back"){
      if(this.pdfFileData.file_page > 1)
        this.pdfFileData.file_page--;
    }
  }
  
  afterLoadComplete(pdf: PDFDocumentProxy){
    this.pdfFileData.file_total_page = pdf.numPages;
  }
}
