import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { rejects } from 'assert';
import { resolve } from 'dns';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class SnackbarNotifService {

  constructor(
    private snackBar: MatSnackBar
  ) { }

  openPlainNotif(message1: string, message2: string) {

    this.miniSwalToast("success",message1)
    /**
     * this.snackBar.open(message1, message2, {
      duration: 5000
    })
     */
  }

  displayNotif(type: string = 'success', message: string){
    /**
     * Swal.fire(
      title,
      message,
      dialogType
    )
     */
    this.miniSwalToast(type.toLowerCase(),message)
  }

  miniSwalToast(icon: any,message: string){
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
    Toast.fire({
      icon: icon,
      title: message
    });
  }

  callLoadingmodal(title : any = "Saving..."){
    Swal.fire({
      title: title,
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      }

    });
  }

  confirmDialog(title: any, message:any = "Are you sure?" ) : Promise<Boolean> {

    return new Promise( (resolve, rejects)=>{

          Swal.fire({
            title: title,
            text : message,
            showConfirmButton : false,
            showDenyButton: true,
            showCancelButton: true,
            denyButtonText: `Yes, Remove`
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                return resolve(true)
            } else if (result.isDenied) {
              return resolve(true)
            }
          });

     

    })

    

  }
  
 
}
