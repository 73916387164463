import { Timestamp } from "@firebase/firestore-types";

export enum WorklistStatusCitizensCharter {
    DRAFT = "DRAFT",
    // To be verified by QA first before sending for approval
    FOR_VERIFICATION = "FOR VERIFICATION",
    // New status before sending to Agency Head for Approval
    VERIFIED = "VERIFIED",
    // For approval
    FOR_APPROVAL = "FOR APPROVAL",
    APPROVED = "APPROVED",
    REAPPROVED = "REAPPROVED",
    // Returned by Verifier
	FOR_REVISION = "FOR REVISION",
    // Returned by ARTA
    FOR_MODIFICATION = "FOR MODIFICATION",

    // Disapproved by Agency Head but not yet returned for revision
    DISAPPROVED = "DISAPPROVED",
	RESUBMITTED = "RESUBMITTED",
    // For ARTA review after approved by agency
    FOR_ARTA_REVIEW = "FOR ARTA REVIEW",
    FOR_ARTA_REVIEW_PO_APPROVED = "APPROVED - ARTA CMEO PO",

    FOR_ARTA_REVIEW_DC = "FOR ARTA REVIEW - DC",
    FOR_ARTA_REVIEW_DC_APPROVED = "APPROVED - ARTA CMEO DC",

    FOR_ARTA_REVIEW_DIR = "FOR ARTA REVIEW - DIRECTOR",
    FOR_ARTA_REVIEW_DIR_APPROVED = "APPROVED - ARTA CMEO DIRECTOR",
    // Final status of Citizens Charter workflow
    POSTED = "POSTED"
}

export enum ServiceAssessmentCitizensCharter {
    COMPLIANT = 'Compliant',
    NON_COMPLIANT = 'Non-Compliant',
}

export const TableSortWeightWorklistStatusCitizensCharter: any = {
    [WorklistStatusCitizensCharter.FOR_MODIFICATION]: 1,
    [WorklistStatusCitizensCharter.FOR_REVISION]: 2,
    [WorklistStatusCitizensCharter.RESUBMITTED] : 3,
    [WorklistStatusCitizensCharter.FOR_VERIFICATION] : 4,
    [WorklistStatusCitizensCharter.VERIFIED]: 5,
    [WorklistStatusCitizensCharter.DRAFT] : 6,
}

// A distilled version of WorklistStatusCitizensCharter
// The Charter moves along with the worklists under it
export enum CharterStatusCitizensCharter {
    // Initial status
    // New CCs are now created by the Verifier alongside all worklists under it to be sent for approval
    FOR_APPROVAL = "FOR APPROVAL",
    // Sent back
    FOR_REVISION = "FOR REVISION",
    // While under ARTA
    FOR_ARTA_REVIEW = "FOR ARTA REVIEW",
    ONGOING_ARTA_REVIEW = "ONGOING ARTA REVIEW",
    FOR_DC_APPROVAL = "FOR CMEO DC APPROVAL",
    FOR_DIR_APPROVAL = "FOR CMEO DIRECTOR APPROVAL",
    // Final status
    POSTED = "POSTED"
}

// Status for posted services (is_posted == true)
// Posted Services could have an update or delist ongoing
export enum PostedServiceStatusCitizensCharter {
    POSTED = "POSTED",
    FOR_UPDATING = "FOR UPDATING",
    FOR_DELISTING = "FOR DELISTING",
    DELISTED = "DELISTED",
}

// Also known as "worklist type"
export enum EffectivityCitizensCharter {
    NEW = "NEW",
    UPDATE = "UPDATE",
    DELIST = "DELIST",
    MODIFY_NEW = "MODIFY - NEW",
    MODIFY_UPDATE = "MODIFY - UPDATE",
    MODIFY_DELIST = "MODIFY - DELIST",
}

export enum GovernmentAccountSubtype {
    AGENCY_ENC = "Government - Encoder",
    AGENCY_VER = "Government - Verifier",
    AGENCY_DIR = "Government - Agency Head",
    AGENCY_ADMIN = "Government - IT/Admin",
    ARTA_ADMIN = "ARTA - IT/Admin",
    ARTA_CMEO_PO = "ARTA - CMEO - Program Officer",
    ARTA_CMEO_DC = "ARTA - CMEO - Division Chief",
    ARTA_CMEO_DIR = "ARTA - CMEO - Director",
    ARTA_BRO_PO = "ARTA - BRO - Program Officer",
    ARTA_BRO_DC = "ARTA - BRO - Division Chief",
    ARTA_BRO_DIR = "ARTA - BRO - Director",
    ARTA_DDG = "ARTA - DDG",
    ARTA_DG = "ARTA - DG",
}

export enum RemarkLevelCitizensCharter {
    LEVEL_AGENCY = "Agency",
    LEVEL_ARTA = "ARTA",
    // ARTA remarks viewable to Agency
    LEVEL_RETURN = "Returned"
}
export const RemarkLevelRoleMappingCitizensCharter: any = {
    [GovernmentAccountSubtype.AGENCY_ENC] : RemarkLevelCitizensCharter.LEVEL_AGENCY,
    [GovernmentAccountSubtype.AGENCY_VER] : RemarkLevelCitizensCharter.LEVEL_AGENCY,
    [GovernmentAccountSubtype.AGENCY_DIR] : RemarkLevelCitizensCharter.LEVEL_AGENCY,
    [GovernmentAccountSubtype.ARTA_CMEO_PO] : RemarkLevelCitizensCharter.LEVEL_ARTA,
    [GovernmentAccountSubtype.ARTA_CMEO_DC] : RemarkLevelCitizensCharter.LEVEL_ARTA,
    [GovernmentAccountSubtype.ARTA_CMEO_DIR] : RemarkLevelCitizensCharter.LEVEL_ARTA,
}

export enum RemarkResolvedStatusCitizensCharter {
    UNRESOLVED = 'Unresolved',
    RESOLVED = 'Resolved',
    DELETED = 'Deleted'
}

export const WorklistRemarksGroupUnabletoEdit={
    AGENCY_VER : [
        WorklistStatusCitizensCharter.FOR_REVISION,
        WorklistStatusCitizensCharter.FOR_MODIFICATION,
    ]
}

// Service Status View and Action CC for Agency
export const WorklistAgencyGroupedStatusCitizensCharter = {
    AGENCY_ENC: [
        WorklistStatusCitizensCharter.DRAFT,
        WorklistStatusCitizensCharter.FOR_REVISION,
        WorklistStatusCitizensCharter.FOR_MODIFICATION,
        WorklistStatusCitizensCharter.RESUBMITTED,
        // Include FOR VERIFICATION and VERIFIED, but do not let Encoders do anything
        WorklistStatusCitizensCharter.FOR_VERIFICATION,
        WorklistStatusCitizensCharter.VERIFIED,
    ],
    AGENCY_VER: [
        WorklistStatusCitizensCharter.FOR_REVISION,
        WorklistStatusCitizensCharter.FOR_MODIFICATION,
        WorklistStatusCitizensCharter.FOR_VERIFICATION,
        WorklistStatusCitizensCharter.RESUBMITTED,
        WorklistStatusCitizensCharter.VERIFIED,
    ],
    AGENCY_DIR: [
        WorklistStatusCitizensCharter.FOR_APPROVAL,
        WorklistStatusCitizensCharter.APPROVED,
        WorklistStatusCitizensCharter.FOR_MODIFICATION,
        WorklistStatusCitizensCharter.DISAPPROVED,
        WorklistStatusCitizensCharter.RESUBMITTED,
    ],
}

export const WorklistAgencyMappedStatusCitizensCharter:any = {
    AGENCY_ENC: [
        GovernmentAccountSubtype.AGENCY_ENC,
        WorklistAgencyGroupedStatusCitizensCharter.AGENCY_ENC
    ],
    AGENCY_VER: [
        GovernmentAccountSubtype.AGENCY_VER,
        WorklistAgencyGroupedStatusCitizensCharter.AGENCY_VER
    ],
    AGENCY_DIR: [
        GovernmentAccountSubtype.AGENCY_DIR,
        WorklistAgencyGroupedStatusCitizensCharter.AGENCY_DIR
    ],
}
//


// Service Status View and Action CC for Arta
export const WorklistArtaGroupedStatusCitizensCharter = {
    ARTA_CMEO_PO: [
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW,
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_PO_APPROVED,
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DC,
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DC_APPROVED,
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DIR,
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DIR_APPROVED,
    ],
    ARTA_CMEO_DC: [
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW,
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_PO_APPROVED,
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DC,
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DC_APPROVED,
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DIR,
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DIR_APPROVED,
    ],
    ARTA_CMEO_DIR: [
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW,
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_PO_APPROVED,
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DC,
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DC_APPROVED,
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DIR,
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DIR_APPROVED,
    ],
}

export const WorklistArtaMappedStatusCitizensCharter:any = {
    ARTA_CMEO_PO: [
        GovernmentAccountSubtype.ARTA_CMEO_PO,
        WorklistArtaGroupedStatusCitizensCharter.ARTA_CMEO_PO
    ],
    ARTA_CMEO_DC: [
        GovernmentAccountSubtype.ARTA_CMEO_DC,
        WorklistArtaGroupedStatusCitizensCharter.ARTA_CMEO_DC
    ],
    ARTA_CMEO_DIR: [
        GovernmentAccountSubtype.ARTA_CMEO_DIR,
        WorklistArtaGroupedStatusCitizensCharter.ARTA_CMEO_DIR
    ],
}
//

export enum WorklistActionFlagCitizensCharter {    
    // Agency Head -> ARTA CMEO PO
    APPROVE = 1,
    DISAPPROVE = 2,
    // Verifier actions
    RETURN_FROM_AGENCY_QA = 3,
    TAG_AS_VERIFIED = 4,

    // ARTA Officer to next ranking officer (starting from CMEO PO)
    CMEO_PO_APPROVE = 11,
    // CMEO DC can now either submit to DIR or return the worklist to PO (due to unsatisfactory remarks)
    CMEO_DC_APPROVE = 12,
    CMEO_DC_DISAPPROVE = 13,
    CMEO_DIR_APPROVE = 14,
    // Two-step disapproval: disapprove worklist, then return worklist to CC
    CMEO_DIR_DISAPPROVE = 15,
    
    SAVE_DRAFT = 7,
    SUBMIT_DRAFT = 8,
    RESUBMIT = 9,

    HARD_DELETE_SERVICE = 97,
    OPEN_REMARKS = 98,
    POST_CITIZENS_CHARTER = 99,
}

export enum WorklistActionFlagPBRISRegulation {
    SUBMIT_TO_AGENCY_APPR = 0,
    // Agency Head -> ARTA BRO PO
    SUBMIT_TO_ARTA = 1,

    RE_SUBMIT_TO_AGENCY_APPR = 2,
    RE_SUBMIT_TO_ARTA = 3,
    
    RETURN_FROM_AGENCY_QA = 4,
    RETURN_FROM_AGENCY_APPR = 5,
    RETURN_FROM_ARTA = 6,
    RETURN_DRIS = 7,

    // ARTA Officer to next ranking officer (starting from BRO PO)
    SUBMIT_TO_ARTA_NEXT_LEVEL = 11,
    SUBMIT_TO_ARTA_BRO_DC = 12,
    SUBMIT_TO_ARTA_BRO_DIR = 13,
    SUBMIT_TO_ARTA_DDG = 14,
    SUBMIT_TO_ARTA_DG = 15,

    MARK_FOR_RIA = 20,
    WITHDRAW_REGULATION = 21,

    // DRIS actions
    DRIS_SUBMIT_TO_AGENCY_APPR = 30,
    DRIS_SUBMIT_TO_ARTA = 31,
    DRIS_SUBMIT_TO_ARTA_NEXT_LEVEL = 32,
    DRIS_SUBMIT_TO_ARTA_BRO_DC = 33,
    DRIS_SUBMIT_TO_ARTA_BRO_DIR = 34,
    DRIS_SUBMIT_TO_ARTA_DDG = 35,
    DRIS_SUBMIT_TO_ARTA_DG = 36,
    DRIS_SUBMIT_FOR_ASSESSMENT = 37,
    
    SAVE_DRAFT = 7,
    SUBMIT_DRAFT = 8,
    SUBMIT_FOR_POSTING = 98,
    POST_REGULATION = 99,
}

// Assume that Proposed Regulations always go through RIA, with some exceptions
export enum WorklistStatusPBRISRegulation {
    DRAFT = "DRAFT",
    //Sends to Notifications
    FOR_NOTIFICATION = "FOR NOTIFICATION",
    // To be verified by QA first before sending for approval
    FOR_VERIFICATION = "FOR VERIFICATION",
    // For approval
    FOR_APPROVAL = "FOR APPROVAL",
    // If Agency Approver returns with comments
	FOR_REVISION = "FOR REVISION",
	RESUBMITTED = "RESUBMITTED",
    // For ARTA review after approved by agency
    FOR_ARTA_REVIEW = "FOR ARTA REVIEW - PO",
    FOR_ARTA_REVIEW_DC = "FOR ARTA REVIEW - DC",
    FOR_ARTA_REVIEW_DIR = "FOR ARTA REVIEW - DIRECTOR",
    FOR_ARTA_REVIEW_DDG = "FOR ARTA REVIEW - DDG",
    FOR_ARTA_REVIEW_DG = "FOR ARTA REVIEW - DG",

    // If ARTA returns with comments
    FOR_MODIFICATION = "FOR MODIFICATION",
    FOR_REAPPROVAL = "FOR RE-APPROVAL",

    // In case of RIA
    // Viewed by all users (Agency and ARTA)
    // Will require filtering by GovernmentAccountSubtype
    FOR_RIA = "FOR RIA",

    // If regulation was determined to be For RIA but Agency decided not to push through. This is a final status.
    WITHDRAWN = "WITHDRAWN",

    // TODO: Return any DRIS to the RIA/CRIS level for additional consultations (in the case of controversial regulations)
    DRAFT_CRIS = "WITH DRAFT CRIS",
    SUBMITTED_CRIS = "UPLOADED RIS - FOR CONSULTATION",

    DRAFT_DRIS = "WITH DRAFT DRIS",

    // DRIS statuses?
    // Return to DRIS-level for slight revisions 
    DRIS_FOR_REVISION = "DECISION FOR REVISION",
    DRIS_FOR_VERIFICATION = "DECISION FOR VERIFICATION",
    DRIS_FOR_APPROVAL = "DECISION FOR APPROVAL",
    DRIS_FOR_ARTA_REVIEW = "DECISION SUBMISSION - PO",
    DRIS_FOR_ARTA_REVIEW_DC = "DECISION FOR ARTA REVIEW - DC",
    DRIS_FOR_ARTA_REVIEW_DIR = "DECISION FOR ARTA REVIEW - DIRECTOR",
    DRIS_FOR_ARTA_REVIEW_DDG = "DECISION FOR ARTA REVIEW - DDG",
    DRIS_FOR_ARTA_REVIEW_DG = "DECISION FOR ARTA REVIEW - DG",

    // From DRIS DIR/DDG/DG to PO first before sending back to Agency
    DRIS_FOR_ASSESMENT = "DECISION FOR ARTA ASSESSMENT - PO",
    FOR_POSTING = "FOR POSTING",
    // Final status of Regulation workflow
    POSTED = "POSTED"
}

export enum WorklistStatusWeightPBRISRegulation {
    DRAFT = 0,
    //Sends to Notifications
    FOR_NOTIFICATION = 1,
    // To be verified by QA first before sending for approval
    FOR_VERIFICATION = 2,
    // For approval
    FOR_APPROVAL = 3,
    // If Agency Approver returns with comments
	FOR_REVISION = 4,
	RESUBMITTED = 5,
    // For ARTA review after approved by agency
    FOR_ARTA_REVIEW = 6,
    FOR_ARTA_REVIEW_DC = 7,
    FOR_ARTA_REVIEW_DIR = 8,
    FOR_ARTA_REVIEW_DDG = 9,
    FOR_ARTA_REVIEW_DG = 10,

    // If ARTA returns with comments
    FOR_MODIFICATION = 11,
    FOR_REAPPROVAL = 12,

    // In case of RIA
    // Viewed by all users (Agency and ARTA)
    // Will require filtering by GovernmentAccountSubtype
    FOR_RIA = 13,

    // If regulation was determined to be For RIA but Agency decided not to push through. This is a final status.
    WITHDRAWN = -1,

    DRAFT_CRIS = 14,
    SUBMITTED_CRIS = 15,

    DRAFT_DRIS = 16,

    // DRIS statuses?
    DRIS_FOR_VERIFICATION = 17,
    DRIS_FOR_APPROVAL = 18,
    DRIS_FOR_ARTA_REVIEW = 19,
    DRIS_FOR_ARTA_REVIEW_DC = 20,
    DRIS_FOR_ARTA_REVIEW_DIR = 21,
    DRIS_FOR_ARTA_REVIEW_DDG = 22,
    DRIS_FOR_ARTA_REVIEW_DG = 23,

    FOR_POSTING = 998,
    // Final status of Regulation workflow
    POSTED = 999
}

export const WorklistAgencyViewGroupedStatusPBRISRegulation = {
    AGENCY_ENC: [
        WorklistStatusPBRISRegulation.DRAFT,
        WorklistStatusPBRISRegulation.FOR_REVISION,
        WorklistStatusPBRISRegulation.FOR_MODIFICATION,
        WorklistStatusPBRISRegulation.FOR_APPROVAL,
        WorklistStatusPBRISRegulation.FOR_VERIFICATION,
        WorklistStatusPBRISRegulation.FOR_RIA,
        WorklistStatusPBRISRegulation.DRAFT_CRIS,
        WorklistStatusPBRISRegulation.SUBMITTED_CRIS,
        WorklistStatusPBRISRegulation.DRAFT_DRIS,
        WorklistStatusPBRISRegulation.DRIS_FOR_VERIFICATION,
        WorklistStatusPBRISRegulation.DRIS_FOR_REVISION,
    ],
    AGENCY_VER: [
        WorklistStatusPBRISRegulation.DRAFT,
        WorklistStatusPBRISRegulation.FOR_APPROVAL,
        WorklistStatusPBRISRegulation.FOR_REVISION,
        WorklistStatusPBRISRegulation.FOR_MODIFICATION,
        WorklistStatusPBRISRegulation.FOR_VERIFICATION,
        WorklistStatusPBRISRegulation.SUBMITTED_CRIS,
        WorklistStatusPBRISRegulation.FOR_RIA,
        WorklistStatusPBRISRegulation.DRIS_FOR_VERIFICATION,
        WorklistStatusPBRISRegulation.DRIS_FOR_REVISION,
    ],
    AGENCY_DIR: [
        // WorklistStatusPBRISRegulation.DRAFT,
        WorklistStatusPBRISRegulation.FOR_REVISION,
        WorklistStatusPBRISRegulation.FOR_MODIFICATION,
        WorklistStatusPBRISRegulation.FOR_REAPPROVAL,
        // WorklistStatusPBRISRegulation.FOR_VERIFICATION,
        WorklistStatusPBRISRegulation.FOR_APPROVAL,
        WorklistStatusPBRISRegulation.RESUBMITTED,
        WorklistStatusPBRISRegulation.FOR_RIA,
        WorklistStatusPBRISRegulation.SUBMITTED_CRIS,
        WorklistStatusPBRISRegulation.DRIS_FOR_VERIFICATION,
        WorklistStatusPBRISRegulation.DRIS_FOR_REVISION,
        WorklistStatusPBRISRegulation.DRIS_FOR_APPROVAL,
        WorklistStatusPBRISRegulation.FOR_POSTING,

        WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW,
        WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DC,
        WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DIR,
        WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DDG,
        WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DG,
        WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW,
        WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DC,
        WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DIR,
        WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DDG,
        WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DG,
    ],
    AGENCY_ADMIN: [],
}



export const WorklistAgencyViewMappedStatusPBRISRegulation:any = {
    AGENCY_ENC: [
        GovernmentAccountSubtype.AGENCY_ENC,
        WorklistAgencyViewGroupedStatusPBRISRegulation.AGENCY_ENC,
    ],
    AGENCY_VER: [
        GovernmentAccountSubtype.AGENCY_VER,
        WorklistAgencyViewGroupedStatusPBRISRegulation.AGENCY_VER,
    ],
    AGENCY_DIR: [
        GovernmentAccountSubtype.AGENCY_DIR,
        WorklistAgencyViewGroupedStatusPBRISRegulation.AGENCY_DIR,
    ],
    AGENCY_ADMIN: [
        GovernmentAccountSubtype.AGENCY_ADMIN,
        WorklistAgencyViewGroupedStatusPBRISRegulation.AGENCY_ADMIN,
    ],
}

// Pbris Regulations Action for agency
export const WorklistAgencyActionGroupedStatusPBRISRegulation = {
    AGENCY_ENC: [
        WorklistStatusPBRISRegulation.DRAFT,
        WorklistStatusPBRISRegulation.FOR_REVISION,
        WorklistStatusPBRISRegulation.FOR_MODIFICATION,
        WorklistStatusPBRISRegulation.SUBMITTED_CRIS,
        WorklistStatusPBRISRegulation.DRAFT_CRIS,
        WorklistStatusPBRISRegulation.DRAFT_DRIS,
        WorklistStatusPBRISRegulation.FOR_RIA,
        // WorklistStatusPBRISRegulation.FOR_APPROVAL,
    ],
    AGENCY_VER: [
        // WorklistStatusPBRISRegulation.DRAFT,
        // WorklistStatusPBRISRegulation.FOR_APPROVAL,
        // WorklistStatusPBRISRegulation.FOR_REVISION,
        // WorklistStatusPBRISRegulation.FOR_MODIFICATION,
        WorklistStatusPBRISRegulation.FOR_VERIFICATION,
        WorklistStatusPBRISRegulation.FOR_RIA,
        WorklistStatusPBRISRegulation.DRIS_FOR_VERIFICATION,
        WorklistStatusPBRISRegulation.DRIS_FOR_REVISION,
        WorklistStatusPBRISRegulation.SUBMITTED_CRIS,
        WorklistStatusPBRISRegulation.DRAFT_DRIS,
    ],
    AGENCY_DIR: [
        // WorklistStatusPBRISRegulation.DRAFT,
        // WorklistStatusPBRISRegulation.FOR_REVISION,
        // WorklistStatusPBRISRegulation.FOR_MODIFICATION,
        // WorklistStatusPBRISRegulation.FOR_VERIFICATION,
        WorklistStatusPBRISRegulation.SUBMITTED_CRIS,
        WorklistStatusPBRISRegulation.DRAFT_DRIS,
        WorklistStatusPBRISRegulation.FOR_APPROVAL,
        WorklistStatusPBRISRegulation.RESUBMITTED,
        WorklistStatusPBRISRegulation.FOR_REAPPROVAL,
        WorklistStatusPBRISRegulation.FOR_RIA,
        WorklistStatusPBRISRegulation.DRIS_FOR_VERIFICATION,
        WorklistStatusPBRISRegulation.DRIS_FOR_REVISION,
        WorklistStatusPBRISRegulation.DRIS_FOR_APPROVAL,
        WorklistStatusPBRISRegulation.FOR_POSTING
    ],
    AGENCY_ADMIN: [],
}

export const WorklistAgencyActionMappedStatusPBRISRegulation:any = {
    AGENCY_ENC: [
        GovernmentAccountSubtype.AGENCY_ENC,
        WorklistAgencyActionGroupedStatusPBRISRegulation.AGENCY_ENC,
    ],
    AGENCY_VER: [
        GovernmentAccountSubtype.AGENCY_VER,
        WorklistAgencyActionGroupedStatusPBRISRegulation.AGENCY_VER,
    ],
    AGENCY_DIR: [
        GovernmentAccountSubtype.AGENCY_DIR,
        WorklistAgencyActionGroupedStatusPBRISRegulation.AGENCY_DIR,
    ],
    AGENCY_ADMIN: [
        GovernmentAccountSubtype.AGENCY_ADMIN,
        WorklistAgencyActionGroupedStatusPBRISRegulation.AGENCY_ADMIN,
    ],
}
//

//Pbris Regulations Action for arta
export const WorklistArtaActionGroupedStatusPBRISRegulation = {
    ARTA_ADMIN: [],
    ARTA_CMEO_PO: [
        WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW,
        WorklistStatusPBRISRegulation.FOR_REAPPROVAL,
    ],
    ARTA_CMEO_DC: [
        WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DC,
    ],
    ARTA_CMEO_DIR: [
        WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DIR,
    ],
    ARTA_BRO_PO: [
        WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW,
        // WorklistStatusPBRISRegulation.FOR_REAPPROVAL,
        WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW,
        WorklistStatusPBRISRegulation.DRIS_FOR_ASSESMENT
    ],
    ARTA_BRO_DC: [
        WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DC,
        WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DC,
    ],
    ARTA_BRO_DIR: [
        WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DIR,
        WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DIR,
    ],
    ARTA_DDG: [
        WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DDG,
        WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DDG,
    ],
    ARTA_DG: [
        WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DG,
        WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DG,
    ],
}

export const WorklistArtaActionMappedStatusPBRISRegulation:any = {
    ARTA_ADMIN: [
        GovernmentAccountSubtype.ARTA_ADMIN,
        WorklistArtaActionGroupedStatusPBRISRegulation.ARTA_ADMIN,
    ],
    ARTA_CMEO_PO: [
        GovernmentAccountSubtype.ARTA_CMEO_PO,
        WorklistArtaActionGroupedStatusPBRISRegulation.ARTA_CMEO_PO,
    ],
    ARTA_CMEO_DC: [
        GovernmentAccountSubtype.ARTA_CMEO_DC,
        WorklistArtaActionGroupedStatusPBRISRegulation.ARTA_CMEO_DC,
    ],
    ARTA_CMEO_DIR: [
        GovernmentAccountSubtype.ARTA_CMEO_DIR,
        WorklistArtaActionGroupedStatusPBRISRegulation.ARTA_CMEO_DIR,
    ],
    ARTA_BRO_PO: [
        GovernmentAccountSubtype.ARTA_BRO_PO,
        WorklistArtaActionGroupedStatusPBRISRegulation.ARTA_BRO_PO,
    ],
    ARTA_BRO_DC: [
        GovernmentAccountSubtype.ARTA_BRO_DC,
        WorklistArtaActionGroupedStatusPBRISRegulation.ARTA_BRO_DC,
    ],
    ARTA_BRO_DIR: [
        GovernmentAccountSubtype.ARTA_BRO_DIR,
        WorklistArtaActionGroupedStatusPBRISRegulation.ARTA_BRO_DIR,
    ],
    ARTA_DDG: [
        GovernmentAccountSubtype.ARTA_DDG,
        WorklistArtaActionGroupedStatusPBRISRegulation.ARTA_DDG,
    ],
    ARTA_DG: [
        GovernmentAccountSubtype.ARTA_DG,
        WorklistArtaActionGroupedStatusPBRISRegulation.ARTA_DG,
    ],
}
//

//Pbris Regulations View for Arta
export const WorklistArtaViewGroupedStatusPBRISRegulation = {
    ARTA:[
        WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW,
        WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DC,
        WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DIR,
        WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DDG,
        WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DG,
        // WorklistStatusPBRISRegulation.FOR_REAPPROVAL,
        WorklistStatusPBRISRegulation.FOR_RIA,
        WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW,
        WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DC,
        WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DIR,
        WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DDG,
        WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DG,
        WorklistStatusPBRISRegulation.DRIS_FOR_ASSESMENT,
    ]
}

export const WorklistArtaArtaMappedStatusPBRISRegulation:any = {
    ARTA_BRO_PO:[
        GovernmentAccountSubtype.ARTA_BRO_PO,
        WorklistArtaViewGroupedStatusPBRISRegulation.ARTA,
    ],
    ARTA_BRO_DC:[
        GovernmentAccountSubtype.ARTA_BRO_DC,
        WorklistArtaViewGroupedStatusPBRISRegulation.ARTA,
    ],
    ARTA_BRO_DIR:[
        GovernmentAccountSubtype.ARTA_BRO_DIR,
        WorklistArtaViewGroupedStatusPBRISRegulation.ARTA,
    ],
    ARTA_DDG:[
        GovernmentAccountSubtype.ARTA_DDG,
        WorklistArtaViewGroupedStatusPBRISRegulation.ARTA,
    ],
    ARTA_DG:[
        GovernmentAccountSubtype.ARTA_DG,
        WorklistArtaViewGroupedStatusPBRISRegulation.ARTA,
    ],
}
//

export enum WorklistRulestage {
    PR_DRAFT = "PRE-RULE STAGE (Drafting)"
}

export const WorklistStatusToRulestageMapped:any = {

}


export enum CitizensCharterCommentSectionFlag {
    OVERALL_CC = "cc",
    CLIENT_STEP = "cs"
}

export enum CitizensCharterCommentStatus {
    UNRESOLVED = "UNRESOLVED",
    RESOLVED = "RESOLVED"
}

export enum RegulationCommentStatus {
    UNRESOLVED = "UNRESOLVED",
    RESOLVED = "RESOLVED"
}

export interface WorklistCitizensCharter {
    dateGenerated: Timestamp;
    fromAgency: string;
    fromOfficer: string;
    toAgency: string;
    toOfficer: string;
    status: WorklistStatusCitizensCharter;
    documentFile: string;
    documentId: string;
    documentTitle: string;
    dueDate: Timestamp;
}
