<form [formGroup]="searchform" (ngSubmit)="search('existing')">

    <!-- RADIO BUTTON CLASSIFICATION -->
    <!-- <div class="col-lg-6 col-sm-12 p-3 my-1">
        <div class="row input-group">
            <h6 class="fw-bold mb-3">Select Classification</h6>
            <div class="form-group my-3">
                <div class="form-check form-check-inline" style="margin-right: 1em;">
                    <input mdbRadio class="form-check-input" (change)="selectChange('class',$event)" type="radio" formControlName="classification" name="classification" id="existing_class_business" value="Business">
                    <label class="form-check-label" for="existing_class_business">Business</label>
                </div>

                <div class="form-check form-check-inline"  style="margin-left: 1em;">
                    <input mdbRadio class="form-check-input" (change)="selectChange('class',$event)" type="radio" formControlName="classification" name="classification" id="existing_class_nonbusiness" value="Non-Business">
                    <label class="form-check-label" for="existing_class_nonbusiness">Non - business</label>
                </div>
            </div>
        </div>
    </div> -->

    <div class="row">
        <!-- KEYWORD -->
        <div class="col-12 mt-1 p-2">
            <h6 class="fw-bold mb-3" for="keyword">Keyword/s</h6>
            <input type="search" class="form-control rounded" placeholder="-" formControlName="keyword">
        </div>

        <!-- CLASSIFICATION -->
        <div class="col-lg-6 col-sm-12 my-2">
            <h6 class="fw-bold mb-3">Select Classification</h6>
            <select class="form-select" (change)="selectChange('class',$event)" formControlName="classification" name="classification" id="existing_class_business">
                <option selected readonly value="">- select -</option>
                <option id="existing_class_business" value="Business">Business</option>
                <option id="existing_class_nonbusiness" value="Non-Business">Non-Business</option>
            </select>
        </div>

        <!-- Busines -->
        <div class="col-lg-6 col-sm-12 my-2" *ngIf="searchform.value.classification == 'Business'">
            <h6 class="fw-bold mb-3" for="sector">Sector</h6>
            <select class="form-select" aria-label="Sector" #select_sector (change)="selectChange('sect',select_sector.value)">
                <option selected readonly value="">- select -</option>
                <option *ngFor="let entry of sectors" [value]="entry.name">{{entry.name}}</option>
            </select>
        </div>

        <!-- Non-busines -->
        <div class="col-lg-6 col-sm-12 my-1" *ngIf="searchform.value.classification == 'Non-Business'">
            <h6 class="fw-bold mb-3" for="sol">Stage of Life</h6>
            <select class="form-select" aria-label="Stage of Life" #select_sol (change)="selectChange('sol',select_sol.value)">
                <option selected readonly value="">- select -</option>
                <option *ngFor="let entry of sol" [value]="entry.stage">{{entry.stage}}</option>
            </select>
        </div>

        <!-- Form button -->
        <div class="form-group text-center mt-4 mb-5">
            <!-- <button type="reset" (click)="resetData()" class="btn btn-light rounded-9 m-1">Reset Form</button> -->
            <button type="submit" class="btn btn-primary rounded-9 m-1"><i class="fas fa-search me-2"></i>Search</button>
            <button type="reset" class="btn btn-dark btn-rounded mx-1 my-1" aria-label="Reset" routerLink="/pbris/regulations/existing">
                <p class="m-0">Reset</p>
            </button>
        </div>
    </div>
</form>

