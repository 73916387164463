import { AngularFireStorage } from '@angular/fire/storage';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { allMustBe, atLeastOne } from 'src/app/validators';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {Location} from '@angular/common';
import { info } from 'console';
import { ReuseLoginComponent } from 'src/app/modal-manager/reuse-login/reuse-login.component';
import { ARTEMIS, PBRIS } from 'src/app/entities/constant';
import { MatStepper } from '@angular/material/stepper';
import { trigger } from '@angular/animations';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-agency-add',
  templateUrl: './agency-add.component.html',
  styleUrls: ['./agency-add.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: true},
    },
  ],
})
export class AgencyAddComponent implements OnInit {

  style_theme: any = 'theme-default'; //current theme
  currentSystem:any = sessionStorage.getItem('currentSystem');
  pbris = PBRIS

  isAgency: boolean = false;
  isAdmin: boolean = true;

  loading: boolean = false;

  //image bucket
  // logoFiles:any = []
  // logoFile:any;
  // logo:any;
  imgSrc : string;
  defaultImgSrc = "assets/default_upload_logo.png"
  agencyImage: any;
  //
  logoPath = "agency/";

  agencyCategories:any = []

  motheragencies:any =[]

  showAgencyCategory:any = ""

  showMotherAgency:any=""
  isLastStepper:boolean = false;
  hasNextStepper:boolean = true;
  hasBackStepper:boolean = false;
  showLogoUploadProgress = false;
  currentLogoUploadProgress = 0;
  loadedLogoUploadProgress = '';
  disablerWhileLoadingLogo = false;
  logoName = '';
  logoSize = ''

  //error printers
  checkAgencyForm = {
    motherAgencyID:[{
      type:"required",message:"Mother Agency is required"
    }],
    agencyName:[
      { type: "required", message: "Agency name is required" },
    ],
    agencyAbbreviation:[
      { type: "required", message: "Agency abbreviation is required" },
    ],
    phoneNumber:[
      { type: "required", message: "Phone number is required" },
      { type: "pattern", message: "Phone number must only contain numbers" },
      { type: 'minlength', message: 'Phone Number must contain atleast 10 digits'},
    ],
    telephoneNumber:[
      { type: "required", message: "Telephone number is required" },
      { type: "pattern", message: "Telephone must only contain numbers" },
      { type: 'minlength', message: 'Telephone must atleast contain 10 digits'},
    ],
    trunkline:[
      { type: "required", message: "Trunkline number is required" },
      // { type: "pattern", message: "Phone number must only contain numbers" },
      // { type: 'minlength', message: 'Phone Number must atleast contain 10 digits'},
    ],
    email:[
      { type: "required", message: "Email is required" },
      { type: "email", message: "Email incorrect format" },
    ],
    websiteLink:[
      { type: "required", message: "Website URL is required" },
      { type: "pattern", message: "Website URL incorrect format" },
    ],
    protocol:[
      { type: "required", message: "Website Protocol is required" },
    ],
    agencyCategoryID:[
      { type: "required", message: "Agency Category is required"}
    ]
  };

  checkAddressForm = {
    addressLine:[
      { type: "required", message: "Address is required" },
    ],
    barangay:[
      { type: "required", message: "Barangay is required" },
    ],
    city:[
      { type: "required", message: "City/Municipality Name is required" },
    ],
    province:[
      { type: "required", message: "Province - Region Name is required" },
    ],
    postal:[
      { type: "required", message: "Postal code / Zip code is required" },
    ],
  };

  checkProfileForm = {
    mandate:[
      { type: "required", message: "Mandate is required" },
    ],
    mission:[
      { type: "required", message: "Mission is required" },
    ],
    vision:[
      { type: "required", message: "Vision is required" },
    ],
    pledges:[
      { type: "required", message: "Service pledges is required" },
    ],
  };

  checkCartForm = {
    name:[
      { type: "required", message: "Name is required" },
    ],
    designation:[
      { type: "required", message: "Designation is required" },
    ],
    email:[
      { type: "required", message: "Email is required" },
      { type: "email", message: "Email incorrect format" },
    ],
    allMustBe:[
      { type: "required", message: "Required" },
    ],
  };

  checkFeedbackForm = {
    howToSendFeedback:[
      { type: "required", message: "How to send feedback is required" },
    ],
    howFeedbackProcess:[
      { type: "required", message: "How feedback is processed is required" },
    ],
    howTofileComplaint:[
      { type: "required", message: "How to file a complaint is required" },
    ],
    howComplaintsProcess:[
      { type: "required", message: "How complaints are processed is required" },
    ],
  };
  //
  uploadForm = this.formBuilder.group({
    logoFile: new FormControl(''), //event
    logoSrc: new FormControl('') //img src
  });

  //forms initialization
  agencyForm = this.formBuilder.group({
    agencyAbbreviation:new FormControl('',[Validators.required]),
    agencyName: new FormControl('',[Validators.required]),
    phoneNumber: new FormControl('',[this.checkPhoneNumber()]),
    // {validators:[
      // Validators.required,
      // Validators.minLength(12),
      // Validators.pattern(/(\d{0,9})/g),
    //   this.checkPhoneNumber()
    // ]}
    // telephoneNumber: new FormControl('',
    // [
    //   this.checkTelephoneNumber(),
    //   Validators.required,
    //   // Validators.minLength(14),
    //   // Validators.pattern(/(\d{0,9})/g),
    // ]),
    
    telephoneArray: this.formBuilder.array([
      new FormGroup({
        telephoneNumber: new FormControl('',
        [
          Validators.required,
          this.checkTelephoneNumber()
        ]),
        trunkline: new FormControl('',[this.checkTrunklineNumber()]),
        hasTrunkline: new FormControl(false)//
      }),
    ],{ validators: [atLeastOne(Validators.required)] }),
    // email: new FormControl('',[Validators.required,Validators.email]),
    emailArray: this.formBuilder.array([
      new FormGroup({
        email: new FormControl('',[Validators.required,Validators.email]),
      })
    ],{ validators: [atLeastOne(Validators.required)]}),
    websiteLink: new FormControl('',[
      Validators.required,
      // Validators.pattern(/^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/),
      Validators.pattern(/^(https?:\/\/)((([a-zA-Z\d]([a-zA-Z\d-]*[a-zA-Z\d])*)\.)+[a-zA-Z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\\d+)?(\/[-a-zA-Z\d%_.~+]*)*(\?[;&a-zA-Z\d%_.~+=-]*)?(\#[-a-zA-Z\d_]*)?$/),
    ]),
    // protocol: new FormControl('',Validators.required),
    logoURL: new FormControl(''),
    agencyCategoryID: new FormControl('',Validators.required),
    motherAgencyID: new FormControl('',Validators.required)
  });

  addressForm = this.formBuilder.group({
    addressLine: new FormControl('',[Validators.required]), //  street/house/bldg/subdv
    barangay: new FormControl('',[Validators.required]),
    city: new FormControl('',[Validators.required]), // municipality
    province: new FormControl('',[Validators.required]), // region
    postal: new FormControl('',[Validators.required]), // zip
  });

  profileForm = this.formBuilder.group({
    mandate: new FormControl('',[Validators.required]),
    mission: new FormControl('',[Validators.required]),
    vision: new FormControl('',[Validators.required]),
    pledges: new FormControl('',[Validators.required]),
  });

  cartForm = this.formBuilder.group({
    headOfAgencyTitle: new FormControl(''),
    headOfAgencyForm: this.formBuilder.array([
      this.formBuilder.group({
        name: new FormControl('', Validators.required),
        designation: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.required, Validators.email]),
      }),
    ],{ validators: [allMustBe(Validators.required)] }),
    chairPersonTitle: new FormControl(''),
    chairPersonForm: this.formBuilder.array([
      this.formBuilder.group({
          name: new FormControl('', Validators.required),
          designation: new FormControl('', Validators.required),
          email: new FormControl('', [Validators.required, Validators.email]),
        }),
      ],{ validators: [allMustBe(Validators.required)] }),
    viceChairPersonTitle: new FormControl(''),
    viceChairPersonForm: this.formBuilder.array([
      this.formBuilder.group({
          name: new FormControl('', Validators.required),
          designation: new FormControl('', Validators.required),
          email: new FormControl('', [Validators.required, Validators.email]),
        }),
      ],{ validators: [allMustBe(Validators.required)] }),
    membersTitle: new FormControl(''),
    membersForm: this.formBuilder.array([
      this.formBuilder.group({
          name: new FormControl('', Validators.required),
          designation: new FormControl('', Validators.required),
          email: new FormControl('', [Validators.required, Validators.email]),
        }),
      ],{ validators: [allMustBe(Validators.required)] }),
  });

  feedbackForm = this.formBuilder.group({
    howToSendFeedback: new FormControl(''),//[Validators.required]
    howFeedbackProcess: new FormControl(''),//[Validators.required]
    howTofileComplaint: new FormControl(''),//[Validators.required]
    howComplaintsProcess: new FormControl(''),//[Validators.required]
  });
  //


  constructor(public dialogRef: MatDialogRef<AgencyAddComponent>,
    private afs: AngularFirestore,
    private store: AngularFireStorage,
    private router: Router,
    public formBuilder: FormBuilder,
    private loc: Location
  ) { }

  ngOnInit(): void {
    if(sessionStorage.getItem("user_type") == 'agency'){
      this.isAgency = true;
    }
    if(sessionStorage.getItem("user_type") == 'arta') {
      if(sessionStorage.getItem("arta_admin") == 'true'){
        this.isAdmin = true;
      }
    }

    this.loadAgencyCategory()
    this.loadMotherAgencies()
  }

  loadAgencyCategory(){
    this.afs.collection('Agency Category', filter=> filter.orderBy('name')).snapshotChanges()
    .subscribe(info=>{
      let tempArr:any = []
      info.forEach(data=>{
        let agencyCat:any = data.payload.doc.data()
        agencyCat.id = data.payload.doc.id
        tempArr.push(agencyCat)
      })
      this.agencyCategories = tempArr
    })
  }

  loadMotherAgencies(){
    this.afs.collection('Mother Agency',filter=> filter.orderBy('motheragency')).snapshotChanges()
    .subscribe(info=>{
        let tempArr:any = []
        info.forEach(data=>{
          let motheragency: any = data.payload.doc.data()
          motheragency.id = data.payload.doc.id
          tempArr.push(motheragency)
        })
        this.motheragencies = tempArr
    })
  }


  //submit
  onSubmit(){
    this.validateFields();
    this.loading = true;
    // console.log('agencyForm',this.agencyForm.value)
    if(this.agencyForm.valid && this.addressForm.valid && this.profileForm.valid && this.cartForm.valid && this.feedbackForm.valid){
      // console.log('form valid')
    // if(true){
      let agencyVal = this.agencyForm.value
      agencyVal.phoneNumber = agencyVal.phoneNumber.replace(/[^0-9]/g,'');
      // agencyVal.telephoneNumber = agencyVal.telephoneNumber.replace(/[^0-9]/g,'');
      agencyVal.agencyCategory =  this.agencyCategories.filter((result:any)=>(result.id==agencyVal.agencyCategoryID)?true:false)[0]
      agencyVal.motherAgency = this.motheragencies.filter((result:any)=>(result.id == agencyVal.motherAgencyID)?true:false)[0]
      agencyVal.telephoneArray.forEach((telephoneItem:any)=>{
        telephoneItem.telephoneNumber.replace(/[^0-9]/g,'');
        if(telephoneItem.hasTrunkline == false) telephoneItem.trunkline = ''
      })
      const agencyURL = agencyVal.websiteLink //agencyVal.protocol+'//'+agencyVal.websiteLink
      const addressVal = this.addressForm.value
      const profileVal = this.profileForm.value
      const cartVal = this.cartForm.value
      const feedbackVal =  this.feedbackForm.value

      const headOfAgencyTitle = cartVal.headOfAgencyTitle ? cartVal.headOfAgencyTitle : 'Agency Head';
      const headOfAgencyval = cartVal.headOfAgencyForm ;
      const chairPersonTitle = cartVal.chairPersonTitle ? cartVal.chairPersonTitle : 'Chairpersons';
      const chairPersonval = cartVal.chairPersonForm;
      const viceChairPersonTitle = cartVal.viceChairPersonTitle ? cartVal.viceChairPersonTitle : 'Vice-Chairpersons';
      const viceChairPersonval = cartVal.viceChairPersonForm;
      const membersTitle = cartVal.membersTitle ? cartVal.membersTitle : 'Members';
      const membersval = cartVal.membersForm;

      const newAddress = this.concatAddress(addressVal)
      const agencyData:any = {
        abbreviation: agencyVal.agencyAbbreviation,
        name: agencyVal.agencyName,
        contact: agencyVal.phoneNumber,
        address: newAddress,
        email: agencyVal.emailArray,
        mandate: profileVal.mandate,
        mission: profileVal.mission,
        vision: profileVal.vision,
        service_pledge: profileVal.pledges,

        agencyDetails: {
          agencyName: agencyVal.agencyName,
          agencyAbbreviation: agencyVal.agencyAbbreviation,
          website: agencyURL,
          logoURL: null,
          agencyCategory: agencyVal.agencyCategory,
          motherAgency: agencyVal.motherAgency
        },
        cartDetails:{
          titles:{
            headOfAgencyTitle:headOfAgencyTitle,
            chairPersonTitle:chairPersonTitle,
            viceChairPersonTitle:viceChairPersonTitle,
            membersTitle:membersTitle,
          }
        },
        contactDetails: {
          phoneNumber: agencyVal.phoneNumber,
          telephoneArray: agencyVal.telephoneArray,
          emailArray: agencyVal.emailArray,
        },
        addressDetails: addressVal,
        agencyInfo: profileVal,
        feedbackMeasures: feedbackVal,
      }
      // console.log('agencyData',agencyData)
      this.afs.collection("Agency").add(agencyData)
      .then(async result=>{
        const agencyId = result.id;
        //console.log('agencyId',agencyId)
        if(this.agencyImage != null || this.agencyImage != undefined){
          this.uploadLogo(agencyId,agencyId+'',this.agencyImage)
          .then(url=>{
            //console.log("Success Uploading logo")
            agencyData.agencyDetails.logoURL = url
            this.afs.collection('Agency').doc(agencyId).update({
              agencyDetails: agencyData.agencyDetails
            })
            .then(()=>{
              //console.log("Success Updating logo")
            })
            .catch(err=>{
              console.error("Error Updating logo")
            })
          })
          .catch(err=>{
            console.error("Error Uploading logo")
          })
        }
        const hOA = await this.afs.collection("Agency").doc(agencyId).collection("Head of Agency")//.add(headOfAgencyval)
        const hOASub = await headOfAgencyval.map((hOAItem:any)=>{
          return hOA.add(hOAItem);
        })
        const cP = await this.afs.collection("Agency").doc(agencyId).collection("Chairperson")//.add(chairPersonval)
        const cPSub = await chairPersonval.map((cPItem:any)=>{
          return cP.add(cPItem);
        })
        const vCP = await this.afs.collection("Agency").doc(agencyId).collection("Vice Chairperson")//.add(viceChairPersonval)
        const vCPSub = await viceChairPersonval.map((vCPItem:any)=>{
          return vCP.add(vCPItem);
        })
        const mem = await this.afs.collection("Agency").doc(agencyId).collection("Members")//.add(membersval)
        const memSub = await membersval.map((memItem:any)=>{
          return mem.add(memItem);
        })
        if(hOASub && cPSub && vCPSub && memSub){
          alert("Agency Created")
          this.goToAgencyPage(agencyId)
        }else{
          alert("Agency Created")
          this.goToAgencyPage(agencyId)
        }
      })
      .catch(err=>{
        this.loading = false;
        alert('Failed to create agency')
      })
    }else{
      this.loading = false;
      alert("Failed to Submit: Form Invalid")
    }
  }

  concatAddress(address:any){
    return address.addressLine+" "+address.barangay+" "+address.city+" "+address.province+" "+address.postal;
  }
  //


  //image uploader 
  onFileChange(event:any){
    if(event.target.files && event.target.files.length > 0){
      this.disablerWhileLoadingLogo = true;
      const reader = new FileReader();
      const [file] = event.target.files;
      if(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'){
        this.logoName = file.name
        this.logoSize = (file.size / 1024).toFixed(2);
        reader.readAsDataURL(file)
        reader.onprogress = (e: ProgressEvent) => {
          this.fileProgress(e);
        }
        reader.onload = () =>{
          this.agencyImage = event.target.files[0] as File
          this.imgSrc = reader.result as string;
          this.agencyForm.patchValue({
            logoSrc: reader.result
          })
        }
        reader.onerror = () =>{
          alert("Error in uploading image")
          this.resetLogo();
        }
      }
      else{
        alert("Incorrect file type")
        this.resetLogo();
      }
    }
  }

  fileProgress(e: ProgressEvent){
    this.showLogoUploadProgress = true
    let progress = 0
    this.currentLogoUploadProgress = progress
    let loaded = ''
    this.loadedLogoUploadProgress = loaded
    if(e.lengthComputable){
      progress = Math.round((e.loaded/e.total)*100)
      this.currentLogoUploadProgress = progress
      loaded = (e.loaded/1024).toFixed(2)
      this.loadedLogoUploadProgress = loaded
      if(progress == 100) this.disablerWhileLoadingLogo = false;
    }
  }

  uploadLogo(agencyId:any,logoName:any, file:File){
    let filePath  = this.logoPath+"/"+agencyId+"/logo/uploadedLogo";
    return this.store.upload(filePath,file)
    .then(res=>{
      return res.ref.getDownloadURL()
    })
    .catch(rej=>{
      return null
    })
  }

  resetLogo(){
    this.currentLogoUploadProgress = 0;
    this.loadedLogoUploadProgress = '';
    this.showLogoUploadProgress = false;
    this.logoName = ''
    this.agencyImage = null
    this.imgSrc = this.defaultImgSrc;
    this.agencyForm.patchValue({
      logoSrc: '',
      logoFile: ''
    });
    (document.getElementById('logoFile') as HTMLInputElement).value = "";
    this.disablerWhileLoadingLogo = false;
  }
  //

  // agency form getters
  get telephoneArray(){
    return this.agencyForm.controls['telephoneArray'] as FormArray
  }
  get emailArray(){
    return this.agencyForm.controls['emailArray'] as FormArray
  }
  // 

  //cart form getters
  get headOfAgencyForm(){
    return this.cartForm.controls['headOfAgencyForm'] as FormArray;
  }

  get chairPersonForm(){
    return this.cartForm.controls['chairPersonForm'] as FormArray;
  }

  get viceChairPersonForm(){
    return this.cartForm.controls['viceChairPersonForm'] as FormArray;
  }

  get membersForm(){
    return this.cartForm.controls['membersForm'] as FormArray;
  }
  //
  

  //forms controller
  getFormsArray(listName: string):FormGroup[]{
    switch(listName){
      case 'hOA': return this.headOfAgencyForm.controls as FormGroup[]
      case 'cP': return this.chairPersonForm.controls as FormGroup[]
      case 'vCP': return this.viceChairPersonForm.controls as FormGroup[]
      case 'memb': return this.membersForm.controls as FormGroup[]
      case 'telephone': return this.telephoneArray.controls as FormGroup[]
      case 'email': return this.emailArray.controls as FormGroup[]
      default: return []
    }
    // if(listName === 'hOA')
    //   return this.headOfAgencyForm.controls as FormGroup[]
    // else if(listName === 'cP')
    //   return this.chairPersonForm.controls as FormGroup[]
    // else if(listName === 'vCP')
    //   return this.viceChairPersonForm.controls as FormGroup[]
    // else if(listName === 'memb')
    //   return this.membersForm.controls as FormGroup[]
    // else if(listName === 'telephone')
    //   return this.telephoneArray.controls as FormGroup[]
    // else if(listName === 'email')
    //   return this.emailArray.controls as FormGroup[]
    // else
    //   return []
  }

  addNewRow(listName:any){
    // const newRowForm = 
    if(listName==='hOA')
      this.headOfAgencyForm.push(this.formBuilder.group({
        name: new FormControl('', Validators.required),
        designation: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.required, Validators.email]),
      }))
    else if(listName==='cP')
      this.chairPersonForm.push(this.formBuilder.group({
        name: new FormControl('', Validators.required),
        designation: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.required, Validators.email]),
      }))
    else if(listName === 'vCP')
      this.viceChairPersonForm.push(this.formBuilder.group({
        name: new FormControl('', Validators.required),
        designation: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.required, Validators.email]),
      }))
    else if(listName === 'memb')
      this.membersForm.push(this.formBuilder.group({
        name: new FormControl('', Validators.required),
        designation: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.required, Validators.email]),
      }))
    else if(listName === 'telephone')
      this.telephoneArray.push(this.formBuilder.group({
        telephoneNumber: new FormControl('',
        [
          Validators.required,
          this.checkTelephoneNumber()
        ]),
        hasTrunkline: new FormControl(false),
        trunkline: new FormControl('',[this.checkTrunklineNumber()]),
      }))
    else if(listName === 'email')
      this.emailArray.push(this.formBuilder.group({
        email: new FormControl('',[Validators.required,Validators.email])
      }))
  }

  removeCartFormRow(listName:string, index:any){
    if(index >= 0){
      if(listName === 'hOA') (this.headOfAgencyForm.removeAt(index))
      else if(listName === 'cP') (this.chairPersonForm.removeAt(index))
      else if(listName === 'vCP') (this.viceChairPersonForm.removeAt(index))
      else if(listName === 'telephone') (this.telephoneArray.removeAt(index))
      else if(listName === 'email') (this.emailArray.removeAt(index))
    }
    else console.warn('no index value to remove')
  }

  selectChange(controlName:any,value:any){
    if(controlName === 'website'){
      this.agencyForm.patchValue({
        protocol: value
      })
    }

    if(controlName === 'motherAgencyID'){
      this.agencyForm.patchValue({
        motherAgencyID: value
      })
      let agencyMother = this.motheragencies.filter((result:any)=>(this.agencyForm.value.motherAgencyID == result.id)?true:false)
      if(agencyMother.length > 0)
        this.showMotherAgency = agencyMother[0].motheragency
      else
      this.showMotherAgency = ""
      
    }

    if(controlName === 'agencyCategoryID'){
      this.agencyForm.patchValue({
        agencyCategoryID: value
      })

      let agencyCategory = this.agencyCategories.filter((result:any)=>(this.agencyForm.value.agencyCategoryID==result.id)?true:false)
      if(agencyCategory.length > 0)
        this.showAgencyCategory = "("+agencyCategory[0].abbreviation+") "+agencyCategory[0].name
      else
        this.showAgencyCategory = ""
    }
  }

  showTrunkline(row:any){
  //   if((this.telephoneArray.controls as FormGroup[])[row].controls)
    // console.log()
    if((this.telephoneArray.controls as FormGroup[])[row].controls['hasTrunkline'].value == true) return true
    return false
  }

  getNumberOfElements(listName:any){
    if(listName === 'hOA') return this.headOfAgencyForm.controls.length
    else if(listName === 'cP') return this.chairPersonForm.controls.length
    else if(listName === 'vCP') return this.viceChairPersonForm.controls.length
    else if(listName === 'memb') return this.membersForm.controls.length
    else if(listName === 'telephone') return this.telephoneArray.controls.length
    else if(listName === 'email') return this.emailArray.controls.length
    else return 0
  }
  //


  //reroutes
  cancel(){
    // this.router.navigate(['/agency']);
    // window.location.
    //this.loc.back();
    this.dialogRef.close();
  }

  goToAgencyPage(id:any){
    this.dialogRef.close();
    this.router.navigate(['/artemis/view/agency/',id]);
  }
  //


  //theme changer
  async setTheme() {
    switch(sessionStorage.getItem("user_type")){
      case 'arta':
        this.style_theme = 'theme-arta';
        if(sessionStorage.getItem("arta_admin") == "true" ){
          this.isAdmin = true;
        }
        break;
      case 'agency':
        this.style_theme = 'theme-agency';
        break;
      case 'personal':
        this.style_theme = 'theme-default';
        break;
      default:
        this.style_theme = 'theme-default';
    }
  }
  //

  // Validators
  checkUrl():ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>{
      if(this.agencyForm != undefined){
        const val = control.value ? control.value : null
        if(val){
          let tempbool: boolean = false
          let errors = {
            pattern: false,
          }
          // let regex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
          let regex = /^(https?:\/\/)((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/
          if (!regex.test(val)){
            tempbool = true
            errors.pattern = true
          }
          return tempbool ? errors : null
        }
      }
      return null
    }
  }

  checkPhoneNumber():ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>{
      if(this.agencyForm != undefined){
        const val = control.value ? control.value : null // read '' as any in indexOf
        if(val){
          let tempbool: boolean = false
          let errors = {
            minlength: false,
            // pattern: false,
          }
          if(val.length > 0){
            if( val.length != 12){
              tempbool = true;
              errors.minlength = true;
            }
            // console.log(val.match(/(\d{0,9})/g))
            // if(){ 
            //   tempbool = true;
            //   errors.pattern = true;
            // }
          }
          return tempbool ? errors : null
        }
      }
      return null
    }
  }

  checkTelephoneNumber():ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>{
      if(this.agencyForm != undefined){
        const val = control.value ? control.value : null // read '' as any in indexOf
        let tempbool: boolean = false
        let errors = {
          minlength: false,
          required:false,
          // pattern: false,
        }
        if(val){
          // if(val.length == 0) {
          //   tempbool = true
          //   errors.required = true
          // }
          if(val.length > 0){
            if( val.length < 14){
              tempbool = true;
              errors.minlength = true;
            }
            // console.log(val.match(/(\d{0,9})/g))
            // if(){ 
            //   tempbool = true;
            //   errors.pattern = true;
            // }
          }
        } else{
          tempbool = true
          errors.required = true
        }
        return tempbool ? errors : null
      }
      return null
    }
  }

  checkTrunklineNumber(): ValidatorFn {
    return (control: AbstractControl): {[key: string]:any} | null =>{
      if(this.agencyForm != undefined){
        let tempbool:boolean = false
        let errors = {
          required:false
        }
        if(control.parent != null && control.parent != undefined){
          const hasTrunkline:any = (control.parent as FormGroup).controls['hasTrunkline'].value
          const trunkline:any = control.value
          if(hasTrunkline == true){ 
            if(trunkline != undefined){
              if(trunkline.length == 0){
                tempbool = true
                errors.required = true
              }
            }
            else{
              tempbool = true;
              errors.required = true
            }
          }
        }
        return tempbool ? errors : null
      }
      return null
    }
  }
  // !Validators


  //Error handlers
  isTouched(formGroup:any, controlName:any, ctr?:any):boolean{
    if(formGroup === 'agency')
      return (this.agencyForm.get(controlName) as FormControl).touched;
    
    else if(formGroup === 'address')
      return (this.addressForm.get(controlName) as FormControl).touched;
    
    else if(formGroup === 'profile')
      return (this.profileForm.get(controlName) as FormControl).touched;
    
    // else if(formGroup === 'carthead')
    //   return (((this.headOfAgencyForm.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).touched;
    
    // else if(formGroup === 'cartchair')
    //   return (((this.chairPersonForm.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).touched;
    
    // else if(formGroup === 'cartvchair')
    //   return (((this.viceChairPersonForm.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).touched;
    
    // else if(formGroup === 'cartmemb')
    //   return (((this.membersForm.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).touched;
    
    else if(formGroup === 'feedback')
      return (this.feedbackForm.get(controlName) as FormControl).touched;
    
    else return false
  }

  hasError(formGroup:any,controlName:any, validType:any, ctr?:any){
    if(formGroup === 'agency'){
      return ((this.agencyForm.get(controlName) as FormControl).hasError(validType) 
      && ((this.agencyForm.get(controlName) as FormControl).dirty 
      || (this.agencyForm.get(controlName) as FormControl).touched));
    }

    if(formGroup === 'emailArray'){
      return (((this.emailArray.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).hasError(validType)
      && ((((this.emailArray.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).dirty
      || (((this.emailArray.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).touched)
    }

    if(formGroup === 'telephoneArray'){
      return (((this.telephoneArray.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).hasError(validType)
      && ((((this.telephoneArray.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).dirty
      || (((this.telephoneArray.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).touched)
    }

    else if(formGroup === 'address'){
      return ((this.addressForm.get(controlName) as FormControl).hasError(validType) 
      && ((this.addressForm.get(controlName) as FormControl).dirty 
      || (this.addressForm.get(controlName) as FormControl).touched));
    }

    else if(formGroup === 'profile'){
      return ((this.profileForm.get(controlName) as FormControl).hasError(validType) 
      && ((this.profileForm.get(controlName) as FormControl).dirty 
      || (this.profileForm.get(controlName) as FormControl).touched));
    }

    else if(formGroup === 'carthead'){
      return (((this.headOfAgencyForm.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).hasError(validType)
      && ((((this.headOfAgencyForm.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).dirty
      || (((this.headOfAgencyForm.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).touched)
    }

    else if(formGroup === 'cartchair'){
      return (((this.chairPersonForm.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).hasError(validType)
      && ((((this.chairPersonForm.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).dirty
      || (((this.chairPersonForm.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).touched)
    }

    else if(formGroup === 'cartvchair'){
      return (((this.viceChairPersonForm.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).hasError(validType)
      && ((((this.viceChairPersonForm.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).dirty
      || (((this.viceChairPersonForm.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).touched)
    }

    else if(formGroup === 'cartmemb'){
      return (((this.membersForm.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).hasError(validType)
      && ((((this.membersForm.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).dirty
      || (((this.membersForm.controls as FormGroup[])[ctr] as FormGroup).get(controlName) as FormControl).touched)
    }

    else if(formGroup === 'feedback'){
      return ((this.feedbackForm.get(controlName) as FormControl).hasError(validType) 
      && ((this.feedbackForm.get(controlName) as FormControl).dirty 
      || (this.feedbackForm.get(controlName) as FormControl).touched));
    }

    else return null;
  }

  //go to next stepper

  onStepChange(stepper: MatStepper){
    console.log(stepper.selectedIndex)
    switch(stepper.selectedIndex){
      case -1:
      case  0:
            this.hasBackStepper= false
            this.hasNextStepper =true
            this.isLastStepper =false
            break;
      case 5:
          this.hasBackStepper =true
          this.hasNextStepper =false
          this.isLastStepper = true
        break;
      case 1:
      case 2:
      case 3:
      case 4:
          this.hasBackStepper =true
          this.hasNextStepper =true
          this.isLastStepper =false
        break
    }

  }

  goBack(stepper: MatStepper){
    stepper.previous()
    this.onStepChange(stepper)
    
  }
  goNext(stepper: MatStepper){
    stepper.next()
    this.onStepChange(stepper)
   
  }


  validateFields(): void {
    if (!this.agencyForm.valid) {
      // Mark the form and inputs as touched so the errors messages are shown
      this.agencyForm.markAsTouched();
      for (const control in this.agencyForm.controls) {
        // console.log('control',control)
        if (this.agencyForm.controls.hasOwnProperty(control)) {
          if(this.agencyForm.controls[control] instanceof FormControl){
            // console.log(control+': control')
            this.agencyForm.controls[control].markAllAsTouched();
            this.agencyForm.controls[control].markAsDirty();
          }
          else if(this.agencyForm.controls[control] instanceof FormGroup){
            // console.log(control+': group')
            this.agencyForm.controls[control].markAllAsTouched();
            this.agencyForm.controls[control].markAsDirty();
          }
          else if(this.agencyForm.controls[control] instanceof FormArray){
            // console.log(control+': array')
            for(const formArrCtr in ((this.agencyForm.controls[control] as FormArray).controls as FormGroup[])){
              ((((this.agencyForm.controls[control] as FormArray).controls as FormGroup[]))[formArrCtr] as FormGroup).markAllAsTouched();
              ((((this.agencyForm.controls[control] as FormArray).controls as FormGroup[]))[formArrCtr] as FormGroup).markAsDirty();
              for(const formGroupCtr in (((this.agencyForm.controls[control] as FormArray).controls as FormGroup[])[formArrCtr] as FormGroup).controls){
                if((((this.agencyForm.controls[control] as FormArray).controls as FormGroup[])[formArrCtr] as FormGroup).controls.hasOwnProperty(formGroupCtr)){
                  (((this.agencyForm.controls[control] as FormArray).controls as FormGroup[])[formArrCtr] as FormGroup).controls[formGroupCtr].markAllAsTouched();
                  (((this.agencyForm.controls[control] as FormArray).controls as FormGroup[])[formArrCtr] as FormGroup).controls[formGroupCtr].markAsDirty();
                }
              }
            }
          }
        }
      }
    }

    if (!this.addressForm.valid) {
      // Mark the form and inputs as touched so the errors messages are shown
      this.addressForm.markAsTouched();
      for (const control in this.addressForm.controls) {
        if (this.addressForm.controls.hasOwnProperty(control)) {
          this.addressForm.controls[control].markAllAsTouched();
          this.addressForm.controls[control].markAsDirty();
        }
      }
    }

    if (!this.profileForm.valid) {
      // Mark the form and inputs as touched so the errors messages are shown
      this.profileForm.markAsTouched();
      for (const control in this.profileForm.controls) {
        if (this.profileForm.controls.hasOwnProperty(control)) {
          this.profileForm.controls[control].markAllAsTouched();
          this.profileForm.controls[control].markAsDirty();
        }
      }
    }

    if (!this.headOfAgencyForm.valid) {
      // Mark the form and inputs as touched so the errors messages are shown
      for(const formArrCtr in (this.headOfAgencyForm.controls as FormGroup[])){
        ((this.headOfAgencyForm.controls as FormGroup[])[formArrCtr] as FormGroup).markAllAsTouched();
        ((this.headOfAgencyForm.controls as FormGroup[])[formArrCtr] as FormGroup).markAsDirty();
        for(const formGroupCtr in (this.headOfAgencyForm.controls as FormGroup[])[formArrCtr]){
          if(((this.headOfAgencyForm.controls as FormGroup[])[formArrCtr] as FormGroup).controls.hasOwnProperty(formGroupCtr)){
            ((this.headOfAgencyForm.controls as FormGroup[])[formArrCtr] as FormGroup).controls[formGroupCtr].markAllAsTouched();
            ((this.headOfAgencyForm.controls as FormGroup[])[formArrCtr] as FormGroup).controls[formGroupCtr].markAsDirty();
          }
        }
      }
    }

    if (!this.chairPersonForm.valid) {
      // Mark the form and inputs as touched so the errors messages are shown
      for(const formArrCtr in (this.chairPersonForm.controls as FormGroup[])){
        ((this.chairPersonForm.controls as FormGroup[])[formArrCtr] as FormGroup).markAllAsTouched();
        ((this.chairPersonForm.controls as FormGroup[])[formArrCtr] as FormGroup).markAsDirty();
        for(const formGroupCtr in (this.chairPersonForm.controls as FormGroup[])[formArrCtr]){
          if(((this.chairPersonForm.controls as FormGroup[])[formArrCtr] as FormGroup).controls.hasOwnProperty(formGroupCtr)){
            ((this.chairPersonForm.controls as FormGroup[])[formArrCtr] as FormGroup).controls[formGroupCtr].markAllAsTouched();
            ((this.chairPersonForm.controls as FormGroup[])[formArrCtr] as FormGroup).controls[formGroupCtr].markAsDirty();
          }
        }
      }
    }

    if (!this.viceChairPersonForm.valid) {
      // Mark the form and inputs as touched so the errors messages are shown
      for(const formArrCtr in (this.viceChairPersonForm.controls as FormGroup[])){
        ((this.viceChairPersonForm.controls as FormGroup[])[formArrCtr] as FormGroup).markAllAsTouched();
        ((this.viceChairPersonForm.controls as FormGroup[])[formArrCtr] as FormGroup).markAsDirty();
        for(const formGroupCtr in (this.viceChairPersonForm.controls as FormGroup[])[formArrCtr]){
          if(((this.viceChairPersonForm.controls as FormGroup[])[formArrCtr] as FormGroup).controls.hasOwnProperty(formGroupCtr)){
            ((this.viceChairPersonForm.controls as FormGroup[])[formArrCtr] as FormGroup).controls[formGroupCtr].markAllAsTouched();
            ((this.viceChairPersonForm.controls as FormGroup[])[formArrCtr] as FormGroup).controls[formGroupCtr].markAsDirty();
          }
        }
      }
    }

    if (!this.membersForm.valid) {
      // Mark the form and inputs as touched so the errors messages are shown
      for(const formArrCtr in (this.membersForm.controls as FormGroup[])){
        ((this.membersForm.controls as FormGroup[])[formArrCtr] as FormGroup).markAllAsTouched();
        ((this.membersForm.controls as FormGroup[])[formArrCtr] as FormGroup).markAsDirty();
        for(const formGroupCtr in (this.membersForm.controls as FormGroup[])[formArrCtr]){
          if(((this.membersForm.controls as FormGroup[])[formArrCtr] as FormGroup).controls.hasOwnProperty(formGroupCtr)){
            ((this.membersForm.controls as FormGroup[])[formArrCtr] as FormGroup).controls[formGroupCtr].markAllAsTouched();
            ((this.membersForm.controls as FormGroup[])[formArrCtr] as FormGroup).controls[formGroupCtr].markAsDirty();
          }
        }
      }
    }

    if (!this.feedbackForm.valid) {
      // Mark the form and inputs as touched so the errors messages are shown
      this.feedbackForm.markAsTouched();
      for (const control in this.feedbackForm.controls) {
        if (this.feedbackForm.controls.hasOwnProperty(control)) {
          this.feedbackForm.controls[control].markAllAsTouched();
          this.feedbackForm.controls[control].markAsDirty();
        }
      }
    }
  }
  //
}
