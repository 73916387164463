import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';
import { formatISO } from 'date-fns';
import { Observable, of, Subject } from 'rxjs';
import { FileUploadService } from 'src/app/file-upload.service';
import { AngularFireAuth } from '@angular/fire/auth';
import {  tap, take, first } from 'rxjs/operators';
import { NotificationsEmailer } from 'src/app/service/notifications-emailer.service';
import { combineLatest } from 'rxjs';


@Component({
  selector: 'app-ticketing-filing',
  templateUrl: './ticketing-filing.component.html',
  styleUrls: ['./ticketing-filing.component.scss']  
})
export class TicketingFilingComponent implements OnInit {
  new_tag_panel: boolean = false;
  regulations: any = [];
  ticket: any = [];
  loading: boolean = false;

  userType: any = sessionStorage.getItem("user_type");
  user_agency:any;

  dtTrigger: Subject<any> = new Subject<any>();
  showFormErrors: boolean = false;
  uploading: boolean = true;
  display_ticket_id: string = "";
  ticket_id: string = "";
  ticket_file: string = "";
  ticket_id_path: any;
  uploadProgress!: Observable<number | undefined>;
  expiresOnDateISO: string = "";

  
  newTicket: FormGroup = this.fb.group({
    nature: new FormControl(''),
    regulation: new FormControl(''),
    ticket_id: new FormControl(''),
    ticket_text: new FormControl(''),
    tags_list: this.fb.array([]),
  });

  constructor(
    private afs: AngularFirestore, 
    private fb:FormBuilder,
    private router: Router, 
    private route: ActivatedRoute, 
    public fileUploadService: FileUploadService,
    public auth: AngularFireAuth,
    private nE: NotificationsEmailer,
    ) { 
  }



  ngOnInit(): void {

    this.loadRegulations();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  refreshComponent(){
    this.router.navigate([this.router.url]);
  }



  openNewTag(){
    this.new_tag_panel = true;
    this.quantities().push(this.newQuantity());
    this.newTicket.controls['nature'].setValue("tag");
    //console.log(this.newTicket.controls['nature'].value);
    //TODO Set Tag Type Value to new Tag
  }

  quantities() : FormArray {
    return this.newTicket.get("tags_list") as FormArray
  }
   
  newQuantity(): FormGroup {
    return this.fb.group({
      tag_type: '',  
      tag_name: '',
    })
  }
   
  addQuantity() {
    this.quantities().push(this.newQuantity());
  }
   
  removeQuantity(i:number) {
    this.quantities().removeAt(i);

    if(this.quantities().length < 1){
      this.new_tag_panel = false;
    }
  }

  upload(event: any, type: any) {
    var allowedExtensions = /(\.jpg|\.jpeg|\.pdf|\.png)$/i;
    if(!allowedExtensions.exec(event.target.files[0].name)) {
      //this.newTicket.reset();
      this.ticket_id = "";
      this.display_ticket_id = "";
     
      this.uploading = true;
      //this.showFormErrors = true;
      return;
    }
    this.showFormErrors = false;

    if (type === "ticket") {
      this.ticket_id_path = event.target.files[0]
      this.display_ticket_id = this.ticket_id_path.name;

      if (this.ticket_id_path !== null) {
        this.uploading = true
        // new path to make it somewhat less difficult to navigate
        const filePath = `ticket/files/${formatISO(Date.now())}`;

        //new method that uses an API endpoint to access file storage through google cloud
        //old method was due to firebase DNS issues
        this.fileUploadService.uploadFile(this.ticket_id_path, filePath)
          .subscribe((resp) => {
            if (resp.type === HttpEventType.Response) {
              console.log('Upload complete');
              this.expiresOnDateISO = resp.body.expiresOn;
              this.ticket_file = resp.body.downloadUrl;
              this.uploading = false;
            }
            if (resp.type === HttpEventType.UploadProgress) {
              const total = resp.total || 1
              const percentDone = Math.round(100 * resp.loaded / total);
              this.uploadProgress = of(percentDone);
            }
          });
      }
    }
  }
   
  // onSubmit() {
  //   this.afs.collection(`Tickets`).add({
  //     nature: this.newTicket.controls['nature'].value,
  //     regulation: this.newTicket.controls['regulation'].value,
  //     ticket_text:  this.newTicket.controls['ticket_text'].value,
  //     ticket_file:  this.ticket_file,
  //     tags_list:  this.newTicket.controls['tags_list'].value,

  //   }).then((docRef: any) => {
  //     alert("create ticket successful");
  //     console.log(docRef)
  //     //const documentID = docRef.id;

  //     this.router.navigate(['/pbris/regulatory-management/']);
  //     this.loading = false
  //   }).catch(error => {
  //     this.loading = false
  //     alert(error.message)
  //   });

  //   console.log(this.newTicket.value);

  //   this.newTicket.reset;
  // }

  async onSubmit() {
    console.log('ticket form',this.newTicket.value)
    const ticketVal:any = this.newTicket.value
    // this.getDetailsForEmailer(ticketVal)
    const newTicket = await this.afs.collection(`Tickets`).add({
      nature: this.newTicket.controls['nature'].value,
      regulation: this.newTicket.controls['regulation'].value,
      ticket_text:  this.newTicket.controls['ticket_text'].value,
      ticket_file:  this.ticket_file,
      tags_list:  this.newTicket.controls['tags_list'].value,

    })
    if(newTicket){
      return this.toNotif(ticketVal)
      .then((docRef: any) => {
         
          alert("create ticket successful");
          console.log(docRef)
          //const documentID = docRef.id;
    
          this.router.navigate(['/pbris/regulatory-management/']);      
          this.loading = false
          // return this.toNotifications(newTicket.id,this.newTicket.controls['ticket_text'].value, this.newTicket.controls['nature'].value)
      
      }).catch(error => {
        this.loading = false
        alert(error.message)
        return null
      });
    }
    else{
      alert("Error creating new ticket")
      return null
    }
  }

  async loadRegulations() {
    return this.afs.collection('PBRIS 1 Regulations', filter => filter.orderBy('short_title')).snapshotChanges().subscribe((data: any) => 
    {
      this.regulations = []
      data.forEach((results: any) => {
        let item: any = results.payload.doc.data();
        item.id = results.payload.doc.id;

        this.regulations.push(item);
        })
      
      });    
  }  

  async toNotif(ticketVal:any){
    this.getDetailsForEmailer(ticketVal)
    const user = await this.auth.currentUser;
    let fromOfficer = user?.displayName;

    const newNotif = await this.afs.collection('Notifications').add({
      
      display_name: fromOfficer,
      uid:user?.uid,
      agency: null,
      event: 'Ticket Filed',
      message: 'This is a notification Message',
      level: 'sample 1',
      notif_type: 'Tickets',
      notif_in:'pbris'
    })
    
    this.getUserAgency(user?.uid,newNotif.id)
  }

  async getUserAgency(uid:any,notif_id:any ){
    this.afs.collection('Users').doc(uid)
    .snapshotChanges()
    .subscribe(
    (data:any) =>{
      let item = data.payload.data()      
      item.user_agency = item.government.agency 
      console.log("agency_name: ", item.user_agency)

      this.afs.collection('Notifications').doc(notif_id).update({
        agency:item.user_agency
      })
    })
  }

  getCurrentUser(){
    return new Promise((res,rej)=>{
      this.auth.user.subscribe({
        next:(info)=>{
          let uid = info?.uid
          if(uid){
            this.afs.collection('Users').doc(uid).snapshotChanges()
            .pipe(first())
            .subscribe({
              next:(result)=>{
                let user:any = result.payload.data()
                user.id = result.payload.id
                return res(user)
              },
              error:(err)=>{
                return res(false)
              }
            })
          }
        },
        error:(err)=>{
          return res(false)
        }
      })
    })
  }

  getAgency(agencyCode:any){
    return new Promise((res, rej)=>{
      let batches = []
      let batchCode:any = []
      batches.push(this.afs.collection('Agency').doc(agencyCode).snapshotChanges())
      batchCode.push('id')

      batches.push(this.afs.collection('Agency', filter=> filter.where('agencyDetails.agencyName','==',agencyCode)).snapshotChanges())
      batchCode.push('name')

      batches.push(this.afs.collection('Agency', filter=> filter.where('name','==',agencyCode)).snapshotChanges())
      batchCode.push('name')

      combineLatest(batches)
      .pipe(first())
      .subscribe({
        next:(result)=>{
          let ctr = 0
          let agency:any;
          result.every((content:any,ctr:number)=>{
            if(batchCode[ctr] == 'id'){
              if(content.payload.exists){
                agency = content.payload.data()
                agency.id = content.payload.id
                return false
              }
            }
            else if(batchCode[ctr] == 'name'){
              content.every((item:any)=>{
                if(item.payload.doc.exists){
                  agency = item.payload.doc.data()
                  agency.id = item.payload.doc.id
                  return false
                }
                return true
              })
              if(agency) return false
            }
            return true
          })
          if(agency) return res(agency)
          return res(false)
        },
        error:(err)=>{
          console.error('getAgency error: ',err)
          return res(false)
        }
      })
    })
  }

  getUsersUnderArta(accountSubtype:any){
    return new Promise((res,rej)=>{
      this.afs.collection('Users',filter=>filter.where('credentials.account_subtype','==',accountSubtype)).snapshotChanges()
      .pipe(first())
      .subscribe({
        next:(result)=>{
          if(result.length > 0){
            let tempUserEmailArr:any[] = []
            result.map(item =>{
              let user:any = item.payload.doc.data()
              user.id = item.payload.doc.id
              if(!tempUserEmailArr.includes(user.credentials.email)) tempUserEmailArr.push(user.credentials.email)
            })
            return res(tempUserEmailArr)
          } else {
            console.warn('no arta users with account subtype')
            return res(false)
          }
        },
        error:(err)=>{
          console.warn('getting arta users error: ',err)
          return res(false)
        }
      })
    })
  }

  getUsersUnderAgency(agencyIds:any[], accountSubtype:any ,templateCode:any,data:any){
    this.afs.collection('Users', 
      filter=>filter.where('government.agency','in',agencyIds)
        .where('credentials.account_subtype','==',accountSubtype)
    ).snapshotChanges()
    .pipe(first())
    .subscribe({
      next: async (res)=>{
        if(res.length > 0){
          let tempUserEmailArr:any[] = []
          res.map(item =>{
            let user:any = item.payload.doc.data()
            user.id = item.payload.doc.id
            if(!tempUserEmailArr.includes(user.credentials.email)) tempUserEmailArr.push(user.credentials.email)
          })
          console.log('tempUserEmailArr',tempUserEmailArr)
          if(tempUserEmailArr.length > 0) this.toSendNotifEmail(tempUserEmailArr,templateCode,data)
        }
      }
    })
  }

  async getDetailsForEmailer(ticketVal:any){
    console.log(ticketVal)
    if(ticketVal.nature == 'tag' && ticketVal.tags_list.length > 0){
      const currentUser:any = await this.getCurrentUser()
      if(currentUser){
        const agency:any = await this.getAgency(currentUser.government.agency)
        const artaUsers:any = await this.getUsersUnderArta('ARTA - IT/Admin')
        if(agency && artaUsers && artaUsers.length > 0){
          let data = {
            ticketFor: ticketVal.regulation,
            agencyName: agency.agencyDetails ? agency.agencyName ? agency.agencyName : agency.name : agency.name,
          }
          this.toSendNotifEmail(artaUsers,161,data)
        } else console.warn('agency/artaUsers is invalid')
      } else console.warn('current user is invalid')
    }
    else if(ticketVal.nature == 'technical' || ticketVal.nature == 'functional'){
      const artaUsers:any = await this.getUsersUnderArta('ARTA - IT/Admin')
      if(artaUsers && artaUsers.length>0){
        let data = {
          ticketFor:ticketVal.regulation,
          link:'https://arta-respond.web.app', //link to ticket report
          ticketPurpose:ticketVal.nature+" issue",
        }
        this.toSendNotifEmail(artaUsers,162,data)
      }
    }
    else if(ticketVal.nature == 'complaint'){
      const artaUsers:any = await this.getUsersUnderArta('ARTA - IT/Admin')
      if(artaUsers && artaUsers.length>0){
        let data = {
          ticketFor:ticketVal.regulation,
          link:'https://arta-respond.web.app', //link to ticket report
          ticketPurpose:ticketVal.nature,
        }
        this.toSendNotifEmail(artaUsers,162,data)
      }
    }

  }

  toSendNotifEmail(currentUsers:any[], templateCode:number,data:any){
    console.log('sending email: ',currentUsers, templateCode)
    if(templateCode > 0){
      // this.nE.sendEmailNotiftoUser(currentUsers,1,templateCode,data)
      // .subscribe({
      //     next:(apiResponse)=>{
      //         console.log('TicketingFilingComponent emailer: ',apiResponse)
      //     },
      //     error:(err)=>{
      //         console.error('TicketingFilingComponent emailer error: ',err)
      //     }
      // })
    } else console.error('TicketingFilingComponent emailer error: no email template for account type')
  }
  
}
