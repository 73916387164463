import { Component, OnInit,ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { tap, take } from 'rxjs/operators';
import { ARTEMIS } from 'src/app/entities/constant';
import { GovernmentAccountSubtype } from 'src/app/entities/worklist';
import { ConfirmSendVerificationComponent } from 'src/app/modal-manager/confirm-send-verification/confirm-send-verification.component';
import { ModalManagerComponent } from 'src/app/modal-manager/modal-manager.component'
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-artemis-login-page',
  templateUrl: './artemis-login-page.component.html',
  styleUrls: ['./artemis-login-page.component.scss']
})
export class ArtemisLoginPageComponent implements OnInit {
  modalRef?: MDBModalRef;
  loading: boolean = false;
  hide: boolean = true;
  
  show:boolean= false;
  

  form = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),

  });

  public call_registration: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AngularFireAuth,
    private afs: AngularFirestore,
    public modalService: MDBModalService,
    private snackBarService: SnackbarNotifService) { }

  ngOnInit(): void {

    sessionStorage.setItem("currentSystem",ARTEMIS)
    this.call_registration = false;
    

    this.route.queryParams.subscribe(params => {
      if (params.initial) {
        this.callModal();
      }
    })
    if (this.route.snapshot.params.subpage) {
      if (this.route.snapshot.params.subpage == 'register') {
        this.toggleRegistration();
      }
    }
  }

  toggleRegistration() {
    this.call_registration = !this.call_registration ;
  }

  callModal() {
    this.modalRef = this.modalService.show(ModalManagerComponent,
      {
        backdrop: 'static',
        data: {
          header_content: {
            logo: "/assets/arta-logo.png"
          },
          body_content: {
            message: "You can experience ARTEMIS without logging in. Click here to proceed with a guest account."
          },
          buttons: {
            confirm: "Continue As Guest",
            registration: "Sign Up for an Account",
            cancel: "No thanks, I have an account"
          },
          login_route: "/artemis",
          register_route: "/artemis/login/register",
        }
      });
  }

  callMissingUserModal() {
    this.modalRef = this.modalService.show(ModalManagerComponent,
      {
        backdrop: 'static',
        data: {
          header_content: {
            logo: "/assets/arta-logo.png"
          },
          body_content: {
            message: "This user does not exist. Would you like to sign up for an ARTEMIS account?"
          },
          buttons: {
            confirm: "No (Continue As Guest)",
            registration: "Yes (Sign Up for an Account)"
          },
          login_route: "/artemis",
          register_route: "/artemis/login/register",
        }
      });
  }

  onSubmit() {
    this.loading = true
    //console.log(this.form.value)
    this.auth.signInWithEmailAndPassword(this.form.value.username,
      this.form.value.password).then(cred => {
        if (cred.user?.uid) {
          // get account type from firestore
          this.afs.doc(`Users/${cred.user.uid}`).snapshotChanges()
            .pipe(
              tap(
                async (data: any) => {
                  let item = data.payload.data()
                  let account_type = item.credentials.account_type.toLowerCase();
                  //console.log('account_typ',item.credentials.account_type)
                  // Set account_subtype in sessionStorage for other features, assumes that it matches GovernmentAccountSubtype
                  sessionStorage.setItem("account_subtype", item.credentials.account_subtype);
                  sessionStorage.setItem("currentSystem",ARTEMIS)
                  switch (account_type) {
                    case "government":
                      if (item.credentials.account_subtype && item.credentials.account_subtype.toLowerCase().includes("arta")) {
                        sessionStorage.setItem("user_type", "arta");

                        switch(item.credentials.account_subtype){
                          case GovernmentAccountSubtype.ARTA_ADMIN:{
                            sessionStorage.setItem("arta_admin", "true");
                            break;
                          }
                          case GovernmentAccountSubtype.AGENCY_ADMIN:{
                            sessionStorage.setItem("agency_admin", "true");
                            break;
                          }
                        }
                        // if (item.credentials.account_subtype.includes("admin")) {
                        //   sessionStorage.setItem("arta_admin", "true");
                        // }
                      }
                      else {
                        sessionStorage.setItem("user_type", "agency");
                        const agency_data = await this.afs.doc(`Agency/${item.government?.agency}`).get().toPromise();
                        let agency_data_data: any = agency_data.data();

                        sessionStorage.setItem("agency_name", agency_data_data?.name);
                        sessionStorage.setItem("agency_id", item.government?.agency);
                        sessionStorage.setItem('account_role',item.credentials.role? item.credentials.role : 'Basic')

                        switch(item.credentials.account_subtype){
                          case GovernmentAccountSubtype.AGENCY_ADMIN:{
                            sessionStorage.setItem("agency_admin", "true");
                            break;
                          }
                        }
                      }

                      this.redirectLogin('Government');
                      break;
                    case "company":
                      sessionStorage.setItem("user_type", "agency");

                      this.redirectLogin();
                      break;
                    case "personal":
                      sessionStorage.setItem("user_type", "personal");

                      if (!cred.user?.emailVerified) {
                        this.loading = false;
                        this.modalRef = this.modalService.show(ConfirmSendVerificationComponent,
                          {
                            backdrop: 'static',
                            data: {
                              header_content: {
                                logo: "/assets/arta-logo.png"
                              },
                              body_content: {
                                message: `This user's email is not verified. 
                              If you have not received a verfication message, 
                              or your previous message has expired, you can request for a new message.`
                              },
                              buttons: {
                                confirm: "Yes, please send me a new message.",
                                cancel: "No thanks."
                              },
                              user_obj: cred?.user,
                              redirect_url: environment.verificationRedirectUrls.artemis
                            }
                          });
                      }
                      else {
                        this.redirectLogin();
                      }
                      break;
                  }
                }
              ),
              take(1)
            )
            .subscribe();
        }
      }).catch(error => {
        this.loading = false;
        switch(error.code){
          case 'auth/user-not-found':
          case 'auth/invalid-email':
            this.callMissingUserModal();
            break;
          default:
            alert(error);
            break; 
        }
      })

  }

  loginAsGuest() {
    this.router.navigate(['/artemis']);
  }

  loginAsAdmin() {  // for accessing the accounts without using an account 
    this.auth.signInAnonymously().then(() => {
      sessionStorage.setItem("user_type", "arta");
      sessionStorage.setItem("arta_admin", "true");
      this.router.navigate(['/artemis']);
    });
  }

  redirectLogin(account_type?:string) {
    if(account_type == 'Government'){
      
      this.router.navigate(['/artemis/charter'],{
        queryParams: {notificationsView: true}
      }).then(() => {
        this.snackBarService.openPlainNotif("Login successful!","Close");
        this.loading = false;
      });
    }else{
      
      this.router.navigate(['/artemis']).then(() => {
        this.snackBarService.openPlainNotif("Login successful!","Close");
        this.loading = false;
      });
    }
  }
}
