<div>
    
    
    <div [formGroup]="searchForm" class="form-group d-flex flex-row align-items-center" style="margin:1em 0;">
        <label for="search" style="margin-right:1em;">Search:</label>
        <input formControlName="keyword" id="search" name="search" (keyup)="search($event)" class="form-control"/>
        <button class="btn btn-outline-dark" style="margin-left:1em;" (click)="refreshList()" title="refresh"><i class="fas fa-redo"></i></button>
    </div>
        
    <mat-paginator pageSize="15" aria-label="Select page of users"></mat-paginator>
    <table mat-table class="table table-hover table-responsive"
        id="matTable"
        [dataSource]="agencyServicesDataSource">
        
        <!-- matSort
        (matSortChange)="sortChange($event)" -->

        <ng-container matColumnDef="service">
            <th mat-header-cell *matHeaderCellDef style="padding:1em"><h4>Services</h4></th>
            <td mat-cell *matCellDef="let serviceData" style="padding:2em">
                <app-artemis-service-view [s_data] = "serviceData" [agency]="doc_id">
                </app-artemis-service-view>
              <!--  <div style="font-weight:bold">
                    <h5>
                        <i class="mx-2" *ngIf="serviceData.serviceDetails.service_name">
                            <strong class="fw-bold">{{serviceData.serviceDetails.service_name}}</strong>
                        </i>
                        <a [routerLink]="" (click)="goToService(serviceData.id)" title="Go to {{serviceData.serviceDetails.service_name}} service"><i class="fas fa-chevron-circle-right"></i></a>
                    </h5>
                </div>
                <div>
                    <p>
                        <i class="mx-2" *ngIf="serviceData.serviceDetails.service_category">
                            <strong class="fw-bold">Category</strong>
                            {{ serviceData.serviceDetails.service_category }}
                        </i>
                    </p>
                    <p>
                        <i class="mx-2" *ngIf="serviceData.posted_status">
                            <strong class="fw-bold">Status</strong>
                            {{ serviceData.posted_status }}
                        </i>
                    </p>
                    <p>
                        <i class="mx-2" *ngIf="serviceData.serviceDetails.service_description">
                            <strong class="fw-bold"> Description</strong>
                            <p [innerHtml]="serviceData.serviceDetails.service_description"></p>
                        </i>
                    </p>
                </div>-->
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="services_columns;"></tr>
        <tr mat-row *matRowDef="let row; columns: services_columns;"></tr>
    </table>
    <mat-paginator pageSize="15" aria-label="Select page of users"></mat-paginator>
</div>