<div class="d-flex justify-content-center align-self-center">
    <div class="spinner-grow" role="status" *ngIf="!isLoaded">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<div class="content" *ngIf="isLoaded">
    <div class="container-fluid">
        <div class="card shadow p-3">
            <div class="card-body form-container">
                <div class="card-header border-bottom border-4 border-dark">
                    <h3>Uploaded Documents</h3>                           
                </div>

                <div class="row">
                    <div class="col-3">
                        <table class="table table-responsive table-hover">
                            <thead>
                                <tr>
                                    <th class="fw-bold"><h5>Version</h5></th>
                                </tr>
                            </thead>
                            
                            <tbody>
                                <tr *ngIf="uploadDetailsData && uploadDetailsData.dris" (click)="chooseVersion(uploadDetailsData.dris.controlCode)">
                                    <th>DRIS</th>
                                </tr>
                                <tr *ngIf="uploadDetailsData && uploadDetailsData.cris" (click)="chooseVersion(uploadDetailsData.cris.controlCode)">
                                    <th>CRIS</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-9">
                        <div id="nav-tabs">
                            <div style="font-weight: bold; border-bottom: .4px solid black; padding:1em; margin-bottom: 1em;">
                                <h2>
                                    <!-- Version:  -->
                                    <span> {{version ? version.toUpperCase() : "N/A"}}</span>
                                </h2>
                            </div>
                            <!-- <ul class="nav nav-tabs" id="navs-heads" role="tablist" style="border-bottom: 2px solid #eceef1;">
                                <ng-container *ngFor="let uploadData of chosenUploads; let i = index; let c = count" >
                                    <li *ngIf="uploadData.downloadUrl" class="nav-item" role="presentation">
                                        <a class="nav-link" [ngClass]="i==0?'active':''" [id]="uploadData.key" data-mdb-toggle="tab" href="#{{uploadData.key}}-tab"
                                            role="tab" aria-controls="{{uploadData.key}}-tab" aria-selected="{{i==0?true:false}}">
                                            {{uploadData.name}}
                                        </a>
                                    </li>
                                </ng-container>
                            </ul> -->

                            <!-- <div class="tab-content" id="content">
                                <div *ngFor="let uploadData2 of chosenUploads; let i = index; let c = count"
                                    class="tab-pane fade" [ngClass]="i==0?'active show':''" id="{{uploadData2.key}}-tab" role="tabpanel" aria-labelledby="{{uploadData2.key}}">
                                    <ng-container *ngIf="uploadData2.downloadUrl; else noDownLoadUrl">
                                        <ng-container *ngIf="((checkAuthLetterFileType('image/jpeg',uploadData2.contentType) || checkAuthLetterFileType('image/jpg',uploadData2.contentType) || checkAuthLetterFileType('image/png',uploadData2.contentType)))">
                                            <img style="width: 600px;" [src]="uploadData2.downloadUrl">
                                        </ng-container>
                                        <ng-container *ngIf="(checkAuthLetterFileType('application/pdf',uploadData2.contentType))">
                                            <div class="d-flex justify-content-center flex-column relative button-hover w-100">
                                                <div class="d-flex justify-content-center">
                                                    <b><h4>{{uploadData2.name}}</h4></b>
                                                </div>
                                                <div class="d-flex align-items-center flex-column">
                                                    <div class="d-flex justify-content-center"><p>{{uploadData2.fileBasePage}} / {{uploadData2.fileBaseTotalPage ? uploadData2.fileBaseTotalPage : "1"}}</p></div>
                                                    <pdf-viewer style="max-width: 600px;"
                                                        (after-load-complete)="afterLoadComplete($event,i)"
                                                        [page]="uploadData2.fileBasePage"
                                                        [show-all]="false" 
                                                        [original-size]=false 
                                                        [src]="uploadData2.downloadUrl">
                                                    </pdf-viewer>
                                                    <div class="d-flex justify-content-center"><p>{{uploadData2.fileBasePage}} / {{uploadData2.fileBaseTotalPage ? uploadData2.fileBaseTotalPage : "1"}}</p></div>
                                                </div>
                                                <button (click)="pageShift('back',i)" type="button" class="absolute left-button btn-dark">
                                                    <
                                                </button>
                                                <button (click)="pageShift('next',i)" type="button" class="absolute right-button btn-dark">
                                                    >
                                                </button>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                    <ng-template #noDownLoadUrl>
                                        <div class="card d-flex justify-content-center" style="padding:1em;" >
                                            <div class="row" style="padding:0.5em;">
                                                <label class="form-input-header fw-bold" for="feedback_remark">
                                                    <h3><b>No {{uploadData2.name}} Document</b></h3>
                                                </label>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div> -->
                        
                            
                            <ul>
                                <ng-container *ngFor="let uploadData of chosenUploads; let i = index; let c = count">
                                    <li>
                                        <div class="row">
                                            <div class="col-4 fw-bold">
                                                {{uploadData.name}} :
                                            </div>
                                            <div class="col">
                                                {{uploadData.fileName ? uploadData.fileName : '- Unavailable -'}} 
                                                <ng-container *ngIf="uploadData.downloadUrl" title="click to download file">
                                                    <a [routerLink]="" (click)="downloadFile(uploadData.downloadUrl)"><i class="fas fa-file-download"></i></a>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <hr *ngIf="i<(c-1)">
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="content">
    <div class="container-fluid">
        <div class="card shadow p-3">
            <div class="card-body form-container">
                <div class="card-header border-bottom border-4 border-dark">
                    <h3>Uploaded documents</h3>
                </div>
                <div class="row" style="margin-top: 2em; width: 100%;">
                    tab navs
                    <div id="nav-tabs">
                        <ul class="nav nav-tabs" id="navs-heads" role="tablist" style="border-bottom: 2px solid #eceef1;">
                            <ng-container *ngFor="let uploadData of objectUploads; let i = index; let c = count" >
                                <li *ngIf="uploadData.downloadUrl" class="nav-item" role="presentation">
                                    <a class="nav-link" [ngClass]="i==0?'active':''" [id]="uploadData.key" data-mdb-toggle="tab" href="#{{uploadData.key}}-tab"
                                        role="tab" aria-controls="{{uploadData.key}}-tab" aria-selected="{{i==0?true:false}}">
                                        {{uploadData.name}}
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                    !tab navs
                    
                    tab content
                    <div class="tab-content" id="content">
                        <div *ngFor="let uploadData2 of objectUploads; let i = index; let c = count"
                            class="tab-pane fade" [ngClass]="i==0?'active show':''" id="{{uploadData2.key}}-tab" role="tabpanel" aria-labelledby="{{uploadData2.key}}">
                            <ng-container *ngIf="uploadData2.downloadUrl">
                                <ng-container *ngIf="((checkAuthLetterFileType('image/jpeg',uploadData2.contentType) || checkAuthLetterFileType('image/jpg',uploadData2.contentType) || checkAuthLetterFileType('image/png',uploadData2.contentType)))">
                                    <img style="width: 600px;" [src]="uploadData2.downloadUrl">
                                </ng-container>
                                <ng-container *ngIf="(checkAuthLetterFileType('application/pdf',uploadData2.contentType))">
                                    <div class="d-flex justify-content-center flex-column relative button-hover w-100">
                                        <div class="d-flex justify-content-center">
                                            <b><h4>{{uploadData2.name}}</h4></b>
                                        </div>
                                        <div class="d-flex align-items-center flex-column">
                                            <div class="d-flex justify-content-center"><p>{{uploadData2.fileBasePage}} / {{uploadData2.fileBaseTotalPage ? uploadData2.fileBaseTotalPage : "1"}}</p></div>
                                            <pdf-viewer style="max-width: 600px;"
                                                (after-load-complete)="afterLoadComplete($event,i)"
                                                [page]="uploadData2.fileBasePage"
                                                [show-all]="false" 
                                                [original-size]=false 
                                                [src]="uploadData2.downloadUrl">
                                            </pdf-viewer>
                                            <div class="d-flex justify-content-center"><p>{{uploadData2.fileBasePage}} / {{uploadData2.fileBaseTotalPage ? uploadData2.fileBaseTotalPage : "1"}}</p></div>
                                        </div>
                                        <button (click)="pageShift('back',i)" type="button" class="absolute left-button btn-dark">
                                            <
                                        </button>
                                        <button (click)="pageShift('next',i)" type="button" class="absolute right-button btn-dark">
                                            >
                                        </button>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                    !tab content
                </div>
            </div>
        </div>
    </div>
</div> -->