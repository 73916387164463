


<div id="situation-container"
(mouseover)="hideButtons=true"
(mouseleave)="hideButtons=false">

<div style="background-color: #44B8ED; padding: 5px;">
  <input id="title"  class="form-control" [formControl]="title" type="text" [(ngModel)]="situation.title">
  <small class="text-danger" *ngIf="!title.value">Title is required*</small>
  <h6 class="card-title p-2">{{requirementService.printToLetter(situationIndex + 1)}}.{{situation.title}}</h6>
</div>
  <h6 class="card-subtitle" *ngIf="situation.chooseany" ><small>Any of the following</small></h6>
  <div *ngFor="let requirement of situation.requirement; let index = index">
     <app-requirement-control 
     *ngIf="!requirement.chooseany"
     [requirement]="requirement"
     [referenceArray]="situation.requirement"
     [SituationIndex]="situationIndex"
     [requirementIndex]="index"
     [chooseany]="situation.chooseany"
     [isSituational]="true"
     [req_type]="enum_requirement_type.STANDARD_WITH_SUB_REQUIREMENT"
     ></app-requirement-control>

     <app-subsituational-requirement-control
     *ngIf="requirement.chooseany"
     [situation]="requirement"
     [situationIndex]="index"
     [referenceArray]="situation.requirement"
     [req_type]="enum_requirement_type.STNDARD_WITH_TITLE"
     
     ></app-subsituational-requirement-control>
 </div>

 <div *ngFor="let requirement of situation.subRequirement;let index = index">

     <app-subsituational-requirement-control   
     [situation]="requirement"
     [situationIndex]="index"
     [referenceArray]="situation.subRequirement"
     [indexName]="requirementService.printToLetter(situationIndex + 1)"
     [req_type]="enum_requirement_type.SUB_SITUATIONAL_REQUIREMENT" 
     ></app-subsituational-requirement-control>
   </div>
   <div  class="d-inline-flex mt-2 bg-light p-1 w-100" >
    <button mat-button matTooltip="Add Sub Situational Requirement" (click)="addSituational()" ><span><i class="fas fa-plus-circle"></i> </span> Sub Situational Requirement</button>
    <button mat-stroked-button  matTooltip="Paste" *ngIf="requirementService.checkClipboard('requirement_copy')" (click)="pasteRequirement()"><span><i class="fas fa-paste"></i> Paste Requirement</span></button>
    <button mat-stroked-button  matTooltip="Paste" *ngIf="requirementService.checkClipboard('situation_copy')" (click)="pasteSituation()"><span><i class="fas fa-paste"></i> Paste Situation</span></button>
    <button mat-button matTooltip="Edit" (click)="updateSituationalRequirement()" ><span><i class="fas fa-plus-circle"></i> </span> Add requirement</button> 
    <button mat-button matTooltip="Clone" (click)="copySituation()"><span><i class="fas fa-clone"></i></span> Clone</button>
    <button mat-button matTooltip="Remove" color="warn" (click)="removeRequirement()" ><span><i class="fas fa-trash"> </i>Remove</span></button>

   </div>
    
</div>
