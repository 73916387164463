<mat-card class="mt-3">
  <mat-card-body>
    <div class="col my-3">
      <div class="row">
      <div class="col-10">
        <h2 class="text-primary fw-bold m-1">Service Results <span *ngIf="!loading">   {{ servicesList.length }}</span></h2>
      </div>

      <div class="col-2 ">
        <button mat-button (click)="closeComponent()">Close </button>
      </div>

      </div>
      <p class="border border-primary w-100 m-3"></p>
  
      <div class="col my-3">
        <div class="d-flex justify-content-center align-self-center">
          <div class="spinner-grow " role="status" *ngIf="loading">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        
        <table
          datatable
          [dtOptions]="dtOptions"
          [dtTrigger]="dtTrigger"
          class="table table-hover table-striped table-responsive"
          [hidden]="!servicesList"

        >
          <thead hidden>
            <tr>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <!-- Table Item -->
            <!-- <tr
              *ngFor="let entry of services"
              (click)="viewCitizensCharter(entry.id)"
              class="charter-row"
            > -->
            <tr
              *ngFor="let entry of servicesList"
                
              class="charter-row "
            >
              <td class="w-25">
                <div class="d-flex justify-content-center">
                  <img
                    [src]="logo_url"
                    class="img-fluid m-0 align-self-center"
                    style="width: 110px; height: 110px; object-fit: cover;"
                  >
                </div>
              </td>
              <td>
                <h5  class="pointer"   (click)="viewService(entry.id)"><i class="mx-2" *ngIf="entry.serviceDetails.service_name"
                    ><strong class="fw-bold"> Name of Service:</strong>
                      {{entry.serviceDetails.service_name }}</i
                  ></h5>
                  <i class="mx-2 mb-3" *ngIf="entry.serviceDetails.service_category"
                    ><strong class="fw-bold">Category</strong>
                    {{ entry.serviceDetails.service_category }}</i
                  >
                <p>
                  <i class="mx-2" *ngIf="entry.serviceDetails.service_description"
                    ><strong class="fw-bold"> Description</strong>
                    <p >{{entry.ischecked? entry.serviceDetails.service_description :  sliceDescription(entry.serviceDetails.service_description)}} <span *ngIf="!entry.ischecked && entry.serviceDetails.service_description.trim().length > 40"><u class="pointer" (click)="seeMore(entry)">see more</u></span> </p></i
                  >
                </p>
                <i class="mx-2" *ngIf="entry.agency_name"
                    ><strong class="fw-bold">Agency:</strong>
                    {{ entry.agency_name }}</i
                  >

              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </mat-card-body>
</mat-card>