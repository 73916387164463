<!--NAVBAR-->
<app-artemis-navigation-header [currentPage]="'Charter'"></app-artemis-navigation-header>

<div class="content">
    <div class="row" *ngIf="userLoading == true || (user); else showRestricted">
        <!-- <div> -->
        <mat-sidenav-container class="full-heigth" autosize="true">
            <mat-sidenav mode="side" opened>

                <div mat-menu-item>
                    <div class="pad with-icon" (click)="toggleSideNav()" style="cursor: pointer;">
                        <mat-icon>menu</mat-icon>
                        <span class="ml" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Citizens Charter Management</p></span>
                    </div>
                </div>

                <!-- <div class="pad with-icon" *ngIf="!collapse">
                    {{user.displayName}}
                    <br>
                    {{agencyName}}
                </div> -->

               <div class="with-icon" *ngIf="!collapse">
                    <ng-container *ngIf="userLoading == true; else finishedLoading">
                        <div class="spinner-border text-danger" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </ng-container>
                    <ng-template #finishedLoading>
                        <div class="d-flex flex-column" ></div>
                            <div class="p-3 text-warning  text-center" style="background-color:rgb(34, 33, 33);">
                                <p class="wrapper">{{userFullname}}</p>
                                <p class="wrapper" title="{{user.agency.agencyDetails.agencyName ? user.agency.agencyDetails.agencyName : user.agency.name ? user.agency.name : ''}}">{{user.agency.agencyDetails.agencyName ? user.agency.agencyDetails.agencyName : user.agency.name ? user.agency.name : ''}}</p>
                                <p class="wrapper" title="{{userSubtype ? userSubtype : userType }}">{{userSubtype ? userSubtype : userType }}</p>
                            </div>
                           <!--
                             <p class="wrapper" title="{{user.auth.displayName}}">{{user.auth.displayName}}</p> 
                             <br>Department of Agency 
                               <p class="wrapper" title="{{agencyName}}">{{agencyName}}</p> 
                           -->
                           
                          
                       
                    </ng-template>
                </div>

               <!--
                 
                <div *ngIf="collapse">
                    <ng-container *ngIf="userLoading == true; else finishedLoading">
                        <div class="spinner-border text-danger" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </ng-container>
                    <ng-template #finishedLoading>
                        <div style="margin-top: 0.5em;" class="d-flex flex-column align-items-center">
                            <span class="user-icon" style="margin-bottom:0.5em" title="{{getAcronym(user.auth.displayName)}}">{{getAcronym(user.auth.displayName)}}</span>
                            <span class="user-icon" style="margin-bottom:0.5em"><img style="width: 50px;height: 50px;" src="{{user.agency.agencyDetails.logoURL ? user.agency.agencyDetails.logoURL : '/assets/default_upload_logo.png'}}"/></span>
                        </div>
                    </ng-template>
                </div>
               -->

                <!--
                    <div mat-menu-item [ngClass]="notificationsView? 'active':''" (click)="openNotificationsView()" style="cursor: pointer;">
                    <div class="pad with-icon">
                        <mat-icon>notifications</mat-icon>
                        <span class="ml" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Inbox (Notifications)</p></span>
                    </div>
                </div>

               
                -->
                
<!--
                    <div mat-menu-item style="cursor: pointer;">
                    <div class="pad with-icon">
                        <mat-icon>dashboard</mat-icon>
                        <span class="ml" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Dashboard</p></span>
                    </div>
                </div>
-->
                
                <div mat-menu-item *ngIf ="isAdmin" style="cursor: pointer;" [ngClass]="accountverificationView? 'active':''" (click)="openaccountverificationView()">
                    <div class="pad with-icon">
                        <mat-icon>admin_panel_settings</mat-icon>
                        <span class="ml" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Account Management</p></span>
                    </div>
                </div>

                <div mat-menu-item *ngIf ="isAdmin" style="cursor: pointer;" [ngClass]="systemsettingsView? 'active':''" (click)="opensystemsettingsView()">
                    <div class="pad with-icon">
                        <mat-icon>settings</mat-icon>
                        <span class="ml" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Reference Control</p></span>
                    </div>
                </div>


                
                <!-- Removed already included in the Reference control view
                    <div mat-menu-item *ngIf ="isAdmin" style="cursor: pointer;" [ngClass]="AgencyControlView? 'active':''" (click)="openAgencyControlView()">
                    <div class="pad with-icon">
                        <mat-icon>building</mat-icon>
                        <span class="ml" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Agency Management</p></span>
                    </div>
                </div>
                -->
                <div mat-menu-item *ngIf ="isAdmin" style="cursor: pointer;"  [ngClass]="accountprofileView? 'active':''" (click)="openaccountprofileView()">
                    <div class="pad with-icon">
                        <mat-icon>manage_accounts</mat-icon>
                        <span class="ml" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Account Profile</p></span>
                    </div>
                </div>



                <div mat-menu-item *ngIf="!isAdmin && !isAgencyAdmin" [ngClass]="inboxView? 'active':''" (click)="openInboxView()" style="cursor: pointer;">
                    <div class="pad with-icon">
                        <mat-icon>assignment</mat-icon>
                        
                        <span class="ml" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">{{ inboxMode == 'arta' ? 'Submissions' : 'Worklist Management' }}</p></span>
                    </div>
                </div>

                <div mat-menu-item *ngIf="isAgencyAdmin" [ngClass]="inboxView? 'active':''" [routerLink]="account_management_link" style="cursor: pointer;">
                    <div class="pad with-icon">
                        <mat-icon>manage_accounts</mat-icon>
                        
                        <span class="ml" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Account Management</p></span>
                    </div>
                </div>

                <div mat-menu-item *ngIf ="isAgency" [ngClass]="serviceManagementView? 'active':''" (click)="openServiceManagementView()" style="cursor: pointer;">
                    <div class="pad with-icon">
                        <mat-icon>miscellaneous_services</mat-icon>
                        <span class="ml" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Service Management</p></span>
                    </div>
                </div>

                <div *ngIf="!isAgency && !isAgencyAdmin" mat-menu-item [ngClass]="charterListView? 'active':''" (click)="openCharterListView()" style="cursor: pointer;">
                    <div class="pad with-icon">
                        <mat-icon>list</mat-icon>
                        <span class="ml" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Submitted Citizen's Charter</p></span>
                    </div>
                </div>
                
                <div *ngIf="!isAgency && !isAgencyAdmin" mat-menu-item [ngClass]="cocmasterlistview? 'active':''" (click)="opencocmasterlistView()" style="cursor: pointer;">
                    <div class="pad with-icon">
                        <mat-icon>list</mat-icon>
                        <span class="ml" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Submitted COC</p></span>
                    </div>
                </div>

                
                <div *ngIf="!isAgency && !isAgencyAdmin" mat-menu-item (click)="goToReports()" style="cursor: pointer;">
                    <div class="pad with-icon">
                        <mat-icon>insert_chart</mat-icon>
                        <span class="ml" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Reports</p></span>
                    </div>
                </div>

                
                <div *ngIf="isAdmin" mat-menu-item [ngClass]="feedbackIndex? 'active':''" (click)="openFeedbackInbox()" style="cursor: pointer;">
                    <div class="pad with-icon">
                        <mat-icon>feedback</mat-icon>
                        <span class="ml" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">User Feedback</p></span>
                    </div>
                </div>

                <div *ngIf="isAgency || isAgencyAdmin" mat-menu-item [ngClass]="profile? 'active':''" (click)="openagencypageView()"  style="cursor: pointer;">
                    <div class="pad with-icon">
                        <mat-icon>account_balance</mat-icon>
                        <span class="ml" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Agency Profile</p></span>
                    </div>
                </div>

                <div  *ngIf="isAgency" mat-menu-item  (click)="goToCCReport()" style="cursor: pointer;">
                    <div class="pad with-icon">
                        <mat-icon>insert_drive_file</mat-icon>
                        <span class="ml" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Agency Citizen's Charter</p></span>
                    </div>
                </div>
                
                <div *ngIf="isAgency" mat-menu-item [ngClass]="comment? 'active':''" (click)="openCommentView()" style="cursor: pointer;">
                    <div class="pad with-icon">
                        <mat-icon>comment</mat-icon>
                        <span class="ml" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Comments</p></span>
                    </div>
                </div>

                <div mat-menu-item  routerLink="/artemis/charter/feedback/" style="cursor: pointer;">
                    <div class="pad with-icon">
                        <mat-icon>send</mat-icon>
                        <span class="ml" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Send Feedback</p></span>
                    </div>
                </div>

                <!--<div mat-accordion style="padding:0;" class="accordion">
                    <mat-expansion-panel expanded='true' hideToggle >
                        <mat-expansion-panel-header style="padding:0;margin: 0;" (click)="toggleExpander()">
                            <mat-panel-title style="margin:0;">
                                <div class="pad with-icon" style="padding-right: 0;">
                                    <mat-icon>inbox</mat-icon>
                                </div>
                            </mat-panel-title>
                            <mat-panel-description class="with-icon" style="margin:0">
                                <span *ngIf="!collapse" > <p class="wrapper items-center" style="margin:0; padding:0">Worklist  <mat-icon>{{inboxExpander  ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon></p></span>
                                <span *ngIf="collapse"> <mat-icon>{{inboxExpander  ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon> </span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                    </mat-expansion-panel>
                </div>-->
                
               

            </mat-sidenav>

            <mat-sidenav-content class="padded">
                <!--Views-->
                <div class="charter-view-card col">

                    <app-artemis-view-organization *ngIf="profile"></app-artemis-view-organization>
                    <app-artemis-charter-inbox-page *ngIf="inboxView && inboxMode == 'agency' "></app-artemis-charter-inbox-page>
                    <app-artemis-charter-inbox-page-agency-head *ngIf="inboxView && inboxMode == 'agency-head' "></app-artemis-charter-inbox-page-agency-head>
                    <app-artemis-charter-inbox-page-arta [activateChild]="activateChild" [activateObject]="activateObject" *ngIf="inboxView && inboxMode == 'arta' "></app-artemis-charter-inbox-page-arta>
                    <app-artemis-notifications *ngIf="notificationsView"></app-artemis-notifications>
                    <app-artemis-download-charter-page *ngIf="charterListView"></app-artemis-download-charter-page>
                    <app-artemis-inbox-user-feedback *ngIf="feedbackIndex"></app-artemis-inbox-user-feedback>
                    <app-artemis-comment-view *ngIf="comment"></app-artemis-comment-view>
                    <app-artemis-service-management-page *ngIf="serviceManagementView"></app-artemis-service-management-page>
                    <!--Agency creation are moved to reference control under Artemis TAB
                        <app-agency-control *ngIf="AgencyControlView"></app-agency-control>-->
                    <app-reference-control *ngIf="systemsettingsView"></app-reference-control>
                    <app-crud-users *ngIf="accountverificationView"></app-crud-users>
                    <app-pbris-account *ngIf="accountprofileView"></app-pbris-account>
                    <app-artemis-coc-masterlist *ngIf="cocmasterlistview"></app-artemis-coc-masterlist>
                    <app-agency-members-control *ngIf="accountManagementView"></app-agency-members-control>
                
                </div>
            </mat-sidenav-content>
        </mat-sidenav-container>
        <!-- </div> -->
    </div>

    <ng-template #showRestricted>
        <h1>This is a Restricted Page</h1>
    </ng-template>
</div>

<!-- <ng-template #showRestricted>
    <h1>This is a Restricted Page</h1>
</ng-template> -->