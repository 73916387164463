<app-wel-navbar></app-wel-navbar>

<div class="content">
    <div class="container-fluid p-3">
        <div class="card">
            <div class="card-head m-3">
                <button class="btn btn-danger" (click)="delete()"> Delete</button>
                <h3>Agency Information</h3>
            </div>
            <div class="card-body form-container">
                <form [formGroup]="form">
                    <div class="form-group fw-bold">
                        <label class="form-input-header">Agency Abbreviation</label>
                        <input type="text" class="form-control" formControlName="abbreviation" />
                    </div>
                    <div class="form-group fw-bold">
                        <label class="form-input-header">Agency Name</label>
                        <input type="text" class="form-control" formControlName="name" />
                    </div>
                    <div class="form-group fw-bold">
                        <label class="form-input-header">Contact Number</label>
                        <input type="text" class="form-control" formControlName="contact" />
                    </div>
                    <div class="form-group fw-bold">
                        <label class="form-input-header">Email</label>
                        <input type="email" class="form-control" formControlName="email" />
                    </div>
                    <div class="form-group fw-bold">
                        <label class="form-input-header">Mandate</label>
                        <textarea type="text" class="form-control" formControlName="mandate" appTextareaAutoresize></textarea>
                    </div>
                    <div class="form-group fw-bold">
                        <label class="form-input-header">Mission</label>
                        <textarea type="text" class="form-control" formControlName="mission" appTextareaAutoresize></textarea>
                    </div>
                    <div class="form-group fw-bold">
                        <label class="form-input-header">Vision</label>
                        <textarea type="text" class="form-control" formControlName="vision" appTextareaAutoresize></textarea>
                    </div>
                    <div class="form-group fw-bold">
                        <label class="form-input-header">Service Pledge</label>
                        <textarea type="text" class="form-control" formControlName="service_pledge" appTextareaAutoresize></textarea>
                    </div>
                    <a class="btn btn-link text-dark m-3" routerLink="/agency">Cancel</a>
                    <div class="float-end m-3">
                        <button class="btn btn-secondary" (click)="update()" [hidden]="update_flag"> Update</button>
                        <button class="btn btn-primary" (click)="save()" id="bt " *ngIf="update_flag" [disabled]="form.invalid || loading">
                        
                <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span> Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

</div>