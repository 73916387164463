<div class="content">
    <div class="container-fluid">
        <div class="card shadow p-3">
            <div class="card-body form-container">
                <div class="card-header border-bottom border-4 border-dark">
                    <h3>Regulatory Notification</h3>                           
                </div>
                <div style="padding: 1em;">
                    <div class="form-group ">
                        <label class="form-input-header fw-bold" for="feedback_remark"
                            ><b>Problem being addressed by the Regulation :</b></label>
                        <p class="desc">{{currentProposedRegulation.reg_notif_form.reg_problem}}</p>
                    </div>

                    <div class="form-group ">
                        <label class="form-input-header fw-bold">Classification :</label>
                        <div class="p-3 d-flex justify-content-evenly">
                                <p><mat-icon>{{currentProposedRegulation.reg_notif_form.reg_classification === "Business" ? "check_circle_outline" : "cancel"}}</mat-icon> Business</p>
                                <p><mat-icon>{{currentProposedRegulation.reg_notif_form.reg_classification === "Non-Business" ? "check_circle_outline" : "cancel"}}</mat-icon> Non-Business</p>
                        </div>
                    </div>

                    <div *ngIf="currentProposedRegulation.reg_notif_form.reg_classification == 'Business'">
                        <div class="form-inline minispace">
                            <div class="row">
                                <div class="col-3 fw-bold"><label class="form-input-header fw-bold" for="subject">Sector</label></div>
                                <div class="col-1 fw-bold">:</div>
                                <div class="col">
                                    <p>{{currentProposedRegulation.reg_notif_form.reg_sector_doc.name}}</p>
                                </div>
                                <!-- <div><p class="desc">{{currentProposedRegulation.reg_notif_form.reg_sector_doc.definition}}</p></div> -->
                            </div>
                        </div>

                        <div class="form-inline minispace">
                            <div class="row">
                                <div class="col-3 fw-bold"><label class="form-input-header fw-bold" for="subject">Stage of Business</label></div>
                                <div class="col-1 fw-bold">:</div>
                                <div class="col">
                                    <p>{{currentProposedRegulation.reg_notif_form.reg_business_doc.name}}</p>
                                </div>
                                <!-- <div><p class="desc">{{currentProposedRegulation.reg_notif_form.reg_business_doc.definition}}</p></div> -->
                            </div>
                        </div>
                    </div>

                    <div *ngIf="currentProposedRegulation.reg_notif_form.reg_classification == 'Non-Business'">
                        <div class="form-inline minispace">
                            <div class="row">
                                <div class="col-3 fw-bold"><label class="form-input-header fw-bold" for="subject">Stage of Life</label></div>
                                <div class="col-1 fw-bold">:</div>
                                <div class="col">
                                    <p>{{currentProposedRegulation.reg_notif_form.reg_stageoflife.name}}</p>
                                </div>
                                <!-- <div><p class="desc">{{currentProposedRegulation.reg_notif_form.reg_stageoflife.definition}}</p></div> -->
                            </div>
                        </div>

                        <div class="form-inline minispace">
                            <div class="row">
                                <div class="col-3 fw-bold"><label class="form-input-header fw-bold" for="subject">Life Event</label></div>
                                <div class="col-1 fw-bold">:</div>
                                <div class="col">
                                    <p>{{currentProposedRegulation.reg_notif_form.reg_lifeevent}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-inline">
                        <div class="row">
                            <div class="col-3 fw-bold"><label class="form-input-header fw-bold" for="subject">Division</label></div>
                            <div class="col-1 fw-bold">:</div>
                            <div class="col">
                                <p>{{currentProposedRegulation.reg_notif_form.reg_division_doc.name}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="form-inline">
                        <div class="row">
                            <div class="col-3 fw-bold"><label class="form-input-header fw-bold" for="subject">Jurisdiction</label></div>
                            <div class="col-1 fw-bold">:</div>
                            <div class="col">
                                <p>{{currentProposedRegulation.reg_notif_form.reg_juris_doc.name}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="form-inline">
                        <div class="row">
                            <div class="col-3 fw-bold"><label class="form-input-header fw-bold" for="subject">Case Use</label></div>
                            <div class="col-1 fw-bold">:</div>
                            <div class="col">
                                <p>{{currentProposedRegulation.reg_notif_form.reg_case_doc.name}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="form-inline">
                        <table class="table table-responsive table-hover">
                            <thead>
                                <tr >
                                    <th class="fw-bold">Activity</th>
                                    <th class="fw-bold">Start Date</th>
                                    <th class="fw-bold">End Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let time of currentProposedRegulation.reg_notif_form.pia_ria_timeline">
                                    <th>{{time.activity_title}}</th>
                                    <td>{{time.start_date}}</td>
                                    <td>{{time.end_date}}</td>
                                </tr>
                                <tr *ngIf="currentProposedRegulation.reg_notif_form.pia_ria_timeline.length == 0">
                                    <th>No Data</th>
                                    <td>. . .</td>
                                    <td>. . .</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="form-group ">
                        <label class="form-input-header fw-bold" for="feedback_remark"
                            ><b>Other Remarks :</b></label
                        >
                        <p class="desc">{{currentProposedRegulation.reg_notif_form.reg_remarks}}</p>
                    </div>

                    <div class="form-inline">
                        <div class="row">
                            <div class="col-3 fw-bold"><label class="form-input-header fw-bold" for="subject">Proposal Date</label></div>
                            <div class="col-1 fw-bold">:</div>
                            <div class="col">
                                <p>{{currentProposedRegulation.reg_notif_form.doc_date}}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>