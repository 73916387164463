<app-artemis-navigation-header
  [currentPage]="''"
></app-artemis-navigation-header>

<app-artemis-loading-animation *ngIf="loader" ></app-artemis-loading-animation>

<mat-toolbar class="navbar-sticky" *ngIf="!loader">
  <button
    (click)="goBack()"
    mat-icon-button
    class="example-icon"
    aria-label="Example icon-button with menu icon"
  >
    <i class="fas fa-chevron-circle-left fa-lg"></i>
  </button>
  <span class="example-spacer"></span>
  <!--
    <button
    (click)="updateService()"
    mat-icon-button
    class="example-icon favorite-icon"
    aria-label="Example icon-button with heart icon"
  >
    <i class="fas fa-save fa-lg"></i>
  </button>
  -->
  <div *ngIf="step == 1">
    <button [disabled]="no_req.value" class="mx-1" (click)="addRequirement(enum_req_type.STANDARD_NO_TITLE)" mat-button>
      <mat-icon>add</mat-icon> <span>Standard Requirement</span>
    </button>

   <!---
   <button class="mx-1" (click)="addRequirement(enum_req_type.STNDARD_WITH_TITLE)" mat-button>
      <mat-icon>add</mat-icon> <span>Standard Requirement with Title</span>
    </button>-->

    <button [disabled]="no_req.value" class="mx-1" (click)="addRequirement(enum_req_type.SITUATIONAL_REQUIREMENT)" mat-button>
      <mat-icon>add</mat-icon>
      <span>Situational Requirement</span>
    </button>
  </div>

  <div *ngIf="step == 2">
    <button mat-button (click)="addServiceStep()">
      <mat-icon>add</mat-icon> <span>New Client Step</span>
    </button>
  </div>

  <div class="align-center">
    {{service_name_and_details_form.value.service_name}}
  </div>
</mat-toolbar>
<div class="container-fluid bg-light">
  <div class="container py-2" [hidden]="loader">

    <mat-accordion class="example-headers-align">
      <!--Service information tab-->
      <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h5><span class="circle">1</span></h5> <h4 class="mx-2">Service Information</h4> <span class="text-danger" *ngIf="(serviceInformationRemarks + generalremarks) > 0"> ({{serviceInformationRemarks + generalremarks}} Unresolved Remarks)</span>
          </mat-panel-title>
          <mat-panel-description *ngIf="!service_name_and_details_form.valid  || listofOffices.length  <= 0 || checkifwithInterAgencyOfficeError() ">
           <span class="text-danger"> Incomplete or Invalid inputs</span>
          </mat-panel-description>
        </mat-expansion-panel-header>
    
        <div class="form-container">
          <form [formGroup]="service_name_and_details_form">
            <ng-template matStepLabel>Service Information <span class="text-danger" *ngIf="(serviceInformationRemarks + generalremarks) > 0"> ({{serviceInformationRemarks + generalremarks}} Unresolved Remarks)</span></ng-template>
          <section class="mt-2">
            <label for="">Service Information Remarks</label>
            <app-artemis-service-remarks-thread [openRemarks]="true" [remarks]=remarks_thread [service_id]=updateServiceId [component_id]="'Description'" [type]= "'description'"></app-artemis-service-remarks-thread>
            <label for="">General Remarks</label>
            <app-artemis-service-remarks-thread [openRemarks]="true" [remarks]=remarks_thread [service_id]=updateServiceId [component_id]="'General_Remarks'" [type]= "'general_remarks'" ></app-artemis-service-remarks-thread>
  
          </section>
          <div class="form-group row mt-2">
            <label class="form-input-header fw-bold" for="service_name "
            >Service Name
          </label>
            <mat-form-field class="mt-3" appearance="outline">
              <mat-label>Service Name</mat-label>
              <textarea
                matInput
                
                formControlName="service_name"
              ></textarea>
              <mat-error *ngIf="service_name_and_details_form.get('service_name')?.errors ">Serivce name is required!</mat-error>
            </mat-form-field>
          </div>
          <div class="form-group row">
            <label class="form-input-header" for="service_description "
              ><strong>Service Description*</strong>
            </label>
            <mat-form-field
              style="width: 100%"
              
              floatLabel="never"
            >
              <input matInput hidden />
              <editor
                formControlName="service_description"
                [init]="{
                  base_url: '/tinymce',
                  suffix: '.min',
                  height: 250,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
                                      alignleft aligncenter alignright alignjustify | \
                                      bullist numlist outdent indent | removeformat | help'
                }"
              ></editor>
              <mat-hint class="text-danger"
              *ngIf="
                service_name_and_details_form.get('service_description')
                  ?.invalid
              "
              >Serivce description is required!</mat-hint
            >
              <!--<mat-error *ngIf="service_name_and_details_form.get('service_description')?.errors ">Serivce description is required!</mat-error>-->
            </mat-form-field>
           
          </div>
  
          <div class="form-group row mt-2">
            <div class="col-sm-12">
              <div class="row">
                <label class="form-input-header fw-bold" for="process_owner "
            >Service Process Owner
          </label>
                <mat-form-field class="mt-3" appearance="outline">
                  <mat-label>Process Owner</mat-label>
                 <!--  <mat-select formControlName="process_owner" required>
 -->
                    <input
                    matInput
                    placeholder="Process Owner"
                    formControlName="process_owner"
                    [matAutocomplete]="autos"
                    [(ngModel)]="officefilter"
                 
                  />

                  <mat-autocomplete
                    #autos="matAutocomplete"
                    (optionSelected)="selected($event)"
                  >
                    <mat-option
                      *ngFor="let office of alloficesexcludingGroup | search : officefilter "
                      [value]="office"
                    >
                      {{  office }}
                    </mat-option>
                  </mat-autocomplete>
                    <!-- <input placeholder="Filter..." class="form-control w-100" type="text" (keyup)="onkey($event)">
                    <mat-option
                      *ngFor="let office of alloficesexcludingGroup"
                      [value]="office"
                      >{{ office }}</mat-option
                    >
                  </mat-select> -->
                  <mat-error *ngIf="service_name_and_details_form.get('process_owner')?.errors ">Process owner is required!</mat-error>
        
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="form-group row mt-2">
            <div class="col">
              <div class="row">
                <label class="form-input-header fw-bold" for="office_division "
            >Office / Division Included in the Service Process
          </label>
                <mat-form-field
                  class="example-chip-list mt-3"
                  appearance="outline"
                >
                  <mat-label
                    >Office/Division</mat-label
                  >
                  <mat-chip-list #chipList aria-label="office selection">
                    <mat-chip
                      *ngFor="let office of listofOffices"
                      [selectable]="selectable"
                      [removable]="removable"
                      (removed)="remove(office)"
                    >
                      {{ office?.name ? office.name : office }}
                      <mat-icon matChipRemove *ngIf="removable"
                        >cancel</mat-icon
                      >
                    </mat-chip>
                    <input
                      placeholder="office/division"
                      #officeInput
                      [formControl]="officeControl"
                      [matAutocomplete]="auto"
                      [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [(ngModel)]="keyword"
                   
                    />
                       <!---(matChipInputTokenEnd)="add($event)"-->
                  </mat-chip-list>
                  <mat-autocomplete
                    #auto="matAutocomplete"
                    (optionSelected)="selected($event)"
                  >
                  <mat-option>
                    <button (click)="createGroup()" class="btn btn-primary">Create Group</button>
                  </mat-option>
                    <mat-option
                      *ngFor="let office of AgencyOffices | search : keyword "
                      [value]="office"
                    >
                      {{  office?.name ? office.name : office  }}
                    </mat-option>
                  </mat-autocomplete>
  
                <!--  <mat-hint class="text-danger"
                  *ngIf="listofOffices && listofOffices.length <= 0 " >List of offices is required!</mat-hint
                >  -->
                  <!--<mat-error *ngIf="listofOffices.length == 0 ">Office/Division Included in the Service
                    Process is required!</mat-error>-->
                </mat-form-field>
                
              </div>
            </div>
           
          </div>
          <div class="row">
            <div class="col-sm">
              <button [matMenuTriggerFor]="clearOfficesMenu" mat-flat-button ><mat-icon>delete</mat-icon><span>clear all office/s</span></button>
              <mat-menu #clearOfficesMenu="matMenu">
                <div class="container">
                  <div class="row">
                    Are you sure?
                  </div>
                  <button  (click)="clearOffices()" mat-button color="warn">Yes, remove all</button>
                  <button  mat-button >No</button>
                </div>
              
              </mat-menu>
                </div>
          </div>
  
          <div class="form-group row mt-2">
            <label class="mt-1" for=""
              ><strong>Service Category</strong></label
            >
            <mat-radio-group
              formControlName="service_category"
              class="mx-3 mt-1 bg-light py-4"
              aria-label="Select an option"
            >
              <mat-radio-button color="primary" value="External Service"
                >External</mat-radio-button
              >
              <mat-radio-button color="primary" value="Internal Service"
                >Internal</mat-radio-button
              >
              <mat-error *ngIf="service_name_and_details_form.get('service_category')?.errors ">Service Category is required!</mat-error>
        
            </mat-radio-group>
             </div>
          <div class="form-group row mt-2">
            <label class="mt-1" for=""
              ><strong>Service Classification</strong></label
            >
            <mat-radio-group
              formControlName="classification"
              class="mt-1 mx-3 bg-light py-4"
              aria-label="Select an option"
            >
              <mat-radio-button color="primary" value="Simple"
                >Simple</mat-radio-button
              >
              <mat-radio-button color="primary" value="Complex"
                >Complex</mat-radio-button
              >
              <mat-radio-button color="primary" value="Highly Technical"
                >Highly Technical</mat-radio-button
              >
              <mat-error *ngIf="service_name_and_details_form.get('classification')?.errors ">Service Classification is required!</mat-error>
         
            </mat-radio-group>
            </div>
  
          <div class="form-group row">
            <section class="example-section">
              <div class="col">
                <div class="mt-3">
                  <div class="form-check form-check-inline">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="interagency_submission"
                      formControlName="interagency_submission"
                      [(ngModel)]="withInteragencySubmission"
                    />
                    <label class="form-check-label" for="interagency_submission"
                      ><strong>Inter-Agency Submission</strong></label
                    >
                  </div>
                </div>
              </div>
            </section>
          </div>
          
          <!--inter agency offices included in the service process-->
          <div *ngIf="withInteragencySubmission" class="form-group row mt-2">
            <div class="col-sm">
              <div class="row">
                <mat-form-field class="example-chip-list" appearance="outline">
                  <mat-label>Inter Agency Offices/Divisions</mat-label>
                  <mat-chip-list #officechip aria-label="Inter Agency Offices/Division">
                    <mat-chip 
                          *ngFor="let office of interagencyoffices" 
                          [selectable]="false"
                          [removable]="removable"
                           (removed)="removeinteragencyoffice(office)"
                           >
                      {{ office }}
                      <button matChipRemove >
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip>
                    <input placeholder="New Office/Division..."
                          #interagencyofficeinput
  
                           [matChipInputFor]="officechip"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           [matChipInputAddOnBlur]="true"
                           (matChipInputTokenEnd)="addinterAgencyOffice($event)">
                  </mat-chip-list>
                  <mat-hint class="text-danger"
                  *ngIf="listofOffices.length <= 0 " >List of inter-agency offices is required!</mat-hint
                >
                </mat-form-field>
                <div class="row">
                  <div class="col">
                    <button [matMenuTriggerFor]="clearOfficesMenu" mat-flat-button><mat-icon>delete</mat-icon><span>Clear all inter-agency offices/divisions</span></button>
                    <mat-menu #clearOfficesMenu="matMenu">
                      <div class="container">
                        <div class="row">
                          Are you sure?
                        </div>
                        <button  (click)="clearinteragencyOffices()" mat-button color="warn">Yes, remove all</button>
                        <button  mat-button >No</button>
                      </div>
                    
                    </mat-menu>
                  </div>
                </div>
              </div>
             
            </div>
            
          </div>
  
          <div class="form-group row">
            <section class="example-section  mt-5">
              <div class="col">
                <label class="form-input-header" for="type_of_transaction"
                  ><strong>Type of Transaction</strong>
                </label>
                <div formGroupName="type_of_transaction" class="mt-1 bg-light py-3">
                  <div class="form-check form-check-inline">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="type_g2c"
                      formControlName="type_g2c"
                      value="G2C"
                    />
                    <label class="form-check-label" for="type_g2c"
                      >Government to Client (G2C)</label
                    >
                  </div>
  
                  <div class="form-check form-check-inline">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="type_g2g"
                      formControlName="type_g2g"
                      value="G2G"
                    />
                    <label class="form-check-label" for="type_g2g"
                      >Government to Government (G2G)</label
                    >
                  </div>
  
                  <div class="form-check form-check-inline">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="type_g2b"
                      formControlName="type_g2b"
                      value="G2B"
                    />
                    <label class="form-check-label" for="type_g2b"
                      >Government to Business (G2B)</label
                    >
                  </div>
                </div>
                <mat-error *ngIf="service_name_and_details_form.get('type_of_transaction')?.errors ">Type of transaction is required!</mat-error>
              </div>
            </section>
          </div>
  
          <div class="form-group row">
            <div class="mt-2">
              <label class="form-input-header" for="who_may_avail "
                ><strong>Who may avail</strong></label
              >
              <mat-form-field
                style="width: 100%"
                hideRequiredMarker
                floatLabel="never"
              >
                <!-- <input
                      matInput
                      placeholder="Input"
                      type="text"
                      formControlName="who_may_avail"
                    /> -->
                <input matInput hidden />
                <editor
                  formControlName="who_may_avail"
                  [init]="{
                    base_url: '/tinymce',
                    suffix: '.min',
                    height: 250,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help'
                  }"
                ></editor>
                <mat-hint class="text-danger" *ngIf="service_name_and_details_form.get('who_may_avail')?.errors ">Who may avail is required!</mat-hint>
           
              </mat-form-field>
              
             </div>
          </div>

          <div class="form-group row">
            <div class="col-3">
              <div class="row">
                <label class="form-input-header" for="hour_per_day"
                  ><strong>Number of Hours Per Day</strong>
                </label>
                <mat-form-field class="mt-3" appearance="outline">
                  <mat-label>Select Number of Hours</mat-label>
                  <mat-select
                    formControlName="hour_per_day"
                    [(ngModel)]="hour_per_day"
                    name="hour_per_day"
                    (valueChange)="setMaxHours($event)"
                  >
                    <mat-option
                      *ngFor="let hrs of hours_per_day"
                      [value]="hrs"
                    >
                      {{ hrs }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="service_name_and_details_form.get('hour_per_day')?.errors ">Hours per day is required!</mat-error>
           
                </mat-form-field>
              </div>
            </div>
          </div>
  
          <div class="form-group row">
            <div class="col-3">
              <div class="row">
                <label class="form-input-header" for="type_of_transaction"
                  ><strong>Service Operating Hours</strong>
                </label>
                <mat-form-field class="mt-3" appearance="outline">
                  <mat-label>Operating Hours</mat-label>
                  <mat-select
                    formControlName="operating_hours"
                    name="operating_hours"
                    (ngModelChange)="getOthers($event)"
                  >
                    <mat-option
                      *ngFor="let hrs of operatingHours"
                      [value]="hrs.value"
                    >
                      {{ hrs.viewValue }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="service_name_and_details_form.get('operating_hours')?.errors ">Operating hours is required!</mat-error>
           
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-6">
              <div class="row">
                <div
                  *ngIf="
                    service_name_and_details_form.value.operating_hours ==
                    'Others'
                  "
                >
                  <label class="form-input-header" for="operating_hours "
                    >Other Operating Hours
                  </label>
                  <mat-form-field
                    style="width: 100%"
                    hideRequiredMarker
                    floatLabel="never"
                  >
                    <input
                      required
                      matInput
                      placeholder="Input"
                      type="text"
                      formControlName="operating_hours_other"
                      name="operating_hours_other"
                    />
                    <mat-error *ngIf="service_name_and_details_form.get('operating_hours_other')?.errors ">Other operating hours is required!</mat-error>
           
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
  
          <div class="form-group row">
            <section class="example-section">
              <div class="col-6">
                <div class="mt-3">
                  <div class="form-check form-check-inline">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="statue"
                      value="false"
                      formControlName="has_statute"
                      (change)="changeStatuteValue($event)"
                      [(ngModel)]="iswithStatue"
                    />
                    <label class="form-check-label" for="statue"
                      ><strong
                        >Check if Service is based on a statute</strong
                      ></label
                    >
                  </div>
                </div>
              </div>
            </section>
          </div>
  
          <div *ngIf="iswithStatue"  class="form-group row">
            <section class="exampl-section">
              <div class="row">
                <mat-form-field appearance="outline">
                  <mat-label>Statute</mat-label>
                  <input
                    
                    formControlName="statute"
                    matInput
                    width="100%"
                    placeholder="Statue"
                    [required]="iswithStatue"
                  />
                  <mat-error *ngIf="service_name_and_details_form.get('statute')?.errors ">Statute hours is required!</mat-error>
           
                </mat-form-field>
              </div>
            </section>
          </div>
  
          <div class="form-group row mt-3">
            <label class="form-input-header" for="general_remarks "
              ><strong>General Remarks(Optional)</strong>
            </label>
            <mat-form-field
              style="width: 100%"
              
              floatLabel="never"
            >
              <input matInput hidden />
              <editor
                formControlName="general_remarks"
                [init]="{
                  base_url: '/tinymce',
                  suffix: '.min',
                  height: 250,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
                                      alignleft aligncenter alignright alignjustify | \
                                      bullist numlist outdent indent | removeformat | help'
                }"
              ></editor>
             
            </mat-form-field>
           
          </div>
  
          <div class="row">
            <input type="hidden" formControlName="total_processing_time">
          </div>
          </form>
        </div>
        
       
        <mat-action-row>
          <button mat-button color="primary" (click)="nextStep()">Next</button>
        </mat-action-row>
      </mat-expansion-panel>

       <!--end-->

       <!--Service Requirements-->

      
    
      <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title> 
            <h5><span class="circle">2</span></h5> <h4 class="mx-2"> Service Requirements</h4> 
            <span *ngIf="(requirementRemarks) > 0" class="text-danger" >({{requirementRemarks}} Unresolved Remarks)</span>
            <span class="text-danger" *ngIf="no_req.value == false && (situational_requirements.length <= 0 && standard_requirements.length <= 0)">  Requirement is required </span>
            <span class="text-danger" *ngIf="validateRequirements() > 0">Invalid Requirements</span>
          </mat-panel-title>

        </mat-expansion-panel-header>
        <div class="form-container">
            <section class="my-1">

              <app-artemis-service-remarks-thread [openRemarks]="true" [remarks]=remarks_thread [service_id]=updateServiceId [component_id]="'Requirements'" [type]= "'description'" ></app-artemis-service-remarks-thread>

            </section>
            <section style="font-size: large;">
              <input [formControl]="no_req" (change)="noRequirementChange($event)" id="no_requirements"  type="checkbox">
              <label for="no_requirements">No Requirements</label>
            </section>
             <div class="s td-req-container">
              <div class="row p-2 mt-2 bg-light" style="border: 1px solid #d9d9d9;">
                <div class="col ms-5">
                  <h6 class="text-bold" >Standard Requirements</h6>
                </div>
                <div class="col-5">
                  <h6 class="text-bold" >Where to Secure</h6>
                </div>
              </div>
  
              <ul class="tree" >

                <li *ngFor="let requirement of standard_requirements; let index = index"> 

                  <app-requirement-control *ngIf="!requirement.chooseany"
                
                [requirement]="requirement"
                [referenceArray]="standard_requirements"
                [requirementIndex]="index"
                [req_type]="enum_requirement_type.STANDARD_NO_TITLE"
            
                ></app-requirement-control>
  
                <app-subsituational-requirement-control
                *ngIf="requirement.chooseany"
                [situation]="requirement"
                [situationIndex]="index"
                [referenceArray]="standard_requirements"
                [req_type]="enum_requirement_type.STNDARD_WITH_TITLE"
                
                ></app-subsituational-requirement-control>
          
  

                </li>
                
  
                
              </ul>
            </div>

            <div class="row">
              <button [disabled]="no_req.value" class="m-1" (click)="addRequirement(enum_req_type.STANDARD_NO_TITLE)" mat-raised-button color="primary">
                <mat-icon>add</mat-icon> <span>Standard Requirement</span>
              </button>
            </div>
            

            <!--Situational Requirements-->
            <div class="sit-req-container">
              <div class="row  p-2 mt-2 bg-light" style="border: 1px solid #d9d9d9;">
                <div class="col">
                  <h6 class="text-bold" >Situational Requirements</h6>
                </div>
                <div class="col-5">
                  <h6 class="text-bold" >Where to Secure</h6>
                </div>
              </div>

              <div  *ngFor="let situation of situational_requirements;let index = index">

                <app-situational-requirement-control

              
                [situation]="situation"
                [situationIndex]="index"
                [referenceArray]="situational_requirements"
                
                ></app-situational-requirement-control>



              </div>
            </div>

            <!--add situational button last row-->
            <div class="row">
              <button [disabled]="no_req.value" id="situationalButton" class="m-1" (click)="addRequirement(enum_req_type.SITUATIONAL_REQUIREMENT)" mat-raised-button color="primary">
                <mat-icon>add</mat-icon> <span>New Situation</span>
              </button>
            </div>


            <!--end of situational requirements
            <div class="row mb-2">
            <button id="standardButton" class="mx-1" (click)="addRequirement(enum_req_type.STANDARD_NO_TITLE)" mat-button>
              <mat-icon>add</mat-icon> <span>New Requirement</span>
            </button>
            <button id="standardButton" class="mx-1" (click)="addRequirement(enum_req_type.STNDARD_WITH_TITLE)" mat-button>
              <mat-icon>add</mat-icon> <span>New Requirement with Title</span>
            </button>
          </div>
            -->


          
        </div>
      <mat-action-row>
        <button mat-button color="warn" (click)="prevStep()">Previous</button>
        <button mat-button color="primary" (click)="nextStep()">Next</button>
      </mat-action-row>
    </mat-expansion-panel>

    <!--end--> 
 
      <!--Client Step-->
    
      <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h5><span class="circle">3</span></h5> <h4 class="mx-2"> Client Steps </h4>
            <span class="text-danger" *ngIf="(clientStepRemarks + agencyActionRemarks) > 0">({{clientStepRemarks + agencyActionRemarks}} Unresolved Remarks)</span>
            <span class="text-danger" *ngIf="InvalidSituationalStep() >= 1 ">Invalid Client Step</span>
          </mat-panel-title>
          <mat-panel-description class="text-danger" *ngIf="clientStepDatasource.length == 0 || exceedsMaxDays || invalidSituationalClientStepProcessingTime.length > 0">
            {{clientStepDatasource.length == 0 ? 'No Client Steps and Agency Actions': invalidSituationalClientStepProcessingTime.length > 0 ? 'Total Processing Time Exceed Maximum Days' :  exceedsMaxDays ? 'Total Processing Time Exceed Maximum Days' : ''}}

          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="form-control">
          <div class="row">
            <table class="table table-client-step table-striped">
              <thead class="info-header">
                <tr>
                  <th class="info-subheader" style="width: 45%;">
                    Client Step
                  </th>
                  <th class="info-subheader" style="width: 45%;">
                    Agency Action
                  </th>
                  <th class="info-subheader" style="width: 10%;">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let clientStep of clientStepDatasource ; let i = index;" >
  
                  <tr >
                    <ng-container *ngIf="clientStep.situational">
    
                      <td colspan="3">
                        <div  class="row">
                          
                        <mat-form-field appearance="outline">
                          <mat-label>Situation (Optional)</mat-label>
                          <textarea 
                          [(ngModel)]="clientStep.situational_step_title"
                          matInput width="100%" 
                          placeholder="Situation (optional)"></textarea>
                        </mat-form-field>
  
                        <div class="row mb-2 " id="situational-step">
                          <div class="col-6">
                            <button class="full-width border-button" mat-button color="primary" (click)="addServiceStep(true,clientStep,false, 0 , (i+ 1) + '.' + requirementservice.printToLetter(clientStep.clientStep.length + 1))"><mat-icon>add</mat-icon> New Client Step & Agency Action</button>
    
                          </div>
                          <div class="col-6">
                            <button class="full-width border-button" mat-button color="warn" (click)="removeServiceStep(i,clientStepDatasource,true)"><mat-icon>delete</mat-icon> Remove Situation</button>
                          </div>
                        </div>
                        <div class="row">
                          {{clientStep.situational_step_title ? i + 1 + "." + clientStep.situational_step_title : ''}}
                        </div>
  
                        <span *ngIf="InvalidSituationalStep() >= 1 || invalidSituationalClientStepProcessingTime.length > 0" class="text-danger">
                            Invalid Situational Step
                            <ol>
                              <li *ngIf="InvalidSituationalStep() >= 1">
                                Client Step should be 2 or more Situational Client Steps
                              </li>

                              <li *ngFor="let invalid of invalidSituationalClientStepProcessingTime">

                                {{invalid}}

                              </li>
                            </ol>
                        </span>
    
                       
                        </div>
                        
                        <div class="content-center">
                          <table class="situational-table table">
                            <col style="width: 45%;">
                            <col style="width: 45%;">
                            <col style="width: 10%;">
                            <tbody>
                              <ng-container>
                                <tr *ngFor="let situationalStep of clientStep.clientStep;let si = index;let sic = count ">
                                  <td style="width: 45%;">
                                   <div class="container">
                                    <span class="fw-bold text-wrap mb-2">Situation: {{situationalStep.info.situation}}</span> 
                                    <br><br>
                                    <span class="fw-bold text-wrap">{{i + 1}}.{{ requirementservice.printToLetter(si + 1)  }}. {{situationalStep.info.title}}</span> 
                                    <div>
                              <span class="text-bold text-muted">Total Fees:  <span *ngIf="!computeTotalFees([],situationalStep).length">None</span> </span
                              ><ul style="list-style-type: none;">
                                <li *ngFor="let fee of computeTotalFees([],situationalStep)">
                                   + {{fee.currency}} {{fee.amount  }} 
                                </li>
                            </ul>
                            </div>
                            <br>
                                   Location: 
                                    <div [innerHTML]="situationalStep.info.location">

                                    </div>
                            <div class="" *ngIf="situationalStep.info?.notes">
                              <label for="notes">Notes/Instructions: </label>
                              <div  id="notes" [innerHTML]="situationalStep.info?.notes"></div>
                            </div>

                            <app-artemis-service-remarks-thread [openRemarks]="true" [remarks]=remarks_thread [service_id]=updateServiceId [component_id]="situationalStep.step_Id" [type]="'ClientStep'" ></app-artemis-service-remarks-thread>
                            
                                   
                                   </div>
                                  </td>
                                  <td style="width: 45%;">
                                      <div class="row">
                                        <ng-container *ngFor="let agencyAction of situationalStep.agency_actions; let ai = index">
    
                                          <p class="text-bold text-wrap">
                                            {{ i + 1 }}.{{ requirementservice.printToLetter(si + 1) }}.{{ai + 1}}
                                            {{ agencyAction.title }}
                                          </p>

                                          <small class="text-muted" for="">Agency Action Remarks</small>
                                          <app-artemis-service-remarks-thread [openRemarks]="true" [remarks]=remarks_thread [service_id]=updateServiceId [component_id]="agencyAction.action_Id" [type]="'AgencyAction'" ></app-artemis-service-remarks-thread>
                                         
                      
                                          <div class="row ms-5">
                                            <label class="text-bold text-muted"
                                              >Person Responsible</label
                                            >
                                            <div class="mx-2">
                                              <ul>
                                                <li
                                                  *ngFor="
                                                    let responsible of agencyAction.persons_responsible
                                                  "
                                                >
                                                  {{ responsible.designation }} :
                                                  {{ responsible.division_group?.name ?  responsible.division_group.name : responsible.division_group }}
                                                </li>
                                              </ul>
                                            </div>
                                            <small class="text-muted" for="">Person Responsible Remarks</small>
                                            <app-artemis-service-remarks-thread [openRemarks]="true" [remarks]=remarks_thread [service_id]=updateServiceId [component_id]="agencyAction.action_Id" [type]="'ProcessingTime'" ></app-artemis-service-remarks-thread>
                                       
                      
                                            <label class="text-bold text-muted" for=""
                                              >Processing Time</label
                                            >
                                            <p>
                                              {{ agencyAction.total_processing_time }}
                                            </p>
                                            
                                          </div>
                                          <small class="text-muted" for="">Processing Time Remarks</small>
                                          <app-artemis-service-remarks-thread [openRemarks]="true" [remarks]=remarks_thread [service_id]=updateServiceId [component_id]="agencyAction.action_Id" [type]="'PersonResponsible'" ></app-artemis-service-remarks-thread>
                            
    
                                        </ng-container>
                                        
                                      </div>
                                  </td>
                                  <td>
                                                  <button
                                      (click)="updateServiceStep(situationalStep, si,clientStep.clientStep,(i + 1)+ '.' + requirementservice.printToLetter(si + 1) )"
                                      mat-button
                                    >
                                      <span><i class="fas fa-edit fa-lg"></i></span>
                                    </button>
                                    <button
                                      mat-button
                                      (click)="removeServiceStep(si,clientStep.clientStep)"
                                    >
                                      <span> <i class="fas fa-trash-alt fa-lg"></i></span>
                                    </button>

    
                                  </td>
                                 
                                </tr>
                              </ng-container>
                            </tbody>
    
                          </table>
                        </div>
                      </td>
                      
    
                    </ng-container>
    
                    <ng-container *ngIf="!clientStep.situational">
    
                      <td id="{{'client_step_' + (i)}}" style="width: 45%;">
                        <div class="row " style="word-break: break-all;">
                          <div class="text-bold">
                            {{ i + 1 }}. {{ clientStep.info.title }}
                          </div>
                          <div class="ms-3">
                            <p *ngIf="clientStep.info?.situation">
                              <span class="text-bold text-muted">Situation: </span
                              >{{ clientStep.info?.situation }}
                            </p>

                            <br>
                            Location: 
                             <div [innerHTML]="clientStep.info.location">

                             </div>

                            
                            <div>
                              <span class="text-bold text-muted">Total Fees:  <span *ngIf="!computeTotalFees([clientStep]).length">None</span></span
                              >
                              <ul style="list-style-type: none;">
                                <li *ngFor="let fee of computeTotalFees([clientStep])">
                                   + {{fee.currency}} {{fee.amount  }} 
                                </li>
                            </ul>
                            </div>
                            <div class="" *ngIf="clientStep.info?.notes">
                              <label for="notes">Notes/Instructions: </label>
                              <div id="notes" [innerHTML]="clientStep.info?.notes"></div>
                            </div>
                            <!--
                              <p *ngIf="clientStep.info?.notes">
                              <span class="text-bold text-muted"
                                >Notes/Instructions: </span
                              >
                            </p>--->
                            
                          </div>
      
                          <app-artemis-service-remarks-thread [openRemarks]="true" [remarks]=remarks_thread [service_id]=updateServiceId [component_id]="clientStep.info.step_Id" [type]= "'ClientStep'" ></app-artemis-service-remarks-thread>
      
                        </div>
      
                       
                      </td>
                      <td style="width: 45%;">
                        <div
                        *ngFor="
                                let agency_actions of clientStep.agency_actions;
                                let j = index
                              "
                         class="row" style="word-break: break-all;">
                          <div class="text-bold">
                            {{ i + 1 }}.{{ j + 1 }}
                            {{ agency_actions.title }}
                          </div>
                          <small class="text-muted" for="">Agency Action Remarks</small>
                          <app-artemis-service-remarks-thread [openRemarks]="true" [remarks]=remarks_thread [service_id]=updateServiceId [component_id]="agency_actions.action_Id" [type]="'AgencyAction'" ></app-artemis-service-remarks-thread>
                     
      
                          <div class="row">
                            <label class="text-bold text-muted"
                              >Person Responsible</label
                            >
                            <div class="mx-2">
                              <ul>
                                <li
                                  *ngFor="
                                    let responsible of agency_actions.persons_responsible
                                  "
                                >
                                  {{ responsible.designation }} :
                                  {{ responsible.division_group?.name ? responsible.division_group.name : responsible.division_group }}
                                </li>
                              </ul>
                            </div>

                            <small class="text-muted" for="">Person Responsible Remarks</small>
                          <app-artemis-service-remarks-thread [openRemarks]="true" [remarks]=remarks_thread [service_id]=updateServiceId [component_id]="agency_actions.action_Id" [type]="'PersonResponsible'" ></app-artemis-service-remarks-thread>
                     
      
                            <label class="text-bold text-muted" for=""
                              >Processing Time</label
                            >
                            <p>
                              {{ agency_actions.total_processing_time }}
                            </p>
                            
                          </div>
                          <small class="text-muted" for="">Processing Time Remarks</small>
                          <app-artemis-service-remarks-thread [openRemarks]="true" [remarks]=remarks_thread [service_id]=updateServiceId [component_id]="agency_actions.action_Id" [type]= "'ProcessingTime'" ></app-artemis-service-remarks-thread>
      
      
                          </div>
                       
                      </td>
                      <td style="width: 10%;">
                        <button
                          (click)="updateServiceStep(clientStep, i,clientStepDatasource,i + 1)"
                          mat-button
                        >
                          <span><i class="fas fa-edit fa-lg"></i></span>
                        </button>
                        <button
                          mat-button
                          [matMenuTriggerFor]="removeClientStep"
                        >
                          <span> <i class="fas fa-trash-alt fa-lg"></i></span>
                        </button>
                        <mat-menu #removeClientStep="matMenu">
      
                          <div class="container">
                            <div class="row">
                              Are you sure?
                            </div>
                            <button
                              (click)="removeServiceStep(i,clientStepDatasource)"
                              mat-button
                              color="warn"
                            >
                              Yes
                            </button>
                            <button mat-button>No</button>
                          </div>
                        </mat-menu>
                      </td>
    
                    </ng-container>
                  
                  </tr>
  
                  <tr>
                    <td colspan="3">
  
                            <div class="row">
                    <div class="col-6">
                      <button class="full-width" id="newstepbutton" mat-raised-button color="primary" (click)="addServiceStep(false,null,true,i + 1,i + 2)">
                        <mat-icon>add</mat-icon> <span>New Client Step</span>
                      </button>
                    </div>
                    <div class="col-6">
  
                      <button class="full-width" [disabled]="checkifCLientDatasourcehasSituational()" mat-raised-button color="accent" (click)="addSituation(i + 1 )"><mat-icon>add</mat-icon> Situational Client Step</button>
                
                    </div>
                  </div>
  
                          </td>
                  </tr>
  
                </ng-container>
  
                <ng-container *ngIf="clientStepDatasource.length == 0">
                  <tr>
                    <td colspan="3">
  
                            <div class="row">
                    <div class="col-6">
                      <button class="full-width" id="newstepbutton" mat-raised-button color="primary" (click)="addServiceStep()">
                        <mat-icon>add</mat-icon> <span>New Client Step</span>
                      </button>
                    </div>
                    <div class="col-6">
  
                      <button class="full-width" [disabled]="checkifCLientDatasourcehasSituational()" mat-raised-button color="accent" (click)="addSituation(clientStepDatasource.length)"><mat-icon>add</mat-icon> Situational Client Step</button>
                
                    </div>
                  </div>
  
                          </td>
                  </tr>
                </ng-container>
  
                
  
  
                
              </tbody>
            </table>
  
            <div *ngIf="exceedsMaxDays" class="bg-danger text-center p-3">
              Total Processing Time Exceed Maximum Days
            </div>
  
          </div>
        </div>
       
    
    
        <mat-action-row>
          <button mat-button color="warn" (click)="prevStep()">Previous</button>
          <button mat-button color="primary" (click)="nextStep()">Next</button>
        </mat-action-row>
      </mat-expansion-panel>
      

      <!--end-->

      <!--EODB Tab-->

      <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h5><span class="circle">4</span></h5> <h4 class="mx-2">EODB Tags</h4> <span class="text-danger" *ngIf="(eodbRemarks) > 0">({{eodbRemarks }} Unresolved Remarks)</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="form-control">
          <section class="mt-1">
            <app-artemis-service-remarks-thread [openRemarks]="true" [remarks]=remarks_thread [service_id]=updateServiceId [component_id]="'EODB_Tags'" [type]= "'eodb_tags'" ></app-artemis-service-remarks-thread>
  
          </section>
          <div class="col-10 tab-column">
            <div class="form-header">
              <h3>Add EODB Tags</h3>
            </div>
            <mat-dialog-content class="tabular">
              <div class="form-group">
                <table
                  *ngIf="eodb_tags_form.length > 0"
                  mat-table
                  [formArray]="eodb_tags_form"
                  [dataSource]="eodb_tags_form_datasource"
                  class="mat-elevation-z8"
                >
                  <ng-container matColumnDef="classification">
                    <th mat-header-cell *matHeaderCellDef>Classification</th>
                    <td mat-cell *matCellDef="let element" [formGroup]="element">
                      <mat-form-field
                        style="width: 100%"
                        hideRequiredMarker
                        floatLabel="never"
                      >
                        <mat-select formControlName="classification" multiple>
                          <mat-option value="Business">Business</mat-option>
                          <mat-option value="Non-Business">Non-Business</mat-option>
                        </mat-select>
    
                        <mat-hint *ngIf="!element.get('classification')?.touched"
                          >Required</mat-hint
                        >
                        <mat-error
                          style="color: red"
                          *ngIf="element.get('classification')?.errors"
                          >Input a value</mat-error
                        >
                      </mat-form-field>
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="sector">
                    <th mat-header-cell *matHeaderCellDef>Sector</th>
                    <td mat-cell *matCellDef="let element" [formGroup]="element">
                      <mat-form-field
                        style="width: 100%"
                        hideRequiredMarker
                        floatLabel="never"
                      >
                        <mat-select formControlName="sector">
                          <mat-option value="" disabled selected>Sector</mat-option>
                          <mat-option
                            *ngFor="let row of valueSuggestions.sector"
                            [value]="row.name"
                            >{{ row.name }}</mat-option
                          >
                        </mat-select>
    
                        <mat-hint *ngIf="!element.get('sector')?.touched"
                          >Required</mat-hint
                        >
                        <mat-error
                          style="color: red"
                          *ngIf="element.get('sector')?.errors"
                          >Input a value</mat-error
                        >
                      </mat-form-field>
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="division">
                    <th mat-header-cell *matHeaderCellDef>Division</th>
                    <td mat-cell *matCellDef="let element" [formGroup]="element">
                      <mat-form-field
                        style="width: 100%"
                        hideRequiredMarker
                        floatLabel="never"
                      >
                        <mat-select formControlName="division">
                          <mat-option value="" disabled selected
                            >Division</mat-option
                          >
                          <mat-option
                            *ngFor="let row of filteredDivision(element.get('sector')?.value)"
                            [value]="row.name"
                            >{{ row.name }}</mat-option
                          >
                        </mat-select>
    
                        <mat-hint *ngIf="!element.get('division')?.touched"
                          >Required</mat-hint
                        >
                        <mat-error
                          style="color: red"
                          *ngIf="element.get('division')?.errors"
                          >Input a value</mat-error
                        >
                      </mat-form-field>
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="stage_of_business">
                    <th mat-header-cell *matHeaderCellDef>Stage of Business</th>
                    <td mat-cell *matCellDef="let element" [formGroup]="element">
                      <mat-form-field
                        style="width: 100%"
                        hideRequiredMarker
                        floatLabel="never"
                      >
                        <mat-select formControlName="stage_of_business">
                          <mat-option value="" disabled selected
                            >Stage of Business</mat-option
                          >
                          <mat-option
                            *ngFor="let row of valueSuggestions.stage_of_business"
                            [value]="row.name"
                            >{{ row.name }}</mat-option
                          >
                        </mat-select>
    
                        <mat-hint *ngIf="!element.get('stage_of_business')?.touched"
                          >Required</mat-hint
                        >
                        <mat-error
                          style="color: red"
                          *ngIf="element.get('stage_of_business')?.errors"
                          >Input a value</mat-error
                        >
                      </mat-form-field>
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="case_use">
                    <th mat-header-cell *matHeaderCellDef>Case Use</th>
                    <td mat-cell *matCellDef="let element" [formGroup]="element">
                      <mat-form-field
                        style="width: 100%"
                        hideRequiredMarker
                        floatLabel="never"
                      >
                        <mat-select formControlName="case_use">
                          <mat-option value="" disabled selected
                            >Case Use</mat-option
                          >
                          <mat-option
                            *ngFor="let row of filteredCaseUse(element.get('stage_of_business')?.value)"
                            [value]="row.name"
                            >{{ row.name }}</mat-option
                          >
                        </mat-select>
    
                        <mat-hint *ngIf="!element.get('case_use')?.touched"
                          >Required</mat-hint
                        >
                        <mat-error
                          style="color: red"
                          *ngIf="element.get('case_use')?.errors"
                          >Input a value</mat-error
                        >
                      </mat-form-field>
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="jurisdiction">
                    <th mat-header-cell *matHeaderCellDef>Jurisdiction</th>
                    <td mat-cell *matCellDef="let element" [formGroup]="element">
                      <mat-form-field
                        style="width: 100%"
                        hideRequiredMarker
                        floatLabel="never"
                      >
                        <mat-select formControlName="jurisdiction">
                          <mat-option value="" disabled selected
                            >Jurisdiction</mat-option
                          >
                          <mat-option
                            *ngFor="let row of valueSuggestions.jurisdiction"
                            [value]="row.name"
                            >{{ row.name }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element; let $i = index">
                      <button
                        mat-stroked-button
                        (click)="removeEodbTag($i)"
                        class="btn committee"
                      >
                        <span class="material-icons"> remove_circle </span>
                      </button>
                    </td>
                  </ng-container>
    
                  <tr
                    mat-header-row
                    *matHeaderRowDef="eodbTagsFormDisplayedColumns"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: eodbTagsFormDisplayedColumns"
                  ></tr>
                </table>
              </div>
            </mat-dialog-content>
            <button mat-stroked-button (click)="addNewEodbTag()" class="btn m-1">
              <span class="material-icons"> add_circle_outline </span>
              Add Row
            </button>
          </div>
        </div>
        
    
    
        <mat-action-row>
          <button mat-button color="warn" (click)="prevStep()">Previous</button>
          <button mat-button color="primary" (click)="nextStep()">Next</button>
        </mat-action-row>
      </mat-expansion-panel>

      <!---end-->


      <!--Service Preview-->

      <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h5><span class="circle">5</span></h5> <h4 class="mx-2">Service Preview</h4>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="form-container">
          <app-citizens-charter-service-list-view [services] = services [hasCounter]="false"></app-citizens-charter-service-list-view>
        </div>
    
        
      </mat-expansion-panel>

      <!---end-->
    
    </mat-accordion>
    
 
    
  </div>
  <div class="container">
  <mat-action-row *ngIf="!loader">
          <button mat-button color="warn" (click)="prevStep()">Previous</button>
          <button
            class="mx-1"
            (click)="goBack()"
            style="margin-left: 20px"
            mat-flat-button
            color="warn"
          >
            Cancel
          </button>

          <!--save as draft if from service management-->
          <button *ngIf="fordraftOnly"
            (click)="saveAsDraft()"
            class="mx-1" mat-flat-button color="primary">Save as Draft
          </button>
  
          <!--Use only save changes
            <button *ngIf="(worklistStatus && !fromServiceManagement) && !serviceCompleteness"
          (click)="updateService(true)"
          class="mx-1" mat-flat-button color="primary">Save as Draft
        </button>-->

        <!--[disabled]="!checkServicesValidity() || exceedsMaxDays || InvalidSituationalStep() >= 1"-->
  
          <button *ngIf="((!worklistStatus && !fromServiceManagement) || fromServiceManagement) && !serviceCompleteness"
            (click)="saveAsDraft()"
            class="mx-1" mat-flat-button color="primary">Save as Draft
          </button>
          <button *ngIf="!fromServiceManagement && updateServiceId && updateWorklistId   && (account_type == enum_account_subtype.AGENCY_ENC || account_type == enum_account_subtype.AGENCY_VER)"
          (click)="updateService(true)"

          [disabled]="( !checkServicesValidity()) && account_type  === enum_account_subtype.AGENCY_VER"
          
          class="mx-1" mat-flat-button color="primary">
          Save Changes
          
        </button>
        <button 

        *ngIf="(!fromServiceManagement && worklistStatus == enum_citizenscharter_status.DRAFT) "
        (click)="updateService(false,true)"
        mat-flat-button
        [disabled]="InvalidSituationalStep() >= 1 || invalidSituationalClientStepProcessingTime.length > 0  || !checkServicesValidity() || exceedsMaxDays || validateRequirements() > 0 || (no_req.value == false && (situational_requirements.length <= 0 && standard_requirements.length <= 0))"
        color="primary">Save and Submit</button>

          <!--<button
          *ngIf="fromServiceManagement"
            (click)="submitService()"
            [disabled]="InvalidSituationalStep() >= 1 || !checkServicesValidity() || exceedsMaxDays"
            class="mx-1"
            mat-flat-button
            color="primary"
          >
          Save Changes and Submit
          </button>-->
  
          <button
          *ngIf="!fromServiceManagement && (!updateServiceId && !updateWorklistId) && !worklistStatus"
            (click)="submitService()"
            [disabled]="InvalidSituationalStep() >= 1 || invalidSituationalClientStepProcessingTime.length > 0 || !checkServicesValidity() || exceedsMaxDays || validateRequirements() > 0 || (no_req.value == false && (situational_requirements.length <= 0 && standard_requirements.length <= 0))"
            class="mx-1"
            mat-flat-button
            color="primary"
          >
          Save Service and Submit
          </button>
        </mat-action-row>
  </div>
</div>
