import { Component, OnInit, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app--reference-common-modal',
  templateUrl: './reference-common-modal.component.html',
  styleUrls: ['./reference-common-modal.component.scss']
})
export class ReferenceCommonModalComponent implements OnInit {

  action:any;

  toEdit:boolean = false;
  toCreate:boolean= false;
  toView:boolean = false;
  toDelete:boolean = false;

  currentRef:any;

  method!: string;
  collection!: string;
  id?:any;
  show_form: boolean = false;
  del_confirm: boolean = false;

  viewDetails = {
    id: "",
    section: "",
    name: "",
    definition: "",
  }
  
  commonRefForm = this.fb.group({
    section: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    definition: new FormControl('', Validators.required)
  });
  
  title: string = "";

  resetValue = {
    id: "",
    section: "",
    name: "",
    definition: "",
  }

  checkRefForm = {
    section:[
      { type:"required", message:"Section is requried"}
    ],
    name:[
      { type:"required", message:"Name is requried"}
    ],
    definition:[
      { type:"required", message:"Definition is requried"}
    ],
  };

  constructor(
    private afs: AngularFirestore,
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<ReferenceCommonModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
  }

  ngOnInit(): void {
    this.data.success = false;
    if(this.data.action === 'view'){
      this.action = "View"
      this.toView = true
    }
    else if(this.data.action === 'create'){
      this.action = "Add"
      this.toCreate = true
    }
    else if(this.data.action === 'edit'){
      this.action = "Edit"
      this.toEdit = true
    }
    else if(this.data.action === 'delete'){
      this.action = "Delete"
      this.toDelete = true
    }

    this.title = this.data.currentRef
    if(this.data.action === 'edit' || this.data.action === 'delete' || this.data.action === 'view')
      this.loadData(this.data.currentRef,this.data.refId)
  }

  loadData(collection:any, id:any){
    this.afs.collection(collection).doc(id).snapshotChanges()
    .pipe(first())
    .subscribe(info=>{
      if(info.payload.exists){
        let data:any = info.payload.data();
        data.id = id
        this.currentRef = data
        this.patchData(data)
      }
    })
  }

  patchData(data:any){
    this.commonRefForm.patchValue({
      section: data.section ? data.section : "",
      name: data.name ? data.name : "",
      definition: data.definition ? data.definition : "",
    })
  }

  goBack(){
    this.dialogRef.close(this.data)
  }

  creating(){
    this.validateFields()
    if(this.commonRefForm.valid){
      const artemisData = this.commonRefForm.value
      this.afs.collection(this.data.currentRef).add(artemisData)
      .then(result=>{
        // this.dialogRef.close(this.data)
        this.data.refId = result.id
        this.data.success = true;
        this.dialogRef.close(this.data)
      })
      .catch(err=>{
        alert('Unable to create '+this.currentRef)
        this.dialogRef.close(this.data)
      })
    }
    else console.warn('form invalid')
  }

  updating(){
    this.validateFields()
    if(this.commonRefForm.valid){
      const artemisData = this.commonRefForm.value
      this.afs.collection(this.data.currentRef).doc(this.data.refId).update(artemisData)
      .then(()=>{
        this.data.success = true;
        this.dialogRef.close(this.data)
      })
      .catch(err=>{
        alert('Unable to edit '+this.currentRef)
        this.dialogRef.close(this.data)
      })
    }
  }

  deleting(){
    this.afs.collection(this.data.currentRef).doc(this.data.refId).delete()
    .then(()=>{
      this.data.success = true;
      this.dialogRef.close(this.data)
    })
    .catch(err=>{
      alert('Unable to delete '+this.currentRef)
      this.dialogRef.close(this.data)
    })
  }



  isTouched(controlName:any){
    return (this.commonRefForm.get(controlName) as FormControl).touched;
  }

  hasError(controlName:any, validType:any){
    return ((this.commonRefForm.get(controlName) as FormControl).hasError(validType) 
    && ((this.commonRefForm.get(controlName) as FormControl).dirty 
    || (this.commonRefForm.get(controlName) as FormControl).touched));
  }

  validateFields(): void{
    if(!this.commonRefForm.valid){
      this.commonRefForm.markAsTouched();
      for(const control in this.commonRefForm.controls){
        if(this.commonRefForm.controls.hasOwnProperty(control)){
          this.commonRefForm.controls[control].markAllAsTouched();
          this.commonRefForm.controls[control].markAsDirty();
        }
      }
    }
  }
}
