<!--
<p>testJSON: {{testJSON | json}} end</p>
<p>testJSON[0]:{{testJSON[0] | json}}*end</p>
<p>headers: {{testJSONheaders}} end</p>
-->
<div class="content">
    <div class="container-fluid p-4">
        <div class="btn-group ms-auto m-3 d-flex justify-content-center">
            <button *ngFor="let tab of tabPages" class="btn btn-outline-dark" (click)="switchTab(tab.title)">{{tab.title}}</button>
        </div>
        <!-- List -->
        <div class="row m-3" *ngFor="let tab of tabPages">
            <h3 class="card-title" *ngIf="currentTab == tab.title">{{tab.title}}</h3>
            <small class="card-subtitle" *ngIf="currentTab == tab.title">{{tab.subtitle}}</small>
            <!--Card-->
            <app-crud-table *ngIf="currentTab == tab.title" [firebaseCollection]="tab.full_path"></app-crud-table>
        </div>
        <!--Modal-->
        <!--View Modal-->

        <!--Update Modal-->

        <!--Delete Modal-->



    </div>
</div>

