<app-navbar [currentPage]="'Management'"></app-navbar>

<div class="content">
    <div class="p-4">
        <div class="row">
            <div class="container">
                <!--Nav components-->
                <div class="row">
                    <div class="col-4">
                        <nav>
                            <ul class="nav flex-column">
                                <li class="nav-item" style="border: 1px black solid; text-align: center; padding: 5px;">Regulatory Management</li>
                                <li class="nav-item" style="border: 1px black solid; padding: 10px;">
                                    Productivity Report
                                </li>
                                
                               
                                <li (click)="openConsultationReport()" class="nav-item nav-item-click fw-bold" [ngClass]="{
                                'bg-dark text-white': consultation_report}" style="border: 1px black solid;padding: 10px; ">
                                    <a>Consultation</a>
                                </li>
                                <li [ngClass]="{
                                'bg-dark text-white': existing_report}"  (click)="openExistingReport()" class="nav-item nav-item-click fw-bold" style="border: 1px black solid;padding: 10px; ">
                                    <a>Existing Regulation</a>
                                </li>
                                <li  [ngClass]="{
                                'bg-dark text-white': proposed_report}" (click)="openProposedReport()" class="nav-item nav-item-click fw-bold" style="border: 1px black solid;padding: 10px; ">
                                    <a>Proposed Regulation</a>
                                </li>
                                <li  [ngClass]="{
                                'bg-dark text-white': tickets_report}" (click)="openTicketReport()" class="nav-item nav-item-click fw-bold" style="border: 1px black solid;padding: 10px; ">
                                    <a>Tickets</a>
                                </li>
                                <li  [ngClass]="{
                                'bg-dark text-white': use_mgt_report}" (click)="openUserManagementReport()" class="nav-item nav-item-click fw-bold" style="border: 1px black solid;padding: 10px; ">
                                    <a>User Management</a>
                                </li>
                                
                                
                                
                            </ul>


                        </nav>
                    </div>
                    <div class="col-8 ">
                        <div class="container " style="border: 2px solid black; padding: 30px; ">
                            <!--Main Content Here -->
                            <app-productivity-consultation *ngIf="consultation_report"></app-productivity-consultation>
                            <app-productivity-existing-regulations *ngIf="existing_report"></app-productivity-existing-regulations>
                            <app-productivity-proposed-regulations *ngIf="proposed_report"></app-productivity-proposed-regulations>
                            <app-productivity-tickets *ngIf="tickets_report"></app-productivity-tickets>
                            <app-productivity-user-management *ngIf="use_mgt_report"></app-productivity-user-management>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
