import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReferenceService {

  constructor(private afs: AngularFirestore) { }

  async getNotification(code: string) {
    return this.afs
      .collection("References")
      .doc("common").collection("notif_messages", filter => filter.where("code", "==", code).limit(1))
      .snapshotChanges()
      .pipe(
        map(
          (actions) => {
            return actions.map(a => {
              return a.payload.doc.data().value;
            })
          }
        ),
        map((data) => {
          return data[0];
        })
      );
  }
}
