import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup} from '@angular/forms';
import firebase from "firebase/app";
import Timestamp = firebase.firestore.Timestamp;

@Component({
    selector: 'app-ris-assessment-view-arta-remarks-modal',
    templateUrl: './ris-assessment-view-arta-remarks-modal.component.html',
    styleUrls: ['./ris-assessment-view-arta-remarks-modal.component.scss'],
})
export class PublicRiaAssessmentViewArtaRemarksModalComponent implements OnInit {
    remarksForm = new FormGroup({
        remarks: new FormControl('')
    })

    constructor(
        public dialogRef: MatDialogRef<PublicRiaAssessmentViewArtaRemarksModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        if(this.data.control.score.remarks){
            this.remarksForm.patchValue({
                remarks: this.data.control.score.remarks
            })
        }
    }

    closeModal(){
        this.dialogRef.close()
    }

    checkData(value:any){
        try{
            if(value){
                if(value instanceof Timestamp){
                    return value.toDate()
                }
                else{
                    if(value.seconds && value.nanoseconds){
                        const timestamp = new Timestamp(value.seconds, value.nanoseconds)
                        return timestamp.toDate()
                    }
                }
            }
            return ''
        }catch(err){
            console.error('value error: ',err)
            return ''
        }
    }
}