<div *ngIf="isInboxListView">
  <app-inbox-list
    [regulationInboxWorklistCollectionName]="regulationInboxWorklistCollectionName"
    [activateChild]="activateChild"
    [activateObject]="activateObject"
    (toInboxComponent)="changeInboxViewPort($event)"
  ></app-inbox-list>
</div>

<div *ngIf="isExistingRegulationView">
  <app-regulation-view-existing
    [regulationInboxCollectionName]="regulationInboxCollectionName"
    [regulationInboxWorklistCollectionName]="regulationInboxWorklistCollectionName"
    (toInboxComponent)="changeInboxViewPort($event)"
    [paramFromInboxList]="isParamFromInboxList"
    [docuFromInboxList]="isDocuIDFromInboxList"
  ></app-regulation-view-existing>
</div>

<div *ngIf="isProposedRegulationView">
  <app-regulation-view-proposed
    [regulationInboxCollectionName]="regulationInboxCollectionName"
    [regulationInboxWorklistCollectionName]="regulationInboxWorklistCollectionName"
    [constExistingRegulation]="constExistingRegulation"
    (toInboxComponent)="changeInboxViewPort($event)"
    [paramFromInboxList]="isParamFromInboxList"
    [docuFromInboxList]="isDocuIDFromInboxList"
  ></app-regulation-view-proposed>
</div>
