<!--NAVBAR-->
<app-artemis-navigation-header [currentPage]="'Reports'"></app-artemis-navigation-header>
<!-- <div class="header-divider"></div> -->
<div class="container-fluid service-finder p-0 bg-light">
    <div class="theme-{{userType}} service-finder-title ">
        <h1>Services Finder</h1>
    </div>
    
    <form [formGroup]="form" class="service-finder-body">
        <div class="row service-finder-body">
            <div class="col-10">
                <div class="row">
                    <div class="col">
                        <input type="text"
                            class="form-control text-center service-finder-input"
                            formControlName="keyword"
                            placeholder="Keyword">
                        <!-- <input type="text" formControlName="keyword" (input)="searchForm()" class="form-control w-100 service-finder-input" placeholder="Keyword">
                        <ul class="list-group service-finder-input" style="display: inline-block; white-space: nowrap;">
                            <li *ngFor="let result of searchSuggestions" (click)="selectItem(result)" class="list-group-item text-dark">{{ result }}</li>
                        </ul> -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <div class="row">
                            <div class="col">
                                <input type="text"
                                    class="form-control w-100 service-finder-input"
                                    formControlName="agency"
                                    [matAutocomplete]="agency"
                                    placeholder="Agency Name">
                                <ng-container *ngFor="let item of checkSearchForm.agency">
                                    <mat-error style="color:red" *ngIf="hasError('agency',item.type)">* {{item.message}}</mat-error>
                                </ng-container> 
                                <mat-autocomplete #agency="matAutocomplete">
                                    <mat-option *ngFor="let agency of filteredAgency | async" [value]="agency.agencyDetails ? agency.agencyDetails.agencyName : agency.name">
                                        {{agency.agencyDetails ? agency.agencyDetails.agencyName : agency.name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <input type="text"
                                    class="form-control w-100 service-finder-input"
                                    formControlName="sector"
                                    [matAutocomplete]="sector"
                                    placeholder="Sector">
                                <mat-autocomplete #sector="matAutocomplete">
                                    <mat-option *ngFor="let sector of filteredSector | async" [value]="sector.name">
                                        {{sector.name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>
                        
                        <!-- <select name="agency" formControlName="agency" (change)="searchForm()" class="form-select w-100 service-finder-input">
                            <option value="" disabled selected>Agency</option>
                            <option *ngFor="let row of valueSuggestions.agency" value="{{row.name}}">{{row.name}}</option>
                        </select>
                        <select name="sector" formControlName="sector" (change)="searchForm()" class="form-select w-100 service-finder-input">
                            <option value="" disabled selected>Sector</option>
                            <option *ngFor="let row of valueSuggestions.sector" value="{{row.name}}">{{row.name}}</option>
                        </select> -->
                    </div>
                    <div class="col-7">
                        <div class="row">
                            <div class="col">
                                <input type="text"
                                    class="form-control w-100 service-finder-input"
                                    formControlName="agencycategory"
                                    [matAutocomplete]="agencycategory"
                                    placeholder="Agency Category">
                                <mat-autocomplete #agencycategory="matAutocomplete">
                                    <mat-option *ngFor="let agencycategory of filteredAgencyCategory | async" [value]="agencycategory.name">
                                        ({{agencycategory.abbreviation}}) {{agencycategory.name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                            <div class="col" *ngIf="(userType == 'arta' || userType == 'agency')">
                                <input type="text"
                                    class="form-control w-100 service-finder-input"
                                    formControlName="servicecategory"
                                    [matAutocomplete]="servicecategory"
                                    placeholder="Service Category">
                                <mat-autocomplete #servicecategory="matAutocomplete">
                                    <mat-option *ngFor="let servicecategory of filteredServiceCategory | async" [value]="servicecategory.name">
                                        {{servicecategory.name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>
                        

                        
                        <!-- <select name="services" formControlName="service" (change)="searchForm()" class="form-select w-100 service-finder-input">
                            <option value="" disabled selected>List of Services</option>
                            <option *ngFor="let row of fieldLOVservice" value="{{row.name}}">{{row.name}}</option>
                        </select> -->
                        <div class="row">
                            <div class="col-4">
                                <select class="form-select w-100 service-finder-input" #year (change)="yearChanged(year.value)">
                                    <option readonly selected value="">Year</option>
                                    <option [selected]="yearSelected(item)" [value]="item" *ngFor="let item of allYears">
                                        {{item}}
                                    </option>
                                </select>
                                <!-- <select name="year" formControlName="year" (change)="searchForm()" class="form-select w-100 service-finder-input">
                                    <option value="" disabled selected>Year</option>
                                    <option *ngFor="let row of fieldLOVyear" value="{{row.name}}">{{row.name}}</option>
                                </select> -->
                            </div>
                            <div class="col-8">
                                <input type="text"
                                    class="form-control w-100 service-finder-input"
                                    formControlName="classification"
                                    [matAutocomplete]="classification"
                                    placeholder="Classification">
                                <mat-autocomplete #classification="matAutocomplete">
                                    <mat-option *ngFor="let classification of filteredClassification | async" [value]="classification.name">
                                        {{classification.name}}
                                    </mat-option>
                                </mat-autocomplete>
                                <!-- <select name="classification" formControlName="classification" (change)="searchForm()" class="form-select w-100 service-finder-input">
                                    <option value="" disabled selected>Classification</option>
                                    <option *ngFor="let row of valueSuggestions.classification" value="{{row.name}}">{{row.name}}</option>
                                </select> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2 service-finder-image d-flex justify-content-evenly" style="flex-direction: column">
                <!--insert thumbnail?-->
                <div>
                    <button class="btn btn-dark w-100" type="button" (click)="formSubmit()">Search</button>
                </div>
                <div>
                    <button class="btn btn-dark w-100" type="button" (click)="resetForm()">Reset</button>
                </div>
                <div>
                    <button class="btn btn-dark w-100" type="button" (click)="toArtemisAdvancedSearch()">Advanced Search</button>
                </div>
            </div>
            <!-- <div class="col-2 service-finder-image d-flex justify-content-center align-items-center">
                insert thumbnail?
                <img src="/assets/jg.jpg" class="w-100">
                <img src="/assets/arta-logo.png" class="w-100">
            </div> -->
            
        </div>
    </form>
</div>


<!-- <p class="text-end">Export in XML, JSON...</p> -->
<!-- <table class="table table-light table-hover service-results shadow table-responsive tab-pane border border-dark border-top-0 border-right-0 border-left-0 border-bottom bg-light rounded-bottom">
    <thead>
        <tr class="theme-{{userType}}-text theme-{{userType}}-border service-results-header">
            <th>
                <h1 style="font-weight: bold !important">Services Found <span style="color: red !important">{{resultscount}}</span></h1>
            </th>
            <th class="sort-button">
                <button class="btn btn-light">⇅ AZ</button>
            </th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngIf="results.length > 0">
            <tr *ngFor="let result of results">
                <td>
                    <div class="row">
                        <div class="col">
                            <h4 class='list-header'>{{result.serviceDetails.service_name}}</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">Agency: {{result.serviceDetails.agency}}</div>
                        <div class="col-3">Section: {{result.sector}}</div>
                    </div>
                </td>
                <td class="service-results-buttons">
                    <a routerLink="/artemis/view/detail/{{result.id}}" class="btn btn-light">
                        <i class="far fa-list-alt fa-5x"></i>
                    </a>
                    <a routerLink="/artemis/view/process/{{result.id}}" class="btn btn-light">
                        <i class="fas fa-project-diagram fa-5x"></i>
                    </a>
                </td>
            </tr>
        </ng-container>
        <ng-container *ngIf="results.length == 0">
            <tr>
               <h1 style="font-weight: bold;">No Data</h1> 
            </tr>
        </ng-container>
        
    </tbody>
    <tfoot>
        <div class="btn-group m-3">
            <div class="btn btn-sm btn-light">Previous</div>
            <div class="btn btn-sm btn-light">Next</div>
        </div>
    </tfoot>
</table> -->

<div class="container">
    <div class="col my-3">
        <div class="col d-inline-flex">
            <h2 class="text-primary fw-bold m-1">Service Results</h2>
            <h3 class="text-danger fw-bold mt-2 mx-3" *ngIf="!loading">
                {{ resultscount }}
            </h3>
            <div *ngIf="loading && currentRegulationInPercent > 0" class="d-flex justify-content-center align-self-center relative">
                <span class="absolute percentLoader">{{currentRegulationInPercent}}%</span>
                <mat-spinner [diameter]="50" mode="determinate" [value]="currentRegulationInPercent"></mat-spinner>
            </div>
        </div>
        <p class="border border-primary w-100 m-3"></p>
    
        <div class="col my-3">
            <!-- <div class="d-flex justify-content-center align-self-center">
                <div class="spinner-grow " role="status" *ngIf="loading">
                <span class="visually-hidden">Loading...</span>
                </div>
            </div> -->
            
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                class="table table-hover"
                [hidden]="!results">
                <thead hidden>
                <tr>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <!-- Table Item -->
                <!-- <tr
                    *ngFor="let entry of services"
                    (click)="viewCitizensCharter(entry.id)"
                    class="charter-row"
                > -->
                <tr *ngFor="let entry of results" (click)="viewService(entry.id)" class="charter-row">
                    <td class="w-25">
                    <!-- <img
                        src="../../assets/ph-seal_small.png"
                        class="img-fluid m-0 align-self-center"
                    /> -->
                        <div class="d-flex justify-content-center">
                            <img [src]="getImage(entry)"
                            class="img-fluid m-0 align-self-center card"
                            style="width: 220px; height: 220px; object-fit: cover;" >
                        </div>
                    </td>
                    <td>
                    <p>
                        <i class="mx-2" *ngIf="entry.serviceDetails.service_name"
                        ><strong class="fw-bold"> Name of Service:</strong>
                            {{entry.serviceDetails.service_name }}</i>
                    </p>
                    <p>
                        <i class="mx-2" *ngIf="entry.serviceDetails.service_category"
                        ><strong class="fw-bold">Category</strong>
                        {{ entry.serviceDetails.service_category }}</i>
                    </p>
                    <p>
                        <i class="mx-2" *ngIf="entry.serviceDetails.service_description"
                        ><strong class="fw-bold"> Description</strong>
                        <p [innerHtml]="entry.serviceDetails.service_description"></p></i>
                    </p>
                    <p>
                        <i class="mx-2" *ngIf="entry.serviceDetails.agency"
                        ><strong class="fw-bold">Agency:</strong>
                        {{ entry.serviceDetails.agency }}</i>
                    </p>
                    <!-- <p>
                        <i class="mx-2" *ngIf="entry.tags"
                        ><strong class="fw-bold">Tags:</strong>
                        {{ entry.tags }}</i
                        >
                    </p> -->
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    </div>