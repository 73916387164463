import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { ReferenceService } from 'src/app/service/reference.service';

@Component({
  selector: 'app-confirm-reset-password',
  templateUrl: './confirm-reset-password.component.html',
  styleUrls: ['./confirm-reset-password.component.scss']
})
export class ConfirmResetPasswordComponent implements OnInit {

  method!: string;
  collection!: string;
  id?: any;

  header_content?: any;
  body_content?: any;
  buttons?: any;

  email!: string;
  // extra links
  login_route!: string;
  redirect_url!: string;
  notif_message_code!: string;

  constructor(public modalRef: MDBModalRef, private auth: AngularFireAuth, private router: Router,
    private referenceService: ReferenceService) { }

  ngOnInit(): void {
  }

  confirm() {
    this.auth.sendPasswordResetEmail(this.email, {
      url: this.redirect_url
    });
    this.modalRef.hide();
    this.referenceService.getNotification(this.notif_message_code).then((data) => {
      data.subscribe((entry) => {
        alert(entry);
        this.reRoute(this.login_route);
      });
    });
  }

  cancel() {
    //
    this.modalRef.hide();
  }

  reRoute(link: any) {
    this.router.navigate([link]);
  }
}
