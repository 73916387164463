import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})

export class DatePickerComponent implements OnInit {
  
  // pickedDate = "";
  
  pickedDate : Date = new Date(); 

  dateToNum = "";

  monthNames = ["January", "February", "March", "April", "May", "June",  
    "July", "August", "September", "October", "November", "December"];
  
  monthNumbers = ["01", "02", "03", "04", "05", "06",
    "07", "08", "09", "10", "11", "12"];

  @Output() datePickerEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  // getInputMonth(pick:any){
  //   console.log("this: " + this.monthNames[this.pickedDate.getMonth()]);
  //   this.inputMonth = this.monthNames[this.pickedDate.getMonth()];
  // }

  monthPicked(pick: any){
    
    this.datePickerEvent.emit(this.dateToNum = this.monthNames[this.pickedDate.getMonth()]);
       
    console.log("this: " + this.monthNames[this.pickedDate.getMonth()]);
  }

}
