import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-update-charter',
  templateUrl: './update-charter.component.html',
  styleUrls: ['./update-charter.component.scss']
})
export class UpdateCharterComponent implements OnInit {
  id = this.route.snapshot.params.id
  charter: any = "";
  loading: boolean = false;

  // phase 1 - sample form
  phase1form = new FormGroup({ //date
    organization_grouping_01: new FormControl('', Validators.required),
    organization_level_00: new FormControl('', Validators.required),
    organization_level_01: new FormControl('', Validators.required),
    organization_level_02: new FormControl(''),
    organization_level_03: new FormControl(''),
    psgc_location: new FormControl(''),
    uacs_location_code: new FormControl(''),
    uacs_org_description: new FormControl(''),
    uacs_organization_code: new FormControl(''),
    filename: new FormControl('', Validators.required),
    // date: new FormControl('', Validators.required),
    consolidated: new FormControl(''),
    agency_initials: new FormControl(''),
   });
   phase_01_filepath: any;


  constructor(private afs: AngularFirestore, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.loadDefault(this.id)
  }

  loadDefault(id: any){
    this.afs.doc(`citizens-charter-phase-01/${id}`).get().subscribe(
      (data: any) => {
        this.charter = data;

        let path = data.get('file_name');

        this.phase1form.patchValue({
          organization_grouping_01: data.get('organization_grouping_01'),
          organization_level_00: data.get('organization_level_00'),
          organization_level_01: data.get('organization_level_01'),
          organization_level_02: data.get('organization_level_02'),
          organization_level_03: data.get('organization_level_03'),
          psgc_location: data.get('psgc_location'),
          uacs_location_code: data.get('uacs_location_code'),
          uacs_org_description: data.get('uacs_org_description'),
          uacs_organization_code: data.get('uacs_organization_code'),
          filename: data.get('filename'),
          // date: data.get('date'),
          consolidated: data.get('consolidated'),
          agency_initials: data.get('agency_initials'),
        })
      });
  }

  async saveAndUpload() {
    this.loading = true;
    let documentID: any;
    
    this.afs.doc(`citizens-charter-phase-01/${this.route.snapshot.params.id}`).update(this.phase1form.value)
      .then((docRef:any) => {
      alert("Citizen Charter has been updated");
      
      // this.router.navigate(['/artemis/citizen-charter/view/'+ this.id]);
      this.goToCC()
      this.loading = false
    }).catch(error =>{
      this.loading = false
      alert(error.message)
    });
    
  }

  

  goToCC(){
    this.router.navigate(['/artemis/citizen-charter/view'],{
      queryParams:{
        cc_id: this.id
      }
    })
  }

}
