<h2 mat-dialog-title>Office Grouping</h2>
<mat-dialog-content class="mat-typography">
  

  <div class="">
      <div class="form-floating mb-3">
          <input [(ngModel)]="keyword" type="text" class="form-control" id="floatingInput3" placeholder="Search here..">
          <label for="floatingInput3">Search Office Name...</label>
        </div>
  
  
        <div>
          <div class="form-floating mb-3">
              <input required [(ngModel)]="groupName" type="text" class="form-control" id="z" placeholder="Group Name">
              <label for="z">Set Group Name</label>
            </div>
      
            <button (click)="createGroup()" class="btn btn-primary mb-2">Create Group</button>
          
        </div>
  
        <div *ngIf="officeSelected.length <= 0">
           Select Office/Division
        </div>
        <input (change)="changed($event)" class="form-check-input me-1 mb-1" type="checkbox" id="i" >
            <label class="form-check-label " for="i">
              Select All
            </label>
      <ul id="ultable" class="list-group">
          <li *ngFor="let office of AgencyOffices | search : keyword  | paginate: { id: 'upagination', itemsPerPage: 5, currentPage: p } ; let i = index" class="list-group-item">
            <input (change)="updatelist(office)" class="form-check-input me-1" type="checkbox" [(ngModel)]="office.selected" [id]="i">
            <label class="form-check-label stretched-link" [for]="i">
              {{office.name}} <br>
              <span><small>{{office.type}}</small></span>
            </label>
          </li>
        
        </ul>
  
        <pagination-controls id="upagination" (pageChange)="p = $event"></pagination-controls>

        <hr>
  
    
        <div class="form-floating mb-3">
          <input [(ngModel)]="keywords" type="text" class="form-control" id="x" placeholder="Search here..">
          <label for="x">Search Group Name...</label>
        </div>
  
        <ul class="list-group ">
          <li *ngFor="let office of groupedOffices | search : keywords ; let i = index" class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold">Group Name: {{office.group_name}}</div>
                  <ul>
                      <li *ngFor="let suboffice of office.offices">
                          {{suboffice.name}}
                          <br>
                          <span><small>{{suboffice.type}}</small></span>
                      </li>
                  </ul>
            </div>
            <span (click)="delete(office)" style="cursor: pointer;" class="badge text-bg-primary text-danger rounded-pill">delete</span>
          </li>
         
        </ul>
  
   
        
  </div>
  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>


