import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-productivity-tickets',
  templateUrl: './productivity-tickets.component.html',
  styleUrls: ['./productivity-tickets.component.scss']
})
export class ProductivityTicketsComponent implements OnInit {

  ticket_count: any =[];
  dateInfo: any;

  constructor(private afs: AngularFirestore, public auth: AngularFireAuth) { }

  ngOnInit(): void {    
    this.loadCounts();
  }
  
  receivedDatePicker(p:any){
    this.dateInfo = p;
  }

  async loadCounts() {
    this.ticket_count = [];
    this.afs.collection("Tickets").snapshotChanges()
      .pipe(
        tap(
          (data: any) => {
            data.forEach((info: any) => {
              let item: any = info.payload.doc.data();
              item.id = info.payload.doc.id;
              
              this.ticket_count.push(item);
              /**
               * load un-posted services for editing & approving
               */
            });
          }
        )
      )
      .subscribe()
  } 
}
