import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import {format } from 'date-fns';

@Component({
  selector: 'app-view-charter-public',
  templateUrl: './view-charter-public.component.html',
  styleUrls: ['./view-charter-public.component.scss']
})
export class ViewCharterPublicComponent implements OnInit {
  loader: boolean = true;

  //Current Agency
  doc_id = this.route.snapshot.params.id

  currentCC:any;
  currentAgency:any;

  charter_list:any;
 
  // Agency Information
  agencies:any=[];  
  services:any=[];  

  agency_details:any;
  agency_services:any;

  agency_name:any;
  agency_abbreviation:any;
  agency_address:any;
  agency_address_line:any;
  agency_barangay:any;
  agency_city:any;
  agency_province:any;
  agency_postal:any;
  agency_website:any;
  protocol:any;
  agency_category:any;
  agency_categories:any = []

  fileURL:any;

  //
  agency_head_list:any [] = []
  chairperson_list:any [] = []
  vice_person_list:any [] = []
  members_list:any [] = []


  //User limitations
  //


  style_theme: any = 'theme-default'; //current theme


  //input error message to types
  checkAgencyTitleForm = {
    agencyName:[
      { type: "required", message: "Agency Name is required" },
    ],
    agencyAbbreviation:[
      { type: "required", message: "Agency Abbreviation is required" },
    ],
    id:[
      { type: "required", message: "Agency Category is required"},
    ]
  }

  checkAgencyWebsiteForm = {
    website:[
      { type: "required", message: "Website URL is required" },
      { type: "pattern", message: "Website URL incorrect format" },
    ],
    protocol:[
      { type: "required", message: "Website Protocol is required"}
    ]
  }

  checkAgencyAddressForm= {
    addressLine:[
      { type: "required", message: "Address Line: House no / Building no. - Street name / Avenue name - Subdivision is required" },
    ],
    barangay:[
      { type: "required", message: "Barangay is required" },
    ],
    city:[
      { type: "required", message: "City / Minicipality is required" },
    ],
    province:[
      { type: "required", message: "Province - Region is required" },
    ],
    postal:[
      { type: "required", message: "Postal code / Zip code is required" },
    ],
  }

  constructor(
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private router: Router,
    private fb:FormBuilder,
    private auth: AngularFireAuth,) { }

  ngOnInit(): void {
    
    this.loadAgencyCategory()
    this.loadPageInformation(this.doc_id)
    // this.checkUser()
  }

  //page data loaders
  loadAgencyCategory(){
    this.afs.collection('Agency Category',filter=>filter.orderBy('name')).snapshotChanges()
    .pipe(first())
    .subscribe(info=>{
      let tempArr:any = []
      info.forEach(data=>{
        let agencyCategory:any = data.payload.doc.data()
        agencyCategory.id = data.payload.doc.id
        tempArr.push(agencyCategory)
      })
      this.agency_categories = tempArr
    })
  }

  async loadPageInformation(id: any){
    this.afs.collection('citizens-charter-phase-02').doc(id).snapshotChanges()
    .subscribe({
      next:(data) =>{
        this.loader = false;
        if(data.payload.exists){
          let item:any  = data.payload.data()
          //console.log('charter: ',item)
          this.agency_services=item.service_id
          // console.log('charter: ', item)
          // console.log('agency_services: ', this.agency_services)

          if(item.fromAgencyName) this.agency_name = item.fromAgencyName
          if(item.keywords.abbreviation) this.agency_abbreviation = item.keywords.abbreviation
          if(item.agencyCategory) this.agency_category = item.agencyCategory

          if(item.committee){
            if(item.committee.head_of_agency) this.agency_head_list = item.committee.head_of_agency
            if(item.committee.chairperson) this.chairperson_list = item.committee.chairperson
            if(item.committee.vice_chairperson) this.vice_person_list = item.committee.vice_chairperson
            if(item.committee.members) this.members_list = item.committee.members
          }

          if(item.reportURL)  this.fileURL = item.reportURL
          
          if(item.fromAgencyId){
            this.loadAgencyById(item.fromAgencyId)
            // this.afs.collection('Agency').doc(item.fromAgencyId).snapshotChanges()          
            // .pipe(first())
            // .subscribe(agency_data =>{
              
            //   let agency_info:any  = agency_data.payload.data()
            //   agency_info.id = item.fromAgencyId
  
            //   console.log('agency_info: ', agency_info)
              
            //   if(agency_info.agencyDetails != null){
            //     this.agency_name = agency_info.agencyDetails.agencyName ? agency_info.agencyDetails.agencyName : agency_info.name ? agency_info.name : "";
            //     this.agency_abbreviation = agency_info.agencyDetails.agencyName ? agency_info.agencyDetails.agencyAbbreviation : agency_info.abbreviation ? agency_info.abbreviation : ""
            //     this.agency_category = agency_info.agencyDetails.agencyCategory ? agency_info.agencyDetails.agencyCategory : "" ;
  
            //   }
            //   else{
            //     this.agency_name = agency_info.name ? agency_info.name : ""
            //     this.agency_abbreviation = ""
            //     this.agency_category = {
            //       id:"",
            //       abbreviation: "",
            //       name: ""
            //     }
            //   }        
      
            //   if(agency_info.addressDetails != null){
            //     this.agency_address_line = agency_info.addressDetails.addressLine ? agency_info.addressDetails.addressLine : "" ;
            //     this.agency_barangay = agency_info.addressDetails.barangay ? agency_info.addressDetails.barangay : "" ;
            //     this.agency_city = agency_info.addressDetails.city ? agency_info.addressDetails.city : "" ;
            //     this.agency_province = agency_info.addressDetails.province ? agency_info.addressDetails.province : "" ;
            //     this.agency_postal = agency_info.addressDetails.postal ? agency_info.addressDetails.postal : "" ;
      
            //     this.agency_address = this.agency_address_line 
            //     + " " + this.agency_barangay
            //     + " " + this.agency_city
            //     + " " + this.agency_province
            //     + "," + this.agency_postal;
            //   }
            //   else if(agency_info.address != null){
            //     this.agency_address_line =  "" ;
            //     this.agency_barangay =  "" ;
            //     this.agency_city =  "" ;
            //     this.agency_province =  "" ;
            //     this.agency_postal =  "" ;
            //     this.agency_address = item.address
            //   }
            //   else{
            //     this.agency_address_line =  "" ;
            //     this.agency_barangay =  "" ;
            //     this.agency_city =  "" ;
            //     this.agency_province =  "" ;
            //     this.agency_postal =  "" ;
            //     this.agency_address = "";
            //   }
            //   if(agency_info.agencyDetails != null && agency_info.agencyDetails.website  != null){
      
            //     this.agency_website = agency_info.agencyDetails.website ? agency_info.agencyDetails.website : "";
                
            //   }
            //   this.afs.collection('Agency').doc(agency_info.id).collection('Head of Agency', 
            //   filter => filter.orderBy("name")).snapshotChanges()
            //   .pipe(first())
            //   .subscribe(
            //   (data: any) => {
            //     data.forEach((agency_head_info: any) => {
            //       let agency_head_item: any = agency_head_info.payload.doc.data();
            //       agency_head_item.id = agency_head_info.payload.doc.id;
            //       // console.log('Head of Agency',item)
  
            //       const personJSON:any={
            //         name:agency_head_item.name,
            //         designation:agency_head_item.designation,
            //         email:agency_head_item.email,
            //         id:agency_head_item.id
            //       }
  
            //       agency_head_list.push(personJSON);
            //     });
            //   });
  
            //   this.afs.collection('Agency').doc(agency_info.id).collection('Chairperson', 
            //   filter => filter.orderBy("name")).snapshotChanges()
            //   .pipe(first())
            //   .subscribe(
            //   (data: any) => {
            //     data.forEach((chairperson_info: any) => {
            //       let chairperson_item: any = chairperson_info.payload.doc.data();
            //       chairperson_item.id = chairperson_info.payload.doc.id;
            //       // console.log('Chairperson',item)
  
            //       const personJSON:any={
            //         name:chairperson_item.name,
            //         designation:chairperson_item.designation,
            //         email:chairperson_item.email,
            //         id:chairperson_item.id
            //       }
  
            //       chairperson_list.push(personJSON);
            //     });
            //   });    
  
            //   this.afs.collection('Agency').doc(agency_info.id).collection('Vice Chairperson', 
            //   filter => filter.orderBy("name")).snapshotChanges()
            //   .pipe(first())
            //   .subscribe(
            //   (data: any) => {
            //     data.forEach((vice_person_info: any) => {
            //       let vice_person_item: any = vice_person_info.payload.doc.data();
            //       vice_person_item.id = vice_person_info.payload.doc.id;
  
            //       const personJSON:any={
            //         name:vice_person_item.name,
            //         designation:vice_person_item.designation,
            //         email:vice_person_item.email,
            //         id:vice_person_item.id
            //       }
  
            //       vice_person_list.push(personJSON);
            //     });
            //   });
  
            //   this.afs.collection('Agency').doc(agency_info.id).collection('Members', 
            //   filter => filter.orderBy("name")).snapshotChanges()
            //   .pipe(first())
            //   .subscribe(
            //   (data: any) => {
            //     data.forEach((members_info: any) => {
            //       let members_item: any = members_info.payload.doc.data();
            //       members_item.id = members_info.payload.doc.id;
            //       // console.log('Members',item)
            //       const personJSON:any={
            //         name:members_item.name,
            //         designation:members_item.designation,
            //         email:members_item.email,
            //         id:members_item.id
            //       }
  
            //       members_list.push(personJSON);
            //     });
            //   });
              
            //   let agencyDetailsJSON:any={
            //     agencyInfo: agency_info.agencyInfo,
            //     contact_details: agency_info.contactDetails,
            //     agencyDetails: agency_info.agencyDetails,
            //     agencyFeedBackMeasures:agency_info.feedbackMeasures,
            //     agencyCommittee:{
            //       agency_head: agency_head_list,
            //       chairperson: chairperson_list,
            //       vice_person: vice_person_list,
            //       members: members_list
                  
            //     }
                
            //   }
  
            //   this.agency_details = agencyDetailsJSON            
  
            //   // console.log('parent agency: ', agencyDetailsJSON)            
            // })   
          }
  
          if(item.service_id) this.loadServices(item.service_id)
  
          this.charter_list = item  
        }      
      },
      error:(err)=>{
        console.error('Error: ',err)
      }
    })
  }

  loadAgencyById(agencyId:any){
    this.afs.collection('Agency').doc(agencyId).snapshotChanges()
    .subscribe({
      next:(result)=>{
        if(result){
          if(result.payload.exists){
            let agency:any = result.payload.data()
            agency.id = result.payload.id
            //console.log('agency: ',agency)
            this.loadedAgency(agency)
          }
        }
      },
      error:(err)=>{
        console.error('Error: ',err)
      }
    })
  }

  loadedAgency(agency:any){
    this.currentAgency = agency
    if(agency.addressDetails != null){
      this.agency_address_line = agency.addressDetails.addressLine ? agency.addressDetails.addressLine : "" ;
      this.agency_barangay = agency.addressDetails.barangay ? agency.addressDetails.barangay : "" ;
      this.agency_city = agency.addressDetails.city ? agency.addressDetails.city : "" ;
      this.agency_province = agency.addressDetails.province ? agency.addressDetails.province : "" ;
      this.agency_postal = agency.addressDetails.postal ? agency.addressDetails.postal : "" ;

      this.agency_address = this.agency_address_line 
      + " " + this.agency_barangay
      + " " + this.agency_city
      + " " + this.agency_province
      + "," + this.agency_postal;
    }
    else{
      this.agency_address_line =  "" ;
      this.agency_barangay =  "" ;
      this.agency_city =  "" ;
      this.agency_province =  "" ;
      this.agency_postal =  "" ;
      this.agency_address = "";
    }
          
    let agencyDetailsJSON:any={
      agencyInfo: agency.agencyInfo,
      contact_details: agency.contactDetails,
      agencyDetails: agency.agencyDetails,
      agencyFeedBackMeasures:agency.feedbackMeasures,
      agencyCommittee:{
        agency_head: this.agency_head_list,
        chairperson: this.chairperson_list,
        vice_person: this.vice_person_list,
        members: this.members_list
      }
    }

    this.agency_details = agencyDetailsJSON 
  }
  
  loadServices(service_ids:any[]){
    service_ids.map((service_id:any)=>{
      this.afs.collection('Services Phase 02').doc(service_id).snapshotChanges()
      .pipe(first())
      .subscribe(service_data =>{
      
        let service_info:any  = service_data.payload.data()
        service_info.id = service_data.payload.id

        service_info.date_posted= service_info.date_posted? format(service_info.date_posted.toDate(), 'MM/dd/yyyy'): ''

        this.services.push(service_info)
        
      })
    })
  }


  getAgencyFullURL(){
    return this.agency_website;
  }
  // 

}
