<div class="container">
  <div class="py-4">
    <div class="form-group mb-3">
      <div class="text-primary fw-bold fs-1">
        Pinned Regulations
      </div>
      <hr class="my-0"/>
      <div>
        Find your Pinned Regulations here.
      </div>
    </div>
    <div class="form-group my-3">
      <div class="w-full my-2">
        <input type="text" class="form-control"/>
      </div>
      <div class="row col-12 col-lg-12">
        <div class="col-10 col-lg-10">
          <div class="row my-1">
            <div class="col d-grid">
              <button class="btn btn-outline-dark">Classification</button>
            </div>
            <div class="col d-grid">
              <button class="btn btn-outline-dark">Sector</button>
            </div>
            <div class="col d-grid">
              <button class="btn btn-outline-dark">Stage of Submission</button>
            </div>
            <div class="col d-grid">
              <button class="btn btn-outline-dark">Jurisdiction</button>
            </div>
          </div>
          <div class="row my-1">
            <div class="col d-grid">
              <button class="btn btn-outline-dark">Effectivity</button>
            </div>
            <div class="col d-grid">
              <button class="btn btn-outline-dark">Division</button>
            </div>
            <div class="col d-grid">
              <button class="btn btn-outline-dark">Case</button>
            </div>
            <div class="col d-grid">
              <button class="btn btn-outline-dark">Agency</button>
            </div>
          </div>
        </div>
        <div class="col-2 col-lg-2">
          <button class="btn btn-outline-dark d-grid">Test</button>
        </div>
      </div>
    </div>
    <div class="form-group my-3">
      <div class="m-3">
        <div class="row justify-content-center" *ngFor="let list of pinnedList; let i = index">
          <div class="card my-2 border" [class]="list.regulation_type == 'Existing' ? ' border-success' : ' border-danger'">
            <div class="card-body py-0">
              <div class="row col-12">
                <div class="col-2 text-center">
                  <img
                    *ngIf="list.regulation_type === 'Existing' "
                    src="/assets/Assets/Icons/Existing%20Regulation.png"
                    class="img-fluid"
                    alt="Existing Regulation"
                  />
                  <img
                    *ngIf="list.regulation_type === 'Proposed' "
                    src="/assets/Assets/Icons/Proposed%20Regulation.png"
                    class="img-fluid"
                    alt="Proposed Regulation"
                  />
                </div>
                <div class="col-9 align-self-center">
                  <h5 class="card-title"><b>{{ list.issuing_agency }}</b></h5>
                  <p class="card-text">{{ list.short_title }}</p>
                </div>
                <div class="col-1 align-self-center">
                  <span class="fs-4 text-primary icon card-pointer" (click)="onReg(list.id)" data-toggle="tooltip" title="Check the file">
                    <i class="fas fa-file"></i>
                  </span>
                  <span class="fs-4 text-danger icon card-pointer" (click)="onRemove(list)" data-toggle="tooltip" title="Remove regulation">
                    <i class="fas fa-trash-alt"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="loading" class="text-center">
        <div class="spinner-border text-primary my-5" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</div>