import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GovServiceService } from 'src/app/service/gov-service.service';

@Component({
  selector: 'app-artemis-disapprove-charter-reason-modal',
  templateUrl: './artemis-disapprove-charter-reason-modal.component.html',
  styleUrls: ['./artemis-disapprove-charter-reason-modal.component.scss']
})
export class ArtemisDisapproveCharterReasonModalComponent implements OnInit {

  reason_for_disapproval = new FormControl("", Validators.required);
  
  constructor(
    public dialogRef: MatDialogRef<ArtemisDisapproveCharterReasonModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private remarkService: GovServiceService
  ) {
    if (this.data.defaultReason) {
      this.reason_for_disapproval.patchValue(this.data.defaultReason);
    }
  }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    const returnServiceEntity = {
      submitFlag: true,
      submitValue: this.reason_for_disapproval.value
    };

    this.dialogRef.close(returnServiceEntity);
  }

}
