<app-navbar [currentPage]="'Survey'"></app-navbar>
<div class="page-header theme-registration mb-3 py-3">
  <div class="container">
    <div class="row">
      <div class="col-6 title-widget info-widget">
        <h5 class="fw-bold py-2">FEEDBACK</h5>
      </div>
      <div class="col-6 d-none d-md-block">
        <div class="float-end">
          <button type="button" class="btn btn-light btn-floating mx-1" style="width: auto; height: auto;" hidden>
            <a href="/pbris/regulations/existing" title="Existing Regulation">
              <img src="/assets/Assets/Icons/Existing%20Regulation.png" height="70" alt="Existing Regulation" />
            </a>
          </button>
          <button type="button" class="btn btn-light btn-floating mx-1" style="width: auto; height: auto;" hidden>
            <a href="/pbris/regulations/proposed" title="Proposed Regulation">
              <img src="/assets/Assets/Icons/Proposed%20Regulation.png" height="70" alt="Proposed Regulation" />
            </a>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container py-2">
  <form [formGroup]="phase1form" autocomplete="off">
    <div class="mt-2">
      <h5 class="fw-bold">
        HOW WAS YOUR EXPERIENCE?
      </h5>
    </div>

    <div class="py-3">
      <p>Thank you for using the PBRIS! We are constantly working to improve our services. Please help us by sharing your overall experience in using the site. 
      Your feedback will be anonymous and be processed in accordance with the Data Privacy Act of 2012.</p>
    </div>

    <!-- DROPDOWN -->
    <div class="row form-group">
      <input type="hidden" formControlName="application" name="application">
      <div class="col-md-6 col-sm-12 mb-1">
        <h6 class="mb-2 fw-bold form-label" for="feature_accessed">
          Feature Accessed
        </h6>
        <div class="dropdown">
          <select class="form-select"  aria-label="Default select example" formControlName="feature">
            <option selected></option>
            <option *ngFor="let access of featureAccessList" [ngValue]="access.access.access">{{ access.access.access }}</option>
          </select>
        </div>
      </div>

      <div class="col-md-6 col-sm-12 mb-1">
        <h6 class="mb-2 fw-bold form-label" for="discoverFormControlTextarea1">
          How did you discover PBRIS?
        </h6>
        <div class="dropdown" id="discoverFormControlTextarea1">
          <select class="form-select" aria-label="Default select example" formControlName="discover" name="discover" (change)="discoverOnChange($event)">
            <option selected></option>
            <option value="Friends">Friends</option>
            <option value="Internet">Internet</option>
            <option value="Newspaper">Newspaper</option>
            <option value="Others">Others</option>
          </select>
        </div>
        <div class="mt-2" *ngIf="others">
          <textarea class="form-control col-12" formControlName="otherDiscover" id="elaborateFormControlTextarea1" rows="1" placeholder="Others"></textarea>
        </div>
      </div>
    </div>

    <!-- RATINGS -->
    <div class="form-group my-5 p-3" style="background:rgba(239, 239, 239, .5);">
      <div class="row px-3 py-2">
        <div class="col-md-3 col-sm-12 d-flex align-items-center">
          <label class="form-label fw-bold mb-0" for="overall_field">
            Please rate your overall experience:
          </label>
        </div>
        <div class="col-md-2 col-sm-12 d-flex align-items-left p-1">
          <fieldset class="rating ms-3" for="overall" id="overall_field">
            <input type="radio" id="star5" formControlName="overall" name="overall" value="Excellent" (change)="starOnChange($event)"/><label for="star5" title="Excellent">5 stars</label>
            <input type="radio" id="star4" formControlName="overall" name="overall" value="Very satisfy" (change)="starOnChange($event)"/><label for="star4" title="Very satisfy">4 stars</label>
            <input type="radio" id="star3" formControlName="overall" name="overall" value="Satisfy" (change)="starOnChange($event)"/><label for="star3" title="Satisfy">3 stars</label>
            <input type="radio" id="star2" formControlName="overall" name="overall" value="Unsatisfactory" (change)="starOnChange($event)"/><label for="star2" title="Unsatisfactory">2 stars</label>
            <input type="radio" id="star1" formControlName="overall" name="overall" value="Poor" (change)="starOnChange($event)"/><label for="star1" title="Poor">1 star</label>
          </fieldset>
        </div>

        <div class="col-md-7 col-sm-12 d-flex align-items-center font-italic text-muted p-1">
          {{ overall_rate }}
        </div>
      </div>

      <div class="row px-3 py-2">
        <div class="col-md-4 col-sm-12 d-flex align-items-center">
          <label for="improveFormControlTextarea1" class="form-label fw-bold mb-0">
            How likely are you to visit the site again?
          </label>
        </div>
        <div class="col-md-2 col-sm-12 d-flex align-items-left p-1">
          <fieldset class="rating ms-3">
            <input type="radio" id="star55" name="visit" formControlName="visit" (change)="starOnChange($event)" value="Excellent" /><label for="star55" title="Excellent">% stars</label>
            <input type="radio" id="star44" name="visit" formControlName="visit" (change)="starOnChange($event)" value="Very satisfy" /><label for="star44" title="Very satisfy">4 stars</label>
            <input type="radio" id="star33" name="visit" formControlName="visit" (change)="starOnChange($event)" value="Satisfy" /><label for="star33" title="Satisfy">3 stars</label>
            <input type="radio" id="star22" name="visit" formControlName="visit" (change)="starOnChange($event)" value="Very good" /><label for="star22" title="Very good">2 stars</label>
            <input type="radio" id="star11" name="visit" formControlName="visit" (change)="starOnChange($event)" value="Good" /><label for="star11" title="Good">1 star</label>
          </fieldset>
        </div>
        <div class="col-md-6 col-sm-12 d-flex align-items-center font-italic text-muted p-1">
          {{ visit_site }}
        </div>
      </div>

      <div class="row px-3 pt-2 pb-3">
        <div class="col-md-5 col-sm-12 d-flex align-items-center p-1">
          <label for="improveFormControlTextarea1" class="form-label fw-bold mb-0">
            How likely are you going to refer the PBRIS to a friend?
          </label>
        </div>
        <div class="col-md-2 col-sm-12 d-flex align-items-left">
          <fieldset class="rating ms-3">
            <input type="radio" id="star555" name="refer" formControlName="refer" (change)="starOnChange($event)" value="Excellent" /><label for="star555" title="Excellent">5 stars</label>
            <input type="radio" id="star444" name="refer" formControlName="refer" (change)="starOnChange($event)" value="Very satisfy" /><label for="star444" title="Very satisfy">4 stars</label>
            <input type="radio" id="star222" name="refer" formControlName="refer" (change)="starOnChange($event)" value="Satisfy" /><label for="star222" title="Satisfy">2 stars</label>
            <input type="radio" id="star333" name="refer" formControlName="refer" (change)="starOnChange($event)" value="Very good" /><label for="star333" title="Very good">3 stars</label>
            <input type="radio" id="star111" name="refer" formControlName="refer" (change)="starOnChange($event)" value="Good" /><label for="star111" title="Good">1 star</label>
          </fieldset>
        </div>
        <div class="col-md-5 col-sm-12 d-flex align-items-center font-italic text-muted p-1">
          {{ refer_site }}
        </div>
      </div>
    </div>

    <!-- TEXTAREA -->
    <div class="form-group mb-5">
      <div class="row" *ngIf="commentView == 1">
        <div class="col-12 mb-3">
          <label for="elaborateFormControlTextarea1" class="form-label mb-2 font-weight-bold">
            Please elaborate on your experience
          </label>
        </div>
        <div class="col-12">
          <textarea class="form-control col-12" formControlName="elaborate" id="elaborateFormControlTextarea1" rows="3" placeholder="Comments"></textarea>
        </div>
      </div>
      
      <div class="row" *ngIf="commentView == 2">
        <div class="col-12 mb-3">
          <label for="improveFormControlTextarea1" class="form-label mb-2 font-weight-bold">
            My experience was satisfactory but could be further improved by:
          </label>
        </div>
        <div class="col-12">
          <textarea class="form-control col-12" formControlName="improve" id="improveFormControlTextarea1" rows="3" placeholder="Comments"></textarea>
        </div>
      </div>

      <div class="row" *ngIf="commentView == 3">
        <div class="col-12 mb-3">
          <label class="form-check-label font-weight-bold text-muted" for="list">
            My experience was poor/unsatisfactory as the PBRIS
          </label>
        </div>

        <div class="row p-2" id="list">
          <div class="col-md-6 col-sm-12">
            <div class="form-check">
              <input class="form-check-input" type="radio" formControlName="experience" name="experience" id="test2" value="Did not have the information I need">
              <label class="form-check-label font-weight-bold text-muted mx-2" for="test2">
                Did not have the information I need
              </label>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="form-check">
              <input class="form-check-input" type="radio" formControlName="experience" name="experience" id="test3" value="Navigation was too confusing">
              <label class="form-check-label font-weight-bold text-muted mx-2" for="test3">
                Navigation was too confusing
              </label>
            </div>
          </div>
        </div>

        <div class="row p-2">
          <div class="col-md-6 col-sm-12">
            <div class="form-check">
              <input class="form-check-input" type="radio" formControlName="experience" name="experience" id="test4" value="Had too much information">
              <label class="form-check-label font-weight-bold text-muted mx-2" for="test4">
                Had too much information
              </label>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="form-check">
              <input class="form-check-input" type="radio" formControlName="experience" name="experience" id="test5" value="Service/s is/are out of date">
              <label class="form-check-label font-weight-bold text-muted mx-2" for="test5">
                Service/s is/are out of date
              </label>
            </div>
          </div>
        </div>

        <div class="row p-2">
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input" type="radio" formControlName="experience" name="experience" id="test6" value="Other">
              <label class="form-check-label font-weight-bold text-muted mx-2" for="test6">
                Other
              </label>
              <div class="col-12 mt-3">
                <textarea class="form-control col-12" formControlName="others" id="elaborateFormControlTextarea1" rows="3" placeholder="Comments"></textarea>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>

    <div class="form-group">
      <div class="row mb-3">
        <div class="col-12">
          <label for="improveFormControlTextarea1" class="form-label mb-2 font-weight-bold">
            My experience could be further improved by:
          </label>
        </div>
        <div class="col-12">
          <textarea class="form-control col-12" formControlName="enhance" id="improveFormControlTextarea1" rows="3" placeholder="Comments"></textarea>
        </div>
      </div>
    </div>

    <div class="my-5 text-center">
      <button type="button" class="btn btn-primary btn-rounded col-md-4 col-sm-12 mb-5 p-3" (click)="onSubmit()" [disabled]="loading">
        <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>
        <h6 class="fw-bold mb-0"><span *ngIf="loading == false">Submit</span></h6>
      </button>
    </div>
    
  </form>
</div>