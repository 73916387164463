import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators, NgForm, FormsModule } from '@angular/forms';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ModalManagerComponent } from 'src/app/modal-manager/modal-manager.component'

@Component({
  selector: 'app-crud-table',
  templateUrl: './crud-table.component.html',
  styleUrls: ['./crud-table.component.scss']
})
export class CrudTableComponent implements OnInit {
  modalRef?: MDBModalRef;
  objectKeys = Object.keys;

  // app parameter
  @Input() firebaseCollection: any;

  // table row
  list: any = [];
  columns: any = [];
  currentID: string = '';
  currentIDstate: string = '';

  constructor(private auth: AngularFireAuth, private afs: AngularFirestore, private router: Router, public modalService: MDBModalService) { }

  ngOnInit(): void {
    this.list = [];
    this.afs.collection(this.firebaseCollection).snapshotChanges().subscribe( //, filter => filter.orderBy(orderByColumn)
      (data: any) => {
          data.forEach((info: any) => {
            
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.list.includes(item) === false){
              this.list.push(item);
            }     
          });
      });
      this.columns = this.objectKeys(this.list);
  }

  removeID(StringWid: string[]){
    StringWid.forEach((value,index)=>{
      if(value=='id') StringWid.splice(index,1);
    });
    return StringWid;
  }


  openViewModal() {
    //
  }

  /**
   * 
   * CRUD triggers
   */
  triggerAddRow(columnKeys?:any){
    this.currentIDstate = 'add';
/*
    if(columnKeys){
      this.modalRef = this.modalService.show(
        ModalManagerComponent,
        { backdrop: 'static',
          data:{
            header_content: {
              title: "Add new "
            },
            body_content: {
              form_keys: columnKeys
            },
            buttons: {
              confirm: "Add to list",
              cancel: "Cancel"
            },
            firestore_collection: this.firebaseCollection
          }
        });
    }
    */
  }
  triggerUpdateRow(id: any){
    this.currentID = id;
    this.currentIDstate = 'update';
  }
  triggerDeleteRow(id: any){
    this.currentID = id;
    this.currentIDstate = 'delete';
  }
  cancelCRUD(){
    this.currentID = '';
    this.currentIDstate = '';
  }

  
  dbUpdate(form: NgForm){
    //remove id from form only
    delete form.value['id'];
    //if no id, then add, if id is available, then update
    if(this.currentID == ''){
      this.afs.collection(`${this.firebaseCollection}`).add(form.value).then(() => {
        alert("create successful");
      }).catch(error =>{
        alert(error.message)
      });
    }
    else {
      this.afs.doc(`${this.firebaseCollection}/${this.currentID}`).update(form.value).then(() => {
        alert("update successful")
      }).catch(error =>{
        alert(error.message)
      });
    }
    //
    this.currentID = '';
    this.currentIDstate = '';
  }
  dbDelete(){
    this.afs.doc(`${this.firebaseCollection}/${this.currentID}`).delete().then((data => {
      alert("delete successful");
    }));
    //
    this.currentID = '';
    this.currentIDstate = '';
  }

}
