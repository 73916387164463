import { first } from 'rxjs/operators';
import { element } from 'protractor';
import { formatDate } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl, FormGroup, ControlValueAccessor } from '@angular/forms';
import { GovernmentAccountSubtype, RegulationCommentStatus } from 'src/app/entities/worklist';
import {format, isThursday } from 'date-fns';

import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';

import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';


import Swal from 'sweetalert2'
import { NotificationsEmailer } from 'src/app/service/notifications-emailer.service';
import { combineLatest } from 'rxjs';

export interface Consultation {
  rowId?: string;
  type: string;
  cris_id: string;
  regulation_id: string;
  link: string;
  purpose: string;
  stakeholder: string;
  to_date: string;
  from_date: string;
  consultation_id?: string;
  formated_to_date?: string;
  formated_from_date?: string;
  user_agency?: string;
  reg_agency:any[];
  regulation_title?: string;
  regulation_subj?: string;
}

@Component({ 
  selector: 'app-pbris-consultation-view',
  templateUrl: './pbris-consultation-view.component.html',
  styleUrls: ['./pbris-consultation-view.component.scss'],
  animations: [
    // bugs out /delays sidenav-content width
    trigger('collapsed',[
      state('open', style({width: '400px'})),
      state('close', style({width: '70px'})),
      // state('auto1', style({width: '100%'})),
      // state('auto2', style({width: '100%'})),
      state('auto1', style({width: '*'})),
      state('auto2', style({width: '*'})),
      // transition('open => close',[
      //   animate('0.2s ease')
      // ]),
      // transition('close => open',[
      //   animate('0.2s ease')
      // ]),
      // transition('auto1 => auto2',[
      //   animate('0.2s ease')
      // ]),
      // transition('auto2 => auto1',[
      //   animate('0.2s ease')
      // ]),
    ])
  ],
})
export class PbrisConsultationsViewComponent implements OnInit {
  
  style_theme: any = 'theme-default';
  sample_parg='In the interest of service and pursuant to Presidential Proclamation No. 922, series of 2020 dated 8 March 2020 Declaring a State of Public Health Emergency Throughout the Philippines due to Corona Virus Disease 2019 (COVID-19), Memorandum from the Office of the Executive Secretary dated 16 March 2020 on the subject Community Quarantine over the Entire Luzon and Further Guidelines on the Management of COVID-19 Situation, and the Civil Service Commission Announcement No.13, s. 2020 dated 17 March 2020 on the Alternative Work Arrangements in light of the Enhanced Community Quarantine, the Anti-Red Tape Authority, in compliance with these issuances, informs all covered government agencies that'

  //NEW
  consultations: any = "";  
  regulation_info: any = "";  

  loading: boolean = true;

  collapse = false;
  inboxExpander = false;
  ticketExpander = false
  
  regulatory_inbox_view:boolean = false;
  sub_inbox:boolean = false;
  sub_inbox_existing:boolean = false;
  sub_inbox_proposed:boolean = false;  

  notification_view:boolean = false;

  ticket_root:boolean = false;
  sel_ticket:boolean = false;
  create_ticket: boolean = false;
  regulation_proposed_notification_view: boolean = false;
  feedback_form: boolean = false;
  feedback_list: boolean = false;
  comment_list: boolean = false;


  // global settings
  isLoggedIn: boolean = false;
  userType: any = sessionStorage.getItem("user_type");
  userSubtype: any = sessionStorage.getItem("account_subtype");
  user:any = {};
  userLoading:boolean = true

  
  // user info
  currentUser: any;
  userAgency: any;
  user_id: any;
  user_info: any = [];
  isAdmin: any = false

  //OLD

  // @Input() isAdmin: boolean = false;
  // @Input() user_id: any = '';
  // @Input() consultation_id: any = '';

  commentList: any = [];
  //Modal
  // url: any;
  // comment: any;
  // document: any;
  // type: any;
  // id: any;
  // reply: any;
  // files: any = [];
  // fetching: boolean = false;

  // form = new FormGroup({
  //   reply: new FormControl(''),
  // });
 
  constructor(
    private afs: AngularFirestore,
    private store: AngularFireStorage,
    private nE: NotificationsEmailer,
    public auth: AngularFireAuth, 
    private router: Router,
    private route: ActivatedRoute,
  ) {}
 
  ngOnInit(): void {
    this.checkSessionVariables();
    this.setTheme();
    this.loadConsultation(this.route.snapshot.params.id);
    // this.refreshTable(this.isAdmin, this.consultation_id,this.user_id);
  }

  async setTheme() {
    switch(sessionStorage.getItem("user_type")){
      case 'arta':
        this.style_theme = 'theme-arta';
        this.isLoggedIn = true;
        break;
      case 'agency':
        this.style_theme = 'theme-agency';
        this.isLoggedIn = true;
        break;
      case 'personal':
        this.style_theme = 'theme-default';
        this.isLoggedIn = true;
        break;
      default:
        this.style_theme = 'theme-default';
      }
  }

  loadConsultation(id:any){
    this.afs.collection('Public Consultations').doc(id).get()
    .subscribe((info:any)=>{
      let consultation:any=info.data();
      consultation.consultation_id = info.id;  
      
      this.afs.collection('regulations-proposed-cris').doc(consultation.cris_id).snapshotChanges()
        .pipe(first())
        .subscribe(result=>{
          console.log('hit')
          let regProposedCris: any =result.payload.data();
          consultation.regulation_cris_id = result.payload.id;
          consultation.regulation_title = regProposedCris.title;
          consultation.regulation_subj = regProposedCris.subject;
          consultation.reg_agency = regProposedCris.reg_agency;
          consultation.reg_classification=regProposedCris.reg_classification;
          // consultation.consultation_id = info.payload.doc.id;
          consultation.to_date_Date = consultation.to_date.toDate();
          consultation.from_date_Date = consultation.from_date.toDate();
          consultation.formated_to_date = format(consultation.to_date.toDate(), 'MM/dd/yyyy');
          consultation.formated_from_date = format(consultation.from_date.toDate(), 'MM/dd/yyyy');
          consultation.due_to_date=format(consultation.to_date.toDate(), 'yyyy/MM/dd h:mm:ss a');

          // console.log('reg_agency: ',regProposedCris.reg_agency)
          if(regProposedCris.reg_agency){
            if(Array.isArray(regProposedCris.reg_agency)){
              let batch:any = []
              regProposedCris.reg_agency.map((agencyId:string)=>{
                batch.push(this.afs.collection('Agency').doc(agencyId).snapshotChanges())
              })
              combineLatest(batch).subscribe({
                next:(result1)=>{
                  let agencyNames = result1.map((result2:any)=>{
                    if(result2.payload.exists){
                      let agency:any = result2.payload.data()
                      return agency.name
                    }
                  })
                  // console.log('agnecy names: ',agencyNames)
                  const delimiter = ', '
                  consultation.agency_name = agencyNames.join(delimiter)
                },  
                error:(err)=>{
                  console.error('Error')
                  // console.error('Error: ',err)
                }
              })
            }else{
              this.afs.collection('Agency').doc(regProposedCris.reg_agency).snapshotChanges()
              .subscribe(result=>{
                if(result.payload.exists){
                  let agency: any = result.payload.data()
                  consultation.agency_name=agency.name
                }
              })
            }
          }
          // work on this
          // this.afs.collection('Agency').doc(regProposedCris.reg_agency).snapshotChanges()
          //   .pipe(first())
          //   .subscribe(result=>{
          //     let agency: any = result.payload.data()
          //     consultation.agency_name=agency.name
          //   })

          if(regProposedCris.reg_sector){
            this.afs.collection('Sector').doc(regProposedCris.reg_sector).snapshotChanges()
            .pipe(first())
            .subscribe(result=>{
              let regSector: any = result.payload.data()
              consultation.life_status=regSector.name
            })
          }

          if(regProposedCris.reg_stageoflife){
            
            this.afs.collection('Stage of Life').doc(regProposedCris.reg_stageoflife).snapshotChanges()
            .pipe(first())
            .subscribe(result=>{
              let regStage: any = result.payload.data()
              consultation.life_status=regStage.name
            })
          }

          this.afs.collection('regulations-proposed-phase-02').doc(consultation.regulation_id).snapshotChanges()
            .pipe(first())
            .subscribe(result=>{
              this.loading=false;
              let regulation: any = result.payload.data()
              regulation.id = result.payload.id
              // console.log('regulation: ',regulation)
              // console.log('reg_notif_form: ',regulation.reg_notif_form)
              if(regulation.doc_date) consultation.doc_date = regulation.doc_date
              else if(regulation.reg_notif_form) consultation.doc_date = regulation.reg_notif_form.doc_date
              else consultation.doc_date = 'NOT AVAILABLE'
              
              consultation.significance=regulation.reg_significance
              // consultation.life_status=regStage.name

              this.afs.collection('Sector').doc(regulation.tags.reg_sector).snapshotChanges()
              .pipe(first())
              .subscribe(result3=>{
                let sector: any = result3.payload.data()
                // consultation.life_status=regStage.name
                consultation.sector_name=sector.name
              })         

              this.afs.collection('Case Use').doc(regulation.tags.reg_case).snapshotChanges()
              .pipe(first())
              .subscribe(result=>{
                let case_use: any = result.payload.data()
                // consultation.life_status=regStage.name
                consultation.case_name=case_use.name
              })        

              this.afs.collection('Jurisdiction').doc(regulation.tags.reg_juris).snapshotChanges()
              .pipe(first())
              .subscribe(result=>{
                let jury: any = result.payload.data()
                // consultation.life_status=regStage.name
                consultation.jury_name=jury.name
              }) 

              if(regulation.tags){
                if(regulation.tags.date_published_onar){
                  consultation.date_published_onar = regulation.tags.date_published_onar
                }else{                  
                  consultation.date_published_onar = 'NOT AVAILABLE'
                }
                
                if(regulation.tags.date_published_gazette){
                  consultation.date_published_gazette = regulation.tags.date_published_gazette
                }else{                  
                  consultation.date_published_gazette = 'NOT AVAILABLE'
                }
                
                if(regulation.tags.date_effective){
                  consultation.date_effective = regulation.tags.date_effective
                }else{                  
                  consultation.date_effective = 'NOT AVAILABLE'
                }
                
                if(!regulation.tags.date_repeal || regulation.tags.date_repeal == ''){
                  consultation.date_repeal = 'NOT AVAILABLE'
                }else{      
                  consultation.date_repeal = regulation.tags.date_repeal            
                }
                
                if(regulation.tags.ammended){
                  consultation.ammended = regulation.tags.ammended
                }else{                  
                  consultation.ammended = 'NOT AVAILABLE'
                }
              }
              
              // console.log('regulation: ', this.regulation_info)

          })

          // if(this.userAgency)
          //   consultation.user_agency = this.userAgency
          // tempArr.push(consultation as Consultation)
          // this.consultations = tempArr;     
          

          // console.log('regProposedCris: ',regProposedCris)    
          
          this.consultations = consultation
          
          // console.log('result: ', this.consultations)

        })
    })  
    
  }

  getDueDays(dateSent:any){
    // console.log('dateSent', dateSent);
    let currentDate = new Date();
    dateSent = new Date(dateSent);

    let date_due =Math.floor(
      (Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      ) -
        Date.UTC(
          dateSent.getFullYear(),
          dateSent.getMonth(),
          dateSent.getDate()
        )) /
        (1000 * 60 * 60 * 24)
    );

    if(currentDate>dateSent){
      return '-'+date_due
    }else{ 
      return date_due
    }     
    

   
  }

  test(id:any){
    this.afs.collection('Public Consultations', filter => filter.orderBy('to_date','desc')).snapshotChanges()
    .pipe(first())
    .subscribe(data=>{
      let cleanArr:any = []
      let tempArr:Consultation[] = []
      let rowId: number = 1; 
      data.forEach(info=>{
        let consultation: any = info.payload.doc.data() as Consultation;
        let consultationClean: any = info.payload.doc.data();
      
        consultationClean.consultation_id = info.payload.doc.id;
        consultationClean.short_title = consultationClean.purpose;
        consultationClean.regulation_type = "Proposed Regulation";
        if(this.userAgency)
          consultationClean.user_agency = this.userAgency
        cleanArr.push(consultationClean)

        this.afs.collection('regulations-proposed-cris').doc(consultation.cris_id).snapshotChanges()
        .pipe(first())
        .subscribe(result=>{

          let regProposedCris: any =result.payload.data()
          consultation.regulation_cris_id = regProposedCris.id
          consultation.regulation_title = regProposedCris.title
          consultation.regulation_subj = regProposedCris.subject
          consultation.reg_agency = regProposedCris.reg_agency;
          consultation.consultation_id = info.payload.doc.id;
          consultation.to_date_Date = consultation.to_date.toDate();
          consultation.from_date_Date = consultation.from_date.toDate();
          consultation.formated_to_date = format(consultation.to_date.toDate(), 'MM/dd/yyyy');
          consultation.formated_from_date = format(consultation.from_date.toDate(), 'MM/dd/yyyy');

          if(regProposedCris.reg_sector){
            this.afs.collection('Sector').doc(regProposedCris.reg_sector).snapshotChanges()
            .pipe(first())
            .subscribe(result1=>{
              let regSector: any = result1.payload.data()
              consultation.life_status=regSector.name
            })
          }

          if(regProposedCris.reg_stageoflife){
            
            this.afs.collection('Stage of Life').doc(regProposedCris.reg_stageoflife).snapshotChanges()
            .pipe(first())
            .subscribe(result1=>{
              let regStage: any = result1.payload.data()
              consultation.life_status=regStage.name
            })
          }

          consultation.rowId = rowId++;
          
          console.log('result: ', regProposedCris)

          // if(this.userAgency)
          //   consultation.user_agency = this.userAgency
          // tempArr.push(consultation as Consultation)
          // this.consultations = tempArr;          

        })

        // consultation.consultation_id = info.payload.doc.id;
        // consultation.formated_to_date = format(consultation.to_date.toDate(), 'MM/dd/yyyy');
        // consultation.formated_from_date = format(consultation.from_date.toDate(), 'MM/dd/yyyy');
        // consultation.rowId = rowId++;
        // // consultation.type_title = consultation.type;
        // tempArr.push(consultation as Consultation)
      })
      // this.consultations = tempArr;
      // this.consultationTabledataSource = new MatTableDataSource<Consultation>(this.consultations)
      // // this.settingFilter()
      
      
    })
  }

  async checkSessionVariables(){
    /**
     * checks if user is logged in
     */
    if(await this.auth.user){
      
      if(sessionStorage.getItem("user_type")) {
        this.setTheme();
      }
      else {
        /**
         * for reloading user types {default, agency, arta}
         */
        this.auth.onAuthStateChanged(user => {
          if(user?.uid){
            // alert(user?.uid);
            // set logged in status
            this.isLoggedIn = true;
            // get account type from firestore
            this.afs.doc(`Users/${user.uid}`).snapshotChanges().subscribe(
              (data: any) => {
                  let item = data.payload.data()
        
                  if(item.credentials.account_subtype.includes("ARTA")) {
                    sessionStorage.setItem("user_type","arta");

                    switch(item.credentials.account_subtype){
                      case GovernmentAccountSubtype.ARTA_ADMIN:{
                        sessionStorage.setItem("arta_admin", "true");
                        break;
                      }
                      case GovernmentAccountSubtype.AGENCY_ADMIN:{
                        sessionStorage.setItem("agency_admin", "true");
                        break;
                      }
                    }
                    // if(item.credentials.account_subtype.toLowerCase().includes("admin")) {
                    //   sessionStorage.setItem("arta_admin", "true");
                    // }
                  }
                  else if(item.credentials.account_type.includes("Government") || item.credentials.account_type.includes("Company")) {
                    sessionStorage.setItem("user_type","agency");
                    switch(item.credentials.account_subtype){
                      case GovernmentAccountSubtype.AGENCY_ADMIN:{
                        sessionStorage.setItem("agency_admin", "true");
                        break;
                      }
                    }
                  }
                  else {
                    sessionStorage.setItem("user_type","personal");
                  }
                  this.setTheme();
                  window.location.reload();
              });
          }
          else {
            sessionStorage.setItem("user_type","default");
          }
        });
      }
      //
    }
  }
  

  getConsultation(id:any){
    return new Promise((res,rej)=>{
      this.afs.collection('Public Consultations').doc(id).snapshotChanges()
      .pipe(first())
      .subscribe({
        next:(result)=>{
          let consultation:any = result.payload.data()
          console.log('consultation',consultation)
          return res(consultation)
        },
        error: (err)=>{
          return res(false)
        }
      })
    })
  }

  getUser(uid:any){
    return new Promise((res,rej)=>{
      this.afs.collection('Users').doc(uid).snapshotChanges()
      .subscribe({
        next:(result)=>{
          let userTemp = result.payload.data()
          return res(userTemp)
        },
        error:(err)=>{
          return res(false)
        }
      })
    })
  }

  getAgency(aid:any){
    return new Promise((res,rej)=>{
      this.afs.collection('Agency').doc(aid).snapshotChanges()
      .subscribe({
        next:(result)=>{
          let agency:any = result.payload.data()
          return res(agency)
        },
        error:(err)=>{
          return res(false)
        }
      })
    })
  }

  getAcronym(str:any){
    let matches = str.match(/\b(\w)/g);
    let acronym = matches.join('');
    return acronym;
  }
  
  toggleSideNav(){
    this.collapse = !this.collapse
  }  

  toggleExpander(value:any){
    if(value == 'inbox'){
      this.inboxExpander = !this.inboxExpander
    }
    else if(value == 'ticket'){
      this.ticketExpander = !this.ticketExpander
    }
  }

  
  async codeToSendForNotifEmail(commentId:any){
    let templateCode: number = 0
    const commentCtr = this.commentList.map((res:any)=>res.id).indexOf(commentId)
    if(commentCtr >= 0){
      const currentComment:any = this.commentList[commentCtr]
      const commentUID = currentComment.user.user_id
      const consultationId = currentComment.info.consultation_id
      const currentConsultation:any = await this.getConsultation(consultationId)
      const commentUser:any = await this.getUser(commentUID)
      if(commentUser && currentConsultation){
        if(commentUser.credentials.account_subtype == 'Personal'
          || commentUser.credentials.account_type == 'Company'
          || commentUser.credentials.account_type == 'Government') templateCode = 6
        const agency: any = await this.getAgency(currentComment.info.user_agency)
        if(agency){
          // if(commentUser.settings && commentUser.settings.consultations.create){
          if(templateCode > 0){
            let data = {
              consultationTitle: currentConsultation.purpose,
              consultationTrackNo: '',
              agencyName: agency.agencyDetails ? agency.agencyDetails.agencyName ? agency.agencyDetails.agencyName : agency.name : agency.name,
              feedbackLink: 'https://arta-respond.web.app/', //change
              commentsLink: 'https://arta-respond.web.app/', //change
              accountLink: 'https://arta-respond.web.app/', //change
            }
            this.toSendNotifEmail([commentUser.credentials.email],templateCode,data)
          } else console.error('PbrisConsultationsViewComponent emailer error: no email template for account type')
        // } else console.warn('user subscribed to consultations is not set to true')
        } else console.warn('agency not found')
      } else console.warn('user/consultation of comment not found')
    }else console.warn('comment not in list')
  }

  
  toSendNotifEmail(currentUsers:any[], templateCode:number,data:any){
    console.log('sending email: ',currentUsers, templateCode)
    if(templateCode > 0){
      // this.nE.sendEmailNotiftoUser(currentUsers,1,templateCode,data)
      // .subscribe({
      //     next:(apiResponse)=>{
      //         console.log('PbrisConsultationsViewComponent emailer: ',apiResponse)
      //     },
      //     error:(err)=>{
      //         console.error('PbrisConsultationsViewComponent emailer error: ',err)
      //     }
      // })
    } else console.error('PbrisConsultationsViewComponent emailer error: no email template for accoun tyype')
  }

  toRegulation(regulation_details:any){
    console.log('regulation_id: ', regulation_details)
    
    let from = "proposed"
    this.router.navigate(['/pbris/regulations/view/',from,regulation_details]);        
  }



  //OLD STUFFS

  // public refreshTable(user_type:any, consId:any,  uId:any, aIds:any[]){ //admin
  // this.commentList = []
  // // public refreshTable(admin:any,consId:any, useId:any, agenId:any, regAgenId:any){ //admin
  // console.log("refreshtable",{user_type},{consId},{uId},{aIds})
  // if(user_type === 'admin'){
  //     this.afs.collection('Comment PBRIS', filter => filter.orderBy('created_at','desc')).ref
  //     .where('info.consultation_id','==',consId)
  //     .get()
  //     .then(data=>{
  //       let tempArr:any = []
  //       data.forEach(e=>{
  //         let comment:any = e.data() 
  //         comment.id = e.id
  //         tempArr.push(comment)
  //       })
  //       console.log("admin comments",{tempArr})
  //       this.commentList = tempArr;
  //     })
  //   }
  //   else if(user_type === 'agency'){
  //     //get all user that has commented to this
  //     // this.afs.collection('Comment PBRIS').ref
  //     // .where('info.consultation_id','==',consId)
  //     // .where('info.user_agency','in',aIds)
  //     // .get()
  //     // .then(data=>{
  //     //   let tempArr:any = []
  //     //   data.forEach(e=>{
  //     //     let comment:any = e.data() 
  //     //     comment.id = e.id
  //     //     tempArr.push(comment)
  //     //   })
  //     //   console.log("ageny comments",{tempArr})
  //     //   this.commentList = tempArr;
  //     // })

  //     //
  //     this.afs.collection('Comment PBRIS').ref
  //     .where('info.consultation_id','==',consId)
  //     .get() 
  //     .then(data=>{
  //       let tempArr:any = []
  //       data.forEach(e=>{
  //         let comment:any = e.data() 
  //         comment.id = e.id
  //         console.log("checking aIds1",comment.info.user_agency.agency,aIds)
  //         if(aIds.includes(comment.info.user_agency)){
  //           // console.log("checking aIds1",user.government.agency,aIds)
  //           if(!this.commentList.includes(comment)){
  //             this.commentList.push(comment)
  //           }
  //         }
  //         else{
  //           this.afs.collection('Users').doc(comment.user.user_id).snapshotChanges()
  //           .pipe(first())
  //           .subscribe(element=>{
  //             let user: any = element.payload.data()
  //             if(user.government){
  //               if(user.government.agency){
  //                 console.log("checking ids", comment.user.user_id,comment.user.user_id,user.credentials.uid)
  //                 if((uId === user.credentials.uid && comment.user.user_id === user.credentials.uid)){
  //                   if(!this.commentList.includes(comment))
  //                     this.commentList.push(comment)
  //                 } 
  //               }
  //             }
  //           })
  //         }
          
  //         // tempArr.push(comment)
  //       })
  //       // this.commentList = tempArr;
  //     })
  //   }
  //   else if(user_type === 'public'){
  //     this.afs.collection('Comment PBRIS', filter => filter.orderBy('created_at')).ref
  //     .where('info.consultation_id','==',consId) 
  //     .where('user.user_id', '==',uId)
  //     .get()
  //     .then(data=>{
  //       let tempArr:any = []
  //       data.forEach(e=>{
  //         let comment:any = e.data() 
  //         comment.id = e.id
  //         tempArr.push(comment)
  //       })
  //       console.log("public comments",{tempArr})
  //       this.commentList = tempArr;
  //     })
  //   }
  //   else{
  //     this.commentList = []
  //   } 
  //   // else if(!admin && consId && agenId){ // agency not yet working
  //   //   alert('agency commenting in progress')
  //   //   // this.afs.collection('Comment PBRIS', filter => filter.orderBy('created_at', 'desc')).ref
  //   //   // .where('','==',agenId)
  //   //   // .where('info.consultation_id','==',consId)
  //   //   // .get()
  //   //   // .then(data=>{
  //   //   //   let tempArr:any = []
  //   //   //   data.forEach(e=>{
  //   //   //     let comment:any = e.data() 
  //   //   //     comment.id = e.id
  //   //   //     tempArr.push(comment)
  //   //   //   })
  //   //   //   this.commentList = tempArr;
  //   //   // })
 
  //   // }else if (!admin && consId && useId){ // public and gov users
  //     // this.afs.collection('Comment PBRIS', filter => filter.orderBy('created_at')).ref
  //     // .where('info.consultation_id','==',consId)
  //     // .where('user.user_id', '==',useId)
  //     // .get()
  //     // .then(data=>{
  //     //   let tempArr:any = []
  //     //   data.forEach(e=>{
  //     //     let comment:any = e.data() 
  //     //     comment.id = e.id
  //     //     tempArr.push(comment)
  //     //   })
  //     //   this.commentList = tempArr;
  //     // })
  //   // }else{
  //   //   this.commentList = [];
  //   // }
  // }

  // openModal(data: any) {
  //   this.files = [];
  //   this.url = data.comments.url;
  //   this.user = data.user
  //   this.comment = data.comments.comment;
  //   this.type = data.type
  //   this.reply = data.reply; 
  //   this.id = data.id;
  //   this.fetching = true;
  //   if (data.comments.path && data.comments.document) {
  //     this.getFiles({ 
  //       path: data.comments.path, 
  //       documents: data.comments.document
  //     });
  //   } else {
  //     this.fetching = false;
  //     this.files = [];
  //   }
  // }
 
  // closeModal() {
  //   this.files = [];
  // }

  // getFiles(data: any): void {
  //   for (let i = 0; i < data.documents.length; i++) {
  //     this.store.ref(`${data.path}${data.documents[i].name}`)
  //       .getDownloadURL() 
  //       .subscribe((url: string) => {
  //         this.files.push({name: data.documents[i].name ,url: url})
  //     }, err => { console.log("Error",err) });
  //   }
  //   this.fetching = false;
  // }

  // getComments() {
  //   return this.afs.collection('Comment PBRIS').snapshotChanges(); 
  // }
 
  // submit(data: any) {
  //   this.loading = true;
  //   Swal.fire({
  //     title: 'Processing',
  //     allowOutsideClick: false,
  //     timerProgressBar: true,
  //     didOpen: () => {
  //       Swal.showLoading();
  
  //       this.afs.collection('Comment PBRIS')
  //         .doc(data)
  //         .update({
  //           reply: this.form.value.reply, 
  //           status: RegulationCommentStatus.RESOLVED,
  //           resolved_at: formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0800')
  //         })
  //         .then((docRef:any) => {
  //           this.loading = false;
  //           Swal.close(); 
  //           Swal.fire({
  //             title:'Successfully processed',
  //             icon: 'info'
  //           });
  //           this.resetForm();
  //       }).catch((error:any) =>{
  //         this.loading = false;
  //         Swal.close();
  //         Swal.fire({
  //           title:'System error',
  //           titleText: 'Please refresh the page',
  //           icon: 'error'
  //         }); 
  //       });
  //     }
  //   })
  // }

  // resetForm() {
  //   this.form.reset(); 
  // }

}