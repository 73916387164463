<div class="container">
    <div class="col-12">
        <div class="row file-container">
            <!-- <div class="col-1">
                <button *ngIf="pdfFileData.file" (click)="pageShift('back')" type="button" class="h-100 left-button btn-dark">
                    <i class="fas fa-chevron-left"></i>
                </button>
            </div> -->
            <div class="col-12">
                <div *ngIf="pdfFileData.file" >
                    <!-- TOP BUTTONS -->
                    <div class="d-flex justify-content-center fw-bold p-2">
                        <div class="me-5">
                            <button *ngIf="pdfFileData.file" (click)="pageShift('back')" type="button" class="h-100 left-button btn-dark">
                                <i class="fas fa-arrow-left"></i>
                            </button>
                        </div>

                        <div class="fw-bold">
                            <p>{{pdfFileData.file_page}} / {{pdfFileData.file_total_page ? pdfFileData.file_total_page : "1"}}</p>
                        </div>
                        
                        <div class="ms-5">
                            <button *ngIf="pdfFileData.file" (click)="pageShift('next')" type="button" class=" h-100 right-button btn-dark">
                                <i class="fas fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>

                    <!-- REGULATION PDF -->
                    <div class="my-4">
                        <pdf-viewer *ngIf="pdfFileData.file"
                        style="min-height: 1070px; max-height: 1200px;"
                        (after-load-complete)="afterLoadComplete($event)"
                        [show-all]="false"
                        [page]="pdfFileData.file_page"
                        [stick-to-page]="true"
                        [src]="pdfFileData.file"></pdf-viewer>
                    </div>

                    <!-- BOTTOM BUTTONS -->
                    <div class="d-flex justify-content-center fw-bold p-2">
                        <div class="me-5">
                            <button *ngIf="pdfFileData.file" (click)="pageShift('back')" type="button" class="h-100 left-button btn-dark">
                                <i class="fas fa-arrow-left"></i>
                            </button>
                        </div>

                        <div class="fw-bold">
                            <p>{{pdfFileData.file_page}} / {{pdfFileData.file_total_page ? pdfFileData.file_total_page : "1"}}</p>
                        </div>
                        
                        <div class="ms-5">
                            <button *ngIf="pdfFileData.file" (click)="pageShift('next')" type="button" class=" h-100 right-button btn-dark">
                                <i class="fas fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-1">
                <button *ngIf="pdfFileData.file" (click)="pageShift('next')" type="button" class=" h-100 right-button btn-dark">
                    <i class="fas fa-chevron-right"></i>
                </button>
            </div> -->
        </div>
    </div>
</div>