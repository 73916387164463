<div class="relative">
	<a class="absolute" style="top:1em; right:2em; cursor: pointer; font-weight: bold;" (click)="closeModal(false)"><i class="fas fa-times-circle fa-lg"></i></a>
	<div class="card-header info-header">
		<div class="row">
		  <div class="ms-3">
			<h6 class="fw-bold">Remarks on {{data.section}}</h6>
		  </div>
		</div>
	</div>

	<div style="min-height:80%; max-height:80%; overflow-y: auto;">
		<table class="table table-striped table-responsive">
			<tbody>
				<ng-container *ngIf="data.previous_remarks.length > 0;else noRemarks">
					<tr *ngFor="let user of data.previous_remarks">
						<td>
							<div>
								<div class="d-flex justify-content-between flex-row ">
									<div style="font-weight:bold">{{user.userName}}</div>
									<!-- <div>{{user.date ? new Date(user.date) ? : : '--'}}</div> -->
									<div>{{checkData(user.date)}}</div>
									<!-- {{user.date.toDate()}} -->
								</div>
								<div>{{user.currentAccountSubtype}}</div>
								<hr>
								<div style="padding: 0 1em;">
									<!-- <p>{{user.remark}}</p>  -->
									<p [innerHtml]="user.remark | linebreaks"></p>
								</div>
							</div>
						</td>
					</tr>
				</ng-container>
					<ng-template #noRemarks>
						<tr>
							<td>
								<div class="d-flex justify-content-center align-items-center">
									<h2>No Remarks</h2>
								</div>
							</td>
						</tr>
					</ng-template>
			</tbody>
		</table>
	</div>
	<div class="form-group" [formGroup]="remarksForm">
		<textarea class="form-control textArea" formControlName="remarks" placeholder="No Remarks. . ."></textarea>
	</div>
	<div *ngIf="data.action" class="relative">
		<button class="btn absolute" style="top:.8em; right:2em;" [ngClass]="data.remarks && remarksForm.value.remarks == ''? 'btn-primary' : 'btn-danger '" type="button" (click)="closeModal(true)">
			<ng-container *ngIf="data.remarks == ''; else forUpdate">
				<i class="fas fa-plus-circle me-2 fa-lg"></i>Add Remarks</ng-container>
			<ng-template #forUpdate>
				<ng-container *ngIf="remarksForm.value.remarks; else removeRemarks"><i class="fas fa-pencil-alt me-2 fa-lg"></i>Update Remarks</ng-container>
				<ng-template #removeRemarks>Remove Remarks</ng-template>
			</ng-template>
		</button>
	</div>
</div>