<!--NAVBAR-->
<app-artemis-navigation-header [currentPage]="'Reports'"></app-artemis-navigation-header>


<div class="advanced-search-card shadow">
    <div style="height: 30px"></div>
    <div class="{{style_theme}} advanced-search-title">
        <i class="advanced-search-title-icon fas fa-search fa-3x"></i>&nbsp;&nbsp;
        <h1> Advanced Search</h1>
    </div>
    <div class="text-center">
        <form [formGroup]="searchform" class="form-group">

            <!-- agency cat -->
            <div class="row mt-3">
                <div class="col-2">
                    Agency Category
                </div>
                <div class="col">
                    <input type="text"
                    class="form-control text-center service-finder-input"
                    formControlName="agencycategory"
                    [matAutocomplete]="agencycategory"
                    placeholder="Agency Category">
                    <mat-autocomplete #agencycategory="matAutocomplete">
                        <mat-option *ngFor="let agencycategory of filteredAgencyCategory | async" [value]="agencycategory.name">
                            ({{agencycategory.abbreviation}}) {{agencycategory.name}}
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>

            <!-- agency -->
            <div class="row mt-3">
                <div class="col-2">
                    Agency<span style="color: red;">*</span>
                </div>
                <div class="col">
                    <input type="text"
                        class="form-control text-center service-finder-input"
                        formControlName="agency"
                        [matAutocomplete]="agency"
                        placeholder="Agency Name">
                    <ng-container *ngFor="let item of checkSearchForm.agency">
                        <mat-error style="color:red" *ngIf="hasError('agency',item.type)">* {{item.message}}</mat-error>
                    </ng-container> 
                    <mat-autocomplete #agency="matAutocomplete">
                        <mat-option *ngFor="let agency of filteredAgency | async" [value]="agency.agencyDetails ? agency.agencyDetails.agencyName : agency.name">
                            {{agency.agencyDetails ? agency.agencyDetails.agencyName : agency.name}}
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>

            <!-- sector -->
            <div class="row mt-3">
                <div class="col-2">
                    Sector
                </div>
                <div class="col">
                    <input type="text"
                    class="form-control text-center service-finder-input"
                    formControlName="sector"
                    [matAutocomplete]="sector"
                    placeholder="Sector">
                    <mat-autocomplete #sector="matAutocomplete">
                        <mat-option *ngFor="let sector of filteredSector | async" [value]="sector.name">
                            {{sector.name}}
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>

            <!-- class -->
            <div class="row mt-3">
                <div class="col-2">
                    Classification
                </div>
                <div class="col">
                    <input type="text"
                    class="form-control text-center service-finder-input"
                    formControlName="classification"
                    [matAutocomplete]="classification"
                    placeholder="Classification">
                    <mat-autocomplete #classification="matAutocomplete">
                        <mat-option *ngFor="let classification of filteredClassification | async" [value]="classification.name">
                            {{classification.name}}
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>

            <!-- service cat only shown to arta & agency -->
            <div class="row mt-3" *ngIf="(userType == 'arta' || userType == 'agency')">
                <div class="col-2">
                    Service Category
                </div>
                <div class="col">
                    <input type="text"
                    class="form-control text-center service-finder-input"
                    formControlName="servicecategory"
                    [matAutocomplete]="servicecategory"
                    placeholder="Service Category">
                    <mat-autocomplete #servicecategory="matAutocomplete">
                        <mat-option *ngFor="let servicecategory of filteredServiceCategory | async" [value]="servicecategory.name">
                            {{servicecategory.name}}
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
            
            <!-- year -->
            <div class="row mt-3">
                <div class="col-2">
                    Year
                </div>
                <div class="col">
                    <select class="form-select text-center service-finder-input" #year (change)="yearChanged(year.value)">
                        <option readonly selected value="">Year</option>
                        <option [value]="item" *ngFor="let item of allYears">
                            {{item}}
                        </option>
                    </select>
                    <!-- <input type="text"
                    class="form-control text-center service-finder-input"
                    formControlName="year"
                    [matAutocomplete]="service"
                    placeholder="Service Year">
                    <mat-autocomplete #service="matAutocomplete">
                        <mat-option *ngFor="let service of filteredService | async" [value]="year.name">
                            {{service.name}}
                        </mat-option>
                    </mat-autocomplete> -->
                </div>
            </div>

            <!-- keyword -->
            <div class="row mt-3">
                <div class="col-2">
                    Keyword
                </div>
                <div class="col">
                    <!-- <input type="text" (input)="getKeywordSuggestions()" formControlName="keyword" class="form-control text-center service-finder-input">
                    <ul class="list-group service-finder-input" style="display: inline-block; white-space: nowrap;">
                        <li *ngFor="let result of searchSuggestions" (click)="selectItem(result)" class="list-group-item text-dark">{{ result }}</li>
                    </ul> -->
                    <input type="text"
                    class="form-control text-center service-finder-input"
                    formControlName="keyword"
                    placeholder="Keyword">
                    <!-- <input type="text"
                    class="form-control text-center service-finder-input"
                    formControlName="keyword"
                    [matAutocomplete]="keyword"
                    placeholder="Keyword"> -->
                    <!-- <mat-autocomplete #keyword="matAutocomplete">
                        <mat-option *ngFor="let service of filteredService | async" [value]="service.serviceDetails.service_name">
                            {{service.serviceDetails.service_name}}
                        </mat-option>
                        <mat-option *ngFor="let service of filteredService | async" [value]="service.serviceDetails.office_division">
                            {{service.serviceDetails.office_division}}
                        </mat-option>
                    </mat-autocomplete> -->
                </div>
            </div>


        </form>
    </div>
    <div class="m-3 text-center row">
        <div class="col">
            <button class="btn" (click)="formReset()">Reset Form</button>
        </div>
        <div class="col">
            <button class="{{style_theme}}-btn-inverted theme-button" (click)="formSubmit()">SEARCH</button>
        </div>
    </div>
    <div class="m-3 text-center"></div>
</div>