import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[app-artemis-requirement-node]',
  templateUrl: './artemis-requirement-node.component.html',
  styleUrls: ['./artemis-requirement-node.component.scss']
})
export class ArtemisRequirementNodeComponent implements OnInit {

  @Input() requirement: any
  @Input() index:any

  constructor() { }

  ngOnInit(): void {
  }

}
