<app-navbar [currentPage]=""></app-navbar>

<div class="container">
  <div class="row my-5">
    <div class="col-3">
      <ul class="list-group">
        <li class="list-group-item bg-primary text-light" disabled>
          <div class="float-start">
            <b>My PBRIS Account</b>
          </div>
          <div class="float-end">
            <i class="fas fa-align-justify"></i>
          </div>
        </li>
        <li class="list-group-item text-center">
          <div class="px-5 py-1 rounded"><img src="/assets/Assets/blank-profile.png" class="rounded-circle img-fluid"/></div>
          <div><b>{{ user_info.personal?.first_name }} {{ user_info.personal?.last_name }}</b></div>
          <div>{{ user_info.company?.name_of_organization ? user_info.company?.name_of_organization : 'N/A' }}</div>
        </li>
        <button class="list-group-item list-group-item-action text-start" (click)="openDashboard()">My Dashboard</button>
        <button class="list-group-item list-group-item-action text-start" (click)="onSubscription(subscription_options)">My Subscriptions</button>
        <ul class="list-unstyled" *ngIf="subscription_options">
          <li class="w-full" style="padding-left: 20px;">
            <button class="list-group-item list-group-item-action text-star" (click)="openSubscription()">Subscriptions</button>
          </li>
          <li class="w-full" style="padding-left: 20px;">
            <button class="list-group-item list-group-item-action text-star" (click)="openAgency()">Agencies</button>
          </li>
          <li class="w-full" style="padding-left: 20px;">
            <button class="list-group-item list-group-item-action text-star" (click)="openSector()">Sectors</button>
          </li>
        </ul>
        <button class="list-group-item list-group-item-action text-start" (click)="openComment()">My Comments</button>
        <button class="list-group-item list-group-item-action text-start" (click)="openRegulation()">Pinned Regulations</button>
        <button class="list-group-item list-group-item-action text-start" (click)="openTickets()">My Ticket</button>
        <ul class="list-unstyled" *ngIf="sub_inbox">
          <li class="w-full" style="padding-left: 20px;">
            <button class="list-group-item list-group-item-action text-star" (click)="openTickets()">List Ticket</button>
          </li>
          <li class="w-full" style="padding-left: 20px;">
            <button class="list-group-item list-group-item-action text-star" (click)="createTickets()">File Ticket</button>
          </li>
        </ul>         
        <button class="list-group-item list-group-item-action text-start" (click)="openManagement()">Account Management</button>
        <button class="list-group-item list-group-item-action text-start" (click)="onLogout()">Sign Out</button>
      </ul>
    </div>
    <div class="col-9">
      <div class="shadow rounded">
        <app-pbris-account-dashboard *ngIf="dashboard" [data_catch]="user_info"></app-pbris-account-dashboard>
        <app-pbris-account-subscription *ngIf="subscription" [data_catch]="user_info"></app-pbris-account-subscription>
        <app-pbris-account-regulation *ngIf="regulation" [data_catch]="user_info"></app-pbris-account-regulation>
        <app-pbris-account-ticket *ngIf="createTicket" [data_catch]="user_info"></app-pbris-account-ticket>
        <app-pbris-account-ticket-list *ngIf="ticket" [data_catch]="user_info"></app-pbris-account-ticket-list>
        <app-pbris-account-comment *ngIf="comment" [data_catch]="user_info"></app-pbris-account-comment>
        <app-pbris-account-management *ngIf="management" [data_catch]="user_info"></app-pbris-account-management>
        <app-pbris-account-sector *ngIf="sector" [data_catch]="user_info"></app-pbris-account-sector>
        <app-pbris-account-agency *ngIf="agency" [data_catch]="user_info"></app-pbris-account-agency>
        <div *ngIf="loading" class="text-center">
          <div class="spinner-border text-primary my-5" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
