<mat-card>
    <mat-card-header class="mb-2">
        <mat-card-title class="text-primary"><strong>Certificate of Compliance Submissions</strong></mat-card-title>
        <!--<mat-card-subtitle>Start</mat-card-subtitle>-->
        
    </mat-card-header>
    
    <mat-card-content>
        <form [formGroup]="charterSearchForm">
            <div class="row">
                <div class="col-4">
                    <mat-form-field>
                        <mat-label>Select date submitted</mat-label>
                        <mat-date-range-input formGroupName="rangeDateSubmitted" [rangePicker]="pickerDateSubmitted">
                          <input matStartDate formControlName="start" placeholder="Start date">
                          <input matEndDate formControlName="end" placeholder="End date">
                        </mat-date-range-input>
                          <mat-datepicker-toggle matIconSuffix [for]="pickerDateSubmitted"></mat-datepicker-toggle>
                        <mat-date-range-picker #pickerDateSubmitted></mat-date-range-picker>
                      
                        <!-- <mat-error *ngIf="charterSearchForm.controls.rangeDateSubmitted.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error> -->
                        <!-- <mat-error *ngIf="charterSearchForm.controls.rangeDateSubmitted.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error> -->
                      </mat-form-field>
                </div>
            </div>
      
            <div class="row">
              <div class="col-4">
                <mat-form-field>
                  <mat-label>Select agency category</mat-label>
                  <mat-select (selectionChange)="filterAgency()" formControlName="agencyCategory">
                    <mat-option value="" selected>All</mat-option>
                    <mat-option *ngFor="let row of valueSuggestions.agency_category" value="{{row.id}}">
                      {{row.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-3">
                <mat-form-field>
                  <mat-label>Select mother agency</mat-label>
                  <mat-select (selectionChange)="filterAgency()" formControlName="motherAgency">
                    <mat-option value="" selected>All</mat-option>
                    <mat-option *ngFor="let row of valueSuggestions.mother_agency" value="{{row.id}}">
                      {{row.motheragency}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-3">
                <mat-form-field>
                  <mat-label>Select agency</mat-label>
                  <mat-select formControlName="agency">
                    <mat-option value="" selected>All</mat-option>
                    <mat-option *ngFor="let row of valueSuggestions.agency_filtered" value="{{row.id}}">
                      {{row.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-2">
                <button  (click)="sendSearchFilters()" class="btn btn-warning btn-block">
                  APPLY FILTER
                </button>
              </div>
            </div>
        </form>

        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger1" class="table table-striped table-responsive">
            <thead>
                <tr>
                    <th class="table-header" style="width: 10%;">Date Submitted</th>
                    <th class="table-header" style="width: 30%;">Agency</th>
                    <th class="table-header">Person Responsible</th>
                    <th class="table-header">Certificate of Compliance</th>
                    <th class="table-header" style="width: 10%;">Status</th>

                </tr>
            </thead>
            <tbody>

                <tr *ngFor="let row of cocCollections;">

                    <td>
                        {{ row.dateSubmitted.toDate() | date: "yyyy-MMM-dd hh:mm" }}
                    </td>
                    <td>
                        {{row.fromAgencyName}}
                    </td>
                    <td>
                       {{row.personResponsible.name}}
                    </td>

                    <td>
                        <a class="text-primary service-link" (click)="ViewCCDocument(row)">{{row.cocYear}} COC for Citizen's
                                    Charter {{row.citizenscharterVersion.year}}
                                    {{row.citizenscharterVersion.edition}}<span><sup>{{transform(row.citizenscharterVersion.edition,false)}}</sup></span>
                                    Edition</a>
                    </td>
                    <td>
                        <u>{{ row.submission }} CC</u>
                    </td>

                </tr>
            </tbody>
        </table>
    </mat-card-content>
</mat-card>