<mat-card>
    <mat-card-content>
      <div>
        <span>Service Information</span>
      <ol>
        <li>
          <a (click)="scrollService.scrollTo('s_name')">Service Name</a>  
        </li>
        <li>
          <a (click)="scrollService.scrollTo('s_info')">Service Descricption</a>  
        </li>
        <li>
          <a (click)="scrollService.scrollTo('s_ood')">Office or Division</a>  
        </li>
        <li>
          <a (click)="scrollService.scrollTo('s_cat')">Category</a>  
        </li>
        <li>
          <a (click)="scrollService.scrollTo('s_class')">Classification</a>  
        </li>
        <li>
          <a (click)="scrollService.scrollTo('s_tot')">Type of Transaction</a>  
        </li>
        <li>
          <a (click)="scrollService.scrollTo('s_wma')">Who may avail</a>  
        </li>
        <li>
          <a (click)="scrollService.scrollTo('s_oh')">Operating Hours</a>  
        </li>
        <li *ngIf="services.serviceDetails.has_statute == true">
          <a (click)="scrollService.scrollTo('s_stt')">Statute</a>  
        </li>
      </ol>
      </div>
      <div>
        <ng-container *ngIf="services.standardRequirements && services.standardRequirements.length > 0">
                  <div>
                      <label style="font-weight: bold; padding-left: 25px;"> Standard Requirement</label>
                  </div>
                  <div class="content-center">
                    <ol>
                      <li *ngFor="let standardReq of services.standardRequirements; let i = index; let c = count">
                          <div *ngIf="!standardReq?.chooseany">
                                <a (click)="scrollService.scrollTo('stdreq_' + i)" matTooltip="{{standardReq.requirement}}">{{ i + 1 }}. {{standardReq.requirement}}</a>  
                          </div>
                          <div *ngIf="standardReq?.chooseany">
                            <div>
                                  <div>
                                      <span (click)="scrollService.scrollTo('stdreq_' + i)" matTooltip="{{standardReq.title}}">{{ i + 1 }}. {{standardReq.title}}</span>  <br>
                                      <label *ngIf="standardReq.chooseany"><small>Any of the following:</small></label>
                                  </div>
                                  <ng-container *ngIf="standardReq.requirement && standardReq.requirement.length > 0;">
                                      
                                        <ol>
                                          <li *ngFor="let situationalReq of standardReq.requirement; let x = index; let z = count">
                                            <a (click) = "scrollService.scrollTo('standard_sub' + i + x) " matTooltip="{{ requirementservice.printToLetter(x + 1) }}.{{situationalReq.requirement}}">{{ requirementservice.printToLetter(x + 1) }}.{{situationalReq.requirement}}</a>
                                            </li>
                                        </ol>    
              
                                  </ng-container>
                            </div>
                          </div>
                      </li>
                    </ol>     
                  </div>
      </ng-container>
       <!--sit-->
      <ng-container *ngIf="services?.situationalRequirementsArray && services?.situationalRequirementsArray.length > 0 ;noSituationalRequirementsData">
          <ng-container *ngFor="let situationalBody of services?.situationalRequirementsArray;let sitIndex = index">
                  <div>
                      <div>
                        <span (click)="scrollService.scrollTo('sitreq_title' + sitIndex)" matTooltip="{{situationalBody.title}}">{{situationalBody.title}}</span><br>
                          <label *ngIf="situationalBody.chooseany"><small>Any of the following:</small></label>
                      </div>
                      <ng-container *ngIf="situationalBody.requirement && situationalBody.requirement.length > 0;">
                            <ol>
                              <li *ngFor="let situationalReq of situationalBody.requirement; let i = index; let c = count">
                                  <div *ngIf="!situationalReq.chooseany">
                                    <a (click)="scrollService.scrollTo('sitreq_' + sitIndex + i)" matTooltip ="{{ situationalBody.chooseany? requirementservice.printToLetter(i + 1)  : i+1}}.{{situationalReq.requirement}}">{{ situationalBody.chooseany? requirementservice.printToLetter(i + 1)  : i+1}}.{{situationalReq.requirement}}</a>
                                  </div>
                                  <div *ngIf="situationalReq?.chooseany">
                                          <div>
                                              <span (click)="scrollService.scrollTo('stdreq_' + i)" matTooltip ="{{i + 1}}.{{situationalReq.title}}">{{i + 1}}.{{situationalReq.title}}</span>
                                              <br>
                                              <label *ngIf="situationalReq.chooseany"><small>Any of the following:</small></label>
                                          </div>
                                          <ng-container *ngIf="situationalReq.requirement && situationalReq.requirement.length > 0;">
                                                <ol>
                                                  <li *ngFor="let situationalReq of situationalReq.requirement; let x = index; let z = count">
                                                    <a (click)="scrollService.scrollTo('standard_sub' + i + x)" matTooltip ="{{  requirementservice.printToLetter(x + 1) }}.{{situationalReq.requirement}}">{{  requirementservice.printToLetter(x + 1) }}.{{situationalReq.requirement}}</a>
                                                  </li>       
                                                </ol>       
                                              
                                          </ng-container>
                                    </div>
                                  <ng-container *ngIf="situationalReq?.subRequirement && situationalReq?.subRequirement.length > 0 ;noSituationalRequirementsData">
                                   <!-- <p class="text-muted"><i>Sub Situational Requirement/s</i></p>-->
                                    <ng-container *ngFor="let situationalBody of situationalReq?.subRequirement; let subindex = index">
                                      <div>
                                        <div class="ms-5">
                                            <span (click)="scrollService.scrollTo('subsitreq_title'+ i + subindex)" matTooltip="{{situationalBody.title}}">{{situationalBody.title}}</span><br>
                                            <label *ngIf="situationalBody.chooseany"><small>Any of the following:</small></label>
                                        </div>
                                        <ng-container *ngIf="situationalBody.requirement && situationalBody.requirement.length > 0;">
                                                    <ol>
                                                        <li *ngFor="let situationalReq of situationalBody.requirement; let i = index; let c = count">
                                                            <div *ngIf="!situationalReq.chooseany">
                                                                  <a (click)="scrollService.scrollTo('subsitreq_' + sitIndex + subindex + i)" matTooltip ="{{ situationalBody.chooseany? requirementservice.printToLetter(i + 1)  : i+1}}.{{situationalReq.requirement}}">{{ situationalBody.chooseany? requirementservice.printToLetter(i + 1)  : i+1}}.{{situationalReq.requirement}}</a>
                                                            </div>
                                                            <div *ngIf="situationalReq?.chooseany">
                                                                  <div>
                                                                     <span (click)="scrollService.scrollTo('stdreq_'+ i)" matTooltip="{{i + 1}}.{{situationalReq.title}}">{{i + 1}}.{{situationalReq.title}}</span><br>
                                                                      <label *ngIf="situationalReq.chooseany"><small>Any of the following:</small></label>
                                                                  </div>
                                                                  <ng-container *ngIf="situationalReq.requirement && situationalReq.requirement.length > 0;">
                                                                        <ol>
                                                                          <li *ngFor="let situationalReq of situationalReq.requirement; let x = index; let z = count">
                                                                                <a (click)="scrollService.scrollTo('standard_sub' + i + x)" matTooltip ="{{  requirementservice.printToLetter(x + 1) }}.{{situationalReq.requirement}}">{{  requirementservice.printToLetter(x + 1) }}.{{situationalReq.requirement}}</a>
                                                                          </li>
                                                                         </ol>    
                                                                  </ng-container>
                                                            </div>
                                                        </li>    
                                                    </ol>
                                            
                                        </ng-container>
                                        <ng-template #noSituationalRequirementsData>
                                            <div class="content-center">
                                                No Requirements Data
                                            </div>
                                        </ng-template>
                                    </div>
                                    </ng-container>
                                  </ng-container>
                              </li>
                            </ol>  
                                 
                              
                      
                      </ng-container>
  
                      <ng-container *ngIf="situationalBody?.subRequirement && situationalBody?.subRequirement.length > 0 ;">
                          <ng-container *ngFor="let situation of situationalBody?.subRequirement; let i = index;">
                              <div class="content-center">
                                <div class="requirement-table">
                                  <span style="font-weight: bold; ">{{requirementservice.printToLetter(i + 1)}}.{{situation.title}}</span><br>
                                  <label *ngIf="situation.chooseany"><small>Any of the following:</small></label>
                                </div>
                              <ng-container *ngIf="situation.requirement && situation.requirement.length > 0;">
                                  <div class="content-center">
                                      <div class="requirement-table situational">
                                          <ol>
                                              <ng-container *ngFor="let situationalReq of situation.requirement; let i = index; let c = count">
                                                  <div *ngIf="!situationalReq.chooseany">
                                                          <div>
                                                            {{ situation.chooseany? requirementservice.printToLetter(i + 1)  : i+1 }}.{{situationalReq.requirement}}
                                                          </div>
                                                  </div>
                                                  <div *ngIf="situationalReq?.chooseany">
                                                      <div>
                                                          <div>
                                                              <span (click)="scrollService.scrollTo('stdreq_'+ i)" matTooltip="{{i + 1}}.{{situationalReq.title}}" >{{i + 1}}.{{situationalReq.title}}</span><br>
                                                              <label *ngIf="situationalReq.chooseany"><small>Any of the following:</small></label>
                                                          </div>
                                                          <ng-container *ngIf="situationalReq.requirement && situationalReq.requirement.length > 0;">
                                                              <div class="content-center">
                                                                  <div class="requirement-table">
                                                                      <ol>
                                                                          <li *ngFor="let situationalReq of situationalReq.requirement; let x = index; let z = count">
                                                                            <a (click)="scrollService.scrollTo('standard_sub' + i + x)" matTooltip ="{{ situationalReq.chooseany? requirementservice.printToLetter(x + 1) :  x+1}}.{{situationalReq.requirement}}">{{ situationalReq.chooseany? requirementservice.printToLetter(x + 1) :  x+1}}.{{situationalReq.requirement}}</a>
                                                                          </li>     
                                                                      </ol>
                                                                    </div>
                                                              </div>
                                                          </ng-container>
                                                      </div>
                                                    </div>
                                                  
                                              </ng-container>
                                          </ol>
                                      </div>
                                  </div>
                              </ng-container>
                              </div>
                          </ng-container>
                        </ng-container>
  
                      
                      <ng-template #noSituationalRequirementsData>
                          <div class="content-center">
                              No Requirements Data
                          </div>
                      </ng-template>
                  </div>
       
          </ng-container>
      </ng-container>
      </div>
     


      <!-- <div>
        <div *ngIf="services.standardRequirements && services.standardRequirements.length > 0"> 
          <div>
            <span >Standard Requirement</span>
          </div>
          <ol>
              <li *ngFor="let standardReq of services.standardRequirements; let i = index; let c = count">
                <div *ngIf="!standardReq?.chooseany">
                  <a (click)="scrollService.scrollTo('stdreq_' + i)" matTooltip="{{standardReq.requirement}}">{{ i + 1 }}. {{standardReq.requirement}}</a>  
                </div>
                <div *ngIf="standardReq?.chooseany">
                  <div>
                    <a (click) = "scrollService.scrollTo('stdreq_' + i) " matTooltip="{{standardReq.requirement}}">{{i + 1}}.{{standardReq.title}}</a>
                  </div>
                  <ng-container *ngIf="standardReq.requirement && standardReq.requirement.length > 0;">
                    <label *ngIf="standardReq.chooseany"><small>Any of the following:</small></label>
                    <ol>
                      <li *ngFor="let situationalReq of standardReq.requirement; let x = index; let z = count">
                          <a (click) = "scrollService.scrollTo('standard_sub' + i + x) " matTooltip="{{situationalReq.requirement}}">{{ standardReq.chooseany? requirementservice.printToLetter(x + 1) :  x+1}}.{{situationalReq.requirement}}</a>
                      </li>
                    </ol>
                  </ng-container>
                </div>
              </li>
          </ol>          
        </div>
     situational req
        <div *ngIf="services?.situationalRequirementsArray && services?.situationalRequirementsArray.length > 0 ;noSituationalRequirementsData">
          <div *ngFor="let situationalBody of services?.situationalRequirementsArray;let x =index">
            <div> situational requirements
              <div>
                <span>Situational Requirement</span>
              </div>
              <div class="ms-4">
                <span (click)="scrollService.scrollTo('sitreq_title' + x)" matTooltip="{{situationalBody.title}}">{{situationalBody.title}}</span>
                <label *ngIf="situationalBody.chooseany"><small>Any of the following:</small></label>
              </div>  
              <div *ngIf="situationalBody.requirement && situationalBody.requirement.length > 0;">
                    <ol>
                          <li *ngFor="let situationalReq of situationalBody.requirement; let i = index; let c = count">
                            <a (click)="scrollService.scrollTo('sitreq_' + x + i)" matTooltip ="{{situationalReq.requirement}}">{{ i + 1 }}. {{situationalReq.requirement}}</a>
                          
                          <ng-container *ngIf="situationalReq.subRequirement && situationalReq.subRequirement.length > 0 ;noSituationalRequirementsData">
                            <div *ngFor="let situationalBody of situationalReq.subRequirement; let y = index">
                              <span class="text-muted m-0" span ><i>Sub Situational Requirement/s</i></span>
                              <div class="ms-5">
                                <label (click)="scrollService.scrollTo('subsitreq_title'+ i + y)" matTooltip="If {{situationalBody.title}}">If {{situationalBody.title}}</label>
                              </div>
                              <div *ngIf="situationalBody.requirement && situationalBody.requirement.length > 0;" >     
                                  <ol>
                                    <li class="ms-5" *ngFor="let situationalReq of situationalBody.requirement; let i = index; let c = count">
                                      <a (click)="scrollService.scrollTo('subsitreq_'+ x + y + i)" matTooltip="{{situationalReq.requirement}}" >{{ i + 1 }}. {{situationalReq.requirement}}</a>
                                    </li>
                                  </ol>
                              </div>  
                            </div>
                          </ng-container>
                        </li> 

                        <li class="ms-3" *ngFor="let situationalReq of situationalBody.subRequirement let i = index; let c = count">
                          <div >
                            <label (click)="scrollService.scrollTo('subsubreq_title'+ i + c)" matTooltip="If {{situationalReq.title}}">If {{situationalReq.title}}</label>
                          </div>
                          <ol>
                            <li *ngFor="let subreq of situationalReq.requirement"> <a (click)="scrollService.scrollTo('subsitreq_'+ x + c + i)" matTooltip="{{subreq.requirement}}" >{{ i + 1 }}. {{subreq.requirement}}  </a>
                            </li> 
                          </ol>
                          
                      </li> 
                    </ol> 
              </div>      
            </div>
        </div>    
        </div>
      </div> -->
  <!--Client Steps-->
  <div>
    <div>
      <span >Client Steps</span>
    </div>
    <div style="width: 80%;" *ngIf="services.processedClientStep && services.processedClientStep.length > 0">
      <ol>
              <li *ngFor="let clientStep of services.processedClientStep">
                <a (click)="scrollService.scrollTo(clientStep.step_Id)" matTooltip="{{clientStep.clientStepName}}" >{{clientStep.clientStepName}}</a> 
              <ol class="">
                  <div *ngIf="clientStep.isSituationalStep == true" class="situational-header" matTooltip="* For {{clientStep.situation}}">* For {{clientStep.situation}}</div>
                  <li><a (click)="scrollService.scrollTo(clientStep.action_Id)" matTooltip="{{clientStep.agencyActions}}">{{clientStep.agencyActions}}</a></li>
              </ol>
            </li>
        </ol>
    </div>
  </div>
      <div>
        <ol class="p-0">
          <li>
            <a  (click)="scrollService.scrollTo('tpt')">Total Processing Time</a>  
          </li>
          <li>
            <a  (click)="scrollService.scrollTo('tpf')">Total Processing Fee</a>  
          </li>
          <li>
            <a  (click)="scrollService.scrollTo('lpf')">List Of Possible Fees</a>  
          </li>
          <li>
            <a  (click)="scrollService.scrollTo('lff')">List of Formulated Fees</a>  
          </li>
        </ol>  
      </div>
    </mat-card-content>
  </mat-card>