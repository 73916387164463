<div class="contianer my-3">
    <div class="row justify-content-center">
        <h2 mat-dialog-title class="mat-typography modal-header d-flex justify-content-between align-items-center">
            <!-- header -->
            <h2 style="margin: 0;">SUBMIT A COMMENT</h2>
            <button type="button" class="close" aria-label="Close" (click)="goBack()">
                <span aria-hidden="true">&times;</span>
            </button>
        </h2>
        <div mat-dialog-content class="modal-body">
            <!-- style="min-width: 440.25px; min-height: max-content;" -->

            <div class="form-group py-3">
                <div class="col-12">
                    <label for="comment2" class="fw-bold">Your comment :</label>
                </div>
                <div class="col-12">
                    <p id="comment2" class="m-2 fs-6">{{data.pbrisCommentForm.comment ? data.pbrisCommentForm.comment : "N/A"}}</p>
                </div>
            </div>

            <div class="form-group py-3">
                <div class="col-12">
                    <label class="fw-bold">Attachment/s :</label>
                </div>

                <ul class="list-unstyled m-2">
                    <li>
                        <div class="row">
                            <div class="col-md-3 col-sm-12">
                                <label for="url" class="fw-bold me-3">Url :</label>
                            </div>
                            <div class="col-md-9 col-sm-12">
                                {{data.pbrisCommentForm.url ? data.pbrisCommentForm.url : "N/A"}}
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="col-md-3 col-sm-12">
                            <label for="attachment" class="fw-bold">File/s :</label>
                        </div>

                        <div class="col-md-9 col-sm-12">
                            <ng-template #noUploadedFile>
                                N/A
                            </ng-template>
                        </div>
                        
                        <div *ngIf="data.temp_file && data.temp_file.length > 0; else noUploadedFile" >
                            <ul class="list-unstyled" *ngFor="let list of data.temp_file; let i = index">
                                <li style="border-bottom:1px solid rgba(0,0,0, .3);">
                                    <div id="attachment" class="row m-2">
                                        <div class="col-md-6 col-sm-12 p-2 text-break">{{list.name}}</div>
                                        <div class="col-md-3 col-sm-12 p-2">{{list.type}}</div>
                                        <div class="col-md-3 col-sm-12 p-2">{{list.size}} kb/s</div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <ng-template #noUploadedFile>
                            N/A
                        </ng-template>
                    </li>
                </ul>
            </div>

            <div class="form-group py-3 d-flex">
                <div class="col-md-3 col-sm-12">
                    <label for="submit_as" class="fw-bold">Submitting as :</label>
                </div>
                <div class="col-md-9 col-sm-12">
                    <p id="submit_as" class="mx-2">{{data.pbrisCommentForm.user ? data.pbrisCommentForm.user : "N/A"}}</p>
                </div>
            </div>

            <hr />

            <div class="form-group py-3">
                <div class="col-12">
                    <p class="text-center">
                        <i class="fa fa-exclamation-triangle fa-2x text-warning me-3"></i>
                        <span>Comment cannot be removed once submitted.</span>
                    </p>
                </div>
            </div>

            <div class="form-group">
                <div class="col-12 my-2" style="text-align: -webkit-center;">
                <re-captcha 
                    [(ngModel)]="captcha"
                    (resolved)="resolved($event)" 
                    (error)="onError($event)" 
                    errorMode="handled" 
                    [siteKey]="recaptchaV2SiteKey"
                ></re-captcha>
                </div>
            </div>
        </div> 

        <div mat-dialog-actions class="modal-footer d-flex align-items-center">
            <!-- d-flex justify-content-between -->
            <button type="button" class="btn btn-light btn-rounded" data-dismiss="modal" (click)="goBack()"><strong>Cancel</strong></button>
            <button type="button" class="btn btn-danger btn-rounded" (click)="onSubmit();" [disabled]="!this.captcha">
                <strong>Submit</strong>
                <i class="fas fa-share-square mx-2"></i>
            </button>
        </div>
    </div>
    
</div>
