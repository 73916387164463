<div class="card">
  <h5 class="card-header">Sectors</h5>
  <div class="card-body p-0">
    <div class="table-responsive" style="max-height: 500px;">
      <table class="table">
        <thead>
          <tr class="text-muted">
            <th class="table-header fw-bold col-2">Name</th>
            <th class="table-header fw-bold col-2">Section</th>
            <th class="table-header fw-bold col-6">Definition</th>
            <th class="table-header fw-bold col-2">Action</th>
          </tr>
        </thead>
        <tbody class="card-text">
          <tr *ngFor="let list of sectorList; let i = index">
            <th scope="row">{{ list.name ? list.name : "N/A"}}</th>
            <td>{{ list.section ? list.section : "N/A" }}</td>
            <td class="">{{ list.definition ? list.definition : "N/A" }}</td>
            <td>
              <div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-info" (click)="sampleModal.show(); openModal(list)">Details</button>
                <button type="button" class="btn btn-success" *ngIf="list.status === false || list.status?.status === false " (click)="onSubscribe(list)">Subscribe</button>
                <button type="button" class="btn btn-danger" *ngIf="list.status?.status === true" (click)="onSubscribe(list)">Unsubscribe</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Modal -->
  <div style="overflow-y: auto" data-keyboard="false" data-backdrop="static" class="modal fade" mdbModal #sampleModal="mdbModal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div *ngIf="onModal" class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Review Sector</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="sampleModal.hide(); closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <dl class="row">
            <dt class="col-sm-3">Name</dt>
            <dd class="col-sm-9">{{ modal?.name ? modal?.name : 'N/A'}}</dd>

            <dt class="col-sm-3">Section</dt>
            <dd class="col-sm-9">{{ modal?.section ? modal?.section : 'N/A'}}</dd>
          
            <dt class="col-sm-3">Definition</dt>
            <dd class="col-sm-9">{{ modal?.definition ? modal?.definition : 'N/A'}}</dd>

          </dl>
        </div> 
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="sampleModal.hide(); closeModal()">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>