<!--NAVBAR-->
<app-artemis-navigation-header [currentPage]="'Charter'"></app-artemis-navigation-header>


<!-- Content -->
<div class="col m-3">
    <div class="row mx-3">
        <a target="_blank" [hidden]="!file" href="{{file}}" class="theme-{{userType}}-btn btn btn-lg btn-primary float-end">Download Citizens Charter</a>
    </div>

    <div class="row my-3 border border-dark p-3 px-0">
        <div class="d-flex justify-content-center align-self-center">
            <div class="spinner-grow text-info" role="status" [hidden]="file">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <pdf-viewer [hidden]="!file" [src]="file"></pdf-viewer>
    </div>
</div>

