<div class="mx-auto">
    <table class = "table table-bordered">
        <thead class = "table table-dark text-center">
            <tr>
                <th colspan = "2">SUMMARY REPORT  - PBRIS USERS</th>
            </tr>
            <tr>
                <!-- <th colspan = "2">(October 1 - 30, 2020)</th> -->
                <th colspan = "2">
                    <app-date-picker 
                    (datePickerEvent) = "receivedDatePicker($event)"
                    ></app-date-picker>{{dateInfo}}</th>          
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class = "table-warning">TOTAL COUNT OF USERS</td>
                <td class = "h3">{{users_count.length}}</td>
            </tr>
            <tr>
                <td>GUESTS</td>
                <td class = "h3">{{guests_count.length}}</td>
            </tr>
            <tr>
                <td>INDIVIDUAL</td>
                <td class = "h3">{{individual_count.length}}</td>
            </tr>
            <tr>
                <td>ORGANIZATION</td>
                <td class = "h3">{{organization_count.length}}</td>
            </tr>
            <tr>
                <td>GOVERNMENT</td>
                <td class = "h3">{{government_count.length}}</td>
            </tr>
          <tr>
            <td colspan = "4">
                <table class = "table table-bordered mb-0">
                    
                        <thead class = "table table-dark">
                            <tr>
                                <th colspan = "2">OTHER DATA</th>
                                
                            </tr>
                            
                        </thead>
                        <tbody>
                            
                            <tr>
                                <td>TOTAL REGISTERED USERS</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>COUNT OF PASSWORD RESET</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>COUNT OF UNLOCKING REQUESTS</td>
                                <td></td>
                            </tr>
                        </tbody>
                        
                   </table>
               </td>
          </tr>
        </tbody>
        
    </table>
</div>