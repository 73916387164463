import { PdfRenderService } from 'src/app/pdf-render.service';
import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { first } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
    // import { ActivatedRoute, Router } from '@angular/router';
    // import { AngularFireAuth } from '@angular/fire/auth';
    // import { PdfRenderService } from '../../pdf-render.service';
    // import { format } from 'date-fns';
    // import firebase from "firebase/app";
    // import Swal from 'sweetalert2';

@Component({
selector: 'app-worklist-regulations-pia-view',
templateUrl: './worklist-regulations-pia-view.component.html',
styleUrls: ['./worklist-regulations-pia-view.component.scss']
})
export class WorklistRegulationsPiaViewComponent implements OnInit {

  @Input() id:any;
  @Input() currentRegulation:any;

  currentProposedRegulation:any;

  constructor(
      private afs: AngularFirestore,
      private pdf_render: PdfRenderService,
      private store: AngularFireStorage,
  ) { }

  ngOnInit(): void {
    this.loadProposedRegulation(this.id, this.currentRegulation);
  }

  // loadProposedRegulation(id:any){
  //   this.afs.collection('regulations-proposed-phase-02').doc(id).snapshotChanges()
  //   .pipe(first())
  //   .subscribe(async (info)=>{
  //       let item:any = info.payload.data()
  //       item.id = info.payload.id

  //       if(item.pia_section_1.policy_problem_file_folder && item.pia_section_1.policy_problem_file_name){
  //         let tokenized = item.pia_section_1.policy_problem_file_name.split(".")
  //         // change file type check
  //         if(tokenized && tokenized.length > 0 && tokenized[tokenized.length-1] == 'zip'){
  //           item.pia_section_1.policy_problem_file_type = 'zip';
  //           this.store.ref(`${item.pia_section_1.policy_problem_file_folder}/${item.pia_section_1.policy_problem_file_name}`).getDownloadURL()
  //           .subscribe((url)=>{
  //             item.pia_section_1.policy_problem_download_link = url
  //           })
  //         }else{
  //           const path = `${item.pia_section_1.policy_problem_file_folder}/${item.pia_section_1.policy_problem_file_name}`;
  
  //           this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
  //             item.pia_section_1.policy_problem_file = data;
  //             item.pia_section_1.file_page = 1; 
  //           });
  //         }
  //       }

         
  //       for(let pia4Ctr in item.pia_section_4){
  //         if(item.pia_section_4[pia4Ctr].compliance_file_name && item.pia_section_4[pia4Ctr].compliance_file_folder){
  //           const path = item.pia_section_4[pia4Ctr].compliance_file_folder+"/"+item.pia_section_4[pia4Ctr].compliance_file_name

  //           this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
  //             item.pia_section_4[pia4Ctr].compliance_file = data;
  //             item.pia_section_4[pia4Ctr].file_page = 1
  //           });
  //         }
  //       }

  //       if(item.pia_section_4_file && item.pia_section_4_file.compliance_file_folder && item.pia_section_4_file.compliance_file_name){
  //         let tokenized = item.pia_section_4_file.compliance_file_name.split(".")
  //         if(tokenized && tokenized.length > 0 && tokenized[tokenized.length-1] == 'zip'){
  //           item.pia_section_4_file.impact_analysis_file_type = 'zip';
  //           this.store.ref(`${item.pia_section_4_file.compliance_file_folder}/${item.pia_section_4_file.compliance_file_name}`).getDownloadURL()
  //           .subscribe((url)=>{
  //             item.pia_section_4_file.impact_analysis_download_link = url
  //           })
  //         } else{
  //           const path = `${item.pia_section_4_file.compliance_file_folder}/${item.pia_section_4_file.compliance_file_name}`;
  
  //           this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
  //             item.pia_section_4_file.impact_analysis_file = data;
  //             item.pia_section_4_file.file_page = 1; 
  //           });
  //         }
  //       }

  //       if(item.pia_section_6.consultation_and_recommendation_file_folder && item.pia_section_6.consultation_and_recommendation_file_name){
  //         let tokenized = item.pia_section_6.consultation_and_recommendation_file_name.split(".")
  //         if(tokenized && tokenized.length > 0 && tokenized[tokenized.length-1] == 'zip'){
  //           item.pia_section_6.consultation_and_recommendation_file_type = 'zip' 
  //           this.store.ref(`${item.pia_section_6.consultation_and_recommendation_file_folder}/${item.pia_section_6.consultation_and_recommendation_file_name}`).getDownloadURL()
  //           .subscribe((url)=>{
  //             item.pia_section_6.consultation_and_recommendation_download_link = url
  //           })
  //         }else{
  //           const path = `${item.pia_section_6.consultation_and_recommendation_file_folder}/${item.pia_section_6.consultation_and_recommendation_file_name}`;
  //           this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
  //             item.pia_section_6.consultation_and_recommendation_file = data;
  //             item.pia_section_6.file_page = 1; 
  //           });
  //         }
  //       }
  //       this.currentProposedRegulation = item
  //   })
  // }

  loadProposedRegulation(id:any,item:any){
    if(item) this.setPiaDetails(item)
    else{
      this.afs.collection('regulations-proposed-phase-02').doc(id).snapshotChanges()
      .pipe(first())
      .subscribe(async (info)=>{
          let item:any = info.payload.data()
          item.id = info.payload.id
          this.setPiaDetails(item)
      })
    }
  }

  setPiaDetails(item:any){
    console.log('item: ',item)

    if(item){
      // if(item.pia_section_1.policy_problem_file_folder && item.pia_section_1.policy_problem_file_name){
      //   let tokenized = item.pia_section_1.policy_problem_file_name.split(".")
      //   // change file type check
      //   if(tokenized && tokenized.length > 0 && tokenized[tokenized.length-1] == 'zip'){
      //     item.pia_section_1.policy_problem_file_type = 'zip';
      //     this.store.ref(`${item.pia_section_1.policy_problem_file_folder}/${item.pia_section_1.policy_problem_file_name}`).getDownloadURL()
      //     .subscribe((url)=>{
      //       item.pia_section_1.policy_problem_download_link = url
      //     })
      //   }else{
      //     const path = `${item.pia_section_1.policy_problem_file_folder}/${item.pia_section_1.policy_problem_file_name}`;

      //     this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
      //       item.pia_section_1.policy_problem_file = data;
      //       item.pia_section_1.file_page = 1; 
      //     });
      //   }
      // }

       
      // for(let pia4Ctr in item.pia_section_4){
      //   if(item.pia_section_4[pia4Ctr].compliance_file_name && item.pia_section_4[pia4Ctr].compliance_file_folder){
      //     const path = item.pia_section_4[pia4Ctr].compliance_file_folder+"/"+item.pia_section_4[pia4Ctr].compliance_file_name

      //     this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
      //       item.pia_section_4[pia4Ctr].compliance_file = data;
      //       item.pia_section_4[pia4Ctr].file_page = 1
      //     });
      //   }
      // }

      // if(item.pia_section_4_file && item.pia_section_4_file.compliance_file_folder && item.pia_section_4_file.compliance_file_name){
      //   let tokenized = item.pia_section_4_file.compliance_file_name.split(".")
      //   if(tokenized && tokenized.length > 0 && tokenized[tokenized.length-1] == 'zip'){
      //     item.pia_section_4_file.compliance_file_type = 'zip';
      //     this.store.ref(`${item.pia_section_4_file.compliance_file_folder}/${item.pia_section_4_file.compliance_file_name}`).getDownloadURL()
      //     .subscribe((url)=>{
      //       item.pia_section_4_file.compliance_file_download_link = url
      //     })
      //   } else{
      //     const path = `${item.pia_section_4_file.compliance_file_folder}/${item.pia_section_4_file.compliance_file_name}`;

      //     this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
      //       item.pia_section_4_file.compliance_file = data;
      //       item.pia_section_4_file.file_page = 1; 
      //     });
      //   }
      // }

      // if(item.pia_section_6.consultation_and_recommendation_file_folder && item.pia_section_6.consultation_and_recommendation_file_name){
      //   let tokenized = item.pia_section_6.consultation_and_recommendation_file_name.split(".")
      //   if(tokenized && tokenized.length > 0 && tokenized[tokenized.length-1] == 'zip'){
      //     item.pia_section_6.consultation_and_recommendation_file_type = 'zip' 
      //     this.store.ref(`${item.pia_section_6.consultation_and_recommendation_file_folder}/${item.pia_section_6.consultation_and_recommendation_file_name}`).getDownloadURL()
      //     .subscribe((url)=>{
      //       item.pia_section_6.consultation_and_recommendation_download_link = url
      //     })
      //   }else{
      //     const path = `${item.pia_section_6.consultation_and_recommendation_file_folder}/${item.pia_section_6.consultation_and_recommendation_file_name}`;
      //     this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
      //       item.pia_section_6.consultation_and_recommendation_file = data;
      //       item.pia_section_6.file_page = 1; 
      //     });
      //   }
      // }
      
    if(item.pia_section_1.policy_problem_file_folder && item.pia_section_1.policy_problem_file_name){
      item.pia_section_1.fileFolder = item.pia_section_1.policy_problem_file_folder
      item.pia_section_1.fileName = item.pia_section_1.policy_problem_file_name
      const path = `${item.pia_section_1.policy_problem_file_folder}/${item.pia_section_1.policy_problem_file_name}`;
      let tokenized = item.pia_section_1.policy_problem_file_name.split(".")
      if(tokenized && tokenized.length > 0 && tokenized[tokenized.length-1] == 'zip'){
        item.pia_section_1.file_type = 'zip';
        this.store.ref(path).getDownloadURL()
        .subscribe((url)=>{
          item.pia_section_1.file_download_link = url
        })
      }
      else{
        this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
          item.pia_section_1.file = data;
          item.pia_section_1.file_page = 1; 
        });
      }
    }
      
    for(let pia4Ctr in item.pia_section_4){
      // item.pia_section_4[pia4Ctr]
      // console.log(item.pia_section_4[pia4Ctr])
      if(item.pia_section_4[pia4Ctr].compliance_file_name && item.pia_section_4[pia4Ctr].compliance_file_folder){
        item.pia_section_4[pia4Ctr].fileFolder = item.pia_section_4[pia4Ctr].compliance_file_folder
        item.pia_section_4[pia4Ctr].fileName = item.pia_section_4[pia4Ctr].compliance_file_name
        const path = item.pia_section_4[pia4Ctr].compliance_file_folder+"/"+item.pia_section_4[pia4Ctr].compliance_file_name

        this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
          item.pia_section_4[pia4Ctr].file = data;
          item.pia_section_4[pia4Ctr].file_page = 1
        });
      }
    }

    if(item.pia_section_4_file){
      item.pia_section_4_file.fileFolder = item.pia_section_4_file.compliance_file_folder
      item.pia_section_4_file.fileName = item.pia_section_4_file.compliance_file_name
      let tokenized = item.pia_section_4_file.compliance_file_name.split(".")
      const path = `${item.pia_section_4_file.compliance_file_folder}/${item.pia_section_4_file.compliance_file_name}`;
      if(tokenized && tokenized.length > 0 && tokenized[tokenized.length-1] == 'zip'){
        item.pia_section_4_file.file_type = 'zip'
        this.store.ref(path).getDownloadURL()
        .subscribe((url)=>{
          item.pia_section_4_file.file_download_link = url
        })
      }else{
        this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
          item.pia_section_4_file.file = data;
          item.pia_section_4_file.file_page = 1; 
        });
      }
    }


    if(item.pia_section_6.consultation_and_recommendation_file_folder && item.pia_section_6.consultation_and_recommendation_file_name){
      item.pia_section_6.fileFolder = item.pia_section_6.consultation_and_recommendation_file_folder
      item.pia_section_6.fileName = item.pia_section_6.consultation_and_recommendation_file_name
      const path = `${item.pia_section_6.consultation_and_recommendation_file_folder}/${item.pia_section_6.consultation_and_recommendation_file_name}`;
      let tokenized = item.pia_section_6.consultation_and_recommendation_file_name.split(".")
      if(tokenized && tokenized.length > 0 && tokenized[tokenized.length-1] == 'zip'){
        item.pia_section_6.file_type = 'zip'
        this.store.ref(path).getDownloadURL()
        .subscribe((url)=>{
          item.pia_section_6.file_download_link = url
        })
      }else{
        this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
          item.pia_section_6.file = data;
          item.pia_section_6.file_page = 1; 
        });
      }
    }

      this.currentProposedRegulation = item
    }
  }

  pageShift(action:any,section:any,index?:any){
    if(section == 'pia1'){
      if(action == "next"){
        if(this.currentProposedRegulation.pia_section_1.file_page < this.currentProposedRegulation.pia_section_1.file_total_page)
          this.currentProposedRegulation.pia_section_1.file_page++;
      }
      if(action == "back"){
        if(this.currentProposedRegulation.pia_section_1.file_page > 1)
          this.currentProposedRegulation.pia_section_1.file_page--;
      }
    }
    else if(section == 'pia4'){
      // if(action == "next"){
      //   if(this.currentProposedRegulation.pia_section_4[index].file_page < this.currentProposedRegulation.pia_section_4[index].file_total_page)
      //     this.currentProposedRegulation.pia_section_4[index].file_page++;
      // }
      // if(action == "back"){
      //   if(this.currentProposedRegulation.pia_section_4[index].file_page > 1)
      //     this.currentProposedRegulation.pia_section_4[index].file_page--;
      // }
      if(action == "next"){
        if(this.currentProposedRegulation.pia_section_4_file.file_page < this.currentProposedRegulation.pia_section_4_file.file_total_page)
          this.currentProposedRegulation.pia_section_4_file.file_page++;
      }
      if(action == "back"){
        if(this.currentProposedRegulation.pia_section_4_file.file_page > 1)
          this.currentProposedRegulation.pia_section_4_file.file_page--;
      }
    }
    else if(section == 'pia6'){
      if(action == "next"){
        if(this.currentProposedRegulation.pia_section_6.file_page < this.currentProposedRegulation.pia_section_6.file_total_page)
          this.currentProposedRegulation.pia_section_6.file_page++;
      }
      if(action == "back"){
        if(this.currentProposedRegulation.pia_section_6.file_page > 1)
          this.currentProposedRegulation.pia_section_6.file_page--;
      }
    }
  }

  afterLoadComplete(pdf: PDFDocumentProxy,section:any,index?:any){
    if(section === 'pia1'){
      this.currentProposedRegulation.pia_section_1.file_total_page = pdf.numPages;
    }
    else if(section === 'pia4'){
      // this.currentProposedRegulation.pia_section_4[index].file_total_page = pdf.numPages;
      this.currentProposedRegulation.pia_section_4_file.file_total_page = pdf.numPages;
    }
    else if(section === 'pia6'){
      this.currentProposedRegulation.pia_section_6.file_total_page = pdf.numPages;
    }
  }

  downloadFile(downloadLink:any, fileName:string, section:string){
    // var xhr = new XMLHttpRequest();
    // xhr.responseType = 'blob';
    // xhr.onload = (event) => {
    //   var blob = xhr.response;
    // };
    // xhr.onerror = (event) =>{
    //   console.error('Error: ',event)
    // }
    // xhr.open('GET', downloadLink);
    // xhr.send();

    // const link = document.createElement(section);
    // link.setAttribute('target', '_blank');
    // link.setAttribute('href', downloadLink);
    // link.setAttribute('download', fileName);
    // document.body.appendChild(link);
    // link.click();
    // link.remove();

    window.open(downloadLink, '_blank')
  }

}
