import { Directive, HostListener } from '@angular/core';
import { NgControl, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: '[appTelephoneMask]'
})
export class TelephoneMaskDirective {

  constructor(public ngControl: NgControl) { }
  @HostListener('ngModelChange', ['$event'])
  onModelChange(event:any) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event:any) {
    this.onInputChange(event.target.value, true);
  }
  

  onInputChange(event:any, backspace:any) {
    let newVal = event.replace(/\D/g, '');
    if (backspace && newVal.length <= 5) {
      newVal = newVal.substring(0, newVal.length - 1);
    }
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1)'); //($1)
    } else if (newVal.length <= 7) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,4})/, '($1) $2'); //($1) $2
    } else if (newVal.length <= 11) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})/, '($1) $2-$3'); //($1) $2-$3
    } else {
      newVal = newVal.substring(0, 11);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})(\d{0,4})/, '($1) $2-$3'); //($1) $2-$3-$4
    }

    ((this.ngControl as NgControl).valueAccessor as ControlValueAccessor).writeValue(newVal);
  }

}