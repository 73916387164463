export enum EmailTemplateCode {
	NEW_USER_REGISTRATION = 1,
	USER_NEW_SUBSCRIPTION = 2,
	USER_SUBSCRIBRED_REGULATION_OPENED = 3,
	USER_SUBSCRIBRED_REGULATION_CLOSED = 4,
	USER_SUBMIT_COMMENT = 5,
	AGENCY_FEEDBACK_ON_USER_COMMENT = 6,
	USER_SUBSCRIBRED_REGULATION_PUBLISHED = 7,
	USER_SUBSCRIBRED_REGULATION_AMENDED = 8,
	USER_SUBSCRIBRED_REGULATION_REPEALED = 9,
	USER_MULTIPLE_LOGIN_FAILED = 10,
	USER_PASSWORD_CHANGE_REQUEST = 11,
	USER_PASSWORD_CHANGE_SUCCESS = 12,
	USER_SUBSCRIBRED_REGULATION_UPDATED = 13,

	GOV_NEWLY_REGISTERED = 51,
	ADMIN_REGISTERED_GOVERNMENT_USER = 53,	

	ARTA_ASSIGNED_TO_REGULATIONS_FOR_REVIEW = 81,
	ARTA_ASSIGNED_REGULATION_RETURNED_FOR_MODIFICATION = 82,
	// ARTA_ASSIGNED_REGULATION_FOR_REVISION = 83,
	ARTA_ASSIGNED_REGULATION_NEXT_LEVEL = 85,
	ARTA_RETURNING_REGULATION_WITH_COMMENTS = 86,
    
	// AGENCY_ENCODER_ASSIGNED_FOR_ENCODING_REGULATION = 111,
	AGENCY_ENCODER_FORWARD_REGULATION_TO_VERIFIER = 111,
	AGENCY_VERIFIER_RECIEVES_REGULATION_FOR_VERIFICATION = 112,
	AGENCY_VALIDATOR_INDICATED_REGULATION_REVISION = 113, // can be sent back by either verifier or agency head
	AGENCY_ENCODER_RECEIVES_REGULATION_FOR_REVISION = 114,
	AGENCY_VERIFIER_SUBMITS_REGULATION_FOR_APPROVAL = 115,
	AGENCY_VERIFIER_SUBMITS_REVISED_REGULATION_FOR_APPROVAL = 116,
	AGENCY_HEAD_RECEIVES_REGULATION_FOR_APPROVAL = 117,
	AGENCY_HEAD_RECEIVES_REVISED_REGULATION_FOR_APPROVAL = 118, // only get this if revision is for revision by agency head
	AGENCY_HEAD_SUBMITS_TO_ARTA = 119,
	AGENCY_HEAD_POSTS_REG = 120,

	// AGENCY_VALIDATOR_RECIEVED_RETURNED_FROM_ARTA = 119,
	// AGENCY_VALIDATOR_REGULATION_CLEARD_BY_ARTA = 120,
    
	GOV_CREATE_CONSULTATION = 141,
	GOV_END_OF_DAY_MAIL = 142,
	GOV_CONSULTATION_SUCCESSFULLY_UPDATED = 143,
	GOV_CONSULTATION_SUCCESSFULLY_CLOSED = 144,
    
	GOV_TICKETS_REQUEST_ADD_TAGS = 161,
	GOV_TICKETS_ERROR_REPORTED = 162,

	TEST_DYNAMIC_EMAIL = 999,
	TEST_EMAIL = 500,

	//ARTEMIS TEMPLATE CODES

	//Common to all users
	USER_PROFILE_UPDATED = 601,

	//AGENCY LEVEL
	AGENCY_LEAD_ENCODER_SUBMIT_ALL_SERVICES_TO_VERIFIER = 301,
	AGENCY_LEAD_VERIFIER_SUBMIT_CC_TO_AGENCY_HEAD = 302,
	AGENCY_ENCODER_RESUBMIT_SERVICE_TO_VERIFIER = 303,
	AGENCY_VERIFIER_RETURN_SERVICE_TO_ENCODER = 304,
	AGENCY_HEAD_RETURN_CC_TO_ENCODER =305,
	AGENCY_ADMIN_CREATED_ACCOUNT = 306,
	AGENCY_HEAD_SUBMIT_CC_TO_ARTA = 307,
	AGENCY_HEAD_SUBMIT_COC_TO_ARTA = 308,

	//ARTA LEVEL
	ARTA_PO_SUBMIT_CC_TO_NEXT_LEVEL = 401,
	ARTA_DC_SUBMIT_CC_TO_NEXT_LEVEL = 402,
	ARTA_DIR_POST_CC = 403,
	ARTA_DC_RETURN_SERVICE_TO_PO = 404,
	ARTA_DIR_RETURN_SERVICE_TO_PO = 405,
	ARTA_DIR_RETURN_CC_TO_ENCODER = 406,

	//PUBLIC LEVEL
	PUBLIC_SUBSCRIBED_TO_AGENCY = 501,
	PUBLIC_SUBSCRIBED_TO_SERVICE = 502,
	PUBLIC_COMMENTED_ON_SERVICE = 503,
	PUBLIC_SUBMIT_SURVEY_TO_ARTEMIS = 504,

	//User Account Creation ARTEMIS
	AGENCY_ADMIN_CREATE_ACCOUNT = 505
}

export enum EmailAppCode{
	TEST = 99,
	PBRIS = 1,
	ARTA = 1,
	ARTEMIS = 2,
}