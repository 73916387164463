<!--NAVBAR-->
<app-artemis-navigation-header [currentPage]="'Charter'"></app-artemis-navigation-header>

<div *ngIf="isAgency || isAdmin" class="container-fluid">
    <!--preview-->
    <div class="card" *ngIf="pageTag == 'preview'">
        <!--card head-->
        <div class="card-head m-5 mb-0">
            <h3>New Citizen's Charter <label *ngIf="currentStatus">({{currentStatus | titlecase}})</label></h3>
        </div>
        <!--card body-->
        <div class="card-body form-container m-5 mt-0">
            <!--step 2/3: services-->
            <ng-container *ngIf="currentFormPage=='servicelist' ">
                <div class="form-header ">
                    <h3>Services</h3>
                </div>
                <div class="form-group mt-5 ">
                    <table class="table ">
                        <thead>
                            <tr>
                                <th>
                                    Service Name
                                </th>
                                <th>
                                    Total Client Steps
                                </th>
                                <th>
                                    Total Fees
                                </th>
                                <th>
                                    Total Processing Time
                                </th>
                                <th>
                                    Is Complete Service?
                                </th>
                                <th>
                                </th>
                                <th>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let service_item of display_service_form; let i=index">
                                <!-- <td *ngIf="!isEditMode ">{{service_item.service_name}}</td> -->
                                <!-- <td *ngIf="isEditMode " routerLink="/artemis/view/detail/{{service_item.id}} ">{{service_item.service_name}}</td> -->
                                <td>{{service_item.service_name}}</td>
                                <td>{{service_item.client_steps_length}}</td>
                                <td>{{service_item.total_fees | currency:'₱':'symbol':'1.2-2'}}</td>
                                <td>{{service_item.total_processing_time}}</td>
                                <td [ngSwitch] = "service_item.is_valid_str" style="text-align: center;" >
                                    <div class="align-items-center">
                                        <div *ngSwitchCase="'VALID'">
                                            <h4><span><i class="fas fa-check-circle text-success"></i></span></h4>
                                        </div>
                                        <div *ngSwitchCase="'INCOMPLETE'">
                                            <h4><span><i class="fas fa-times-circle text-danger"></i></span></h4>
                                        </div>
                                        <div *ngSwitchDefault>
                                            <h6><span class="badge rounded-pill bg-info">{{service_item.is_valid_str}}</span></h6>
                                        </div>
                                    </div>
                                    
                                </td>
                                <td><button (click)="callServiceCRUD(service_item.full_service_form,service_item.id,i,service_item.validity_map) " class="btn btn-success ">Edit</button></td>
                                <!-- Delete service? How about the worklist attached to it? -->
                                <td><button *ngIf="deleteEnabled " (click)="deleteService(service_item.id,i) " class="btn btn-danger ">Delete</button></td>
                            </tr>
                        </tbody>
                    </table>
                    <!--callServiceCRUD-->
                    <div *ngIf="!isEditMode && !newUpdateWorklist">
                        <button mat-raised-button (click)="openNewServiceDialog() " class="btn "><span class="material-icons">
                            add_circle_outline
                        </span>Add New Service</button>
                        <hr>
                    </div>
                </div>
                <div *ngIf="isEditMode " class="form-group mt-5 btn-buttons">
                    <button class="btn btn-outline-dark m-2" (click)="backToCharter()" id="bt ">Back</button>
                    <button mat-stroked-button class="btn m-3" [disabled]="processLoader" (click)="updateService()">
                        <div *ngIf="updateServiceLoader" class="spinner-border text-warning" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Update services
                    </button>
                    <button mat-stroked-button class="btn btn-warning m-2" [disabled]="processLoader || !validForm" (click)="submitService()">
                        <div *ngIf="submitServiceLoader" class="spinner-border text-light" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Submit
                    </button>
                </div>
                <div *ngIf="!isEditMode " class="form-group mt-5 btn-buttons">
                    <button mat-stroked-button class="btn m-2 " (click)="backToCharter()">Cancel</button>
                    <button mat-stroked-button class="btn m-3 " [disabled]="service_form.length === 0 || processLoader" (click)="saveAllAsDraft() ">
                        <div *ngIf="saveAllAsDraftLoader" class="spinner-border text-warning" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Save as Draft
                    </button>
                    <button mat-stroked-button class="btn btn-warning m-2 " [disabled]="service_form.length === 0 || processLoader  || !validForm"  (click)="submitAllForms() " id="bt ">
                        <div *ngIf="submitAllFormsLoader" class="spinner-border text-light" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Submit
                    </button>
                </div>
            </ng-container>
        </div>
    </div>

</div>