

<div *ngFor="let remark of filteredRemarks; let index = index">

    <app-artemis-service-remark 
    [remark]="remark"
    [allowedAction]="openRemarks"
    ></app-artemis-service-remark>

</div>


<form  [formGroup]="remark_form" *ngIf="user_position !=  position_tpye.AGENCY_ENC && openRemarks" class="mt-3" action="" (click)="$event.stopPropagation()">

    <label *ngIf="disableControl">Saving...</label>
    <mat-form-field  class="example-full-width" appearance="fill">
        <mat-label>New Remark</mat-label>
        <textarea formControlName="remark" [disabled]="disableControl" (focusout)="writeRemark(input)" (input)="valuechange($event)" matInput #input ></textarea>
        <mat-icon  matTooltip="Save Remark"  matSuffix>save</mat-icon>
        <mat-hint class="text-danger" *ngIf="someoneistyping && input.value && !disableControl">Don't forget the save your remark!</mat-hint>
    </mat-form-field>

     
</form>