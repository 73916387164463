
          
        <div class="d-flex justify-content-center align-self-center my-5" *ngIf="loading">
            <div class="spinner-grow text-info" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <div *ngIf="!loading">
            <div *ngIf="isPhase2">

                <!-- <div class="card-header info-header">
                    <div class="row border-top border-primary border-1 py-3 px-2 mb-2" style="background: rgba(239, 239, 239, .5);">
                        <div class="ms-3">
                            <h6 class="fw-bold">Regulation Details
                                <button *ngIf="commentButtonsEnabled" mat-icon-button aria-label="Comment on regulation details" (click)="goToComments('regdetails')">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </h6>
                        </div>
                    </div>
                </div> -->

                <!-- Title-->
                <div class="mt-3">
                    <div class="row">
          
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead class="info-subheader">
                                    <tr></tr>
                                </thead>
            
                                <tbody>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Regulation Title</th>
                                        <td><textarea class="form-control" rows="4" [value]="phase2Worklist.title" disabled readonly></textarea></td>
                                    </tr>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Regulation Short Title</th>
                                        <td><textarea class="form-control" rows="2" [value]="phase2Worklist.subject"  disabled readonly></textarea></td>
                                    </tr>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Regulation Instrument</th>
                                        <td><input type="text" class="form-control" [value]="phase2Worklist.instrument_name ? phase2Worklist.instrument_name : '--'" disabled readonly/></td>
                                    </tr>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Issuing Agency</th>
                                        <td>
                                            <ul>
                                                <li *ngFor="let row of agency_list">
                                                    {{row}}
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Regulation Number</th>
                                        <td>
                                            <input type="text" class="form-control" 
                                            [value]="(collection == 'regulations-existing-phase-02')? phase2Worklist.reg_number ? phase2Worklist.reg_number : '' : (collection == 'regulations-proposed-phase-02') ? phase2Worklist.tags.reg_number ? phase2Worklist.tags.reg_number : '--' : '--'"
                                            disabled readonly/>
                                        </td>
                                    </tr>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Regulatory Significance</th>
                                        <td><input type="text" class="form-control" [value]="phase2Worklist.reg_significance? phase2Worklist.reg_significance : '--'" disabled readonly/></td>
                                      </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                <!-- Date & File -->
                <div class="mt-3">
                    <div class="row">
          
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead class="info-subheader">
                                    <tr></tr>
                                </thead>
            
                                <tbody>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">File Uploaded</th>
                                        <th style="background-color:#FFFFFF;">
                                            <a [download]="phase2Worklist.file_file_name" target="_blank" href="{{existing_reg_download_url}}" 
                                                class="btn btn-primary btn-sm btn-custom" matTooltipPosition="above" matTooltip="Open in New Tab">
                                                <i class="fas fa-eye me-2"></i>VIEW
                                            </a>
                                        </th>
                                    </tr>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Issued Date</th>
                                        <td><input type="text" class="form-control" [value]="phase2Worklist.doc_date ? phase2Worklist.doc_date : '--'" disabled readonly/></td>
                                    </tr>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Date Published (ONAR)</th>
                                        <td><input type="text" class="form-control" [value]="phase2Worklist.date_published_onar ? phase2Worklist.date_published_onar : '--'" disabled readonly/></td>
                                    </tr>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Date Published (Official Gazette)</th>
                                        <td><input type="text" class="form-control" [value]="phase2Worklist.date_published_gazette ? phase2Worklist.date_published_gazette : '--'" disabled readonly/></td>
                                    </tr>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Date of Effectivity</th>
                                        <td><input type="text" class="form-control" [value]="phase2Worklist.date_effective ? phase2Worklist.date_effective : '--'" disabled readonly/></td>
                                    </tr>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Effectivity Type</th>
                                        <td><input type="text" class="form-control" [value]="phase2Worklist.effectivity_type ? phase2Worklist.effectivity_type : '--'" disabled readonly/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                <!-- Legal Bases & International Agreements & file-->
                <div class="mt-3">
                    <div class="row">
          
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead class="info-subheader">
                                    <tr></tr>
                                </thead>
            
                                <tbody>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold" colspan="2" for="title">Legal Bases</th>
                                        <td>
                                            <ul>
                                                <li *ngFor="let row of legalBases_list">
                                                    <i class="fas fa-circle fa-xs me-3"></i>{{row}}
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>

                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold" colspan="2" for="title">International Agreements</th>
                                        <td>
                                            <ul>
                                                <li *ngFor="let row of internationalAgreement_list">
                                                    <i class="fas fa-circle fa-xs me-3"></i>{{row}}
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                <!-- Main Tag-->
                <div class="mt-3">
                    <div class="row">
          
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead class="info-subheader">
                                    <tr></tr>
                                </thead>
            
                                <tbody>
                                    <tr class="info-subheader">
                                       <th colspan="2" class="fw-bold"><i class="fas fa-tag me-3" style="color:#FCD116;"></i>Main Tag</th>
                                    </tr>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Classification</th>
                                        <td>
                                            <div class="form-check">
                                                <mat-radio-group class="example-radio-group d-flex flex-row justify-content-evenly">
                                                    <mat-radio-button
                                                    [checked]="(phase2Worklist.reg_classification == 'Business') || (phase2Worklist.tags && (phase2Worklist.tags.reg_classification == 'Business'))"
                                                    value="Business" class="example-radio-button" disabled readonly>Business</mat-radio-button>
                                                    
                                                    <mat-radio-button
                                                    [checked]="(phase2Worklist.reg_classification == 'Non-Business') || (phase2Worklist.tags && (phase2Worklist.tags.reg_classification == 'Non-Business'))"
                                                    value="Non-Business" class="example-radio-button" disabled readonly>Non-Business</mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>

                                <tbody *ngIf="(phase2Worklist.reg_classification ==  'Business') || (phase2Worklist.tags && (phase2Worklist.tags.reg_classification == 'Business'))">
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Sector</th>
                                        <td><input type="text" class="form-control" [value]="phase2Worklist.sector_name ? phase2Worklist.sector_name : '--'" disabled readonly/></td>
                                    </tr>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Stage of Business</th>
                                        <td><input type="text" class="form-control" [value]="phase2Worklist.business_name ? phase2Worklist.business_name : '--'"  disabled readonly/></td>
                                    </tr>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Division</th>
                                        <td><input type="text" class="form-control" [value]="phase2Worklist.division_name ? phase2Worklist.division_name : '--'"  disabled readonly/></td>
                                    </tr>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Case Use</th>
                                        <td><input type="text" class="form-control" [value]="phase2Worklist.case_name ? phase2Worklist.case_name : '--'" disabled readonly/></td>
                                    </tr>
                                </tbody>

                                <tbody *ngIf="(phase2Worklist.reg_classification == 'Non-Business') || (phase2Worklist.tags && (phase2Worklist.tags.reg_classification == 'Non-Business'))">
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Stage of Life</th>
                                        <td><input type="text" class="form-control" [value]="phase2Worklist.stageoflife_name ? phase2Worklist.stageoflife_name : '--'" disabled readonly/></td>
                                    </tr>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Life Event</th>
                                        <td><input type="text" class="form-control read-only" [value]="phase2Worklist.lifeevent_name ? phase2Worklist.lifeevent_name : '--'" disabled readonly/></td>
                                    </tr>
                                </tbody>

                                <tbody>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Jurisdiction</th>
                                        <td><input type="text" class="form-control" [value]="phase2Worklist.juris_name ? phase2Worklist.juris_name : '--'" disabled readonly/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                <!-- Sub Tag-->
                <div class="mt-3" *ngIf="phase2Worklist.has_sub_tags">
                    <div class="row" *ngFor="let subTag of phase2Worklist.sub_tags; let i = index; let c = count">
          
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead class="info-subheader">
                                    <tr></tr>
                                </thead>
            
                                <tbody>
                                    <tr class="info-subheader">
                                       <th colspan="2" class="fw-bold"><i class="fas fa-tags me-3" style="color:#FCD116;"></i>Sub Tag</th>
                                    </tr>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Classification</th>
                                        <td>
                                            <div class="form-check">
                                                <mat-radio-group class="example-radio-group d-flex flex-row justify-content-evenly">
                                                    <mat-radio-button 
                                                    [checked]="subTag.reg_classification == 'Business'" value="Business" class="example-radio-button" 
                                                    disabled readonly>Business</mat-radio-button>
            
                                                    <mat-radio-button 
                                                    [checked]="subTag.reg_classification == 'Non-Business'" value="Non-Business"
                                                    class="example-radio-button" disabled readonly>Non-Business</mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>

                                <tbody *ngIf="subTag.reg_classification == 'Business'">
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Sector</th>
                                        <td><input type="text" class="form-control" [value]="subTag.sector_name ? subTag.sector_name : '--'" disabled readonly/></td>
                                    </tr>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Stage of Business</th>
                                        <td><input type="text" class="form-control" [value]="subTag.business_name ? subTag.business_name : '--'" disabled readonly/></td>
                                    </tr>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Division</th>
                                        <td><input type="text" class="form-control" [value]="subTag.div_name ? subTag.div_name : '--'" disabled readonly/></td>
                                    </tr>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Case Use</th>
                                        <td><input type="text" class="form-control" [value]="subTag.case_name ? subTag.case_name : '--'" disabled readonly/></td>
                                    </tr>
                                </tbody>

                                <tbody *ngIf="subTag.reg_classification == 'Non-Business'">
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Stage of Life</th>
                                        <td><input type="text" class="form-control" [value]="subTag.sol_name ? subTag.sol_name : '--'" disabled readonly/></td>
                                    </tr>
                                    <tr class="info-subheader">
                                        <th class="col-md-3 col-sm-12 fw-bold">Life Event</th>
                                        <td><input type="text" class="form-control read-only" [value]="subTag.le_name ? subTag.le_name : '--'" disabled readonly/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

            </div>
        </div>

   
