import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-productivity-proposed-regulations',
  templateUrl: './productivity-proposed-regulations.component.html',
  styleUrls: ['./productivity-proposed-regulations.component.scss']
})
export class ProductivityProposedRegulationsComponent implements OnInit {

  report_count: any = [];
  draft_result: any = [];
  verify_result: any = [];
  
  dateInfo: any;

  
  //PER STAGE
  pre_rule_count: any = [];
  pre_rule_assessment_count: any = [];
  proposed_rule_count: any = [];
  proposed_rule_assessment_count: any = [];
  interim_rule_count: any = [];
  final_rule_count: any = [];

  //BY SIGNIFICANCE
  major_count: any = [];
  exempted_count: any = [];
  emergency_count: any = [];
  minor_count: any = [];

  //BY ARTA ASSESSMENT (RIS)
  best_practice_count: any = [];
  satisfactory_count: any = [];
  returned_count: any = [];

  constructor(private afs: AngularFirestore, public auth: AngularFireAuth, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.loadWorklistCounts();
    this.loadRegulationProposedCounts();
  }
  
  receivedDatePicker(p:any){
    this.dateInfo = p;
  }

  async loadWorklistCounts() {
    this.report_count = [];

    this.afs.collection("Worklist-Proposed Regulations").snapshotChanges()
      .pipe(
        tap(
          (data: any) => {
            data.forEach((info: any) => {
              let item: any = info.payload.doc.data();
              item.id = info.payload.doc.id;
              
              this.report_count.push(item);

              if(item['status'] == "FOR VERIFICATION"){
                this.verify_result = []
                this.verify_result.push(item)
              }
              else if(item['status'] == "DRAFT"){
                this.draft_result = []  
                this.draft_result.push(item)
              }
             
            });
          }
        )
      )
      .subscribe()
  }  

  async loadRegulationProposedCounts(){

    this.afs.collection("regulations-proposed-phase-02").snapshotChanges()
      .pipe(
        tap(
          (data: any) => {
            data.forEach((info: any) => {
              let item: any = info.payload.doc.data();
              item.id = info.payload.doc.id;
              
              if(item['status'] == "Pre-rule"){
                this.pre_rule_count = []
                this.pre_rule_count.push(item)
              } 

              else if(item['status'] == "Pre-rule Assessment"){
                this.pre_rule_assessment_count = []
                this.pre_rule_assessment_count.push(item)
              } 

              else if(item['status'] == "Proposed Rule"){
                this.proposed_rule_count = []
                this.proposed_rule_count.push(item)
              } 

              else if(item['status'] == "Proposed Rule Assessment"){
                this.proposed_rule_assessment_count = []
                this.proposed_rule_assessment_count.push(item)
              } 
              else if(item['status'] == "Interim Rule"){
                this.interim_rule_count = []
                this.interim_rule_count.push(item)
              } 

              else if(item['status'] == "Final Rule"){
                this.final_rule_count = []
                this.final_rule_count.push(item)
              } 

              //BY SIGNIFICANCE
              else if(item['reg_significance'] == "Major"){
                this.major_count = []
                this.major_count.push(item)
              }
              else if(item['reg_significance'] == "Exempted"){
                this.exempted_count = []  
                this.exempted_count.push(item)
              }
              else if(item['reg_significance'] == "Emergency"){
                this.emergency_count = []  
                this.emergency_count.push(item)
              }
              else if(item['reg_significance'] == "Minor"){
                this.minor_count = []  
                this.minor_count.push(item)
              }             
            });
          }
        )
      )
      .subscribe()      
  }

}
