import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2'
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-artemis-user-feedback',
  templateUrl: './artemis-user-feedback.component.html',
  styleUrls: ['./artemis-user-feedback.component.scss']
})

export class ArtemisUserFeedbackComponent implements OnInit {
  loading: boolean = false;
  featureAccessList: any;
  overall_rate: any;
  visit_site: any;
  refer_site: any;
  others: boolean = false;
  commentView: number = 0;
  discoverSelectedValue: any = ""
  todayDate : Date = new Date();

  // phase 1 - sample form
  phase1form = new FormGroup({ //date
      application: new FormControl('feedback-artemis', Validators.required),
      feature: new FormControl('', Validators.required),
      overall: new FormControl('', Validators.required),
      elaborate: new FormControl(''),
      improve: new FormControl(''),
      experience: new FormControl(''),
      visit: new FormControl('', Validators.required),
      refer: new FormControl('', Validators.required),
      discover: new FormControl('', Validators.required),
      enhance: new FormControl('', Validators.required),
      others: new FormControl(''),
      otherDiscover: new FormControl(''),
      created_at: new FormControl(formatDate(this.todayDate, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0800'))
    });

  constructor(private afs: AngularFirestore, private router: Router,) { }

  ngOnInit(): void {
    this.getFeatureAccessed().subscribe(data => {
      //console.log(this.featureAccessList);
      this.featureAccessList = data.map(e => {
        let retObj: any = e.payload.doc.data();
        retObj.id = e.payload.doc.id;
        return retObj;
      })
      
      // Code-level sort to reduce the need for a Firestore index
      this.featureAccessList.sort((a: any, b: any) => {
        return a.order_num - b.order_num;
      });
    })
  }

  getFeatureAccessed() {
    return this.afs.collection('Feature-Accessed', ref => ref.where('site','==','ARTEMIS')).snapshotChanges();
  }

  starOnChange(data: any) {
    if (data.target.name == 'overall') {
      this.overall_rate = data.target.value ? data.target.value : ''
      if (data.target.value == 'Excellent') {
        this.commentView = 1
      } else if (data.target.value == 'Very satisfy' || data.target.value == 'Satisfy') {
        this.commentView = 2
      } else if (data.target.value == 'Very good' || data.target.value == 'Good') {
        this.commentView = 3
      } else {
        this.commentView = 0
      }
    } else if (data.target.name == 'visit') {
      this.visit_site = data.target.value ? data.target.value : ''
    } else if (data.target.name == 'refer') {
      this.refer_site = data.target.value ? data.target.value : ''
    }
  }

  discoverOnChange(data: any) {
    
    if (data.value == 'Others') {
      this.others = true
    } else {
      this.others = false
    }
  }

  resetForm(){
    // this.loading = false;
    // this.featureAccessList = '';
    // this.overall_rate = {};
    // this.visit_site = '';
    // this.refer_site = '';
    this.others = false;
    this.commentView = 0;
 
  }

  async onSubmit() {
    this.loading = true;
    let documentID: any;

    await Swal.fire({
      title: 'Terms and conditions',
      input: 'checkbox',
      inputValue: 0,
      allowOutsideClick: false,
      showCancelButton: true,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      },
      html: 
        '<p class="text-left" style="text-align: left; font-size: medium;">You can use <b>bold text</b>, ' +
        '<a href="//sweetalert2.github.io">links</a> ' +
        'and other HTML tags,' +
        ' Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. <br><br>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'+
        '</p>',
      inputPlaceholder:
        'I agree with the terms and conditions',
      confirmButtonText:
        'Proceed',
      inputValidator: (result) => {
        return !result ? 'You need to agree with T&C' : ''
      }
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: 'Processing',
          allowOutsideClick: false,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            
            if(this.phase1form.valid){
              this.phase1form.addControl('terms and conditions',new FormControl(1, Validators.required));
              this.afs.collection(`Feedback Artemis`).add(this.phase1form.value).then((docRef:any) => {
                this.loading = false;
                Swal.close();
                Swal.fire({
                  title:'Successfully processed',
                  icon: 'info'
                }).then(() => {
                  this.router.navigate(['/artemis',]);
                });
              }).catch((error:any) =>{
                this.loading = false;
                Swal.close();
                Swal.fire({
                  title:'System error',
                  titleText: 'Please refresh the page',
                  icon: 'error'
                });
              });
            } else {
              this.loading = false;
              Swal.close();
              Swal.fire({
                title:'Warning',
                titleText: 'Please fill out the required fields',
                icon: 'warning'
              });
            }  
          }
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.loading = false;
      }
    })
  }
}
