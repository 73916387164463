<!-- 

<mat-accordion>
  <mat-expansion-panel class="mb-2" [expanded]="true" >
    <mat-expansion-panel-header >
      <mat-panel-title>
        <div class="row w-100" >
          <div class="row" >
            <div class="col-8">
              <p style="margin-bottom: 0;" >{{  node.name }}</p>
              <span style="font-size: 12px; font-style: italic; margin: 0;">{{ node.type === 'Others'? node.others : node.type }}</span>
            </div>
            <div  *ngIf="leadEncoder" class="bc col-4 d-flex justify-content-end align-items-center">
              <span class="bttn btn-add" (click)="addNodethisChild()"><span><i class="fa fa-plus-circle"></i></span> Office</span>
              <span matTooltip="Remove Office" class="bttn btn-rem" (click)="removeNode(node)" ><span><i class="fas fa-trash"> </i></span></span>
              <span matTooltip="Edit Office" class="bttn btn-edt"(click)="EditNode(node)"><span><i class="far fa-edit"> </i></span></span>
            </div>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="row ms-5" *ngIf="node?.children && node.children.length > 0" >
      <ng-container >
        <div *ngFor="let childnode of node.children;let i= index" >
          <app-artemis-office-model-view
            [node]="childnode"
            [index]="i"
            [office_id]="office_id"
            [referenceArray]="node.children"
            [leadEncoder]="leadEncoder" 
          ></app-artemis-office-model-view>
        </div>
      </ng-container>
    </div>

  </mat-expansion-panel>
 -->


 
<p (mouseover)="viewElement = true" (mouseleave)="viewElement = false" style="margin-bottom: 0;" ><span class="text-success" *ngIf="node?.children && node.children.length > 0" (click)="expanded = !expanded"><i [ngClass]="expanded? 'fa fa-minus-circle' : 'fa fa-plus-circle'" > </i></span>  <span [ngClass]="parent? 'fw-bold':''">{{  node.name }}</span> - <span style="font-size: 12px; font-style: italic; margin: 0;" class="text-muted">{{ node.type === 'Others'? node.others : node.type }} </span>
  
    <ng-container  *ngIf="leadEncoder && viewElement">
      <span matTooltip="Add Subffice " class="bttn btn-add mx-2" (click)="addNodethisChild()"><span><i class="fas fa-plus-square"></i></span></span>
      <span matTooltip="Remove Office" class="bttn btn-rem mx-2 text-danger" (click)="removeNode(node)" ><span><i class="fas fa-trash"> </i></span></span>
      <span matTooltip="Edit Office" class="bttn btn-edt mx-2"(click)="EditNode(node)"><span><i class="fas fa-edit"> </i></span></span>
    </ng-container>

</p>

    <ng-container *ngIf="node?.children && node.children.length > 0 && expanded">
      <ul class="tree">
  
        <li *ngFor="let childnode of node.children;let i= index">
          <app-artemis-office-model-view
            [node]="childnode"
            [index]="i"
            [office_id]="office_id"
            [referenceArray]="node.children"
            [leadEncoder]="leadEncoder" 
          ></app-artemis-office-model-view>
        </li>
  
      </ul>
    </ng-container>
  



      







