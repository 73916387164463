<div class="container">
    <div class="d-flex justify-content-center align-self-center" *ngIf="!isLoaded">
        <div class="spinner-grow text-info" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>

    <!-- REGULATION DETATILS -->
    <div class="row justify-content-center">
    
        <!-- VERSION BUTTONS -->
        <!-- <div class="table-responsive">
        <table class="table table-responsive table-hover">
            <thead>
                <tr>
                    <th class="fw-bold"><h5>Version</h5></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="regulation && regulation.existing" (click)="chooseVersion(regulation.existing.controlCode)">
                    <th>Existing Regulation</th>
                </tr>
                <tr *ngIf="regulation && regulation.dris" (click)="chooseVersion(regulation.dris.controlCode)">
                    <th>DRIS</th>
                </tr>
                <tr *ngIf="regulation && regulation.cris" (click)="chooseVersion(regulation.cris.controlCode)">
                    <th>CRIS</th>
                </tr>
                <tr *ngIf="regulation && regulation.proposed" (click)="chooseVersion(regulation.proposed.controlCode)">
                    <th>Proposed Regulation</th>
                </tr>
            </tbody>
            <div style="font-weight: bold; border-bottom: .4px solid black; padding:1em;">
                <h6>
                    <span> {{version ? version.toUpperCase() : "N/A"}}</span>
                </h6>
            </div>
        </table> 
        </div> -->

        <div class="col-12 info-header">
            <h6 class="fw-bold mb-0">REGULATION DETAILS</h6>
        </div>
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Regulation Short Title</h6>
        </div>
        <div class="col-md-9 col-sm-12 border-bottom">
            <textarea class="form-control" [value]="chosenRegulation.subject ? chosenRegulation.subject : '--'" disabled readonly></textarea>
        </div>
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Issuing Agency</h6>
        </div>
        <div class="col-md-9 col-sm-12 border-bottom">
            <ul class="fw-bold">
                <li class="form-control" *ngFor="let row of chosenRegulation.agency_list">
                    {{row}}
                </li>
            </ul>
        </div>
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Regulation Instrument</h6>
        </div>
        <div class="col-md-9 col-sm-12 border-bottom">
            <input type="text" class="form-control" [value]="chosenRegulation.instrument_name ? chosenRegulation.instrument_name : '--'" disabled readonly>
        </div>
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Issued Date</h6>
        </div>
        <div class="col-md-3 col-sm-12 border-bottom">
            <textarea class="form-control" [value]="chosenRegulation.doc_date ? chosenRegulation.doc_date : '--'" disabled readonly></textarea>
        </div>
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Regulation Number</h6>
        </div>
        <div class="col-md-3 col-sm-12 border-bottom">
            <textarea type="text" class="form-control" [value]="chosenRegulation.reg_number ? chosenRegulation.reg_number : '--'" disabled readonly></textarea>
        </div>
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Regulatory Significance</h6>
        </div>
        <div class="col-md-3 col-sm-12 border-bottom">
            <input type="text" class="form-control" [value]="chosenRegulation.reg_significance ? chosenRegulation.reg_significance : '--'" disabled readonly>
        </div>
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Jurisdiction</h6>
        </div>
        <div class="col-md-3 col-sm-12 border-bottom">
            <input type="text" class="form-control" [value]="chosenRegulation.juris_name ? chosenRegulation.juris_name : '--'" disabled readonly>
        </div>
    </div>

    <!--- DATES -->
    <div class="row justify-content-center">
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Date Published (ONAR)</h6>
        </div>
        <div class="col-md-3 col-sm-12 border-bottom">
            <input type="text" class="form-control" [value]="chosenRegulation.date_published_onar ? chosenRegulation.date_published_onar : '--'" disabled readonly/>
        </div>
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Date Published (Official Gazette)</h6>
        </div>
        <div class="col-md-3 col-sm-12 border-bottom">
            <input type="text" class="form-control" [value]="chosenRegulation.date_published_gazette ? chosenRegulation.date_published_gazette : '--'" disabled readonly/>
        </div>
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Date of Effectivity</h6>
        </div>
        <div class="col-md-3 col-sm-12 border-bottom">
            <input type="text" class="form-control" [value]="chosenRegulation.date_effective ? chosenRegulation.date_effective : '--'" disabled readonly/>
        </div>
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Effectivity Type</h6>
        </div>
        <div class="col-md-3 col-sm-12 border-bottom">
            <input type="text" class="form-control" [value]="chosenRegulation.effectivity_type ? chosenRegulation.effectivity_type : '--'" disabled readonly/>
        </div>
    </div>

    <!-- LEGAL BASES AND INTL AGREEMENTS -->
    <div class="row justify-content-center">
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Legal Bases</h6>
        </div>
        <div class="col-md-9 col-sm-12 border-bottom">
            <ul class="fw-bold" style="padding: 1rem 1.4rem;">
                <li *ngFor="let row of chosenRegulation.legalBases_list">
                    <i class="fas fa-minus fa-xs me-3"></i>{{row}}
                </li>
            </ul>
        </div>
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>International Agreements</h6>
        </div>
        <div class="col-md-9 col-sm-12 border-bottom">
            <ul class="fw-bold" style="padding: 1rem 1.4rem;">
                <li *ngFor="let row of chosenRegulation.internationalAgreement_list">
                    <i class="fas fa-minus fa-xs me-3"></i>{{row}}
                </li>
            </ul>
        </div>
    </div>

    <!-- MAIN TAG -->
    <div class="row justify-content-center mt-4">
        <div class="col-12 info-header">
            <h6 class="fw-bold mb-0"><i class="fas fa-tag me-3" style="color: #FCD116;"></i>Main Tag</h6>
        </div>
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Classification</h6>
        </div>
        <div class="col-md-9 col-sm-12 border-bottom">
            <div class="form-check">
                <mat-radio-group class="example-radio-group d-flex flex-row justify-content-evenly">
                    <mat-radio-button [checked]="chosenRegulation.classification == 'Business'" value="Business" class="example-radio-button" disabled readonly>
                        Business
                    </mat-radio-button>
                    <mat-radio-button [checked]="chosenRegulation.classification == 'Non-Business'" value="Non-Business" class="example-radio-button" disabled readonly>
                        Non-Business
                    </mat-radio-button>
                    <mat-radio-button [checked]="chosenRegulation.classification == 'Government'" value="Government" class="example-radio-button" disabled readonly>
                        Government
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>
    <div class="row justify-content-center" *ngIf="chosenRegulation.classification == 'Business'">
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Sector</h6>
        </div>
        <div class="col-md-9 col-sm-12 border-bottom">
            <textarea class="form-control" [value]="chosenRegulation.sector_name ? chosenRegulation.sector_name : '--'" disabled readonly></textarea>
        </div>
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Division</h6>
        </div>
        <div class="col-md-9 col-sm-12 border-bottom">
            <textarea class="form-control" [value]="chosenRegulation.division_name ? chosenRegulation.division_name : '--'"  disabled readonly></textarea>
        </div>
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Stage of Business</h6>
        </div>
        <div class="col-md-3 col-sm-12 border-bottom">
            <input type="text" class="form-control" [value]="chosenRegulation.business_name ? chosenRegulation.business_name : '--'"  disabled readonly>
        </div>
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Case Use</h6>
        </div>
        <div class="col-md-3 col-sm-12 border-bottom">
            <input type="text" class="form-control" [value]="chosenRegulation.case_name ? chosenRegulation.case_name : '--'" disabled readonly>
        </div>
    </div>
    <div class="row justify-content-center" *ngIf="chosenRegulation.classification == 'Non-Business'">
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Stage of Life</h6>
        </div>
        <div class="col-md-3 col-sm-12 border-bottom">
            <textarea class="form-control" [value]="chosenRegulation.sol_name ? chosenRegulation.sol_name : '--'" disabled readonly></textarea>
        </div>
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Life Event</h6>
        </div>
        <div class="col-md-3 col-sm-12 border-bottom">
            <textarea class="form-control read-only" [value]="chosenRegulation.event_name ? chosenRegulation.event_name : '--'" disabled readonly></textarea>
        </div>
    </div>

    <!-- SUB TAG -->
    <!-- <div class="row justify-content-center mt-4" *ngIf="chosenRegulation.has_sub_tags">
        <div class="row justify-content-center mt-4" *ngFor="let subTag of chosenRegulation.sub_tags; let i = index; let c = count">
            <div class="col-12 info-header">
                <h6 class="fw-bold mb-0"><i class="fas fa-tags me-3" style="color: #FCD116;"></i>Sub Tag</h6>
            </div>
        </div><div class="col-md-3 col-sm-12 table-active-column">
            <h6>Classification</h6>
        </div>
        <div class="col-md-9 col-sm-12 border-bottom">
            <div class="form-check">
                <mat-radio-group class="example-radio-group d-flex flex-row justify-content-evenly">
                    <mat-radio-button [checked]="subTag.reg_classification == 'Business'"
                    value="Business"
                    class="example-radio-button"
                    disabled readonly>Business</mat-radio-button>

                    <mat-radio-button [checked]="subTag.reg_classification == 'Non-Business'"
                    value="Non-Business"
                    class="example-radio-button"
                    disabled readonly>Non-Business</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>
    <div class="row justify-content-center" *ngIf="subTag.reg_classification ==  'Business'">
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Sector</h6>
        </div>
        <div class="col-md-9 col-sm-12 border-bottom">
            <textarea class="form-control" class="form-control" [value]="subTag.sector_name ? subTag.sector_name : '--'" disabled readonly></textarea>
        </div>
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Stage of Business</h6>
        </div>
        <div class="col-md-9 col-sm-12 border-bottom">
            <textarea class="form-control" class="form-control" [value]="subTag.business_name ? subTag.business_name : '--'" disabled readonly></textarea>
        </div>
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Division</h6>
        </div>
        <div class="col-md-9 col-sm-12 border-bottom">
            <textarea class="form-control" class="form-control" [value]="subTag.division_name ? subTag.division_name : '--'" disabled readonly></textarea>
        </div>
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Case Use</h6>
        </div>
        <div class="col-md-9 col-sm-12 border-bottom">
            <textarea class="form-control" class="form-control" [value]="subTag.case_name ? subTag.case_name : '--'" disabled readonly></textarea>
        </div>
    </div>
    <div class="row justify-content-center" *ngIf="subTag.reg_classification == 'Non-Business'">
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Stage of Life</h6>
        </div>
        <div class="col-md-3 col-sm-12 border-bottom">
             <textarea class="form-control" class="form-control" [value]="subTag.sol_name ? subTag.sol_name : '--'" disabled readonly></textarea>
        </div>
        <div class="col-md-3 col-sm-12 table-active-column">
            <h6>Life Event</h6>
        </div>
        <div class="col-md-3 col-sm-12 border-bottom">
             <textarea class="form-control" class="form-control read-only" [value]="subTag.event_name ? subTag.event_name : '--'" disabled readonly></textarea>
        </div>
    </div> -->



        <!-- SUB TAG (Table Version) -->
        <div class="mt-3" *ngIf="chosenRegulation.has_sub_tags">
            <div class="row" *ngFor="let subTag of chosenRegulation.sub_tags; let i = index; let c = count">
  
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead class="info-subheader">
                            <tr></tr>
                        </thead>
    
                        <tbody>
                            <tr class="info-subheader">
                                <th colspan="2" class="fw-bold info-header table-active-column">
                                    <h6 class="fw-bold mb-0"><i class="fas fa-tag me-3" style="color: #FCD116;"></i>Sub Tag</h6>
                                </th>
                            </tr>
                            <tr>
                                <th class="col-md-3 col-sm-12 fw-bold table-active-column">Classification</th>
                                <td>
                                    <div class="form-check">
                                        <mat-radio-group class="example-radio-group d-flex flex-row justify-content-evenly">
                                            <mat-radio-button [checked]="subTag.reg_classification == 'Business'"
                                            value="Business"
                                            class="example-radio-button"
                                            disabled readonly>Business</mat-radio-button>
        
                                            <mat-radio-button [checked]="subTag.reg_classification == 'Non-Business'"
                                            value="Non-Business"
                                            class="example-radio-button"
                                            disabled readonly>Non-Business</mat-radio-button>

                                            <mat-radio-button [checked]="subTag.reg_classification == 'Government'"
                                            value="Government"
                                            class="example-radio-button"
                                            disabled readonly>Government</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                        <tbody *ngIf="subTag.reg_classification ==  'Business'">
                            <tr>
                                <th class="col-md-3 col-sm-12 fw-bold table-active-column">Sector</th>
                                <td><input type="text" class="form-control" [value]="subTag.sector_name ? subTag.sector_name : '--'" disabled readonly/></td>
                            </tr>
                            <tr>
                                <th class="col-md-3 col-sm-12 fw-bold table-active-column">Stage of Business</th>
                                <td><input type="text" class="form-control" [value]="subTag.business_name ? subTag.business_name : '--'" disabled readonly/></td>
                            </tr>
                            <tr>
                                <th class="col-md-3 col-sm-12 fw-bold table-active-column">Division</th>
                                <td><input type="text" class="form-control" [value]="subTag.division_name ? subTag.division_name : '--'" disabled readonly/></td>
                            </tr>
                            <tr>
                                <th class="col-md-3 col-sm-12 fw-bold table-active-column">Case Use</th>
                                <td><input type="text" class="form-control" [value]="subTag.case_name ? subTag.case_name : '--'" disabled readonly/></td>
                            </tr>
                        </tbody>

                        <tbody *ngIf="subTag.reg_classification == 'Non-Business'">
                            <tr>
                                <th class="col-md-3 col-sm-12 fw-bold table-active-column">Stage of Life</th>
                                <td><input type="text" class="form-control" [value]="subTag.sol_name ? subTag.sol_name : '--'" disabled readonly/></td>
                            </tr>
                            <tr>
                                <th class="col-md-3 col-sm-12 fw-bold table-active-column">Life Event</th>
                                <td><input type="text" class="form-control read-only" [value]="subTag.event_name ? subTag.event_name : '--'" disabled readonly/></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    
    
    <ng-template #noRegulationDetails>
        <div class="row" style="padding:0.5em;">
            <label class="form-input-header fw-bold" for="feedback_remark">
                <h6><b>No Regulation Details</b></h6></label>
        </div>
    </ng-template>
    
</div>
