import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { GovernmentAccountSubtype } from 'src/app/entities/worklist';

@Component({
  selector: 'app-artemis-reports-page',
  templateUrl: './artemis-reports-page.component.html',
  styleUrls: ['./artemis-reports-page.component.scss']
})
export class ArtemisReportsPageComponent implements OnInit {
  status: any;

  reports_list: any;

  form: FormGroup = new FormGroup({
    description: new FormControl('', Validators.required),
  });

  constructor(private route: ActivatedRoute, private afs: AngularFirestore, private auth: AngularFireAuth) { }

  ngOnInit(): void {
    this.status = this.route.snapshot.params.status;
    this.getExistingReports()
  }

  getExistingReports(){
    this.reports_list = []
    this.afs.collection("Reports", ref => ref.orderBy("date",'desc') ).snapshotChanges().subscribe( //, filter => filter.orderBy(orderByColumn)
      (data: any) => {
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
            
            this.reports_list.push(item); // push into array
          });
      });
  }

  getUserData(){
    this.auth.onAuthStateChanged(user => {
      if(user?.uid){
        alert(user?.uid);
        // set logged in status
        // get account type from firestore
        this.afs.doc(`Users/${user.uid}`).snapshotChanges().subscribe(
          (data: any) => {
              let item = data.payload.data()
    
              if(item.credentials.account_subtype.includes("ARTA")) {
                sessionStorage.setItem("user_type","arta");

                switch(item.credentials.account_subtype){
                  case GovernmentAccountSubtype.ARTA_ADMIN:{
                    sessionStorage.setItem("arta_admin", "true");
                    break;
                  }
                  case GovernmentAccountSubtype.AGENCY_ADMIN:{
                    sessionStorage.setItem("agency_admin", "true");
                    break;
                  }
                }
                // if(item.credentials.account_subtype.toLowerCase().includes("admin")) {
                //   sessionStorage.setItem("arta_admin", "true");
                // }
              }
              else if(item.credentials.account_type.includes("Government") || item.credentials.account_type.includes("Company")) {
                sessionStorage.setItem("user_type","agency");
                switch(item.credentials.account_subtype){
                  case GovernmentAccountSubtype.AGENCY_ADMIN:{
                    sessionStorage.setItem("agency_admin", "true");
                    break;
                  }
                }
              }
              else {
                sessionStorage.setItem("user_type","personal");
              }
              window.location.reload();
          });
      }
      else {
        sessionStorage.setItem("user_type","default");
      }
    });
  }

  addReport(){
    let date = new Date();
    let usertype = sessionStorage.getItem("user_type");

    let JSON = {
      title: this.form.value.title,
      description: this.form.value.description,
      agency: "sample agency",
      status: "pending",
      usertype: usertype,
      date: date.toString(),
      compliant: "true",
    };

    this.afs.collection(`Reports`).add(JSON).then(() => {
      alert("create successful");
    }).catch(error =>{
      alert(error.message)
    });
  }



}
