import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from "firebase/app";
import { format } from 'date-fns';
@Component({
  selector: 'app-pbris-account-dashboard',
  templateUrl: './pbris-account-dashboard.component.html',
  styleUrls: ['./pbris-account-dashboard.component.scss']
})
export class PbrisAccountDashboardComponent implements OnInit {
  @Input() data_catch: any;
  comment_count: number = 0;
  regulation_count: number = 0;
  subscription_count: number = 0;

  last_login: any

  constructor(private afs: AngularFirestore) { }

  ngOnInit(): void {
    this.onLoad(this.data_catch.credentials?.uid)
    this.last_login = (this.data_catch.credentials?.loggedIn instanceof firebase.firestore.Timestamp ? format(this.data_catch.credentials?.loggedIn.toDate(), 'MMMM dd, yyyy hh:mm a') : this.data_catch.credentials?.loggedIn )
  }

  async onLoad(id: any)
  {
    this.afs.collection('PBRIS Pinned Regulation', ref => ref.where('user_id', '==', id)
                                                              .where('status', '==', true))
                                                              .get()
                                                              .subscribe(data => {
                                                                this.regulation_count = data.size
                                                              })

    this.afs.collection('Comment PBRIS', ref => ref.where('user.user_id', '==', id))
                                                              .get()
                                                              .subscribe(data => {
                                                                this.comment_count = data.size
                                                              })

    this.afs.collection('PBRIS Subscription', ref => ref.where('user_id', '==', id)
                                                              .where('status','==',true))
                                                              .get()
                                                              .subscribe(data => {
                                                                this.subscription_count = data.size
                                                              })
  }

}
