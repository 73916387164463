<app-navbar [currentPage]="'Management'"></app-navbar>

<div class="content">
  <div class="container-fluid p-4">
    <div class="card" *ngIf="isPhase1">
      <div
        class="card-header border-bottom border-4 border-dark"
        *ngIf="currentFormPage == 'preface'"
      >
        <h3>Create a Proposed Regulation</h3>
      </div>
      <div
        class="card-header border-bottom border-4 border-dark"
        *ngIf="currentFormPage != 'preface'"
      >
        <h3>Pre-Rule Stage</h3>
      </div>
      <div class="card-body form-container">
        <form *ngIf="currentFormPage == 'preface'">
          <div>
            <p>
              Pursuant to Section 5 of RA 11032, all proposed regulations of
              government agencies under Section 3 of the same Act shall undergo
              regulatory impact assessment to establish if the proposed
              regulation does not add undue regulatory burden and cost to these
              agencies and the applicants or requesting parties.
            </p>
            <p>
              To facilitate and implement the RIA process, rules on exemptions
              and proportionality, the rulemaking process will follow four
              stages and one optional procedure.
            </p>
          </div>

          <div class="card shadow my-5">
            <div class="card-header theme-warning">
              <div class="row">
                <div class="col-2 text-center">
                  <h5><b>STAGE</b></h5>
                </div>
                <div class="col-10 text-center">
                  <h5><b>DESCRIPTION</b></h5>
                </div>
              </div>
            </div>

            <div class="card-body p-3 bg-gray rights-container">
              <div class="row">
                <div class="col-2"><b>PRE-RULE STAGE</b></div>
                <div class="col-10">
                  <p>
                    Government agencies must notify ARTA of every formulation,
                    modification, or repeal of regulations with its
                    corresponding rationale, objectives, expected impacts, and
                    targets dates through the Annual Regulatory Plan or the
                    submission of the Regulatory Notification Form.
                  </p>
                  <p>
                    The regulatory agency intends to draft a regulation and is
                    determining viable non-regulatory options as well as through
                    preliminary impact assessment. Should the assessment support
                    the implementation of a regulation, the agency submits its
                    preliminary impact statement (PIS) to ARTA.
                  </p>
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col-2">ARTA Preliminary Review</div>
                <div class="col-10">
                  ARTA is reviewing the PIS submitted by the agency and
                  evaluating whether it has adequately explained and justified
                  the proposed regulation. If ARTA deems the proposed regulation
                  to have a significant impact on the economy, businesses,
                  individuals or households, or the environment, ARTA will
                  require the regulatory agency to conduct a full RIA process of
                  the proposed regulation (Proposed Rule Stage). Otherwise, the
                  regulation proceeds to the Interim Rule Stage.
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col-2"><b>PROPOSED RULE STAGE</b></div>
                <div class="col-10">
                  The regulation has been assessed as major and is undergoing
                  regulatory impact assessment. The drafted regulatory impact
                  statement (RIS) shall be submitted to the PBRIS for public
                  consultation. Upon completion of the consultation, the
                  regulatory agency finalizes the RIS and submits it to ARTA.
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col-2">ARTA Proposed Rule Review</div>
                <div class="col-10">
                  The proposed regulation and its regulatory impact statement
                  has been submitted to ARTA and is undergoing proposed rule
                  review. ARTA may endorse or return a RIS based on its quality.
                  Regardless of the assessment, all regulations will proceed to
                  the Interim Rule Stage.
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col-2"><b>INTERIM RULE STAGE</b></div>
                <div class="col-10">
                  <p>
                    The proposed regulation and its impact statement has been
                    assessed by ARTA and is returned to the agency. The
                    regulatory agency shall inform ARTA of its next course of
                    action:
                  </p>
                  <ul>
                    <li>
                      <b>For an Endorsed RIS:</b> Regulatory agencies are
                      required to submit the implementation form which shall
                      detail dates of promulgation, filing with ONAR,
                      publication in the Official Gazette/Newspaper of General
                      Circulation, and the effectivity of the regulation.
                    </li>
                    <li>
                      <b>For a Returned RIA:</b> RIA will act as a trigger for
                      the proponent agency to revise and improve the conducted
                      RIA and drafted RIS. The agency should consider ARTA's
                      findings and recommendation and should re-submit the
                      revised RIS for ARTA review and endorsement.
                    </li>
                    <li>
                      <b>For minor regulations:</b> a Monitoring & Evaluation
                      Plan must be submitted to ARTA with the Notification Form.
                    </li>
                  </ul>
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col-2"><b>FINAL RULE STAGE</b></div>
                <div class="col-10">
                  The proposed regulation and its regulatory impact statement is
                  for promulgation, publishing and implementation.
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col-2"><b>SUBMISSION TO CONGRESS*</b></div>
                <div class="col-10">
                  ARTA, if it deems necessary, may forward the results of the
                  RIA undertaken by the Agency and the results of the ARTA
                  review to Congress for appropriate action.
                </div>
              </div>
            </div>
          </div>

          <div class="text-center">
            <!-- <a
              href="/pbris/regulatory-management"
              class="m-1 btn btn-lg btn-outline-dark"
              >Cancel</a
            > -->
            <button (click)="toCancel()"
            class="m-1 btn btn-lg btn-outline-dark"
              >Cancel</button
            >
            <button
              class="m-1 btn btn-lg btn-primary"
              (click)="gotoForm('regulationdetails')"
            >
              Proceed
            </button>
          </div>
        </form>

        <!-- Page 1: Regulation Details -->
        <form
          [formGroup]="regnotifangularform"
          *ngIf="currentFormPage == 'regulationdetails'"
        >
          <div>
            <p>
              All government agencies (national government agencies and offices,
              GOCCs, government instrumentalities, and all LGUs) covered under
              Section 3 of the EODB Act must notify ARTA of every formulation,
              modification, or repeal of regulations with its corresponding
              rationale, objectives, expected impacts, and target dates.
            </p>
            <p>
              For this purpose, it is recommended that all agencies should
              submit to ARTA an Annual Regulatory Plan as an advance
              notification. For instances wherein an agency intends to
              formulate/modify a regulation not included in the Annual
              Regulatory Plan, it should submit an RNF.
            </p>
          </div>

          <div>
            <div class="theme-warning">
              <h3>Regulatory Planning</h3>
            </div>

            <div class="form-group">
              <label class="form-input-header fw-bold" for="title"
                >Is your regulation included/identified in your Agency's Annual
                Regulatory Plan? *</label
              >
              <div>
                <div class="form-check form-check-inline">
                  <input
                    disabled
                    class="form-check-input"
                    type="radio"
                    name="part_of_reg_plan"
                    id="inlineRadio1"
                  />
                  <!--
                    return these attributes when reenabled
                    formControlName="part_of_reg_plan"
                    [value]="true"
                  -->
                  <label class="form-check-label" for="inlineRadio1">Yes</label>
                </div>

                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="part_of_reg_plan"
                    id="inlineRadio2"
                    formControlName="part_of_reg_plan"
                    [value]="false"
                  />
                  <label class="form-check-label" for="inlineRadio2">No</label>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="
              regnotifangularform.controls.part_of_reg_plan.value === true
            "
          >
            <!-- TODO: Collection for plan, to bo done soon -->
            Coming soon!
          </div>

          <div
            *ngIf="
              regnotifangularform.controls.part_of_reg_plan.value === false
            "
          >
            <div class="theme-warning">
              <h3>Regulatory Notification Form (Page 1 of 2)</h3>
            </div>

            <hr />
            <div class="form-group">
              <label class="form-input-header fw-bold" for="title"
                >Regulation Title *</label
              >
              <input type="text" class="form-control" formControlName="title" />
              <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(regnotifangularform, 'title')">Input Required</mat-hint>
              <ng-container *ngFor="let item of checkRegulatoryNotificationForm.title">
                  <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(regnotifangularform, 'title',item.type)">* {{item.message}}</mat-error>
              </ng-container> 
            </div>
            <div class="form-group fw-bold">
              <label class="form-input-header" for="subject"
                >Regulation Short Title *</label
              >
              <input
                type="text"
                class="form-control"
                formControlName="subject"
              />
              <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(regnotifangularform, 'subject')">Input Required</mat-hint>
              <ng-container *ngFor="let item of checkRegulatoryNotificationForm.subject">
                  <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(regnotifangularform, 'subject',item.type)">* {{item.message}}</mat-error>
              </ng-container> 
            </div>
            <div class="form-inline">
              <div class="form-inline fw-bold">
                <label class="form-input-header" for="subject"
                  >Regulation Instrument *</label
                >
                <select class="form-control" formControlName="reg_instrument" (change)="instrumentOnChange($event)">
                  <option value="" disabled selected>--</option>
                  <option *ngFor="let x of instruments" [value]="x['id']">
                    {{ x["name"] }}
                  </option>
                </select>
                <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(regnotifangularform, 'reg_instrument')">Input Required</mat-hint>
                <ng-container *ngFor="let item of checkRegulatoryNotificationForm.reg_instrument">
                    <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(regnotifangularform, 'reg_instrument',item.type)">* {{item.message}}</mat-error>
                </ng-container> 
              </div>
              <div class="form-inline fw-bold">
                <label class="form-input-header" for="subject"
                  >Issuing Agency *</label
                >
                <mat-select
                  *ngIf="!multipleIssuingAgency"
                  class="form-control"
                  formControlName="reg_agency"
                >
                  <mat-option *ngFor="let x of agencies" [value]="x['id']">
                    {{ x["name"] }}
                  </mat-option>
                </mat-select>
                <mat-select
                  *ngIf="multipleIssuingAgency"
                  class="form-control"
                  formControlName="reg_agency"
                  multiple
                >
                  <mat-option *ngFor="let x of agencies" [value]="x['id']">
                    {{ x["name"] }}
                  </mat-option>
                </mat-select>
                <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(regnotifangularform, 'reg_agency')">Input Required</mat-hint>
                <ng-container *ngFor="let item of checkRegulatoryNotificationForm.reg_agency">
                    <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(regnotifangularform, 'reg_agency',item.type)">* {{item.message}}</mat-error>
                </ng-container> 
              </div>
            </div>

            <div class="form-group">
              <label class="form-input-header fw-bold" for="title">
                Please classify your regulatory action*
              </label>
              <div formGroupName="reg_action_classification">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="reg_action_new"
                    formControlName="reg_action_new"
                    value="New Regulation"
                  />
                  <label class="form-check-label" for="reg_action_new"
                    >New Regulation</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="reg_action_modification"
                    formControlName="reg_action_modification"
                    value="Modification of Existing"
                  />
                  <label class="form-check-label" for="reg_action_modification"
                    >Modification of Existing</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="reg_action_repeal"
                    formControlName="reg_action_repeal"
                    value="Modification of Existing"
                  />
                  <label class="form-check-label" for="reg_action_repeal"
                    >Repeal of Existing</label
                  >
                </div>
              </div>
              <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(regnotifangularform, 'reg_action_classification')">Regulatory action Required</mat-hint>
              <ng-container *ngFor="let item of checkRegulatoryNotificationForm.reg_action_classification">
                  <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(regnotifangularform, 'reg_action_classification',item.type)">* {{item.message}}</mat-error>
              </ng-container> 
            </div>

            <div
              class="form-group"
              *ngIf="
                reg_action_classification_val.controls.reg_action_modification
                  .value === true
              "
            >
              <div class="form-inline fw-bold">
                <label class="form-input-header">Regulation to Amend</label>
                <!-- Multiple select for regulations is a placeholder -->
                <!-- TODO: This must be properly reworked to hand the sheer number of regulations -->
                <mat-select class="form-control" multiple>
                  <mat-option value="">--</mat-option>
                </mat-select>
              </div>
              <div class="form-inline fw-bold">
                <label class="form-input-header">Section to Amend</label>
                <!-- Multiple select for regulations is a placeholder -->
                <!-- TODO: This must be properly reworked to hand the sheer number of regulations -->
                <mat-select class="form-control" multiple>
                  <mat-option value="">--</mat-option>
                </mat-select>
              </div>
            </div>

            <div
              class="form-group"
              *ngIf="
                reg_action_classification_val.controls.reg_action_repeal
                  .value === true
              "
            >
              <div class="form-inline fw-bold">
                <label class="form-input-header">Regulation to Repeal</label>
                <!-- Multiple select for regulations is a placeholder -->
                <!-- TODO: This must be properly reworked to hand the sheer number of regulations -->
                <mat-select class="form-control" multiple>
                  <mat-option value="">--</mat-option>
                </mat-select>
              </div>
            </div>

            <div class="form-group">
              <label class="form-input-header fw-bold" for="title"
                >Has this been subjected to stakeholder consultation and
                comments? *</label
              >
              <div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="has_consultation_comments"
                    id="inlineRadioCons1"
                    formControlName="has_consultation_comments"
                    [value]="true"
                  />
                  <label class="form-check-label" for="inlineRadioCons1"
                    >Yes</label
                  >
                </div>

                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="has_consultation_comments"
                    id="inlineRadioCons2"
                    formControlName="has_consultation_comments"
                    [value]="false"
                  />
                  <label class="form-check-label" for="inlineRadioCons2"
                    >No</label
                  >
                </div>
              </div>
              
              <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(regnotifangularform, 'has_consultation_comments')">Input Required</mat-hint>
              <ng-container *ngFor="let item of checkRegulatoryNotificationForm.has_consultation_comments">
                  <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(regnotifangularform, 'has_consultation_comments',item.type)">* {{item.message}}</mat-error>
              </ng-container> 
            </div>

            <div class="form-group" *ngIf="regnotifangularform.invalid">
              <p class="fst-italic">
                * Required fields - must be filled in order to Submit the form
              </p>
            </div>
          </div>

          <div class="text-center">
            <!-- <a
              href="/pbris/regulatory-management"
              class="m-1 btn btn-lg btn-outline-dark"
              >Cancel</a
            > -->
            <button (click)="toCancel()"
            class="m-1 btn btn-lg btn-outline-dark"
              >Cancel</button
            >
            <button
              class="m-1 btn btn-lg btn-primary"
              (click)="gotoForm('regulatorynotificationform')"
            >
              Next
            </button>
          </div>
        </form>

        <!-- Regulatory notification form is not the same as tagging -->
        <form
          [formGroup]="regnotifangularform"
          *ngIf="currentFormPage == 'regulatorynotificationform'"
        >
          <div>
            <p>
              All government agencies (national government agencies and offices,
              GOCCs, government instrumentalities, and all LGUs) covered under
              Section 3 of the EODB Act must notify ARTA of every formulation,
              modification, or repeal of regulations with its corresponding
              rationale, objectives, expected impacts, and target dates.
            </p>
            <p>
              For this purpose, it is recommended that all agencies should
              submit to ARTA an Annual Regulatory Plan as an advance
              notification. For instances wherein an agency intends to
              formulate/modify a regulation not included in the Annual
              Regulatory Plan, it should submit an RNF.
            </p>
          </div>

          <h3>Regulatory Notification Form (Page 2 of 2)</h3>
          <hr />

          <div formGroupName="reg_notif_form">
            <div class="form-group fw-bold">
              <label class="form-input-header" for="feedback_remark"
                ><b>Problem being addressed by the Regulation *</b></label
              >
              <mat-form-field
                style="width: 100%"
                hideRequiredMarker
                floatLabel="never"
              >
                <textarea
                  matInput
                  placeholder="Input"
                  type="text"
                  formControlName="reg_problem"
                ></textarea>
                <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(regnotifangularform, 'reg_problem','reg_notif_form')">Input Required</mat-hint>
                <ng-container *ngFor="let item of checkRegulatoryNotificationForm.reg_notif_form.reg_problem">
                    <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(regnotifangularform, 'reg_problem',item.type,'reg_notif_form')">* {{item.message}}</mat-error>
                </ng-container> 
              </mat-form-field>
            </div>

            <div class="form-group fw-bold">
              <label class="form-input-header">Classification</label>
              <div class="form-check p-3">
                <mat-radio-group
                  formControlName="reg_classification"
                  class="example-radio-group"
                >
                <!-- (change)="classificationChange($event)" -->
                  <mat-radio-button
                    checked
                    value="Business"
                    class="example-radio-button"
                    >Business</mat-radio-button
                  >
                  <mat-radio-button
                    value="Non-Business"
                    class="example-radio-button"
                    >Non-Business</mat-radio-button
                  >
                </mat-radio-group>
              </div>
            </div>

            <div
              *ngIf="
                this.regnotifangularform
                  .get('reg_notif_form')
                  ?.get('reg_classification')?.value == 'Business'
              "
            >
              <div class="form-inline fw-bold">
                <label class="form-input-header" for="subject">Sector *</label>
                <select
                  class="form-control"
                  formControlName="reg_sector"
                  (change)="sectorOnChange($event)"
                >
                  <option value="" disabled selected>--</option>
                  <option *ngFor="let x of sectors" [value]="x['id']">
                    {{ x["name"] }}
                  </option>
                </select>
                <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(regnotifangularform, 'reg_sector','reg_notif_form')">Input Required</mat-hint>
                <ng-container *ngFor="let item of checkRegulatoryNotificationForm.reg_notif_form.reg_sector">
                    <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(regnotifangularform, 'reg_sector',item.type,'reg_notif_form')">* {{item.message}}</mat-error>
                </ng-container> 
              </div>

              <div class="form-inline fw-bold">
                <label class="form-input-header" for="subject"
                  >Division *</label
                >
                <select class="form-control" formControlName="reg_division"  (change)="selectOnChange1('reg_division')">
                  <option value="" disabled selected>--</option>
                  <option *ngFor="let x of divisions_select" [value]="x['id']">
                    {{ x["name"] }}
                  </option>
                </select>
                <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(regnotifangularform, 'reg_division','reg_notif_form')">Input Required</mat-hint>
                <ng-container *ngFor="let item of checkRegulatoryNotificationForm.reg_notif_form.reg_division">
                    <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(regnotifangularform, 'reg_division',item.type,'reg_notif_form')">* {{item.message}}</mat-error>
                </ng-container> 
              </div>

              <div class="form-inline fw-bold">
                <label class="form-input-header" for="subject"
                  >Stage of Business *</label
                >
                <select
                  class="form-control"
                  formControlName="reg_business"
                  (change)="sobOnChange($event)"
                >
                  <option value="" disabled selected>--</option>
                  <option *ngFor="let x of sobs" [value]="x['id']">
                    {{ x["name"] }}
                  </option>
                </select>
                <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(regnotifangularform, 'reg_business','reg_notif_form')">Input Required</mat-hint>
                <ng-container *ngFor="let item of checkRegulatoryNotificationForm.reg_notif_form.reg_business">
                    <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(regnotifangularform, 'reg_business',item.type,'reg_notif_form')">* {{item.message}}</mat-error>
                </ng-container> 
              </div>

              <div class="form-inline fw-bold">
                <label class="form-input-header" for="subject"
                  >Case Use *</label
                >
                <select class="form-control" formControlName="reg_case" (change)="selectOnChange1('reg_case')">
                  <option value="" disabled selected>--</option>
                  <option *ngFor="let x of cases_select" [value]="x['id']">
                    {{ x["name"] }}
                  </option>
                </select>
                <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(regnotifangularform, 'reg_case','reg_notif_form')">Input Required</mat-hint>
                <ng-container *ngFor="let item of checkRegulatoryNotificationForm.reg_notif_form.reg_case">
                    <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(regnotifangularform, 'reg_case',item.type,'reg_notif_form')">* {{item.message}}</mat-error>
                </ng-container> 
              </div>
            </div>
            <div
              *ngIf="
                this.regnotifangularform
                  .get('reg_notif_form')
                  ?.get('reg_classification')?.value == 'Non-Business'
              "
            >
              <div class="form-inline fw-bold">
                <label class="form-input-header" for="subject"
                  >Stage of Life *</label
                >
                <select
                  class="form-control"
                  formControlName="reg_stageoflife"
                  name="reg_stageoflife"
                  id="reg_stageoflife"
                  (change)="solOnChange($event)"
                >
                  <option value="" disabled selected>--</option>
                  <option *ngFor="let x of sols" [value]="x['id']">
                    {{ x["stage"] }}
                  </option>
                </select>
                <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(regnotifangularform, 'reg_stageoflife','reg_notif_form')">Input Required</mat-hint>
                <ng-container *ngFor="let item of checkRegulatoryNotificationForm.reg_notif_form.reg_stageoflife">
                    <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(regnotifangularform, 'reg_stageoflife',item.type,'reg_notif_form')">* {{item.message}}</mat-error>
                </ng-container> 
              </div>

              <div class="form-inline fw-bold">
                <label class="form-input-header" for="subject"
                  >Life Event</label
                >
                <select class="form-control" formControlName="reg_lifeevent" (change)="selectOnChange1('reg_lifeevent')">
                  <option value="" disabled selected>--</option>
                  <option *ngFor="let x of lifeevents_select" [value]="x['id']">
                    {{ x["event"] }}
                  </option>
                </select>
                <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(regnotifangularform, 'reg_lifeevent','reg_notif_form')">Input Required</mat-hint>
                <ng-container *ngFor="let item of checkRegulatoryNotificationForm.reg_notif_form.reg_lifeevent">
                    <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(regnotifangularform, 'reg_lifeevent',item.type,'reg_notif_form')">* {{item.message}}</mat-error>
                </ng-container> 
              </div>
            </div>

            <div class="form-inline fw-bold">
              <label class="form-input-header" for="subject"
                >Jurisdiction *</label
              >
              <select class="form-control" formControlName="reg_juris" (change)="selectOnChange1('reg_juris')">
                <option value="" disabled selected>--</option>
                <option *ngFor="let x of jurisdictions" [value]="x['id']">
                  {{ x["name"] }}
                </option>
              </select>
              <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(regnotifangularform, 'reg_juris','reg_notif_form')">Input Required</mat-hint>
              <ng-container *ngFor="let item of checkRegulatoryNotificationForm.reg_notif_form.reg_juris">
                  <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(regnotifangularform, 'reg_juris',item.type,'reg_notif_form')">* {{item.message}}</mat-error>
              </ng-container> 
            </div>

            <hr />

            <div class="form-inline fw-bold">
              <label class="form-input-header" for="timelines"
                >Target Timelines for the Conduct of PIA and RIA</label
              >
              <div>
                <label style="color: lightskyblue; font-size: small;"><i>Remove entry if there are no consultations conducted.</i></label>
              </div>
              
              <mat-list>
                <mat-list-item
                  *ngFor="
                    let x of pia_ria_timeline_val.controls;
                    let $i = index
                  "
                >
                  <div
                    class="d-flex flex-row"
                    mat-line
                    [class.bg-info]="$i === active_pia_ria_timeline_index"
                    (click)="loadPIARIATimelineInformation(x, $i)"
                    [ngClass]="x.value.activity_title ? 'color-black' : 'color-red'"
                  >
                    {{ x.value.activity_title ? x.value.activity_title : '* Title of Activity is required' }}
                    
                    <mat-error style="margin-left:1em" *ngIf="!x.valid" class="color-red">
                      * Invalid Entry
                    </mat-error>
                  </div>
                  <div class="d-flex justify-content-end">
                    <button mat-stroked-button color="warn"
                    class="m1"
                      (click)="removePIARIATimeline($i)"
                    >
                    <!-- class="btn btn-danger m-1 " -->
                      <span class="material-icons"> remove_circle </span>
                    </button>
                  </div>
                </mat-list-item>
              </mat-list> 

              <button
                mat-stroked-button
                (click)="addPIARIATimeline()"
                class="btn committee"
              >
                <span class="material-icons"> add_circle_outline </span> Add row
              </button>
            </div>

            <div
              *ngIf="active_pia_ria_timeline_form"
              [formGroup]="active_pia_ria_timeline_form"
            >
              <div class="form-group">
                <label class="form-input-header fw-bold" for="activity_title"
                  >Title of Activity *</label
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="activity_title"
                />
                <!-- <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(active_pia_ria_timeline_form,'activity_title')">Input Required</mat-hint> -->
                <ng-container *ngFor="let item of checkRegulatoryNotificationForm.concudted_consultations.activity_title">
                    <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(active_pia_ria_timeline_form,'activity_title',item.type)">* {{item.message}}</mat-error>
                </ng-container> 
              </div>
              <div class="row">
                <div class="col-4">
                  <label class="form-input-header fw-bold" for="start_date"
                    >Date Start *</label
                  >
                  <input
                    type="date"
                    placeholder="mm/dd/yyyy"
                    class="form-control"
                    formControlName="start_date"
                  />
                  <ng-container *ngFor="let item of checkRegulatoryNotificationForm.concudted_consultations.start_date">
                    <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(active_pia_ria_timeline_form,'start_date',item.type)">* {{item.message}}</mat-error>
                  </ng-container> 
                </div>
                <div class="col-4">
                  <label class="form-input-header fw-bold" for="end_date"
                    >Date End *</label
                  >
                  <input
                    type="date"
                    placeholder="mm/dd/yyyy"
                    class="form-control"
                    formControlName="end_date"
                  />
                  <ng-container *ngFor="let item of checkRegulatoryNotificationForm.concudted_consultations.end_date">
                    <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(active_pia_ria_timeline_form,'end_date',item.type)">* {{item.message}}</mat-error>
                  </ng-container> 
                </div>
              </div>
            </div>

            <hr />

            <div class="form-group fw-bold">
              <label class="form-input-header" for="reg_remarks"
                ><b>Other Remarks</b></label
              >
              <mat-form-field
                style="width: 100%"
                hideRequiredMarker
                floatLabel="never"
              >
                <textarea
                  matInput
                  placeholder="Input"
                  type="text"
                  formControlName="reg_remarks"
                ></textarea>
              </mat-form-field>
            </div>

            <hr />
            <div class="form-group fw-bold">
              <label class="form-input-header" for="doc_date"
                >Proposal Date *</label
              >
              <input
                type="date"
                placeholder="mm/dd/yyyy"
                class="form-control"
                formControlName="doc_date"
              />
              <!-- <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(regnotifangularform,'doc_date','reg_notif_form')">Input Required</mat-hint> -->
              <ng-container *ngFor="let item of checkRegulatoryNotificationForm.reg_notif_form.doc_date">
                  <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(regnotifangularform,'doc_date',item.type,'reg_notif_form')">* {{item.message}}</mat-error>
              </ng-container> 
            </div>
          </div>

          <div class="form-group" *ngIf="regnotifangularform.invalid">
            <p class="fst-italic">
              * Required fields - must be filled in order to Submit the form
            </p>
          </div>

          <div class="text-center">
            <!-- <a
              href="/pbris/regulatory-management"
              class="m-1 btn btn-lg btn-outline-dark"
              >Cancel</a
            > -->
            <button (click)="toCancel()"
            class="m-1 btn btn-lg btn-outline-dark"
              >Cancel</button
            >
            <button
              class="m-1 btn btn-lg btn-outline-dark"
              (click)="gotoForm('regulationdetails')"
            >
              Back
            </button>
            <button
              class="m-1 btn btn-lg btn-primary"
              (click)="submitNotificationForm()"
              id="bt "
            >
              <span
                class="spinner-border spinner-border-sm mr-1"
                *ngIf="loading"
              ></span>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
