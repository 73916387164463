<div class="container-fluid">
    <table class="table table-responsive table-striped table-sm" mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChange($event)"  >
        
        <!-- Date coc submitted -->
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Submitted </th>
          <td mat-cell *matCellDef="let row"> {{ row.dateSubmitted.toDate() | date: "yyyy-MMM-dd" }} </td>
        </ng-container>

        <!-- Person Responsible -->
        <ng-container matColumnDef="responsible">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Person Responsible </th>
            <td mat-cell *matCellDef="let row"> {{ row.personResponsible.name }} </td>
          </ng-container>
    
        <!-- coc and cc Version -->
        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Certificate of Compliance</th>
          <td mat-cell *matCellDef="let row" style="cursor:pointer;" ><a class="text-primary" (click)="ViewCCDocument(row)" ><u>{{row.cocYear}} COC for Citizen's Charter  {{row.citizenscharterVersion.year}} {{row.citizenscharterVersion.edition}}<span><sup>{{transform(row.citizenscharterVersion.edition,false)}}</sup></span> Edition</u></a> </td>
        </ng-container>

        <!-- coc and cc submissionStatus -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let row" style="cursor:pointer;" >{{row.submission}} CC</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
      </table>
      <mat-paginator [pageSizeOptions]="[10, 25, 50,100]" aria-label="Select page of users"></mat-paginator>
</div>