<footer style="background-color: #efefef;">
  <!-- Grid container -->
  <div class="container">
    <div id="gwt-standard-footer">
      <iframe src="//gwhs.i.gov.ph/gwt-footer/footer-source.html" id="footer-gwt-frame" width="100%" scrolling="no" style="border: 0; min-height: 188px" ></iframe>
    </div>
    <script data-main="js/main" src="vendor/requirejs/require.js"></script>
  <!-- Global site tag (gtag.js) - Google Analytics -->
  <script async src="https://www.googletagmanager.com/gtag/js?id=UA-107134498-3"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
  
    gtag('config', 'UA-107134498-3');
  </script>
  
  </div>
</footer>
