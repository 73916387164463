<div class="row margin-top: 2em; margin-bottom: 2em;">
  <div class="col">
      <table class="table" >
          <tbody>
              <!-- <tr *ngFor="let entry of services; let i = index;" class="charter-row">
                <app-citizens-charter-service-view [service]="entry" [counter]="hasCounter? lastCounter + i:null"></app-citizens-charter-service-view>
              </tr> -->
              <ng-container *ngIf="hasCounter">
                <th class="header-title py-2" style="background-color: #e7e7e7" *ngIf="hasCounter"> <h2>{{processowner}}</h2></th>
                  <tr *ngFor="let item of ServiceList; let i = index;" style="padding: 20px;" >
                    <app-citizens-charter-service-view [counter]="i + 1" [service]="item" ></app-citizens-charter-service-view>
                  </tr>
                </ng-container>
                <ng-container *ngIf="!hasCounter">
                  <tr *ngFor="let entry of services; let i = index;" class="charter-row">
                    <app-citizens-charter-service-view [service]="entry"></app-citizens-charter-service-view>
                  </tr> 
                </ng-container>
          </tbody>
      </table>
  </div>
</div>