import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pbris-faq-search',
  templateUrl: './pbris-faq-search.component.html',
  styleUrls: ['./pbris-faq-search.component.scss']
})
export class PbrisFaqSearchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
