<div>
    <ng-container [ngSwitch]="currentSystem">
        <app-artemis-navigation-header  *ngSwitchCase="artemis"></app-artemis-navigation-header>
        <app-navbar *ngSwitchCase="pbris"></app-navbar>
        <app-artemis-navigation-header *ngSwitchDefault [currentPage]="''"></app-artemis-navigation-header>
    </ng-container>
</div>
<div class="content">
    <div class="container-fluid p-3">
        <a *ngIf="isAdmin" class="btn btn-outline-dark" routerLink="/agency/">
            Back
        </a>
        <div class="card">
            <div class="card-head p-3">
                <h3 class="card-header">{{agencyTitle}} Agency Members</h3>
            </div>
            <div *ngIf="isAgencyAdmin || isAdmin">
                <button type="button" class="btn btn-primary" (click)="openRegisterMemberModal()">Create Member</button>
            </div>
            <div class="card-body p-3 row">
                <div class="col-7">
                    <!-- search -->
                    <div style="padding:0.5em;">
                        <form [formGroup]="filtersForm">
                            <div class="row form-group">
                                <div class="col-2" style="display:flex; align-items: center; justify-content:center;">
                                    <label>Search</label>
                                </div>
                                <div class="col">
                                    <!-- <input class="full-width form-control" (keyup)="search($event)" formControlName="search" type="text"> -->
                                    <input class="full-width form-control" (keyup)="search($event)" formControlName="search" type="text">
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- table -->
                    <table mat-table matSort
                        class="table table-hover table-responsive"
                        id="matTable"
                        [dataSource]="memberDataSource"
                        (matSortChange)="sortChange($event)">

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Member Name</h5>  </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="d-flex align-items-center" style="padding:0.3em">
                                    <p>{{(element.personal.first_name || element.personal.last_name) ? (element.personal.first_name+" "+element.personal.last_name) : "N/A"}}</p> 
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Email</h5>  </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="d-flex align-items-center" style="padding:0.3em">
                                    <p>{{element.credentials.email}}</p>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="account_subtype">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Sub Type</h5>  </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="d-flex align-items-center" style="padding:0.3em">
                                    <p>{{element.credentials.account_subtype ? element.credentials.account_subtype : "N/A"}}</p>
                                    <p>{{element.credentials.role ? element.credentials.role : 'Basic' }}</p>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="memberDataSourceColumns; sticky: true"></tr>
                        <tr mat-row matRipple (click)="memberChosen(row)" *matRowDef="let row; columns: memberDataSourceColumns;"></tr>
                    </table>
                    <mat-paginator pageSize="15" aria-label="Select page of users"></mat-paginator>
                </div>
                <div class="col card" style="max-height:909px;">
                    <!-- chosenMember -->
                    <div style="padding:2em;">
                        <!-- name -->
                        <div class="row">
                            <div class="col-3 fw-bold"><label class="form-input-header fw-bold">Name</label></div>
                            <div class="col-1">:</div>
                            <div class="col">
                                <ng-container *ngIf="chosenMember">
                                    <p>{{ (chosenMember.personal.first_name || chosenMember.personal.last_name) ? (chosenMember.personal.first_name+" "+chosenMember.personal.last_name) : "N/A" }}</p>
                                </ng-container>
                                <ng-container *ngIf="!chosenMember">
                                    <p>N/A</p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-3 fw-bold"><label class="form-input-header fw-bold">Email</label></div>
                            <div class="col-1">:</div>
                            <div class="col">
                                <ng-container *ngIf="chosenMember">
                                    <p>{{chosenMember.credentials.email}}</p>
                                </ng-container>
                                <ng-container *ngIf="!chosenMember">
                                    <p>N/A</p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-3 fw-bold"><label class="form-input-header fw-bold">Mobile Number</label></div>
                            <div class="col-1">:</div>
                            <div class="col">
                                <ng-container *ngIf="chosenMember">
                                    <p>{{chosenMember.credentials.mobile_number}}</p>
                                </ng-container>
                                <ng-container *ngIf="!chosenMember">
                                    <p>N/A</p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="card" style="padding: 2em; margin-top: 2em;">
                            <form [formGroup]="agencyForm">

                                <!-- subtype -->
                                <div class="row">
                                    <div class="col-5 fw-bold"><label class="form-input-header fw-bold">Account Subtype</label></div>
                                    <div class="col-1">:</div>
                                    <div class="col">
                                        <ng-container *ngIf="agencyEditForm">
                                            <p>{{(agencyForm.value.account_subtype) ? (agencyForm.value.account_subtype) : "N/A"}}</p>
                                        </ng-container>
                                        <ng-container *ngIf="!agencyEditForm">
                                            <select class="form-select" #subtype_account (change)="universalUpdater('govsubtype',subtype_account.value)">
                                                <option selected disabled value="">Account Subtype</option>
                                                <option *ngFor="let item of universalOption('govsubtype')"
                                                    [value]="item"
                                                    [selected]="universalSelector('govsubtype',item)"
                                                    >{{item}}</option>
                                            </select>
                                        </ng-container>
                                    </div>
                                </div>

                                <!-- designation -->
                                <div class="row input-row">
                                    <div class="col-5 fw-bold"><label class="form-input-header fw-bold">Official Designation</label></div>
                                    <div class="col-1">:</div>
                                    <div class="col form-group">
                                        <ng-container *ngIf="agencyEditForm">
                                            <p>{{(agencyForm.value.official_designation) ? (agencyForm.value.official_designation) : "N/A"}}</p>
                                        </ng-container>
                                        <ng-container *ngIf="!agencyEditForm">
                                            <input type="text" class="form-control" formControlName="official_designation" placeholder="Official Designation">
                                        </ng-container>
                                    </div>
                                </div>

                                <!-- designation -->
                                <div class="row input-row">
                                    <div class="col-5 fw-bold"><label class="form-input-header fw-bold">Sub Agency</label></div>
                                    <div class="col-1">:</div>
                                    <div class="col form-group">
                                        <ng-container *ngIf="agencyEditForm">
                                            <p>{{(agencyForm.value.subagency) ? (agencyForm.value.subagency) : "N/A"}}</p>
                                        </ng-container>
                                        <ng-container *ngIf="!agencyEditForm">
                                            <input type="text" class="form-control" formControlName="subagency" placeholder="Sub Agency">
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="row input-row">
                                    <div class="col-5 fw-bold"><label class="form-input-header fw-bold">Account Role</label></div>
                                    <div class="col-1">:</div>
                                    <div class="col form-group">
                                        <ng-container *ngIf="agencyEditForm">
                                            <p>{{(agencyForm.value.role) ? (agencyForm.value.role) : "Basic"}}</p>
                                        </ng-container>
                                        <ng-container *ngIf="!agencyEditForm">
                                            <select class="form-select" #role (change)="universalUpdater('role',role.value)">
                                                <option selected disabled value="">Account Role</option>
                                                <option *ngFor="let item of filterRow(agencyForm.value.account_subtype ? agencyForm.value.account_subtype : '' )"
                                                    [value]="item"
                                                    [selected]="universalSelector('role',item)"
                                                    >{{item}}</option>
                                            </select>
                                        </ng-container>
                                    </div>
                                </div>

                                <div *ngIf="isAgencyAdmin || isAdmin" class="d-flex justify-content-around" style="padding: 1em;">
                                    <ng-container *ngIf="agencyEditForm">
                                        <button type="button" [disabled]="!chosenMember || artaCheck()" (click)="updateToggle('agency','edit')" class="btn btn-info">Update</button>
                                    </ng-container>
                                    <ng-container *ngIf="!agencyEditForm">
                                        <button type="button" [disabled]="!chosenMember" (click)="updateToggle('agency','cancel')" class="btn btn-danger">Cancel</button>
                                        <button type="button" [disabled]="!chosenMember || artaCheck()" (click)="submitUpdate('agency')" class="btn btn-primary">Confirm</button>
                                    </ng-container>
                                </div>
                            </form>
                        
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>