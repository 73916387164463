<td colspan="5" *ngIf="requirement?.chooseany">
    <div 
    app-artemis-chooseany-requirement-node 
    [requirement]="requirement"
    [index]="index"
    ></div>

</td>

<td colspan="5"  *ngIf="!requirement?.chooseany">

    <div 
    app-artemis-requirement-node 
    [requirement]="requirement"
    [index]="index"></div>

</td>