import { Component, OnInit, Query, ViewChild } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase';
import { combineLatest } from 'rxjs';
import { first, map, take, tap } from 'rxjs/operators';
import {
  CharterStatusCitizensCharter,
  EffectivityCitizensCharter,
  GovernmentAccountSubtype,
  ServiceAssessmentCitizensCharter,
  TableSortWeightWorklistStatusCitizensCharter,
  WorklistAgencyMappedStatusCitizensCharter,
  WorklistStatusCitizensCharter,
} from 'src/app/entities/worklist';
import { WorklistService } from 'src/app/service/worklist-service.service';
import { DatePipe } from '@angular/common';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';
import Swal from 'sweetalert2';
import { GovServiceService } from 'src/app/service/gov-service.service';
import { accountRole } from 'src/app/agency-control/agency-register-member/agency-register-member.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { BreadcrumbModule } from 'ng-uikit-pro-standard';
import { NotificationsEmailer } from 'src/app/service/notifications-emailer.service';
import { EmailerService } from 'src/app/service/emailer.service';
import { EmailAppCode, EmailTemplateCode } from 'src/app/entities/emailTemplates';
@Component({
  selector: 'app-artemis-charter-inbox-page',
  templateUrl: './artemis-charter-inbox-page.component.html',
  styleUrls: ['./artemis-charter-inbox-page.component.scss'],
})
export class ArtemisCharterInboxPageComponent implements OnInit {
  
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild('worklist_CC') paginator: MatPaginator;
  @ViewChild('worklist_CCSort') sort: MatSort;

  @ViewChild('worklist_NONCC') paginator1: MatPaginator;
  @ViewChild('worklist_NONCCSort') sort1: MatSort;


  user_account_role = accountRole

  account_role : any

  viewAddService:boolean =false;

  results: any = []; // main countainer for the inbox results list
  results_2: any = [];
  // filtered out
  results_3: any = [];
  results_4: any = [];
  service_status: any;
  loading: boolean = true;
  submitEnabled: boolean = false;
  ongoingCharter: boolean = false;
  servicesCertified: boolean = false;
  charterForRevision: boolean = false;
  activeCharter: any;
  activeCertification: any;
  

  account_type: string = '';
  enum_account_subtype = GovernmentAccountSubtype;
  enum_worklist_status = WorklistStatusCitizensCharter;
  enum_active_charter_status = CharterStatusCitizensCharter;
  enum_service_effectivity = EffectivityCitizensCharter;

  nonCCworklist_columns = ['dateGenerated','fromOfficer','category','documentTitle','status','effectivity']
  worklist_columns = ['dateGenerated','process_owner','fromOfficer','category','documentTitle','status','effectivity','completeness','action']
  //old worklist table
  //nonCCworklist_columns = ['dateGenerated','fromOfficer','documentTitle','status','effectivity','dateDue']
  //worklist_columns = ['dateGenerated','fromOfficer','documentTitle','status','effectivity','dateDue','action']
  worklistDataSource: MatTableDataSource<any>;
  nonCCWorklistDataSource: MatTableDataSource<any>;
  /**
   * 1 - draft
   * 2 - for verification
   * 3 - for revision
   * 4 - for agency review
   * 5 - for comments consolidation
   * 6 - consolidation complete
   * 7 - for approval
   * 8 - for arta review
   * 9 - for modification
   * 10 - posted
   */
  countDraft = 0;
  countVerification = 0;
  countVerified = 0;
  countRevision = 0;
  countAgencyReview = 0;
  countConsolidation = 0;
  countApproval = 0;
  countARTAReview = 0;
  countModification = 0;
  countIncompleteService = 0;
  //countPosted???
  private worklistFilters = new Map<string, string[]>([
    WorklistAgencyMappedStatusCitizensCharter.AGENCY_ENC,
    WorklistAgencyMappedStatusCitizensCharter.AGENCY_VER,
  ]);

  searchForm: FormGroup = new FormGroup({
    keyword: new FormControl('')
  })

  user : any = {
    displayName : '',
    email : '',
    id:''
  }

  constructor(
    private afs: AngularFirestore,
    private router: Router,
    public worklistService: WorklistService,
    private snackBarService: SnackbarNotifService,
    private route: ActivatedRoute,
    private govService: GovServiceService,
    private auth: AngularFireAuth,
    private nE : NotificationsEmailer,
    private emailer : EmailerService
  ) {
    this.account_type = sessionStorage.getItem('account_subtype') || '';
    this.account_role = sessionStorage.getItem('account_role')

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  openServiceModal(serviceId:any = null){

   
    if(serviceId != null)  this.router.navigate(['serviceeditor'], {queryParams: serviceId} );
    else  this.router.navigate(['serviceeditor'] );
  }

  closeServiceModal(value:any){
    this.viewAddService =false;
    this.selectedServiceId = null

  }

  office_list = new FormControl()
  selectedServiceId:any;

  officeValueChanged(value:any){
    this.filteredOffice(value)
    this.resetSandP();
  }

  filteredOffice(value:any,status: any = '',secondstatus: any = ''){

     if(value == 'All' && status == '') {
      this.worklistDataSource = new MatTableDataSource<any>(this.results)
    }else{
        if(status){

          let filteredresult = this.results.filter((f:any) => f.status.toLowerCase() === status.toLowerCase() || f.status.toLowerCase() ===secondstatus.toLowerCase())
          this.worklistDataSource = new MatTableDataSource<any>(filteredresult)

        }else{
          let filteredresult = this.results.filter((f:any) => f.process_owner.toLowerCase() === value.toLowerCase())
          this.worklistDataSource = new MatTableDataSource<any>(filteredresult)
        }
    }
      sessionStorage.setItem('selected_office',value)
      this.office_list.setValue(value)
       
  }


  ngOnInit(): void {
    this.loadCounts();
    this.loadOfficeDivision();
    this.loaduser()
    /**
     * this.office_list.valueChanges.subscribe(value => {  

      this.resetSandP(value)

      ///this.filteredOffice(value)
       
     })
     */
  }

 async loaduser(){
    await this.auth.user
    .pipe(first())
    .subscribe({

      next:(res)=>{
        // const id = res?.uid
        // console.log('res',res)
        this.user.displayName = res?.displayName
        this.user.email = res?.email
        this.user.id = res?.uid
       
    }

    })
  }

 async cloneService(worklistdata:any){

    await  this.govService.cloneService(worklistdata);

   
  }
 

  // shows quick count if data is available
  // otherwise, there is no need to show an empty table
  get ifCountsAreAvailable() {
    if (
      this.countDraft != 0 ||
      this.countVerification != 0 ||
      this.countVerified != 0 ||
      this.countRevision != 0 ||
      this.countAgencyReview != 0 ||
      this.countConsolidation != 0 ||
      this.countApproval != 0 ||
      this.countARTAReview != 0 ||
      this.countModification != 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  get verifiedWorklistsCharter() {    
    return this.results.filter(
      (option: any) =>
        option.status === WorklistStatusCitizensCharter.VERIFIED
    );
  }

  get forVerificationWorklistsCertification() {
    // Like with Certification, loop through current worklists to get VERIFIED or FOR_VERIFICATION
    // Allow any pre-VERIFIED worklists to be part of the certification...for now;

    return this.results.filter(
      (option: any) =>
        option.status === WorklistStatusCitizensCharter.VERIFIED ||
        option.status === WorklistStatusCitizensCharter.FOR_VERIFICATION ||
        option.status === WorklistStatusCitizensCharter.RESUBMITTED 
    )
  }
  resetCounts(){
      this.countDraft = 0;
      this.countVerification =0;
      this.countVerified = 0;
      this.countApproval = 0;
      this.countAgencyReview = 0;
      this.countConsolidation = 0;
      this.countRevision = 0;
  }
  // loads data from database (firestore)
  async loadCounts() {
    this.loading = true;
    this.results = [];
    this.results_2 = [];

    const loadCharter = this.loadOngoingCharterFunc();
    const loadWorklists = this.loadWorklistsFunc();
    const loadCertification = this.loadOngoingCertificationFunc();


    // Because of the weirdness of combineLatest, it is probably better to just add a take(1)
    // and manually call loadCounts() every time the worklists update in this screen
    combineLatest([loadCharter, loadWorklists, loadCertification])
      .pipe(
        tap(([charterData, worklistData, certificationData]) => {
          // keep submitEnabled as false to hide the submit CC button
          let tempSubmitEnabled = true;
          let tempServicesCertified = true;

          // Assume that charterData will either by empty or contain 1
          if (charterData.length > 0) {
            this.ongoingCharter = true;
            this.activeCharter = charterData[0].payload.doc.data();
            this.activeCharter.id = charterData[0].payload.doc.id;

            this.charterForRevision = this.activeCharter.status === CharterStatusCitizensCharter.FOR_REVISION;
            // Force submit enabled as false if Charter is ongoing but not yet For Revision
            if(!this.charterForRevision) {
              tempSubmitEnabled = false;
            }
          }

          // TODO: handling of Service Certification
          let certifiedServices: any[] = [];
          if(certificationData.length > 0) {
            this.activeCertification = certificationData[0].payload.doc.data();
            this.activeCertification.id = certificationData[0].payload.doc.id;

            certifiedServices = this.activeCertification.service_id;
          }
          else {
            tempServicesCertified = false;
          }
         
          worklistData.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
          
            // Do not proceed with adding service to list if it is not part of an ongoing charter
            // TODO: Add to results3 instead and display as a separate table
            if (
              this.activeCharter &&
              this.activeCharter?.service_id.indexOf(item.documentId) < 0 &&
              this.activeCharter?.remark_disapproval_arta_dir
            ) {
              if(item.serviceCompleteness ==false){this.countIncompleteService++}
              this.results_3.push(item);
              return;
            }

            /* 
              Submit CC must only be allowed if ALL RETRIEVED ENTRIES ARE VERIFIED
              So do a false check: If user is NOT VERIFIER, OR even ONE entry's status is NOT VERIFIED,
              set submitEnabled to false
            */
            if (
              tempSubmitEnabled &&
              (item.status !== WorklistStatusCitizensCharter.VERIFIED ||
                sessionStorage.getItem('account_subtype') !==
                  GovernmentAccountSubtype.AGENCY_VER)
            ) {
              if(sessionStorage.getItem('account_subtype') === GovernmentAccountSubtype.AGENCY_VER) {
                // If verifier, force tempServicesCertified to false to show the "certified and verified prompt"
                tempServicesCertified = false;
              }
              tempSubmitEnabled = false;
            }

            /* 
              As Verifier, check that each service must be certified in addition to being verified
              So even if a single pre-VERIFIED service has not yet been certified, submission cannot push through
            */
            if (
              tempServicesCertified &&
              ((sessionStorage.getItem('account_subtype') ===
                GovernmentAccountSubtype.AGENCY_VER &&
                item.status === WorklistStatusCitizensCharter.VERIFIED) ||
                (sessionStorage.getItem('account_subtype') ===
                  GovernmentAccountSubtype.AGENCY_ENC &&
                  (item.status ===
                    WorklistStatusCitizensCharter.FOR_VERIFICATION ||
                    item.status === WorklistStatusCitizensCharter.VERIFIED ||
                    item.status === WorklistStatusCitizensCharter.RESUBMITTED)))
            ) {
              // tempServicesCertified becomes false if worklist.documentId is not in list of certified services
              tempServicesCertified =
                certifiedServices.indexOf(item.documentId) > -1;
            }

            /* 
                Whenever worklist status is updated, snapshotChanges() fires off again,
                so ensure the snapshots do not get recklessly added to the list
            */
           //console.log("info tyoe",info.type)

           switch(info.type){
            case 'removed':

            // For modified snapshots, find and replace the entry
            for (var index in this.results){
              const worklist = this.results[index];
              if(item.id === worklist.id) {
                this.results.splice(index,1)
              }
            }

            break;

            case 'modified':
            default:
                // For modified or added snapshots, find and replace the entry or add the entry
                let addflag =true;
                for (var index in this.results){
                  const worklist = this.results[index];
                  if(item.id === worklist.id){
                    this.results.splice(index,1,item)
                    addflag =false
                    break;
                  }
                }

                if(addflag){
                  this.results.push(item);
                }
  
              
              break;

           }
           
          });

         
          this.results.sort((a: any, b: any) => {
            var sortNum = (TableSortWeightWorklistStatusCitizensCharter[a.status] ?? 99) - (TableSortWeightWorklistStatusCitizensCharter[b.status] ?? 99);
            if(sortNum > 0) {
              return 1;
            }
            else if(sortNum < 0) {
              return -1;
            }
            else {
              return 0;
            }
          });
          this.worklistDataSource = new MatTableDataSource<any>(this.results)
          this.nonCCWorklistDataSource = new MatTableDataSource<any>(this.results_3)
          
          this.submitEnabled = tempSubmitEnabled;
          this.servicesCertified = tempServicesCertified;
          this.loading = false;
        }),
        //take(1)
      )
      .subscribe(()=>{
        this.officeValueChanged(sessionStorage.getItem('selected_office') ? sessionStorage.getItem('selected_office') : 'All' )
        //this.resetSandP();
        this.resetSandPNonCC();
        this.resetCounts();

        //load counts after data has been loaded to the results array
        for(var item of this.results){
          this.setCounts(item.status)
        }
      });
  }



  private setCounts(status:any){
    switch (status) {
      case WorklistStatusCitizensCharter.DRAFT:
        this.countDraft++;
        break;
      case WorklistStatusCitizensCharter.FOR_VERIFICATION:
        this.countVerification++;
        break;
      case WorklistStatusCitizensCharter.VERIFIED:
        this.countVerified++;
        break;
      case WorklistStatusCitizensCharter.FOR_APPROVAL:
      case WorklistStatusCitizensCharter.RESUBMITTED:
        this.countApproval++;
        break;
      case WorklistStatusCitizensCharter.FOR_ARTA_REVIEW:
      case WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DC:
      case WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DIR:
        this.countAgencyReview++;
        break;
      case WorklistStatusCitizensCharter.FOR_REVISION:
        this.countRevision++;
        break;
      case WorklistStatusCitizensCharter.FOR_MODIFICATION:
        this.countConsolidation++;
        break;
    }
  }

  private async loadLatestCharterFunc() {
    let inboxFilter: QueryFn;

    inboxFilter = (filter) =>
      filter.where('fromAgencyId', '==', sessionStorage.getItem('agency_id'));

    return this.afs
      .collection('citizens-charter-phase-02', inboxFilter)
      .valueChanges()
      .pipe(
        map((data: any) => {
          // Manually sort by dateSubmitted ddesc at the JS level to save a Firestore index

          let tempSortedCharters = data.sort(
            (a: any, b: any) =>
              b.dateSubmitted.toDate() - a.dateSubmitted.toDate()
          );

          return tempSortedCharters[0];
        }),
        first()
      )
      .toPromise();
  }

  private loadOngoingCharterFunc() {
    let inboxFilter: QueryFn;

    inboxFilter = (filter) =>
      filter
        .where('fromAgencyId', '==', sessionStorage.getItem('agency_id'))
        .where('status', '!=', CharterStatusCitizensCharter.POSTED);

    return this.afs
      .collection('citizens-charter-phase-02', inboxFilter)
      .stateChanges();
  }

  private loadWorklistsFunc() {
    // Enable when multiple users per role are created
    const worklistFilterArr = this.worklistFilters.get(
      sessionStorage.getItem('account_subtype') || '' 
    );

    let inboxFilter: QueryFn;

    inboxFilter = (filter) =>
      filter
        .where('fromAgency', '==', sessionStorage.getItem('agency_id'))
        .where('status', 'in', worklistFilterArr)
        .orderBy('dueDate', 'asc');

    //comment out, do not commit
    // inboxFilter = filter => filter.orderBy('dueDate', "asc");
    //comment out, do not commit

    return this.afs
      .collection(`Worklist-Citizens Charter`, inboxFilter)
      .stateChanges();
  }

  private loadOngoingCertificationFunc() {
    let inboxFilter: QueryFn;

    inboxFilter = (filter) =>
      filter
        .where('fromAgencyId', '==', sessionStorage.getItem('agency_id'))
        .where('citizens_charter', '==', '');

    return this.afs
      .collection(`services-certification-phase-02`, inboxFilter)
      .stateChanges();
  }

  async createCertificationDialog(){
    Swal.fire({
      title: 'Are you sure?',
      text: "Submit all completed services.",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, submit all services!'
    }).then((result) => {
      if (result.isConfirmed) {
          this.createCertification()

        Swal.fire(
          'Submitted!',
          'All services is submitted.',
          'success'
        )
      }
    })
  }

  async createCertification() {
    // Only create certification if there are actually any for verification worklists
    if (
      sessionStorage.getItem('account_subtype') ===
      GovernmentAccountSubtype.AGENCY_ENC
      //&&this.forVerificationWorklistsCertification.length > 0
    ) {
      const batch = this.afs.firestore.batch(); // batch uploader, firestore

      let services_certification_ref = this.afs.firestore
        .collection(`services-certification-phase-02`)
        .doc();
      let serviceID = [];
      let worklistID = [];
      
      // Loop to get service IDs and update service entries
      for (var service of this.forVerificationWorklistsCertification) {
        /* 
          I think it would make sense to push both the service and worklist IDs
          The worklist ID helps with functionality, but you really need the service ID,
          especially when reporting of certifications comes into play
        */
        serviceID.push(service.documentId);
        worklistID.push(service.id);
      }

      let setServiceID = [];
      setServiceID = serviceID;

      let certificationJSON = {
        // Initial empty citizens_charter value for "is null" retrieval in Firestore
        citizens_charter: '',

        // Save id references to Services collection instead of service data, like with CC
        service_id: setServiceID,
        worklist_id: worklistID,

        // Other details of the certification (date and agency)
        fromAgencyId: sessionStorage.getItem('agency_id') || '',
        fromAgencyName: sessionStorage.getItem('agency_name') || '',
        certificationDate: firebase.firestore.Timestamp.now(),

        // Status of certification is currently not necessary because it ends when the citizens_charter value is filled
      };

      // then push all to charter collection db
      batch.set(services_certification_ref, certificationJSON);

      await batch.commit().then(async () => {
        this.sendNotif(EmailTemplateCode.AGENCY_LEAD_ENCODER_SUBMIT_ALL_SERVICES_TO_VERIFIER )
        this.snackBarService.displayNotif('Success','Certification of services submitted!')
        this.servicesCertified = true;
        let updatedCert = await services_certification_ref.get();
        this.activeCertification = updatedCert.data();
        this.activeCertification.id = updatedCert.id;
      });
    }
  }

  testEmail(){

    this.afs.collection("mail").add({
      to: ['rhomnel.madie28@gmail.com'],
      message: {
        subject: 'Hello from Firebase!',
        text: 'This is the plaintext section of the email body.',
        html: 'This is the <code>HTML</code> section of the email body.',
      }
    })
    
  }

  async appendCertificationDialog(){

    Swal.fire({
      title: 'Are you sure?',
      text: "Append services certification.",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, append certification!'
    }).then((result) => {
      if (result.isConfirmed) {
          this.appendCertification()

        Swal.fire(
          'Success!',
          'Services certification appended.',
          'success'
        )
      }
    })

  }

  

  async appendCertification() {
    if (
      sessionStorage.getItem('account_subtype') ===
      GovernmentAccountSubtype.AGENCY_ENC
      && this.activeCertification
      && this.activeCertification.service_id
    ) {
      const batch = this.afs.firestore.batch(); // batch uploader, firestore

      let services_certification_ref = this.afs.firestore
        .collection(`services-certification-phase-02`)
        .doc(this.activeCertification.id);
      
      let serviceID = [...this.activeCertification.service_id];
      let worklistID = [...this.activeCertification.worklist_id];

      // Loop to get service IDs and update service entries
      for (var service of this.forVerificationWorklistsCertification) {
        /* 
          Append
        */
        if(this.activeCertification.service_id.indexOf(service.documentId) < 0) {
          serviceID.push(service.documentId);
        }

        if(this.activeCertification.worklist_id.indexOf(service.id) < 0) {
          worklistID.push(service.id);
        }
      }

      let setServiceID = [];
      setServiceID = serviceID;
      let updateCerificationObj = {
        service_id: setServiceID,
        worklist_id: worklistID,
      };

      batch.update(services_certification_ref, updateCerificationObj);

      await batch.commit().then(async () => {
        this.snackBarService.displayNotif('Success','Certification of services appended!')
        this.servicesCertified = true;
        let updatedCert = await services_certification_ref.get();
        this.activeCertification = updatedCert.data();
        this.activeCertification.id = updatedCert.id;
      });
    }
  }

  async SubmittoAgencyHeadDialog(){
    Swal.fire({
      title: 'Are you sure?',
      text: "Submit Citizen's Charter to Agency Head.",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: "Yes, submit Citizen's Charter!"
    }).then((result) => {
      if (result.isConfirmed) {
          this.createCC();
      }
    })
  }

  async createCC() {
    if (
      sessionStorage.getItem('account_subtype') ===
      GovernmentAccountSubtype.AGENCY_VER
      && this.servicesCertified
      && this.verifiedWorklistsCharter.length > 0
    ) {
      const batch = this.afs.firestore.batch(); // batch uploader, firestore

      let citizens_charter_ref = this.afs.firestore
        .collection(`citizens-charter-phase-02`)
        .doc();
      let serviceID = [];

      // Loop to get service IDs and update service entries
      for (var service of this.verifiedWorklistsCharter) {
        let worklist_ref = this.afs.firestore
          .collection(`Worklist-Citizens Charter`)
          .doc(service.id);

        let updateWorklistObj: Record<string, any> =
          await this.worklistService.updateWorklistStatusObj(
            WorklistStatusCitizensCharter.FOR_APPROVAL
          );
        updateWorklistObj.citizensCharter = citizens_charter_ref.id;

        batch.update(worklist_ref, updateWorklistObj);

        serviceID.push(service.documentId);
        let service_ref = this.afs.firestore
          .collection(`Services Phase 02`)
          .doc(service.documentId);

        let updateServiceObj = {
          citizens_charter: citizens_charter_ref.id,
        };

        batch.update(service_ref, updateServiceObj);
      }

      // Update Certification's citizen_charter
      if(this.activeCertification) {
        let services_certification_ref = this.afs.firestore
          .collection(`services-certification-phase-02`)
          .doc(this.activeCertification.id);

        let updateCerificationObj = {
          citizens_charter: citizens_charter_ref.id,
        };

        batch.update(services_certification_ref, updateCerificationObj);
      }

      let agency_profile = {};
      let feedback_obj = {};
      let committeeJSON = {};
      let keywords:any = {};
      let agencyCategory:any = {};
      const agency_id = sessionStorage.getItem('agency_id') || '';
      // Agency Profile/CART/Feedback now retrieved from enhanced Agency collection
      if (sessionStorage.getItem('agency_id') || '') {
        const agency = await this.afs
          .collection(`Agency`)
          .doc(agency_id)
          .get()
          .toPromise();

        const item: any = agency.data();
        agency_profile = {
          mandate: item.mandate,
          mission: item.mission,
          vision: item.vision,
          service_pledge: item.service_pledge,
          website_link: item.agencyDetails ? item.agencyDetails.website ? item.agencyDetails.website : '' : '',
        };

        keywords = this.getKeywords(item);
        agencyCategory = item ? item.agencyDetails ? item.agencyDetails.agencyCategory ? item.agencyDetails.agencyCategory : {} : {} : {}

        feedback_obj = item.feedbackMeasures ? item.feedbackMeasures : '';

        let batches = [];
        const cartNames = [
          'Head of Agency',
          'Chairperson',
          'Vice Chairperson',
          'Members',
        ];
        let insertCartArrs: any[][] = [[], [], [], []];

        for (var x of cartNames) {
          batches.push(
            this.afs
              .collection(`Agency`)
              .doc(agency_id)
              .collection(x, (filter) => filter.orderBy('name'))
              .get()
          );
        }

        const cart = await combineLatest(batches).toPromise();

        let index = 0;
        cart.forEach((list) => {
          list.forEach((entry) => {
            insertCartArrs[index].push(entry.data());
          });
          index++;
        });

        committeeJSON = {
          head_of_agency: insertCartArrs[0],
          chairperson: insertCartArrs[1],
          vice_chairperson: insertCartArrs[2],
          members: insertCartArrs[3],
        };
      }

      let setServiceID = [];
      const latestCharter = await this.loadLatestCharterFunc();
      if (latestCharter) {
        // For every new charter made after the first, get the latest charter and combine all unique
        // Services to be DELISTED will be handled later after posting
        const previousCharterServices = latestCharter.service_id;

        setServiceID = [...new Set([...previousCharterServices, ...serviceID])];
      } else {
        // If agency has no charter yet, initial service_id value will be serviceID
        setServiceID = serviceID;
      }

      let charterJSON = {
        date: firebase.firestore.Timestamp.now(),
        // No more Phase 01 data
        status: CharterStatusCitizensCharter.FOR_APPROVAL,
        // Phase 2 details
        // Retrieve all Phase 2 form details from user management
        agency_profile: agency_profile,
        committee: committeeJSON,
        feedback: feedback_obj,
        // Save id references to Services collection instead of service data within CC
        service_id: setServiceID,

        // Additional info for ARTA CC grid
        fromAgencyId: sessionStorage.getItem('agency_id') || '',
        fromAgencyName: sessionStorage.getItem('agency_name') || '',
        dueDate: firebase.firestore.Timestamp.now(),

        keywords: keywords,
        agencyCategory: agencyCategory
      };

      // then push all to charter collection db
      batch.set(citizens_charter_ref, charterJSON);

      await batch.commit().then(() => {
        this.snackBarService.displayNotif('Success','Charter Submitted!')
        this.submitEnabled = false;
        this.loadCounts();
      });
    }
  }


  async ResubmittoAgencyHeadDialog(){
    Swal.fire({
      title: 'Are you sure?',
      text: "Submit Citizen's Charter to Agency Head.",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: "Yes, submit Citizen's Charter!"
    }).then((result) => {
      if (result.isConfirmed) {
          this.resubmitCC();
      }
    })
  }



  async resubmitCC() {
    if (
      sessionStorage.getItem('account_subtype') ===
        GovernmentAccountSubtype.AGENCY_VER
      && this.activeCharter
      && this.verifiedWorklistsCharter.length > 0
    ) {
      const batch = this.afs.firestore.batch(); // batch uploader, firestore

      let citizens_charter_ref = this.afs.firestore
        .collection(`citizens-charter-phase-02`)
        .doc(this.activeCharter.id);

      // Loop to get service IDs and update service entries
      for (var service of this.verifiedWorklistsCharter) {
        let worklist_ref = this.afs.firestore
          .collection(`Worklist-Citizens Charter`)
          .doc(service.id);

        let updateWorklistObj: Record<string, any> =
          await this.worklistService.updateWorklistStatusObj(
            WorklistStatusCitizensCharter.FOR_APPROVAL
          );

        updateWorklistObj.citizensCharter = this.activeCharter.id;

        batch.update(worklist_ref, updateWorklistObj);

        let service_ref = this.afs.firestore
          .collection(`Services Phase 02`)
          .doc(service.documentId);

        let updateServiceObj = {
          citizens_charter: this.activeCharter.id,
        };

        batch.update(service_ref, updateServiceObj);
      }

      let charterJSON = {
        status: CharterStatusCitizensCharter.FOR_APPROVAL,
        dueDate: firebase.firestore.Timestamp.now(),
      };

      // then push all to charter collection db
      batch.update(citizens_charter_ref, charterJSON);

      await batch.commit().then(() => {
        this.snackBarService.displayNotif('Success','Charter Submitted!')
        this.loadCounts();
      });
    }
  }

  
  
  search(event:Event){
    let searchValue = (event.target as HTMLInputElement).value
    this.worklistDataSource.filter = searchValue.trim().toLowerCase()
    if(this.worklistDataSource.paginator){
      this.worklistDataSource.paginator.firstPage()
    }
  }

  resetSandP(){   
    this.worklistDataSource.paginator = this.paginator
    this.worklistDataSource.sort = this.sort
    if(this.worklistDataSource.paginator){
      this.worklistDataSource.paginator.firstPage()
    }
    this.settingFilters()

    

  }

  

  resetSandPNonCC(){
    this.nonCCWorklistDataSource.paginator = this.paginator1
    this.nonCCWorklistDataSource.sort = this.sort1
    //this.settingFilters()
  }

  settingFilters(){
    this.worklistDataSource.filterPredicate = (option:any, searchValue:string): boolean =>{
      let dateGenerated = option.dateGenerated ? option.dateGenerated.toDate() : ''
      let transformedG = new DatePipe('en-US').transform(dateGenerated,'MMMM d, y h:mm:ss')
      let stringGeneratedDate = transformedG?.toString()
      let duesDate = option.dueDate ? option.dueDate.toDate() : ''
      let transformedD = new DatePipe('en-US').transform(duesDate,'MMMM d, y h:mm:ss')
      let stringDueDate = transformedD?.toString()
      if(searchValue){
        return (option.fromOfficer)?.trim().toLowerCase().includes(searchValue)
        || (option.documentTitle)?.trim().toLowerCase().includes(searchValue)
        || (option.status)?.trim().toLowerCase().includes(searchValue)
        || (option.effectivity)?.trim().toLowerCase().includes(searchValue)
        || (transformedG)?.trim().toLowerCase().includes(searchValue)
        || (transformedD)?.trim().toLowerCase().includes(searchValue)
        || (stringGeneratedDate)?.trim().toLowerCase().includes(searchValue)
        || (stringDueDate)?.trim().toLowerCase().includes(searchValue)
      }else return false
    }
  }

  sortChange(sort: Sort){
    const value: any = sessionStorage.getItem('selected_office') ? sessionStorage.getItem('selected_office') : 'All'
    let data : any = []
    if(value != 'All') data = this.results.filter((f:any) => f.process_owner.toLowerCase() === value?.toLowerCase()).slice()
    else data = this.results.slice();
    //const data = this.results.slice()
    if(!sort.active || sort.direction === ''){
        this.results_2 = data
        return;
    }
    this.results_2 = data.sort((a:any,b:any)=>{
        const isAsc = sort.direction === 'asc'
        switch (sort.active){
            case 'dateGenerated':
            return this.compare(a, b, isAsc)
            case 'category':
            return this.compare(a.category,b.category,isAsc)
            case 'status':
            return this.compare(a.status,b.status,isAsc)
            case 'documentTitle':
            return this.compare(a.documentTitle,b.documentTitle,isAsc)
            case 'process_owner':
            return this.compare(a.process_ownder,b.process_owner,isAsc)
            case 'fromOfficer':
            return this.compare(a.fromOfficer.trim().toLowerCase(), b.fromOfficer.trim().toLowerCase(), isAsc)
            // case 'account_type':
            // return this.compare(a.credentials.account_type.trim().toLowerCase(), b.credentials.account_type.trim().toLowerCase(), isAsc)
            default:
            return 0
        }
    })
    this.worklistDataSource = new MatTableDataSource<any>(this.results_2)
    this.resetSandP()
  }

  sortChange2(sort: Sort){
    const data = this.results_3.slice()
    if(!sort.active || sort.direction === ''){
        this.results_4 = data 
        return;
    }
    this.results_4 = data.sort((a:any,b:any)=>{
        const isAsc = sort.direction === 'asc'
        switch (sort.active){
            case 'dateGenerated':
            return this.compare(a, b, isAsc)
            case 'fromOfficer':
            return this.compare(a.fromOfficer.trim().toLowerCase(), b.fromOfficer.trim().toLowerCase(), isAsc)
            // case 'account_type':
            // return this.compare(a.credentials.account_type.trim().toLowerCase(), b.credentials.account_type.trim().toLowerCase(), isAsc)
            default:
            return 0
        }
    })
    this.nonCCWorklistDataSource = new MatTableDataSource<any>(this.results_4)
    this.resetSandPNonCC()
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  
  getKeywords(agency:any){
    if(agency){
      let keywords:any = {};

      if(agency.abbreviation){
        keywords.abbreviation = agency.abbreviation
      }

      if(agency.agencyDetails ? agency.agencyDetails.agencyAbbreviation ? true : false : false){
        keywords.abbreviation = agency.agencyDetails.agencyAbbreviation
      }

      return keywords
    }else return {}
  }

 async submitAllworklistservice(){
    const batch = this.afs.firestore.batch(); // batch uploader, firestore

   
    for(var worklist of this.results){

      if(worklist?.serviceCompleteness && worklist.status == WorklistStatusCitizensCharter.DRAFT && worklist?.fromOfficer == this.user.displayName ){
        worklist.status = WorklistStatusCitizensCharter.FOR_VERIFICATION

        let worklist_ref = this.afs.firestore.collection('Worklist-Citizens Charter').doc(worklist.id);
        let worklistJSON: any = {
          status: WorklistStatusCitizensCharter.FOR_VERIFICATION,
          
        }
  
        batch.update(worklist_ref, worklistJSON);

      }
     

    }

    await batch.commit().then(() => {
      let alert_message = 'Services submitted to verifier';
      this.snackBarService.displayNotif('Success',alert_message)
      window.location.reload()
    });
 
  }

  getChildData(officeObj: any, destinationArray: any[]) {
    if (officeObj.children && officeObj.children.length > 0) {
      officeObj.children.forEach((office: any) => {
        destinationArray.push(office.name);
        this.getChildData(office, destinationArray);
      });
    }
  }
  allOffices:any = []

  loadOfficeDivision() {
    let officeFilter: QueryFn;
    const agencyId = sessionStorage.getItem('agency_id');
    officeFilter = (filter) =>
      filter.where('fromAgencyId', '==', agencyId).orderBy('createdAt', 'asc');
    // .where('Status', '==', 'Active');

    //from Agency-Offices-Division Collection to Agency Office Hierarchy
    this.afs
      .collection('Agency Office Hierarchy', officeFilter)
      .snapshotChanges()
      .pipe(
        tap((data: any) => {
          this.allOffices = [];
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            this.allOffices.push(item.office.name);
            this.getChildData(item.office, this.allOffices);
            //this.allOffices.push(item.name);
          });

          this.filterredOffice = this.allOffices
         
          /**
           *  this.allOffices.sort((a, b) => a.localeCompare(b));
           * this.filteredOffices = this.officeControl.valueChanges.pipe(
            startWith(null),
            map((office: string | null) =>
              office ? this._filter(office) : this.allOffices.slice()
            )
          );
           */
        })
      )
      .subscribe();
  }

  onkey(event:any){
    const value = event.target.value.toLowerCase();
    if(value) this.allOffices = this.filterredOffice.filter((f:any) => f.toLowerCase().includes(value));
    else this.allOffices = this.filterredOffice
  }

  filterredOffice: any = []

 async  sendNotif(templateCode : EmailTemplateCode){
    const variables = {
    }
    let id  : any = sessionStorage.getItem('agency_id')
    // cred.user?.sendEmailVerification({
    //   url: environment.verificationRedirectUrls.artemis
    // });

    const docRef = this.emailer.getUsers(templateCode,id )
    
    let emaillist : any[] = [this.user.email]

    docRef.pipe(take(1))
    .subscribe({
      next : (responses)=>{

        responses.map((response)=>{
           let item:any =  response.payload.doc.data()
           emaillist.push(item.credentials.email)
        })

       //console.log(emaillist)
        this.nE.emailServiceHandler(
               emaillist,
               EmailAppCode.PBRIS,
               templateCode , 
               variables)
      }
    })

  }

  tempCode = EmailTemplateCode
  

}
