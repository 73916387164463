<div class="container">
    <div class="d-flex justify-content-center align-self-center">
        <div class="spinner-grow" role="status" *ngIf="!isLoaded">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>

    <!-- VERSION BUTTONS -->
    <!-- <div class="row">
        <div class="col-lg-2 col-sm-12">
            <table class="table table-responsive table-hover">
                <thead>
                    <tr >
                        <th class="fw-bold"><h5>Version</h5></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="textRegulationData && textRegulationData.existing" (click)="chooseVersion(textRegulationData.existing.controlCode)">
                        <th>Existing Regulation</th>
                    </tr>
                    <tr *ngIf="textRegulationData && textRegulationData.dris" (click)="chooseVersion(textRegulationData.dris.controlCode)">
                        <th>DRIS</th>
                    </tr>
                    <tr *ngIf="textRegulationData && textRegulationData.cris" (click)="chooseVersion(textRegulationData.cris.controlCode)">
                        <th>CRIS</th>
                    </tr>
                    <tr *ngIf="textRegulationData && textRegulationData.proposed" (click)="chooseVersion(textRegulationData.proposed.controlCode)">
                        <th>Proposed Regulation</th>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-lg-10 col-sm-12">
            <div style="font-weight: bold; border-bottom: .4px solid black; padding:1em;">
                <h6>
                    Version:
                    <span> {{version ? version.toUpperCase() : "N/A"}}</span>
                </h6>
            </div>
        </div>
    </div> -->

    <!-- TEXT OF REGULATION -->
    <div class="row justify-content-center">
        <div class="col-12 info-header">
            <h6 class="fw-bold mb-0">TEXT OF REGULATION</h6>
        </div>

        <ng-container *ngIf="chosenRegulation && chosenRegulation.length > 0; else noTextReg">
            <ng-container *ngFor="let section of chosenRegulation">
                <div class="col-12 table-active-column">
                    <h6>{{section.info.section_title}}</h6>
                </div>
                <div class="col-12 border-bottom" *ngFor="let type of section.info.section_type; let i = index">
                    <ng-container *ngIf="i > 0"></ng-container>{{type}}
                </div>
                <div class="col-12 border-bottom">
                    <div [innerHtml]="section.info.section_text"></div>
                    <ng-container *ngFor="let subsection of section.subsections; let subsecidx = index">
                        <div class="row" style="padding-left:2em;">
                            <label class="form-input-header fw-bold" for="feedback_remark">
                            <h6 class="section-header-link fw-bold mb-3">{{subsection.subsection_title}}
                                <!-- <button *ngIf="commentButtonsEnabled" mat-icon-button aria-label="Comment on this subsection" (click)="goToComments('regtext',secidx,subsecidx)">
                                <mat-icon>edit</mat-icon>
                                </button> -->
                            </h6>
                            </label>
                            <div class="row" style="padding-left:2em;text-align: justify;">
                                <div class="mb-0" [innerHtml]="subsection.subsection_text"></div>
                                <div class="d-flex justify-content-center" *ngIf="subsection.fileUrl">
                                    <img style="width:200px;" [src]="subsection.fileUrl">
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>

        <ng-template #noTextReg>
            <div class="row" style="padding:0.5em;">
                <label class="form-input-header fw-bold" for="feedback_remark">
                    <h6>No Text Regulation Data</h6></label>
            </div>
        </ng-template>

        <div class="row justify-content-center mt-4" *ngIf="chosenRegulationFile && chosenRegulationFile.has_annex_files">
            <div class="col-12 info-header">
                <h6 class="fw-bold mb-0"><i class="fas fa-folder-plus me-3" style="color: #FCD116;"></i>ANNEXES TO REGULATION</h6>
            </div>
            <div class="col-12 border-bottom">
                <ng-container *ngIf="(chosenRegulationFile.file || chosenRegulationFile.file_download_link); else noAnnexFile">
                    <ng-container *ngIf="chosenRegulationFile.file_type == 'zip'; else notZipFile">
                        <button class="btn btn-primary" type="button" (click)="downloadFile(chosenRegulationFile.file_download_link)">
                            Annexes to Regulation - Download: {{chosenRegulationFile.fileName}}
                        </button>
                    </ng-container>
                    <ng-template #notZipFile>
                        <app-pdf-file-view [pdfFileData]="chosenRegulationFile"></app-pdf-file-view>
                    </ng-template>
                </ng-container>
                <ng-template #noAnnexFile>
                    <div class="card d-flex justify-content-center" style="padding:1em;" >
                        <div class="row" style="padding:0.5em;">
                            <label class="form-input-header fw-bold">
                                <h6><b>No Annexes to Regulation File</b></h6>
                            </label>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

</div>