<app-wel-navbar></app-wel-navbar>
<div class="content">
    <div class="container-fluid p-4">

        <!-- Sectors -->
        <div class="row m-3">
            <div class="card">
                <div class="card-header">
                    <div class="col">
                        <h3 class="card-title">Users</h3>
                        <small class="card-subtitle">List of Users</small>
                    </div>
                </div>
                <div class="card-body">
                    <div class="col btn-group float-start">
                        <button [hidden]="entries < 1" (click)="multARTEMIS(false)" class="btn btn-outline-danger">Restrict <strong class = "badge bg-danger">ARTEMIS</strong></button>
                        <button [hidden]="entries < 1" (click)="multPBRIS(false)" class="btn btn-outline-danger">Restrict <strong class = "badge bg-primary">PBRIS</strong></button>
                        <button [hidden]="entries < 1" (click)="multAll(false)" class="btn btn-outline-danger">Restrict <strong class = "badge bg-dark">ALL</strong></button>
                    </div>

                    <div class="col btn-group float-end">
                        <button [hidden]="entries < 1" (click)="multARTEMIS(true)" class="btn btn-outline-success">Allow <strong class = "badge bg-danger">ARTEMIS</strong></button>
                        <button [hidden]="entries < 1" (click)="multPBRIS(true)" class="btn btn-outline-success">Allow <strong class = "badge bg-primary">PBRIS</strong></button>
                        <button [hidden]="entries < 1" (click)="multAll(true)" class="btn btn-outline-success">Allow <strong class = "badge bg-dark">ALL</strong></button>
                        <button [hidden]="entries < 1" (click)="multDelete()" class="btn btn-danger">Delete Account</button>
                    </div>
                    <div class="d-flex justify-content-center align-self-center">
                        <div class="spinner-grow" role="status" *ngIf="loader">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>

                    <table class="table table-hover table-responsive w-100" *ngIf="!loader">
                        <thead>
                            <tr>
                                <th>

                                </th>
                                <th>Timestamp</th>
                                <th>Email</th>
                                <th>User Type</th>
                                <th>User Subtype</th>
                                <th>Action(s)</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let entry of users">
                                <td>
                                    <input type="checkbox" [disabled]='!entry.credentials.account_subtype' [hidden]='entry.credentials.account_subtype == enum_govt_account_subtype.ARTA_ADMIN' [value]="entry" [checked]="checkCheckBoxSelected(entry)" (change)="checkCheckBoxvalue($event, entry)" class="form-check-input"
                                        type="checkbox" id="flexCheckDefault" />
                                </td>
                                <th>{{ entry.credentials.sinceMember }}</th>
                                <th>{{ entry.credentials.email }}</th>
                                <td>{{entry.credentials.account_type}}</td>
                                <td scope="row">
                                    <select class="form-select-sm" [disabled]='entry.credentials.account_subtype == enum_govt_account_subtype.ARTA_ADMIN' #subtype (change)="updateSubType(entry, subtype.value)">
                                        <option selected disabled>Update subtype</option>
                                        <option *ngFor="let x of filterType(entry.credentials.account_type)" [value]="x"
                                        [selected] = "x === entry.credentials.account_subtype"
                                        >{{x}}</option>
                                    </select>
                                </td>
                                <td scope="row">
                                    <div class="btn-group">
                                        <button (click)="setARTAccessibility(entry, false)" [hidden]="!entry.credentials.access_artemis" [disabled]='entry.credentials.account_subtype == enum_govt_account_subtype.ARTA_ADMIN' class="btn btn-sm btn-outline-danger">Restrict <strong class = "badge bg-danger">ARTEMIS</strong></button>
                                        <button (click)="setARTAccessibility(entry, true)" [hidden]="entry.credentials.access_artemis " [disabled]='entry.credentials.account_subtype == enum_govt_account_subtype.ARTA_ADMIN' class="btn btn-sm btn-outline-success">Allow <strong class = "badge bg-danger">ARTEMIS</strong></button>
                                        <a routerLink="/admin/files/{{entry.id}}" class="btn btn-sm btn-link">Files</a>
                                        <button (click)="setPBRAccessibility(entry, false)" [hidden]="!entry.credentials.access_pbris" [disabled]='entry.credentials.account_subtype == enum_govt_account_subtype.ARTA_ADMIN' class="btn btn-sm btn-outline-danger">Restrict <strong class = "badge bg-primary">PBRIS</strong></button>
                                        <button (click)="setPBRAccessibility(entry, true)" [hidden]="entry.credentials.access_pbris" [disabled]='entry.credentials.account_subtype == enum_govt_account_subtype.ARTA_ADMIN' class="btn btn-sm btn-outline-success">Allow <strong class = "badge bg-primary">PBRIS</strong></button>
                                    </div>

                                </td>

                            </tr>
                        </tbody>
                        <tbody *ngIf="users.length < 1 && !loader">
                            <tr>
                                <td colspan="4">No users</td>
                            </tr>
                        </tbody>

                    </table>

                </div>
            </div>
        </div>
    </div>
</div>