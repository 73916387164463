<!--Card-->
<div class="card">

    <!--Card Header
    <div class="card-header">

        <div class="col">
            <h3 class="card-title">{{Tab.getTitle()}}</h3>
            <small class="card-subtitle">{{Tab.getSubtitle()}}</small>
        </div>

        <div class="col">
            <button *ngIf="isCreateBTNclicked === false" class="btn btn-dark" (click)="createBTNclicked()">Create</button>
        </div>
    </div>
    Card Header: END-->


    <!--Card Body-->
    <div class="card-body">
        <!--Form-->
        <form #updateform="ngForm" (ngSubmit)="dbUpdate(updateform)" >
            <button *ngIf="currentIDstate == '' " class="btn btn-dark" (click)="triggerAddRow(objectKeys(list[0]))">Create</button>
            <!--Table-->
            <table class="table table-responsive">
                <!--Table Head-->
                <thead>
                    <tr>
                        <th *ngFor="let col of objectKeys(list[0])" class="text-uppercase">{{col == 'id'? '' : col.replace('_', ' ') }}</th>
                        <th scope='col'>Action(s)</th>
                    </tr>
                    <tr *ngIf="currentIDstate == 'add'">
                        <th *ngFor="let col of objectKeys(list[0])">
                            <input *ngIf="col != 'id'" type="text" class="form-control" name="{{col}}" [value]="" placeholder="{{col}}" ngModel>
                        </th>
                        <th scope='btn-group'>
                            <input type="submit" value="ADD" class="btn btn-sm btn-warning">
                            <button class="btn btn-sm btn-danger" (click)="cancelCRUD()">Cancel</button>
                        </th>
                    </tr>
                </thead>
                <!--Table Body-->
                <tbody>
                    <tr *ngFor="let listItem of list">
                        <!--Row Element: loop each column-->
                        <td *ngFor="let col of objectKeys(listItem)">
                            <!--Static Data-->
                            <p *ngIf="(listItem['id'] != currentID || currentIDstate == 'delete') && col != 'id'">{{listItem[col]}}</p>
                            <!--Update CRUD Input-->
                            <input *ngIf="listItem['id'] == currentID && col != 'id' && currentIDstate == 'update' " type="text" class="form-control" name="{{col}}" [value]="listItem[col]" placeholder="{{listItem[col]}}" ngModel>
                        </td>
                        <!--Action Buttons-->
                        <td>
                            <!--Normal Buttons-->
                            <div *ngIf="listItem['id'] != currentID" class="btn-group btn-group_2"><!--  -->
                                <button class="btn btn-sm btn-primary" (click)="openViewModal()">View</button>
                                <button class="btn btn-sm btn-warning" (click)="triggerUpdateRow(listItem['id'])">Update</button>
                                <button class="btn btn-sm btn-danger" (click)="triggerDeleteRow(listItem['id'])">Delete</button>
                            </div>
                            <!--Update Mode Buttons-->
                            <div *ngIf="listItem['id'] == currentID && currentIDstate == 'update'" class="btn-group"><!--  -->
                                <input type="submit" value="UPDATE" class="btn btn-sm btn-warning">
                                <button class="btn btn-sm btn-danger" (click)="cancelCRUD()">Cancel</button>
                            </div>
                            <!--Delete Mode Buttons-->
                            <div *ngIf="listItem['id'] == currentID && currentIDstate == 'delete'" class="btn-group"><!--  -->
                                <button class="btn btn-sm btn-danger" (click)="dbDelete()">DELETE</button><!--submitDeleteRequest()-->
                                <button class="btn btn-sm btn-warning" (click)="cancelCRUD()">Cancel</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <!--Table Body: END-->
            </table>
        </form>
        <!--Form: END-->
    </div>
    <!--Card Body: END-->

    <!--Create Table-->


</div>