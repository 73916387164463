<app-artemis-navigation-header
  [currentPage]="'Service'"
></app-artemis-navigation-header>

<div class="content">
  <div class=" tab-pane border border-dark border-top-0 bg-light rounded-bottom fade show active "
      id="ex1-tabs-1"
      role="tabpanel"
      aria-labelledby="ex1-tab-1">
    <div class="d-flex">
      <h4>Service</h4>
    </div>
    <!-- (ngSubmit)="search()" -->
    <form [formGroup]="searchform" >
      <div class="row service-finder-body">
        <!-- <form> -->
        <div class="col-10 ">
          <label for="keyword">Keywords:</label>
          <input
            type="text"
            class="form-control mx-2"
            placeholder="Keyword"
            formControlName="keyword"
          />
        </div>
        <div class="col-2 service-finder-image d-flex justify-content-evenly" style="flex-direction: column" >
          <div>
            <button class="btn btn-dark w-100 m-1">Search</button>
          </div>
          <div>
            <button class="btn btn-dark w-100 m-1" type="button" (click)="toArtemisAdvancedSearch()">Advanced Search</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Service List -->

<div class="container">
  <div class="col my-3">
    <div class="col d-inline-flex">
      <h2 class="text-primary fw-bold m-1">Service Results</h2>
      <h3 class="text-danger fw-bold mt-2 mx-3" *ngIf="!loading">
        {{ services.length }}
      </h3>
    </div>
    <p class="border border-primary w-100 m-3"></p>

    <div class="col my-3">
      <div class="d-flex justify-content-center align-self-center">
        <div class="spinner-grow " role="status" *ngIf="loading">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      
      <table style="width:100%"
        datatable
        [dtOptions]="dtOptions"
        [dtTrigger]="dtTrigger"
        class="table table-hover"
        [hidden]="!services"
      >
      <thead hidden>
        <tr>
          <th></th>
          <th></th>
        </tr>
      </thead>
        <tbody>
          <!-- Table Item -->
          <!-- <tr
            *ngFor="let entry of services"
            (click)="viewCitizensCharter(entry.id)"
            class="charter-row"
          > -->
          <tr
            *ngFor="let entry of services | search : searchform.value.keyword | paginate: { id: 'serviceSearchPage', itemsPerPage: 15, currentPage: p }"
            (click)="viewService(entry.id)"
            class="charter-row"
          >
            <td style="width: 25%">
              <!-- <img
                src="../../assets/ph-seal_small.png"
                class="img-fluid m-0 align-self-center"
              /> -->
              <div class="d-flex justify-content-center p-0 m-0"><!--image-->
                <mat-card class="img-container">
                  <img [src]="getImage(entry)">
                </mat-card>
              </div>
            </td>
            <td>
              <!--Service Name-->
              <h5 class="fw-bold mb-0" *ngIf="entry.serviceDetails.service_name">{{entry.serviceDetails.service_name }}</h5>
              <!--service stats-->
              <div class="stats py-2" >
                <div [ngSwitch]="entry.posted_status">
                    <div *ngSwitchCase="'POSTED'">
                        <span class="badge badge-success d-inline">
                            Status: <strong class="px-2"> {{entry.posted_status}} </strong> 
                        </span>
                    </div>
                    <div *ngSwitchCase="'DELISTED'">
                        <span class="badge badge-danger d-inline">
                            Status: <strong class="px-2"> {{entry.posted_status}} </strong> 
                        </span>
                    </div>
                    <div *ngSwitchDefault>
                        <span class="badge badge-primary d-inline">{{entry.posted_status}}</span>
                    </div>
                </div>
              </div>
              <div class="my-3">
                <p class="mb-0">Name of Agency: {{nameofAgency}}</p><!--agency name-->
                <p class="mb-0">Citizen's Charter as of: {{ entry.date_posted ? (entry.date_posted.toDate() | date: "yyyy-MM-dd hh:mm") : ' '}}</p><!--CC date posted-->
                <p class="mb-0" *ngIf="entry.serviceDetails.service_category">Category: {{entry.serviceDetails.service_category ? entry.serviceDetails.service_category : ' '}}<!--category-->    
              </div>
              <div *ngIf="entry.serviceDetails.service_description">
                <strong class="fw-bold"> Description</strong>
                <p [innerHtml]="entry.serviceDetails.service_description"></p>
              </div>
              <!-- <p>
                <i class="mx-2" *ngIf="entry.tags"
                  ><strong class="fw-bold">Tags:</strong>
                  {{ entry.tags }}</i
                >
              </p> -->
            </td>
          </tr>
        </tbody>
      </table>

      <pagination-controls id="serviceSearchPage" (pageChange)="p = $event"></pagination-controls>
    </div>
  </div>
</div>