<app-navbar></app-navbar>

<div class="content">
    <!-- Auxiliary Navigation -->
    <mat-toolbar class="auxiliary-nav">
        <button mat-icon-button (click)="drawer.toggle()" active class="example-icon" aria-label="icon-button with menu icon">
          <mat-icon class="fw-bold" matTooltipPosition="right" matTooltip="Hide Menu">menu</mat-icon>
        </button>
        <span class="ms-3"></span>
        <span class="auxiliary-nav-spacer"></span>
        <div class="auxiliary-nav-button-row">
            <a mat-icon-button (click)="showComment()" [ngClass]="showViews.submitCommentView? 'active':''" (click)="onView('submitCommentView')">
                <mat-icon class="fw-bold" matTooltipPosition="above" matTooltip="Submit a Comment">insert_comment</mat-icon>
            </a>
            <a mat-icon-button *ngIf="(mainPDFFile && mainPDFFile.file)" href="{{mainPDFFile.file}}" target="_blank">
                <mat-icon class="fw-bold" matTooltipPosition="above" matTooltip="Download the Regulation">download</mat-icon>
            </a>
            <button mat-icon-button aria-label="Example icon-button with share icon">
                <mat-icon class="fw-bold">share</mat-icon>
            </button>
            <a mat-icon-button routerLink="/pbris/regulations/existing">
                <mat-icon class="fw-bold" matTooltipPosition="above" matTooltip="Back to Regulations">arrow_back_ios</mat-icon>
            </a>
        </div>
    </mat-toolbar>
          
    <!-- Sidenav drawer -->
    <mat-drawer-container class="sidenav-container" autosize>
        <mat-drawer #drawer class="regulation-sidenav" mode="side" opened="true">
            <div class="text-center">
                <div *ngIf="from != 'proposed'"> 
                    <img title="Existing Regulation" src="/assets/Assets/Icons/Existing%20Regulation.png" height="130" width="130" alt="Existing Regulation" />
                </div>
                <div *ngIf="from == 'proposed'"> 
                    <img title="Proposed Regulation" src="/assets/Assets/Icons/Proposed%20Regulation.png" height="130" width="130" alt="Proposed Regulation" />
                </div>
            </div>

            <ul class="nav nav-pills flex-column mb-4 py-3" id="pills-tab" role="tablist" style="cursor:pointer;border-top:1px solid rgba(0,0,0,0.1);margin-top:10px;cursor:pointer;">
                
                <!-- FOR THE EXISTING REGULATION -->
                <li (click)="showContent()" *ngIf="regulationData.CURRENT && hasData.existing && userAgencyAllowed" class="nav-item" style="cursor: pointer;">
                    <a class="nav-link" [ngClass]="showViews.editRegulationDatesView? 'active':''" (click)="onView('editRegulationDatesView')" data-toggle="pill" role="tab" aria-controls="pills-regulation-dates" aria-selected="false">Regulation Dates</a>
                </li>

                <li (click)="showContent()" class="nav-item" *ngIf="from" style="cursor: pointer;">
                    <a class="nav-link" [ngClass]="showViews.regulationDetailsView? 'active':''" (click)="onView('regulationDetailsView')" data-toggle="pill" role="tab" aria-controls="pills-details" aria-selected="true">Regulation Details</a>
                </li>

                <li (click)="showContent()" class="nav-item" *ngIf="from" style="cursor: pointer;">
                    <a class="nav-link" [ngClass]="showViews.textRegView? 'active':''" (click)="onView('textRegView')" data-toggle="pill" role="tab" aria-controls="pills-textReg" aria-selected="false">Text of Regulation</a>
                </li>

                <li class="nav-item" (click)="showContent()">
                    <a class="nav-link" [ngClass]="showViews.pdfView? 'active':''" (click)="onView('pdfView')" data-toggle="pill" role="tab" aria-controls="pills-pdf" aria-selected="false">Regulation</a>
                </li>

                <!-- FOR THE PROPOSED REGULATION -->
                <!-- <li (click)="showContent()" class="nav-item" *ngIf="from && hasData.initial" style="cursor: pointer;">
                        <a class="nav-link" [ngClass]="showViews.piaView? 'active':''" (click)="onView('piaView')"   data-toggle="pill"  role="tab" aria-controls="pills-pia" aria-selected="false">PIA</a>
                    </li>

                    <li (click)="showContent()" class="nav-item" *ngIf="(from == 'proposed' || (from == 'existing' && hasData.initial)) && hasData.piaAssessment" style="cursor: pointer;">
                        <a class="nav-link" [ngClass]="showViews.piaAssessmentView? 'active':''" (click)="onView('piaAssessmentView')"   data-toggle="pill"  role="tab" aria-controls="pills-pia-assessment" aria-selected="false">PIA ASSESSMENT</a>
                    </li>

                    <li (click)="showContent()" class="nav-item" *ngIf="(from == 'proposed' || (from == 'existing' && hasData.initial)) && hasData.cris && hasData.consultations" style="cursor: pointer;">
                    <a class="nav-link" [ngClass]="showViews.consultationstView? 'active':''" (click)="onView('consultationstView')"   data-toggle="pill"  role="tab" aria-controls="pills-consultations" aria-selected="false">Consultations</a>
                    </li>

                    <li (click)="showContent()" class="nav-item" *ngIf="(from == 'proposed' || (from == 'existing' && hasData.initial)) && (hasData.cris || hasData.dris) && hasData.uploads" style="cursor: pointer;">
                    <a class="nav-link" [ngClass]="showViews.uploadsView? 'active':''" (click)="onView('uploadsView')" data-toggle="pill"  role="tab" aria-controls="pills-uploads" aria-selected="false">Uploads</a>
                    </li>

                    <li (click)="showContent()" class="nav-item" *ngIf="(from == 'proposed' || (from == 'existing' && hasData.initial))" style="cursor: pointer;">
                    <a class="nav-link" [ngClass]="showViews.riaAssessmentView? 'active':''" (click)="onView('riaAssessmentView')" data-toggle="pill" role="tab" aria-controls="pills-ria-assessment" aria-selected="false">RIA ASSESSMENT</a>
                    </li> 

                    <li class="nav-item" *ngIf="from" style="cursor: pointer;">
                        <a class="nav-link" [ngClass]="showViews.notificationsView? 'active':''" (click)="onView('notificationsView')"   data-toggle="pill"  role="tab" aria-controls="pills-notif" aria-selected="false">NOTIFICATIONS</a>
                    </li> -->

                <!-- <li class="nav-item mt-3 py-3" disabled style="cursor:pointer;border-top:1px solid rgba(0,0,0,0.1);border-bottom:1px solid rgba(0,0,0,0.1);cursor:pointer;">
                    <a class="nav-link" (click)="showComment()" [ngClass]="showViews.submitCommentView? 'active':''" (click)="onView('submitCommentView')" data-toggle="pill" role="tab" aria-controls="pills-submit-comment" aria-selected="false">
                        <i class="fas fa-comments me-2"></i>Submit a comment
                    </a>
                </li> -->

                <!-- FOR ADMIN ACTION -->
                <li class="nav-item" *ngIf="isAdmin">
                    <button (click)="deleteRegulation(collection)" class="row btn btn-danger">Delete (No Warning Prompt)</button>
                </li>
                <li class="nav-item" *ngIf="isAdmin">
                    <a class="row btn btn-secondary" routerLink="/pbris/regulations/update/{{id}}">Update Regulation</a>
                </li>

            </ul>
        </mat-drawer>
    
        <!-- Sidenav Content -->
        <div class="regulation-sidenav-content pb-5">
            <div class="container">

                <div class="col-12">
                    <!-- <strong class="text-danger">{{regulation.issuance_no}}</strong> -->
                    <strong class="text-danger">{{regulationData.CURRENT ? regulationData.CURRENT.issuance_no : '--'}}</strong>
                </div>

                <!-- REGULATION TITLE -->
                <h5 class="full-reg-title fw-bold my-4 col-12">{{regulationData.CURRENT ? ((regulationData.CURRENT.subject_full_title) ? regulationData.CURRENT.subject_full_title : regulationData.CURRENT.title) : '--'}}</h5>
                <div class="row mb-4">
                    <div class="col-md-6 d-none d-md-block">
                        <p><i class="fas fa-landmark me-2" style="color: #FCD116;"></i>
                            <!-- <strong>{{regulation.issuing_agency}}</strong></p> -->
                            <span>{{regulationData.CURRENT ? regulationData.CURRENT.issuing_agency && regulationData.CURRENT.issuing_agency.length > 0 ? regulationData.CURRENT.issuing_agency : '--' : '--'}}</span>
                        </p>
                    </div>
                    <div class="col-md-4 d-none d-md-block">
                        <p class="float-end">
                            <span *ngIf="from != 'proposed'"><i class="fas fa-calendar-alt me-2" style="color: #FCD116;"></i>Issued on </span> 
                            <span *ngIf="from == 'proposed'"><i class="fas fa-calendar-alt me-2" style="color: #FCD116;"></i>Proposed on </span>
                            <span *ngIf="regulationData.CURRENT">{{(regulationData.CURRENT.displayDate) ? regulationData.CURRENT.displayDate : regulationData.CURRENT.doc_date ? regulationData.CURRENT.doc_date : '--'}}</span>
                        </p>
                    </div>
                    <div class="col-md-2 d-none d-md-block">
                        <p class="float-end">
                            <span><i class="fas fa-info-circle me-2" style="color: #FCD116;"></i>
                                {{regulationData.CURRENT ? (regulationData.CURRENT.effectivity_type ? regulationData.CURRENT.effectivity_type : '--') : '--'}}
                            </span>
                        </p>
                    </div>
                </div>

                <!-- NEW Regulation Details VIEW -->
                <div *ngIf="showViews.regulationDetailsView && from">
                    <app-regulations-phase2-view [regulation]="regulationDetailsData"></app-regulations-phase2-view>
                </div>

                <!-- NEW Text of Regulations VIEW -->
                <div *ngIf="showViews.textRegView && from">
                    <app-regulations-textreg-view [textRegulationData]="textRegulationData"></app-regulations-textreg-view>
                </div>

                <!-- NEW PDF VIEW -->
                <div *ngIf="showViews.pdfView">
                    <ng-container *ngIf="mainPDFFile && mainPDFFile.file ; else noPDFData">
                        <app-pdf-file-view [pdfFileData]="mainPDFFile"></app-pdf-file-view>
                    </ng-container>
                    <ng-template #noPDFData>
                        <!-- *ngIf="regulation.proposed_regulation_02_id == null || regulation.proposed_regulation_02_id == undefined" -->
                        <div class="d-flex justify-content-center" style="padding:1em;" >
                            <div class="row" style="padding:0.5em;">
                                <label class="form-input-header fw-bold">
                                    <h6><b>No Regulation Document</b></h6>
                                </label>
                            </div>
                        </div>
                    </ng-template>

                    <!-- <ng-container  *ngIf="file; else noPDFData" >
                        <div style="max-width: 1000px;" class="row minispace">
                            <div class="d-flex justify-content-evenly">
                                <button (click)="pageShift('back','file')" class="btn btn-outline-primary"><</button>
                                <div><p>{{filePage}} / {{fileTotalPage ? fileTotalPage : "1"}}</p></div>
                                <button (click)="pageShift('next','file')" class="btn btn-outline-primary">></button>
                            </div>
                            <div class="d-flex justify-content-center">
                                <pdf-viewer style="max-width: 1000px;"
                                    (after-load-complete)="afterLoadComplete($event,'file')"
                                    [stick-to-page]="true"
                                    [page]="filePage"
                                    [show-all]="false" 
                                    [original-size]=false 
                                    [src]="file"></pdf-viewer>
                            </div>
                            <div class="d-flex justify-content-evenly">
                                <button (click)="pageShift('back','file')" class="btn btn-outline-primary"><</button>
                                <div><p>{{filePage}} / {{fileTotalPage ? fileTotalPage : "1"}}</p></div>
                                <button (click)="pageShift('next','file')" class="btn btn-outline-primary">></button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #noPDFData>
                        <div class="card d-flex justify-content-center" style="padding:1em;" *ngIf="regulation.proposed_regulation_02_id == null || regulation.proposed_regulation_02_id == undefined">
                            <div class="row" style="padding:0.5em;">
                                <label class="form-input-header fw-bold" for="feedback_remark">
                                    <h3><b>No Regulation Document</b></h3>
                                </label>
                            </div>
                        </div>
                    </ng-template> -->
                </div>

                <!-- OG Edit Regulation Dates VIEW -->
                <!-- <div *ngIf="regDetailsView">
                    <app-regulation-details (EditRegulation)="toEditRegulation($event)" [regulation]="regulation"></app-regulation-details>
                </div> -->

                <!-- NEW Regulations Date VIEW -->
                <div *ngIf="showViews.editRegulationDatesView">
                    <app-regulation-details (EditRegulation)="toEditRegulation($event)" [regulation]="this.regulationData.CURRENT"></app-regulation-details>
                </div>
                        
                <!-- OG CRIS VIEW -->
                <!-- <div *ngIf="crisView && from && prevProposedReg">
                    <app-regulations-cris-view [crisData]="crisData" [consultationsData]="consultationsData"></app-regulations-cris-view>
                </div> -->

                <!-- OG DRIS VIEW -->
                <!-- <div *ngIf="drisView && from && prevProposedReg">
                    <app-regulations-dris-view [drisData]="drisData"></app-regulations-dris-view>
                </div> -->

                <!-- OG Regulation Details VIEW -->
                <!-- <div *ngIf="phase2View && from">
                    <app-regulations-phase2-view [regulation]="regulation"></app-regulations-phase2-view>
                </div> -->

                <!-- OG Text Regulations VIEW -->
                <!-- <div *ngIf="textRegView && from">
                    <app-regulations-textreg-view  [id]=id"regulation['proposed-regulation-02-id']"></app-regulations-textreg-view>
                    <app-regulations-textreg-view [regulation]="regulation"></app-regulations-textreg-view>
                    <app-regulations-textreg-view [textRegulationData]="textRegulationData"></app-regulations-textreg-view>
                </div> -->

                <!-- <div *ngIf="crisView && from">
                    <app-regulations-cris-view [regulation]="regulation"></app-regulations-cris-view>
                </div> -->

                <!-- OG PIA VIEW -->
                <!-- <div *ngIf="piaView && from_proposed">
                    <app-regulations-pia-view *ngIf="!prevProposedReg" [regulation]="regulation"></app-regulations-pia-view>
                    <app-regulations-pia-view *ngIf="prevProposedReg" [regulation]="proposedRegulation"></app-regulations-pia-view>
                    <div class="card d-flex justify-content-center" style="padding:1em;" *ngIf="!from_proposed">
                        <div class="row" style="padding:0.5em;">
                            <label class="form-input-header fw-bold" for="feedback_remark"
                                ><h3><b>No PIA Data</b></h3></label>
                        </div>
                    </div>
                </div> -->

                <!-- NEW PIA VIEW -->
                <div *ngIf="showViews.piaView && hasData.initial ">
                    <app-regulations-pia-view [id]="regulationData['INITIAL'].id" [regulation]="regulationData['INITIAL']"></app-regulations-pia-view>
                </div>

                <!-- OG PIA ASSESSMENT VIEW -->
                <!-- <div *ngIf="piaAssessmentView && from_proposed && has_pia_assessment">
                    <app-pia-assessment-view [pis_assessment]="pis_assessment" [flagged_sections]="flagged_sections"></app-pia-assessment-view>
                </div> -->

                <!-- NEW PIA ASSESSMENT VIEW -->
                <div *ngIf="showViews.piaAssessmentView">
                    <app-pia-assessment-view [pis_assessment]="pis_assessment" [flagged_sections]="flagged_sections"></app-pia-assessment-view>
                </div>

                <!-- NEW Consultations VIEW -->
                <div *ngIf="showViews.consultationstView">
                    <app-public-consultations-view [objectData]="regulationData['CRIS']" baseCode="CRIS"></app-public-consultations-view>
                </div>

                <!-- NEW Uploads VIEW -->
                <div *ngIf="showViews.uploadsView">
                    <app-public-uploaded-documents-view [uploadDetailsData]="uploadDetailsData"></app-public-uploaded-documents-view>
                </div>

                <!-- OG RIA ASSESSMENT VIEW -->
                <!-- <div *ngIf="riaAssessmentView && from_proposed && has_ria_assessment">
                    <app-ria-assessment-view [drisData]="drisData"></app-ria-assessment-view>
                </div> -->

                <!-- NEW RIA ASSESSMENT VIEW -->
                <div *ngIf="showViews.riaAssessmentView">
                    <app-ria-assessment-view [drisData]="regulationData['DRIS']"></app-ria-assessment-view>
                </div>

                <!-- OG NOTIFS VIEW -->
                <!-- <div *ngIf="notificationsView && from" >
                    <app-regulations-notif-view *ngIf="regulation.proposed_regulation_02_id" [id]="regulation.proposed_regulation_02_id"></app-regulations-notif-view>
                    <div class="card d-flex justify-content-center" style="padding:1em;" *ngIf="regulation.proposed_regulation_02_id == null || regulation.proposed_regulation_02_id == undefined">
                        <div class="row" style="padding:0.5em;">
                            <label class="form-input-header fw-bold" for="feedback_remark"
                                ><h3><b>No Notifications Data</b></h3></label>
                        </div>
                    </div>
                </div> -->

                <!-- OG NOTIFS VIEW -->
                <div *ngIf="notificationsView && from" >
                    <app-regulations-notif-view *ngIf="regulationData['CURRENT'].proposed_regulation_02_id" [id]="regulationData['CURRENT'].proposed_regulation_02_id"></app-regulations-notif-view>
                    <div class="card d-flex justify-content-center" style="padding:1em;" *ngIf="regulationData['CURRENT'].proposed_regulation_02_id == null || regulationData['CURRENT'].proposed_regulation_02_id == undefined">
                        <div class="row" style="padding:0.5em;">
                            <label class="form-input-header fw-bold" for="feedback_remark"
                                ><h3><b>No Notifications Data</b></h3></label>
                        </div>
                    </div>
                </div>

                <!-- OG app-existing-reg-phase-two-view -->
                <div *ngIf="phase2View">
                    <app-existing-reg-phase-two-view></app-existing-reg-phase-two-view>
                </div>

                <!-- <app-pbris-comment-create *ngIf="show_comment" [data_catch_id]="id" [data_catch]="regulation"></app-pbris-comment-create> -->
                <app-pbris-comment-create *ngIf="show_comment" [data_catch_id]="id" [data_catch]="regulationData['CURRENT']"></app-pbris-comment-create>

                <!-- <h6 class="text-white fw-bold">
                    RELATED DATES
                </h6>
                <div class="my-3">
                    <p class="text-white m-0"> Date Published (ONAR):
                        <strong class="text-uppercase">{{regulationData.CURRENT ? (regulationData.CURRENT.date_published_onar ? regulationData.CURRENT.date_published_onar : 'NOT AVAILABLE') : 'NOT AVAILABLE'}}</strong>
                    </p>
                    <p class="text-white m-0"> Date Published (Gazette):
                        <strong class="text-uppercase">{{regulationData.CURRENT ? (regulationData.CURRENT.date_published_gazette ? regulationData.CURRENT.date_published_gazette : 'NOT AVAILABLE') : 'NOT AVAILABLE'}}</strong>
                    </p>
                    <p class="text-white m-0"> Date of Effectivity:
                        <strong class="text-uppercase">{{regulationData.CURRENT ? (regulationData.CURRENT.date_effective ? regulationData.CURRENT.date_effective : 'NOT AVAILABLE') : 'NOT AVAILABLE'}}</strong>
                        </p>
                    <p class="text-white m-0"> Date of Upload PBRIS:
                        <strong class="text-uppercase">{{regulationData.CURRENT ? (regulationData.CURRENT.effectivity_type ? regulationData.CURRENT.effectivity_type : 'NOT AVAILABLE') : 'NOT AVAILABLE'}}</strong>
                        </p>
                    <p class="text-white m-0"> Date Repealed:
                        <strong class="text-uppercase">{{regulationData.CURRENT ? (regulationData.CURRENT.date_repeal ? regulationData.CURRENT.date_repeal : 'NOT AVAILABLE') : 'NOT AVAILABLE'}}</strong>
                    </p>
                    <p class="text-white m-0"> Date Amended:
                        <strong class="text-uppercase">{{regulationData.CURRENT ? (regulationData.CURRENT.ammended ? regulationData.CURRENT.ammended : 'NOT AVAILABLE') : 'NOT AVAILABLE'}}</strong>
                        </p>
                    </div>
                </div> -->

            </div>
        </div>
    </mat-drawer-container>

</div>