import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { first, tap } from 'rxjs/operators';

@Component({
  selector: 'app-artemis-home-page-services-results',
  templateUrl: './artemis-home-page-services-results.component.html',
  styleUrls: ['./artemis-home-page-services-results.component.scss']
})
export class ArtemisHomePageServicesResultsComponent implements OnInit, OnDestroy {

  servicesList: any = []

  @Input() receivedfromserviceFinder: any[]

  @Output() closeForm = new EventEmitter<any>();


  //datatable
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtInstanceTriggered: boolean = false

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      searching: true
    };

  }

  ngOnChanges(changes: SimpleChanges) {

    this.dosomething(changes.receivedfromserviceFinder.currentValue);

  }

  agency: any;
  loading: boolean = false;
  logo_url: any = "";
  dosomething(value: any) {
    this.loadServices(value);
    if(value?.logo_url){
      this.logo_url = value?.logo_url ? value.logo_url : ""
    }
  }

  constructor(
    private afs: AngularFirestore,
    private router: Router) {

  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  sliceDescription(value: String) {

    if (value.trim().length > 40) {
      return value.substring(0, 40) + "...";
    }

    return value;

  }


  closeComponent(){
    
      this.closeForm.emit(false);
  }

  seeMore(value: any) {
    if (value.ischecked) value.ischecked = false;
    else value.ischecked = true;
  }


  loadServices(value:any) {
    this.loading = true;

    if(value.service){
      this.dtOptions.search = false;
      this.afs.collection('Services Phase 02').doc(value.service)
      .snapshotChanges().pipe(first())
      .subscribe( (data)=>{
          this.servicesList = [];
          let item: any = data.payload.data();
          item.id = data.payload.id;
          item.agency_name = item.serviceDetails.agency;
          item.ischecked = false;
          this.servicesList.push(item);

          this.loading = false;
          this.rerender();

      });

    }else{

      //console.log('Search by agency',value)

      this.afs.collection('Services Phase 02',
      filter => filter.where('posted_status', '==', 'POSTED')
      .where('fromAgencyId','==',value.agency)
        .orderBy('serviceDetails.service_name'))
      .snapshotChanges().pipe(
        tap(async (data: any) => {

          let temp:any = [];

          for await (let results of data) {
            let item: any = results.payload.doc.data();

            if (item.serviceDetails.agency) {
              item.id = results.payload.doc.id;
              item.agency_name = item.serviceDetails.agency;
              item.ischecked = false;
              temp.push(item);
            }

          }
          if(value.year !== '') {
          temp =  temp.filter((s:any)=>s.date_posted.toDate().getFullYear() == value.year);
            //console.log("after year", temp.length)
          }
          if(value.classification !== ''){
          temp=  temp.filter((s:any)=> s.serviceDetails.classification == value.classification);
          } 
           this.servicesList = temp;
          this.loading = false;
        
          this.rerender();


        })
      ).subscribe();

    }





  
    this.loading = false;


  }


 

  getImage(service: any) {
    if (service.serviceDetails) {
      if (service.serviceDetails.agency_id) {
        return "/assets/GOP_Logo.png"
      }
      else if (service.serviceDetails.agency) {
        const ctr: number = this.servicesList.map((res: any) => {
          if (res.agencyDetails) {
            if (res.agencyDetails.agencyName)
              return res.agencyDetails.agencyName;
            else if (res.name) return res.name
          }
          else if (res.name) return res.name
          return ""
        }).indexOf(service.serviceDetails.agency)
        if (ctr >= 0) {
          if (this.servicesList[ctr].agencyDetails.logoURL)
            return this.servicesList[ctr].agencyDetails.logoURL;
        }
      }
    }
    return "/assets/GOP_Logo.png"
  }

  viewService(entryId: string) {
    //TODO: Temporarily reusing the view details for worklisting
    //TODO: Create a new component for viewing separate from 
    this.router.navigate([`artemis/view/detail/${entryId}`])
  }

  rerender(): void {
    if (this.dtInstanceTriggered == true) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear().draw();
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else if (this.dtInstanceTriggered == false) {
      this.dtTrigger.next()
      this.dtInstanceTriggered = true
    }
  }

}
