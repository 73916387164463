import { Component, OnChanges, Input} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subject, combineLatest, } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, first } from 'rxjs/operators';

@Component({
  selector: 'app-view-charter-public-services',
  templateUrl: './view-charter-public-services.component.html',
  styleUrls: ['./view-charter-public-services.component.scss']
})
export class ViewCharterPublicServicesComponent implements OnChanges {
  isLoaded:boolean = false
  
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  
  expandedElement: any;  
  allAgencies:any = []

  
  @Input() services:any[] =[];
  @Input() agency_details:any;

  temp_services: any = []

  constructor(
    private afs: AngularFirestore,
    private router: Router
    ) {
    }

  ngOnChanges(): void {
    if(this.services && this.agency_details){
      this.isLoaded = true;   
      // console.log('services: ', this.services)
      // console.log('agency_details: ', this.agency_details)
      
      this.temp_services = this.services 

      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 5,
        lengthChange: false,
        searching: false
      };
      
      this.dtTrigger.next();
      // TODO: Make pagination take effect when searching again when data already has been loaded
      this.dtTrigger.unsubscribe(); 
    }

  }  
  
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  searchFilter(keyword:any){
    //console.log('keyword',keyword)
    const filterValue = keyword
  }
  
  // filter funcs
  search(event: Event) {
    let searched:any = []

    const filterValue = (event.target as HTMLInputElement).value
    // console.log(this.displayed_consultations)

    // this.services.forEach((element:any) => {
    //   if(element.serviceDetails.service_name.includes(filterValue.trim().toLowerCase())){
    //     searched.push(element)
    //   }
    // });

    if(filterValue) this.temp_services = this.services.filter((element)=>(element.serviceDetails.service_name.trim().toLowerCase()).includes(filterValue.trim().toLowerCase()))
    else this.temp_services = this.services
  }

  viewService(entryId: string){

    this.router.navigate([`/artemis/view//detail/${entryId}`])
  }
  
  loadAgencies(){
    this.afs.collection('Agency').snapshotChanges()
    .pipe(first())
    .subscribe(info=>{
      let tempArr:any = []
      info.forEach(data=>{
        let agency: any = data.payload.doc.data();
        agency.id = data.payload.doc.id;
        tempArr.push(agency)
      })
      this.allAgencies = tempArr;
    })
  }

  getImage(agency:any){
    if(agency.agencyDetails.logoURL){
      return agency.agencyDetails.logoURL
    }else{
      return "/assets/GOP_Logo.png"
    }
  } 
}
