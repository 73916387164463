export interface Consultation {
  type: string;
  purpose: string;
  stakeholder: string;
  link: string;
  from_date: Date;
  // Retrieved from "end" value of date, which is defined as Date | undefined, so ? operator is required
  to_date?: Date;
  // TODO eventually: connecting collection for Existing Regulations (NOT CRIS)
  cris_id?: string;
  regulation_id?: string;
  id?: string;
  // For future integration with Existing Regulations
  regulation_type?: string;
  // Flag if Consultation has been completed (current date is greater than to_date, no timestamp)
  completed: boolean;
}

export interface FileUploadInput {
  file_path: any;
  display_file_name: string;
  show_errors: boolean;
  newFile:boolean;
  hasFile?:boolean;
  url?:any;
}

export enum ConsultationTypes {
  ONLINE_COMMENTING = 'Online Commenting',
  FOCUS_GROUP_DISCUSSION = 'Focus Group Discussion',
  SURVERY = 'Survey',
}

export enum SubmitAs {
  ORGANIZATION = 'Organization',
  INIVIDUAL = 'Individual',
  ANONYMOUS = 'Anonymity',
}
