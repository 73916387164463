<!-- Navbar -->
<nav class="navbar fixed-top navbar-expand-lg navbar-light bg-white shadow-2" style="max-width: 100%;">
    <!-- Container wrapper -->
    <div class="container-fluid">
        <!-- Navbar brand -->
        <a class="navbar-brand" href="https://www.gov.ph/" target="_blank">GOVPH</a>

        <!-- Toggle button -->
        <button class="navbar-toggler" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fas fa-bars"></i>
        </button>

        <!-- Collapsible wrapper -->
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <!-- Left links -->
            <ul class="navbar-nav ms-auto m-0 mb-lg-0">
                <li class="nav-item">
                    <a class="nav-link {{activeHome}}" aria-current="page" href="/pbris">Home</a>
                </li>
                <!-- Navbar dropdown 
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-mdb-toggle="dropdown" aria-expanded="false" tabindex="0">
                     Regulations</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a class="dropdown-item" routerLink="/pbris/advanced-search">Advanced Search</a></li>
                        <li><a class="dropdown-item" href="/pbris/regulations/existing">Existing</a></li>
                        <li><a class="dropdown-item" href="/pbris/regulations/proposed">Proposed</a></li>

                        <li class="dropend">
                            <a class="dropdown-item dropdown-toggle">Business Related</a>
                            <ul class="dropdown-menu bg-light shadow" aria-labelledby="navbarDropdown">
                                <li><a class="dropdown-item" href="#">Stage of Business</a></li>
                                <li><a class="dropdown-item" href="#">By Sector</a></li>
                            </ul>
                        </li>

                        <li class="dropend">
                            <a class="dropdown-item dropdown-toggle">Non-Business Related</a>
                            <ul class="dropdown-menu bg-light shadow" aria-labelledby="navbarDropdown">
                                <li><a class="dropdown-item" href="#">Life Event</a></li>
                                <li><a class="dropdown-item" href="#">Stage of Life</a></li>
                            </ul>
                        </li>

                        <li class="dropend">
                            <a class="dropdown-item dropdown-toggle">By Jurisdiction</a>
                            <ul class="dropdown-menu bg-light shadow" aria-labelledby="navbarDropdown">
                                <li><a class="dropdown-item" href="#">Barangay</a></li>
                                <li><a class="dropdown-item" href="#">City/Municipal Government</a></li>
                                <li><a class="dropdown-item" href="#">Regional Government</a></li>
                                <li><a class="dropdown-item" href="#">National Government</a></li>
                            </ul>
                        </li>
                    </ul>
                </li>-->

                <!-- <li class="nav-item">
                    <a class="nav-link" href="#">Regulatory Planning</a>
                </li> -->

                <li class="nav-item">
                    <a class="nav-link {{activePolicy}}" routerLink="/pbris/regulatory-policy">The Philippine Regulatory Policy</a>
                </li>

                <li *ngIf="cred?.credentials.account_type === 'Government'" class="nav-item">
                    <a class="nav-link {{activeManagement}}" routerLink="/pbris/regulatory-management">Regulatory Management</a>
                </li>

                <li *ngIf="cred?.credentials.account_type === 'ARTA'" class="nav-item">
                    <a class="nav-link {{activeConsultation}}" routerLink="/pbris/consultation">Consultation</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link {{activeRegulations}}" routerLink="/pbris/regulations/existing">Regulations</a>
                </li>

                <!-- <li *ngIf="cred?.credentials.account_type === 'Government'" class="nav-item">
                    <a class="nav-link {{activeSurvey}}" routerLink="/pbris/feedback">Survey</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link {{activeHelp}}" routerLink="/pbris/help">Help</a>
                </li> -->

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-mdb-toggle="dropdown" aria-expanded="false" tabindex="0">
                     Help</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a class="dropdown-item" routerLink="/pbris/faq">FAQs</a></li>
                        <li><a class="dropdown-item" routerLink="/pbris/feedback">Feedback</a></li>
                    </ul>
                </li>

                <li class="nav-item">
                    <a class="nav-link {{activeContactUs}}" style="border-right:1px solid #D9D9D9;" routerLink="/pbris/contact-us">Contact Us</a>
                </li>

                <!-- Profile -->
                <li class="nav-item dropdown" *ngIf="auth.user | async as user; else showLogin">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-mdb-toggle="dropdown" aria-expanded="false">
                        <img class='list-header' style="width:25px;height:25px;" src="{{'/assets/profile-gray.png'}}"/>
                        <!--Hi, <strong class='list-header'>{{ user.displayName }}</strong> --> </a>
                    <!-- Dropdown menu -->
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                        <span>
                            <p class="pt-3 pb-0" style="color:#FCD116;font-size:10px;text-align:center;text-transform:uppercase;">{{userSubtype ? userSubtype : userType }}</p>
                            <p class="fw-bold pt-2 ps-3 pe-3" style="font-size:12px;text-align:center;">{{user.displayName}}</p>
                            <p class="pt-2 ps-3 pe-3 pb-3" style="font-size:12px;text-align:center;">{{agencyName}}</p>
                        </span>
                        <li>
                            <a class="dropdown-item" routerLink="/">Return to Homepage</a>
                        </li>
                        <li>
                            <hr class="dropdown-divider" />
                        </li>
                        <li>
                            <a class="dropdown-item" *ngIf="isAdmin" routerLink="/email-config/">
                              Email Config
                            </a>
                          </li>
                        <li>
                            <a class="dropdown-item" *ngIf="isAdmin" routerLink="/agency/">Agency Controls</a>
                        </li>
                        <li>
                            <a class="dropdown-item" *ngIf="isAdmin" routerLink="/admin/controls/">Admin Controls</a>
                        </li>
                        <li>
                            <!-- <a class="dropdown-item"  routerLink="/pbris/account/">Account Settings</a> -->
                            <a class="dropdown-item" *ngIf="isAdmin" routerLink="/account/">Admin Account Settings</a> 
                        </li>
                        <li>
                            <a class="dropdown-item"  [routerLink]="accountSettingsLink">Account Settings</a> 
                        </li>
                        <li>
                            <ng-container *ngIf="hasPage && isAgencyAdmin">
                                <a class="dropdown-item" [routerLink]="agencyMemberPage">Agency Member Controls</a>
                            </ng-container>
                        </li>
                        <li>
                            <ng-container *ngIf="hasPage">
                                <a class="dropdown-item" [routerLink]="agencyPage">Agency Page</a>
                            </ng-container>
                        </li>
                        <li>
                            <ng-container *ngIf="canViewCC && hasPage">
                                <a class="dropdown-item" (click)="goToCCReport()">Agency Citizen's Charter</a>
                                <!-- [routerLink]="toAgencyCCPage"  [queryParams]="toAgencyCCPageQP" -->
                            </ng-container>
                        </li>
                        <li>
                            <a class="dropdown-item" *ngIf="isAdmin || isArta || isAgency" routerLink="/artemis/reference-controls/">Reference Controls</a>
                        </li>
                        <li>
                            <a class="dropdown-item" routerLink="/pbris/productivity/">Reports Page</a>
                        </li>
                        <li>
                            <hr class="dropdown-divider" />
                        </li>
                        <li>
                            <a class="dropdown-item" (click)='logout()'><i class="fas fa-sign-out-alt"></i> Logout</a>
                        </li>
                    </ul>
                </li>
                <ng-template #showLogin>
                    <li class="nav-item">
                        <a class="nav-link fw-bold" href="/pbris/login">
                        Login</a>
                    </li>
                </ng-template>
            </ul>
            <!-- Left links -->


        </div>
        <!-- Collapsible wrapper -->
    </div>
    <!-- Container wrapper -->
</nav>
<!-- Navbar -->
<app-pbris-quick-search></app-pbris-quick-search>
