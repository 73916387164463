import { map, first } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { format } from 'date-fns';
import firebase from "firebase/app";
import { Subject  } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { WorklistStatusCitizensCharter } from 'src/app/entities/worklist';

@Component({
  selector: 'app-artemis-service-crud',
  templateUrl: './artemis-service-crud.component.html',
  styleUrls: ['./artemis-service-crud.component.scss']
})

export class ArtemisServiceCrudComponent implements OnInit, OnDestroy {
  status: string = "";
  services: any = [];
  searchQueryJSON: any;
  loading: boolean = true;
  userType: any = sessionStorage.getItem("user_type");
  allAgencies:any = []
  nameofAgency: any
  enum_service_status = WorklistStatusCitizensCharter

  searchform: FormGroup = new FormGroup({
    keyword: new FormControl('', Validators.required),
  });

  p:number= 1
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtInstanceTriggered:boolean = false

  searchFilters: Array<string> = ["service_name", "service_category", "service_description", "agency_name"];

  // searchFilters: Array<string> = ["serviceDetails.service_name", "serviceDetails.service_category", "serviceDetails.service_description"];
  constructor(private route: ActivatedRoute,
    private afs: AngularFirestore,
    private router: Router) {

    route.queryParams.subscribe((params: any) => {
      this.searchQueryJSON = params;
    })
  }

  ngOnInit(): void {
    this.loadAgencies()
    this.dtOptions = {
      processing: true,
        lengthMenu: [20, 50, 100],
        destroy: true,
        pagingType: 'full_numbers',
        pageLength: 10,
      lengthChange: false,
      searching: false
    };

    this.loadStatus(this.route.snapshot.params.status);

    let JSONfields: Map<string, string> = new Map();

    this.route.queryParams.subscribe((params: any) => {
      this.searchform.patchValue({
        keyword: params.keyword
      });

      if (params.keyword === "") {
        JSONfields.set("title", "");
      }
      else {
        JSONfields.set("title", params.keyword);
      }

      for (var x in this.searchFilters) {
        const searchFilter = this.searchFilters[x];
        if (params.keyword === "") {
          JSONfields.set(searchFilter, "");
        }
        else {
          JSONfields.set(searchFilter, params.keyword);
        }
      }
      this.loadServices(JSONfields);
    })
  }

  loadAgencies(){
    this.afs.collection('Agency').snapshotChanges()
    .pipe(first())
    .subscribe(info=>{
      let tempArr:any = []
      info.forEach(data=>{
        let agency: any = data.payload.doc.data();
        agency.id = data.payload.doc.id;
        tempArr.push(agency)
      })
      this.allAgencies = tempArr;
      //('+',this.allAgencies)
    })
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    if(this.dtInstanceTriggered == true){
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear().draw();
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    }else if(this.dtInstanceTriggered == false){
      this.dtTrigger.next()
      this.dtInstanceTriggered = true
    }
  }

  loadStatus(getStatus: string) {
    if (getStatus) {
      this.status = getStatus.charAt(0).toUpperCase() + getStatus.slice(1)
    }
  }

 async loadServices(inputJSON: Map<string, string>) {
    this.afs.collection('Services Phase 02', filter => filter.where('posted_status', '==', this.enum_service_status.POSTED).orderBy('serviceDetails.service_name')).snapshotChanges().subscribe(async (data: any) => {
      this.services = [];

      // data.forEach((results: any) => {
      for await (let results of data){
        let item: any = results.payload.doc.data();
        item.id = results.payload.doc.id;

        item.agency_name = item.serviceDetails.agency;

        // console.log("agency_name", item.serviceDetails.agency)
        //console.log("service", item)
        // this.services.push(item);

        if (this.services.includes(item) === false) {

          if (inputJSON.get("title") === undefined) {
            this.isAcceptedItem("", inputJSON, item.serviceDetails) //TODO: Shall we remove this??
            item.displayName= item.serviceDetails.service_name;
            this.services.push(item);
          }
          else {
            for (var x in this.searchFilters) {
              const searchFilter = this.searchFilters[x];
              let byName = this.isAcceptedItem(searchFilter, inputJSON, item.serviceDetails)
              let allBool = byName

                if (allBool) {
                  item.displayName= item.serviceDetails.service_name;
                  this.services.push(item);
                  break;
                }
            }
          }

        }
      }//);
      this.loading = false;
      //this.rerender()
      // this.dtTrigger.next();
      // TODO: Make pagination take effect when searching again when data already has been loaded
      // this.dtTrigger.unsubscribe();
    })
  }

  isAcceptedItem(keyName: string, inputJSON: Map<string, string>, item: any) {

    // if field is left empty

    if (inputJSON.get(keyName) === '') {
      return true;
    }
    // if field is from keyword, convert all to lower-case then compare
    if (this.searchFilters.includes(keyName)) {
      let inputStr = inputJSON.get(keyName) || "";
      if (
        item[keyName] && item[keyName].toLowerCase().includes(inputStr.toLowerCase())) {
        return true;
      }
    }
    // if field has data that is not from keyword
    else if (item[keyName] === inputJSON.get(keyName)) {
      return true
    }
    else if (item[keyName] === undefined || inputJSON.get(keyName) === undefined) {
      return false;
    }
    return false;
  }

  search() {
    let JSONarray = {
      keyword: this.searchform.value.keyword,
    };
    this.router.navigate([`/artemis/services`], { queryParams: JSONarray });  // define your component where you want to go
  }


  viewService(entryId: string){
    //TODO: Temporarily reusing the view details for worklisting
    //TODO: Create a new component for viewing separate from 
    this.router.navigate([`artemis/view/detail/${entryId}`])
  }

  /*getImage(service:any){
    if(service.serviceDetails){
      if(service.serviceDetails.agency_id){
        return "/assets/GOP_Logo.png"
      }
      else if(service.serviceDetails.agency){
        const ctr:number = this.allAgencies.map((res:any)=>{
          if(res.agencyDetails){
            if(res.agencyDetails.agencyName)
              return res.agencyDetails.agencyName;
            else if(res.name) return res.name
          }
          else if(res.name) return res.name
          return ""
        }).indexOf(service.serviceDetails.agency)
        if(ctr>=0){
          if(this.allAgencies[ctr].agencyDetails.logoURL)
            return this.allAgencies[ctr].agencyDetails.logoURL;
        }
      }
    }
    return "/assets/GOP_Logo.png"
  }*/

  getImage(service: any) {
    if (service.fromAgencyId) { // Check if service has agency ID
        const agencyId = service.fromAgencyId;
        // Look for agency with matching ID
        const agency = this.allAgencies.find((res: any) => res.agencyDetails && res.id === agencyId);
        if (agency && agency.agencyDetails && agency.agencyDetails.logoURL) {
          this.nameofAgency = agency?.agencyDetails?.agencyName ? agency.agencyDetails.agencyName : agency?.name ? agency.name : ""

            return agency.agencyDetails.logoURL; // Return logo URL if found
        }
    } else if (service.agency) { // If no agency ID, check for agency name
        const agencyName = service.agency;
        // Look for agency with matching name
        const agency = this.allAgencies.find((res: any) => res.agencyDetails && res.agencyDetails.agencyName === agencyName);
        if (agency && agency.agencyDetails && agency.agencyDetails.logoURL) {
          this.nameofAgency = agency?.agencyDetails?.agencyName ? agency.agencyDetails.agencyName : agency?.name ? agency.name : ""
            return agency.agencyDetails.logoURL; // Return logo URL if found
        }
    }
    return "/assets/GOP_Logo.png"; // Return default logo if no match found
}


  
  toArtemisAdvancedSearch(){
    this.router.navigate(['artemis/advanced-search'])
  }
}
