import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
enum DialogType{
  Success = 0,
  Danger = 1,
  Warning = 2,
  Info = 3,
}

enum MessageType{
  MESSAGE = 0,
  DIALOG = 1,
 
}

@Component({
  selector: 'app-artemis-custom-dialog',
  templateUrl: './artemis-custom-dialog.component.html',
  styleUrls: ['./artemis-custom-dialog.component.scss']
})
export class ArtemisCustomDialogComponent implements OnInit {
  submitFlag:any ={
    flag: true
  }
  msg_type: MessageType = MessageType.DIALOG
  btnType:any="primary"
  constructor(public dialogRef: MatDialogRef<ArtemisCustomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

    if(this.data.dialogType){

      switch(this.data.dialogType){
        case DialogType.Danger:
              this.btnType = "warn"
              break;
        case DialogType.Info:
              this.btnType = "info"
              break;
      
      }
    }

    if(this.data?.messageType){
      this.msg_type  = this.data.messageType
    }
  }

}
