<app-navbar [currentPage]="'Management'"></app-navbar>

<div class="content" *ngIf="false">
    <div class="p-4">
        <!-- <div class="row" *ngIf="auth.user | async as user; else showRestricted"> -->
        <div class="row" *ngIf="userLoading == true || (user); else showRestricted">
            <div class="container">
                <!--Nav components-->
                <div class="row">
                    <div class="col-4">
                        <nav>
                            <ul class="nav flex-column">
                                <li class="nav-item" style="font-size: 12px; background-color: #2e3338; border: 1px black solid; text-align: center; cursor: pointer;">REGULATORY MANAGEMENT</li>
                                <!--<li class="nav-item" style="border: 1px black solid; padding: 10px;">
                                    <ng-container *ngIf="userLoading == true; else finishedLoading">
                                        <div class="spinner-border text-danger" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </ng-container>
                                   <ng-template #finishedLoading>
                                        <p class="wrapper">{{user.displayName}}</p> 
                                        <br>Department of Agency
                                        <p class="wrapper"></p>
                                        <p class="wrapper">{{userType}}</p>
                                    </ng-template>
                                </li> -->
                                <li class="nav-item fw-bold" style="border: 1px black solid;padding: 10px;">
                                    <a>Dashboard</a>
                                </li>
                                <li [ngClass]="{
                                    'bg-dark text-white': sub_inbox}" (click)="openRegulatoryInbox()" class=" nav-item nav-item-click fw-bold" style="border: 1px black solid;padding: 10px; ">
                                    Inbox (click to expand)
                                </li>
                                <ng-container *ngIf="sub_inbox">
                                    <li [ngClass]="{
                                        'bg-secondary text-white': sub_inbox_existing}" (click)="openWorklistExisting()" class=" nav-item nav-item-click ms-2" style="border: 1px black solid;padding: 10px; ">
                                        <span [ngClass]="{'fw-bold' : sub_inbox_existing}">My Worklist (Existing)</span>
                                    </li>
                                    <li [ngClass]="{
                                        'bg-secondary text-white': sub_inbox_proposed}" (click)="openWorklistProposed()" class=" nav-item nav-item-click ms-2" style="border: 1px black solid;padding: 10px; ">
                                        <span [ngClass]="{'fw-bold' : sub_inbox_proposed}">My Worklist (Proposed)</span>
                                    </li>
                                    <!-- <li class=" nav-item nav-item-click ms-2 bg-light" style="border: 1px black solid;padding: 10px; ">
                                        My Group
                                    </li> -->
                                    <li [ngClass]="{
                                        'bg-secondary text-white': ticket_root}" (click)="openTickets()" class="nav-item nav-item-click ms-2" style="border: 1px black solid;padding: 10px; ">
                                       <span [ngClass]="{'fw-bold' : ticket_root}">My Tickets</span>
                                    </li>
                                    <ng-container *ngIf="ticket_root">
                                        <li [ngClass]="{
                                            'bg-info': sel_ticket}"
                                         (click)="openTickets()" class="nav-item nav-item-click ms-4" style="border: 1px black solid;padding: 10px; ">
                                            <span [ngClass]="{'fw-bold' : sel_ticket}">List Tickets</span>
                                        </li>
                                        <li [ngClass]="{
                                            'bg-info': create_ticket}"
                                         (click)="createTickets()" class="nav-item nav-item-click ms-4" style="border: 1px black solid;padding: 10px; ">
                                            <span [ngClass]="{'fw-bold' : create_ticket}">File a Ticket</span>
                                        </li>
                                        </ng-container>
                                </ng-container>
                                <li [ngClass]="{
                                    'bg-dark text-white': notification_view}" (click)="openNotifications()" class="nav-item nav-item-click fw-bold" style="border: 1px black solid;padding: 10px; ">
                                    <a>Notifications</a>
                                </li>
                                <li [ngClass]="{
                                    'bg-dark text-white': regulation_proposed_notification_view}" (click)="openRegulationProposedNotificationView()" class="nav-item nav-item-click fw-bold" style="border: 1px black solid;padding: 10px; ">
                                    <a>Regulation Proposed Notification View</a>
                                </li>
                                <!-- <li [ngClass]="{
                                    'bg-dark text-white': regulation_view}" (click)="openRegulationView()" class="nav-item nav-item-click fw-bold" style="border: 1px black solid;padding: 10px; ">
                                    <a>Inbox Regulation View</a>
                                </li> -->
                                <li [ngClass]="{
                                    'bg-dark text-white': regulatory_stock_view}" (click)="openRegulatoryStock()" class="nav-item nav-item-click fw-bold" style="border: 1px black solid;padding: 10px; ">
                                    <a>Regulatory Stock</a>
                                </li>
                                <!-- <li class=" nav-item fw-bold" style="border: 1px black solid;padding: 10px; ">
                                    <a>Regulatory Review</a>
                                </li> -->
                                <!-- <li [ngClass]="{
                                    'bg-secondary text-white': feedback_form}" (click)="feedbackForm()" class="nav-item nav-item-click fw-bold" style="border: 1px black solid;padding: 10px; ">
                                    <a>Feedback Form</a>
                                </li> -->
                                <li [ngClass]="{
                                    'bg-secondary text-white': feedback_list}" (click)="feedbackList()" class="nav-item nav-item-click fw-bold" style="border: 1px black solid;padding: 10px; ">
                                    <a>Feedback List</a>
                                </li>
                                <li [ngClass]="{
                                    'bg-secondary text-white': comment_list}" (click)="commentList()" class="nav-item nav-item-click fw-bold" style="border: 1px black solid;padding: 10px; ">
                                    <a>Comments</a>
                                </li>
                            </ul>


                        </nav>
                    </div>

                    <div class="col-8 ">
                        <div class="container " style="border: 2px solid black; padding: 30px; ">
                            <!--Main Content Here -->
                            <app-verification-regulation hidden></app-verification-regulation>
                            <app-inbox [regulationInboxCollectionName]="existing_regulation_collection_name" [regulationInboxWorklistCollectionName]="existing_regulation_worklist_collection_name" *ngIf="sub_inbox_existing "></app-inbox>                   
                            <app-inbox [regulationInboxCollectionName]="proposed_regulation_collection_name" [regulationInboxWorklistCollectionName]="proposed_regulation_worklist_collection_name" *ngIf="sub_inbox_proposed "></app-inbox>                   
                            <app-ticket-list *ngIf="sel_ticket "></app-ticket-list>
                            <app-ticketing-filing *ngIf="create_ticket"></app-ticketing-filing>

                            <!-- Notifications -->
                            <app-notifications *ngIf="notification_view"></app-notifications>

                            <div class="charter-view-card col ">
                                <app-regulatory-stock *ngIf="regulatory_stock_view "></app-regulatory-stock>
                            </div>
                            <app-regulation-proposed-notifications [regulationProposedNotificationCollectionName]="proposed_regulation_notification_name" [regulationWorklistCollectionName]="proposed_regulation_worklist_collection_name" *ngIf="regulation_proposed_notification_view"></app-regulation-proposed-notifications>
                            <app-pbris-user-feedback-view *ngIf="feedback_list"></app-pbris-user-feedback-view>
                            <app-pbris-comment-view *ngIf="comment_list"></app-pbris-comment-view>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <ng-template #showRestricted>
            <h1>This is a Restricted Page</h1>
        </ng-template>
    </div>
    <!--Views-->
</div>

<div class="content">
    <!-- <div class="row" *ngIf="auth.user | async as user; else showRestricted"> -->
        <!-- [ngClass]="collapse == true ?'collapse-true':'collapse-false'" -->
        <div class="row parent-collapse"  *ngIf="userLoading == true || (user); else showRestricted">
        <!-- <div> -->
            <mat-sidenav-container class="full-heigth" autosize="true">
                <mat-sidenav mode="side" class="full-heigth" [@collapsed]="collapse == true ? 'close' : 'open' "  opened>

                    <div mat-menu-item >
                        <div class="pad with-icon" (click)="toggleSideNav()" style="font-size: 12px; background-color: #2e3338; cursor: pointer;">
                            <mat-icon style="color:#FCD116 !important;margin: 0 !important;">menu</mat-icon>
                            <span class="ms-1 align-center" *ngIf="!collapse"><p class="wrapper items-center" style="color:#FCD116;">REGULATORY MANAGEMENT</p></span>
                        </div>
                    </div>

                    <!-- <div class="pad with-icon" *ngIf="!collapse">
                        <ng-container *ngIf="userLoading == true; else finishedLoading">
                            <div class="spinner-border text-danger" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </ng-container>
                        <ng-template #finishedLoading>
                            <div class="d-flex flex-column">
                                <p class="wrapper" title="{{user.auth.displayName}}">{{user.auth.displayName}}</p> 
                                <br>Department of Agency
                                <p class="wrapper" title="{{user.agency.agencyDetails.agencyName ? user.agency.agencyDetails.agencyName : user.agency.name ? user.agency.name : ''}}">{{user.agency.agencyDetails.agencyName ? user.agency.agencyDetails.agencyName : user.agency.name ? user.agency.name : ''}}</p>
                                <p class="wrapper" title="{{agencyName}}">{{agencyName}}</p> 
                                <p class="wrapper" title="{{userSubtype ? userSubtype : userType }}">{{userSubtype ? userSubtype : userType }}</p>
                            </div>
                        </ng-template>
                    </div>

                    <div *ngIf="collapse">
                        <ng-container *ngIf="userLoading == true; else finishedLoading">
                            <div class="spinner-border text-danger" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </ng-container>
                        <ng-template #finishedLoading>
                            <div style="margin-top: 0.5em;" class="d-flex flex-column align-items-center">
                                <span class="user-icon" style="margin-bottom:0.5em" title="{{getAcronym(user.auth.displayName)}}">{{getAcronym(user.auth.displayName)}}</span>
                                <span class="user-icon" style="margin-bottom:0.5em"><img style="width: 50px;height: 50px;" src="{{user.agency.agencyDetails.logoURL ? user.agency.agencyDetails.logoURL : '/assets/default_upload_logo.png'}}"/></span>
                            </div>
                        </ng-template>
                    </div> -->

                    <div mat-menu-item *ngIf="userType === 'arta'" style="cursor: pointer;">
                        <div class="pad with-icon">
                            <mat-icon style="margin-right:0;">dashboard</mat-icon>
                            <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Dashboard</p></span>
                        </div>
                    </div>
        
                    <div mat-accordion class="accordion" *ngIf="userSubtype != 'Government - Admin'">
                        <!-- <mat-expansion-panel expanded='false' hideToggle > -->
                        <mat-expansion-panel [expanded]='inboxExpander' hideToggle >
                            <mat-expansion-panel-header style="margin:0;padding:0;" (click)="toggleExpander('inbox')">
                                <mat-panel-title style="margin:0;padding:0;">
                                    <div class="pad with-icon">
                                        <mat-icon> inbox</mat-icon>
                                    </div>
                                </mat-panel-title>

                                <mat-panel-description class="with-icon" style="margin:0;padding:0;">
                                    <span ml align-center *ngIf="!collapse" >
                                        <!-- <p class="wrapper">Inbox (click to {{inboxExpander  ? 'collapse' : 'expand' }})</p> -->
                                        <p class="wrapper items-center" style="margin:0;padding:0">Inbox <mat-icon class="ms-3">{{inboxExpander  ? 'keyboard_arrow_down' : 'keyboard_arrow_left' }}</mat-icon></p>
                                    </span>
                                    <span *ngIf="collapse"> <mat-icon>{{inboxExpander  ? 'keyboard_arrow_down' : 'keyboard_arrow_left' }}</mat-icon> </span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                                <div mat-accordion class="accordion">
                                    <mat-expansion-panel expanded='false' hideToggle >
                                        <mat-expansion-panel-header style="border-bottom:1px solid #394046;border-radius:0;" (click)="toggleExpander('myworklist')">

                                            <mat-panel-title>
                                                <div class="pad with-icon">
                                                    <mat-icon> dns</mat-icon>
                                                </div>
                                            </mat-panel-title>

                                            <mat-panel-description class="with-icon">
                                                <span *ngIf="!collapse">
                                                    <p class="wrapper items-center">My Worklist <mat-icon class="ms-3"> {{myworklistExpander  ? 'keyboard_arrow_down' : 'keyboard_arrow_left' }}</mat-icon></p>
                                                </span>
                                                <span *ngIf="collapse"> <mat-icon>{{myworklistExpander  ? 'keyboard_arrow_down' : 'keyboard_arrow_left' }}</mat-icon> </span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        
                                            <div mat-menu-item-focus [ngClass]="sub_inbox_existing? 'active':''" (click)="openWorklistExisting2()" style="border-bottom:1px solid #394046;cursor:pointer;">
                                                <div class="pad with-icon" >
                                                    <mat-icon>playlist_add_check</mat-icon>
                                                    <span class="ms-4 align-center" *ngIf="!collapse"><p class="wrapper items-center">Existing</p></span>
                                                </div>
                                            </div>
                
                                            <!-- <div mat-menu-item [ngClass]="sub_inbox_proposed? 'active':''" (click)="openWorklistProposed2()" style="border-bottom:1px solid #394046;cursor:pointer;">
                                                <div class="pad with-icon">
                                                    <mat-icon>playlist_add</mat-icon>
                                                    <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center">Proposed</p></span>
                                                </div>
                                            </div> -->

                                    </mat-expansion-panel>
                                </div>
        
                        </mat-expansion-panel>
                    </div>
        
                    <!-- <div mat-menu-item [ngClass]="regulation_proposed_notification_view? 'active':''" (click)="openRegulationProposedNotificationView()" style="cursor: pointer;">
                        <div class="pad with-icon">
                            <mat-icon>post_add</mat-icon>
                            <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Proposed Regulation </p></span>
                        </div>
                    </div> -->
        
                    <!-- <div mat-menu-item [ngClass]="regulation_view? 'active':''" (click)="openRegulationView()" style="cursor: pointer;">
                        <div class="pad with-icon">
                            <mat-icon>view_headline</mat-icon>
                            <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Inbox Regulation View</p></span>
                        </div>
                    </div> -->
        
                    <!-- <div mat-menu-item [ngClass]="regulatory_stock_view? 'active':''" (click)="openRegulatoryStock()" style="cursor: pointer;">
                        <div class="pad with-icon">
                            <mat-icon>note_add</mat-icon>
                            <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Regulatory Stock</p></span>
                        </div>
                    </div> -->

                    <div mat-accordion class="accordion" *ngIf="userSubtype != 'Government - Admin'">
                        <mat-expansion-panel expanded='false' hideToggle >
                            <mat-expansion-panel-header style="border-bottom:1px solid #394046;border-radius:0;" (click)="toggleExpander('regulatorystockEpander')">

                                <mat-panel-title style="margin:0;padding:0;">
                                    <div class="pad with-icon">
                                        <mat-icon>library_add</mat-icon>
                                    </div>
                                </mat-panel-title>

                                <mat-panel-description class="with-icon">
                                    <span *ngIf="!collapse">
                                        <p class="wrapper items-center">Regulatory Stock <mat-icon class="ms-3"> {{regulatorystockEpander  ? 'keyboard_arrow_down' : 'keyboard_arrow_left' }}</mat-icon></p>
                                    </span>
                                    <span *ngIf="collapse"> <mat-icon>{{regulatorystockEpander  ? 'keyboard_arrow_down' : 'keyboard_arrow_left' }}</mat-icon> </span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            
                                <div mat-menu-item routerLink="/pbris/upload-beta/existing" style="border-bottom:1px solid #394046;cursor:pointer;">
                                    <div class="pad with-icon" >
                                        <mat-icon>playlist_add_check</mat-icon>
                                        <span class="ms-4 align-center" *ngIf="!collapse"><p class="wrapper items-center">Upload Existing</p></span>
                                    </div>
                                </div>
    
                                <!-- <div class="ms-3" mat-menu-item routerLink="/pbris/upload/proposed" style="border-bottom:1px solid #394046;cursor:pointer;">
                                    <div class="pad with-icon">
                                        <mat-icon>playlist_add</mat-icon>
                                        <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center">Upload Proposed</p></span>
                                    </div>
                                </div> -->

                        </mat-expansion-panel>
                    </div>

                    <!-- <div mat-menu-item [ngClass]="feedback_form? 'active':''" (click)="feedbackForm()" style="cursor: pointer;">
                        <div class="pad with-icon">
                            <mat-icon>rate_review</mat-icon>
                            <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Feedback Form</p></span>
                        </div>
                    </div> -->


                    <!-- AGENCY ADMINISTRATOR -->
                    <div mat-menu-item *ngIf="userSubtype === 'Government - Admin'" routerLink="/registration-pbris" style="cursor: pointer;">
                        <div class="pad with-icon">
                            <mat-icon style="margin-right:0;">supervisor_account</mat-icon>
                            <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Create an account</p></span>
                        </div>
                    </div>

                    <div mat-menu-item *ngIf="userType === 'arta'" [ngClass]="comment_list? 'active':''" (click)="commentList()" style="cursor: pointer;">
                        <div class="pad with-icon">
                            <mat-icon style="margin-right:0;">comment</mat-icon>
                            <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Comments</p></span>
                        </div>
                    </div>

                    <!-- ARTA ADMINISTRATOR -->
                    <div mat-menu-item *ngIf="userType === 'arta'">
                        <div class="pad with-icon" style="font-size: 14px; background-color: #2d3339; cursor: pointer;">
                            <mat-icon>settings</mat-icon> <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="font-size: 12px; color:#FFFFFF; margin:0; padding:0">ADMIN MANAGEMENT</p></span>
                        </div>
                    </div>

                    <div mat-menu-item *ngIf="userType === 'arta'" [ngClass]="notification_view? 'active':''" (click)="openNotifications()" style="cursor: pointer;">
                        <div class="pad with-icon">
                            <mat-icon>notifications</mat-icon>
                            <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Notifications</p></span>
                        </div>
                    </div>

                    <div mat-menu-item *ngIf="userType === 'arta'" [ngClass]="feedback_list? 'active':''" (click)="feedbackList()" style="cursor: pointer;">
                        <div class="pad with-icon">
                            <mat-icon>textsms</mat-icon>
                            <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Feedback List</p></span>
                        </div>
                    </div>

                    <div mat-accordion class="accordion" *ngIf="userType === 'arta'">
                        <mat-expansion-panel expanded='false' hideToggle >
                            <mat-expansion-panel-header style="border-bottom:1px solid #394046;border-radius:0;" (click)="toggleExpander('ticket')">

                                <mat-panel-title>
                                    <div class="pad with-icon">
                                        <mat-icon>featured_play_list</mat-icon>
                                    </div>
                                </mat-panel-title>

                                <mat-panel-description class="with-icon">
                                    <span *ngIf="!collapse" >
                                        <!-- Tickets (click to {{ticketExpander  ? 'collapse' : 'expand' }})</p> -->
                                        <p class="wrapper items-center">Tickets <mat-icon class="ms-3"> {{ticketExpander  ? 'keyboard_arrow_down' : 'keyboard_arrow_left' }}</mat-icon></p>
                                    </span>
                                    <span *ngIf="collapse"> <mat-icon>{{ticketExpander  ? 'keyboard_arrow_down' : 'keyboard_arrow_left' }}</mat-icon> </span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div class="ms-3" mat-menu-item [ngClass]="sel_ticket? 'active':''" (click)="openTickets2()" style="border-bottom:1px solid #394046;border-radius:0;;cursor:pointer;">
                                <div class="pad with-icon">
                                    <mat-icon>playlist_add_check</mat-icon>
                                    <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center">List Tickets</p></span>
                                </div>
                            </div>

                            <div class="ms-3" mat-menu-item [ngClass]="create_ticket? 'active':''" (click)="createTickets2()" style="border-bottom:1px solid #394046;border-radius:0;;cursor:pointer;">
                                <div class="pad with-icon">
                                    <mat-icon>playlist_add</mat-icon>
                                    <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center">File a Ticket</p></span>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </div>
                    
                </mat-sidenav>
            
                <mat-sidenav-content class="full-heigth" [@collapsed]="collapse == true ? 'auto1' : 'auto2' " >
                    <!--Main Content Here -->
                    <app-verification-regulation hidden></app-verification-regulation>
                    <app-inbox [regulationInboxCollectionName]="existing_regulation_collection_name" [regulationInboxWorklistCollectionName]="existing_regulation_worklist_collection_name" [activateChild]="activateChild" [activateObject]="activateObject" *ngIf="sub_inbox_existing"></app-inbox>                   
                    <app-inbox [regulationInboxCollectionName]="proposed_regulation_collection_name" [regulationInboxWorklistCollectionName]="proposed_regulation_worklist_collection_name" *ngIf="sub_inbox_proposed "></app-inbox>                   
                    <app-ticket-list *ngIf="sel_ticket "></app-ticket-list>
                    <app-ticketing-filing *ngIf="create_ticket"></app-ticketing-filing>

                    <!-- Notifications -->
                    <app-notifications *ngIf="notification_view"></app-notifications>

                    <div class="charter-view-card col ">
                        <app-regulatory-stock *ngIf="regulatory_stock_view "></app-regulatory-stock>
                    </div>
                    <app-regulation-proposed-notifications [regulationProposedNotificationCollectionName]="proposed_regulation_notification_name" [regulationWorklistCollectionName]="proposed_regulation_worklist_collection_name" *ngIf="regulation_proposed_notification_view"></app-regulation-proposed-notifications>
                    <app-pbris-user-feedback-create hidden *ngIf="feedback_form"></app-pbris-user-feedback-create>
                    <app-pbris-user-feedback-view *ngIf="feedback_list"></app-pbris-user-feedback-view>
                    <app-pbris-comment-view *ngIf="comment_list"></app-pbris-comment-view>
                </mat-sidenav-content>
            </mat-sidenav-container>
        <!-- </div> -->
        </div>
    <!-- </div> -->

    <ng-template #showRestricted>
        <h1>This is a Restricted Page</h1>
    </ng-template>
</div>


