<h2 mat-dialog-title class="mat-typography">{{action}} {{title}}</h2>
<div mat-dialog-content style="min-width: 440.25px; min-height: max-content;">
    <form [formGroup]="actionForm" class="form-group">
        <div class="row line-1 py-2">
            <div class="col-3"><label class="form-input-header fw-bold">Name</label></div>
            <div class="col-1">:</div>
            <div class="col">
                <ng-container  *ngIf="toView || toDelete">
                    <p>{{data.name}}</p>
                </ng-container>
                <ng-container  *ngIf="(toEdit || toCreate)" >
                    <div class="d-flex" style="flex-direction:column; height: 50px;">
                        <input   type="text" class="form-control" formControlName="name">
                        <ng-container *ngFor="let item of checkActionForm.name"> 
                            <mat-error style="color:red" *ngIf="hasErrors('name',item.type)">* {{item.message}}</mat-error>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="row line-1">
            <div class="col-3"><label class="form-input-header fw-bold">Designation</label></div>
            <div class="col-1">:</div>
            <div class="col">
                <ng-container  *ngIf="toView || toDelete">
                    <p>{{data.designation}}</p>
                </ng-container>
                <ng-container  *ngIf="(toEdit || toCreate)" >
                    <div class="d-flex" style="flex-direction:column; height: 50px;">
                        <input   type="text" class="form-control" formControlName="designation">
                        <ng-container *ngFor="let item of checkActionForm.designation"> 
                            <mat-error style="color:red" *ngIf="hasErrors('designation',item.type)">* {{item.message}}</mat-error>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="row line-1">
            <div class="col-3"><label class="form-input-header fw-bold">Email</label></div>
            <div class="col-1">:</div>
            <div class="col">
                <ng-container  *ngIf="toView || toDelete">
                    <p>{{data.email}}</p>
                </ng-container>
                <ng-container  *ngIf="(toEdit || toCreate)" >
                    <div class="d-flex" style="flex-direction:column; height: 50px;">
                        <input   type="email" class="form-control" formControlName="email">
                        <ng-container *ngFor="let item of checkActionForm.email"> 
                            <mat-error style="color:red" *ngIf="hasErrors('email',item.type)">* {{item.message}}</mat-error>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
    
    
</div>
<div mat-dialog-actions class="d-flex justify-content-between">
    <button type="button" class="btn btn-dark" (click)="goBack()" >
        <span *ngIf="!toView">Cancel</span>
        <span *ngIf="toView">Back</span>
    </button>
    <button type="button" *ngIf="toDelete" class="btn btn-danger" (click)="submitDelete()" [disabled]="loading">
        <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>Delete
    </button>
    <button type="button" *ngIf="toEdit" class="btn btn-warning" (click)="submitUpdate()" [disabled]="loading">
        <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>Update
    </button>
    <button type="button" *ngIf="toCreate" class="btn btn-primary" (click)="submitCreate()" cdkFocusInitial [disabled]="loading">
        <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>Add
    </button>
</div>