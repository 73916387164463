<div class="card shadow">
  <div class="card-header">
    <div class="row">
      <div class="col">
        <h4>Regulatory Planning</h4>
      </div>
      <div class="col text-start">
        <p>Text about submitting proposed regulations here.</p>
      </div>
    </div>
  </div>
  <div class="card-body bg-dark p-0">
    <div class="row m-1">
      <a
        routerLink="/pbris/upload/proposed"
        class="col btn btn-lg btn-outline-light"
      >
        Upload Proposed Regulation
      </a>
    </div>
  </div>
</div>
