export interface RegulationTextNode {
    name: string;
    uuid: string;
    parentUuid?: string;
    children?: RegulationTextNode[];
    nodeType: string;
}

export enum RegulationTextNodeType {
    SECTION = "SECTION",
    // To be verified by QA first before sending for approval
    SUBSECTION = "SUBSECTION",
}
