<app-navbar [currentPage]="'Help'"></app-navbar>
<div class="page-header theme-registration mb-3 py-3">
  <div class="container">
    <div class="row">
      <div class="col-12 title-widget info-widget">
        <h5 class="fw-bold py-2">FREQUENTLY ANSWERED QUESTIONS</h5>
      </div>
      <div class="col-6 d-none d-md-block">
        <div class="float-end">
          <button type="button" class="btn btn-light btn-floating mx-1" style="width: auto; height: auto;" hidden>
            <a href="/pbris/regulations/existing" title="Existing Regulation">
              <img src="/assets/Assets/Icons/Existing%20Regulation.png" height="70" alt="Existing Regulation" />
            </a>
          </button>
          <button type="button" class="btn btn-light btn-floating mx-1" style="width: auto; height: auto;" hidden>
            <a href="/pbris/regulations/proposed" title="Proposed Regulation">
              <img src="/assets/Assets/Icons/Proposed%20Regulation.png" height="70" alt="Proposed Regulation" />
            </a>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="content">
  <!-- TODO: Implement a Table of Contents -->
  <div class="container">
    <div class="card shadow my-5">
      <div class="card-header text-primary theme-default">
        <h5 class="fw-bold">
          PBRIS 2.0. Initial Frequently Answered Questions
        </h5>
      </div>
      <div class="card-body p-3 bg-gray rights-container">
        <p class="m-3">
          The PBRIS is a web-based repository of business and non-business
          regulations issued by the Philippine Government and it also serves as
          a platform for the Regulatory Management Process of the country. The
          portal, which will be linked to the official website of the ARTA,
          provides a ready reference for relevant and up-to-date information on
          regulations. Once fully developed, the system shall facilitate the
          adoption of all good regulatory practices in regulations through the
          full implementation of the Regulatory Management System and Regulatory
          Impact Assessment.
        </p>
        <p class="m-3">
          For more information, please see below the Frequent Answers to
          Questions grouped according to subject:
        </p>
      </div>
    </div>

    <!-- TABLE OF CONTENTS -->
    <div class="card shadow my-5">
      <div class="card-header text-primary theme-default">
        <h5 class="fw-bold">TABLE OF CONTENTS</h5>
      </div>
      <div class="card-body p-3 bg-gray rights-container">
        <ul type="none">
          <li><a [routerLink]="" fragment="Regulations">Regulations</a></li>
          <li>
            <a [routerLink]="" fragment="RegulatoryManagementSystem"
              >Regulatory Management System</a
            >
          </li>
          <li><a [routerLink]="" fragment="ForALL">For ALL</a></li>
          <li>
            <a [routerLink]="" fragment="ForGeneralPublicPrivateSector"
              >For General Public/Private Sector</a
            >
          </li>
          <li>
            <a [routerLink]="" fragment="GovernmentAgencies"
              >Government Agencies</a
            >
          </li>
        </ul>
      </div>
    </div>

    <div id="Regulations" class="card shadow my-5">
      <div class="card-header text-primary theme-default">
        <h5 class="fw-bold">Regulations</h5>
      </div>
      <div class="card-body p-3 bg-gray rights-container">
        <ol>
          <li>
            <h5 class="fw-bold">What is a Regulation?</h5>
            <p class="m-3">
              A regulation is any legal instrument that gives effect to a
              government policy intervention and includes licensing, imposing
              information obligation, compliance to standards or payment of any
              form of fee, levy, charge or any other statutory and regulatory
              requirements necessary to carry out activity or modify behavior.
            </p>
            <p class="m-3">There are three classifications of regulations:</p>
            <ul>
              <li>Primary Regulations (Legislation) – issued by Congress</li>
              <li>
                Secondary Regulations (Regulation) – issued by Executive
                Government Agencies
              </li>
              <li>
                Tertiary Regulation (Ordinances) – issued by Local Government
                Units
              </li>
            </ul>
            <p class="m-3">
              Regulations referred to in the PBRIS are Secondary and Tertiary
              Regulations.
            </p>
          </li>
          <li>
            <h5 class="fw-bold">Who issues Regulations?</h5>
            <p class="m-3">
              Regulatory agencies issue regulations. These are agencies mandated
              by the Constitution and/or Legislation to codify and enforce rules
              and regulations and impose supervision and oversight. Examples of
              regulatory agencies are: (i) Bureau of Fire Protection (BFP); (ii)
              Department of Trade and Industry (DTI); (iii) Food and Drug
              Administration (FDA); (iv) Securities and Exchange Commission
              (SEC); (v) Department of the Environment and Natural Resources
              (DENR);
            </p>
          </li>
        </ol>
      </div>
    </div>

    <div id="RegulatoryManagementSystem" class="card shadow my-5">
      <div class="card-header text-primary theme-default">
        <h5 class="fw-bold">Regulatory Management System</h5>
      </div>
      <div class="card-body p-3 bg-gray rights-container">
        <ol>
          <li>
            <h5 class="fw-bold">
              What is the Regulatory Management System? (RMS)
            </h5>
            <p class="m-3">
              The Regulatory Management System (RMS) is the overall architecture
              of the Philippine Government detailing the different policies,
              processes, and tools involved in using its regulatory powers and
              the different institutions mandated to oversee and manage the
              system. It shall apply across the life cycle of a regulation --
              from drafting, implementing up to its eventual repeal/revocation.
              Through the RMS, a systematic and organized implementation of
              regulations is aimed to be achieved which shall create a conducive
              regulatory environment for citizens and businesses to strive in.
            </p>
          </li>
          <li>
            <h5 class="fw-bold">What is the Rulemaking Process?</h5>
            <p class="m-3">
              The rulemaking process outlines all the necessary steps and
              processes that apply to all proposed regulations of regulatory
              agencies as mandated by Republic Act 11032, the Administrative
              Code of 1987, the National Policy on Regulatory Management and
              other existing laws and regulations.
            </p>
            <p class="m-3">
              The process allows transparency and clarity in rulemaking by
              providing all relevant stakeholders information on the status and
              progress of a proposed regulation and be notified of its
              implementation.
            </p>
            <p class="m-3">
              In its current iteration the following are the stages of the
              Rulemaking Process:
            </p>
            <ol type="a">
              <li>
                <p class="m-3">
                  Pre-Rule Stage – The regulatory agency intends to draft a
                  regulation and is determining viable alternative
                  non-regulatory options through preliminary impact assessment.
                  Should the assessment support the implementation of a
                  regulation, the agency submits its preliminary impact
                  statement (PIS) to ARTA.
                </p>
              </li>
              <li>
                <p class="m-3">
                  ARTA Preliminary Review – ARTA is reviewing the PIS submitted
                  by the agency and evaluating whether it has adequately
                  explained and justified the proposed regulation. If ARTA deems
                  the proposed regulation to have a significant impact on the
                  economy, businesses, individuals or households, or the
                  environment ARTA will require the regulatory agency to conduct
                  a full RIA process of the proposed regulation (Proposed Rule
                  Stage). Otherwise, the regulation proceeds to the Interim Rule
                  Stage.
                </p>
              </li>
              <li>
                <p class="m-3">
                  Proposed Rule Stage – The regulation has been assessed as
                  major and is undergoing regulatory impact assessment. The
                  drafted regulatory impact statement (RIS) shall be submitted
                  to the PBRIS for public consultation. Upon completion of the
                  consultation, the regulatory finalizes the RIS and submits it
                  to ARTA.
                </p>
              </li>
              <li>
                <p class="m-3">
                  ARTA Proposed Rule Review - The proposed regulation and its
                  regulatory impact statement has been submitted to ARTA and is
                  undergoing proposed rule review. ARTA may approve or
                  disapprove a RIS based on its quality. Regardless of the
                  assessment, all regulations will proceed to the Interim Rule
                  Stage.
                </p>
              </li>
              <li>
                <p class="m-3">
                  Interim Rule Stage - The proposed regulation and its impact
                  statement has been assessed by ARTA and is returned to the
                  agency. The regulatory agency shall be informing ARTA of its
                  next course of action:
                </p>
                <ul>
                  <li>
                    <p class="m-3">
                      To proceed to the Final Rule Stage: Regulatory agencies
                      are required to submit the implementation form which shall
                      detail dates of promulgation, filing with ONAR,
                      publication in the Official Gazette/Newspaper of General
                      Circulation, and the effectivity of the regulation.
                    </p>
                    <p class="m-3">
                      For minor regulations: a Monitoring & Evaluation Plan must
                      be submitted to ARTA with the Notification Form.
                    </p>
                    <p class="m-3">
                      For major disapproved regulations: the regulatory agency
                      provides a justification for implementation in response to
                      the disapproval of the RIS with the Notification Form.
                    </p>
                  </li>
                  <li>
                    <p class="m-3">To re-undergo the Proposed Rule Stage:</p>
                    <p class="m-3">
                      Regulatory agencies with a disapproved RIS or who are not
                      satisfied with the quality of their regulation may opt to
                      re-undergo the RIA Process and draft a new Regulatory
                      Impact Statement.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p class="m-3">
                  Final Rule Stage – The proposed regulation and its regulatory
                  impact statement is for promulgation, publishing and
                  implementation.
                </p>
              </li>
              <li>
                <p class="m-3">
                  Existing Regulation – The regulation is being implemented and
                  enforced
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h5 class="fw-bold">
              What is Regulatory Impact Assessment (for Proposed Regulations)?
            </h5>
            <p class="m-3">
              Because of regulations’ significant impact on people and
              businesses, there is a need to develop effective and efficient
              regulations. That is why, regulatory agencies are required to
              subject proposed regulations to Regulatory Impact Assessment
              (RIA). RIA assesses the expected positive and negative effects of
              the proposed regulations and non-regulatory alternatives, using a
              consistent analytical method such as cost-benefit analysis,
              multi-criteria analysis and other evaluation techniques. Through
              RIA, relevant information is provided to decision makers and
              stakeholders about the effectiveness, efficiency and expected
              consequences of a range of alternative policy options that can
              address a particular policy problem or issue.
            </p>
            <p class="m-3">
              RIA also applies to existing regulations. Please see Question 6
              for the discussion.
            </p>
          </li>
          <li>
            <h5 class="fw-bold">
              What are the Filing and Publication Requirements (Final Rule
              Stage)?
            </h5>
            <p class="m-3">
              The Administrative Code of 1987 states that regulations shall
              become effective fifteen (15) days from (i) the filing of a
              regulation with the Office of the National Administrative Register
              (ONAR), and (ii) its publication with the Official Gazette or a
              Newspaper of General Circulation.
            </p>
          </li>
          <li>
            <h5 class="fw-bold">What is Stock Management?</h5>
            <p class="m-3">
              Stock management, just like its logistics counterpart, refers to
              the monitoring, controlling, and tracking of the inventory of
              regulations issued by a regulating agency. Through the PBRIS,
              Regulatory agencies are provided a permanent inventory of all
              issued regulations regardless of their effectivity. The inventory
              provides the agency an overview of regulatory burdens and helps
              prevent overregulation.
            </p>
            <p class="m-3">
              In addition, regulations that are initially well-designed and
              effectively implemented may become irrelevant and burdensome due
              to changes in the economy, advancements in technology and cultural
              and behavioral shifts with the passage of time. Hence, regulators
              must conduct regular reviews of their stock of regulation and
              ensure that post-implementation reviews of regulations are
              conducted on time.
            </p>
          </li>

          <li>
            <h5 class="fw-bold">What is Post-Implementation Review?</h5>
            <p class="m-3">
              Post-Implementation Review refers to the assessment of a
              regulation after its implementation. The review is triggered by
              any of the following:
            </p>
            <ul>
              <li>
                <p class="m-3">
                  Scheduled Review specified in the Monitoring and Evaluation
                  Plan
                </p>
              </li>
              <li>
                <p class="m-3">Embedded Review mechanisms in the regulation</p>
              </li>
              <li>
                <p class="m-3">
                  Ad-hoc Reviews initiated by an oversight agency and/or the
                  regulatory agency itself
                </p>
              </li>
            </ul>
            <p class="m-3">
              Consistent analytical method such as cost-benefit analysis,
              multi-criteria analysis and other evaluation techniques embedded
              in RIA will also be applied to assess the actual positive and
              negative effects of the existing regulation.
            </p>
            <p class="m-3">
              RIA also applies to proposed regulations. Please see Question 3
              for the discussion.
            </p>
          </li>
        </ol>
      </div>
    </div>

    <div id="ForALL" class="card shadow my-5">
      <div class="card-header text-primary theme-default">
        <h5 class="fw-bold">For ALL:</h5>
      </div>
      <div class="card-body p-3 bg-gray rights-container">
        <h5 class="fw-bold">What is PBRIS?</h5>
        <p class="m-3">
          The Philippine Business Regulations Information System (PBRIS) is a
          web-based platform providing accessible information on the Philippine
          Regulatory Management System, as well as laws and regulations relevant
          to the public. Aside from being a repository of business and
          non-business regulations, the PBRIS serves as a platform for the
          Regulatory Management Process, particularly Regulatory Impact
          Assessment.
        </p>

        <h5 class="fw-bold">Who is PBRIS for?</h5>
        <p class="m-3">
          The PBRIS is open for everyone: from students/researchers who wish to
          learn more about regulations, to businesses seeking to set-up and
          operate in the Philippines, to government officials involved in the
          policymaking process.
        </p>

        <h5 class="fw-bold">What can I do with PBRIS?</h5>
        <p class="m-3">
          Through a responsive categorization of regulations per type, sector,
          stage of life, case-use, and locality, the search for regulations is
          made easier and faster with PBRIS. It also allows the creation of user
          accounts to provide a customized experience to its users. Available
          functionalities of PBRIS that users can utilize include text-readable
          regulations, subscriptions to regulations specific to their interests,
          regulatory notifications and updates, and a platform for public
          consultation and commenting.
        </p>
      </div>
    </div>

    <div id="ForGeneralPublicPrivateSector" class="card shadow my-5">
      <div class="card-header text-primary theme-default">
        <h5 class="fw-bold">For General Public/Private Sector:</h5>
      </div>
      <div class="card-body p-3 bg-gray rights-container">
        <h5 class="fw-bold">How can I create a PBRIS account?</h5>
        <p class="m-3">
          In order to create an account, a user must provide the following
          information and requirements:
        </p>

        <p class="m-3">For private individuals:</p>
        <ol>
          <li>First Name</li>
          <li>Last Name</li>
          <li>City/Region of Residence</li>
          <li>Copy of Government Issued ID</li>
          <li>Email Address (for verification and 2FA)</li>
          <li>Cellphone Number (optional for 2FA)</li>
        </ol>

        <p class="m-3">
          For Private Organizations/Companies requesting official accounts in
          the system:
        </p>
        <ol>
          <li>Name of Organization</li>
          <li>Company Address</li>
          <li>Company Email</li>
          <li>Contact Details of Organization</li>
          <li>Copy of Official Company ID</li>
          <li>Details of Authorized Representative</li>
        </ol>
        <p class="m-3">
          To assist you in creating a PBRIS account, please click here:
          <a routerLink="/registration-pbris">Create an Account</a>.
        </p>

        <h5 class="fw-bold">
          Is there a charge for creating an account in PBRIS?
        </h5>
        <p class="m-3">No. Creating an account in PBRIS is free of charge.</p>

        <h5 class="fw-bold">
          How can I edit/update my personal information reflected on my PBRIS
          account?
        </h5>
        <p class="m-3">
          Users may edit/update their personal information in PBRIS anytime. For
          security purposes, a confirmation email and/or two-factor
          authentication will be required to verify edits/updates in a user's
          personal information.
        </p>
        <!-- TODO: Add anchor tags for My Acccount and Persional Information -->
        <p class="m-3">
          To edit/update your account, please go to My Account > Personal
          Information > Edit.
        </p>

        <h5 class="fw-bold">I forgot my password. How can I reset it?</h5>
        <p class="m-3">
          To reset your password, please click on
          <a
            routerLink="/forgot-password"
            [queryParams]="{ requestFrom: 'pbris' }"
            >"Forgot your password?"</a
          >
          in the login screen. For security purposes, a confirmation email
          and/or two-factor authentication will be required to verify password
          reset.
        </p>

        <h5 class="fw-bold">
          I lost access to the email I used for my PBRIS account. What should I
          do?
        </h5>
        <p class="m-3">
          If a user loses access to the email he/she used to register in PBRIS,
          logging in using their username can be an option. Once logged in, the
          user may update his/her Account Information > Email Address.
        </p>

        <h5 class="fw-bold">
          What is the coverage of a customized experience in PBRIS once an
          account is created?
        </h5>
        <p class="m-3">
          When registered with a PBRIS Account, a user may have access to the
          following features in addition to the unlimited access to the
          inventory of regulations:
        </p>
        <ul>
          <li>
            <p class="m-3">
              Subscription to specific categories and classifications of
              regulations (Business/Non-Business, Sector/Stage of Life,
              Case-Use, Locality)
            </p>
          </li>
          <li><p class="m-3">Commenting during Public Consultations</p></li>
          <li>
            <p class="m-3">
              Receipt of in-system and/or email notifications on updates and
              announcements regarding subscribed regulations
            </p>
          </li>
        </ul>

        <h5 class="fw-bold">
          How can I subscribe to regulations I am interested about?
        </h5>
        <!-- TODO: We have a "My Subscriptions"? -->
        <p class="m-3">
          Upon successful creation of a PBRIS Account, users will be directed to
          select regulation categories and classifications that they want to
          subscribe to. To view/edit your subscriptions, please go to "My
          Subscriptions".
        </p>

        <h5 class="fw-bold">
          Can I unsubscribe to regulations I have previously subscribed to?
        </h5>
        <p class="m-3">
          Yes. To view/edit your subscriptions, please go to "My Subscriptions".
        </p>

        <h5 class="fw-bold">
          How can I be notified about regulatory updates I subscribed to?
        </h5>
        <!-- TODO: We have user edit notifications? -->
        <p class="m-3">
          Users may choose to receive email notifications about the regulations
          they have subscribed to. To receive notifications or edit your
          notification settings, please click here.
        </p>

        <h5 class="fw-bold">What are public consultations?</h5>
        <p class="m-3">
          Public consultation is an activity conducted by government agencies to
          gather comments and inputs from the concerned stakeholders of a
          regulation they are implementing/planning to implement. All comments
          and inputs received from public consultations must be studied and
          those applicable must be taken into consideration to guide
          policymakers in crafting and/or improving regulations to be relevant,
          efficient, and effective.
        </p>

        <h5 class="fw-bold">How can I participate in public consultations?</h5>
        <p class="m-3">
          Users may participate in public consultations by providing relevant
          and informed comments on the regulation subject for review. Attachment
          of supporting documents (if any) will also be allowed.
        </p>

        <h5 class="fw-bold">
          How can I provide comments on regulations under review?
        </h5>
        <!-- TODO: Link for My Comments -->
        <p class="m-3">
          User can comment on a regulation open for public consultation by
          clicking the "comment icon" in the left pane of the regulation text.
          Before submitting the comment, the user will be prompted to review
          and/or edit his comment to ensure that he/she has indicated all inputs
          and concerns they want to raise. Once submitted successfully, the
          comment will be assigned a Tracking Number and reflected in the user's
          PBRIS Account > My Comments.
        </p>

        <h5 class="fw-bold">Can I submit a comment as an anonymous user?</h5>
        <p class="m-3">
          Yes. Users may choose to identify themselves as an organization,
          private citizen, or anonymous when submitting a comment. The identity
          of users who opt to send comments anonymously will be disclosed only
          to the Anti-Red Tape Authority, but may be shared to the concerned
          agency/ies when deemed necessary and appropriate. Users must be
          notified of such action accordingly.
        </p>

        <h5 class="fw-bold">
          How will I be notified when the agency has provided a feedback on my
          comment?
        </h5>
        <p class="m-3">
          User will receive email and/or in-system notifications should the
          issuing agency send a reply to the user’s comment. This update will
          also be reflected in the user’s PBRIS Account > My Comments.
        </p>

        <h5 class="fw-bold">Can I reply to a comment given by another user?</h5>
        <p class="m-3">
          No. Only the issuing agency/ies can reply to comments of users. Should
          one wish to comment in response/relation to another comment, they must
          submit a separate comment and may opt to note that they are commenting
          in response/relation to a previous one.
        </p>

        <h5 class="fw-bold">
          Is there a limit to the number of comments I can send in a single
          consultation?
        </h5>
        <p class="m-3">
          Yes. Per consultation period, a user can submit a maximum of two (2)
          comments only. This shall facilitate management of comments that
          agencies would receive, and help in avoiding comments that are
          irrelevant/unrelated to the regulation being reviewed.
        </p>
      </div>
    </div>

    <div id="GovernmentAgencies" class="card shadow my-5">
      <div class="card-header text-primary theme-default">
        <h5 class="fw-bold">Government Agencies:</h5>
      </div>
      <div class="card-body p-3 bg-gray rights-container">
        <h5 class="fw-bold">How can we register to PBRIS?</h5>
        <p class="m-3">
          To register accounts for agency users in PBRIS, the agency must first
          submit an Office Order, containing the names of the duly assigned
          agency representatives, to the Anti-Red Tape Authority. This document
          shall ensure the agency's accountability in all information to be
          uploaded and disseminated through the PBRIS, may it be on the text of
          their regulations, Regulatory Impact Statements, consultation details
          and schedules, contact details, etc.
        </p>
        <p class="m-3">
          The duly assigned agency representatives will be given the “master
          account” of each agency which shall be used to approve subsequent
          agency user accounts in the PBRIS and other relevant functionalities.
        </p>
        <p class="m-3">
          Details to be requested from the agency user upon creation of account
          are the following:
        </p>
        <ol>
          <li>Name</li>
          <li>Agency</li>
          <li>Agency Address</li>
          <li>Agency Email</li>
          <li>Contact Details of Agency</li>
          <li>Details of Authorized Master Representative</li>
        </ol>

        <h5 class="fw-bold">
          We would like to update the list of our authorized users and their
          specific roles. What should we do?
        </h5>
        <p class="m-3">
          You can register additional authorized users in the system through the
          registration page subject to the verification of your agency’s PBRIS
          master account. If your agency needs to update its master account, you
          can send an email to
          <a class="fw-bold" href="mailto:regulatorymanagement@arta.gov.ph"
            >regulatorymanagement@arta.gov.ph</a
          >.
        </p>

        <h5 class="fw-bold">
          Can the Agency Head assign an authorized representative to be
          in-charge of handling his/her account?
        </h5>
        <p class="m-3">
          Yes. The Agency Head may choose to assign an authorized representative
          to handle his/her account to ensure timely responses and actions to
          smoothly carry out activities in the system, on the Agency Head's
          behalf.
        </p>

        <h5 class="fw-bold">
          What happens if the Agency Head assigns an authorized representative
          to handle his/her account?
        </h5>
        <p class="m-3">
          The authorized representative of the Agency Head will be given access
          to the Agency Head's account and/or be assigned of the same role to
          enable actions unique to the Agency Head.
        </p>

        <h5 class="fw-bold">
          How can we upload existing and proposed regulations?
        </h5>
        <p class="m-3">
          Stages in the encoding and uploading of regulations in PBRIS will be
          performed by duly designated Encoders and Verifiers:
        </p>
        <p class="m-3">
          Encoding of the Regulation: Job Order/Contract-of-Service Employee/s
        </p>
        <p class="m-3">
          Review, Revision, and Verification of the Regulation Text: Plantilla
          Employee/s
        </p>
        <p class="m-3">
          Submission to ARTA and Uploading of the Regulation: Plantilla
          Employee/s
        </p>
        <br />
        <!-- TODO: Link to where? -->
        <p class="m-3">
          To view the simplified process for uploading regulations in PBRIS,
          please click here.
        </p>

        <h5 class="fw-bold">
          The appropriate tag/s for our regulation is currently unavailable on
          PBRIS. Can we request for additional tags?
        </h5>
        <!-- TODO: Implement send a ticket -->
        <p class="m-3">
          Yes. Should agencies find that the appropriate tag/s for their
          regulations are not provided in PBRIS, they can Send a Ticket to ARTA,
          informing of their request for additional tag/s.
        </p>

        <h5 class="fw-bold">
          In what cases are we allowed to edit/update the text of a regulation
          we have uploaded in PBRIS?
        </h5>
        <p class="m-3">
          Agencies may edit their regulations should they notice and/or receive
          reports of typographical or grammatical errors in the text of their
          regulations. Edit logs will be recorded by the system to allow
          monitoring of updates made in a regulation.
        </p>

        <h5 class="fw-bold">
          How can we edit/update the text of an uploaded regulation?
        </h5>
        <!-- TODO: Implement Stock of Regulations -->
        <p class="m-3">
          To edit/update uploaded regulations in PBRIS, the agency user shall
          Manage Stock of Regulations, select the regulation to be edited, and
          click on "Edit Regulation".
        </p>

        <h5 class="fw-bold">
          Will users be notified of updates we make on our regulations?
        </h5>
        <p class="m-3">
          Yes. Aside from the edit logs to be recorded by the system and
          accessible to all types of users, an in-system and/or email
          notification will also be sent to subscribed users for every update on
          a regulation they are subscribed to.
        </p>

        <h5 class="fw-bold">
          How can we open a regulation for public consultation in PBRIS?
        </h5>
        <p class="m-3">
          To open a regulation for public consultation. the agency user must
          first indicate that the regulation is "For Review" (this can be seen
          on the left pane of the regulation text). Then, the agency user must
          provide the necessary consultation details such as the purpose of
          consultation, relevant references/supporting documents (if any),
          consultation schedule, etc.
        </p>

        <h5 class="fw-bold">
          How long can we keep a regulation open for public consultation?
        </h5>
        <p class="m-3">
          The duration of a public consultation is in the discretion of the
          concerned agency/ies, and can be extended as may be deemed necessary.
        </p>

        <h5 class="fw-bold">
          Can we modify the schedule of a public consultation?
        </h5>
        <!-- TODO: Implement Consultations page -->
        <p class="m-3">
          Yes. Should there be changes in the schedule of a public consultation,
          the agency user must click on "Modify Schedule" in the Consultations
          Page.
        </p>

        <h5 class="fw-bold">
          How can users be notified of consultation schedules?
        </h5>
        <p class="m-3">
          Users may choose to receive email notifications about the regulations
          they have subscribed to. Aside from this, subscribed users will also
          be able to receive in-system notifications.
        </p>

        <h5 class="fw-bold">
          Can we link and/or reflect information in PBRIS regarding public
          consultations we will conduct in different media/platform?
        </h5>
        <p class="m-3">
          Yes. Agencies may link and/or reflect in the system the information
          regarding public consultations to be conducted outside PBRIS. These
          may include links to survey forms, consultation meeting details, focus
          group discussion schedules, among others.
        </p>

        <h5 class="fw-bold">
          How can we respond to comments on regulations we have opened for
          public consultation?
        </h5>
        <p class="m-3">
          Agencies can send their reply to users’ comments real time. Only the
          issuing agency/ies can reply to these comments.
        </p>

        <h5 class="fw-bold">
          We received a comment that is deemed by our agency as
          irrelevant/unrelated to the subject regulation. What should we do?
        </h5>
        <p class="m-3">
          In such case, the agency must reply to the comment of the user and
          refer them to the proper channel to raise their concerns.
        </p>

        <h5 class="fw-bold">ARTA & ARTA-BRO</h5>
        <p class="m-3">
          The Anti-Red Tape Authority (ARTA) is the lead agency that will
          oversee the operation of the PBRIS and the implementation of the RIA
          and the regulatory management process. ARTA is the regulatory
          oversight body that shall review the Preliminary Impact Statements
          (PIS) and Regulatory Impact Statements (RIS) submitted by the
          government agencies.
        </p>

        <h5 class="fw-bold">Better Regulations Office (BRO)</h5>
        <p class="m-3">
          The Better Regulations Office Sectoral Divisions, under the Office of
          the Deputy Director General for Operations of ARTA, is the main office
          responsible for the oversight of the PBRIS, the RIA process and the
          review of the PIS and RIS. BRO shall review the regulatory proposals
          of the agencies through the submitted PIS and RIS and coordinate the
          review of existing laws, executive issuances, and local ordinances,
          and recommend the repeal of the same if deemed, outdated, redundant,
          and adds undue regulatory burden to the public.
        </p>
      </div>
    </div>
  </div>
</div>
