<app-artemis-navigation-header [currentPage]="'CharterMain'"></app-artemis-navigation-header>

<div class="content">
    <div class="container">
        <a class="btn btn-primary m-2" routerLink="/artemis/citizen-charter">Citizen's Charters</a>
        <div class="card shadow my-3">
            <div class="card-header">
                <h3 class="text-primary fw-bold">Citizen's Rights</h3>
                <i>Section 9. Section 8 of the same Act is hereby amended and renumbered as Section 9 to read as follows:</i>

            </div>
            <div class="card-body p-3 bg-light rights-container">
                <p>
                    "Sec. 9. Accessing Government Services. – The following shall adopted by all government offices and agencies:
                </p>
                <p class="fw-bold">
                    "(a) Acceptance of Applications or Requests. –
                </p>
                <p class="ms-3">
                    "(1) All officers or employees shall accept written applications, requests, and/or documents being submitted by applicants or requesting parties of the offices or agencies.
                </p>
                <p class="ms-3">
                    "(2) The receiving officer or employee shall perform a preliminary assessment of the application or request submitted with its supporting documents to ensure a more expeditious action on the application or request. The receiving officer or employee shall
                    immediately inform the applicant or requesting party of any deficiency in the accompanying requirements, which shall be limited to those enumerated in the Citizen’s Charter.
                </p>
                <p class="ms-3">
                    "(3) The receiving officer or employee shall assign a unique identification number to an application or request, which shall be the identifying number for all subsequent transactions between the government and the applicant or requesting party regarding
                    such specific application or request.
                </p>
                <p class="ms-3">
                    "(4) The receiving officer or employee shall issue an acknowledgement receipt containing the seal of the agency, the name of the responsible officer or employee, his/her unit and designation, and the date and time of receipt of such application or request.
                </p>
                <p class="fw-bold">
                    "(b) Action of Offices. –
                </p>
                <p class="ms-3">
                    "(1) All applications or requests submitted shall be acted upon by the assigned officer or employee within the prescribed processing time stated in the Citizen’s Charter which shall not be longer than three (3) working days in the case of simple transactions
                    and seven (7) working days in the case of complex transactions from the date the request and/or complete application or request was received.
                </p>
                <p class="ms-3">
                    "For applications or requests involving activities which pose danger to public health, public safety, public morals, public policy, and highly technical application, the prescribed processing time shall in no case be longer than twenty (20) working days
                    or as determined by the government agency or instrumentality concerned, whichever is shorter.
                </p>
                <p class="ms-3">
                    "The maximum time prescribed above may be extended only once for the same number of days, which shall be indicated in the Citizen’s Charter. Prior to the lapse of the processing time, the office or agency concerned shall notify the applicant or requesting
                    party in writing of the reason for the extension and final date of release of the government service/s requested. Such written notification shall be signed by the applicant or requesting party to serve as proof of notice.

                </p>
                <p class="ms-3">
                    "If the application or request for license, clearance permit, certification or authorization shall require the approval of the local Sangguniang Bayan, Sangguniang Panlungsod, or the Sangguniang Panlalawigan as the case may be, the Sanggunian concerned
                    shall be given a period of forty-five (45) working days to act on the application or request, which can be extended for another twenty (20) working days.
                </p>
                <p class="ms-3">
                    If the local Sanggunian concerned has denied the application or request, the reason for the denial, as well as the remedial measures that may be taken by the applicant shall be cited by the concerned Sanggunian.
                </p>
                <p class="ms-3">
                    "In cases where the cause of delay is due to force majeure or natural or man-made disasters, which result to damage or destruction of documents, and/or system failure of the computerized or automatic processing, the prescribed processing times mandated
                    in this Act shall be suspended and appropriate adjustments shall be made.
                </p>
                <p class="ms-3">
                    "(2) No application or request shall be returned to the applicant or requesting party without appropriate action. In case an application or request is disapproved, the officer or employee who rendered the decision shall send a formal notice to the applicant
                    or requesting party within the prescribed processing time, stating therein the reason for the disapproval. A finding by a competent authority of a violation of any or other laws by the applicant or requesting party shall constitute
                    a valid ground for the disapproval of the application or request, without prejudice to other grounds provided in this Act or other pertinent laws.
                </p>
                <p class="fw-bold">
                    "(c) Denial of Application or Request for Access to Government Service. – Any denial of application or request for access to government service shall be fully explained in writing, stating the name of the person making the denial and the grounds upon
                    which such denial is based. Any denial of application or request is deemed to have been made with the permission or clearance from the highest authority having jurisdiction over the government office or agency concerned.
                </p>
                <p class="fw-bold">
                    "(d) Limitation of Signatories – The number of signatories in any document shall be limited to a maximum of three (3) signatures which shall represent officers directly supervising the office or agency concerned: Provided, That in case the authorized
                    signatory is on official business or official leave, an alternate shall be designated as signatory. Electronic signatures or pre-signed license, clearance, permit, certification or authorization with adequate security and control mechanism
                    may be used.
                </p>
                <p class="fw-bold">
                    "(e) Electronic Versions of Licenses, Clearances, Permits, Certifications or Authorizations. – All government agencies covered under Section 3 of this Act shall, when applicable, develop electronic versions of licenses, clearances, permits, certifications
                    or authorizations with the same level of authority as that of the signed hard copy, which may be printed by the applicants or requesting parties in the convenience of their offices.
                </p>
                <p class="fw-bold">
                    "(f) Adoption of Working Schedules to Serve Applicants or Requesting Parties. – Heads of offices and agencies which render government services shall adopt appropriate working schedules to ensure that all applicants or requesting parties who are within
                    their premises prior to the end of official working hours are attended to and served even during lunch break and after regular working hours.
                </p>
                <p class="fw-bold">
                    "(g) Identification Card. – All employees transacting with the public shall be provided with an official identification card which shall be visibly worn during office hours.
                </p>
                <p class="fw-bold">
                    "(h) Establishment of Public Assistance/Complaints Desk. – Each office or agency shall establish a public assistance/complaints desk in all their offices."
                </p>

            </div>
        </div>

    </div>
</div>