import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[app-artemis-chooseany-requirement-node]',
  templateUrl: './artemis-chooseany-requirement-node.component.html',
  styleUrls: ['./artemis-chooseany-requirement-node.component.scss']
})
export class ArtemisChooseanyRequirementNodeComponent implements OnInit {

  @Input() requirement:any
  @Input() index:any

  constructor() { }

  ngOnInit(): void {
  }

}
