import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
})
export class InboxComponent implements OnInit {
  isInboxListView: boolean = true;
  isRegulationView: boolean = false;
  isExistingRegulationView: boolean = false;
  isProposedRegulationView: boolean = false;

  isParamFromInboxList: any = null;
  isDocuIDFromInboxList: any = null;

  @Input() regulationInboxCollectionName: string = '';
  @Input() regulationInboxWorklistCollectionName: string = '';
  @Input() activateChild: boolean = false;
  @Input() activateObject: any = {};

  constExistingRegulation:any = ""

  private existing_regulation_collection_name = 'regulations-existing-phase-02';
  private existing_regulation_worklist_collection_name =
    'Worklist-Existing Regulations';
  private proposed_regulation_collection_name = 'regulations-proposed-phase-02';
  private proposed_regulation_worklist_collection_name =
    'Worklist-Proposed Regulations';

  constructor(
    private router: Router,
    private afs: AngularFirestore,
    public auth: AngularFireAuth
  ) {}

  ngOnInit(): void { this.constExistingRegulation = this.existing_regulation_collection_name}

  changeInboxViewPort(param: any) {
    // this.isInboxView = true;
    // this.isRegulationView = false;
    // console.log("This Parent: " + e);
    this.isParamFromInboxList = param?.param;

    this.isDocuIDFromInboxList = param?.docuID;

    this.isInboxListView = !this.isInboxListView;
    this.isRegulationView = !this.isRegulationView;

    if (this.isRegulationView) {
      this.isExistingRegulationView =
        this.regulationInboxCollectionName ===
          this.existing_regulation_collection_name &&
        this.regulationInboxWorklistCollectionName ===
          this.existing_regulation_worklist_collection_name;
      this.isProposedRegulationView =
        this.regulationInboxCollectionName ===
          this.proposed_regulation_collection_name &&
        this.regulationInboxWorklistCollectionName ===
          this.proposed_regulation_worklist_collection_name;
    } else {
      this.isExistingRegulationView = false;
      this.isProposedRegulationView = false;
    }
    this.resetActiveValues()    
  }
  
  resetActiveValues(){
    this.activateChild = false
    this.activateObject = {}
  }
}
