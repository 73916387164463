<app-wel-navbar></app-wel-navbar>

<div class="content">
    <div class="container">

        <!-- ARTEMIS -->
        <div class="card shadow my-3">
            <div class="card-header text-primary theme-default">
                <h5>ANTI-RED TAPE AUTHORITY (ARTA)</h5>
                <h2 class="fw-bold">ABOUT US</h2>
            </div>
            <div class="card-body p-3 bg-light rights-container">
                <strong>The Anti-Red Tape Authority</strong>
                <p class="m-3">
                    Section 17 of Republic Act No. 11032 provides for the creation of the Anti-Red Tape Authority or ARTA, the government agency mandated to administer and implement the said law and its Implementing Rules and Regulations, and to monitor and ensure compliance
                    with the national policy on anti-red tape and ease of doing business in the country.
                </p>

                <strong>Our Vision</strong>
                <p class="m-3">
                    Our vision is a fair and citizen-centric Philippine government, which enables a vibrant business environment and a high-trust society.
                </p>

                <strong>Our Mission</strong>
                <p class="m-3">
                    To achieve our vision, we will transform the way the government enables its citizens and stakeholders through collaboration, technology, and good regulatory practices.
                </p>


            </div>
        </div>




    </div>
</div>