import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { GovernmentAccountSubtype, RemarkLevelCitizensCharter, WorklistStatusCitizensCharter } from 'src/app/entities/worklist';
import { GovServiceService } from 'src/app/service/gov-service.service';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';

@Component({
  selector: 'app-artemis-service-remarks-thread',
  templateUrl: './artemis-service-remarks-thread.component.html',
  styleUrls: ['./artemis-service-remarks-thread.component.scss']
})
export class ArtemisServiceRemarksThreadComponent implements OnInit {

  @Input() service_id : any
  @Input() component_id :any
  @Input() type: any
  @Input() remarks : Array<any>
  @Input() openRemarks: boolean
  @Input() service_status : WorklistStatusCitizensCharter
  

  canViewRemark:boolean =false
  someoneistyping: boolean

  position_tpye =  GovernmentAccountSubtype
  user_position:any
  remark_level = RemarkLevelCitizensCharter

  remark_form = new FormGroup({
    remark: new FormControl('')
  })
  constructor(
    private afs: AngularFirestore,
    private remarkService: GovServiceService,
    private notif: SnackbarNotifService
  ) { }

  ngOnInit(): void {

   this.user_position  = sessionStorage.getItem('account_subtype') || '';
    this.remarkService.someoneistyping_remark.subscribe(value=>{
      this.someoneistyping=value
    })

  //this.loadRemarks()
  }
  disableControl:boolean =false
  writeRemark(input:any){

    this.disableControl =true
    this.remarkService.writeComment(this.component_id,input,this.type,this.service_id).then(()=>{
      this.disableControl = false
      input.value = ""
    
    })
  }

  valuechange(event:any){
    const val:any = event.target.value
    if(val){
      this.remarkService.someoneistyping_remark.next(true)
    }else{
      this.remarkService.someoneistyping_remark.next(false)
    }
  }

  remarks_thread:any = []

 get filteredRemarks() : Array<any>{
    //console.log("Remarks",this.remarks)
    return this.remarks_thread =  this.remarks.filter((val:any) => val.id == this.component_id && val.type == this.type)

  }




}
