<!--header board for margins-->
<div class="header-board">
  <!--default: carousel-->
  <!-- Citizen's Rights Panel -->
  <div *ngIf="userType != 'arta' && userType != 'agency'" id="carouselExampleIndicators" class="carousel slide"
    data-mdb-ride="carousel">
    <div id="carouselExampleIndicators" class="border carousel slide my-3" data-mdb-ride="carousel"
      data-mdb-interval="false">
      <ol class="carousel-indicators">
        <li data-mdb-target="#carouselExampleIndicators" data-mdb-slide-to="0" class="active"></li>
        <li data-mdb-target="#carouselExampleIndicators" data-mdb-slide-to="1"></li>
        <li data-mdb-target="#carouselExampleIndicators" data-mdb-slide-to="2"></li>
        <li data-mdb-target="#carouselExampleIndicators" data-mdb-slide-to="3"></li>
        <li data-mdb-target="#carouselExampleIndicators" data-mdb-slide-to="4"></li>
        <li data-mdb-target="#carouselExampleIndicators" data-mdb-slide-to="5"></li>
        <li data-mdb-target="#carouselExampleIndicators" data-mdb-slide-to="6"></li>
      </ol>

      <div class="carousel-inner">
        <!-- 1 -->
        <div class="carousel-item active">
          <a routerLink="/artemis/citizen-charter/rights">
            <div class="d-flex flex-row">
              <div class="d-flex flex-column">
                <img src="/assets/Assets/Citizens Rights/Fair Access.jpg" class="d-block w-100" alt="..." />
              </div>
            </div>
          </a>
        </div>

        <!-- 2 -->
        <div class="carousel-item">
          <a routerLink="/artemis/citizen-charter/rights">
            <div class="d-flex flex-row">
              <div class="d-flex flex-column">
                <img src="/assets/Assets/Citizens Rights/Streamlined.jpg" class="d-block w-100" alt="..." />
              </div>
            </div>
          </a>
        </div>

        <!-- 3 -->
        <div class="carousel-item">
          <a routerLink="/artemis/citizen-charter/rights">
            <div class="d-flex flex-row">
              <div class="d-flex flex-column">
                <img src="/assets/Assets/Citizens Rights/Information.jpg" class="d-block w-100" alt="..." />
              </div>
            </div>
          </a>
        </div>

        <!-- 4 -->
        <div class="carousel-item">
          <a routerLink="/artemis/citizen-charter/rights">
            <div class="d-flex flex-row">
              <div class="d-flex flex-column">
                <img src="/assets/Assets/Citizens Rights/Issuance.jpg" class="d-block w-100" alt="..." />
              </div>
            </div>
          </a>
        </div>

        <!-- 5 -->
        <div class="carousel-item">
          <a routerLink="/artemis/citizen-charter/rights">
            <div class="d-flex flex-row">
              <div class="d-flex flex-column">
                <img src="/assets/Assets/Citizens Rights/Privacy.jpg" class="d-block w-100" alt="..." />
              </div>
            </div>
          </a>
        </div>

        <!-- 6 -->
        <div class="carousel-item">
          <a routerLink="/artemis/citizen-charter/rights">
            <div class="d-flex flex-row">
              <div class="d-flex flex-column">
                <img src="/assets/Assets/Citizens Rights/Complaint.jpg" class="id-block w-100" alt="..." />
              </div>
            </div>
          </a>
        </div>

        <!-- 7 -->
        <div class="carousel-item">
          <a routerLink="/artemis/citizen-charter/rights">
            <div class="d-flex flex-row">
              <div class="d-flex flex-column">
                <img src="/assets/Assets/Citizens Rights/No Gov.jpg" class="d-block w-100" alt="..." />
              </div>
            </div>
          </a>
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-mdb-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-mdb-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </a>
    </div>
  </div>
  <!-- Citizen's Rights Panel END -->

  <!--agency-->

  <div *ngIf="userType == 'agency'" class="container-fluid">

    <div class="container-fluid">
      <div class="row pt-4 d-flex align-items-center">
        <div class="col-sm-12">
          <div class="row">

            <div class="col-3">
              <div class="card border border-gray shadow-0 rounded-7">
                <div class="card-body ">
                  <div class="row">
                    <div class="col">
                      <span class="h2 font-weight-bold mb-0">{{agencyServicesTotal}}</span>
                      <h5 class="card-title text-uppercase text-muted mb-0"> Total Number of Posted Services</h5>
                    </div>
                    <div class="col-auto">
                      <div class="icon icon-shape bg-info text-white rounded-circle shadow">
                        <i class="fas fa-paste"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button mat-button>More Info <span><i class="fas fa-arrow-circle-right"></i></span></button>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card border border-gray shadow-0 rounded-7">
                <div class="card-body ">
                  <div class="row">
                    <div class="col">
                      <span class="h4 font-weight-bold mb-0">Citizen's Charter {{agencyCCStatus.LatestCC }} </span>
                      <h5 class="card-title text-uppercase text-muted mb-0">Status: LATEST {{agencyCCStatus.Status}} </h5>
                    </div>
                    <div class="col-auto">
                      <div class="icon icon-shape bg-info text-white rounded-circle shadow">
                        <i class="fas fa-paste"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button (click)="ViewDocument(agencyCCStatus)" mat-button>Download <span><i class="fas fa-arrow-circle-right"></i></span></button>
                </div>
              </div>
            </div>

            <div *ngIf="agencyCCStatus.edition2 && agencyCCStatus.CConReview != 'POSTED'" class="col">
              <div class="card border border-gray shadow-0 rounded-7">
                <div class="card-body ">
                  <div class="row">
                    <div class="col">
                      <span class="h4 font-weight-bold mb-0">Citizen's Charter {{agencyCCStatus.edition2}}</span>
                   
                      <h5 class="card-title text-uppercase text-muted mb-0">Status: ARTA ONGOING REVIEW</h5>
                    </div>
                    <div class="col-auto">
                      <div class="icon icon-shape bg-info text-white rounded-circle shadow">
                        <i class="fas fa-paste"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                 <!--- <button  mat-button>Ongoing ARTA Review <span><i class="fas fa-assignment-circle-right"></i></span></button>-->
                </div>
              </div>
            </div>

          </div>
        </div>


      </div>
    </div>

  </div>

  <!--counters-->
  <div *ngIf="userType == 'arta'" class="container-fluid">
    <div class="container-fluid">
      <div class="row  pt-4 d-flex align-items-center">
        <!--Count pending VS POSTED-->
        <div class="col-sm-6">
          <div class="row">
            <!--POSTED-->
            <div class="col">
              <div class="card border border-gray shadow-0 rounded-7">
                <div class="card-body ">
                  <div class="row">
                    <div class="col">
                      <span class="h2 font-weight-bold mb-0">{{postedServices}}</span>
                      <h5 class="card-title text-uppercase text-muted mb-0"> Total Posted</h5>
                    </div>
                    <div class="col-auto">
                      <div class="icon icon-shape bg-info text-white rounded-circle shadow">
                        <i class="fas fa-paste"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button mat-button>More Info <span><i class="fas fa-arrow-circle-right"></i></span></button>
                </div>
              </div>
            </div>
            <!--Pending-->
            <div class="col">
              <div class="card border border-gray shadow-0 rounded-7">
                <div class="card-body ">
                  <div class="row">
                    <div class="col">
                      <span class="h2 font-weight-bold mb-0">{{pendingServices}}</span>
                      <h5 class="card-title text-uppercase text-muted mb-0"> Total Pending</h5>
                    </div>
                    <div class="col-auto">
                      <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                        <i class="fas fa-paste"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button mat-button>More Info <span><i class="fas fa-arrow-circle-right"></i></span></button>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card border border-gray shadow-0 rounded-7">
                <div class="card-body ">
                  <div class="row">
                    <div class="col">
                      <span class="h2 font-weight-bold mb-0">{{totalAgencies}}</span>
                      <h5 class="card-title text-uppercase text-muted mb-0"> Total Number of Agencies Enrolled</h5>
                    </div>
                    <div class="col-auto">
                      <div class="icon icon-shape bg-info text-white rounded-circle shadow">
                        <i class="fas fa-landmark"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button mat-button>More Info <span><i class="fas fa-arrow-circle-right"></i></span></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6">
            <div class="row">
              <div class="col">
                <div class="card border border-gray shadow-0 rounded-7">
                  <div class="card-body ">
                    <div class="row">
                      <div class="col">
                        <span class="h2 font-weight-bold mb-0">{{postedcharters}}</span>
                        <h5 class="card-title text-uppercase text-muted mb-0"> Total Number of Posted Citizen's Charters</h5>
                      </div>
                      <div class="col-auto">
                        <div class="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i class="fas fa-file"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button mat-button>More Info <span><i class="fas fa-arrow-circle-right"></i></span></button>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card border border-gray shadow-0 rounded-7">
                  <div class="card-body ">
                    <div class="row">
                      <div class="col">
                        <span class="h2 font-weight-bold mb-0">{{unpostedcharters}}</span>
                        <h5 class="card-title text-uppercase text-muted mb-0"> Total Number of Unposted Citizen's Charters</h5>
                      </div>
                      <div class="col-auto">
                        <div class="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i class="fas fa-file"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button mat-button>More Info <span><i class="fas fa-arrow-circle-right"></i></span></button>
                  </div>
                </div>
              </div>
            </div>
          
        </div>

        <!--Count services per user-->
        <!--disabled for now unitl after UAT
        
         <div class="col-sm-6">
          <div class="row">
            
            <div class="col">
              <div class="card border border-gray shadow-0 rounded-7">
                  <div class="card-body ">
                      <div class="row">
                          <div class="col">
                              <span class="h2 font-weight-bold mb-0">100</span>
                              <h5 class="card-title text-uppercase text-muted mb-0"> Program Officer</h5>
                          </div>
                          <div class="col-auto">
                              <div class="icon icon-shape bg-info text-white rounded-circle shadow">
                                <i class="fas fa-user"></i>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="card-footer">
                      <button mat-button>More Info <span><i class="fas fa-arrow-circle-right"></i></span></button>
                  </div>
              </div>
            </div>
            
            <div class="col">
              <div class="card border border-gray shadow-0 rounded-7">
                  <div class="card-body ">
                      <div class="row">
                          <div class="col">
                              <span class="h2 font-weight-bold mb-0">100</span>
                              <h5 class="card-title text-uppercase text-muted mb-0"> Division Chief</h5>
                          </div>
                          <div class="col-auto">
                              <div class="icon icon-shape bg-warning text-white rounded-circle shadow">
                                <i class="fas fa-user"></i>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="card-footer">
                      <button mat-button>More Info <span><i class="fas fa-arrow-circle-right"></i></span></button>
                  </div>
              </div>
            </div>
            
            <div class="col">
              <div class="card border border-gray shadow-0 rounded-7">
                  <div class="card-body ">
                      <div class="row">
                          <div class="col">
                              <span class="h2 font-weight-bold mb-0">100</span>
                              <h5 class="card-title text-uppercase text-muted mb-0"> Director</h5>
                          </div>
                          <div class="col-auto">
                              <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                                <i class="fas fa-user"></i>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="card-footer">
                      <button mat-button>More Info <span><i class="fas fa-arrow-circle-right"></i></span></button>
                  </div>
              </div>
            </div>
          </div>
        </div>   
        
        -->
      </div>

      <h5 class="py-2 text-muted">Agencies</h5>
      <div class="row ">
        <!--Count Agencies-->
        <div class="col-sm-12">
          <div class="row">
            <!--NGA-->
            <div class="col">
              <div class="card border border-gray shadow-0 rounded-7">
                <div class="card-body ">
                  <div class="row">
                    <div class="col">
                      <span class="h2 font-weight-bold mb-0">{{nga}}</span>
                      <h5 class="card-title text-uppercase text-muted mb-0"> NGA</h5>
                    </div>
                    <div class="col-auto">
                      <div class="icons icon-shape text-primary">
                        <i class="fas fa-landmark"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button mat-button>More Info <span><i class="fas fa-arrow-circle-right"></i></span></button>
                </div>
              </div>
            </div>
            <!--LGU-->
            <div class="col">
              <div class="card border border-gray shadow-0 rounded-7">
                <div class="card-body ">
                  <div class="row">
                    <div class="col">
                      <span class="h2 font-weight-bold mb-0">{{lgu}}</span>
                      <h5 class="card-title text-uppercase text-muted mb-0"> LGU</h5>
                    </div>
                    <div class="col-auto">
                      <div class="icons icon-shape text-primary">
                        <i class="fas fa-landmark"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button mat-button>More Info <span><i class="fas fa-arrow-circle-right"></i></span></button>
                </div>
              </div>
            </div>
            <!--GOCC-->
            <div class="col">
              <div class="card border border-gray shadow-0 rounded-7">
                <div class="card-body ">
                  <div class="row">
                    <div class="col">
                      <span class="h2 font-weight-bold mb-0">{{gocc}}</span>
                      <h5 class="card-title text-uppercase text-muted mb-0"> GOCC</h5>
                    </div>
                    <div class="col-auto">
                      <div class="icons icon-shape text-primary">
                        <i class="fas fa-landmark"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button mat-button>More Info <span><i class="fas fa-arrow-circle-right"></i></span></button>
                </div>
              </div>
            </div>
            <!--HOSPITAL-->
            <div class="col">
              <div class="card border border-gray shadow-0 rounded-7">
                <div class="card-body ">
                  <div class="row">
                    <div class="col">
                      <span class="h2 font-weight-bold mb-0">{{hosp}}</span>
                      <h5 class="card-title text-uppercase text-muted mb-0"> HOSPITALS</h5>
                    </div>
                    <div class="col-auto">
                      <div class="icons icon-shape text-primary">
                        <i class="fas fa-landmark"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button mat-button>More Info <span><i class="fas fa-arrow-circle-right"></i></span></button>
                </div>
              </div>
            </div>

            <!--SUC-->
            <div class="col">
              <div class="card border border-gray shadow-0 rounded-7">
                <div class="card-body ">
                  <div class="row">
                    <div class="col">
                      <span class="h2 font-weight-bold mb-0">{{suc}}</span>
                      <h5 class="card-title text-uppercase text-muted mb-0"> SUC</h5>
                    </div>
                    <div class="col-auto">
                      <div class="icons icon-shape text-primary">
                        <i class="fas fa-landmark"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button mat-button>More Info <span><i class="fas fa-arrow-circle-right"></i></span></button>
                </div>
              </div>
            </div>

            <!--WD-->
            <div class="col">
              <div class="card border border-gray shadow-0 rounded-7">
                <div class="card-body ">
                  <div class="row">
                    <div class="col">
                      <span class="h2 font-weight-bold mb-0">{{lwd}}</span>
                      <h5 class="card-title text-uppercase text-muted mb-0">LWD</h5>
                    </div>
                    <div class="col-auto">
                      <div class="icons icon-shape text-primary">
                        <i class="fas fa-landmark"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button mat-button>More Info <span><i class="fas fa-arrow-circle-right"></i></span></button>
                </div>
              </div>
            </div>

             <!--CONCOM-->
           <div class="col">
            <div class="card border border-gray shadow-0 rounded-7 units">
              <div class="card-body ">
                <div class="row">
                  <div class="col">
                    <span class="h2 font-weight-bold mb-0">{{concom}}</span>
                    <h5 class="card-title text-uppercase text-muted mb-0"> CONCOM</h5>
                  </div>
                  <div class="col-auto">
                    <div class="icons icon-shape text-primary">
                      <i class="fas fa-landmark"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button mat-button>More Info <span><i class="fas fa-arrow-circle-right"></i></span></button>
              </div>
            </div>
          </div>
          </div>

        </div>

       
        <!--Count per classification-->
        <!---
      <div class="col-sm-6">
      -Count services per classification
        
        <div class="row">
       SIMPLE 
          <div class="col">
            <div class="card border border-gray shadow-0 rounded-7">
                <div class="card-body ">
                    <div class="row">
                        <div class="col">
                            <span class="h2 font-weight-bold mb-0">9</span>
                            <h5 class="card-title text-uppercase text-muted mb-0"> Simple Services</h5>
                        </div>
                        <div class="col-auto">
                          <div class="icons icon-shape text-success">
                              <i class="fas fa-cogs"></i>
                          </div>
                      </div>
                    </div>
                </div>
                <div class="card-footer">
                    <button mat-button>More Info <span><i class="fas fa-arrow-circle-right"></i></span></button>
                </div>
            </div>
          </div>
           COMPLEX 
          <div class="col">
              <div class="card border border-gray shadow-0 rounded-7">
                  <div class="card-body ">
                      <div class="row">
                          <div class="col">
                              <span class="h2 font-weight-bold mb-0">94</span>
                              <h5 class="card-title text-uppercase text-muted mb-0"> Complex Services</h5>
                          </div>
                          <div class="col-auto">
                            <div class="icons icon-shape text-info">
                                <i class="fas fa-cogs"></i>
                            </div>
                        </div>
                      </div>
                  </div>
                  <div class="card-footer">
                      <button mat-button>More Info <span><i class="fas fa-arrow-circle-right"></i></span></button>
                  </div>
              </div>
          </div>
        </div>
        <div class="row pt-2">
          HIGHLY TECHNICAL 
          <div class="col">
          <div class="card border border-gray shadow-0 rounded-7">
              <div class="card-body ">
                  <div class="row">
                      <div class="col">
                          <span class="h2 font-weight-bold mb-0">24</span>
                          <h5 class="card-title text-uppercase text-muted mb-0"> Highly Technical  Services</h5>
                      </div>
                      <div class="col-auto">
                        <div class="icons icon-shape text-warning">
                            <i class="fas fa-cogs"></i>
                        </div>
                    </div>
                  </div>
              </div>
              <div class="card-footer">
                  <button mat-button>More Info <span><i class="fas fa-arrow-circle-right"></i></span></button>
              </div>
          </div>
          </div>
         HIGHLY TECHNICAL > 20 
          <div class="col">
            <div class="card border border-gray shadow-0 rounded-7">
                <div class="card-body ">
                    <div class="row">
                        <div class="col">
                            <span class="h2 font-weight-bold mb-0">2</span>
                            <h5 class="card-title text-uppercase text-muted mb-0"> Highly Technical  > 20 days Services</h5>
                        </div>
                        <div class="col-auto">
                          <div class="icons icon-shape text-danger">
                              <i class="fas fa-cogs"></i>
                          </div>
                      </div>
                    </div>
                </div>
                <div class="card-footer">
                    <button mat-button>More Info <span><i class="fas fa-arrow-circle-right"></i></span></button>
                </div>
            </div>
          </div>
        </div>
        <div class="row pt-4">
          
        </div>
        
     
    </div>-->
      </div>


      <!--
    <div class="row shadow">
    
      <div class="theme-{{ userType }} col-2 banner-title">
        <p>Quick</p>
        <p>Count</p>
      </div>
      <div class="col-1"></div>
      
      <div class="col pt-4" style="line-height: 0.9">
        <h5 *ngIf="userType == 'agency'">Total Submissions</h5>
        <i *ngIf="userType == 'agency'" class="far fa-file-alt fa-5x"></i>
        <h5 *ngIf="userType == 'arta'">Total Compliant</h5>
        <i *ngIf="userType == 'arta'" class="far fa-check-square fa-5x"></i>
        <div class="spinner-grow text-info" role="status" [hidden]="counterCount1">
          <span class="visually-hidden">Loading...</span>
        </div>
        <span class="banner-count">{{ counterCount1 | number: "1.0" }}</span>
      </div>
      <div class="col pt-4" style="line-height: 0.9">
        <h5 *ngIf="userType == 'agency'">Count of Services</h5>
        <i *ngIf="userType == 'agency'" class="fas fa-user-tie fa-5x"></i>
        <h5 *ngIf="userType == 'arta'">Total Pending</h5>
        <i *ngIf="userType == 'arta'" class="far fa-list-alt fa-5x"></i>
        <div class="spinner-grow text-info" role="status" [hidden]="counterCount2">
          <span class="visually-hidden">Loading...</span>
        </div>
        <span class="banner-count">{{ counterCount2 | number: "1.0" }}</span>
      </div>
      
    </div>end-->
    </div>
  </div>