import { AngularFireAuth } from '@angular/fire/auth';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { ARTEMIS, PBRIS } from '../entities/constant';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { AgencyAddComponent } from './agency-add/agency-add.component';


@Component({
  selector: 'app-agency-control',
  templateUrl: './agency-control.component.html',
  styleUrls: ['./agency-control.component.scss']
})
export class AgencyControlComponent implements OnInit {
  pbris = PBRIS
  artemis = ARTEMIS
  currentSystem:any = sessionStorage.getItem('currentSystem');

  loader: boolean = false;
  agencies: any[] = []

  isAgency: boolean = false;
  isAdmin: boolean = true;

  userType: any = sessionStorage.getItem("user_type");

  constructor(
    private afs: AngularFirestore,
    private router: Router,
    public auth: AngularFireAuth,
    public dialog: MatDialog,
    ) { }

  ngOnInit(): void {
    this.checkUser();

    this.loadAgencies()
  }

  loadAgencies(){
    this.afs.collection(`Agency`).snapshotChanges().subscribe(
      (data: any) => {
        this.loader = false;
        this.agencies = []
          data.forEach((info: any) => {  
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.agencies.includes(item) === false){
              this.agencies.push(item);
            }     
          });
      });

    
  }

  async checkUser(){
    if(await this.auth.user){
      if(sessionStorage.getItem("user_type") === "arta") {
        
      }
      else if ( sessionStorage.getItem("user_type") === "agency"){
        // this.afs.collection('Users').doc()
        this.auth.onAuthStateChanged((user:any)=>{
          if(user.uid){
            this.afs.collection('Users').doc(user.uid).snapshotChanges()
            .subscribe(data=>{
              const item:any = data.payload.data()
              this.router.navigate(['/agency/view/',item.government.agency])
            })
          }
        })
      }
      else{
        this.toHome();
      }
    }
  }

  openAddAgency(){
    const dialogRef = this.dialog.open(AgencyAddComponent,{
      disableClose: true,
      maxWidth: "87vw",
      width: "87vw",
      height: "85vh",
    })
  }

  toHome(){
    this.router.navigate(['/'])
  }

  goToAgencyMembers(id:any){
    this.router.navigate(["/agency/view/"+id+"/members"])
  }
}
