import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-update-regulations',
  templateUrl: './update-regulations.component.html',
  styleUrls: ['./update-regulations.component.scss']
})
export class UpdateRegulationsComponent implements OnInit {
  id = this.route.snapshot.params.id
  regulation: any = "";
  loading: boolean = false;

  // phase 1 - sample form
  phase1form = new FormGroup({ //date
     doc_date: new FormControl('', Validators.required),
     file_file_name: new FormControl('', Validators.required),
     firebase_folder: new FormControl('regulations20210505', Validators.required),
     folder_name_id: new FormControl(''),
     issuance_no: new FormControl(''),
     issuance_type: new FormControl(''),
     subject_full_title: new FormControl('', Validators.required),
     short_title: new FormControl('', Validators.required),
     sector: new FormControl(''),
   });
   phase_01_filepath: any;


  constructor(
    private afs: AngularFirestore, 
    private router: Router, 
    private route: ActivatedRoute,
    public auth: AngularFireAuth) { }

  ngOnInit(): void {
    this.loadDefault(this.id)
  }

  loadDefault(id: any){

    this.afs.doc(`PBRIS 1 Regulations/${id}`).get().subscribe(
      (data: any) => {
        this.regulation = data;

        let path = data.get('firebase_folder') + '/' + data.get('file_file_name');

        this.phase1form.patchValue({
          doc_date: data.get('doc_date'),
          folder_name_id: data.get('folder_name_id'),
          file_file_name: data.get('file_file_name'),
          firebase_folder: data.get('firebase_folder'),
          issuance_no:data.get('issuance_no'),
          issuance_type: data.get('issuance_type'),
          subject_full_title: data.get('subject_full_title'),
          short_title: data.get('short_title'),
          sector: data.get('sector'),
        })
      });
  }

  async saveAndUpload() {
    this.loading = true;
    let documentID: any;
    
    this.afs.doc(`PBRIS 1 Regulations/${this.route.snapshot.params.id}`).update(this.phase1form.value)
      .then((docRef:any) => {

        this.toNotif()

        alert("Regulation has been updated");        
          
        this.router.navigate(['/pbris/regulations/view/'+ this.id]);
        this.loading = false
    }).catch(error =>{
      this.loading = false
      alert(error.message)
    });
    
  }
  async toNotif(){
    const user = await this.auth.currentUser;

    const newNotif = await this.afs.collection('Notifications').add({
      uid:user?.uid,
      agency: null,
      event: 'Regulation Updated',
      message: 'This is a notification Message',
      level: 'This is the Level',
      notif_type: 'Regulatory-Review',
      notif_in:'pbris'
    })
    
    this.getUserAgency(user?.uid,newNotif.id)
  }

  async getUserAgency(uid:any,notif_id:any ){
    this.afs.collection('Users').doc(uid)
    .snapshotChanges()
    .subscribe(
    (data:any) =>{
      let item = data.payload.data()      
      item.user_agency = item.government.agency 
      console.log("agency_name: ", item.user_agency)

      this.afs.collection('Notifications').doc(notif_id).update({
        agency:item.user_agency
      })
    })
  }
}

