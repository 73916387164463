<div *ngIf="isLoaded">
    <div class="row" *ngFor="let row of headers">
        <h2 class="mr-1 fw-bold report-title theme-default-text sm-text">{{row.name}}</h2>    
        
        <div class="row staffs-container justify-content-evenly" *ngFor="let list of agency_details.agencyCommittee[row.value]">
            <div class="col-sm-12 col-md-8 col-lg-5 card border-primary mb-2 ">
               <div class="card-body">
                    <div class="row mb-1 align-items-center">
                        <span class="col-lg-2 mr-1 staff-icon">{{getAcronym(list.name)}} </span>
                        <p class="col-lg-10 wrapper"><strong>{{list.name}}</strong></p>
                    </div>
                    
                    <div class="row mb-1 align-items-center">
                        <strong class="right-space col-lg-1 "><i class="fas fa-id-badge"></i></strong>
                        <p class="wrapper col-lg-11"><i>{{list.designation}}</i></p>
                    </div>
                    
                    <div class="row mb-1 align-items-center">
                        <strong class="right-space col-lg-1 "><i class="fas fa-envelope"></i></strong>
                        <p class="wrapper col-lg-11"><strong>{{list.email}}</strong></p>
                    </div>
               </div>
            </div>
        </div>
    </div>
</div>
