import { Component, Input, OnInit } from '@angular/core';
import { GovServiceService } from 'src/app/service/gov-service.service';
import { RequriementsService } from 'src/app/service/requriements.service';

@Component({
  selector: 'app-artemis-service-scrollspy',
  templateUrl: './artemis-service-scrollspy.component.html',
  styleUrls: ['./artemis-service-scrollspy.component.scss']
})
export class ArtemisServiceScrollspyComponent implements OnInit {

  
  
  scrollService: GovServiceService

  @Input() services: any

  constructor(private govservice:GovServiceService,public requirementservice: RequriementsService) {
    this.scrollService =this.govservice
   }

  ngOnInit(): void {
  }

}
