<mat-dialog-content>
  <div>
    <div class="m-4 row">
      <div class="col form-header">
        <h4>Disapproval - CC</h4>
      </div>
    </div>
    <div class="m-4 row">
      <b>
        Please provide your instructions/remarks on revising the services
        contained in the Citizen's Charter.
      </b>
    </div>
    <div class="m-4 row">
      <mat-form-field style="width: 100%" hideRequiredMarker floatLabel="never">
        <textarea
          matInput
          placeholder="Input"
          type="text"
          class="reason-textarea"
          [formControl]="reason_for_disapproval"
        ></textarea>
      </mat-form-field>
    </div>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-5">
        <button
          (click)="submit()"
          class="btn btn-warning m-2 btn-min_width"
          [disabled]="!reason_for_disapproval.valid"
        >
          Confirm Disapproval
        </button>
      </div>
      <div class="col-3">
        <button (click)="cancel()" class="btn btn-dark border m-2">
          Cancel
        </button>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</mat-dialog-content>
