<div class="content">
    <div class="container-fluid p-4">
        <div class="mb-3 row justify-content-between form-group">
            <div  class="col-xs-3 col-sm-auto ">
                <button *ngIf="isAdmin" class="btn btn-sm btn-primary" (click)="btnAction('create',currentRef, '')">
                    <ng-container *ngIf="activated == 'agency'; else elsePlus">
                        <span>Add</span>
                    </ng-container>
                    <ng-template #elsePlus>
                        <span>create</span>
                    </ng-template>
                    {{currentRef}}</button>
            </div>
            <div class="col-lg-5 col-sm-8 d-flex align-items-center">
                <span style="margin:0.5em;">Search</span>
                <ng-container [formGroup]="searchForm">
                    <input class="form-control" (keyup)="search()" formControlName="search" type="text">
                </ng-container>
            </div>
        </div>
        <div class="mb-3 row">
            <table mat-table 
                class="table table-hover table-responsive"
                id="matTable"
                [dataSource]="refDataSource"
                matSort
                (matSortChange)="sortChange($event)"
                >

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let ref">
                        <div *ngIf="isAdmin" class="d-flex align-items-center justify-content-evenly" style="padding:0.3em;">
                            <ng-container *ngIf="activated != 'agency'">
                                <button class="menuBtn" mat-icon-button
                                    [matMenuTriggerFor]="menu"
                                    [matMenuTriggerData]="{ref: ref}"
                                    #menuTrigger>
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                            </ng-container>

                            <ng-container *ngIf="activated == 'agency'">
                                <button class="agencyBtn" mat-mini-fab color="primary" (click)="goToAgencyMembersList(ref.id)" title="Go to Agency Members List">
                                    <mat-icon>list</mat-icon>
                                </button>
                                <button class="agencyBtn" mat-mini-fab color="primary" style="overflow:hidden" (click)="goToAgencyPage(ref.id)" title="Go to Agency Page">
                                    <ng-container *ngIf="(ref.agencyDetails && ref.agencyDetails.agencyAbbreviation);else noAbbrv">
                                        {{ref.agencyDetails.agencyAbbreviation}}
                                    </ng-container>
                                    <ng-template #noAbbrv>
                                        <mat-icon>arrow_forward_ios</mat-icon>
                                    </ng-template>
                                    <!-- <mat-icon>arrow_forward_ios</mat-icon> -->
                                </button>
                            </ng-container>
                        </div>
                    </td>
                </ng-container>

                <!-- Agency -->
                <ng-container *ngIf="activated == 'agency'" matColumnDef="id" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Agency ID</h5>  </th>
                    <td mat-cell *matCellDef="let entry">
                        <div class="d-flex align-items-center" style="padding:0.3em;">
                            <p style="text-align: justify;">{{entry.id}}</p>
                        </div>
                    </td>
                </ng-container>

                <!-- Agency -->
                <ng-container *ngIf="activated == 'agency'" matColumnDef="agencyabbrv" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Agency Abbreviation</h5>  </th>
                    <td mat-cell *matCellDef="let entry">
                        <div class="d-flex align-items-center" style="padding:0.3em;">
                            <p style="text-align: justify;">{{entry.agencyDetails ? entry.agencyDetails.agencyAbbreviation ? entry.agencyDetails.agencyAbbreviation : entry.abbreviation : entry.abbreviation}}</p>
                        </div>
                    </td>
                </ng-container>
                
                <ng-container  *ngIf="activated == 'agency'" matColumnDef="agencyname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Agency Name</h5>  </th>
                    <td mat-cell *matCellDef="let entry">
                        <div class="d-flex align-items-center" style="padding:0.3em;">
                            <p style="text-align: justify;">{{entry.agencyDetails ? entry.agencyDetails.agencyName ? entry.agencyDetails.agencyName : entry.name : entry.name}}</p>
                        </div>
                    </td>
                </ng-container>
                
                <ng-container  *ngIf="activated == 'agency'" matColumnDef="agencywebsite">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Website</h5>  </th>
                    <td mat-cell *matCellDef="let entry">
                        <div class="d-flex align-items-center" style="padding:0.3em;">
                            <!-- <a href="{{entry.agencyDetails ? entry.agencyDetails.website ? entry.agencyDetails.website : '' : ''}}" target="_blank">Link</a> -->
                            <ng-container *ngIf="entry.agencyDetails && (entry.agencyDetails.website != null && entry.agencyDetails.website != undefined && entry.agencyDetails.website != '');">
                                <a href="{{entry.agencyDetails.website}}" target="_blank">{{entry.agencyDetails.website}}</a>
                            </ng-container>
                            <ng-template #noWebsite>
                                N/A 
                            </ng-template>
                        </div>
                    </td>
                </ng-container>
                <!-- Mother Agency-->
                <ng-container *ngIf="activated == 'motheragency'" matColumnDef="motheragency" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Mother Agency</h5>  </th>
                    <td mat-cell *matCellDef="let entry">
                        <div class="d-flex align-items-center" style="padding:0.3em;">
                            <p style="text-align: justify;">{{entry.motheragency}}</p>
                        </div>
                    </td>
                </ng-container>

                <!-- Agency Category -->
                <ng-container *ngIf="activated == 'agencycat'" matColumnDef="agencycatabbrv" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Category Abbreviation</h5>  </th>
                    <td mat-cell *matCellDef="let entry">
                        <div class="d-flex align-items-center" style="padding:0.3em;">
                            <p style="text-align: justify;">{{entry.abbreviation}}</p>
                        </div>
                    </td>
                </ng-container>

                <ng-container *ngIf="activated == 'agencycat'" matColumnDef="agencycatname" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Category</h5>  </th>
                    <td mat-cell *matCellDef="let entry">
                        <div class="d-flex align-items-center" style="padding:0.3em;">
                            <p style="text-align: justify;">{{entry.name}}</p>
                        </div>
                    </td>
                </ng-container>

                
                <!-- Classification -->
                <ng-container *ngIf="activated == 'class'" matColumnDef="classsection" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Section</h5>  </th>
                    <td mat-cell *matCellDef="let entry">
                        <div class="d-flex align-items-center" style="padding:0.3em;">
                            <p style="text-align: justify;">{{entry.section}}</p>
                        </div>
                    </td>
                </ng-container>

                <ng-container *ngIf="activated == 'class'" matColumnDef="classname" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Classification Name</h5>  </th>
                    <td mat-cell *matCellDef="let entry">
                        <div class="d-flex align-items-center" style="padding:0.3em;">
                            <p style="text-align: justify;">{{entry.name}}</p>
                        </div>
                    </td>
                </ng-container>


                <!-- List of Services -->


                <!-- Service Category -->
                <ng-container *ngIf="activated == 'servcat'" matColumnDef="servcatsection" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Section</h5>  </th>
                    <td mat-cell *matCellDef="let entry">
                        <div class="d-flex align-items-center" style="padding:0.3em;">
                            <p style="text-align: justify;">{{entry.section}}</p>
                        </div>
                    </td>
                </ng-container>

                <ng-container *ngIf="activated == 'servcat'" matColumnDef="servcatname" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Category</h5>  </th>
                    <td mat-cell *matCellDef="let entry">
                        <div class="d-flex align-items-center" style="padding:0.3em;">
                            <p style="text-align: justify;">{{entry.name}}</p>
                        </div>
                    </td>
                </ng-container>

                <!-- Stage of Life -->
                <ng-container *ngIf="activated == 'sol'" matColumnDef="solstage" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Stage</h5>  </th>
                    <td mat-cell *matCellDef="let entry">
                        <div class="d-flex align-items-center" style="padding:0.3em;">
                            <p style="text-align: justify;">{{entry.stage}}</p>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="refDataSourceCol; sticky: true"></tr>
                <tr mat-row matRipple *matRowDef="let row; columns: refDataSourceCol;"></tr>

            </table>
            <mat-paginator pageSize="15" aria-label="Select page of users"></mat-paginator>
        </div>
    </div>
</div>
<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-ref="ref">
      
        <button class="menuitem" mat-menu-item (click)="btnAction('view',currentRef,ref.id)">View</button>
        <button class="menuitem" mat-menu-item (click)="btnAction('edit',currentRef,ref.id)">Update</button>
        <button class="menuitem" mat-menu-item (click)="btnAction('delete',currentRef,ref.id )">Delete</button>
    </ng-template>
</mat-menu>