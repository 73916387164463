import { formatDate } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl, FormGroup } from '@angular/forms';
import { RegulationCommentStatus } from 'src/app/entities/worklist';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pbris-account-comment',
  templateUrl: './pbris-account-comment.component.html',
  styleUrls: ['./pbris-account-comment.component.scss']
})
export class PbrisAccountCommentComponent implements OnInit {
  @Input() data_catch: any;

  comment_list: any = []
  files: any = [];

  url: any;
  user: any = [];
  comment: any;
  document: any;
  type: any;
  id: any;
  replied: any;

  reply: any;

  loading: boolean = false;
  fetching: boolean = false;

  form = new FormGroup({
    reply: new FormControl(''),
  });

  constructor(public auth: AngularFireAuth, 
              private afs: AngularFirestore,
              private store: AngularFireStorage) { }

  ngOnInit(): void {
    this.getComments(this.data_catch.credentials?.uid)
  }


  async getComments(id: any) {
    this.afs.collection('Comment PBRIS', ref => ref.where('user.user_id', '==', id))
      .get()
      .subscribe(data => {
        this.comment_list = data.docs.map(res => {
          return { 
              id: res.id ,
              data: res.data()
            }
        })
      })
  }

  getFiles(data: any): void {
    for (let i = 0; i < data.documents.length; i++) {
      this.store.ref(`${data.path}${data.documents[i].name}`)
        .getDownloadURL()
        .subscribe((url: string) => {
          this.files.push({name: data.documents[i].name ,url: url})
      }, err => { console.log("Error",err) });
    }
    this.fetching = false;
  }

  openModal(data: any) {
    this.files = [];
    this.url = data.data.comments.url;
    this.user = data.data.user
    this.comment = data.data.comments.comment;
    this.type = data.data.type
    this.reply = data.data.reply;
    this.id = data.id;
    this.fetching = true;
    if (data.data.comments.path && data.data.comments.document) {
      this.getFiles({ 
        path: data.data.comments.path, 
        documents: data.data.comments.document
      });
    } else {
      this.fetching = false;
      this.files = [];
    }
  }

  submit(data: any) {
    this.loading = true;
    Swal.fire({
      title: 'Processing',
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
  
        this.afs.collection('Comment PBRIS')
          .doc(data)
          .update({
            reply: this.form.value.reply,
            status: RegulationCommentStatus.RESOLVED,
            resolved_at: formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0800')
          })
          .then((docRef:any) => {
            this.loading = false;
            this.ngOnInit()
            Swal.close();
            Swal.fire({
              title:'Successfully processed',
              icon: 'info'
            });
            this.resetForm();
        }).catch((error:any) =>{
          this.loading = false;
          console.log("Error",error)
          Swal.close();
          Swal.fire({
            title:'System error',
            titleText: 'Please refresh the page',
            icon: 'error'
          });
        });
      }
    })
  }

  closeModal() {
    this.files = [];
  }

  resetForm() {
    this.form.reset();
  }
}
