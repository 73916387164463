<!--NAVBAR-->
<app-artemis-navigation-header [currentPage]="'Charter'"></app-artemis-navigation-header>

<div *ngIf="isAgency || isAdmin" class="container-fluid">
    <div class="card">
        <div class="card-head">
            <h3>Add Phase 1 ARTEMIS Service</h3>
        </div>
        <div class="card-body form-container">
            <div class="row">
                <div class="btn-group">
                    <button class="btn {{phase1ServicePageStep == 'general' ? 'active' : ''}}" (click)="changeServicePage('general')">Step 1: General Information</button>
                    <button class="btn {{phase1ServicePageStep == 'requirements' ? 'active' : ''}}" (click)="changeServicePage('requirements')">Step 2: Requirements</button>
                    <button class="btn {{phase1ServicePageStep == 'clientsteps' ? 'active' : ''}}" (click)="changeServicePage('clientsteps')">Step 3: Client Steps</button>
                    <button class="btn {{phase1ServicePageStep == 'processflow' ? 'active' : ''}}" (click)="changeServicePage('processflow')">Step 4: Process Flow</button>
                </div>
            </div>
            <div class="row">
                <!--pageinfo-->
                <div class="col" *ngIf="phase1ServicePageStep == 'general'">
                    <div class="card">
                        <div class="card-head"><h5>Static Page Information</h5></div>
                        <div class="card-body">
                            <form [formGroup]="phase1ServicePageInfoForm">
                                <div class="form-group">
                                    <label class="form-input-header" for="name">Service Name</label>
                                    <input type="text" class="form-control" formControlName="name" />
                                </div>
                                <div class="form-group">
                                    <label class="form-input-header" for="agency">Agency</label>
                                    <input type="text" class="form-control" formControlName="agency" />
                                </div>
                                <div class="form-group">
                                    <label class="form-input-header" for="agencycategory">Agency Category</label>
                                    <input type="text" class="form-control" formControlName="agencycategory" />
                                </div>
                                <div class="form-group">
                                    <label class="form-input-header" for="classification">Classification</label>
                                    <input type="text" class="form-control" formControlName="classification" />
                                </div>
                                <div class="form-group">
                                    <label class="form-input-header" for="date">date</label>
                                    <input type="date" class="form-control" formControlName="date" />
                                </div>
                                <div class="form-group">
                                    <label class="form-input-header" for="sector">sector</label>
                                    <input type="text" class="form-control" formControlName="sector" />
                                </div>
                                <div class="form-group">
                                    <label class="form-input-header" for="servicecategory">servicecategory</label>
                                    <input type="text" class="form-control" formControlName="servicecategory" />
                                </div>
                                <div class="form-group">
                                    <label class="form-input-header" for="servicebackground">Service Background</label>
                                    <textarea class="form-control" formControlName="servicebackground"></textarea>
                                </div>
                            </form>
                        </div>
                        <button (click)="changeServicePage('requirements')" class="btn">Next Step: Requirements</button>
                    </div>
                </div>
                <!--requirements-->
                <div class="col" *ngIf="phase1ServicePageStep == 'requirements'">
                    <div class="card">
                        <div class="card-head"><h5>Requirements</h5></div>
                        <div class="card-body">
                            <form *ngFor="let form of phase1ServiceRequirementsForm" [formGroup]="form">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="form-group">
                                            <label class="form-input-header" for="description">description</label>
                                            <input type="text" class="form-control" formControlName="description" />
                                        </div>
                                        <div class="form-group">
                                            <label class="form-input-header" for="material">material</label>
                                            <input type="text" class="form-control" formControlName="material" />
                                        </div>
                                        <div class="form-group">
                                            <label class="form-input-header" for="wheretosecure">wheretosecure</label>
                                            <input type="text" class="form-control" formControlName="wheretosecure" />
                                        </div>
                                        <div class="form-group">
                                            <br>
                                            <button class="btn btn btn-outline-primary" (click)="addServiceRequirementsForm()" id="bt ">Add Another Requirement</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <button (click)="changeServicePage('clientsteps')" class="btn">Next Step: Client Steps</button>
                    </div>
                </div>
                <!--clientstep-->
                <div class="col" *ngIf="phase1ServicePageStep == 'clientsteps'">
                    <div class="card">
                        <div class="card-head"><h5>Client Steps</h5></div>
                        <div class="card-body">
                            <form *ngFor="let form of phase1ServiceStepsForm" [formGroup]="form.staticform">
                                <div class="card">
                                    <div class="card-body row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label class="form-input-header" for="step">Step</label>
                                                <input type="text" class="form-control" formControlName="step" />
                                            </div>
                                            <div class="form-group">
                                                <label class="form-input-header" for="description">description</label>
                                                <input type="text" class="form-control" formControlName="description" />
                                            </div>
                                            <div class="form-group">
                                                <label class="form-input-header" for="fees">fees</label>
                                                <input type="text" class="form-control" formControlName="fees" />
                                            </div>
                                        </div>
                                        <div class="col">
                                            <form *ngFor="let subform of form.agencyaction" [formGroup]="subform">
                                                <div class="card">
                                                    <div class="card-head"><h5>Agency Step</h5></div>
                                                    <div class="card-body">
                                                        <div class="form-group">
                                                            <label class="form-input-header" for="description">Description</label>
                                                            <input type="text" class="form-control" formControlName="description" />
                                                        </div>
                                                        <div class="form-group">
                                                            <label class="form-input-header" for="subdescription">personresponsible: description</label>
                                                            <input type="text" class="form-control" formControlName="subdescription" />
                                                        </div>
                                                        <div class="form-group">
                                                            <label class="form-input-header" for="subname">personresponsible: name</label>
                                                            <input type="text" class="form-control" formControlName="subname" />
                                                        </div>
                                                        <div class="form-group w-100">
                                                            <label class="form-input-header" for="processingtime">Processing Time</label>
                                                            <input type="text" class="form-control" formControlName="processingtime" />
                                                        </div>
                                                        <div class="form-group">
                                                            <br>
                                                            <button class="btn btn btn-outline-primary w-100" (click)="addServiceAgencyActionForm(form.agencyaction)" id="bt ">Add Action Step</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <button class="btn btn btn-outline-primary" (click)="addServiceStepsForm()" id="bt ">Add New Step</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-outline-dark" (click)="returnRedirect()" id="bt ">Cancel</button>
                        <button class="btn btn-outline-dark" (click)="uploadService('draft')" id="bt ">Save</button>
                        <button *ngIf="isAdmin" class="btn btn-outline-dark" (click)="uploadService('for revision')" id="bt ">For Revision</button>
                        <button *ngIf="isAdmin" class="btn btn-outline-dark" (click)="uploadService('for agency review')" id="bt ">Open For Review</button>
                        <button *ngIf="isAdmin && currentStatus == 'draft' " class="btn btn-warning" (click)="uploadService('for verification')" id="bt ">Submit for Verification</button>
                        <button *ngIf="isAdmin" class="btn btn-danger" (click)="uploadService('posted')" id="bt ">Submit Existing Service for Phase 1</button>
                    </div>
                </div>
                <!--processflow-->
                <div class="col" *ngIf="phase1ServicePageStep == 'processflow'">
                    <div class="card">
                        <div class="card-head"><h5>Process Flow</h5></div>
                        <div class="card-body">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>