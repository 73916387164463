import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class WorklistService {
  constructor(public auth: AngularFireAuth) {}

  async updateWorklistStatusObj(status: string) {
    const user = await this.auth.currentUser;
    let fromOfficer = user?.displayName;

    let retObj: Record<string, any>;
    retObj = {
      status: status,
      fromOfficer: fromOfficer,
      latestUser: {
        email: user?.email,
        id: user?.uid,
        name: fromOfficer
      }
    };
    return retObj;
  }
}
